import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerPingServerWrapper = styled(SingleDrawer)`
  background-color: ${colors.gray1};
  padding: initial;
  overflow: inherit;
  .img-container {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background-color: #27282a;
    border: solid 5px #151617;
    position: absolute;
    top: -55px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .ping-server-block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #151617;
    padding: 60px 30px 20px 30px;
    color: #fff;
    &.active {
      bottom: 0;
      transition-property: bottom;
    }

    .head-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
      h3 {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
      }
    }

    .message-title {
      font-size: 17px;
      color: #ffffff;
    }

    .write-your-message {
      margin-left: -30px;
      margin-right: -30px;
      padding: 25px 30px;
      border-top: 1px solid rgba(255, 255, 255, 0.08);
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      input {
        width: 100%;
        font-size: 15px;
        border: unset;
        outline: unset;
        background-color: transparent;
      }
    }

    .button-save {
      height: 50px;
      margin-top: 20px;
      width: 100%;
      border-radius: 8px;
      background-color: #ed2626;
      color: #fff;
      border: none;
      cursor: pointer;

      opacity: 1;
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -webkit-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;

      &.inactive-button {
        opacity: 0.5;
      }
    }
    .button-save:hover {
      opacity: 0.9;
      &.inactive-button {
        opacity: 0.5;
      }
    }

    .button-cancel {
      height: 50px;
      margin-top: 20px;
      width: 100%;
      border-radius: 8px;
      background-color: #61646c;
      color: #fff;
      border: none;
      cursor: pointer;

      opacity: 1;
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -webkit-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;
    }
    .button-cancel:hover {
      opacity: 0.9;
    }
  }
`;
