import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OrderNotificationContainer } from './style';
import { MiniNotificationControl } from './MiniNotificationControl';
import { NotificationControl } from './NotificationControl';

export function OrderNotification({
  className = '',
  total = 1,
  title = 'Water',
  price = 0,
  status = 0,
  changeStatus = () => {},
  onStartEdit = () => {},
  onStartRemove = () => {},
  description = '',
  normalControl = false,
}) {
  const [showControl, setShowControl] = useState(false);
  const onOpenControl = () => setShowControl(true);
  const onCloseControl = () => setShowControl(false);

  return !showControl ? (
    <OrderNotificationContainer
      className={`order-notification-item ${className} ${
        status ? '' : 'inactive'
      }`}
    >
      <div className='notification-top-part'>
        {status ? (
          <div className='checked-icon'>
            <i className='fas fa-check-circle' />
          </div>
        ) : (
          ''
        )}
        <div className='notifications-total-container'>
          <span className='notification-title-total'>{total}</span>
        </div>
        <div className='notification-title-price-container'>
          <span className='notification-title'>{title}</span>
          <span className='notification-price'>
            {price ? `$${price.toFixed(2)}` : 'Free'}
          </span>
        </div>
        <div className='look-icon-container' onClick={onOpenControl}>
          <i className='fas fa-chevron-right' />
        </div>
      </div>
      {description ? (
        <div className='description-container'>
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      ) : (
        ''
      )}
    </OrderNotificationContainer>
  ) : normalControl ? (
    <NotificationControl
      onBack={onCloseControl}
      onMarkStatus={status ? () => {} : changeStatus}
      onStartEdit={onStartEdit}
      onStartRemove={onStartRemove}
    />
  ) : (
    <MiniNotificationControl
      onBack={onCloseControl}
      onStartEdit={onStartEdit}
      onStartRemove={onStartRemove}
    />
  );
}

OrderNotification.propTypes = {
  className: PropTypes.string,
  normalControl: PropTypes.bool,
  total: PropTypes.number,
  title: PropTypes.string,
  price: PropTypes.number,
  status: PropTypes.number,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  changeStatus: PropTypes.func,
  onStartRemove: PropTypes.func,
  onStartEdit: PropTypes.func,
};
