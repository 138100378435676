import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AvailableMenuContainer } from './style';
import {
  defaultListCategories,
  mapAndFilterFoodInMenu,
} from 'services/functions';
import { useMenus, useAllCategories, useFoods } from 'hooks';
import { DataContext } from 'contexts/DataContextContainer';
import { StateContext } from 'contexts/StateContextContainer';
import ItemCard from 'pages/Menu/ItemCard';
import { NoItemsYet } from 'pages/Menu/components/MenuList/NoItemsYet';

export function AvailableMenu({ onChooseItem = () => {} }) {
  const { categories, setCategories, allCategories, foods } = useContext(StateContext);
  const { menus, chosenMenu, setChosenMenu } = useContext(DataContext);
  const getAllCategories = useAllCategories();

  const [menuList, setMenuList] = useState([]);
  useMenus();
  useFoods();

  useEffect(() => {
    if (menus.length !== 0 && allCategories.length === 0) {
      getAllCategories();
    }
  }, [allCategories, menus, getAllCategories]);

  useEffect(() => {
    const getSelectedMenu = () => {
      if (chosenMenu) {
        const category = allCategories.find(
          (categoryItem) => categoryItem.id === chosenMenu
        );
        if (chosenMenu && category) {
          setCategories(category.categories);
        }
      }
    };

    getSelectedMenu();
  }, [chosenMenu, allCategories, setCategories]);

  // in first load we need to setup chosenMenu
  // if user touched to menu before - code below will not be executed
  useEffect(() => {
    if (!chosenMenu && menus.length) {
      setChosenMenu([...menus].shift().id);
    }
  }, [chosenMenu, setChosenMenu, menus]);

  useEffect(() => {
    setMenuList(defaultListCategories(
      chosenMenu,
      allCategories,
      categories
    ).filter(({ items }) => items.length));
  }, [allCategories, categories]);

  return (
    <AvailableMenuContainer>
      {menuList.length ? (
        menuList.map((category, index) => (
          <div className='available-menu-category' key={category.name + index}>
            <div className='menu-category-title-container'>
              <span className='menu-category-title'>{category.name}</span>
            </div>
            <div className='menu-category-list-foods'>
              {mapAndFilterFoodInMenu('', category.items, foods).map(
                (food, i) => (
                  <ItemCard
                    key={i}
                    foodItem={food}
                    onClick={onChooseItem}
                    isDraggable={false}
                  />
                )
              )}
            </div>
          </div>
        ))
      ) : (
        <NoItemsYet addSomething={false} />
      )}
    </AvailableMenuContainer>
  );
}

AvailableMenu.propTypes = {
  categories: PropTypes.array,
  onChooseItem: PropTypes.func,
};
