import React from 'react';
import PropTypes from 'prop-types';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { StaffHeaderContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import SingleSearchComponent from 'components/SingleSearchComponent';
import SingleAntSelect from 'components/SingleAntSelect';

const sort = [
  {
    value: 1,
    label: 'Earliest',
  },
  {
    value: 0,
    label: 'Oldest',
  },
  {
    value: 2,
    label: 'Highest Rated',
  },
  {
    value: 3,
    label: 'Lowest Rated',
  },
];

const roles = [
  {
    value: 'manager',
    label: 'Manager / Owner',
  },
  {
    value: 'kitchen_staff',
    label: 'Kitchen Staff',
  },
  {
    value: 'waiter',
    label: 'Server / Host',
  },
];

const status = [
  {
    value: 1,
    label: 'Active',
  },
  {
    value: 0,
    label: 'Inactive',
  },
];

export function StaffHeader({
  staffs,
  setOpenDrawerRoles,
  onChangeSearchStaff,
  onClearSearchStaff,
  isAllStaff,
  selectedRole,
  setSelectedRole,
  selectedStatus,
  setSelectedStatus,
  selectedSort,
  setSelectedSort,
}) {
  return (
    <StaffHeaderContainer>
      <SingleText content='Staff' size={24} weight='bold' marginRight='auto'>
        Staff
      </SingleText>
      <div className='filter-container'>
        <SingleSearchComponent
          placeholder='Enter...'
          options={[
            ...staffs.map(({ first_name, last_name }, index) => ({
              id: index,
              value: `${first_name} ${last_name}`,
            })),
          ]}
          onChangeSearch={(value) => onChangeSearchStaff(value)}
          onClearAfterSearch={() => onClearSearchStaff()}
        />
        {isAllStaff ? (
          <>
            <SingleAntSelect
              className='staff-header-select'
              options={sort.map((item) => {
                return item;
              })}
              value={selectedSort && { label: selectedSort, value: selectedSort }}
              onChange={setSelectedSort}
              width={180}
              iconClosed={<FaChevronDown color='white' />}
              iconOpen={<FaChevronUp color='white' />}
              placeholder='Sort'
            />

            <SingleAntSelect
              className='staff-header-select'
              options={roles.map((item) => {
                return item;
              })}
              value={selectedRole && { label: selectedRole, value: selectedRole }}
              onChange={setSelectedRole}
              width={180}
              iconClosed={<FaChevronDown color='white' />}
              iconOpen={<FaChevronUp color='white' />}
              placeholder="Role"
            />

            <SingleAntSelect
              className='staff-header-select'
              options={status.map((item) => {
                return item;
              })}
              value={selectedStatus && { label: selectedStatus, value: selectedStatus }}
              onChange={setSelectedStatus}
              width={120}
              iconClosed={<FaChevronDown color='white' />}
              iconOpen={<FaChevronUp color='white' />}
              placeholder="Status"
            />
          </>
        ) : (
          ''
        )}
        <SingleButton
          shape='circle'
          width={170}
          height={40}
          btnColor={colors.redColor}
          txtColor='white'
          title='+ Add Staff'
          onClick={() => setOpenDrawerRoles(true)}
        />
      </div>
    </StaffHeaderContainer>
  );
}

StaffHeader.propTypes = {
  staffs: PropTypes.array,
  setOpenDrawerRoles: PropTypes.func,
  onChangeSearchStaff: PropTypes.func,
  onClearSearchStaff: PropTypes.func,
  isAllStaff: PropTypes.bool,
  selectedRole: PropTypes.string,
  setSelectedRole: PropTypes.func,
  selectedStatus: PropTypes.string,
  setSelectedStatus: PropTypes.func,
  selectedSort: PropTypes.string,
  setSelectedSort: PropTypes.func,
};
