/* eslint-disable no-magic-numbers */
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { restaurantServices } from 'services/api-services';
import { AuthContext } from 'contexts/AuthContextContainer';
import { showMessage } from 'services/functions';
import { Tab } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import Box from 'components/Box';
import { FaCamera, FaTimes, FaTrash, FaVideo } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import ItemPicker from 'components/DrawerForm/ItemPicker';
import { DrawerItemContainer } from './style';
import { SingleAvatarUploader } from 'components/SingleAvatarUploader';
import { DefaultFood } from 'assets/images/menu';
import { SingleVideoUploader } from 'components/SingleVideoUploader';
import { colors } from 'theme';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { StateContext } from 'contexts/StateContextContainer';
import DrawerTypes, { FoodTypes } from 'pages/Menu/components/DrawerTypes';
import DrawerDietaryTags, { DietaryTags } from 'pages/Menu/components/DrawerDietaryTags';
import DrawerNutrition from 'pages/Menu/components/DrawerNutrition';
import DrawerCustomizations from 'pages/Menu/components/DrawerCustomizations';
import DrawerFoodStatus from 'pages/Menu/components/DrawerFoodStatus';
import DrawerAssignItem from 'pages/Menu/components/DrawerAssignItem';
import DrawerDeleteItem from '../DrawerDeleteItem';


const initialFood = {
  status: 1
}
const initialMediaTab = 'image';
export default function DrawerItem({
  visible = false,
  placement = 'right',
  editFood,
  setOpenDrawerFoodItem,
}) {

  const isEdit = editFood?.id;

  const onClose = () => {
    setFood(initialFood)
    setSelectedTab(initialMediaTab)
    setOpenDrawerFoodItem(false);
  }

  const { allCategories, foods, setFoods, setAllCategories } = useContext(StateContext);
  const { restaurantId } = useContext(AuthContext);

  //drawers
  const [openDrawerTypes, setOpenDrawerTypes] = useState(false);
  const [openDrawerStatus, setOpenDrawerStatus] = useState(false);
  const [openDrawerDietaryTags, setOpenDrawerDietaryTags] = useState(false);
  const [openDrawerNutrition, setOpenDrawerNutrition] = useState(false);
  const [openDrawerCustomizations, setOpenDrawerCustomizations] = useState(false);
  const [openDrawerCategory, setOpenDrawerCategory] = useState(false);
  const [openDrawerDeleteFoodItem, setOpenDrawerDeleteFoodItem] = useState(false);

  //states
  const [food, setFood] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [requiredFields, setRequiredFields] = useState([]);
  const [selectedTab, setSelectedTab] = useState(initialMediaTab);
  const [uploadedPhoto, setUploadedPhoto] = useState();
  const [uploadedVideo, setUploadedVideo] = useState();

  //hooks
  useEffect(() => {
    setFood(initialFood);
  }, [])

  useEffect(() => {
    if (editFood) {
      setFood({ ...editFood });
      setUploadedPhoto(editFood.photo);
      setUploadedVideo(editFood.video);
    }
  }, [editFood])

  useEffect(() => {
    ifRequiredPresent();
    // eslint-disable-next-line
  }, [food])

  // console.log("food", food);

  const onClickType = () => setOpenDrawerTypes(true);
  const onClickDietaryTag = () => setOpenDrawerDietaryTags(true);
  const onClickNutrition = () => setOpenDrawerNutrition(true);
  const onClickDeleteItem = () => setOpenDrawerDeleteFoodItem(true);
  const onClickStatus = () => setOpenDrawerStatus(true);
  const onClickCategory = () => setOpenDrawerCategory(true);
  const onClickCustomizations = () => setOpenDrawerCustomizations(true);

  const onUploadMedia = () => setUploadingMedia(true);

  const ifRequiredPresent = () => {
    const requiredKeys = ['status', 'title', 'kind', 'category', 'price'];
    const translation = { status: 'Status', title: 'Item Name', kind: 'Type', category: 'Category', price: 'Price' };
    const getAddedKeys = Object.keys(food);
    let missingKeys = [];
    requiredKeys.forEach((_key) => {
      if (!getAddedKeys.includes(_key)) { missingKeys.push(translation[_key]); }
      if ((_key === 'title' || _key === 'price') && food[_key] === '') { missingKeys.push(translation[_key]); }
    })
    setRequiredFields(missingKeys);
  }

  const changeFoodMultiply = (updatedFood) => setFood(updatedFood);

  const updateFoods = (newFood, isUpdate = false) => {
    let newFoods = [];
    if (isUpdate) {
      const index = foods.findIndex(item => item.id === newFood.id);
      newFoods = [...foods];
      newFoods[index] = newFood;
    } else {
      newFoods = [...foods, newFood];
    }
    setFoods(newFoods);
  }

  const updateCategories = (newFood) => {
    const updatedAllCat =
      allCategories.map(menu => {
        const updatedCats = menu.categories.map(cat => {
          if (newFood.category.includes(cat.id) && !cat.items.includes(newFood.id)) {
            cat.items.push(newFood.id);
          } else if (!newFood.category.includes(cat.id) && cat.items.includes(newFood.id)) {
            const index = cat.items.findIndex(foodId => foodId === newFood.id);
            cat.items.splice(index, 1);
          }
          return cat;
        })
        return { ...menu, categories: updatedCats };
      })
      console.log('updatedAllCat');
    setAllCategories(updatedAllCat)
  }

  async function onClickAdd() {
    if (requiredFields.length > 0) {
      showMessage(`${requiredFields.join(', ')} required.`, 'error')
    } else {
      setIsProcessing(true);
      const { ok, data, errMessage } = await restaurantServices.createFood(restaurantId, { ...food, price: parseFloat(food.price) });
      if (ok) {
        setIsProcessing(false);
        showMessage('Added Successfully', 'success');
        if (data.id) {
          const newFood = { ...food, id: data.id, photo: uploadedPhoto, video: uploadedVideo }
          saveCustomization(newFood, restaurantId);

          //update food in current state
          setFood(newFood);

          //updating food array
          updateFoods(newFood)

          //update menu and categories
          updateCategories(newFood)
          onClose();
        }
        onClose();
      } else {
        setIsProcessing(false);
        showMessage(errMessage, 'error');
      }
    }
  }

  async function onClickEdit() {
    if (typeof food.photo == 'object') { delete food.photo; }
    if (typeof food.video == 'object') { delete food.video; }
    setIsProcessing(true);
    const { ok, errMessage } = await restaurantServices.updateFood({ restaurantId, foodId: food.id }, food);
    if (ok) {
      setIsProcessing(false);
      showMessage('Edited Successfully', 'success');
      const newFood = { ...food, photo: uploadedPhoto, video: uploadedVideo }
      saveCustomization(newFood, restaurantId);

      //update food in current state
      setFood(newFood);

      //updating foods array
      updateFoods(newFood, true)

      //update menu and categories
      updateCategories(newFood)
      onClose();
    } else {
      setIsProcessing(false);
      showMessage(errMessage, 'error');
    }
  }

  return (
    <DrawerItemContainer
      className="drawer-item-container"
      placement={placement}
      onClose={onClose}
      visible={visible}
    >
      <Box display='flex' alignItems='center' padding='37px 24px'>
        <SingleIconWrapper>
          <FaTimes className='cursor' size={20} color={colors.white3} onClick={onClose} />
        </SingleIconWrapper>
        <Box display='flex' justifyContent='center' flex={1}>
          <SingleText content={`${isEdit ? "Edit" : "Add"} Item`} align='center' size={22} weight={500}>{`${isEdit ? "Edit" : "Add"} Item`}</SingleText>
        </Box>
        {food.id && <FaTrash className='cursor' size={20} color='white' onClick={onClickDeleteItem} />}
      </Box>
      <Scrollbars className="scroolbars-container" autoHide style={{ flex: 1 }}>
        <Box className="scroolbars-container-inner" padding='0 24px'>

          <Tab.Container id="tabs-bootstrap" defaultActiveKey="image" activeKey={selectedTab}>
            <Tab.Content>
              <Tab.Pane eventKey="image">
                <SingleAvatarUploader
                  shape='square'
                  height="220px"
                  placeholder={uploadedPhoto?.file ?? DefaultFood}
                  uploading={uploadingMedia}
                  onUpload={onUploadMedia}
                  overlay={
                    <Box display='flex' justifyContent='center' alignItems='center'>
                      <FaCamera size={24} color='white' />
                      <SingleText content={`${food.photo ? "Replace" : "Add"} Image`} size={15} weight={600} marginLeft={6} spacing='-0.36px'>{`${food.photo ? 'Replace' : 'Add'} Image`}</SingleText>
                    </Box>
                  }
                  receiveImage={(data) => {
                    setUploadedPhoto(data);
                    setFood({ ...food, photo: data.id })
                  }}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="video">
                <SingleVideoUploader
                  shape='square'
                  height="220px"
                  video={uploadedVideo?.file}
                  placeholder={DefaultFood}
                  uploading={uploadingMedia}
                  onUpload={onUploadMedia}
                  overlay={
                    <Box display='flex' justifyContent='center' alignItems='center'>
                      <FaVideo size={24} color='white' />
                      <SingleText content={`${food.video ? "Replace" : "Add"} Video`} size={15} weight={600} marginLeft={6} spacing='-0.36px'>{`${food.video ? 'Replace' : 'Add'} Video`}</SingleText>
                    </Box>
                  }
                  receiveVideo={(data) => {
                    setUploadedVideo(data);
                    setFood({ ...food, video: data.id })
                  }}
                />
              </Tab.Pane>
            </Tab.Content>

            <div className="tab-switcher-container">
              <div href="#" className={`tab-switcher-item ${selectedTab === 'image' ? 'active' : ''}`} onClick={() => setSelectedTab('image')}>
                Image
              </div>
              <div href="#" className={`tab-switcher-item ${selectedTab === 'video' ? 'active' : ''}`} onClick={() => setSelectedTab('video')}>
                Video
              </div>
            </div>
          </Tab.Container>

          <Box className='drawer-menu--options' marginBottom={27}>
            <Box className='drawer-menu--option'>
              <Box>
                <SingleText content='Item Name' size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>Item Name</SingleText>
                <input
                  type="text"
                  placeholder="Ex: Caesar Salad"
                  style={{
                    display: 'block',
                    width: '100%',
                    backgroundColor: 'transparent',
                    border: 'unset',
                    outline: 'unset',
                    paddingLeft: 0,
                    paddingTop: 5,
                    color: '#fff'
                  }}
                  value={food.title}
                  onChange={(e) => setFood({ ...food, title: e.target.value })}
                />
              </Box>
            </Box>

            <ItemPicker
              label='Type'
              value={
                food.kind > -1 ?
                  FoodTypes.filter(i => i.value == food.kind)[0]?.name
                  : 'Set Type'
              }
              onClick={onClickType} />

            <Box className='drawer-menu--option'>
              <Box>
                <SingleText content='Price' size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>Price</SingleText>
                <input
                  type="text"
                  placeholder="Enter Price"
                  style={{
                    display: 'block',
                    width: '100%',
                    backgroundColor: 'transparent',
                    border: 'unset',
                    outline: 'unset',
                    paddingLeft: 0,
                    paddingTop: 5,
                    color: '#fff'
                  }}
                  value={food.price}
                  onChange={(e) => setFood({ ...food, price: e.target.value })}
                />
              </Box>
            </Box>
            <ItemPicker
              label='Status'
              value={food.status === 0 ? 'Inactive' : 'Active'}
              onClick={onClickStatus} />
            <ItemPicker
              label='Menu'
              value={food.category?.length ? `Assigned ${food.category.length} to menu` : `Assigned to menu`}
              onClick={onClickCategory}
            />
          </Box>
          <Box className='drawer-menu--options' marginBottom={45}>
            <SingleText content='Optional but highly recommended.' opacity={0.7} size={15} weight={600} spacing='-0.36px' marginBottom={12} marginRight='auto'>Optional but highly recommended.</SingleText>
            <Box className='drawer-menu--option'>
              <Box>
                <SingleText content='Description' size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>Description</SingleText>
                <input
                  type="text"
                  placeholder="Write a description"
                  style={{
                    display: 'block',
                    width: '100%',
                    backgroundColor: 'transparent',
                    border: 'unset',
                    outline: 'unset',
                    paddingLeft: 0,
                    paddingTop: 5,
                    color: '#fff'
                  }}
                  value={food.description}
                  onChange={(e) => setFood({ ...food, description: e.target.value })}
                />
              </Box>
            </Box>
            <ItemPicker
              label='Dietary Tags'
              value={
                food.dietary?.length ?
                  DietaryTags
                    .filter(i => food.dietary.includes(i.value))
                    .map(i => i.name).join(", ")
                  : 'Set dietary tags'
              }
              onClick={onClickDietaryTag} />
            <ItemPicker
              label='Nutrition Facts'
              value={`${food.nutrition ? 'Edit' : 'Set'} nutrition facts`}
              onClick={onClickNutrition}
            />
            <ItemPicker
              label='Customizations'
              value={food?.customization ? 'Edit customizations ' : 'Set customizations'}
              onClick={() => onClickCustomizations()}
            />
            {/* was 'type === 'Edit' &&' for customization function. When Item food start working - need extra tests. */}
          </Box>
          <DrawerTypes
            visible={openDrawerTypes}
            onClose={() => setOpenDrawerTypes(false)}
            value={food.kind}
            onSubmit={type => {
              setOpenDrawerTypes(false);
              setFood({ ...food, kind: type })
            }}
          />
          <DrawerFoodStatus
            visible={openDrawerStatus}
            onClose={() => setOpenDrawerStatus(false)}
            value={food.status}
            onSubmit={status => {
              setOpenDrawerStatus(false);
              setFood({ ...food, status: status })
            }}
          />
          <DrawerAssignItem
            title={food.title}
            categories={allCategories}
            visible={openDrawerCategory}
            value={food.category ?? []}
            onClose={() => setOpenDrawerCategory(false)}
            onSubmit={(categories) => {
              setOpenDrawerCategory(false)
              setFood({ ...food, category: categories })
            }}
          />
          <DrawerDietaryTags
            visible={openDrawerDietaryTags}
            value={food.dietary}
            onClose={() => setOpenDrawerDietaryTags(false)}
            onSubmit={(dietaryTags) => {
              setOpenDrawerDietaryTags(false)
              setFood({ ...food, dietary: dietaryTags })
            }}
          />
          <DrawerNutrition
            visible={openDrawerNutrition}
            value={(food.nutrition && typeof food.nutrition === 'string') ? JSON.parse(food.nutrition)?.[0] : food.nutrition}
            onClose={() => setOpenDrawerNutrition(false)}
            onSubmit={(nutrition) => {
              setOpenDrawerNutrition(false)
              setFood({ ...food, nutrition: JSON.stringify(nutrition) })
            }}
          />
          <DrawerCustomizations
            visible={openDrawerCustomizations}
            onClose={() => setOpenDrawerCustomizations(false)}
            editFood={food}
            changeFoodMultiply={changeFoodMultiply}
          />
          <DrawerDeleteItem
            visible={openDrawerDeleteFoodItem}
            setOpenDrawerDeleteFoodItem={setOpenDrawerDeleteFoodItem}
            editFood={openDrawerDeleteFoodItem ? food : null}
          />
        </Box>
      </Scrollbars>
      <Box padding='24px' display='flex' flexDirection='column' marginTop='auto'>
        <SingleButton
          title={food ? 'Save Item' : 'Add Item'}
          btnColor={requiredFields.length > 0 ? '#61646c' : colors.redColor}
          loading={isProcessing}
          txtColor='white'
          onClick={isEdit ? onClickEdit : onClickAdd}
        />
      </Box>
    </DrawerItemContainer>
  )
}

DrawerItem.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  editFood: PropTypes.object,
  onClose: PropTypes.func,
  setOpenDrawerFoodItem: PropTypes.func,
};


export const isCustomizationsWasChanged = (food) =>
  ('beforeSubmitCustomizations' in food &&
    food.beforeSubmitCustomizations.length !== 0) ||
  food.isSequenceChanged;

export const isSelectedCustomizationsWasChanged = (food) =>
  food.selectedCustomizationsChanged &&
  !!food.selectedCustomizationsChanged.length;

export const saveCustomization = async (foodItem, restaurantId) => {
  // here we checking if something changed in food
  // currently we have checks only for customizations

  if (
    isCustomizationsWasChanged(foodItem) ||
    isSelectedCustomizationsWasChanged(foodItem)
  ) {
    // here we will update new values from client to server

    if (isSelectedCustomizationsWasChanged(foodItem)) {
      await Promise.all(
        foodItem.selectedCustomizationsChanged.map(
          async (updatedCustomization) =>
            await restaurantServices.updateCustomizationById(
              {
                restaurantId,
                foodId: foodItem.id,
                customizationId: updatedCustomization.id,
              },
              updatedCustomization
            )
        )
      );
    }

    const customizationsBeforeWithoutIdNowWillBeWithId = [];

    await Promise.all(
      foodItem.beforeSubmitCustomizations.map(
        async (customization) =>
          await restaurantServices.createCustomizationOfFood(
            {
              restaurantId,
              foodId: foodItem.id,
              payload: customization,
            },
            ({ id }) =>
              customizationsBeforeWithoutIdNowWillBeWithId.push({
                id,
                name: customization.name,
              })
          )
      )
    );

    return await restaurantServices.updateSortCustomizationByUserSequence({
      restaurantId,
      foodId: foodItem.id,
      payload: transformSequenceOfCustomizationsWithMissedPlaces(
        foodItem.sortSequence,
        customizationsBeforeWithoutIdNowWillBeWithId
      ),
    });
  }
};

// works like this (const a = [1,2,3, 'abc']; const b = [{ id: 4, name: 'abc'}] ) => [1,2,3,4]
export const transformSequenceOfCustomizationsWithMissedPlaces = (
  sequence,
  missedItems
) => {
  return sequence.map((id) => {
    const nameItem = missedItems.find(({ name }) => name === id);
    if (nameItem) {
      return nameItem.id;
    } else {
      return id;
    }
  });
};
