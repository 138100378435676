import React from 'react';
import PropTypes from 'prop-types';
import { DrawerApplyDiscountAreYouSureContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerApplyDiscountAreYouSure({
  visible,
  onClose,
  discount = { value: 0 },
  discountTypes,
  applyDiscountForActiveOrder,
}) {
  const onApply = () => {
    applyDiscountForActiveOrder(discount);
    onClose();
  };

  return (
    <DrawerApplyDiscountAreYouSureContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <SingleText
        size={22}
        spacing='0.42px'
        weight='500'
        marginBottom={10}
        align='center'
        width='329px'
      >
        Apply Discount
      </SingleText>
      <SingleText
        lineHeight='21px'
        spacing='0.27px'
        align='center'
        width='329px'
        marginBottom={31}
      >
        Are you sure you want to apply a{' '}
        {discount && discount.type === discountTypes.first.value
          ? `${Math.trunc(discount.value)}%`
          : `$${discount.value.toFixed(2)}`}{' '}
        discount to this order?
      </SingleText>
      <SingleButton
        title='Apply Discount'
        margin='0px 0px 20px'
        onClick={onApply}
      />
      <SingleButton
        title='Cancel'
        btnColor='transparent'
        txtColor={colors.redColor}
        onClick={onClose}
      />
    </DrawerApplyDiscountAreYouSureContainer>
  );
}

DrawerApplyDiscountAreYouSure.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  discount: PropTypes.object,
  discountTypes: PropTypes.object,
  applyDiscountForActiveOrder: PropTypes.func,
};
