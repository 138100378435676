import styled from '@emotion/styled';

export const AvailableMenuContainer = styled.div`
  .available-menu-category {
    width: 100%;
    margin-top: 19px;
    display: flex;
    flex-direction: column;

    &:first-of-type {
      margin-top: 53px;
    }

    .menu-category-title-container {
      margin-bottom: 21px;
      .menu-category-title {
        font-size: 20px;
        color: white;
        letter-spacing: 0;
      }
    }

    .menu-category-list-foods {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .item-card-main-container {
        cursor: pointer;
        width: initial;

        .dropdown {
          display: none;
        }
      }
    }
  }
`;
