import { ProgressPercentage } from './ProgressPercentage';

export const PERCENTAGE_FOR_FULL_READINESS = 100;

export class Readiness {
  progressPercentage;

  constructor(completedItems) {
    this.progressPercentage = new ProgressPercentage(completedItems);
  }

  isReady() {
    const readinessInPercentage =
      this.progressPercentage.getProgressPercentage();
    return readinessInPercentage === PERCENTAGE_FOR_FULL_READINESS;
  }
}
