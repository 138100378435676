import styled from '@emotion/styled';

export const FullNameContainer = styled.div`
  margin-top: 140px;
  display: flex;
  width: 337px;

  flex-direction: column;
  align-items: center;

  .full-name-item {
    width: 337px;
    padding-left: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;

    label {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.34px;
      color: rgba(255, 255, 255, 0.5);
      margin-top: 5px;
      margin-bottom: 0px;
    }

    input {
      padding-left: 0px;
      width: 337px;
      background-color: transparent;
      border-color: transparent;
      border-left-width: 0px;
      border-right-width: 0px;
      outline: none;
      font-size: 14px;
      font-weight: 600;
      color: white;
      line-height: 22px;
      letter-spacing: -0.34px;
    }
  }
`;
