import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerChooseExistingItemContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  padding: 0;
  display: flex;
  flex-direction: column;

  .exit-icon {
    margin: 36px 0px 40px 33px;
    width: 22px;
    height: 22px !important;
    font-size: 22px;
  }

  .existing-food-card {
    margin: 0px 26px 11px;
    padding: 15px 16px;
    width: 86%;
    display: flex;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 16px;

    &.selected {
      border: 2px solid ${colors.redColor};
    }

    .food-photo {
      width: 43px;
      height: 30px;
      border-radius: 6px;
      background-size: cover;
      background-position: center;
    }
  }

  .bottomControl {
    padding: 0px 26px 33px;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
