import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// import './style.scss';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { restaurantServices } from 'services/api-services';
import { showMessage } from 'services/functions';
import { DrawerDeleteMenuContainer } from './style';
import { colors } from 'theme';
import { StateContext } from 'contexts/StateContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';
import { DataContext } from 'contexts/DataContextContainer';

export default function DrawerDeleteMenu({
  visible = false,
  placement = 'bottom',
  onClose = () => {},
}) {
  const {
    setOpenDrawerDeleteMenu,
    setOpenDrawerMenuEdit,
    setOpenDrawerMoveItemsInMenu,
  } = useContext(StateContext);
  const { restaurantId } = useContext(AuthContext);
  const { selectedMenu } = useContext(DataContext);

  const onClickDeleteMenu = async () => {
    const { ok, errMessage } = await restaurantServices.deleteMenu({
      restaurantId,
      menuId: selectedMenu.id,
    });
    if (ok) {
      setOpenDrawerDeleteMenu(false);
      setOpenDrawerMenuEdit(false);
    } else {
      showMessage(errMessage, 'error');
    }
  };

  function onMoveAndDelete() {
    setOpenDrawerMoveItemsInMenu(true);
  }

  return (
    <DrawerDeleteMenuContainer
      placement={placement}
      onClose={onClose}
      height={359}
      visible={visible}
    >
      <Box className='delete-top'>
        <SingleText size={22} weight={500} marginBottom={10}>
          Delete Menu
        </SingleText>
        <SingleText
          lineHeight={1.5}
          spacing='0.27px'
          align='center'
        >
          Looks like you have 24 items assigned to this menu. What would you
          like to do?
        </SingleText>
      </Box>
      <Box is='div' display='flex' flexDirection='column'>
        <SingleButton
          title='Move Items'
          btnColor={colors.redColor}
          txtColor='white'
          margin='0 0 20px'
          onClick={onMoveAndDelete}
        />
        <SingleButton
          title='Delete Menu & Items'
          btnColor={colors.lightGray}
          txtColor='white'
          margin='0 0 20px'
          onClick={onClickDeleteMenu}
        />
        <SingleButton
          title='Cancel'
          btnColor='transparent'
          txtColor={colors.redColor}
          onClick={onClose}
        />
      </Box>
    </DrawerDeleteMenuContainer>
  );
}

DrawerDeleteMenu.propTypes = {
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickDay: PropTypes.func,
  onClickDeleteMenu: PropTypes.func,
};
