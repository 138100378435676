import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerCuisineWrapper = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  .cuisine-panel {
    font-family: Montserrat;
    .panel-header-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      .back-arrow {
        width: 50px;
        cursor: pointer;
        position: absolute;
        left: 0;
        i {
          color: #ffffff;
          font-size: 22px;
        }
      }
      .panel-header-context {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .panel-status-container {
      margin-bottom: 20px;
      margin-top: 70px;
      .select-message {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 30px;
      }
      .cuisine-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .cuisine-item {
          cursor: pointer;
          margin-bottom: 15px;
          width: 164px;
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          background-color: ${colors.gray5};
          display: flex;
          flex-direction: column;
          align-items: center;

          &.selected {
            border: solid 2px #ed2626;
          }

          &.unselected {
            opacity: 0.4;
          }

          .cuisine-icon {
            max-width: 70px;
            max-height: 70px;
            min-width: 70px;
            min-height: 70px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            margin-bottom: 5px;
          }

          .internal-title {
            font-size: 15px;
            font-weight: 600;
            color: #fff;
          }
        }
      }
    }

    .button-container {
      margin-top: 300px;
      padding-bottom: 25px;

      .done-button {
        position: fixed;
        bottom: 24px;
        height: 50px;
        width: 340px;
        background-color: ${colors.gray12};
        border-radius: 8px;
        box-shadow: 0px 10px 13px -7px rgba(27, 28, 29, 0),
          5px 18px 15px 5px rgba(28, 27, 29, 0.8);
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.4);
        border: none;
        outline: none;
        &.active {
          background-color: #ed2626;
          color: #fff;
        }
      }
    }
  }
`;
