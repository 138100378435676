import React, { useState, useContext } from 'react';
import { CompletedNotifyContainer } from './style';
import { CompletedOrdersContext } from 'contexts/CompletedOrdersContextContainer';
import { Orders } from 'feasttt/domain';
import { CompletedTableNotification } from './CompletedTableNotification';
import { CompletedOrderFoodItems } from './CompletedOrderFoodItems';
import { PriceCheck } from './PriceCheck';
import { DrawerServerReview } from './DrawerServerReview';

const mockedServer = {
  name: 'Megan S.',
  position: 'Server / Host',
  rating: Math.trunc((4 + 5) / 2),
};

export function CompletedNotify() {
  const { activeOrder, isActiveOrderEmpty } = useContext(
    CompletedOrdersContext
  );

  const [openDrawerServerReview, setDrawerServerReview] = useState(false);
  const onOpenDrawerServerReview = () => setDrawerServerReview(true);
  const onCloseDrawerServerReview = () => setDrawerServerReview(false);

  return (
    <CompletedNotifyContainer>
      {!isActiveOrderEmpty() ? (
        <>
          <CompletedTableNotification
            activeOrder={activeOrder}
            server={mockedServer}
            onOpenDrawerServerReview={onOpenDrawerServerReview}
          />
          <CompletedOrderFoodItems activeOrder={activeOrder} />
          <PriceCheck
            subTotal={Orders.calculation.calculatePriceForOrder(activeOrder)}
            total={Orders.calculation.calculatePriceForCompletedOrder(
              activeOrder
            )}
            refund={
              activeOrder.refund && activeOrder.refund.money
                ? activeOrder.refund
                : undefined
            }
            discount={
              activeOrder.discount &&
              Object.keys(activeOrder.discount).length !== 0
                ? activeOrder.discount
                : undefined
            }
            tip={
              activeOrder.tip && activeOrder.tip.money
                ? activeOrder.tip
                : undefined
            }
          />
          <DrawerServerReview
            server={mockedServer}
            visible={openDrawerServerReview}
            onClose={onCloseDrawerServerReview}
          />
        </>
      ) : (
        <div className='select-order-container'>
          <span className='selected-order-title'>
            Select order for displaying.
          </span>
        </div>
      )}
    </CompletedNotifyContainer>
  );
}
