import styled from "@emotion/styled";
import SingleAntDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerAddOptionContainer = styled(SingleAntDrawer)`
  padding: 36px 0px 0px;
  background-color: ${colors.gray1};
  .additional-price {
    transition: 0.3s ease;
  }
`;
