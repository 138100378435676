import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerTableContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  padding: 0px 0px;

  .table-update-top-title {
    margin-left: -30px;
  }
`;
export const TableShapeContainer = styled.div`
  padding: 36px 26px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  .shape-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .table-shape {
    width: 155px;
    height: 98px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    cursor: pointer;
    &.active {
      border: solid 1px #ffffff;
      transition: 0.5s ease;
    }
    &.inactive {
      border: solid 1px rgba(255, 255, 255, 0.1);
      transition: 0.5s ease;
    }
  }
`;
