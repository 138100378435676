import React, { useState, useEffect, useContext } from 'react';
import { RequestPendingContainer } from './style';
import { RequestPendingList } from '../RequestsPendingList';
import { RequestCompletedList } from '../RequestCompletedList';
import Nav from 'react-bootstrap/Nav';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import { RequestPendingSideData } from './RequestPendingSideData';
import { StateContext } from 'contexts/StateContextContainer';

export function RequestPending() {
  const [index, setIndex] = useState(undefined);
  const [indexCom, setIndexCom] = useState(undefined);
  const [conditionPen, setConditionPen] = useState(true);
  const [avg, setAvg] = useState('');

  useEffect(() => {
    setAvg('6m 13s');
  }, []);

  // pending index
  const findClickElement = (id) => {
    setIndex(id);
  };

  // completed index
  const findIndexCompleted = (id) => {
    setIndexCom(id);
  };

  // tabs onClick method
  const handleSelect = (key) => {
    if (key === '1') {
      setConditionPen(true);
      setAvg('6m 13s');
    } else if (key === '2') {
      setAvg('8m 10s');
      setConditionPen(false);
    }
  };

  const contextPendingCount = useContext(StateContext)
  const { pendingRequestsTotal } = contextPendingCount

  return (
    <RequestPendingContainer>
      <div className='wrapper'>
        <div className='flex-inline'>
          <p className='request-text'>Requests</p>
          <div className='line-height'>
            <p className='avg-waiting-text'>Avg Waiting Today</p>

            {avg ? (
              <>
                <p className='avg-waiting-today'>
                  <i className='fad fa-clock'></i><span>{avg}</span>
                </p>
              </>
            ) : (
              <p className='underscore'>— —</p>
            )}

            {/* <p className='underscore'>— —</p> */}
            {/* <p className='avg-waiting-today'>
                  <i className='fad fa-clock'></i>&nbsp;&nbsp;{avg}
                </p> */}
          </div>
        </div>

        {/* tab section */}
        <Tab.Container id='' defaultActiveKey={1} onSelect={handleSelect}>
          <Nav variant='pills'>
            <Nav.Item>
              <Nav.Link eventKey={'1'}>Pending: {pendingRequestsTotal}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={'2'}>Completed</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey={'1'}>
              <div>
                <RequestPendingList handleClick={findClickElement} />
              </div>
            </Tab.Pane>
          </Tab.Content>
          <Tab.Content>
            <Tab.Pane eventKey={'2'}>
              <div>
                <RequestCompletedList handleClick={findIndexCompleted} />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        {/* end tab section */}
      </div>
      <div className='sidebar-wrapper'>
        <RequestPendingSideData
          conditionPendingCompleted={conditionPen}
          // eslint-disable-next-line no-magic-numbers
          pending={index >= 0 ? true : false}
          // eslint-disable-next-line no-magic-numbers
          completed={indexCom >= 0 ? true : false}
          index={index}
          comIndex={indexCom}
        />
      </div>
    </RequestPendingContainer>
  );
}
