import { createGlobalStyle } from 'styled-components';
import { colors } from 'theme';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Montserrat;
    margin: 0;
    height: 100%;
  }
  .items-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .items-start {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // flex-wrap: wrap;
  }

  .items-left-row-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .items-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-wrap: wrap;
  }

  .items-row {
    display: flex;
    flex-direction: row;
  }

  .items-column {
    display: flex;
    flex-direction: column;
  }

  .items-row-center-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .items-row-left-center {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .items-row-between-center {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .items-row-between-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .items-column-left-center {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .items-column-left-top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .items-column-center-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .items-column-center-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .items-column-left-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .text-left {text-align: left;}
  .text-right {text-align: right;}
  .text-center {text-align: center;}
  .text-bold {font-weight: bold;}
  .text-red {color: ${colors.redColor} !important;}
  .text-white {color: white !important;}
  .text-dark {color: black !important;}
  .text-gray {color: #515a65 !important;}
  .text-light-gray {color: #9a9a9a !important;}
  .text-yellow {color: #e2a042 !important;}
  .text-green {color: #339933 !important;}
  .text-orange {color: #fa8223 !important;}

  .text-uppercase {text-transform: uppercase;}

  .cursor { cursor: pointer; }
  .cursor-grab { cursor: grab; }

  .w-100 { width: 100%; }
  .h-100 { height: 100%; }

  .ant-popover {
    z-index: 1;
    padding-top: 0;
    .ant-popover-content {
      box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1), 0 6px 30px 5px rgba(0, 0, 0, 0.06), 0 16px 24px 2px rgba(0, 0, 0, 0.09);
      border: solid 1px #4d5053;
      border-radius: 25px !important;
      .ant-popover-arrow {
        display: none;
      }
      .ant-popover-inner {
        border-radius: 25px !important;
        background-color: ${colors.gray1};
        .ant-popover-inner-content {
          padding: 15px 0;
          border-radius: 25px !important;
          .popup-item {
            height: 30px;
            padding: 0 30px;
            display: flex;
            align-items: center;
            cursor: pointer;
            span {
              color: white;
              display: flex;
              align-items: center;
            }
            .popup-item-icon {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
  
  .antd-img-crop-modal {
    .ant-modal {
      .ant-modal-content {
        background-color: ${colors.gray2};
        border-radius: 10px;
        .ant-modal-close {
          color: white;
        }
        .ant-modal-header {
          background: ${colors.gray2};
          border-bottom: unset;
          border-radius: 10px 10px 0 0;
          padding: 24px 24px 0;
          .ant-modal-title {
            color: white;
            font-family: 'Montserrat';
            font-weight: bold;
            font-size: 18px;
          }
        }
        .ant-modal-body {
          .antd-img-crop-control.zoom {
            .ant-slider {
              .ant-slider-track {
                background-color: #339933;
              }
              .ant-slider-handle {
                border: solid 2px #339933;
              }
            }
            button {
              color: white;
            }
          }
        }
        .ant-modal-footer {
          display: flex;
          text-align: center;
          border-top: unset;
          .ant-btn {
            flex: 1;
            height: 50px;
            border-radius: 7px;
            &.ant-btn-primary {
              background: ${colors.redColor};
              border-color: ${colors.redColor};
            }
            span {
              font-family: 'Montserrat';
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  // some delete drawers

  .trash-delete-icon {
    z-index: 101;
    padding: 21px 22px;
    width: 68.5px;
    height: 68.5px;
    border-radius: 50%;
    background-color: ${colors.redColor};
    position: absolute;

    i {
      font-size: 26px;
      letter-spacing: 0.46px;
      color: white;
    }
  }
`;

export default GlobalStyle;
