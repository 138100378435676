import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContextContainer';

export const useBackToLoginPageAndHistory = () => {
  const history = useHistory();
  const { clearDataIfUserWillNotCompleteProfile } = useContext(AuthContext);

  const backToLoginPage = () => {
    goToLoginPage();
    clearDataIfUserWillNotCompleteProfile();
  };

  const goToLoginPage = () => history.push('sign-in');

  return {
    backToLoginPage,
    history,
  };
};
