import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerRemoveFeaturedImageContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  padding: 36px 31px 23px;
  .delete-image-block {
    .head-block {
      display: flex;
      justify-content: space-between;
      h3 {
        font-size: 22px;
        font-weight: 500;
        color: white;
        margin-bottom: 0px;
      }
    }

    .warning-text {
      font-size: 14px;
      color: white;
      margin-top: 10px;
      margin-bottom: 31px;
    }

    .btn-delete {
      height: 50px;
      margin-bottom: 20px;
      width: 100%;
      border-radius: 8px;
      background-color: #ed2626;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      border: none;
      cursor: pointer;

      opacity: 1;
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -webkit-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;

      &:hover {
        opacity: 0.9;
        &.inactive-button {
          opacity: 0.5;
        }
      }
    }

    .btn-keep {
      height: 50px;
      width: 100%;
      border-radius: 8px;
      background-color: #61646c;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      border: none;
      cursor: pointer;

      opacity: 1;
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -webkit-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;

      &:hover {
        opacity: 0.9;
      }
    }
  }
`;
