import styled from '@emotion/styled';
import { colors } from 'theme';

export const ProfileContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .restaurant-profile-content {
    flex: 1;
    padding: 29px 40px;

    .vertical-space {
      width: 100%;
      .space-item {
        margin-bottom: 25px;
      }

      .space-item:last-item {
        margin-bottom: 0px;
      }
    }

    .boot-container {
      max-width: 100%;

      .container {
        padding-left: 0px;
        padding-right: 0px;
        margin-left: initial;
        margin-right: initial;
      }

      .row {
        .col {
        }
      }
    }
  }
`;

export const ProfileCardContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 22px 0 ${colors.dark1};
  background-color: ${colors.gray2};
  padding: ${(props) => props.padding};
  .ant-space {
    width: 100%;
    .ant-space-item {
      flex: 1;
      height: 100%;
    }
  }

  &.horizontal-space {
    display: flex;
    align-items: center;
    .horizontal-inner-element {
      flex: 1 1 33%;
      ${'' /* margin-right: 66px; */}
    }
    .horizontal-inner-element:nth-of-type(2) {
      ${'' /* margin-right: 0px; */}
      margin: 0 66px;
    }
  }
`;
