import styled from '@emotion/styled';

export const TopFoodImageContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 101;
  padding: 36px 26px 31px;
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .back-icon {
    cursor: pointer;
    width: 19px;
    height: 29px;
    padding-left: 7px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    i {
      font-size: 28px;
      letter-spacing: 0.53px;
      color: white;
    }
    p {
      font-size: 27px;
      font-weight: 500;
      color: #fff;
      margin: 0 0 0 90px;
      pointer-events: none;
    }
  }

  .top-food-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top-food-image {
      width: 100%;
      height: 160px;
      border-radius: 30px;
      background-position: center;
      background-size: cover;
      background-image: url(${({ backgroundImage }) => backgroundImage});
    }

    .top-food-title {
      margin-top: 30px;
      font-size: 20px;
      font-weight: bold;
      color: white;
      letter-spacing: -0.48px;
    }
  }
`;
