import styled from '@emotion/styled';

export const OrdersToHandleContainer = styled.div`
  /* max-width: 1074px; */
  display: flex;
  flex-direction: column;

  .main {
    display: flex;
    flex-wrap: wrap;
  }

  .order-to-handle-pagination {
    margin-left: auto;
    margin-right: auto;
  }
`;
