import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { FullRefundModalContainer } from './style';
import Button from 'react-bootstrap/Button';
import RequestPendingMock from '../../../MockData/MockRequestPending.json';
import { modalContext } from 'routes';
import { ReviewReturnFullrefund } from './ReviewReturnFullRefund';

export function FullRefundModal({ visible, onClose }) {
  const context = useContext(modalContext);
  const [Text, setText] = useState('');
  const [Percentage2, setPercentage2] = useState('');
  const [id, setId] = useState('');

  function ContinueBtn() {
    context.setModal('Return-Full-Refund-Modal-2');
  }

  function onAdd(element) {
    setText(element.text);
    setPercentage2(element.percentage2);
    setId(element.id);
  }

  return (
    <FullRefundModalContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      {/* partial refund */}
      {context.modal === 'Return-FullRefund-Modal-1' ? (
        <>
          <div className='return-fullrefund-wrapper animate__animated animate__fadeInUp'>
            <p className='return-fullrefund-text-style'>Full Refund</p>

            <div className='return-fullrefund-box-wrapper'>
              {RequestPendingMock.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item.partialRefundModalReturn
                      ? item.partialRefundModalReturn.map((element, index) => (
                          <React.Fragment key={index}>
                            <div
                              onClick={() => onAdd(element)}
                              className='return-fullrefund-border-box-1'
                              style={
                                element.id === id
                                  ? { background: '#ed2626' }
                                  : { background: 'none' }
                              }
                            >
                              <div className='return-fullrefund-percentage'>
                                {element.percentage}
                              </div>
                              <div className='return-fullrefund-dollar'>
                                {element.price}
                              </div>
                              <div>{element.text}</div>
                            </div>
                          </React.Fragment>
                        ))
                      : null}
                  </React.Fragment>
                );
              })}
            </div>

            {/* other div */}
            {Text === 'Other' ? (
              <div className='return-fullrefund-other-section-wrapper'>
                <div className='return-fullrefund-other-section-inner'>
                  <p className='return-fullrefund-other-text-style'>
                    Percentage
                  </p>
                  <p className='return-fullrefund-other-text-style'>
                    {Percentage2}
                  </p>
                </div>
                <div>
                  <p className='return-fullrefund-other-cost'>$2.29</p>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <p className='return-fullrefund-refund'>
              $2.29 will be refunded.
            </p>

            <div className='return-fullrefund-btn-center'>
              <Button
                variant='none'
                className='return-fullrefund-btn-style'
                onClick={ContinueBtn}
              >
                <span className='return-fullrefund-btn-text'>Continue</span>
              </Button>
            </div>

            <p
              className='return-fullrefund-cancel-text'
              onClick={onClose}
              aria-hidden='true'
            >
              Cancel
            </p>
          </div>
        </>
      ) : (
        <>
          <ReviewReturnFullrefund onClose={onClose} />
        </>
      )}
    </FullRefundModalContainer>
  );
}

FullRefundModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
