import styled from '@emotion/styled';
import { colors } from 'theme';

export const CardInputContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 8px;

  &.card-number {
    .card-input-container {
      .card-input-view {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  label {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.34px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 1px;
  }

  .card-input-container {
    width: 100%;
    display: flex;

    .card-input-view {
      width: 100%;
      background-color: transparent;
      outline: none;
      border-color: transparent;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.34px;
      color: white;
      padding-left: 0px;
      padding-bottom: 11px;
    }

    .card-input-icon {
      &.valid {
        i {
          color: ${colors.lightGreen};
        }
      }

      &.empty {
        i {
          color: ${colors.black1};
        }
      }

      &.error {
        i {
          color: ${colors.redColor};
        }
      }

      i {
        font-size: 14px;
      }
    }
  }
`;
