import React from 'react';
import PropTypes from 'prop-types';
import { DrawerCancelCheckInContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerCancelCheckIn({ visible, onClose, onCancel }) {

  const cancel = () => {
    onClose();
    onCancel();
  };

  return (
    <DrawerCancelCheckInContainer
      visible={visible}
      placement="bottom"
      onClose={onClose}
    >
      <SingleText size={22} spacing="0.42px" marginLeft={5} marginRight={5} marginBottom={10}>Cancel Check In</SingleText>
      <SingleText lineHeight="21px" spacing="0.27px" marginLeft={5} marginRight={5}>Are you sure you want to cancel Check In ?</SingleText>
      <SingleButton title="Cancel Check In" margin="52px 0px 20px 0px" onClick={cancel} />
      <SingleButton title="Go Back" btnColor={colors.lightGray} onClick={onClose} />
    </DrawerCancelCheckInContainer>
  );
}

DrawerCancelCheckIn.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func
};
