import styled from '@emotion/styled';
import { colors } from 'theme';

export const FloorDropdownContainer = styled.div`
  margin: 30px 24px 50px 24px;

  .dropdown {
    .dropdown-toggle {
      border-radius: 27px;
      background-color: #27282a;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      box-shadow: unset;
      border: unset;
      outline: unset;

      .left-part {
        &.active {
          i {
            color: ${colors.lightGreen};
          }
        }

        &.inactive {
          i {
            color: ${colors.gray9};
          }
        }

        i {
          font-size: 15px;
          color: #8f949a;
        }
        span {
          margin-left: 10px;
        }
      }

      .right-part {
        i {
          color: #5a5b5e;
        }
      }
    }

    .dropdown-toggle:after {
      display: none;
    }

    .dropdown-menu {
      width: 100%;
      border-radius: 25px;
      background-color: #27282a;

      .dropdown-item {
        &.have-tables {
          i {
            color: ${colors.lightGreen};
          }
        }

        &.empty {
          i {
            color: ${colors.gray9};
          }
        }

        &:hover {
          background-color: transparent;
        }

        i {
          font-size: 15px;
          color: #8f949a;
        }

        span {
          font-size: 14px;
          color: #ffffff;
          margin-left: 10px;
        }
      }
    }
  }
`;
