import styled from '@emotion/styled'
import { colors } from 'theme';

export const HighestRatedContainer = styled.div`
  margin-top: 66px;

  .ant-space {
    width: 100%;
    .ant-space-item {
      flex: 1;
      .staff-card {
        flex: 1;
        padding: 20px 0 38px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
        background-color: ${colors.gray2};
      }
    }
  }
  .staff-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
`;
