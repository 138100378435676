import styled from '@emotion/styled'
import { colors } from 'theme';

export const AllStaffContainer = styled.table`
    width: 100%;
  thead{
    color: rgba(255,255,255,0.3);
    tr{
      th{
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.5);
        padding: 10px 0;
        i{
          margin-left: 5px;
          font-weight: normal;
          color: rgba(255, 255, 255, 0.5);
        }
      }
      th:first-of-type{
        padding-left: 141px;
      }
      th:last-child{
        padding-right: 30px;
        text-align: end;
      }
    }
  }
  tbody{
    background-color: #1b1c1d;
    tr{
      cursor: pointer;

      &.inactive {
        td {
          opacity: 0.5;

          &:first-child {
            opacity: 1;
            position: relative;

            &:after {
              content: '';
              display: block;
              width: 4px;
              height: 53px;
              background-color: #727373;
              border-top-right-radius: 14px;
              border-bottom-right-radius: 14px;
              position: absolute;
              top: 18px;
              left: 0;
            }
          }

          .name-container {
            opacity: 0.5;
          }
        }
      }

      &.pending {
        td {
          &:first-child {
            position: relative;

            &:after {
              content: '';
              display: block;
              width: 4px;
              height: 53px;
              background-color: ${colors.lightOrange};
              border-top-right-radius: 14px;
              border-bottom-right-radius: 14px;
              position: absolute;
              top: 18px;
              left: 0;
            }
          }
        }
      }

      td{
        padding: 20px 0;
        // width: 12.5%;
        color: #fff;

        &:first-of-type {
          width: 25%;
        }

        &:nth-of-type(2) {
          width: 15%;
        }

        &:nth-of-type(3) {
          width: 12%;
        }

        &:nth-of-type(4) {
          width: 13%;
        }

        &:nth-of-type(5) {
          width: 13%;
        }

        &:nth-of-type(6) {
          width: 20%;
        }

        &.text-container-big{
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
        }
        &.text-container-normal{
          font-size: 14px;
          font-weight: normal;
          color: #ffffff;
        }
        .staff-avatar{
            width: 50px;
            height: 50px;
            border-radius: 49px;
        }

        .name-container {
          display: flex;
          align-items: center;

          .staff-avatar-title {
            font-size: 14px;
            font-weight: bold;
            color: white;
            margin-left: 61px;
          }
        }

        .status-title {
          font-size: 14px;

          &.active {
            color: ${colors.lightGreen};
          }

          &.pending {
            color: ${colors.lightOrange};

            .spinner-icon {
              margin-right: 3px;
            }
          }

          &.inactive {
            color: #727373;
          }
        }

        .ratings {
          display: flex;
          align-items: center;

          .ratings-icon {
            color: ${colors.rating};
          }
        }

      }
      td:first-of-type{
        padding-left: 30px;
      }
      td:last-child{
        padding-right: 40px;
        text-align: end;
      }
    }
    tr:first-of-type{
      td:first-of-type{
        border-top-left-radius: 8px;
      }
      td:last-child{
        border-top-right-radius: 8px;
      }
    }
    tr:last-child{
      td:first-of-type{
        border-bottom-left-radius: 8px;
      }
      td:last-child{
        border-bottom-right-radius: 8px;
      }
    }
    tr:nth-of-type(odd){
      td{
        background-color: #1b1c1d;
      }
    }
    tr:nth-of-type(even){
      td{
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
  }
`;

export const DropdownStaffMenu = styled.div`
  .popup-item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    span{
      margin-left: 20px !important;
    }
  }
  .popup-item:last-child{
    margin-bottom: 0;
  }
`;