import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerDeleteAccountContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  padding: 36px 26px 33px;
  height: 300px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
