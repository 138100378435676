import React from 'react';
import { PublishProfileContainer } from './style';
import { colors } from 'theme';
import { useWelcomeFlow } from 'hooks';
import SingleButton from 'components/SingleButton';
import { BeforePublishCheckList } from './BeforePublishCheckList';

export function PublishProfile() {
  const { isReadyToPublish, publishProfile } = useWelcomeFlow();

  const onClickPublishProfile = () => publishProfile();

  return (
    <PublishProfileContainer>
      <BeforePublishCheckList />
      <SingleButton
        title='Publish Profile'
        btnColor={
          isReadyToPublish() ? colors.redColor : 'rgba(255, 255, 255, 0.08)'
        }
        txtColor={isReadyToPublish() ? 'white' : 'rgba(255, 255, 255, 0.2)'}
        onClick={onClickPublishProfile}
        disabled={!isReadyToPublish()}
      />
    </PublishProfileContainer>
  );
}
