import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CompleteProfileContainer } from './style';
import { colors } from 'theme';
import {
  useBinaryState,
  useBackToLoginPageAndHistory,
  useUpdateMyAccount,
  useWelcomeFlow,
} from 'hooks';
import { AuthContext } from 'contexts/AuthContextContainer';
import { setJwt } from 'services/jwt';
import { showMessage } from 'services/functions';
import { setAuthHeader } from 'services/customAxios';
import { userServices } from 'services/api-services';
import { AuthLayout } from 'layouts/mainPage/components';
import SingleButton from 'components/SingleButton';
import { DropdownRole } from './DropdownRole';
import SingleAntImage from 'components/SingleAntImage';
import { SingleAvatarUploader } from 'components/SingleAvatarUploader';

import { SidebarLogo } from 'assets/images/logo';

export default function CompleteProfile() {
  const {
    setAuthUser,
    setRestaurantId,
    savedAuthDataForCompleteProfile,
    phoneNumberForCompleteProfile,
  } = useContext(AuthContext);

  const { user, changeFirstName, changeLastName, changeEmail, changeUser } =
    useUpdateMyAccount();

  const [avatar, setAvatar] = useState(null);

  const { isActive: validated, activate: markFormValid } = useBinaryState();

  const { backToLoginPage, history } = useBackToLoginPageAndHistory();

  const { getAllStatuses } = useWelcomeFlow();

  const onSubmitEventHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    checkFormValidityAndDecideWhatDoNext(form);
  };

  const checkFormValidityAndDecideWhatDoNext = (form) => {
    const isFormValid = form.checkValidity();
    if (isFormValid) {
      markFormValidAndSendForm();
    } else {
      warnUserHisEnteredDataIsWrong(form);
    }
  };

  const markFormValidAndSendForm = () => {
    markFormValid();
    submitCompletedProfile();
  };

  const warnUserHisEnteredDataIsWrong = (form) =>
    showMessageAboutInvalidFormField(form);

  const submitCompletedProfile = () => {
    const completedProfile = {
      ...user,
      avatar,
      registration_token: savedAuthDataForCompleteProfile.token,
      notification_push: false,
      notification_sms: false,
      notification_email: false,
      notification_promote: false,
    };

    sendCompletedProfile(completedProfile);
  };

  const sendCompletedProfile = async (completedProfile) => {
    setAuthHeader(savedAuthDataForCompleteProfile.token);

    return await userServices.updateProfile(
      completedProfile,
      (updatedToken) =>
        onOkAfterSendingCompletedProfile(updatedToken, completedProfile),
      onFailAfterSendingCompletedProfile
    );
  };

  const onOkAfterSendingCompletedProfile = (newToken, completedProfile) => {
    assignCompletedProfile(newToken, completedProfile);
    delayTransitionToNextPage();
  };

  const onFailAfterSendingCompletedProfile = (errMessage) =>
    showMessage(errMessage, 'error');

  const assignCompletedProfile = (newToken, completedProfile) => {
    setJwt(newToken);
    setAuthUser({
      ...completedProfile,
      registration_token: newToken,
      restaurant_id: savedAuthDataForCompleteProfile.restaurant_id,
      phone_number: phoneNumberForCompleteProfile,
      ...getAllStatuses(),
    });
    setRestaurantId(savedAuthDataForCompleteProfile.restaurant_id);
    setAuthHeader(newToken);
  };

  const DELAY_BEFORE_ENTERING_NEW_PAGE = 250;

  const delayTransitionToNextPage = () =>
    setTimeout(goToNextPage, DELAY_BEFORE_ENTERING_NEW_PAGE);

  const goToNextPage = () => {
    document.location.reload();
    history.push('/welcome');
  };

  const showMessageAboutInvalidFormField = (formNode) => {
    const firstMessage = readMessageAboutInvalidFormField(formNode);
    showMessage(firstMessage, 'error');
  };

  const readMessageAboutInvalidFormField = (formNode) => {
    const formChildren = [...formNode];
    const validationMessages = formChildren
      .map(({ validationMessage }) => validationMessage)
      .filter((message) => message.length);

    const firstMessage = validationMessages.shift();
    return firstMessage;
  };

  const isFormFieldsNotEmptyBeforeSubmit = () => {
    const { first_name, last_name, email, role_slug } = user;
    return (
      first_name &&
      first_name.trim().length &&
      last_name &&
      last_name.trim().length &&
      email &&
      email.trim().length &&
      role_slug &&
      role_slug.length &&
      role_slug !== 'none'
    );
  };

  const isNotEmptyFields = isFormFieldsNotEmptyBeforeSubmit();

  const onChangeFirstName = (event) =>
    changeFirstName(event.currentTarget.value);

  const onChangeLastName = (event) => changeLastName(event.currentTarget.value);

  const onChangeEmail = (event) => changeEmail(event.currentTarget.value);

  const onChangeRole = (newRole) => changeUser({ ...user, role_slug: newRole });

  const receiveImageForAvatar = (image) => setAvatar(image);

  return (
    <CompleteProfileContainer>
      <AuthLayout onBackIconClick={backToLoginPage}>
        <SingleAntImage
          src={SidebarLogo}
          alt='Logo'
          className='logo-img'
          width={171}
          height={50}
        />
        <span className='complete-your-profile-title'>
          Complete your profile
        </span>
        <Form
          noValidate
          validated={validated}
          className='form-container'
          onSubmit={onSubmitEventHandler}
        >
          <SingleAvatarUploader
            width='150px'
            height='150px'
            src={avatar}
            receiveImage={receiveImageForAvatar}
          />
          <div className='form-input-container'>
            {[
              [
                'formGridName',
                'First Name',
                'text',
                'Enter your name',
                'first_name',
                onChangeFirstName,
                'Required Field!',
              ],
              [
                'formGridLastName',
                'Last Name',
                'text',
                'Enter your last name',
                'last_name',
                onChangeLastName,
                'Required Field!',
              ],
              [
                'formGridEmail',
                'Email Address',
                'email',
                'Enter your email address',
                'email',
                onChangeEmail,
                'Please input your E-mail!',
              ],
            ].map(
              ([
                controlId,
                label,
                type,
                placeholder,
                key,
                onChange,
                validationMessage,
              ]) => (
                <Form.Group controlId={controlId} key={controlId}>
                  <Form.Label>{label}</Form.Label>
                  <Form.Control
                    required
                    autoComplete='off'
                    type={type}
                    placeholder={placeholder}
                    value={user[key]}
                    onChange={onChange}
                  />
                </Form.Group>
              )
            )}
            <Form.Group controlId='formGridRole'>
              <Form.Label>Role</Form.Label>
              <DropdownRole
                roleSlug={user.role_slug}
                onChangeRole={onChangeRole}
              />
            </Form.Group>
          </div>
          <SingleButton
            htmlType='submit'
            title='Continue'
            className='btn-continue'
            btnColor={
              isNotEmptyFields ? colors.redColor : 'rgba(255, 255, 255, 0.1)'
            }
            txtColor={isNotEmptyFields ? 'white' : 'rgba(255, 255, 255, 0.2)'}
            disabled={!isNotEmptyFields}
          />
        </Form>
      </AuthLayout>
    </CompleteProfileContainer>
  );
}
