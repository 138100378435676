import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerDeleteMenuContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  padding: 36px 26px 0px;

  .delete-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 31px;
  }
`;
