import styled from '@emotion/styled';

export const WelcomeDescriptionContainer = styled.div`
  width: 100%;
  margin-bottom: 33px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .welcome-description-text {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0;
    color: white;
    text-align: left;
  }
`;
