import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'components/Box';
import { Scrollbars } from 'react-custom-scrollbars';
import { FaChevronLeft, FaMinusCircle } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import ItemPicker from 'components/DrawerForm/ItemPicker';
import SingleButton from 'components/SingleButton';
import { DrawerNutritionContainer } from './style';
import { colors } from 'theme';
import SingleIconWrapper from 'components/SingleIconWrapper';
import DrawerResetNutrition from './DrawerResetNutrition';
import DrawerHideNutrition from './DrawerHideNutrition';

const nutritionProperties = [
  { name: 'Calories', placeholder: 'Add Calories', key: 'calories' },
  { name: 'Total Fat', placeholder: 'Add Total Fat', key: 'totalFat' },
  { name: 'Saturated Fat', placeholder: 'Add Saturated Fat', key: 'saturatedFat' },
  { name: 'Trans Fat', placeholder: 'Add Trans Fat', key: 'transFat' },
  { name: 'Cholesterol', placeholder: 'Add Cholesterol', key: 'cholesterol' },
  { name: 'Sodium', placeholder: 'Add Sodium', key: 'sodium' },
  { name: 'Total Carbohydrate', placeholder: 'Add Total Carbohydrate', key: 'totalCarbohydrate' },
  { name: 'Dietary Fiber', placeholder: 'Add Dietary Fiber', key: 'dietaryFiber' },
  { name: 'Total Sugars', placeholder: 'Add Total Sugars', key: 'totalSugars' },
  { name: 'Added Sugars', placeholder: 'Add Added Sugars', key: 'addedSugars' },
  { name: 'Protein', placeholder: 'Add Protein', key: 'protein' },
  { name: 'Vitamin A', placeholder: 'Add Vitamin A', key: 'vitaminA' },
  { name: 'Vitamin B', placeholder: 'Add Vitamin B', key: 'vitaminB' },
  { name: 'Vitamin C', placeholder: 'Add Vitamin C', key: 'vitaminC' },
  { name: 'Vitamin D', placeholder: 'Add Vitamin D', key: 'vitaminD' },
  { name: 'Vitamin E', placeholder: 'Add Vitamin E', key: 'vitaminE' },
  { name: 'Calcium', placeholder: 'Add Calcium', key: 'calcium' },
  { name: 'Iron', placeholder: 'Add Iron', key: 'iron' },
  { name: 'Potassium', placeholder: 'Add Potassium', key: 'potassium' },
]
export default function DrawerNutrition({
  visible = false,
  placement = 'right',
  value = {},
  onClose = () => { },
  onSubmit = () => { },
}) {


  const [openDrawerResetNutrition, setOpenDrawerResetNutrition] = useState(false);
  const [openDrawerHideNutrition, setOpenDrawerHideNutrition] = useState(false);

  const [selectedNutrition, setSelectedNutrition] = useState(value ?? {});
  const [hiddenNutrition, setHiddenNutrition] = useState([]);
  const [nutritionToHide, setNutritionToHide] = useState({});

  // const [expand, setExpand] = useState('');
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [subItems] = useState({});

  const onClickReset = () => setOpenDrawerResetNutrition(true);
  const onClickAddMenu = () => {
    onSubmit([{ ...selectedNutrition, subItems: { ...subItems } }]);
    onClose();
  }
  // const onClickHideNutrition = () => setOpenDrawerHideNutrition(true)

  function toggleNutritionHidden(key, name) {
    setNutritionToHide({ key, name });
    setOpenDrawerHideNutrition(true);
  }

  const onAddHiddenNutrition = (currentHiddenNutrition, hideNutrition) => {
    setHiddenNutrition([...currentHiddenNutrition, hideNutrition.key])
  };

  const onRemoveHiddenNutrition = (currentHiddenNutrition, hideNutrition) => {
    setHiddenNutrition([...currentHiddenNutrition.filter(x => x !== hideNutrition.key)]);
  };

  return (
    <DrawerNutritionContainer
      placement={placement}
      onClose={onClose}
      visible={visible}
    >
      <Box display='flex' alignItems='center' marginBottom={40} padding={24}>
        <SingleIconWrapper onClick={onClose}>
          <FaChevronLeft className='cursor' size={20} color={colors.white3} />
        </SingleIconWrapper>
        <Box display='flex' justifyContent='center' flex={1}>
          <SingleText value={selectedNutrition.nutrition} onChange={(e) => setSelectedNutrition({ ...selectedNutrition, nutrition: e.target.value })} content={`Nutrition`} align='center' size={22} weight={500}>Nutrition</SingleText>
        </Box>
      </Box>
      <Scrollbars autoHide style={{ flex: 1 }}>
        <Box className='drawer-menu--options'>

          {nutritionProperties.map((nutritionItem, i) => {
            if (
              (i <= 10 && !isMenuExpanded) ||
              (isMenuExpanded)
            ) {
              return (
                <Box key={`nutritionItem${nutritionItem.key}`} className='nutrition-item' display='flex' paddingTop={5} paddingLeft={5} paddingBottom={10} borderBottom={'1px solid rgba(255, 255, 255, 0.1)'}>
                  <Box className='nutrition-item-inner'>
                    <SingleText content={nutritionItem.name} size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>{nutritionItem.name}</SingleText>
                    <input
                      placeholder={nutritionItem.placeholder}
                      style={
                        hiddenNutrition.includes(nutritionItem.key)
                          ? {
                            display: 'block',
                            width: '100%',
                            backgroundColor: 'transparent',
                            border: 'unset',
                            outline: 'unset',
                            paddingLeft: 0,
                            paddingTop: 5,
                            color: 'rgba(255,255,255,0.3)'
                          }
                          : {
                            display: 'block',
                            width: '100%',
                            backgroundColor: 'transparent',
                            border: 'unset',
                            outline: 'unset',
                            paddingLeft: 0,
                            paddingTop: 5,
                            color: '#fff'
                          }
                      }
                      value={selectedNutrition[nutritionItem.key]}
                      onChange={(e) => setSelectedNutrition({ ...selectedNutrition, [nutritionItem.key]: e.target.value })}
                    />
                  </Box>
                  {selectedNutrition[nutritionItem.key] &&
                    <SingleIconWrapper height='auto' onClick={() => toggleNutritionHidden(nutritionItem.key, nutritionItem.name)}>
                      <FaMinusCircle className='cursor' size={20} color={hiddenNutrition.includes(nutritionItem.key) ? 'rgba(255,255,255,0.3)' : 'rgba(255,255,255,0.8)'} />
                    </SingleIconWrapper>}
                </Box>
              );
            }
            return '';
          })}

          <Box display='flex' className='expand-toggle cursor' padding='10px 0' justifyContent='center' onClick={() => setIsMenuExpanded(!isMenuExpanded)}>
            <SingleText content={`Show ${isMenuExpanded ? 'less' : 'more'}`} color={colors.redColor} weight={600} size={14} lineHeight={1.57} spacing='-0.34px'>{`Show ${isMenuExpanded ? 'less' : 'more'}`}</SingleText>
          </Box>
        </Box>
      </Scrollbars>
      <Box display='flex' flexDirection='column' marginTop='auto' padding={24}>
        <SingleButton
          title='Apply'
          margin='0 0 20px'
          onClick={onClickAddMenu}
        />
        <SingleButton
          title='Reset'
          btnColor='transparent'
          txtColor={colors.redColor}
          onClick={onClickReset}
        />
      </Box>
      <DrawerResetNutrition
        visible={openDrawerResetNutrition}
        onClose={() => setOpenDrawerResetNutrition(false)}
        clearHiddenNutrition={() => setHiddenNutrition([])}
        clearSelectedNutrition={() => setSelectedNutrition({})}
      />
      <DrawerHideNutrition
        visible={openDrawerHideNutrition}
        onClose={() => setOpenDrawerHideNutrition(false)}
        hiddenNutrition={hiddenNutrition}
        onAddHiddenNutrition={onAddHiddenNutrition}
        onRemoveHiddenNutrition={onRemoveHiddenNutrition}
        nutritionToHide={nutritionToHide}
        clearNutritionToHide={() => setNutritionToHide({})}
      />
    </DrawerNutritionContainer>
  )
}

ItemPicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onClickRemove: PropTypes.func,
};
ItemPicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onClickAdd: PropTypes.func,
};

DrawerNutrition.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  value: PropTypes.object,
};
