import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerDeleteTableContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  padding: 36px 31px 0px;

  &.drawer-prevent-hide {
    display: none;
  }
`;
