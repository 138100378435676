import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

import { OopsAddingFailed } from 'assets/images/menu';

export const DrawerAddBankAccountErrorContainer = styled(SingleDrawer)`
  height: 588px !important;
  padding: 97px 26px 33px;
  background-color: ${colors.gray5};
  display: flex;
  flex-direction: column;
  align-items: center;

  .fail-add-bank-account-image {
    width: 326px;
    height: 188px;
    margin: 0px 7px 61px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(${OopsAddingFailed});
  }
`;
