import { useContext, useState, useEffect } from 'react';
import { showMessage } from 'services/functions';
import { restaurantServices } from 'services/api-services';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';

export const useAllCategories = (
  onUserOk = () => {},
  onUserFail = () => {}
) => {
  const [isMadeQuery, setIsMadeQuery] = useState(false);

  const fetchAllCategories = async ({
    menus,
    allCategories,
    restaurantId,
    setAllCategories,
  }) => {
    setIsMadeQuery(true);

    const newAllCategories = [...allCategories];

    const onOk = (data) => {
      newAllCategories.push(data);
    };

    const onFail = (errMessage) => {
      showMessage(errMessage, 'error');
    };

    await Promise.all(
      menus.map(
        async ({ id: menuId }) =>
          await restaurantServices._getCategories(
            restaurantId,
            menuId,
            onOk,
            onFail
          )
      )
    );

    setAllCategories([
      {
        availability: [],
        categories: [
          { id: 1, name: 'Appetizers', items: [] },
          { id: 2, name: 'Soups', items: [] },
          { id: 3, name: 'Main Entrees', items: [] },
          { id: 4, name: 'Desserts', items: [] },
          { id: 5, name: 'Drinks', items: [] },
        ],
        description: null,
        id: 0,
        name: 'All',
        status: 1,
      },
      ...newAllCategories,
    ]);
  };

  const { restaurantId } = useContext(AuthContext);
  const { menus } = useContext(DataContext);
  const { allCategories, setAllCategories } = useContext(StateContext);

  useEffect(() => {
    if (menus.length !== 0 && allCategories.length !== 0 && isMadeQuery) {
      setIsMadeQuery(false);
    }
  }, [isMadeQuery, setIsMadeQuery, menus, allCategories]);

  return () => {
    if (!isMadeQuery) {
      fetchAllCategories({
        menus,
        allCategories,
        restaurantId,
        setAllCategories,
      });
    }
  };
};
