import { useState, useContext } from 'react';
import { Tables } from 'feasttt/domain';
import { restaurantServices } from 'services/api-services';
import { AuthContext } from 'contexts/AuthContextContainer';

export const useTable = () => {
  const { restaurantId } = useContext(AuthContext);
  const [shape, setShape] = useState();
  const [seats, setSeats] = useState(Tables.geometry.SEATS_MIN_LIMIT);

  async function addTables(
    status,
    _seats,
    floorDetails,
    selectedFloorId,
    onOk = () => {},
    onFail = () => {}
  ) {
    const shapeType = shape === 'circle' ? 0 : 1;
    const selectedFloorDetails = floorDetails.find(
      (x) => x.id === selectedFloorId
    );

    const { ok, errMessage } = await restaurantServices.updateFloor(
      { restaurantId, floorId: selectedFloorId },
      {
        ...selectedFloorDetails,
        tables: [
          ...selectedFloorDetails.tables,
          {
            x: 0,
            y: 0,
            rotation: 0,
            type: shapeType,
            seats: _seats,
            status,
          },
        ],
      }
    );
    if (ok) {
      onOk();
    } else {
      onFail(errMessage);
    }
  }

  async function editTable(
    getTableForEditTemp,
    selectedFloorId,
    floorDetails,
    onOk = () => {},
    onFail = () => {}
  ) {
    const shapeType = shape === 'circle' ? 0 : 1;
    const { id, x, y, rotation, status } = getTableForEditTemp;
    const selectedFloorDetails = floorDetails.find(
      (x) => x.id === selectedFloorId
    );

    return await restaurantServices._updateFloor(
      { restaurantId, floorId: selectedFloorId },
      {
        ...selectedFloorDetails,
        tables: [
          ...selectedFloorDetails.tables.filter(
            (x) => x.id !== getTableForEditTemp.id
          ),
          {
            ...getTableForEditTemp,
            id,
            x,
            y,
            rotation,
            type: shapeType,
            seats,
            status,
          },
        ],
      },
      onOk,
      onFail
    );
  }

  return {
    addTables,
    editTable,
    shape,
    setShape,
    seats,
    setSeats,
  };
};
