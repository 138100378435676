import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { TableListStyled } from './style';
import { DataContext } from 'contexts/DataContextContainer';
import SingleText from 'components/SingleText';
import Pagination from 'components/Pagination';

import { colors } from 'theme';

export default function TableList({ tables: mockedTables = [] }) {
  const { getTableList, setTableList, floorDetails } = useContext(DataContext);

  const [selectedFilter, setSelectedFilter] = useState('');
  const [sortType, setSortType] = useState('');
  const [paginatedList, setPaginatedList] = useState([]);

  const onChangePagination = (updatedPaginatedList) =>
    setPaginatedList(updatedPaginatedList);

  function toggleFilter(filterName) {
    if (selectedFilter === filterName) {
      setSortType(sortType === 'up' ? 'down' : 'up');
    } else {
      setSelectedFilter(filterName);
      setSortType('up');
    }
  }

  function sortTableData() {
    const newList = [...getTableList];
    if (selectedFilter) {
      newList.sort((a, b) => {
        if (a[selectedFilter] < b[selectedFilter]) {
          return sortType === 'up' ? -1 : 1;
        }
        if (a[selectedFilter] > b[selectedFilter]) {
          return sortType === 'up' ? 1 : -1;
        }
        return 0;
      });
    }
    return newList;
  }

  useEffect(() => {
    getTablesAll();
    // eslint-disable-next-line
  }, [floorDetails]);

  async function getTablesAll() {
    // mocked version of tables list
    const mixedRealTablesWithMocked = mockedTables.map((mockedTable) => {
      const allTablesFromFloors = floorDetails.reduce(
        (accumulator, current) => [...accumulator, ...current.tables],
        []
      );

      const findTable = allTablesFromFloors.find(
        ({ table_number }) => table_number === mockedTable.tableOrder
      );

      if (findTable) {
        return {
          ...findTable,
          tableOrder: findTable.table_number,
          name: `Table ${findTable.table_number}`,
        };
      } else {
        return mockedTable;
      }
    });

    setTableList(mixedRealTablesWithMocked);
    onChangePagination(mixedRealTablesWithMocked);

    // const { ok, data, errMessage } = await restaurantServices.getTablesList(restaurantId, {});
    // if (ok) {
    //   setTableList(data.tables)
    //   console.log('table list ', data.tables)
    // } else {
    //   showMessage(errMessage, 'error');
    // }
  }

  return (
    <>
      <Scrollbars>
        <TableListStyled>
          <thead>
            <tr>
              <th onClick={() => toggleFilter('tableOrder')}>
                Table{' '}
                {selectedFilter === 'tableOrder' &&
                  (sortType === 'up' ? (
                    <i className='fas fa-arrow-up' />
                  ) : (
                    <i className='fas fa-arrow-down' />
                  ))}
              </th>
              <th onClick={() => toggleFilter('status')}>
                Status{' '}
                {selectedFilter === 'status' &&
                  (sortType === 'up' ? (
                    <i className='fas fa-arrow-up' />
                  ) : (
                    <i className='fas fa-arrow-down' />
                  ))}
              </th>
              <th onClick={() => toggleFilter('order')}>
                Order #{' '}
                {selectedFilter === 'order' &&
                  (sortType === 'up' ? (
                    <i className='fas fa-arrow-up' />
                  ) : (
                    <i className='fas fa-arrow-down' />
                  ))}
              </th>
              <th onClick={() => toggleFilter('guests')}>
                Guests{' '}
                {selectedFilter === 'guests' &&
                  (sortType === 'up' ? (
                    <i className='fas fa-arrow-up' />
                  ) : (
                    <i className='fas fa-arrow-down' />
                  ))}
              </th>
              <th onClick={() => toggleFilter('itemsCount')}>
                Items{' '}
                {selectedFilter === 'itemsCount' &&
                  (sortType === 'up' ? (
                    <i className='fas fa-arrow-up' />
                  ) : (
                    <i className='fas fa-arrow-down' />
                  ))}
              </th>
              <th onClick={() => toggleFilter('orderTotal')}>
                Order Total{' '}
                {selectedFilter === 'orderTotal' &&
                  (sortType === 'up' ? (
                    <i className='fas fa-arrow-up' />
                  ) : (
                    <i className='fas fa-arrow-down' />
                  ))}
              </th>
              <th onClick={() => toggleFilter('server')}>
                Server{' '}
                {selectedFilter === 'server' &&
                  (sortType === 'up' ? (
                    <i className='fas fa-arrow-up' />
                  ) : (
                    <i className='fas fa-arrow-down' />
                  ))}
              </th>
              <th onClick={() => toggleFilter('floorOrder')}>
                Floor{' '}
                {selectedFilter === 'floorOrder' &&
                  (sortType === 'up' ? (
                    <i className='fas fa-arrow-up' />
                  ) : (
                    <i className='fas fa-arrow-down' />
                  ))}
              </th>
            </tr>
          </thead>
          <tbody>
            {filter(paginatedList, selectedFilter, sortType).map(
              (table, index) => {
                return (
                  <tr key={`trkeyintable${table.id + index}`}>
                    {/* @TODO JSON test */}
                    {/* <h6 className="text-white">{JSON.stringify(table)}</h6> */}

                    <td className='text-container'>{table.name}</td>

                    <td
                      className='table-status'
                      style={{
                        color:
                          table.status === 0
                            ? colors.gray
                            : table.is_available
                            ? colors.green
                            : colors.orange,
                      }}
                    >
                      {/* @TODO it is another logic for tables status */}

                      {/* {table.status === 0 &&
                        table.is_available === true &&
                        'Inactive'}
                      {table.status === 1 &&
                        table.is_available === true &&
                        'Available'}
                      {table.status === 1 &&
                        table.is_available === false &&
                        'Occupied'} */}

                      {table.status === 0
                        ? 'Inactive'
                        : table.is_available && table.status
                        ? 'Available'
                        : 'Occupied'}
                    </td>

                    <td>
                      <SingleText weight='bold' spacing='-0.34px'>
                        {table.order || '-'}
                      </SingleText>
                    </td>
                    <td>
                      <SingleText size={14} spacing='-0.34px'>
                        {!Number.isNaN(Number(table.guests)) && table.seats
                          ? `${table.guests}/${table.seats}`
                          : '-'}
                      </SingleText>
                    </td>
                    <td>
                      <SingleText size={14} spacing='-0.34px'>
                        {table.itemsCount ? table.itemsCount : '-'}
                      </SingleText>
                    </td>
                    <td>
                      <SingleText
                        content={
                          table.orderTotal ? `$${table.orderTotal}` : '-'
                        }
                        weight='bold'
                        size={14}
                        spacing='-0.34px'
                      >
                        {table.orderTotal ? `$${table.orderTotal}` : '-'}
                      </SingleText>
                    </td>
                    <td>
                      <SingleText size={14} spacing='-0.34px'>
                        {table.server ? table.server : '-'}
                      </SingleText>
                    </td>

                    <td>
                      <SingleText size={14} spacing='-0.34px'>
                        {/* {table.floor} */}
                        {/* tables floor updated*/}
                        {floorDetails.map(
                          (item, index) =>
                            item.id === table.floor_id && item.name
                        )}
                      </SingleText>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </TableListStyled>
      </Scrollbars>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/** @TODO Make pagination sorting */}
        <Pagination
          className='list-view-pagination'
          list={selectedFilter ? sortTableData() : getTableList}
          limitPerPage={13}
          onChange={onChangePagination}
        />
      </div>
    </>
  );
}

TableList.propTypes = {
  tables: PropTypes.array,
};

export const filter = (list, selectedFilter, sortType) => {
  if (selectedFilter && sortType) {
    let sortCallback = () => {};

    if (selectedFilter === 'status') {
      return filterStatus(list, sortType);
    }

    if (sortType === 'up') {
      // up
      sortCallback = (first, second) => {
        const firstValue = first[selectedFilter];
        const secondValue = second[selectedFilter];

        if (firstValue < secondValue) {
          return -1;
        } else if (firstValue > secondValue) {
          return 1;
        } else {
          return 0;
        }
      };
    } else {
      // down
      sortCallback = (first, second) => {
        const firstValue = first[selectedFilter];
        const secondValue = second[selectedFilter];

        if (firstValue < secondValue) {
          return 1;
        } else if (firstValue > secondValue) {
          return -1;
        } else {
          return 0;
        }
      };
    }

    return list
      .filter((listItem) => !!listItem[selectedFilter])
      .sort(sortCallback);
  } else {
    return list;
  }
};

// @TODO refactor
export const filterStatus = (list, sortType) => {
  const copyList = [...list].map(({ is_available, status }, index) =>
    status === '0'
      ? { value: 1, oldIndex: index }
      : is_available
      ? { value: 3, oldIndex: index }
      : { value: 2, oldIndex: index }
  );

  let sortCallback = () => {};

  if (sortType === 'up') {
    sortCallback = (first, second) => {
      const firstValue = first.value;
      const secondValue = second.value;

      if (firstValue < secondValue) {
        return -1;
      } else if (firstValue > secondValue) {
        return 1;
      } else {
        return 0;
      }
    };
  } else {
    // down
    sortCallback = (first, second) => {
      const firstValue = first.value;
      const secondValue = second.value;

      if (firstValue < secondValue) {
        return 1;
      } else if (firstValue > secondValue) {
        return -1;
      } else {
        return 0;
      }
    };
  }

  return copyList.sort(sortCallback).map(({ oldIndex }) => list[oldIndex]);
};
