import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RejectOtherDrinkPendingContainer } from './style';
import { RejectConfirmDrink } from './RejectConfirm';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';
import { RequestPendingContext } from 'contexts/RequestPendingContextContainer';

export function RejectOtherDrinkPending({ onClose, removePendingDrinkData }) {
  const context = useContext(modalContext);
  const {drinkOtherReasonInput, setDrinkOtherReasonInput} = useContext(RequestPendingContext)

  function RejectOtherDrinkFunc(e) {
    context.setModal('Reject-Drink-Modal-3');
    e.preventDefault();
    // its for test
    // console.log(drinkOtherReasonInput)
  }

  return (
    <RejectOtherDrinkPendingContainer>
      {context.modal === 'Reject-Drink-Modal-2' ? (
        <>
          <div className='reject-other-drink-wrapper animate__animated animate__fadeInUp'>
            <div className='reject-other-drink-padding-wrap-1'>
              <div>
                <p className='reject-other-drink-heading'>
                  Reason drink request <br />
                  can&apos;t be fulfilled.
                </p>
              </div>

              <div className='reject-other-drink-comment-flex'>
                <p className='reject-drink-comments'>Comments</p>
                <p className='reject-drink-optional'>Optional</p>
              </div>
            </div>

            <div>
              <div className='reject-drink-border-top-style'></div>
                <input
                  type='text'
                  placeholder='Describe why you are rejecting this request.'
                  className='reject-drink-input-style'
                  value={drinkOtherReasonInput}
                  onChange={(e) => setDrinkOtherReasonInput(e.target.value)}
                />
              <div className='reject-drink-border-top-style'></div>
            </div>

            <div className='reject-drink-padding-wrap-2'>
              <div>
                <Button
                  variant='none'
                  className='reject-other-drink-reject-request-btn'
                  onClick={RejectOtherDrinkFunc}
                >
                  Reject Request
                </Button>
              </div>

              <div>
                <Button
                  variant='none'
                  className='reject-drink-other-keep-btn'
                  onClick={onClose}
                >
                  Keep
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <RejectConfirmDrink onClose={onClose} removePendingDrinkData={removePendingDrinkData} />
      )}
    </RejectOtherDrinkPendingContainer>
  );
}

RejectOtherDrinkPending.propTypes = {
  onClose: PropTypes.func,
  removePendingDrinkData: PropTypes.func
};
