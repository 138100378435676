import styled from '@emotion/styled';
import { colors } from 'theme';

export const OrdersIconContainer = styled.div`
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
  color: white;
  padding: 4px 7.5px;
  border-radius: 5px;
  background-color: ${colors.redColor};
  position: absolute;
  right: 20px;
`;
