import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RejectRequestPendingDrawerContainer } from './style';
import { RejectOther } from './RejectOther';
import Button from 'react-bootstrap/Button';
import { modalContext, ScreensContext } from 'routes';
import { RejectedByMistakeRejectedAlert } from './RequestedByMistakeAlert';
import { UnreasonableRequestAlert } from './UnreasonableRequestAlert';
import { RequestPendingContext } from 'contexts/RequestPendingContextContainer';
import { requestsServices } from 'services/api-services';
import useLocalStorage from 'react-use-localstorage';
export function RejectRequestPendingDrawer({ visible, onClose, rejectValue }) {
  
  const [restaurantId, setRestaurantId] = useLocalStorage('feasttt-restaurant-id', '');
  const context = useContext(modalContext);
  const { selectedPendingData, setSelectedPendingData, pendingList, completedList, otherReasonInput } = useContext(RequestPendingContext);
  const { targetElement, setTargetElement } = useContext(ScreensContext);
  const [rejectReason, setRejectReason] = useState()
  const [requested, setRequested] = useState(false);
  const [unreasonable, setUnreasonable] = useState(false);
  const [other, setOther] = useState(false);
  const [val, setVal] = useState(false)

  useEffect(() => {}, [val])

  function handleContinueClick() {
    if (requested === true) {
      RequestedByMistakeFunc();
      removePendingData();
    } else if (unreasonable === true) {
      UnreasonableRequestFunc();
      removePendingData();  
    }
    else if (other === true) OtherFunc();
  }

  // remove return-request index
  function removePendingData() {
    let data = pendingList.map((items, index) => {
      if(items.id === selectedPendingData.id) {
          // @TODO its for test
          // console.log(items, 'item')
          // console.log(selectedPendingData, 'select pending data')
          // console.log(index, 'index')
          requestToCompletedFunc()
          completedList.push(items)
          pendingList.splice(index, 1)
          //@TODO:refresh functionality not yet
          // setSelectedPendingData('')
          setVal(!val)
        return index
      }
    })
   // it is for test
   console.log(data, 'data')
  }

  // pending to completed api
  const resId = restaurantId;
  const reqId = selectedPendingData.id;
  function requestToCompletedFunc() {
   const data = { done_status: rejectValue, reject_reason : rejectReason, reject_comment : otherReasonInput };
   requestsServices.requestToCompleted(resId, reqId, data)
  }

  //Requested by mistake option method
  function RequestedByMistakeFunc() {
    context.setModal('RequestedByMistakeModal-2');
  }

  //Unreasonable Request option method
  function UnreasonableRequestFunc() {
    context.setModal('UnreasonableModal-2');
  }

  // other option method
  function OtherFunc() {
    context.setModal('Modal-2');
  }

  return (
    <RejectRequestPendingDrawerContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      {context.modal === 'Modal-1' ? (
        <>
          <div className='reject-return-request-pending-wrapper animate__animated animate__fadeInUp'>
            <div>
              <p className='reject-return-request-pending-heading'>
                Reason return request <br />
                can't be fulfilled.
              </p>
            </div>

            <div className='reject-return-request-margin'>
              <div
                className='reject-return-request-pending-border'
                style={{
                  border:
                    targetElement === 'Requested by mistake'
                      ? '2px solid #ed2626'
                      : '1px solid #5b5e61',
                }}
                onClick={() => {
                  setRequested(true);
                  setUnreasonable(false);
                  setOther(false);
                  setTargetElement('Requested by mistake');
                  setRejectReason(3)
                  // RequestedByMistakeFunc();
                }}
              >
                Requested by mistake
              </div>

              <div
                className='reject-return-request-pending-border'
                style={{
                  border:
                    targetElement === 'Unreasonable Request'
                      ? '2px solid #ed2626'
                      : '1px solid #5b5e61',
                }}
                onClick={() => {
                  setRequested(false);
                  setUnreasonable(true);
                  setOther(false);
                  setTargetElement('Unreasonable Request');
                  setRejectReason(4)
                  // UnreasonableRequestFunc();
                }}
              >
                Unreasonable Request
              </div>

              <div
                className='reject-return-request-pending-border'
                style={{
                  border:
                    targetElement === 'Other'
                      ? '2px solid #ed2626'
                      : '1px solid #5b5e61',
                }}
                onClick={() => {
                  setRequested(false);
                  setUnreasonable(false);
                  setOther(true);
                  setTargetElement('Other');
                  setRejectReason(0)
                  // OtherFunc();
                }}
              >
                Other
              </div>
            </div>

            <div>
              <Button
                variant='none'
                className='reject-return-request-pending-continue-btn'
                // onClick={() => OtherFunc()}
                disabled={!(requested || unreasonable || other)}
                onClick={handleContinueClick}
              >
                Continue
              </Button>
            </div>

            <div>
              <p
                className='reject-return-request-pending-back'
                onClick={onClose}
              >
                Back
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <RejectOther onClose={onClose} removePendingData={removePendingData} />
          <RejectedByMistakeRejectedAlert onClose={onClose} />
          <UnreasonableRequestAlert onClose={onClose} />
        </>
      )}
    </RejectRequestPendingDrawerContainer>
  );
}

RejectRequestPendingDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  rejectValue: PropTypes.any
};
