import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { DrawerFoodReviewContainer } from './style';
import { useSortByTimeAndRating, optionsByTimeAndRating } from 'hooks';
import { TopFoodImage } from './TopFoodImage';
import { Food } from './Food';
import { TotalRating } from './TotalRating';
import { AllReviews } from './AllReviews';
import { DrawerSortContainer } from './DrawerSortContainer';
import { DefaultMenu } from 'assets/images/menu';
import { foodServices } from 'services/api-services';
import { DataContext } from 'contexts/DataContextContainer';
export function DrawerFoodReview({
  visible,
  onClose,
  totalReviews = undefined,
}) {
  
  const { selectedFood } = useContext(DataContext);

  const [foodReviews, setFoodReviews] = useState([])
  const { page, page_size } = foodReviews

  const { sortValue, onChangeSortValue } = useSortByTimeAndRating();

  const [ sortOptions, setSortOptions ] = useState(2)
 
  const [openDrawerSortContainer, setOpenDrawerSortContainer] = useState(false);

  const onOpenDrawerSortContainer = () => setOpenDrawerSortContainer(true);
  const onCloseDrawerSortContainer = () => setOpenDrawerSortContainer(false);

  let foodId = selectedFood.id;

  useEffect(() => {
    getFoodreview()
  }, [page, page_size, sortValue, sortOptions]);

  useEffect(() => {
    // @TODO its another logic for sort options

    // switch(sortValue){
    //   case 'Highest Rated' : setSortOptions(0); 
    //   break;
    //   case 'Lowest Rated' : setSortOptions(1); 
    //   break;
    //   case 'Earliest' : setSortOptions(2); 
    //   break;
    //   case 'Oldest' : setSortOptions(3); 
    //   break;
    //   default : setSortOptions(0)
    // }

    if(sortValue === 'Highest Rated') {
       setSortOptions(0)
    } else if(sortValue === 'Lowest Rated') {
      setSortOptions(1)
    } else if(sortValue === 'Earliest') {
      setSortOptions(2)
    } else if(sortValue === 'Oldest') {
      setSortOptions(3)
    }
  },[sortValue])
  

  const getFoodreview = (sort = sortOptions) => {
    foodServices
    .getFoodReviews(foodId, {
      page: page,
      page_size: page_size,
      sort: sort,
    })
    .then((res) => {
      setFoodReviews(res.data)
    })
    .catch((err) => err);
  } 

  return (
    <DrawerFoodReviewContainer
      backgroundImage={DefaultMenu}
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      <TopFoodImage onBack={onClose} />
      <Food />
      <TotalRating
        reviewsCount={totalReviews ? totalReviews : 12}
        sortValue={sortValue}
        onOpenDrawerSortContainer={onOpenDrawerSortContainer}
        totalRating={foodReviews.total}
      />
      <AllReviews reviews={sortReviews(sortValue)} reviewsData={foodReviews.reviews} />
      <DrawerSortContainer
        visible={openDrawerSortContainer}
        onClose={onCloseDrawerSortContainer}
        sortValue={sortValue}
        onChangeSortValue={onChangeSortValue}
      />
    </DrawerFoodReviewContainer>
  );
}

DrawerFoodReview.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  totalReviews: PropTypes.number,
};

export const sortReviews = (reviews, sortValue) => {
  if (sortValue) {
    let sortCallback = undefined;
    const [earliest, oldest, highestRated, lowestRated] =
      optionsByTimeAndRating;

    switch (sortValue) {
      case earliest:
        sortCallback = (firstReview, secondReview) => {
          const firstTime = firstReview.ago.value;
          const secondTime = secondReview.ago.value;

          if (firstTime > secondTime) {
            return -1;
          } else if (firstTime < secondTime) {
            return 1;
          } else {
            return 0;
          }
        };
        break;
      case oldest:
        sortCallback = (firstReview, secondReview) => {
          const firstTime = firstReview.ago.value;
          const secondTime = secondReview.ago.value;

          if (firstTime < secondTime) {
            return -1;
          } else if (firstTime > secondTime) {
            return 1;
          } else {
            return 0;
          }
        };
        break;
      case highestRated:
        sortCallback = (firstReview, secondReview) => {
          const firstRating = firstReview.rating;
          const secondRating = secondReview.rating;

          if (firstRating > secondRating) {
            return -1;
          } else if (firstRating < secondRating) {
            return 1;
          } else {
            return 0;
          }
        };
        break;
      case lowestRated:
        sortCallback = (firstReview, secondReview) => {
          const firstRating = firstReview.rating;
          const secondRating = secondReview.rating;

          if (firstRating < secondRating) {
            return -1;
          } else if (firstRating > secondRating) {
            return 1;
          } else {
            return 0;
          }
        };
        break;
      default:
        return reviews;
    }

    return reviews.sort(sortCallback);
  }

  return reviews;
};
