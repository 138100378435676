import customAxios from 'services/customAxios';

const searchFood = ({ lat, lon, query }) => customAxios.get(`/food/search?lat=${lat}&lon=${lon}&query=${query}`);
const getFoodDetails = async (foodId, onOk, onFail) => {
  const { ok, data, errMessage } = await customAxios.get(`/food/${foodId}`);
  if (ok) {
    onOk(data);
  } else {
    onFail(errMessage);
  }
};
const getFoodReviews = (id, { page, page_size, sort }) => customAxios.get(`/food/${id}/review?page=${page}&page_size=${page_size}&sort=${sort}`);
const updateFood = (restaurantId, foodId, payload) => customAxios.put(`/restaurant/${restaurantId}/food/${foodId}`, payload);

export const foodServices = {
  searchFood,
  updateFood,
  getFoodDetails,
  getFoodReviews,
};
