import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginContainer } from './style';
import { colors } from 'theme';
import { authServices } from 'services/api-services';
import { showMessage } from 'services/functions';
import { useBinaryState } from 'hooks';
import { AuthContext } from 'contexts/AuthContextContainer';
import { AuthLayout } from 'layouts/mainPage/components';
import SingleButton from 'components/SingleButton';
import SingleAntImage from 'components/SingleAntImage';
import SingleAntFormatInput from 'components/SingleAntFormatInput';

import { SidebarLogo } from 'assets/images/logo';
import { HelloIcon } from 'assets/images/auth';

export default function Login() {
  const history = useHistory();

  const {
    isActive: loading,
    activate: showLoader,
    deactivate: hideLoader,
  } = useBinaryState();

  const { changePhoneNumberForCompleteProfile } = useContext(AuthContext);

  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('1');
  const [phoneNumber, setPhoneNumber] = useState('1');

  const onChangePhoneNumber = (values) => {
    setPhoneNumber(values.value);
    setFormattedPhoneNumber(values.formattedValue);
  };

  const onClickContinue = (e) => {
    e.preventDefault()
    showLoader();
    tryLoginWithThisPhoneNumber();
  };

  const tryLoginWithThisPhoneNumber = async () =>
    await authServices.login(
      phoneNumber,
      onOkResponseAfterSendingPhoneNumber,
      onFailResponseAfterSendingPhoneNumber
    );

  const onOkResponseAfterSendingPhoneNumber = () => {
    hideLoader();
    changePhoneNumberForCompleteProfile(phoneNumber);
    history.push('phone-verify');
  };

  const onFailResponseAfterSendingPhoneNumber = (failResponseMessage) => {
    showMessage(failResponseMessage, 'error');
    hideLoader();
  };

  const whiteTextWhenCompletedOrGrayIfNot = () =>
    isCompleted() ? 'white' : 'rgba(255, 255, 255, 0.2)';
  const redButtonColorWhenCompletedOrGrayIfNot = () =>
    isCompleted() ? colors.redColor : 'rgba(255, 255, 255, 0.1)';

  const isCompleted = () => {
    return phoneNumber.length > 10;
  };

  return (
    <LoginContainer>
      <AuthLayout>
        <SingleAntImage
          src={SidebarLogo}
          alt='Logo'
          className='logo-img'
          width={171}
          height={50}
        />
        <img src={HelloIcon} className='hello-icon' alt='Welcome to Feasttt' />
        <span className='welcome-title'>Welcome to Feasttt!</span>
        <form className='login-from-core' onSubmit={(e) => onClickContinue(e)}>
          <span className='enter-label'>Enter your mobile number</span>
          <SingleAntFormatInput
            style={{ marginBottom: 17 }}
            value={formattedPhoneNumber}
            onChange={onChangePhoneNumber}
            placeholder='Enter your mobile phone'
          />
          <span className='do-not-worry-under-label'>
            If you don’t have any account, we’ll help you create one.
          </span>
          <SingleButton
            htmlType="submit"
            title='Continue'
            loading={loading}
            txtColor={whiteTextWhenCompletedOrGrayIfNot()}
            btnColor={redButtonColorWhenCompletedOrGrayIfNot()}
            disabled={!isCompleted()}
          />
        </form>
      </AuthLayout>
    </LoginContainer>
  );
}
