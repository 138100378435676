import styled from '@emotion/styled';
import SingleAntSelect from 'components/SingleAntSelect';
import { colors } from 'theme';

export const CategoriesSelectContainer = styled(SingleAntSelect)`
  &.categories-switcher-core {
    &.larger {
      .dropdown-content-menu {
        width: ${({ innerMenuWidth }) => 235 + innerMenuWidth}px;
      }
    }

    &.show {
      .dropdown-header-menu {
        .select-icon-container {
          i {
            transform: rotate(0deg);
          }
        }
      }
    }

    .dropdown-header-menu {
      border-color: transparent;
      background-color: ${colors.gray5};

      .option-label-item {
        font-size: 14px;
        color: white;

        .option-schedule {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.5);
          letter-spacing: 0;
          margin-left: 9px;
        }
      }

      .select-icon-container {
        display: flex;
        align-items: center;

        .light-indicator {
          margin-right: 11px;
          width: 11px;
          height: 11px;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 50%;

          &.active {
            background-color: ${colors.lightGreen};
          }
        }

        i {
          transform: rotate(90deg);
        }
      }
    }

    .dropdown-content-menu {
      top: 100% !important; 
      left: -97% !important; 
      transform: none !important; 
      border-color: transparent;
      background-color: ${colors.gray5}; 

      .option-label-item {
        position: relative;

        &.inactive {
          &:after {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }

        .option-container {
          position: relative;

          &.active {
            &:after {
              background-color: ${colors.lightGreen};
            }
          }

          &.inactive {
            &:after {
              background-color: rgba(255, 255, 255, 0.2);
            }
          }

          &:after {
            content: '';
            display: block;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            position: absolute;
            top: 5px;
            right: 0;
          }
        }

        .option-schedule {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.5);
          letter-spacing: 0;
          margin-left: 9px;
        }
      }
    }
  }
`;
