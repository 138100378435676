import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerProcessPaymentAreYouSureContainerStyled = styled(
  SingleDrawer
)`
  padding: 0;
  background-color: ${colors.gray2};
`;
