import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DrawerAddDayContainerWrapper } from './style';
import { DrawerPreventCreateDuplicates } from './DrawerPreventCreateDuplicates';
import { MonthCalendar } from './MonthCalendar';

// @TODO try to remade this component into class component
function DrawerAddDayContainer({
  visible = false,
  placement = 'bottom',
  onClose = () => {},
  closedDays = [],
  selectClosedDay,
  changeSelectClosedDay = () => {},
  clearSelectClosedDay = () => {},
  showDrawerAddDayNameContainer = () => {},
}) {
  const [
    openDrawerPreventCreateDuplicates,
    setOpenDrawerPreventCreateDuplicates,
  ] = useState(false);

  function selectDate() {
    if (selectClosedDay) {
      const allClosedDays = closedDays.map(
        ({ closed_date }) => new Date(closed_date)
      );

      if (isDuplicatesIsNotExists(selectClosedDay, allClosedDays)) {
        showDrawerAddDayNameContainer();
        onClose();
      } else {
        setOpenDrawerPreventCreateDuplicates(true);
      }
    }
  }

  const onChange = (date) => {
    changeSelectClosedDay(date);
  };

  function onCancel() {
    clearSelectClosedDay();
    onClose();
  }

  return (
    <DrawerAddDayContainerWrapper
      onClose={onClose}
      visible={visible}
      placement={placement}
      height='auto'
    >
      <div className={`edit-day-block-calendar`}>
        <div className='fixed-height-calendar'>
          <MonthCalendar
            visible={visible}
            closedDays={closedDays}
            controlDate={selectClosedDay}
            onChangeControlDate={onChange}
          />
        </div>
        <div style={{ padding: '0 30px' }}>
          <button
            className={`button-save ${
              !selectClosedDay ? 'inactive-button' : ''
            }`}
            onClick={() => (selectClosedDay ? selectDate() : null)}
          >
            Add
          </button>
          <button className='button-cancel' onClick={() => onCancel()}>
            Cancel
          </button>
        </div>
      </div>
      <DrawerPreventCreateDuplicates
        visible={openDrawerPreventCreateDuplicates}
        onClose={() => setOpenDrawerPreventCreateDuplicates(false)}
      />
    </DrawerAddDayContainerWrapper>
  );
}

export default DrawerAddDayContainer;

DrawerAddDayContainer.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  closedDays: PropTypes.array,
  selectClosedDay: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  changeSelectClosedDay: PropTypes.func,
  clearSelectClosedDay: PropTypes.func,
  showDrawerAddDayNameContainer: PropTypes.func,
};

export const isDuplicatesIsNotExists = (
  selectedDayByUser,
  availableClosedDays
) => {
  const selectedDay = selectedDayByUser.getDate();
  const selectedMonth = selectedDayByUser.getMonth() + 1;

  return !availableClosedDays
    .map((closedDay) => [closedDay.getDate(), closedDay.getMonth() + 1])
    .some(([day, month]) => day === selectedDay && month === selectedMonth);
};
