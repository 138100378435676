import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { DefaultUser } from 'assets/images/profile';
import { SideMenuContainer, ExpandableMenu } from './style';
import { SidebarLogo } from 'assets/images/logo';
import { StaffRoles } from 'feasttt/domain';
import SingleAntImage from 'components/SingleAntImage';
import SingleText from 'components/SingleText';
import Box from 'components/Box';
import { colors } from 'theme';
import { AuthContext } from 'contexts/AuthContextContainer';
import { RestaurantInfo } from './RestaurantInfo';
import { DrawerMyAccount } from './DrawerMyAccount';
import { OrdersIcon } from './OrdersIcon';
import { RequestsIcon } from './RequestsIcon';
import { requestsActive } from 'assets/images/sideMenu';
import { requestsInactive } from 'assets/images/sideMenu';
import { DataContext } from 'contexts/DataContextContainer';

// const { Panel } = Collapse;

function MainItem({ icon, rightIcon = '', title, link, active = false }) {
  return (
    <Link to={link}>
      <Box className='sidebar-item-wrapper'>
        <Box className={`sidebar-item ${active ? 'active' : 'inactive'}`}>
          <Box className='sidebar-item--icon'>{icon}</Box>
          <span className='sidebar-item--title'>{title}</span>
          {rightIcon}
        </Box>
      </Box>
    </Link>
  );
}

// eslint-disable-next-line react/no-multi-comp
function SubItem({ icon, title, link, active = false }) {
  return (
    <Link to={link}>
      <Box className={`sidebar-subitem ${active ? 'active' : 'inactive'}`}>
        <Box className='sidebar-subitem--icon'>{icon}</Box>
        <span className='sidebar-subitem--title'>{title}</span>
      </Box>
    </Link>
  );
}

// eslint-disable-next-line react/no-multi-comp
function SideMenu() {
  const [isMenuExpanded, setMenuExpanded] = useState(true);

  const [openDrawerMyAccount, setOpenDrawerMyAccount] = useState(false);

  const onOpenDrawerMyAccount = () => setOpenDrawerMyAccount(true);
  const onCloseDrawerMyAccount = () => setOpenDrawerMyAccount(false);

  const { pathname } = useLocation();
  const { authUser, restaurantId } = useContext(AuthContext);
  const { setMenusEditing } = useContext(DataContext);

  let activeItem = '';
  if (pathname.includes('dashboard')) {
    activeItem = 'dashboard';
  } else if (pathname.includes('orders')) {
    activeItem = 'orders';
  } else if (pathname.includes('requests')) {
    activeItem = 'requests';
  } else if (pathname.includes('activity')) {
    activeItem = 'activity';
  } else if (pathname.includes('tables')) {
    activeItem = 'tables';
  } else if (pathname.includes('manage')) {
    activeItem = 'edit_profile';
  } else if (pathname.includes('profile')) {
    activeItem = 'profile';
  } else if (pathname.includes('menu')) {
    activeItem = 'menu';
  } else if (pathname.includes('staff')) {
    activeItem = 'staff';
  } else if (pathname.includes('help')) {
    activeItem = 'help';
  }

  if (!pathname.includes('menu')) {
    setMenusEditing(false);
  }

  return (
    <SideMenuContainer>
      <div className='logo-area'>
        <div className='items-center logo-container'>
          <Link to='/dashboard'>
            <SingleAntImage
              src={SidebarLogo}
              alt='logo'
              width={135}
              height={40}
            />
          </Link>
        </div>
        <div className='divider' />
      </div>
      <RestaurantInfo restaurantId={restaurantId} />
      <Scrollbars autoHide style={{ flex: 1 }}>
        <Box className='sidebar-items'>
          <MainItem
            title='Dashboard'
            link='/dashboard'
            active={activeItem === 'dashboard'}
            icon={<i className='fas fa-store-alt' />}
          />
          <MainItem
            title='Orders'
            link='/orders'
            active={activeItem === 'orders'}
            icon={<i className='fas fa-file-invoice' />}
            rightIcon={<OrdersIcon />}
          />

          <MainItem
            title='Activity'
            link='/requests'
            active={activeItem === 'requests'}
            icon={
              activeItem === 'requests' ? (
                <img src={requestsActive} alt='' style={{ height: 16 }} />
              ) : (
                <img src={requestsInactive} alt='' style={{ height: 16 }} />
              )
            }
            rightIcon={<RequestsIcon />}
          />

          <ExpandableMenu
            active={
              activeItem === 'staff' ||
              activeItem === 'menu' ||
              activeItem === 'tables' ||
              activeItem === 'profile'
            }
          >
            <div
              className='expandable-title'
              onClick={() => setMenuExpanded(!isMenuExpanded)}
              aria-hidden='true'
            >
              <div className='left-part-title'>
                <span className='icon-container'>
                  <i className='fas fa-cog' />
                </span>
                <span className='title-text'>Manage</span>
              </div>
              <div className='right-part-title'>
                {isMenuExpanded ? (
                  <i className='fas fa-chevron-down' />
                ) : (
                  <i className='fas fa-chevron-right' />
                )}
              </div>
            </div>
            {isMenuExpanded ? (
              <div className='expandable-part'>
                <SubItem
                  title='Profile'
                  link='/profile'
                  active={activeItem === 'profile'}
                  icon={<i className='fas fa-store-alt' />}
                />
                <SubItem
                  title='Menu'
                  link='/menu'
                  active={activeItem === 'menu'}
                  icon={<i className='fas fa-utensils' />}
                />
                <SubItem
                  title='Tables'
                  link='/manage/tables'
                  active={activeItem === 'tables'}
                  icon={<i className='fas fa-utensils' />}
                />
                <SubItem
                  title='Staff'
                  link='/staff'
                  active={activeItem === 'staff'}
                  icon={<i className='fas fa-users' />}
                />
                <SubItem
                  title='Payments'
                  link='/menu'
                  active={activeItem === 'payments'}
                  icon={<i className='fas fa-money-check' />}
                />
                <SubItem
                  title='Settings'
                  link='/menu'
                  active={activeItem === 'settings'}
                  icon={<i className='fas fa-cog' />}
                />
                <SubItem
                  title='Bank Account'
                  link='/bank-account'
                  active={pathname.includes('bank-account')}
                  icon={<i className='fas fa-university' />}
                />
              </div>
            ) : (
              ''
            )}
          </ExpandableMenu>
          <Box
            height={1}
            backgroundColor={colors.border}
            marginX={20}
            marginBottom={20}
          />
          <MainItem
            title='Help'
            link='#'
            icon={<i className='fas fa-life-ring' />}
          />
        </Box>
      </Scrollbars>
      <Box className='sidebar-bottom' onClick={onOpenDrawerMyAccount}>
        <Box display='flex' flexDirection='column' alignItems='flex-start'>
          <SingleText
            family='SFProText'
            size={13}
            weight={600}
            spacing='-0.31px'
            marginBottom={5}
          >
            {authUser.first_name} {authUser.last_name}
          </SingleText>
          <SingleText
            family='SFProText'
            size={13}
            spacing='-0.31px'
            opacity={0.5}
          >
            {StaffRoles.readRole(authUser.role_slug)}
          </SingleText>
        </Box>
        <Box width={50} height={50}>
          <img
            src={authUser.avatar ? authUser.avatar.file : DefaultUser}
            alt='avatar'
            size={50}
            shape='circle'
            className='user-photo cursor'
          />
        </Box>
      </Box>
      <DrawerMyAccount
        visible={openDrawerMyAccount}
        onClose={onCloseDrawerMyAccount}
      />
    </SideMenuContainer>
  );
}

MainItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  rightIcon: PropTypes.object,
  link: PropTypes.string,
  active: PropTypes.bool,
};
SubItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  link: PropTypes.string,
  active: PropTypes.bool,
};

export default SideMenu;
