import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RowCategoriesControlContainer } from './style';
import { StateContext } from 'contexts/StateContextContainer';

export function RowCategoriesControl() {
  const [userRowCategory, setUserRowCategory] = useState(undefined);
  const changeUserRowCategory = (value) => setUserRowCategory(value);

  const { categories } = useContext(StateContext);

  useEffect(() => {
    if (categories.length) {
      setUserRowCategory([...categories].shift().id);
    }
  }, [categories]);

  useEffect(() => {
    if (
      userRowCategory &&
      categories.length &&
      userRowCategory !== [...categories].shift().id
    ) {
      scrollTo(userRowCategory, categories);
    }
  }, [userRowCategory, categories]);

  return (
    <RowCategoriesControlContainer>
      {categories.map((rowCategory, key) => (
        <div
          className={`row-category-container ${
            rowCategory.id === userRowCategory ? 'active' : ''
          }`}
          onClick={() => changeUserRowCategory(rowCategory.id)}
          key={key}
        >
          <span className='row-category-name-title'>{rowCategory.name}</span>
        </div>
      ))}
    </RowCategoriesControlContainer>
  );
}

RowCategoriesControl.propTypes = {
  currentRowCategory: PropTypes.any,
  onChangeRowCategories: PropTypes.func,
};

/**
 * @returns {{ title: string, element: Element }[]}
 */
export const findElementsValues = () => {
  const allCategoriesElements = [
    ...document.querySelectorAll('.available-menu-category'),
  ].map((element) => {
    const [menuCategoryTitleContainer] = [...element.children];
    const [menuCategoryTitle] = [...menuCategoryTitleContainer.children];

    return {
      title: menuCategoryTitle.innerText,
      element,
    };
  });

  return allCategoriesElements;
};

export const findUserRowCategory = (userRowCategory, categories) =>
  categories.find(({ id }) => id === userRowCategory);

export const scrollTo = (userRowCategory, availableCategories) => {
  const findCategory = findUserRowCategory(
    userRowCategory,
    availableCategories
  );
  if (userRowCategory && findCategory) {
    const values = findElementsValues();

    const scrollToElement = values.find(
      ({ title }) => findCategory.name === title
    );

    if (scrollToElement) {
      scrollToElement.element.scrollIntoView();
    }
  }
};
