import React from 'react';
import PropTypes from 'prop-types';

export function DayContainer({
  onClick = () => {},
  children,
  selectedTime,
  placeholder = 'Set time',
}) {
  return (
    <div className='day-of-week-container' onClick={onClick}>
      <div className='left-part'>
        <p className='day-of-week'>{children}</p>
        <p className={`set-time ${selectedTime ? 'selection-done' : ''}`}>
          {selectedTime ? selectedTime : placeholder}
        </p>
      </div>
      <div className='right-part'>
        <i className='fas fa-angle-right' />
      </div>
    </div>
  );
}

DayContainer.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  selectedTime: PropTypes.any,
  placeholder: PropTypes.string,
};
