import React, { useContext } from 'react'
import PropTypes from 'prop-types';
import { DefaultUser } from 'assets/images/profile';
import { AuthContext } from 'contexts/AuthContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
// import { StateContext } from 'contexts/StateContextContainer';
// import { colors } from 'theme';
import { DrawerCheckInCompletedWrapper } from './style';

export default function DrawerCheckInCompleted({
  visible = false,
  placement = 'bottom',
  onClose = () => { },
}) {
  const { authUser } = useContext(AuthContext);
  const { checkInData, setCheckInData } = useContext(DataContext);
  // const { openDrawerCheckInCompleted, setOpenDrawerCheckInCompleted } = useContext(StateContext);

  function closeDrawer() {
    setCheckInData()
    onClose();
  }

  return (
    <DrawerCheckInCompletedWrapper
      placement={placement}
      onClose={closeDrawer}
      height='fit-content'
      visible={visible}
    >
      <div className="check-in-completed">
        <div className="image-container">
          <img src={authUser.avatar ? authUser.avatar.file : DefaultUser} alt='avatar' />
        </div>
        <div className="completed-message">
          <i className="fas fa-check" />
          <h3>Checked In</h3>
        </div>
        <p className="details-message">
          {checkInData?.server?.name} is assigned to {`Table ${checkInData?.table?.table_number}`}.
        </p>
        <button type="button" className="completed-message-button" onClick={() => closeDrawer()}>
          Okay
        </button>
      </div>
    </DrawerCheckInCompletedWrapper>
  )
}

DrawerCheckInCompleted.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickDeleteStaff: PropTypes.func,
};
