import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
// import './style.scss';
import Box from 'components/Box';
import { FaChevronLeft } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerStatusContainer } from './style';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { colors } from 'theme';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';

export default function DrawerStatus({
  visible = false,
  placement = 'right',
  onClose = () => { },
}) {

  const { setOpenDrawerStatus } = useContext(StateContext);
  const { chosenStaff, setChosenStaff } = useContext(DataContext);
  const [initialStatus, setInitialStatus] = useState(undefined);

  const onClickDoneStatus = () => {
    setOpenDrawerStatus(false);
  }

  function setActive(statusId) {
    setChosenStaff({ ...chosenStaff, status: statusId });
  }

  const close = () => {
    setChosenStaff({ ...chosenStaff, status: initialStatus });
    onClose();
  };

  useEffect(() => {
    if (visible && initialStatus === undefined) {
      setInitialStatus(chosenStaff.status);
    } else if (!visible) {
      setInitialStatus(undefined);
    }
  }, [visible, initialStatus, chosenStaff]);

  return (
    <DrawerStatusContainer
      onClose={close}
      visible={visible}
      placement={placement}
    // active={active}
    >
      <Box is='div' display='flex' alignItems='center' marginBottom={40}>
        <SingleIconWrapper onClick={close}>
          <FaChevronLeft size={20} color='white' />
        </SingleIconWrapper>
        <Box is='div' display='flex' justifyContent='center' flex={1}>
          <SingleText content='Status' align='center' size={22} weight={500}>Status</SingleText>
        </Box>
      </Box>
      <Box is='div' className='drawer-status--options'>
        <Box is='div' className={`drawer-status--option ${chosenStaff.status === 1 ? 'active' : ''}`} marginBottom={15} onClick={() => setActive(1)}>
          <SingleText content='Active' size={14} weight={500} spacing='-0.34'>Active</SingleText>
        </Box>
        <Box is='div' className={`drawer-status--option ${chosenStaff.status === 0 ? 'active' : ''}`} onClick={() => setActive(0)}>
          <SingleText content='Inactive' size={14} weight={500} spacing='-0.34'>Inactive</SingleText>
          <SingleText content='Not visible to customers' size={14} opacity={0.5} weight={500} spacing='-0.34'>Not visible to customers</SingleText>
        </Box>
      </Box>
      <Box is='div' display='flex' flexDirection='column' marginTop='auto'>
        <SingleButton
          title='Done'
          btnColor={colors.redColor}
          txtColor='white'
          onClick={onClickDoneStatus}
        />
      </Box>
    </DrawerStatusContainer>
  )
}

DrawerStatus.propTypes = {
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickDay: PropTypes.func,
  onClickDoneStatus: PropTypes.func,
};
