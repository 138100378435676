import styled from '@emotion/styled';
import { colors } from 'theme';

export const SingleSwitchContainer = styled.div`
  width: 46px;
  height: 24px;
  border-radius: 19px;
  background-color: rgba(145, 148, 160, 0.4);
  position: relative;
  cursor: pointer;
  .switch-handle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ffffff;
    position: absolute;
    top: 2px;
    left: 2px;
    transition-property: left;
    transition-duration: 0.3s;
  }
  &.active-button {
    background-color: ${colors.redColor};
    .switch-handle {
      left: 23px;
    }
  }

  &.disabled {
    background-color: ${colors.gray13};
    .switch-handle {
      background-color: ${colors.gray14};
    }
  }
`;
