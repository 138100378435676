import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerRemoveWallContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  padding: 0px;

  .drawer-remove-wall-top {
    text-align: center;
    padding-top: 37px;
    z-index: 1000;
  }
`;
