import React from 'react';
import PropTypes from 'prop-types';
import { DrawerCancelCheckInContainerStyled } from './style';
import { DrawerCancelCheckIn } from './DrawerCancelCheckIn';

export function DrawerCancelCheckInContainer({ visible, onClose = () => {}, onCancel = () => {} }) {
  return (
    <DrawerCancelCheckInContainerStyled
      visible={visible}
      placement="right"
      onClose={onClose}
    >
      <DrawerCancelCheckIn visible={true} onClose={onClose} onCancel={onCancel} />
    </DrawerCancelCheckInContainerStyled>
  );
}

DrawerCancelCheckInContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func
};
