import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { RejectDrinkRequestPendingDrawerContainer } from './style';
import { RejectOtherDrinkPending } from './RejectOther';
import Button from 'react-bootstrap/Button';
import { modalContext, ScreensContext } from 'routes';
import { OutOfStockAlert } from './OutofStock';
import { BarClosedAlert } from './BarClosed';
import { DrinkRequestedByMistakeAlert } from './RequestedByMistake';
import { requestsServices } from 'services/api-services';
import { RequestPendingContext } from 'contexts/RequestPendingContextContainer';
import useLocalStorage from 'react-use-localstorage';
export function RejectDrinkRequestPendingDrawer({ visible, onClose, rejectValueDrinkRefill }) {
  const context = useContext(modalContext);
  const { targetElement, setTargetElement } = useContext(ScreensContext);
  // state var "targetElement" - in variable is getting select options value.

  const {
    selectedPendingData,
    pendingList,
    completedList,
    rejectDrinkRefresh,
    setRejectDrinkRefresh,
    drinkOtherReasonInput,
    setSelectedPendingData,
  } = useContext(RequestPendingContext);

  const [restaurantId, setRestaurantId] = useLocalStorage('feasttt-restaurant-id', '');
  const [outOfStock, setOutofstock] = useState(false);
  const [barClosed, setBarClosed] = useState(false);
  const [requestedByMistake, setRequestedByMistake] = useState(false);
  const [other, setOther] = useState(false);
  const [rejectReasonDrink, setRejectReasonDrink] = useState()

  function handleContinueClick() {
    if (outOfStock === true) {
      OutOfStockModalAlert();
      removePendingDrinkData();
    } else if (barClosed === true){
      BarClosedModalAlert()
      removePendingDrinkData();
    }
    else if (requestedByMistake === true){
      DrinkRequestedByMistakeModalAlert()
      removePendingDrinkData();
    }
    else if (other === true) rejectDrinkDrawerFunc();
  }

  // other method
  function rejectDrinkDrawerFunc() {
    context.setModal('Reject-Drink-Modal-2');
  }

  // out of stock method
  function OutOfStockModalAlert() {
    context.setModal('Reject-Drink-OutOfStock-Modal-2');
  }

  // bar closed stock method
  function BarClosedModalAlert() {
    context.setModal('Reject-Drink-BarClosed-Modal-3');
  }

  // drink requested by mistake method
  function DrinkRequestedByMistakeModalAlert() {
    context.setModal('Drink-Request-By-Mistake-Modal-4');
  }

  // remove drink-refill-request index
  function removePendingDrinkData() {
    let data = pendingList.map((items, index) => {
      if(items.id === selectedPendingData.id) {
          requestToCompletedDrinkFunc()
          completedList.push(items)
          pendingList.splice(index, 1)
        return index
      }
    })
   // its log for test
   console.log(data, 'data-drink-refill')
  }

  // pending to completed drink-refill api
  const resId = restaurantId;
  const reqId = selectedPendingData.id;
  function requestToCompletedDrinkFunc() {
   const data = { done_status: rejectValueDrinkRefill, reject_reason : rejectReasonDrink, reject_comment : drinkOtherReasonInput };
   requestsServices.requestToCompleted(resId, reqId, data)
   .then((res) => {
      // test
      // console.log(res.data)
      setRejectDrinkRefresh(!rejectDrinkRefresh)
      // setSelectedPendingData({})
   })
   .catch((err) => {
      // test
      // console.log(err)
   })
  }

  return (
    <RejectDrinkRequestPendingDrawerContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      {context.modal === 'Reject-Drink-Modal-1' ? (
        <>
          <div className='reject-drink-pending-wrapper animate__animated animate__fadeInUp'>
            <div>
              <p className='reject-drink-request-pending-heading'>
                Reason drink request <br />
                can&apos;t be fulfilled.
              </p>
            </div>

            <div className='reject-drink-request-margin'>
              <div
                className='reject-drink-request-pending-border'
                style={{
                  border:
                    targetElement === 'Out of Stock'
                      ? '2px solid #ed2626'
                      : '1px solid #5b5e61',
                }}
                onClick={() => {
                  // OutOfStockModalAlert();
                  setOutofstock(true);
                  setBarClosed(false);
                  setRequestedByMistake(false);
                  setOther(false);
                  setTargetElement('Out of Stock');
                  setRejectReasonDrink(1)
                }}
                aria-hidden='true'
              >
                Out of Stock
              </div>

              <div
                className='reject-drink-request-pending-border'
                style={{
                  border:
                    targetElement === 'Bar Closed'
                      ? '2px solid #ed2626'
                      : '1px solid #5b5e61',
                }}
                onClick={() => {
                  // BarClosedModalAlert();
                  setOutofstock(false);
                  setBarClosed(true);
                  setRequestedByMistake(false);
                  setOther(false);
                  setTargetElement('Bar Closed');
                  setRejectReasonDrink(2)
                }}
                aria-hidden='true'
              >
                Bar Closed
              </div>

              <div
                className='reject-drink-request-pending-border'
                style={{
                  border:
                    targetElement === 'Requested by mistake'
                      ? '2px solid #ed2626'
                      : '1px solid #5b5e61',
                }}
                onClick={() => {
                  // DrinkRequestedByMistakeModalAlert();
                  setOutofstock(false);
                  setBarClosed(false);
                  setRequestedByMistake(true);
                  setOther(false);
                  setTargetElement('Requested by mistake');
                  setRejectReasonDrink(3)
                }}
                aria-hidden='true'
              >
                Requested by mistake
              </div>

              <div
                className='reject-drink-request-pending-border'
                style={{
                  border:
                    targetElement === 'Other'
                      ? '2px solid #ed2626'
                      : '1px solid #5b5e61',
                }}
                onClick={() => {
                  // rejectDrinkDrawerFunc();
                  setOutofstock(false);
                  setBarClosed(false);
                  setRequestedByMistake(false);
                  setOther(true);
                  setTargetElement('Other');
                  setRejectReasonDrink(0)
                }}
                aria-hidden='true'
              >
                Other
              </div>
            </div>

            <div>
              <Button
                variant='none'
                className='reject-drink-request-pending-continue-btn'
                disabled={
                  !(outOfStock || barClosed || requestedByMistake || other)
                }
                onClick={handleContinueClick}
              >
                Continue
              </Button>
            </div>

            <div>
              <p
                className='reject-drink-request-pending-back'
                onClick={onClose}
                aria-hidden='true'
              >
                Back
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <RejectOtherDrinkPending onClose={onClose} removePendingDrinkData={removePendingDrinkData} />
          <OutOfStockAlert onClose={onClose} />
          <BarClosedAlert onClose={onClose} />
          <DrinkRequestedByMistakeAlert onClose={onClose} />
        </>
      )}
    </RejectDrinkRequestPendingDrawerContainer>
  );
}

RejectDrinkRequestPendingDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  rejectValueDrinkRefill: PropTypes.any
};
