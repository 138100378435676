import React from 'react';
import PropTypes from 'prop-types';
import { PeriodCalendarContainer } from './style';
import SingleMonthCalendar from 'components/SingleMonthCalendar';

export function PeriodCalendar({
  onRangeClick = () => {},
  isInRange = () => {},
  isRangeActive = false,
}) {
  return (
    <PeriodCalendarContainer>
      <SingleMonthCalendar
        className='period-calendar'
        visible={true}
        onClick={onRangeClick}
        isInRange={isInRange}
        isRangeActive={isRangeActive}
      />
    </PeriodCalendarContainer>
  );
}

PeriodCalendar.propTypes = {
  onRangeClick: PropTypes.func,
  isInRange: PropTypes.func,
  isRangeActive: PropTypes.bool,
};
