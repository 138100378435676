import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerBankAccountContainer } from './style';
import { BankAccountTopDropdown } from './BankAccountTopDropdown';
import { PersonalCheckingContainer } from './PersonalCheckingContainer';
import { DrawerAddBankAccountContainer } from './DrawerAddBankAccountContainer';
import { DrawerBankAccountRemoveContainer } from './DrawerBankAccountRemoveContainer';
import { DrawerBankAccountReplaceContainer } from './DrawerBankAccountReplaceContainer';

export function DrawerBankAccount({
  visible,
  onClose,
  currentBankAccount,
  addBankAccount,
  clearBankAccount,
  isBankAccountEmpty,
}) {
  const [
    openDrawerBankAccountReplaceContainer,
    setOpenDrawerBankAccountReplaceContainer,
  ] = useState(false);

  // WARNING - THIS IS TEMPORARY FEATURE - IT WILL BE REPLACED LATER
  // **
  const [emptyState, setEmptyState] = useState(false);

  useEffect(() => {
    if (visible) {
      setEmptyState(isBankAccountEmpty);
    }
  }, [visible, isBankAccountEmpty]);

  // **

  const onOpenDrawerBankAccountReplaceContainer = () =>
    setOpenDrawerBankAccountReplaceContainer(true);

  const onCloseDrawerBankAccountReplaceContainer = () =>
    setOpenDrawerBankAccountReplaceContainer(false);

  const [
    openDrawerBankAccountRemoveContainer,
    setOpenDrawerBankAccountRemoveContainer,
  ] = useState(false);
  const onOpenDrawerBankAccountRemoveContainer = () =>
    setOpenDrawerBankAccountRemoveContainer(true);

  const onCloseDrawerBankAccountRemoveContainer = () =>
    setOpenDrawerBankAccountRemoveContainer(false);

  const [
    openDrawerAddBankAccountContainer,
    setOpenDrawerAddBankAccountContainer,
  ] = useState(false);
  const onOpenDrawerAddBankAccountContainer = () =>
    setOpenDrawerAddBankAccountContainer(true);
  const onCloseDrawerAddBankAccountContainer = () =>
    setOpenDrawerAddBankAccountContainer(false);

  const onReplace = () => {
    clearBankAccount();
    onOpenDrawerAddBankAccountContainer();
  };

  return (
    <DrawerBankAccountContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      <div className='drawer-bank-account-top'>
        <div className='top-control-part'>
          <div className='close-icon' onClick={onClose}>
            <i className='fas fa-chevron-left' />
          </div>
          <div className='top-title-container'>
            <span className='top-title'>Bank Account</span>
          </div>
          {!emptyState ? (
            <BankAccountTopDropdown
              onReplace={onOpenDrawerBankAccountReplaceContainer}
              onRemove={onOpenDrawerBankAccountRemoveContainer}
            />
          ) : (
            <div />
          )}
        </div>
      </div>
      <PersonalCheckingContainer
        currentBankAccount={currentBankAccount}
        onAddBankAccount={onOpenDrawerAddBankAccountContainer}
        onClose={onClose}
        isEmptyState={emptyState}
      />
      <DrawerAddBankAccountContainer
        visible={openDrawerAddBankAccountContainer}
        onClose={onCloseDrawerAddBankAccountContainer}
        addBankAccount={addBankAccount}
      />
      <DrawerBankAccountReplaceContainer
        visible={openDrawerBankAccountReplaceContainer}
        onClose={onCloseDrawerBankAccountReplaceContainer}
        onReplace={onReplace}
      />
      <DrawerBankAccountRemoveContainer
        visible={openDrawerBankAccountRemoveContainer}
        onClose={onCloseDrawerBankAccountRemoveContainer}
        clearBankAccount={clearBankAccount}
      />
    </DrawerBankAccountContainer>
  );
}

DrawerBankAccount.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  currentBankAccount: PropTypes.object,
  addBankAccount: PropTypes.func,
  clearBankAccount: PropTypes.func,
  isBankAccountEmpty: PropTypes.bool,
};
