import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { MoreSelectContainer } from './style';
import { showMessage } from 'services/functions';
import { PendingOrdersContext } from 'contexts/PendingOrdersContextContainer';
import { ActiveOrdersContext } from 'contexts/ActiveOrdersContextContainer';
import { CompletedOrdersContext } from 'contexts/CompletedOrdersContextContainer';

export function MoreSelect({
  selectedPage,
  userOption,
  changeUserOption,
  onOpenDrawerChangeToPendingContainer,
  onOpenDrawerDeleteOrderContainer,
  onOpenDrawerApplyDiscountContainer,
  onOpenDrawerProcessPayment,
  onOpenDrawerFullRefundContainer,
  onOpenDrawerPartialRefundContainer,
  openAddItemPageAndWritePageThatWasBefore = () => {},

  isPendingPageSelected = () => {},
  isActivePageSelected = () => {},
  isCompletedPageSelected = () => {},
}) {
  const { activeOrder, isActiveOrderEmpty: isPendingActiveOrderEmpty } =
    useContext(PendingOrdersContext);

  const { isEmptyOrdersPool: isEmptyActiveOrders, isActiveOrderEmpty } =
    useContext(ActiveOrdersContext);

  const {
    isActiveOrderEmpty: isActiveCompletedOrderEmpty,
    isActiveOrderHaveRefund: isCompletedActiveOrderHaveRefund,
    isActiveOrderHaveFullRefund: isCompletedActiveOrderHaveFullRefund,
  } = useContext(CompletedOrdersContext);

  const [isUserOptionsDropdownOpen, setUserOptionsDropdownOpen] =
    useState(false);

  const onToggleMore = (isOpen) => {
    setUserOptionsDropdownOpen(isOpen);
  };

  const deleteOrder = () => {
    if (isPendingPageSelected() && !isPendingActiveOrderEmpty()) {
      onOpenDrawerDeleteOrderContainer();
    } else if (isActivePageSelected() && !isActiveOrderEmpty()) {
      onOpenDrawerDeleteOrderContainer();
    }
  };

  const applyDiscount = () => {
    if (isActivePageSelected() && !isActiveOrderEmpty()) {
      onOpenDrawerApplyDiscountContainer();
    }
  };

  const processPayment = () => {
    if (isActivePageSelected() && !isActiveOrderEmpty()) {
      onOpenDrawerProcessPayment();
    }
  };

  const isAbleToOpenRefund = () => {
    return isCompletedPageSelected() && !isActiveCompletedOrderEmpty();
  };

  const warnUserForSelectingActiveOrderBeforeRefund = () =>
    showMessage('Please select order before refund', 'warning');

  const warnUserHeAlreadyHaveRefund = () =>
    showMessage('This order already has refund', 'warning');

  const onOpenFullRefundOrWarnUserIfRefundIsNotAvailable = () => {
    if (isAbleToOpenRefund()) {
      if (isCompletedActiveOrderHaveRefund()) {
        warnUserHeAlreadyHaveRefund();
      } else {
        onOpenDrawerFullRefundContainer();
      }
    } else {
      warnUserForSelectingActiveOrderBeforeRefund();
    }
  };

  const onOpenPartialRefundWhenItIsAvailable = () => {
    if (isAbleToOpenRefund()) {
      if (
        isCompletedActiveOrderHaveRefund() &&
        isCompletedActiveOrderHaveFullRefund()
      ) {
        warnUserHeAlreadyHaveRefund();
      } else {
        onOpenDrawerPartialRefundContainer();
      }
    } else {
      warnUserForSelectingActiveOrderBeforeRefund();
    }
  };

  const isAddItemPageAvailable = () => {
    if (isPendingPageSelected()) {
      return !isPendingActiveOrderEmpty();
    } else {
      return !isActiveOrderEmpty();
    }
  };

  const openAddItemSubPage = () => {
    if (isAddItemPageAvailable()) {
      openAddItemPageAndWritePageThatWasBefore();
    } else {
      showMessage('Please, select Active order first', 'warning');
    }
  };

  const sideEffectHandlers = {
    'Change to Pending Order': onOpenDrawerChangeToPendingContainer,
    'Delete Order': deleteOrder,
    'Add Item': openAddItemSubPage,
    'Apply Discount': applyDiscount,
    'Process a Payment': processPayment,
    'Full Refund': onOpenFullRefundOrWarnUserIfRefundIsNotAvailable,
    'Partial Refund': onOpenPartialRefundWhenItIsAvailable,
  };

  const changeUserOptionWithSideEffect = (title) => {
    const sideEffects = Object.keys(sideEffectHandlers);

    if (!sideEffects.includes(title)) {
      changeUserOption(title);
    } else {
      changeUserOption(title);
      sideEffectHandlers[title]();
    }
  };

  return (
    <MoreSelectContainer
      className={`more-select ${
        isEmptyActiveOrders() && isActivePageSelected() ? 'empty' : ''
      }`}
      show={isUserOptionsDropdownOpen}
      onToggle={onToggleMore}
    >
      <Dropdown.Toggle className='btn-more'>
        <i className='fas fa-ellipsis-h' onClick={onToggleMore} />
      </Dropdown.Toggle>
      <Dropdown.Menu className='user-options'>
        {options(
          selectedPage,
          isPendingPageSelected() && activeOrder.status === 1
        ).map(([icon, title], index) => (
          <Dropdown.Item
            key={title + index}
            eventKey={index}
            onClick={() => changeUserOptionWithSideEffect(`${title}`)}
          >
            <div className='dropdown-item-container'>
              {icon}
              <span className='dropdown-item-title'>{title}</span>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </MoreSelectContainer>
  );
}

MoreSelect.propTypes = {
  userOption: PropTypes.string,
  selectedPage: PropTypes.string,
  changeUserOption: PropTypes.func,
  onOpenDrawerChangeToPendingContainer: PropTypes.func,
  onOpenDrawerDeleteOrderContainer: PropTypes.func,
  onOpenDrawerApplyDiscountContainer: PropTypes.func,
  onOpenDrawerProcessPayment: PropTypes.func,
  onOpenDrawerFullRefundContainer: PropTypes.func,
  onOpenDrawerPartialRefundContainer: PropTypes.func,
  openAddItemPageAndWritePageThatWasBefore: PropTypes.func,

  isPendingPageSelected: PropTypes.func,
  isActivePageSelected: PropTypes.func,
  isCompletedPageSelected: PropTypes.func,
};

export const options = (selectedPage, isSeeingOrderReady = false) => {
  const dropdownOptionsBySelectedPage = {
    pending: [
      [
        <i className='fas fa-plus' key='add item' />,
        isSeeingOrderReady ? 'Change to Pending Order' : 'Add Item',
      ],
      [<i className='far fa-trash' key='delete-order' />, 'Delete Order'],
    ],
    addItem: [],
    active: [
      [<i className='fas fa-plus' key='add item' />, 'Add Item'],
      [
        <i className='fas fa-percentage' key='apply discount' />,
        'Apply Discount',
      ],
      [
        <i className='far fa-money-bill-wave-alt' key='process-a-payment' />,
        'Process a Payment',
      ],
      [<i className='far fa-trash' key='delete-order' />, 'Delete Order'],
    ],
    completed: [
      [<i className='fas fa-dollar-sign' key='dollar' />, 'Partial Refund'],
      [<i className='far fa-sack-dollar' key='full' />, 'Full Refund'],
    ],
  };

  return dropdownOptionsBySelectedPage[selectedPage];
};
