import React, { useState, useEffect, useContext } from 'react';
import { restaurantServices } from 'services/api-services';
import { AuthContext } from 'contexts/AuthContextContainer';
import { showMessage } from 'services/functions';
import { staffsTemp } from 'services/static'
import SingleText from 'components/SingleText';
import Box from 'components/Box';
import { StaffHeader } from './components';
import { StaffContainer, StaffContainerMenu } from './style';
import TableAllStaff from './TableAllStaff';
import HighestRated from './HighestRated';
import SingleAntImage from 'components/SingleAntImage';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';

import { StaffNoResults } from 'assets/images/staff';

export default function Staff() {

  const {
    setSelectedStaff,
    setOpenDrawerStaffEdit,
    openDrawerStaffEdit,
    setOpenDrawerStaffDetails,
    setOpenDrawerRoles,
    setOpenDrawerStaffDelete,
  } = useContext(StateContext);
  const { setChosenStaff } = useContext(DataContext);
  const { restaurantId } = useContext(AuthContext);
  const [staffs, setStaffs] = useState([]);
  const [searchStaff, setSearchStaff] = useState('');
  const [selectedMenu, setSelectedMenu] = useState('all-staff');
  const [selectedSort, setSelectedSort] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const onChangeSearchStaff = (newValue) => setSearchStaff(newValue);
  const onClearSearchStaff = () => setSearchStaff('');

  const filterStaffsIfUserSearch = (staffList, searchValue) => {
    if (searchValue.trim()) {
      return staffList.filter(({ first_name, last_name }) => `${first_name} ${last_name}`.toLowerCase().includes(searchValue.trim().toLowerCase()));
    }

    return staffList;
  };

  useEffect(() => {
    getAllStaff()
    //eslint-disable-next-line
  }, [staffsTemp])


  useEffect(() => {
    getAllStaff()
    //eslint-disable-next-line
  }, [selectedSort, selectedRole, selectedStatus])

  useEffect(() => {
    return () => {
      if (openDrawerStaffEdit === true) getAllStaff()
    }
    // eslint-disable-next-line
  }, [openDrawerStaffEdit])

  // const onPressEnter = (keyword) => {
  //   console.log('keyword ', keyword);
  // }

  // const onFilterStaffs = (keyword) => {
  //   console.log('filter keyword ', keyword);
  // }


  async function getAllStaff() {
    const onOk = (data) => {
      setStaffs(data.staffs);
    };

    const onFail = (errMessage) => {
      showMessage(errMessage, 'error');
    };

    if (restaurantId) {
      const filters = {
        "sort": selectedSort,
        "role_slug": selectedRole,
        "status": selectedStatus
      }
      return await restaurantServices.getStaffs(restaurantId, filters, onOk, onFail);
    }
  }

  function editStaffWasClicked(staffId) {
    setOpenDrawerStaffEdit(true);
    setChosenStaff(staffs.find(x => x.id === staffId));
  }

  function onClickStaffItem(staffId) {
    setSelectedStaff(staffs.find(x => x.id === staffId));
    setOpenDrawerStaffDetails(true);
  }

  function onClickStaffDelete(_id) {
    setChosenStaff(staffs.find(x => x.id === _id));
    setOpenDrawerStaffDelete(true);
  }

  return (
    <StaffContainer>
      <StaffHeader
        staffs={staffs}
        onChangeSearchStaff={onChangeSearchStaff}
        onClearSearchStaff={onClearSearchStaff}
        setOpenDrawerRoles={setOpenDrawerRoles}
        isAllStaff={selectedMenu === 'all-staff'}
        selectedSort={selectedSort}
        setSelectedSort={setSelectedSort}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
      />
      <StaffContainerMenu>
        <div className="staff-menus">
          <div className={`staff-menu-item ${selectedMenu === 'all-staff' ? 'active' : ''}`} onClick={() => setSelectedMenu('all-staff')}>
            All Staffs: {filterStaffsIfUserSearch(staffs, searchStaff).length}
          </div>
          <div className={`staff-menu-item ${selectedMenu === 'highest-rated' ? 'active' : ''}`} onClick={() => setSelectedMenu('highest-rated')}>
            Highest-Rated
          </div>
        </div>
        <div className="staff-content-container">
          {selectedMenu === 'all-staff' && <>
            {staffs.length > 0 && filterStaffsIfUserSearch(staffs, searchStaff).length > 0
              ? <TableAllStaff
                staffs={staffs}
                searchValue={searchStaff}
                filterStaffsIfUserSearch={filterStaffsIfUserSearch}
                onClickStaffEdit={(id) => editStaffWasClicked(id)}
                onClickStaffDelete={(id) => onClickStaffDelete(id)}
                onClickStaffItem={onClickStaffItem}
                onStuffActiveChange={setStaffs}
              />
              : <Box display='flex' flexDirection='column' alignItems='center' height='100%' paddingTop='180px'>
                <SingleAntImage src={StaffNoResults} width={347} height={314} style={{ marginBottom: 41, marginRight: '-41px' }} />
                <SingleText content='No results' size={24} weight='bold' marginBottom={14}>No results</SingleText>
                <SingleText content='Try broadening your search' size={16} weight={500}>Try broadening your search</SingleText>
              </Box>
            }
          </>}
          {selectedMenu === 'highest-rated' && <HighestRated searchValue={searchStaff} />}
        </div>
      </StaffContainerMenu>
    </StaffContainer>
  )
}
