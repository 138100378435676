import React, { useEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { getJwt } from '../services/jwt';
import PropTypes from 'prop-types';
import PageLayout from 'layouts';
import { useWelcomeFlow } from 'hooks';
import Welcome from 'pages/Welcome';

const welcomeAllowedRoutes = [
  "/dashboard",
  "/orders",
  "/requests"
]
function MyRoute({ component: Component, ...rest }) {
  const history = useHistory();
  const { isPublished } = useWelcomeFlow();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!getJwt()) {
      history.push('/');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <PageLayout>
          {!isPublished() && welcomeAllowedRoutes.includes(pathname) ? <Welcome /> : <Component {...props} />}
        </PageLayout>
      )}
    />
  );
}

MyRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default MyRoute;
