import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FaStar } from 'react-icons/fa';
import { StaffCardContainer } from './style';
import { StaffRoles } from 'feasttt/domain';
import { colors } from 'theme';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import SingleImage from 'components/SingleImage';

import DefaultProfileImg from 'assets/images/profile/default-profile.png';
import { StateContext } from 'contexts/StateContextContainer';

export function StaffCard({ icon, period, staff }) {

  const {
    setSelectedStaff,
    setOpenDrawerStaffDetails,
  } = useContext(StateContext);

  function onClickViewProfile() {
    setSelectedStaff(staff);
    setOpenDrawerStaffDetails(true);
  }
  return (
    <StaffCardContainer>
      <div className='best-staff-container'>
        {icon &&
          <img
            src={icon}
            alt='staff-rating'
            className='staff-rating-image'
          />
        }
        <span className='staff-period'>{period}</span>
      </div>
      <SingleImage
        src={staff.avatar ? staff.avatar : DefaultProfileImg}
        size={179}
        style={{ marginTop: 45, marginBottom: 34 }}
      />
      <SingleText
        align='center'
        size={24}
        weight='Bold'
        spacing='-0.58px'
        marginBottom={10}
      >
        {`${staff.first_name} ${staff.last_name}`}
      </SingleText>
      <SingleText
        align='center'
        size={19}
        spacing='-0.46px'
        weight='bold'
        marginBottom={27}
        color='#bbbbbb'
      >
        {StaffRoles.readRole(staff.role_slug)}
      </SingleText>
      <Box
        height={28}
        width='fit-content'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <FaStar color={colors.rating} size={24} />
        <SingleText size={24} weight='bold' spacing='-0.34px' marginLeft={5}>
          {staff.rating ? staff.rating : 'N/A'}
        </SingleText>
      </Box>
      <SingleButton
        className='btn-view-profile-staff'
        containerClassName='btn-view-profile-container'
        title='View Profile'
        txtColor='white'
        btnColor={colors.lightGray}
        margin='46px 0px 0'
        onClick={onClickViewProfile}
      />
    </StaffCardContainer>
  );
}

StaffCard.propTypes = {
  icon: PropTypes.string,
  period: PropTypes.string,
  staff: PropTypes.object,
};
