import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from 'components/Box';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { dayTitles } from 'services/static';
import { DrawerAvailablitiesContainer } from './style';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { colors } from 'theme';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';

export default function DrawerAvailabilities({ 
  placement = 'right',
}) {

  const {
    openDrawerAvailabilities, setOpenDrawerAvailabilities,
    setOpenDrawerAvailabilityDay, setSelectedDayOfWeek
  } = useContext(StateContext);
  const { setSelectedAvailability, selectedMenu } = useContext(DataContext)

  function convertTimeToReadableFormat(time) {
    const formattedTime = time % (12 * 60 * 60);
    const hr = Math.floor((formattedTime) / (60 * 60));
    const min = (formattedTime - (hr * 60 * 60)) / 60;
    const dateTime = Math.floor(time / (12 * 60 * 60)) >= 1 ? "PM" : "AM";

    return `${hr < 10 ? '0' + hr : hr}:${min === 0 ? '00' : min} ${dateTime}`;
    // console.log(`${hr < 10 ? '0' + hr : hr}:${min === 0 ? '00' : min} ${dateTime}`)
}

  const onClickDoneAvailability = () => {
    // setSelectedMenu({...selectedMenu, availability: selectedAvailability})
    setOpenDrawerAvailabilities(false);
  }
  const onClickDay = (selectedDay) => {
    // setSelectedDayKey(dayKey)
    // if (selectedAvailability) {
    //   let temp = selectedAvailability.find(_availability => _availability.day === dayKey)
    //   setSelectedAvailableTime(temp ? temp : {})
    // }
    setSelectedDayOfWeek(selectedDay.content);
    setOpenDrawerAvailabilityDay(true);
  }
  const onClose = () => {
    setOpenDrawerAvailabilities(false)
    setSelectedAvailability([])
  }

  const renderAvailableDay = (_id) => {
    const wasSelectedBefore = selectedMenu?.availability?.find(x => x.day === _id);
    let dayToDisplay = '';
    if (wasSelectedBefore) {
        const timeRange = wasSelectedBefore.times[0];
        const [startTime, endTime] = timeRange;
        dayToDisplay = convertTimeToReadableFormat(startTime) + ' - ' + convertTimeToReadableFormat(endTime);
    }
    // let isAvailable = selectedMenu?.filter(_availableDay => _availableDay.day === _i).length > 0
    return (
      <SingleText 
        color={wasSelectedBefore ? 'white' : colors.redColor} size={14} weight={500} lineHeight={1.57} spacing='-0.34px' 
      >
        {
          wasSelectedBefore
          ? dayToDisplay
          : 'Set time'
        }
      </SingleText>
    )
  }
  return (
    <DrawerAvailablitiesContainer
      placement={placement}
      onClose={onClose}
      visible={openDrawerAvailabilities}
    >
      <Box is='div' display='flex' alignItems='center' marginBottom={40}>
        <SingleIconWrapper onClick={onClose}>
          <FaChevronLeft size={20} color='white' />
        </SingleIconWrapper>
        <Box is='div' display='flex' justifyContent='center' flex={1}>
          <SingleText content='Availability' align='center' size={22} weight={500}>Availability</SingleText>
        </Box>
      </Box>
      <Box is='div' className='drawer-menu--options'>
        {dayTitles.map((_day, _i) =>
          <Box cursor="pointer" key={_i} is='div' className='drawer-menu--option' onClick={() => onClickDay(_day)}>
            <Box is='div' display='flex' flexDirection='column'>
              <SingleText content={_day} size={14} weight={500} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>{dayTitles[_i].content}</SingleText>
              {renderAvailableDay(_day.num, selectedMenu)}
            </Box>
            <FaChevronRight size={15} color='rgba(255, 255, 255, 0.3)' />
          </Box>
        )}
      </Box>
      <Box is='div' display='flex' flexDirection='column' marginTop='auto'>
        <SingleButton 
          title='Done' 
          btnColor={colors.redColor}
          txtColor='white'
          onClick={onClickDoneAvailability} 
        />
      </Box>
    </DrawerAvailablitiesContainer>
  )
}

DrawerAvailabilities.propTypes = {
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickDay: PropTypes.func,
  onClickDoneAvailability: PropTypes.func,
};
