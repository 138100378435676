import styled from '@emotion/styled';
import Dropdown from 'react-bootstrap/Dropdown';
import { colors } from 'theme';

export const MoreSelectContainer = styled(Dropdown)`
  &.more-select {
    position: relative;
    cursor: pointer;

    &.empty {
      display: none;
    }

    .btn-more {
      padding: 0px;
      background-color: transparent;
      border-color: transparent;

      i {
        font-size: 24px;
        color: white;
      }

      &:after {
        display: none;
      }

      &:active {
        background-color: transparent;
      }
    }

    .user-options {
      padding: 17px 26px;
      border-radius: 25px;
      background-color: ${colors.gray5};

      .dropdown-item {
        padding: 0px;
        margin-bottom: 30px;

        &:hover {
          background-color: transparent;
        }

        &:focus {
          background-color: transparent;
        }

        &:last-of-type {
          margin-bottom: 0px;
        }

        .dropdown-item-container {
          display: flex;
          align-items: center;

          &:hover {
            i {
              color: ${colors.redColor};
            }

            .dropdown-item-title {
              color: ${colors.redColor};
            }
          }

          i {
            font-size: 14px;
            color: white;
            letter-spacing: 0;
            margin-right: 19px;
          }

          .dropdown-item-title {
            font-size: 14px;
            letter-spacing: 0;
            color: white;
          }
        }
      }
    }
  }
`;
