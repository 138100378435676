import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const PartialRefundModalContainer = styled(SingleDrawer)`
  padding: 0;
  background-color: ${colors.gray2};
  font-size: 14px;
  color: white;
  overflow-y: hidden;

  .return-partial-wrapper {
    /* padding: 35px 0 92px; */
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #27282a;
    /* position: relative;
    top: 31rem; */

    padding: 27px 0 15px 0;
    overflow-x: hidden;
    position: absolute;
    bottom: 0;
  }

  .return-partial-text-style {
    font-size: 23px;
    text-align: center;
    margin-bottom: 25px;
  }

  .return-partial-box-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .return-partial-box-wrapper > div {
    padding: 1em 31.5px 1em 31.5px;
  }

  .return-partial-border-box-1 {
    border: 1px solid rgb(97 92 92 / 50%);
    border-left-style: none;
    cursor: pointer;

    height: 71px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .return-partial-border-box-1:hover {
    background: #ed2626;
  }



  /* .border-box-2 {
    border: 1px solid rgb(97 92 92 / 50%);
    border-left-style: none;
  }

  .border-box-3 {
    border: 1px solid rgb(97 92 92 / 50%);
    border-left-style: none;
  } */

  /* .return-partial-border-box-4 {
    border: 1px solid rgb(97 92 92 / 50%);
    border-right-style: none;
    border-left-style: none;
  } */

  .return-partial-percentage {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }

  .return-partial-dollar {
    font-size: 13px;
    text-align: center;
  }

  .return-partial-other-style {
    height: 71px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .return-partial-refund {
    text-align: center;
    margin-top: 2rem;
  }

  .return-partial-btn-center {
    text-align: center;
  }

  .return-partial-btn-style {
    background: #ed2626;
    border: none;
    width: 20rem;
    position: relative;
    height: 44px;
  }

  .return-partial-btn-text {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
  }

  .return-partial-cancel-text {
    text-align: center;
    font-size: 14px;
    margin-top: 2rem;
    color: #ed2626;
    font-weight: 600;
    cursor: pointer;
  }

  .return-partial-other-section-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 31px 5px 31px;
    border-bottom: 1px solid rgb(97 92 92 / 50%);
  }

  .return-partial-other-section-inner {
    line-height: 10px;
  }

  .return-partial-other-text-style {
    font-size: 14px;
  }

  .return-partial-other-cost {
    font-size: 20px;
    font-weight: 500;
  }
`;
