import React from 'react';
import PropTypes from 'prop-types';
import { DrawerAddBankAccountErrorContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerAddBankAccountError({
  visible,
  onClose,
  onTryAgainClick,
}) {
  return (
    <DrawerAddBankAccountErrorContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='fail-add-bank-account-image' />
      <SingleText
        size={22}
        spacing='0.42px'
        align='center'
        marginBottom={10}
        weight='500'
      >
        Oops!
      </SingleText>
      <SingleText lineHeight='21px' spacing='0.27px' align='center'>
        Something went wrong.
      </SingleText>
      <SingleButton
        title='Try Again'
        margin='31px 0px 20px'
        onClick={onTryAgainClick}
      />
      <SingleButton
        title='Cancel'
        btnColor='transparent'
        txtColor={colors.redColor}
        onClick={onClose}
      />
    </DrawerAddBankAccountErrorContainer>
  );
}

DrawerAddBankAccountError.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onTryAgainClick: PropTypes.func,
};
