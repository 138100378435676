import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tables } from 'feasttt/domain';
import { DataContext } from 'contexts/DataContextContainer';
import { StateContext } from 'contexts/StateContextContainer';
import { showMessage } from 'services/functions';
import { useTable } from 'hooks';
import Box from 'components/Box';
import { FaChevronLeft } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerTableContainer, TableShapeContainer } from './style';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { colors } from 'theme';
import NumberIncrease from 'components/DrawerForm/NumberIncrease';
import ItemPicker from 'components/DrawerForm/ItemPicker';

const shapes = ['rect', 'circle'];

export default function DrawerTable({
  type = '',
  visible = false,
  placement = 'right',
  onClose = () => {},
}) {
  const { setOpenDrawerTableStatus, setOpenDrawerElement } =
    useContext(StateContext);
  const {
    floorDetails,
    selectedFloorId,
    getTableForEditTemp,
    setTableForEditTemp,
  } = useContext(DataContext);

  const {
    addTables: _addTables,
    editTable: _editTable,
    shape,
    setShape,
    seats,
    setSeats,
  } = useTable();

  const changeSeats = (updatedSeats) => setSeats(updatedSeats);
  const changeShape = (updatedShape) => setShape(updatedShape);

  const increaseSeats = () => {
    const updatedSeats = seats + 1;

    if (shape === 'circle') {
      changeSeats(Tables.geometry.validateSeatsForCircleTable(updatedSeats));
      // alert('circle')
    } else {
      const [updatedRectSeats, updatedRectShape] =
        Tables.geometry.validateSeatsAndShapeForRectangle(updatedSeats);
      changeSeats(updatedRectSeats);
      changeShape(updatedRectShape);
      // alert('rectangle')
    }
  };

  const decreaseSeats = () => {
    const updatedSeats = seats - 1;
    setSeats(Tables.geometry.decreaseSeats(updatedSeats));
  };

  useEffect(() => {
    if (getTableForEditTemp && type !== 'Add') {
      setShape(getTableForEditTemp.type === 0 ? 'circle' : 'rect');
      setSeats(getTableForEditTemp.seats);
    }
    // eslint-disable-next-line
  }, [getTableForEditTemp]);

  useEffect(() => {
    if (shape === 'rect') {
      if (seats > 8) {
        setSeats(8);
      }
    }
  }, [shape, seats, setSeats]);

  const onClickStatus = () => {
    setOpenDrawerTableStatus(true);
  };

  const addTables = async () => {
    const onOk = () => {
      onClose();
      setOpenDrawerElement(false);
      setTableForEditTemp();
      changeShape(undefined);
      changeSeats(1);
    };

    const onFail = (errMessage) => {
      showMessage(errMessage, 'error');
    };

    return await _addTables(
      getTableForEditTemp ? getTableForEditTemp.status : 0,
      seats,
      floorDetails,
      selectedFloorId,
      onOk,
      onFail
    );
  };

  async function editTable() {
    const onOk = () => {
      onClose();
      setOpenDrawerElement(false);
      setTableForEditTemp();
    };

    const onFail = (errMessage) => {
      showMessage(errMessage, 'error');
    };

    return await _editTable(
      getTableForEditTemp,
      selectedFloorId,
      floorDetails,
      onOk,
      onFail
    );
  }

  const isShapeRectangleActive = (_shape) =>
    _shape === 'rect' && (shape === 'rectangleh' || shape === 'rectanglev');

  return (
    <DrawerTableContainer
      placement={placement}
      onClose={onClose}
      visible={visible}
    >
      <Box is='div' display='flex' alignItems='center' padding={24}>
        <SingleIconWrapper className='cursor' onClick={onClose}>
          <FaChevronLeft size={20} color='#ffffff' />
        </SingleIconWrapper>
        <Box is='div' display='flex' justifyContent='center' flex={1}>
          <SingleText content='Type' align='center' size={22} weight={500}>
            {type} Table
          </SingleText>
        </Box>
      </Box>

      <TableShapeContainer>
        <SingleText weight={600} spacing='-0.34px' marginBottom={29}>
          Choose table shape
        </SingleText>
        <div className='shape-container'>
          {shapes.map((_shape, _i) => (
            <Box
              key={_i}
              className={`table-shape ${
                shape === _shape || isShapeRectangleActive(_shape)
                  ? 'active'
                  : 'inactive'
              }`}
              onClick={() => setShape(_shape)}
              cursor='pointer'
              marginBottom={20}
            >
              <Box
                width={_shape === 'rectangleh' ? 100 : 50}
                height={_shape === 'rectanglev' ? 90 : 50}
                background='#d8d9da'
                borderRadius={_shape === 'circle' ? '50%' : '0px'}
              />
            </Box>
          ))}
        </div>
      </TableShapeContainer>
      <NumberIncrease
        label='Select seats'
        seats={type === 'Add' ? seats : getTableForEditTemp?.seats}
        increaseSeats={increaseSeats}
        decreaseSeats={decreaseSeats}
        isUserDidNotSelectShape={shape === undefined}
      />
      <ItemPicker
        label='Status'
        placeholder='Set Status'
        value={
          getTableForEditTemp && getTableForEditTemp.status === 1
            ? 'Active'
            : 'Inactive'
        }
        padding='0 27px 0 39px'
        onClick={onClickStatus}
      />
      <Box display='flex' flexDirection='column' marginTop='auto' padding={24}>
        <SingleButton
          title={type === 'Add' ? 'Add Table' : 'Edit Table'}
          margin='0 0 20px'
          btnColor={colors.redColor}
          txtColor='white'
          onClick={() => (type === 'Add' ? addTables() : editTable())}
        />
        <SingleButton
          title={'Cancel'}
          btnColor='transparent'
          txtColor={colors.redColor}
        />
      </Box>
    </DrawerTableContainer>
  );
}

DrawerTable.propTypes = {
  type: PropTypes.string,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
};
