import React, { useContext } from 'react'
import PropTypes from 'prop-types';
// import './style.scss';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { showMessage } from 'services/functions';
import { restaurantServices } from 'services/api-services';
import { AuthContext } from 'contexts/AuthContextContainer';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { DrawerDeleteCategoryConfirmContainer } from './style';
import { colors } from 'theme';

export default function DrawerDeleteCategoryConfirm({ 
  visible = false,
  placement = 'bottom',
  onClose = () => {},
}) {

  const {
    setOpenDrawerDeleteCategoryConfirm,
    setOpenDrawerDeleteCategory,
  } = useContext(StateContext);
  const { restaurantId } = useContext(AuthContext);
  const { chosenCategory, setChosenCategory } = useContext(DataContext);

  const onClickKeepCategory = () => {
    setOpenDrawerDeleteCategoryConfirm(false)
  }

  const onClickDeleteCategory = async () => {
    const { ok, errMessage } = await restaurantServices.deleteCategory({ restaurantId, categoryId: chosenCategory.id });
    if (ok) {
      // getAllCategories()
      setChosenCategory();
      setOpenDrawerDeleteCategoryConfirm(false);
      setOpenDrawerDeleteCategory(false);
    } else {
      showMessage(errMessage, 'error')
    }
  }

  return (
      <DrawerDeleteCategoryConfirmContainer
        placement={placement}
        onClose={onClose}
        height={359}
        visible={visible}
      >
        <Box is='div' display='flex' flexDirection='column' alignItems='flex-start' marginBottom={30}>
          <SingleText size={22} weight={500} marginBottom={10}>Delete Category</SingleText>
          <SingleText size={14} lineHeight={1.5} spacing='0.27px'>Are you sure you want to delete category <b>{chosenCategory?.name}</b> and all of the items under it?</SingleText>
          <SingleText size={14} lineHeight={1.5} spacing='0.27px' marginBottom={30}>This cannot be undone.</SingleText>
        </Box>
        <Box is='div' display='flex' flexDirection='column'>
          <SingleButton 
            title='Delete' 
            btnColor={colors.redColor}
            txtColor='white'
            margin='0 0 20px'
            onClick={onClickDeleteCategory} 
          />
          <SingleButton 
            title='Keep' 
            btnColor='#61646c'
            txtColor='white'
            margin='0 0 20px'
            onClick={onClickKeepCategory} 
          />
        </Box>
      </DrawerDeleteCategoryConfirmContainer>
  )
}

DrawerDeleteCategoryConfirm.propTypes = {
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickDay: PropTypes.func,
  onClickDeleteCategory: PropTypes.func,
  onClickKeepCategory: PropTypes.func,
};
