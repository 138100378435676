import { useState } from 'react';
import { CompletedOrdersPeriods } from 'feasttt/domain';

export const usePeriod = () => {
  const [selectedPeriod, setSelectedPeriod] = useState(
    CompletedOrdersPeriods.initialValue()
  );

  const changeSelectedPeriod = (newSelectedPeriod) =>
    setSelectedPeriod(newSelectedPeriod);

  const isThisPeriodEqualToSelectedPeriod = (periodToCompare) => {
    return CompletedOrdersPeriods.isEqual(selectedPeriod, periodToCompare);
  };

  return {
    selectedPeriod,
    changeSelectedPeriod,
    isThisPeriodEqualToSelectedPeriod,
  };
};
