import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DrawerFeaturedImageWrapper } from './style';
import DrawerRemoveFeaturedImage from './DrawerRemoveFeaturedImage';
import { useBinaryState } from 'hooks';
import { ScaleLoader } from 'react-spinners';
import { assetServices } from 'services/api-services';
import { showMessage } from 'services/functions';

function DrawerFeaturedImage({
  visible = false,
  placement = 'right',
  onClose = () => {},
  gallery = [],
  onDeleteFeaturedImage = () => {},
  onAddFeaturedImage = () => { },
}) {
  const [
    openDrawerRemoveFeaturedImage,
    setDrawerRemoveFeaturedImage,
  ] = useState(false);

  const [featuredImageIdToDelete, setFeaturedImageIdToDelete] = useState(null);
  const {
    isActive: isUploading,
    activate: startUploading,
    deactivate: endUploading,
  } = useBinaryState();

  const inputFileRef = useRef();
  const invokeEventForInput = () => inputFileRef.current.click();

  const areYouSure = (itemToDelete) => {
    setFeaturedImageIdToDelete(itemToDelete);
    setDrawerRemoveFeaturedImage(true);
  };

  const onKeep = () => {
    setFeaturedImageIdToDelete(null);
    setDrawerRemoveFeaturedImage(false);
  };

  const onDelete = () => {
    onDeleteFeaturedImage(
      gallery.filter(({ id }) => id !== featuredImageIdToDelete)
    );

    // clean up all after successfully deleting
    onKeep();
  };

  const close = () => {
    onKeep();
    onClose();
  };

  const onCloseRemoveFeaturedImage = () => {
    setDrawerRemoveFeaturedImage(false);
    onKeep();
  };

  const onUploadLocalFileToAssetsStorage = async (info) => {
    const fileToUploadFromFormData = createFormDataForLocalFile(info);

    startUploading();
    uploadPhotoAsset(fileToUploadFromFormData);
  };

  const createFormDataForLocalFile = (uploadInfo) => {
    const postData = new FormData();
    postData.append('asset', uploadInfo.target.files[0]);
    postData.append('type', 'user');
    postData.append('api_key', process.env.REACT_APP_API_KEY);

    return postData;
  };

  const uploadPhotoAsset = async (localFile) =>
    await assetServices.uploadToS3(
      localFile,
      onSuccessfullyUploaded,
      onFailWhenTriedUpload
    );

  const onSuccessfullyUploaded = (uploadedResource) => {
    onAddFeaturedImage(uploadedResource);
    endUploading();
  };

  const onFailWhenTriedUpload = (errMessage) => {
    showMessage(errMessage, 'error');
    endUploading();
  };

  return (
    <DrawerFeaturedImageWrapper
      onClose={close}
      visible={visible}
      placement={placement}
    >
      <div className='featured-images-panel'>
        <div className='panel-header-container'>
          <div className='back-arrow' onClick={onClose}>
            <i className='fas fa-chevron-left' />
          </div>
          <div className='panel-header-context'>Featured Images</div>
          <div className='add-new-container' onClick={invokeEventForInput}>
            <i className='fas fa-plus' />
          </div>
          <input
            className='file-image-input'
            type='file'
            ref={inputFileRef}
            onChange={onUploadLocalFileToAssetsStorage}
            accept='image/*'
          />

        </div>
        {isUploading &&
          <div className='uploading-icon-container'>
            <div className='uploading-icon'>
              <ScaleLoader size={15} color={'white'} />
            </div>
          </div>
        }
        {!isUploading && <div className='panel-status-container'>
          <div className='featured-images-container'>
            {gallery.map((imageItem) => {
              const imageStyle = imageItem.file
                ? {
                  backgroundImage: `url(${imageItem.file})`,
                  backgroundSize: 'cover',
                }
                : {};

              return (
                <div
                  className={`image-item`}
                  key={`featuredImagekeyitem${imageItem.id}`}
                  style={imageStyle}
                >
                  <div
                    className='delete-button-container'
                    onClick={() => areYouSure(imageItem.id)}
                  >
                    <i className='fas fa-trash' />
                  </div>
                </div>
              );
            })}
          </div>
        </div>}
      </div>
      <DrawerRemoveFeaturedImage
        visible={openDrawerRemoveFeaturedImage}
        onClose={onCloseRemoveFeaturedImage}
        onDelete={onDelete}
        onKeep={onKeep}
      />
    </DrawerFeaturedImageWrapper>
  );
}

export default DrawerFeaturedImage;

DrawerFeaturedImage.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  gallery: PropTypes.array,
  onDeleteFeaturedImage: PropTypes.func,
  onAddFeaturedImage: PropTypes.func,
};
