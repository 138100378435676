import React from 'react'
import PropTypes from 'prop-types';
// import { Popover } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { StyledDropdownMenu } from './style';

export default function SingleAntPopup({
  title = '',
  content,
  placement = '',
  children,
  className = '',
}) {

  // eslint-disable-next-line react/display-name
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      className="dropdown-toggle-header"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));


  return (
    <Dropdown className={className}>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {children}
      </Dropdown.Toggle>

      <StyledDropdownMenu>
        {content}
      </StyledDropdownMenu>
    </Dropdown>
    // <SingleAntPopupContainer
    //   id='signle-ant-popup-container'
    //   // overlayClassName='signle-ant-popup' 
    //   placement={placement}
    //   title={title}
    //   content={content}
    //   // getPopupContainer={() => document.getElementById('signle-ant-popup-container')}
    //   trigger="click"
    //   arrowContent={false}
    // >
    //   {children}
    // </SingleAntPopupContainer>
  )
}

SingleAntPopup.propTypes = {
  title: PropTypes.string,
  content: PropTypes.object,
  placement: PropTypes.string,
  children: PropTypes.object,
  className: PropTypes.string,
};
