import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { OtherRejectItemContainer } from './style';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';
import { OtherRejectItemAlert } from './CompletedAlertRejectItem';

export function OtherRejectItem({ visible, onClose }) {
  const context = useContext(modalContext);

  function OtherConfirmRequestFunc() {
    context.setModal('otherRejectItemModal-2');
  }

  return (
    <>
      <OtherRejectItemContainer
        visible={visible}
        placement='right'
        onClose={onClose}
      >
        {context.modal === 'otherRejectItem-1' ? (
          <>
            <div className='other-rejectitem-wrapper animate__animated animate__fadeInUp'>
              <div>
                <p className='other-rejectitem-heading'>Are you sure?</p>
              </div>

              <div>
                <p className='other-rejectitem-delivered'>
                  The full request will be rejected. This cannot be undone.
                </p>
              </div>

              <div className='other-rejectitem-btn-wrapper'>
                <Button
                  variant='none'
                  className='other-rejectitem-btn'
                  onClick={OtherConfirmRequestFunc}
                >
                  Yes, reject request
                </Button>
              </div>

              <div className='other-rejectitem-btn-wrapper'>
                <Button
                  variant='none'
                  className='other-rejectitem-keep-btn'
                  onClick={onClose}
                >
                  Keep
                </Button>
              </div>
            </div>
          </>
        ) : (
          <OtherRejectItemAlert onClose={onClose} />
        )}
      </OtherRejectItemContainer>
    </>
  );
}

OtherRejectItem.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
