import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft, FaTrash } from 'react-icons/fa';
import { DrawerFloorEditContainer } from './style';
import { showMessage } from 'services/functions';
import { restaurantServices } from 'services/api-services';
import { DataContext } from 'contexts/DataContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';
import { colors } from 'theme';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import SingleIconWrapper from 'components/SingleIconWrapper';
import SingleAntDrawerInput from 'components/SingleAntDrawerInput';
import DrawerDeleteFloor from './DrawerDeleteFloor';

export function DrawerFloorEdit({
  visible = false,
  placement = 'right',
  onClose = () => {},
  fetchUpdatedFloors = () => {},
}) {
  const [openDrawerFloorDelete, setOpenDrawerFloorDelete] = useState(false);

  const { restaurantId } = useContext(AuthContext);
  const { floorDetails, floorForEditId, setFloorForEditId } = useContext(
    DataContext
  );

  const [floorToEditName, setFloorToEditName] = useState();

  useEffect(() => {
    if (floorDetails.length && floorForEditId) {
      setFloorToEditName(
        floorDetails.find((x) => x.id === floorForEditId).name
      );
    }
    // eslint-disable-next-line
  }, [floorForEditId]);

  async function onSaveEditedFloor() {
    const onOk = async () => {
      closeEditMenu();
      await fetchUpdatedFloors();
    };

    const onFail = (errMessage) => {
      showMessage(errMessage, 'error');
    };

    return await restaurantServices._updateFloor(
      { restaurantId, floorId: floorForEditId },
      { name: floorToEditName },
      onOk,
      onFail
    );
  }

  function closeEditMenu() {
    setFloorForEditId();
    setFloorToEditName();
    onClose();
  }

  function onClickDeleteFloor() {
    setOpenDrawerFloorDelete(true);
  }

  const onDrawerFloorEditClose = async () => {
    onClose();
    await fetchUpdatedFloors();
  };

  return (
    <DrawerFloorEditContainer
      placement={placement}
      onClose={closeEditMenu}
      visible={visible}
    >
      <Box is='div' display='flex' alignItems='center' padding={24}>
        <SingleIconWrapper onClick={closeEditMenu}>
          {!openDrawerFloorDelete ? (
            <FaChevronLeft
              className='cursor'
              size={20}
              color='rgba(255, 255, 255, 0.3)'
            />
          ) : (
            ''
          )}
        </SingleIconWrapper>
        <Box
          className={`drawer-floor-edit-top ${
            openDrawerFloorDelete ? 'remove-mode' : 'edit-mode'
          }`}
        >
          <SingleText align='center' size={22} weight={500}>
            {openDrawerFloorDelete ? 'Remove Floor' : 'Edit Floor'}
          </SingleText>
        </Box>
        {!openDrawerFloorDelete ? (
          <FaTrash
            className='cursor'
            size={20}
            color='white'
            onClick={onClickDeleteFloor}
          />
        ) : (
          ''
        )}
      </Box>
      {!openDrawerFloorDelete ? (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          marginBottom={30}
          padding='0 26px'
        >
          <SingleAntDrawerInput
            label='Floor Name'
            placeholder='Ex: First Floor'
            value={floorToEditName}
            onChange={(e) => setFloorToEditName(e.target.value)}
          />
        </Box>
      ) : (
        ''
      )}
      <Box
        is='div'
        display='flex'
        flexDirection='column'
        marginTop='auto'
        padding={24}
      >
        <SingleButton
          title='Save'
          margin='0 0 20px'
          btnColor={colors.redColor}
          txtColor='white'
          onClick={() => onSaveEditedFloor()}
        />
        <SingleButton
          title='Cancel'
          btnColor='transparent'
          txtColor={colors.redColor}
          onClick={() => closeEditMenu()}
        />
      </Box>
      <DrawerDeleteFloor
        visible={openDrawerFloorDelete}
        onClose={() => setOpenDrawerFloorDelete(false)}
        onDrawerFloorEditClose={onDrawerFloorEditClose}
      />
    </DrawerFloorEditContainer>
  );
}

DrawerFloorEdit.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  fetchUpdatedFloors: PropTypes.func,
};
