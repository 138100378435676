import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { restaurantServices } from 'services/api-services';
import { arrayMove } from 'react-movable';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerCustomizationAddContainer } from './style';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';
import { colors } from 'theme';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
// import arrayMove from 'array-move';
import { FaBars, FaPencilAlt } from 'react-icons/fa';
// import { customizationsTemp } from 'services/static';
import SingleAntSwitch from 'components/SingleAntSwitch';
import SingleNumberIncrease from 'components/SingleNumberIncrease';
import { SortableListContainer } from 'components/SingleDragger/style';
import { FaTrash } from 'react-icons/fa';
import { showMessage } from 'services/functions';

export default function DrawerCustomizationAdd({
  visible = false,
  placement = 'bottom',
  onClose = () => { },
  editFood = {},
  selectedCustomizations,
  onAddSelectedCustomizations,
  onEditSelectedCustomizations,
  onRemoveSelectedCustomizations,
}) {

  const {
    setOpenDrawerAddOption,
  } = useContext(StateContext);
  const {
    options, setOptions,
    selectedCustomizationIndex, setSelectedCustomizationIndex, setSelectedOptionIndex
  } = useContext(DataContext);
  const { restaurantId } = useContext(AuthContext);
  // const [options, setOptions] = useState(customizationsTemp[0].options);
  const [customizationName, setCustomizationName] = useState('');
  const [isRequired, setIsRequired] = useState(false);
  const [countAllowed, setCountAllowed] = useState(1);

  const DragHandle = sortableHandle(() => <FaBars className='icon-drag cursor-grab' color={'white'} size={20} />);

  const SortableItem = sortableElement(({ value, sortIndex }) =>
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      padding='15px 35px'
      borderTop='solid 1px rgba(255, 255, 255, 0.1)'
      borderBottom='solid 1px rgba(255, 255, 255, 0.1)'
      background={colors.gray1}
      className='drag-visible'
    >
      <DragHandle />
      <Box display='flex' flexDirection='column' justifyContent='start' marginRight='auto' marginLeft={19}>
        <SingleText size={14} weight={600} spacing='-0.34px'>{value.name}</SingleText>
      </Box>
      <FaPencilAlt className='cursor' size={20} color={'white'} onClick={() => editSelectedOption(sortIndex)} />
    </Box>
  );

  const SortableList = sortableContainer(({ children }) => {
    return (
      <SortableListContainer id='drag-customization-options'>
        {children}
      </SortableListContainer>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setOptions(arrayMove(options, oldIndex, newIndex))
  };

  useEffect(() => {
    if (selectedCustomizationIndex !== undefined) {
      const chosenCustomization = selectedCustomizations.find((x, i) => i === selectedCustomizationIndex);
      setOptions(chosenCustomization.options);
      setCustomizationName(chosenCustomization.name);
      setIsRequired(chosenCustomization.required);
      setCountAllowed(chosenCustomization.available_selection);
    }
    // eslint-disable-next-line
  }, [selectedCustomizationIndex])

  useEffect(() => {
    return () => {
      if (visible === true) {
        setOptions([]);
        setCustomizationName('');
        setIsRequired(false);
        setCountAllowed(1);
        setSelectedCustomizationIndex();
      }
    }
    // eslint-disable-next-line
  }, [visible])

  function editSelectedOption(index) {
    setSelectedOptionIndex(index);
    setOpenDrawerAddOption(true);
  }

  function addCustomization() {
    if (customizationName && options.length > 0) {
      const payload = {
        name: customizationName,
        available_selection: countAllowed,
        required: isRequired,
        options: options,
      }
      onAddSelectedCustomizations(selectedCustomizations, payload);
      onClose();
    } else if (!customizationName) {
      showMessage('Customization name was not set', 'error');
    } else {
      showMessage('Please, add at least 1 option', 'error');
    }
  }

  function editCustomization() {
    if (customizationName && options.length > 0) {
      const payload = {
        name: customizationName,
        available_selection: countAllowed,
        required: isRequired,
        options: options,
      }
      onEditSelectedCustomizations(selectedCustomizations, selectedCustomizationIndex, payload);
      onClose();
    } else if (!customizationName) {
      showMessage('Customization name was not set', 'error');
    } else {
      showMessage('Please, add at least 1 option', 'error');
    }
  }

  async function deleteCustomization() {
    const customizationToDelete = selectedCustomizations.find((x, i) => i === selectedCustomizationIndex);
    onRemoveSelectedCustomizations(selectedCustomizations, selectedCustomizationIndex);
    
    const onOk = () => onClose();
    const onFail = (errMessage) => {
      onClose();
      showMessage(errMessage, 'error');
    }

    return await restaurantServices.deleteCustomizationOfFoodById({ restaurantId, foodId: editFood.id, customizationId: customizationToDelete.id }, onOk, onFail);
  }

  function closeAsideMenu() {
    onClose();
  }

  return (
    <DrawerCustomizationAddContainer
      placement={placement}
      onClose={onClose}
      height='fit-content'
      visible={visible}
    >
      <Box display='flex' flexDirection='row' alignItems='flex-start' justifyContent='space-between' padding='0 26px' marginBottom={30}>
        <SingleText size={22} weight={500}>{selectedCustomizationIndex !== undefined ? 'Edit Customization' : 'Add Customization'}</SingleText>

        {selectedCustomizationIndex !== undefined
          ? <FaTrash className='cursor' size={20} color='white' onClick={() => deleteCustomization()} />
          : ''}
      </Box>
      <Box className='drawer-menu--option' padding='10px 35px'>
        <Box>
          <SingleText content='Name' size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>Name</SingleText>
          <input
            type="text"
            placeholder="Enter name"
            style={{
              display: 'block',
              width: '100%',
              backgroundColor: 'transparent',
              border: 'unset',
              outline: 'unset',
              paddingLeft: 0,
              paddingTop: 5,
              color: '#fff'
            }}
            value={customizationName}
            onChange={(e) => setCustomizationName(e.target.value)}
          />
          {/* <Input
            placeholder="Enter name"
            bordered={false}
            style={{ paddingLeft: 0, color: '#fff' }}
            value={customizationName}
            onChange={(e) => setCustomizationName(e.target.value)}
          /> */}
        </Box>
      </Box>
      <Box display='flex' flexDirection='column' alignItems='flex-start' marginBottom={61}>
        <SortableList
          onSortEnd={onSortEnd}
          helperClass="row-dragging"
          helperContainer={() => document.getElementById('drag-customization-options')}
          useDragHandle
        >
          {options.map((value, index) => (
            <SortableItem key={`item-${value.name}`} sortIndex={index} index={index} value={value} />
          ))}
        </SortableList>
        {/* <List
            values={options}
            onChange={onSortEnd}
            renderList={({ children, props }) => <SortableListContainer {...props} id='drag-customization-options'>{children}</SortableListContainer>}
            renderItem={({ value, props }) => 
              <div {...props}>
              <Box 
                display='flex' 
                justifyContent='space-between' 
                alignItems='center' 
                padding='15px 35px'
                borderTop='solid 1px rgba(255, 255, 255, 0.1)'
                borderBottom='solid 1px rgba(255, 255, 255, 0.1)'
                background={colors.gray1}
                className='drag-visible'
              >
                <DragHandle />
                <Box display='flex' flexDirection='column' justifyContent='start' marginRight='auto' marginLeft={19}>
                  <SingleText size={14} weight={600} spacing='-0.34px'>{value}</SingleText>
                </Box>
                <FaPencilAlt className='cursor' size={ 20 } color={'white'} onClick={() => console.log("ehere")} />
              </Box>
              </div>
            }
          /> */}
        <Box
          className='cursor'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          padding='15px 35px'
          borderTop='solid 1px rgba(255, 255, 255, 0.1)'
          borderBottom='solid 1px rgba(255, 255, 255, 0.1)'
          background={colors.gray1}
          onClick={() => setOpenDrawerAddOption(true)}
        >
          <SingleText content='Add Option' className='cursor' color={colors.redColor} size={14} weight={500} spacing='-0.34px' lineHeight={1.57}>Add Option</SingleText>
        </Box>
      </Box>
      <Box className='custom-options' marginBottom={47}>
        <Box className='custom-option' borderBottom='solid 1px rgba(255, 255, 255, 0.1)'>
          <SingleText content='Selections Allowed' weight={500} size={14} lineHeight={1.57} spacing='-0.34px'>Selections Allowed</SingleText>
          <SingleNumberIncrease value={countAllowed} onIncrease={() => setCountAllowed(countAllowed + 1)} onDecrease={() => countAllowed > 0 && setCountAllowed(countAllowed - 1)} />
        </Box>
        <Box className='custom-option'>
          <SingleText content='Required' weight={500} size={14} lineHeight={1.57} spacing='-0.34px'>Required</SingleText>
          <SingleAntSwitch defaultChecked checked={isRequired} onChange={() => setIsRequired(!isRequired)} />
        </Box>
      </Box>
      <Box display='flex' flexDirection='column' padding='0 26px'>
        <SingleButton
          title={selectedCustomizationIndex !== undefined ? 'Edit Customization' : 'Add Customization'}
          margin='0 0px 20px'
          onClick={selectedCustomizationIndex !== undefined ? editCustomization : addCustomization}
        />
        <SingleButton
          title='Cancel'
          btnColor='transparent'
          txtColor={colors.redColor}
          margin='0 0px 20px'
          onClick={closeAsideMenu}
        />
      </Box>
    </DrawerCustomizationAddContainer>
  )
}

DrawerCustomizationAdd.propTypes = {
  placement: PropTypes.string,
  visible: PropTypes.bool,
  editFood: PropTypes.object,
  selectedCustomizations: PropTypes.array,
  onAddSelectedCustomizations: PropTypes.func,
  onEditSelectedCustomizations: PropTypes.func,
  onRemoveSelectedCustomizations: PropTypes.func,
  onClose: PropTypes.func,
  onClickDay: PropTypes.func,
  onClickDeleteItem: PropTypes.func,
};
