import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerPriceTierWrapper = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  .status-price-change-panel-container {
    font-family: Montserrat;
    position: relative;
    height: 100%;
    .panel-header-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      .back-arrow {
        width: 50px;
        cursor: pointer;
        position: absolute;
        left: 0;
        i {
          color: #ffffff;
          font-size: 22px;
        }
      }
      .panel-header-context {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
      }
    }

    .panel-status-container {
      .status-button {
        cursor: pointer;
        height: 60px;
        width: 100%;
        border-radius: 16px;
        border: solid 1px #5b5e61;
        font-size: 14px;
        font-weight: 500;
        color: #ed2626;
        display: flex;
        align-items: center;
        padding: 0 25px;
        &.active {
          border: solid 1px #fff;
        }
        &.space-bottom {
          margin-bottom: 15px;
        }
      }
      .status-button:hover {
        opacity: 0.9;
      }
    }

    .save-button-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      .save-button {
        cursor: pointer;
        width: 100%;
        height: 50px;
        border-radius: 8px;
        background-color: #ed2626;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        border: none;
        outline: unset;
      }
      .save-button:hover {
        opacity: 0.9;
      }
    }
  }
`;
