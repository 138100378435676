import React from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { sortableElement } from 'react-sortable-hoc';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import { DragHandle } from '../DragHandle';

export const SortableItem = sortableElement(({ value, onClick }) => (
  <Box
    display='flex'
    justifyContent='space-between'
    alignItems='center'
    height={62}
    padding='15px 35px'
    borderTop='solid 1px rgba(255, 255, 255, 0.1)'
    borderBottom='solid 1px rgba(255, 255, 255, 0.1)'
    background='transparent'
    className='drag-visible'
  >
    <DragHandle />
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='start'
      marginRight='auto'
      marginLeft={19}
    >
      <SingleText size={14} weight={600} spacing='-0.34px'>
        {value}
      </SingleText>
    </Box>
    <FaPencilAlt
      className='cursor'
      size={20}
      color={'white'}
      onClick={onClick}
    />
  </Box>
));
