export class Size {
  constructor(width, height) {
    this.width = width;
    this.height = height;
  }

  getWidth() {
    return this.width;
  }

  getHeight() {
    return this.height;
  }

  getSize() {
    return {
      width: this.getWidth(),
      height: this.getHeight(),
    };
  }
}
