import React from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft } from 'react-icons/fa';
import { DrawerAssignItemContainer } from './style';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { Assign } from './Assign';

export default function DrawerAssignItem({
  title = '',
  categories,
  visible,
  onClose,
  value,
  onSubmit
}) {

  return (
    <DrawerAssignItemContainer visible={visible} onClose={onClose}>
      Drawer Assign Item
      <Box className='drawer-assign-item-top'>
        <SingleIconWrapper className='drawer-top-exit-icon' onClick={onClose}>
          <FaChevronLeft color='white' />
        </SingleIconWrapper>
      </Box>
      <SingleText
        color='#bbbbbb'
        spacing='0.27px'
        marginLeft={33}
        marginBottom={10}
        weight={600}
      >
        Assign Item
      </SingleText>
      {title &&
        <SingleText
          width='336px'
          size={22}
          lineHeight='33px'
          spacing={'0'}
          marginLeft={33}
          marginBottom={0}
        >
          {title}
        </SingleText>
      }
      <Assign
        categories={categories}
        value={value}
        onSubmit={onSubmit}
      />
    </DrawerAssignItemContainer>
  );
}

DrawerAssignItem.propTypes = {
  title: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.arrayOf(PropTypes.number),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
