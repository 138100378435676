import React from 'react';
import PropTypes from 'prop-types';
import { NumberItemContainer } from './style';
import SingleText from 'components/SingleText';

export function NumberItem({ label, dots, value }) {
  return (
    <NumberItemContainer>
      <SingleText
        size={15}
        spacing='-0.36px'
        color='rgba(255, 255, 255, 0.6)'
        marginBottom={5}
      >
        {label}
      </SingleText>
      <SingleText size={20} spacing='0.48px' weight='bold'>
        {`${dots} ${value}`}
      </SingleText>
    </NumberItemContainer>
  );
}

NumberItem.propTypes = {
  label: PropTypes.string,
  dots: PropTypes.string,
  value: PropTypes.string,
};
