import React, { useState } from 'react';
import PropTypes from 'prop-types';
// const dummy = {
//   "id": 4,
//   "title": "Restaurant 1",
//   "description": "Restaurant 1",
//   "photo": {
//     "id": 2,
//     "file": "https://media-cdn.tripadvisor.com/media/photo-s/0e/cc/0a/dc/restaurant-chocolat.jpg",
//     "file_name": "restaurant--1603684259721.jpg",
//     "asset_type": "restaurant",
//     "createdAt": "2020-10-26T03:51:02.792Z",
//     "updatedAt": "2020-10-26T03:51:02.792Z"
//   },
//   "gallery": [
//     {
//       "id": 3,
//       "file": "https://assetfoodieave.s3.amazonaws.com/restaurant--1603695534292.jpg",
//       "file_name": "restaurant--1603695534292.jpg",
//       "asset_type": "restaurant",
//       "createdAt": "2020-10-26T06:58:57.509Z",
//       "updatedAt": "2020-10-26T06:58:57.509Z"
//     },
//     {
//       "id": 4,
//       "file": "https://assetfoodieave.s3.amazonaws.com/restaurant--1603695555545.jpg",
//       "file_name": "restaurant--1603695555545.jpg",
//       "asset_type": "restaurant",
//       "createdAt": "2020-10-26T06:59:18.729Z",
//       "updatedAt": "2020-10-26T06:59:18.729Z"
//     }
//   ],
//   "fees_tax": null,
//   "is_favourite": true,
//   "phone_number": "15417543010",
//   "email": "restaurant1@gmail.com",
//   "website": "",
//   "building_number": "871-B",
//   "street": "Melrose Ave",
//   "city": "West Hollywood",
//   "state_code": "CA",
//   "zip_code": "97869",
//   "country": "United States",
//   "address": "871-B Melrose Ave, West Hollywood, CA, United States",
//   "location": [
//     30.2918842,
//     -81.3927381
//   ],
//   "working_hours": [
//     {
//       "day": 0,
//       "times": [
//         [
//           39600,
//           50400
//         ],
//         [
//           61200,
//           75600
//         ]
//       ],
//       "timezone": "GMT-4"
//     },
//     {
//       "day": 1,
//       "times": [
//         [
//           39600,
//           50400
//         ],
//         [
//           61200,
//           75600
//         ],
//         [
//           76000,
//           84000
//         ]
//       ],
//       "timezone": "GMT-4"
//     }
//   ],
//   "scores": [
//     4,
//     4,
//     3
//   ],
//   "reviews_count": 4,
//   "price_level": 4,
//   "cuisines": [
//     "Healthy",
//     "Sushi"
//   ],
//   "distance": 1235
// }
const DataContext = React.createContext(null);

export { DataContext };

function DataContextContainer(props) {
  const [restaurantProfile, setRestaurantProfile] = useState({});
  const [menus, setMenus] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState({ availability: [], status: 0 });
  const [selectedAvailability, setSelectedAvailability] = useState([]);
  const [selectedDayKey, setSelectedDayKey] = useState(0);
  const [selectedAvailableTime, setSelectedAvailableTime] = useState({});
  const [selectedMenuActiveStatus, setSelectedMenuActiveStatus] = useState(0);
  const [isMenusEditing, setMenusEditing] = useState(false);

  const [chosenMenu, setChosenMenu] = useState();
  const [chosenCategory, setChosenCategory] = useState();
  const [chosenStaff, setChosenStaff] = useState({});
  const [staffToAdd, setStaffToAdd] = useState();

  const [floorDetails, setFloorDetails] = useState([]);
  const [floorForEditId, setFloorForEditId] = useState();
  const [selectedFloorId, setSelectedFloorId] = useState();
  const [floorWalls, setFloorWalls] = useState([]);
  const [getTableList, setTableList] = useState([]);
  const [getTableForEditTemp, setTableForEditTemp] = useState();
  const [wallForEdit, setWallForEdit] = useState();

  // Customizations
  const [options, setOptions] = useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState();
  const [selectedCustomizationIndex, setSelectedCustomizationIndex] =
    useState();

  const [orderItemForDelete, setOrderItemForDelete] = useState();
  const [fullOrderForDelete, setFullOrderToDelete] = useState();

  const [checkInData, setCheckInData] = useState();
  const changeTableForCheckInData = (table) =>
    setCheckInData({ ...checkInData, table });

  const [selectedFood, setSelectedFood] = useState('');
  
  return (
    <DataContext.Provider
      value={{
        restaurantProfile,
        setRestaurantProfile,
        menus,
        setMenus,
        chosenMenu,
        setChosenMenu,
        chosenCategory,
        setChosenCategory,
        selectedMenu,
        setSelectedMenu,
        selectedAvailability,
        setSelectedAvailability,
        selectedDayKey,
        setSelectedDayKey,
        selectedAvailableTime,
        setSelectedAvailableTime,
        chosenStaff,
        setChosenStaff,
        staffToAdd,
        setStaffToAdd,
        selectedMenuActiveStatus, 
        setSelectedMenuActiveStatus,
        isMenusEditing, 
        setMenusEditing,

        floorDetails,
        setFloorDetails,
        floorForEditId,
        setFloorForEditId,
        selectedFloorId,
        setSelectedFloorId,
        floorWalls,
        setFloorWalls,
        getTableList,
        setTableList,
        getTableForEditTemp,
        setTableForEditTemp,
        wallForEdit,
        setWallForEdit,

        options,
        setOptions,
        selectedOptionIndex,
        setSelectedOptionIndex,
        selectedCustomizationIndex,
        setSelectedCustomizationIndex,

        orderItemForDelete,
        setOrderItemForDelete,
        fullOrderForDelete,
        setFullOrderToDelete,

        checkInData,
        setCheckInData,
        changeTableForCheckInData,

        selectedFood,
        setSelectedFood,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}

DataContextContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DataContextContainer;
