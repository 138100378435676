import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerDetailNutritionForNewItemInOrderContainer = styled(
  SingleDrawer
)`
  background-color: ${colors.gray5};
  padding: 37px 34px 15px;
  display: flex;
  flex-direction: column;
  height: 728px !important;

  .top-part {
    width: 100%;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nutrition-title {
      font-size: 20px;
      letter-spacing: 0.38px;
      color: white;
    }

    .close-icon {
      i {
        font-size: 28px;
        font-weight: normal;
        letter-spacing: 0.53px;
        color: ${colors.redColor};
      }
    }
  }

  .food-nutrition-detail {
    &.calories-item {
      .core {
        .label {
          font-size: 18px;
        }

        .value {
          font-size: 18px;
        }
      }
    }
  }

  .description {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.29px;
    color: rgba(255, 255, 255, 0.3);
    margin-top: 18px;
    margin-bottom: 23px;
  }
`;
