import React from 'react';
import PropTypes from 'prop-types';
import { FieldContainer } from './style';

export function Field({
  label,
  value = '',
  placeholder = '',
  onClick = () => {},
  isVerified = false,
}) {
  return (
    <FieldContainer onClick={onClick}>
      <div className='field-container'>
        <label>{label}</label>
        <div className={`value-container ${value ? '' : 'empty'}`}>
          <span className='value-title'>{value ? value : placeholder}</span>
        </div>
      </div>
      <div className='click-icon'>
        {value.length ? (
          isVerified ? (
            <div className='verified-icon'>
              <i className='fas fa-check-circle' />
            </div>
          ) : (
            <span className='unverified-title'>Unverified</span>
          )
        ) : (
          ''
        )}
        <i className='fas fa-chevron-right' />
      </div>
    </FieldContainer>
  );
}

Field.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  isVerified: PropTypes.bool,
};
