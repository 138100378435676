import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import SingleAntSwitch from 'components/SingleAntSwitch';
import { dayTitles } from 'services/static';
import { StateContext } from 'contexts/StateContextContainer';
import { TimePicker } from 'components/TimePicker';
import { DrawerBusinessHoursSetTimeWrapper } from './style';

const DrawerBusinessHoursSetTime = ({
  visible = false,
  placement = 'right',
  onClose = () => {},
  workingHours = [],
  onChange = () => {},
}) => {
  const { selectedDayOfWeek } = useContext(StateContext);
  const [isOpen, setOpen] = useState(true);
  const [isAllDay, setAllDay] = useState(false);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const [selectedTime, setSelectedTime] = useState([]);

  useEffect(() => {
    if (selectedDayOfWeek) {
      const dayNumeric = dayTitles.find(
        ({ content: dayContent }) => dayContent === selectedDayOfWeek
      ).num;
      const isDayWasSelected = workingHours.find(
        (selectedDay) => selectedDay.day === dayNumeric
      );
      if (isDayWasSelected) {
        const timeRange = isDayWasSelected.times[0];
        setSelectedTime(timeRange);
        if (timeRange[0] === 0 && timeRange[1] === 86400) {
          setAllDay(true);
        } else {
          setAllDay(false);
        }
      } else {
        setOpen(true);
        setAllDay(false);
        setSelectedTime([]);
      }
    }
    // eslint-disable-next-line
  }, [selectedDayOfWeek]);

  function setDayTime() {
    let newWorkingHours = [];
    if (isOpen) {
      const timezone = (new Date().getTimezoneOffset() / 60) * -1;
      const _workingHours = {
        day: dayTitles.find(
          ({ content: dayContent }) => dayContent === selectedDayOfWeek
        ).num,
        times: isAllDay ? [[0, 86400]] : [[startTime, endTime]],
        timezone: `GMT${timezone > 0 ? '+' : ''}${timezone}`,
      };

      newWorkingHours = [
        ...workingHours.filter(({ day }) => day !== _workingHours.day),
        _workingHours,
      ];

      setSelectedTime([startTime, endTime]);
    } else {
      newWorkingHours = [
        ...workingHours.filter(
          (x) =>
            x.day !==
            dayTitles.find(
              ({ content: dayContent }) => dayContent === selectedDayOfWeek
            ).num
        ),
      ];
    }

    onChange(newWorkingHours);
  }

  return (
    <DrawerBusinessHoursSetTimeWrapper
      onClose={onClose}
      visible={visible}
      placement={placement}
    >
      <div className='configure-business-hours-panel'>
        <div className='panel-header-container'>
          <div className='back-arrow' onClick={onClose}>
            <i className='fas fa-chevron-left' />
          </div>
          <div className='panel-header-context'>{selectedDayOfWeek}</div>
        </div>
        <div className='panel-status-container'>
          <div className='open-control-container'>
            <div className='is-open-container'>
              <div className='left-part'>
                <p className={`${isOpen ? 'is-open' : ''}`}>Open</p>
              </div>
              <div className='right-part'>
                <SingleAntSwitch
                  checked={isOpen}
                  onChange={() => setOpen(!isOpen)}
                />
                {/* <Switch defaultChecked onChange={setOpen} checked={isOpen} /> */}
              </div>
            </div>

            <div className='is-open-container'>
              <div className='left-part'>
                <p className={`${isAllDay ? 'is-open' : ''}`}>All Day</p>
              </div>
              <div className='right-part'>
                <SingleAntSwitch
                  checked={isAllDay}
                  onChange={() => setAllDay(!isAllDay)}
                />
                {/* <Switch onChange={setAllDay} checked={isAllDay} /> */}
              </div>
            </div>

            {!isAllDay && (
              <>
                <p className='set-time-header'>Set Your Time</p>
                <div className='time-picker-wrapper'>
                  <TimePicker
                    selectedTime={setStartTime}
                    value={selectedTime[0]}
                  />
                  <div className='arrow-icon-container'>
                    <i className='fas fa-long-arrow-alt-right' />
                  </div>
                  <TimePicker
                    selectedTime={setEndTime}
                    value={selectedTime[1]}
                  />
                </div>
              </>
            )}

            <div className='button-container'>
              <button
                type='button'
                className='submit-button'
                onClick={() => setDayTime()}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </DrawerBusinessHoursSetTimeWrapper>
  );
};

export default DrawerBusinessHoursSetTime;

DrawerBusinessHoursSetTime.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  workingHours: PropTypes.array,
  onChange: PropTypes.func,
};
