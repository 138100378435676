/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AllReviewsContainer } from './style';
import { DefaultUser } from 'assets/images/profile';
import moment from 'moment';
export function AllReviews({ reviewsData }) {
  
  return (
    <AllReviewsContainer>
      {reviewsData.map((_e, _i) => {

        let lastName = _e.author.last_name
        let strLastName = String(lastName)
        let charLastName = strLastName.charAt(0)
        
        function getDurationText(date = _e.date) {
          return moment(date * 1000).fromNow();
        }

        useEffect(() => {
          getDurationText();
        }, [getDurationText]);

        return (
          <div className='review-item' key={'food-review-item' + _i}>
            <div className='avatar-plus-rating-container'>
              <div
                className='avatar'
                style={{
                  backgroundImage: `url(${
                    _e.author.avatar?.file ?? DefaultUser
                  })`,
                }}
              />

              <div className='main-info-container'>
                <div className='who-and-when'>
                  <span className='who-title'>
                    {_e.author.first_name} <span>{charLastName}</span>
                  </span>
                  <span className='when-title'>{getDurationText()}</span>
                </div>
                <div className='rating-container'>
                  {new Array(5).fill(undefined).map((_, index) => (
                    <i
                      className={`fas fa-star ${
                        index + 1 <= Math.floor(_e.rating) ? 'active' : 'hidden'
                      }`}
                      key={index + 1}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className='review-main'>
              <p className='review-text'>{_e.comment}</p>
            </div>
            <div className='feasts-for-review'>
              <div className='feasts-item'>
                <div className="feast-here">
                  <div className='feasts-value-container'>
                    <span className='feasts-value'>
                      {_e.author.here_activity}
                    </span>
                  </div>
                  <div className='feasts-title-container'>
                    <span className='feasts-title'>Feast here</span>
                  </div>
                </div>
                <div>
                  <div className='feasts-value-container'>
                    <span className='feasts-value'>
                      {_e.author.total_activity}
                    </span>
                  </div>
                  <div className='feasts-title-container'>
                    <span className='feasts-title'>Feast total</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </AllReviewsContainer>
  );
}

AllReviews.propTypes = {
  reviewsData: PropTypes.array
};
