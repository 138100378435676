import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types';
// import './style.scss';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { showMessage } from 'services/functions';
import { restaurantServices } from 'services/api-services';
import { StateContext } from 'contexts/StateContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { DrawerMoveItemsContainer } from './style';
import { colors } from 'theme';

export default function DrawerMoveAndDeleteMenu({
  visible = false,
  placement = 'bottom',
  onClose = () => { },
}) {

  const { setOpenDrawerMoveItemsInMenu, setOpenDrawerDeleteMenu, setOpenDrawerMenuEdit } = useContext(StateContext);
  const { restaurantId } = useContext(AuthContext);
  const { menus, selectedMenu, setSelectedMenu } = useContext(DataContext);
  const [option, setOption] = useState('');

  const onClickMove = async () => {
    const { ok, data, errMessage } = await restaurantServices.getFoodsByMenu({ restaurantId, menuId: selectedMenu.id });
    if (ok) {
      const foodsToMove = data.map(x => x.id);

      const moveFood = await restaurantServices.moveFoodToOtherMenu({ restaurantId, menuId: selectedMenu.id }, { to_menu_id: option, food_ids: [...foodsToMove] });
      if (moveFood.ok) {
            const deleteMenu = await restaurantServices.deleteMenu({ restaurantId, menuId: selectedMenu.id });
            if (deleteMenu.ok) {
              setOpenDrawerMoveItemsInMenu(false);
              setOpenDrawerDeleteMenu(false);
              setOpenDrawerMenuEdit(false);
              setSelectedMenu({ availability: [], status: 0 });
            } else {
              showMessage(deleteMenu.errMessage, 'error')
            }
      } else {
        showMessage(moveFood.errMessage, 'error')
      }
    } else {
      showMessage(errMessage, 'error')
    }
  }

  return (
    <DrawerMoveItemsContainer
      placement={placement}
      onClose={onClose}
      height='fit-content'
      visible={visible}
    >
      <Box display='flex' flexDirection='column' alignItems='flex-start' marginBottom={30}>
        <SingleText size={22} weight={500} marginBottom={10}>Move items to</SingleText>
      </Box>
      <Box className='drawer-move--options'>
        {menus.map((menu, _i) => {
          if (selectedMenu?.id !== menu.id) {
            return (
              <Box key={_i} className={`drawer-move--option ${menu.id === option ? 'active' : 'inactive'}`} marginBottom={15} onClick={() => setOption(menu.id)}>
                <SingleText size={14} weight={500} spacing='-0.34'>{menu.name}</SingleText>
              </Box>
            )
          }
          return '';
        })}
      </Box>
      <Box display='flex' flexDirection='column'>
        <SingleButton
          title='Apply'
          margin='0 0 20px'
          btnColor={option ? colors.redColor : colors.gray}
          onClick={() => option ? onClickMove() : showMessage('Chose Menu first', 'error')}
        />
        <SingleButton
          title='Reset'
          btnColor='transparent'
          txtColor={colors.redColor}
          margin='0 0 20px'
          onClick={onClose}
        />
      </Box>
    </DrawerMoveItemsContainer>
  )
}

DrawerMoveAndDeleteMenu.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickMove: PropTypes.func,
};
