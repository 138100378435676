import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { OtherRequestPendingDrawerContainer } from './style';
import { OtherConfirmRequest } from './OtherConfirmRequest';
import { modalContext } from 'routes';
import { OtherRejectItem } from './RejectItem';
import { RequestPendingContext } from 'contexts/RequestPendingContextContainer';
export function OtherRequestPendingDrawer({ visible, onClose }) {
  
  const context = useContext(modalContext);
  const {
    pendingInfo,
    setPendingInfo,
    otherStatus,
    setOtherStatus,
    otherOptionsText,
    setOtherOptionsText,
    otherOptionsDigit,
    setOtherOptionsDigit,
  } = useContext(RequestPendingContext);

  // other confirm request drawer
  const [openOtherConfirmRequest, setOpenOtherConfirmRequest] = useState(false);
  const onOpenOtherConfirmRequest = () => {
    setOpenOtherConfirmRequest(true);
    context.setModal('otherRequestModal-1');
  };
  const onCloseOtherConfirmRequest = () => setOpenOtherConfirmRequest(false);

  // other reject item drawer
  const [openOtherRejectItem, setOpenOtherRejectItem] = useState(false);
  const onOpenOtherRejectItem = () => {
    setOpenOtherRejectItem(true);
    context.setModal('otherRejectItem-1');
  };
  const onCloseOtherRejectItem = () => setOpenOtherRejectItem(false);

  if(otherOptionsDigit === 2){
    setOtherOptionsText('Fulfilled')
  } else if (otherOptionsDigit === 1){
    setOtherOptionsText('Reject')
  }

  return (
    <OtherRequestPendingDrawerContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      <div className='other-close-icon other-wrapper-sidebar'>
        <i
          className='fas fa-chevron-left other-left'
          onClick={onClose}
          aria-hidden='true'
        />

        <div className='other-text-align'>
          <p>
            Choose how to resolve
            <br />
            this item.
          </p>
        </div>

        <div>
          <p
            className='other-border-style'
            onClick={() => {
              onOpenOtherConfirmRequest()
              setOtherStatus(1)
              setOtherOptionsDigit(2)
              // --
              const tempArray = pendingInfo.items.map((item) => {
                return {
                  ...item,
                  selectedResolveType: item.selected
                    ? 2
                    : item.selectedResolveType,
                };
              });
              setPendingInfo({ ...pendingInfo, items: tempArray });
              // --
            }}
            aria-hidden='true'
          >
            <span style={{ color: '#ffffff' }}>Fulfill Item</span>
          </p>
          <p
            className='other-border-style'
            onClick={() => {
              onOpenOtherRejectItem()
              setOtherStatus(1)
              setOtherOptionsDigit(1)
              // --
              const tempArray = pendingInfo.items.map((item) => {
                return {
                  ...item,
                  selectedResolveType: item.selected
                    ? 1
                    : item.selectedResolveType,
                };
              });
              setPendingInfo({ ...pendingInfo, items: tempArray });
              // --
            }}
            aria-hidden='true'
          >
            <span style={{ color: '#ffffff' }}>Reject Item</span>
          </p>
        </div>
      </div>

      <OtherConfirmRequest
        visible={openOtherConfirmRequest}
        onClose={onCloseOtherConfirmRequest}
      />

      <OtherRejectItem
        visible={openOtherRejectItem}
        onClose={onCloseOtherRejectItem}
      />
    </OtherRequestPendingDrawerContainer>
  );
}

OtherRequestPendingDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
