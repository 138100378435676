import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerPriceTierWrapper } from './style';

const DrawerPriceTier = ({
  visible = false,
  placement = 'right',
  onClose = () => {},
  priceLevel,
  onChange = () => {},
}) => {
  const [selectedStatus, setSelectedStatus] = useState();

  useEffect(() => {
    if (visible) {
      if (priceLevel) {
        setSelectedStatus(priceLevel);
      }
    }
  }, [visible, priceLevel]);

  const onSubmit = () => {
    onChange({ priceLevel: selectedStatus });
    onClose();
  };

  return (
    <DrawerPriceTierWrapper
      onClose={onClose}
      visible={visible}
      placement={placement}
    >
      <div className='status-price-change-panel-container'>
        <div className='panel-header-container'>
          <div className='back-arrow' onClick={onClose}>
            <i className='fas fa-chevron-left' />
          </div>
          <div className='panel-header-context'>Price Tier</div>
        </div>

        <div className='panel-status-container'>
          <div
            className={`status-button space-bottom ${
              selectedStatus === 1 ? 'active' : ''
            }`}
            onClick={() => setSelectedStatus(1)}
          >
            $
          </div>
          <div
            className={`status-button space-bottom ${
              selectedStatus === 2 ? 'active' : ''
            }`}
            onClick={() => setSelectedStatus(2)}
          >
            $$
          </div>
          <div
            className={`status-button space-bottom ${
              selectedStatus === 3 ? 'active' : ''
            }`}
            onClick={() => setSelectedStatus(3)}
          >
            $$$
          </div>
          <div
            className={`status-button ${selectedStatus === 4 ? 'active' : ''}`}
            onClick={() => setSelectedStatus(4)}
          >
            $$$$
          </div>
        </div>

        <div className='save-button-container'>
          <button
            className='save-button'
            onClick={selectedStatus ? onSubmit : () => {}}
          >
            Save
          </button>
        </div>
      </div>
    </DrawerPriceTierWrapper>
  );
};

export default DrawerPriceTier;

DrawerPriceTier.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  priceLevel: PropTypes.number,
  onChange: PropTypes.func,
};
