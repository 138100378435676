import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CompletedComponentContainer } from './style';
import { GeneralStatistic } from './GeneralStatistic';
import { CompletedOrders } from './CompletedOrders';
import { CompletedNotify } from './CompletedNotify';
import { DrawerPartialRefundContainer } from './DrawerPartialRefundContainer';
import { DrawerFullRefundContainer } from './DrawerFullRefundContainer';

export function CompletedComponent({
  openDrawerFullRefundContainer,
  onCloseDrawerFullRefundContainer,
  openDrawerPartialRefundContainer,
  onCloseDrawerPartialRefundContainer,
}) {
  // @TODO Remove commented when Orders API will be full fledged in back-end

  // const { restaurantId } = useContext(AuthContext);
  // // eslint-disable-next-line no-unused-vars
  // const [ordersData, setOrdersData] = useState([]);
  // // eslint-disable-next-line no-unused-vars
  // const [selectedOrder, setSelectedOrder] = useState({});

  // useEffect(() => {
  //   getOrderDetails();
  //   // eslint-disable-next-line
  // }, []);

  // async function getOrderDetails() {
  //   const { ok, data, errMessage } =
  //     await restaurantServices.getRestaurantOrders(restaurantId, {
  //       date: '2021-01-05',
  //     });
  //   if (ok) {
  //     setOrdersData(data.orders);
  //   } else {
  //     showMessage(errMessage, 'error');
  //   }
  // }

  return (
    <CompletedComponentContainer>
      <Container>
        <Row>
          <Col lg={8}>
            <GeneralStatistic />
            <CompletedOrders />
          </Col>
          <Col lg={4}>
            <CompletedNotify />
          </Col>
        </Row>
      </Container>
      <DrawerPartialRefundContainer
        visible={openDrawerPartialRefundContainer}
        onClose={onCloseDrawerPartialRefundContainer}
      />
      <DrawerFullRefundContainer
        visible={openDrawerFullRefundContainer}
        onClose={onCloseDrawerFullRefundContainer}
      />
    </CompletedComponentContainer>
  );
}

CompletedComponent.propTypes = {
  openDrawerPartialRefundContainer: PropTypes.bool,
  onCloseDrawerPartialRefundContainer: PropTypes.func,
  openDrawerFullRefundContainer: PropTypes.bool,
  onCloseDrawerFullRefundContainer: PropTypes.func,
};
