import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';
import { OopsAddingFailed } from 'assets/images/menu';

export const DrawerAddingFailedContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  padding: 90px 26px 33px;
  height: 588px !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  .failed-image {
    width: 100%;
    height: 210px;
    background-size: cover;
    background-position: center;
    background-image: url(${OopsAddingFailed});
    margin-bottom: 61px;
  }
`;
