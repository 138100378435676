import React from 'react';
import PropTypes from 'prop-types';
import { DrawerAddingFailedContainerStyled } from './style';
import { DrawerAddingFailed } from './DrawerAddingFailed';

export function DrawerAddingFailedContainer({ visible, tryAgain, onCancel }) {
  return (
    <DrawerAddingFailedContainerStyled
      visible={visible}
      placement='right'
      onClose={onCancel}
    >
      <DrawerAddingFailed
        visible={true}
        tryAgain={tryAgain}
        onCancel={onCancel}
      />
    </DrawerAddingFailedContainerStyled>
  );
}

DrawerAddingFailedContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};
