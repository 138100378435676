import React from 'react';
import PropTypes from 'prop-types';
import { DrawerDeleteOrderContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerDeleteOrder({ visible, onClose, onDelete }) {
  return (
    <DrawerDeleteOrderContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='trash-delete-icon'>
        <i className='fas fa-trash' />
      </div>
      <SingleText
        className='delete-order-title'
        size={22}
        spacing='0.42px'
        marginBottom={10}
        align='center'
      >
        Delete Order
      </SingleText>
      <SingleText
        spacing='0.27px'
        lineHeight='21px'
        align='center'
        marginBottom={32}
      >
        The entire order will be deleted.
      </SingleText>
      <SingleButton
        title='Delete'
        margin='0px 0px 20px 0px'
        onClick={onDelete}
      />
      <SingleButton
        title='Keep'
        btnColor={colors.lightGray}
        onClick={onClose}
      />
    </DrawerDeleteOrderContainer>
  );
}

DrawerDeleteOrder.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};
