import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerStatusContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  .drawer-status--options {
    display: flex;
    flex-direction: column;
    align-items: center;
    .drawer-status--option {
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 62px;
      padding: 0 26px;
      border-radius: 16px;
      border: solid 1px rgba(255, 255, 255, 0.1);
      &.active {
        border: solid 1px #ffffff;
      }
    }
  }
`;
