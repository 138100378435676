import styled from '@emotion/styled';
import { colors } from 'theme';

import { ThisAppIsNotSupported } from 'assets/images/ThisAppIsNotSupported';
import { DownloadOnTheAppStore } from 'assets/images/icons';

export const ThisAppIsNotSupportedContainer = styled.div`
  background-color: ${colors.black1};
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  .this-app-main-image-container {
    width: 100%;
    height: calc(100vw * 1.17);

    .this-app-is-not-supported {
      width: 100%;
      height: 101%;
      background-position: top;
      background-repeat: no-repeat;
      background-size: 100%;
      background-image: url(${ThisAppIsNotSupported});
    }
  }

  .is-not-supported-container {
    width: 100%;
    height: calc(100vw + 60px);
    background-color: ${colors.black1};
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 500px) {
      height: calc(70vw + 60px) !important;
    }

    .not-supported-title {
      font-family: 'SFProText';
      font-size: 25px;
      font-weight: bold;
      line-height: 35px;
      letter-spacing: -0.6px;
      width: 278px;
      margin-top: 27px;
      margin-bottom: 15px;
      color: white;
      text-align: center;
    }

    .not-supported-text {
      font-family: 'SFProText';
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.43px;
      width: 278px;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 30px;
      text-align: center;
    }

    .download-ios-app-link {
      .download-ios-app-icon {
        width: 192px;
        height: 64px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(${DownloadOnTheAppStore});
      }
    }
  }
`;
