import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { DrawerProcessPaymentContainer } from './style';
import { colors } from 'theme';
import { paymentMethods } from './paymentMethods';
import { creditCardManufacturer } from 'services/functions';
import { ActiveOrdersContext } from 'contexts/ActiveOrdersContextContainer';
import { CompletedOrdersContext } from 'contexts/CompletedOrdersContextContainer';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { PaymentCard } from './PaymentCard';
import { PaymentWay } from './PaymentWay';
import { DrawerClassDetailsContainer } from './DrawerClassDetailsContainer';
import { DrawerProcessPaymentAreYouSureContainer } from './DrawerProcessPaymentAreYouSureContainer';
import { DrawerAreYouSureNoPaymentContainer } from './DrawerAreYouSureNoPaymentContainer';

export function DrawerProcessPayment({ visible, onClose }) {
  const { activeOrder, moveActiveOrderToCompletedSection } =
    useContext(ActiveOrdersContext);
  const { onAddToCompletedOrders } = useContext(CompletedOrdersContext);

  const [
    openDrawerProcessPaymentAreYouSureContainer,
    setOpenDrawerProcessPaymentAreYouSureContainer,
  ] = useState(false);

  const onOpenDrawerProcessPaymentAreYouSureContainer = () =>
    setOpenDrawerProcessPaymentAreYouSureContainer(true);
  const onCloseDrawerProcessPaymentAreYouSureContainer = () =>
    setOpenDrawerProcessPaymentAreYouSureContainer(false);

  const [
    openDrawerAreYouSureNoPaymentContainer,
    setOpenDrawerAreYouSureNoPaymentContainer,
  ] = useState(false);

  const onOpenDrawerAreYouSureNoPaymentContainer = () =>
    setOpenDrawerAreYouSureNoPaymentContainer(true);
  const onCloseDrawerAreYouSureNoPaymentContainer = () =>
    setOpenDrawerAreYouSureNoPaymentContainer(false);

  const [openDrawerClassDetailsContainer, setOpenDrawerClassDetailsContainer] =
    useState(false);
  // eslint-disable-next-line no-unused-vars
  const onOpenDrawerClassDetailsContainer = () =>
    setOpenDrawerClassDetailsContainer(true);
  const onCloseDrawerClassDetailsContainer = () =>
    setOpenDrawerClassDetailsContainer(false);

  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(0);
  const onChoose = (id) => setSelectedPaymentMethodId(id);
  const [paymentMethod, setPaymentMethod] = useState(undefined);

  const onCreateCreditCard = (createdCard) => {
    const { cardholderName, number, expires } = createdCard;
    const [shortName] = creditCardManufacturer(Number(number[0]));

    setPaymentMethod({
      type: shortName,
      lastFourNumbers: Number(number.slice(12)),
      expire: new Date(`20${expires.slice(3)}-${expires.slice(0, 2)}-01`),
      name: cardholderName,
    });
    onOpenDrawerProcessPaymentAreYouSureContainer();
  };

  const onProcessPayment = () => {
    if (selectedPaymentMethodId === 4) {
      onOpenDrawerClassDetailsContainer();
      return;
    }

    setPaymentMethod(
      [...paymentMethods.cards, ...paymentMethods.ways].find(
        ({ id }) => selectedPaymentMethodId === id
      )
    );
    onOpenDrawerProcessPaymentAreYouSureContainer();
  };

  const close = () => {
    onCloseDrawerProcessPaymentAreYouSureContainer();
    onCloseDrawerAreYouSureNoPaymentContainer();
    onCloseDrawerClassDetailsContainer();
    onClose();
  };

  const onSendPayment = (tip) => {
    moveActiveOrderToCompletedSection(
      { ...activeOrder, tip: tip || {} },
      onAddToCompletedOrders
    );
    close();
  };

  return (
    <DrawerProcessPaymentContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      <div className='close-icon' onClick={onClose}>
        <i className='fas fa-chevron-left' />
      </div>
      <SingleText size={22} lineHeight='33px' spacing='0' marginLeft={7}>
        Process Payment
      </SingleText>
      <SingleText
        spacing='0.27px'
        marginBottom={35}
        color={colors.lightGray}
        marginLeft={7}
      >
        Choose a payment method
      </SingleText>
      {paymentMethods.cards.map(
        ({ type, lastFourNumbers, expire, name, id }, index) => (
          <PaymentCard
            type={type}
            lastFourNumbers={lastFourNumbers}
            expire={expire}
            name={name}
            onClick={() => onChoose(id)}
            isActive={id === selectedPaymentMethodId}
            key={id + index}
          />
        )
      )}
      {paymentMethods.ways.map(({ title, icon, id, className }, index) => (
        <PaymentWay
          title={title}
          icon={icon}
          className={className}
          onClick={() => onChoose(id)}
          isActive={id === selectedPaymentMethodId}
          key={id + index}
        />
      ))}
      <SingleButton
        title='Process Payment'
        margin='auto 0px 0px 0px'
        onClick={onProcessPayment}
      />
      <DrawerClassDetailsContainer
        visible={openDrawerClassDetailsContainer}
        onClose={onCloseDrawerClassDetailsContainer}
        onContinue={onCreateCreditCard}
      />
      <DrawerProcessPaymentAreYouSureContainer
        visible={openDrawerProcessPaymentAreYouSureContainer}
        onClose={onCloseDrawerProcessPaymentAreYouSureContainer}
        onSendPayment={onSendPayment}
        onOpenDrawerAreYouSureNoPaymentContainer={
          onOpenDrawerAreYouSureNoPaymentContainer
        }
        paymentMethod={paymentMethod}
        isNoPaymentNecessary={selectedPaymentMethodId === 6}
        isCash={selectedPaymentMethodId === 5}
      />
      <DrawerAreYouSureNoPaymentContainer
        visible={openDrawerAreYouSureNoPaymentContainer}
        onClose={onCloseDrawerAreYouSureNoPaymentContainer}
        onSendPayment={onSendPayment}
      />
    </DrawerProcessPaymentContainer>
  );
}

DrawerProcessPayment.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
