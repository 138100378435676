import styled from '@emotion/styled';

export const TableListStyled = styled.table`
  width: 100%;

  thead {
    color: rgba(255, 255, 255, 0.3);
    tr {
      th {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 0;
        i {
          margin-left: 5px;
          font-weight: normal;
        }
      }
      th:first-of-type {
        padding-left: 30px;
      }
      th:last-child {
        padding-right: 30px;
        text-align: end;
      }
    }
  }
  tbody {
    background-color: #1b1c1d;
    tr {
      td {
        padding: 20px 0;
        width: 12.5%;
        &.text-container {
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
        }

        &.table-status {
          font-weight: bold;
        }
      }
      td:first-of-type {
        padding-left: 30px;
      }
      td:last-child {
        padding-right: 30px;
        text-align: end;
      }
    }
    tr:first-of-type {
      td:first-of-type {
        border-top-left-radius: 8px;
      }
      td:last-child {
        border-top-right-radius: 8px;
      }
    }
    tr:last-child {
      td:first-of-type {
        border-bottom-left-radius: 8px;
      }
      td:last-child {
        border-bottom-right-radius: 8px;
      }
    }
    tr:nth-of-type(odd) {
      td {
        background-color: #1b1c1d;
      }
    }
    tr:nth-of-type(even) {
      td {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
  }
`;
