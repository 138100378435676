import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerTableStatusContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  display: flex;
  flex-direction: column;

  .drawer-status--options {
    display: flex;
    flex-direction: column;
    align-items: center;

    .drawer-status--option {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 62px;
      padding: 0 26px;
      border-radius: 16px;
      &.active {
        border: 1px solid white;
      }
      &.inactive {
        border: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }

  .drawer-status-btn-save-container {
    margin-top: auto;

    .btn-save {
      margin-left: 2px;
      margin-right: 2px;
    }
  }
`;
