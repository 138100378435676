import React from 'react';
import PropTypes from 'prop-types';
import { SettingsContainer } from './style';
import SingleAntSwitch from 'components/SingleAntSwitch';

export function Settings({
  user = {
    notification_push: false,
    notification_sms: false,
    notification_email: false,
    notification_promote: false,
  },
  changePushNotifications,
  changeSMSNotifications,
  changeEmailNotifications,
  changePromotionalNotifications,
  isEmailVerified = false,
  isPhoneVerified = false,
  setUserChanged = () => {},
}) {
  return (
    <SettingsContainer>
      {[
        [
          'Push Notifications',
          'Receive push notifications',
          !!user.notification_push,
          false,
          changePushNotifications,
        ],
        [
          'SMS Notifications',
          'Receive SMS notifications',
          !!user.notification_sms,
          !isPhoneVerified,
          changeSMSNotifications,
        ],
        [
          'Email Notifications',
          'Receive Email notifications',
          !!user.notification_email,
          !isEmailVerified,
          changeEmailNotifications,
        ],
        [
          'Promotional Push Notifications',
          'Receive promotional push notifications',
          !!user.notification_promote,
          false,
          changePromotionalNotifications,
        ],
      ].map(([title, description, value, disabled, onChange], index) => (
        <div
          className='settings-option-container'
          key={title + description + index}
        >
          <div className='settings-option-description-container'>
            <span className='settings-title'>{title}</span>
            <span className='settings-description'>{description}</span>
          </div>
          <div className='settings-switcher'>
            <SingleAntSwitch
              checked={value}
              onChange={() => {
                setUserChanged(true)
                onChange()
              }}
              disabled={disabled}
            />
          </div>
        </div>
      ))}
    </SettingsContainer>
  );
}

Settings.propTypes = {
  isEmailVerified: PropTypes.bool,
  isPhoneVerified: PropTypes.bool,
  user: PropTypes.object,
  changePushNotifications: PropTypes.func,
  changeSMSNotifications: PropTypes.func,
  changeEmailNotifications: PropTypes.func,
  changePromotionalNotifications: PropTypes.func,
  setUserChanged: PropTypes.func,
};
