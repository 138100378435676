import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerBusinessHoursSetTimeWrapper = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  .configure-business-hours-panel {
    font-family: Montserrat;
    position: relative;
    height: 100%;
    .panel-header-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      .back-arrow {
        width: 50px;
        cursor: pointer;
        position: absolute;
        left: 0;
        i {
          color: #ffffff;
          font-size: 22px;
        }
      }
      .panel-header-context {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .panel-status-container {
      margin-bottom: 20px;
      margin-top: 70px;
      .open-control-container {
        .is-open-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 5px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          .left-part {
            p {
              margin-bottom: 0;
              font-size: 14px;
              font-weight: 500;
              color: rgba(255, 255, 255, 0.3);
            }
            .is-open {
              color: rgba(255, 255, 255, 1);
            }
          }
          .right-part {
            .ant-switch {
              background-color: #5f626a;
            }
            .ant-switch-checked {
              background-color: #ed2626;
            }
          }
        }

        .time-picker-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .arrow-icon-container {
            i {
              font-size: 16px;
              color: rgba(255, 255, 255, 0.5);
            }
          }
        }

        .set-time-header {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
          margin-bottom: 25px;
          margin-top: 50px;
        }

        .button-container {
          padding: 0 25px;
          position: absolute;
          width: 100%;
          bottom: 30px;
          left: 0;
          .submit-button {
            cursor: pointer;
            outline: unset;
            border-radius: 8px;
            background-color: #ed2626;
            height: 50px;
            width: 100%;
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            border: none;
          }
        }
      }
    }
  }
`;
