import styled from '@emotion/styled';

export const ActiveContainer = styled.div`
  width: 100%;
  margin-left: 33px;
  margin-top: 34px;
  position: relative;

  @media (max-width: 1700px) {
    .active-notify-list {
      position: initial;
      margin-top: 25px;
    }
  }
`;
