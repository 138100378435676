export class ReadyToPublishProfile {
  profile;

  constructor(profile) {
    this.profile = profile;
  }

  publishProfile() {
    return {
      ...this.profile,
      isPublished: true,
    };
  }
}
