import styled from '@emotion/styled'
import { motion } from "framer-motion";

export const SingleButtonContainer = styled(motion.div)`
  width: ${props => props.width};
  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-btn-loading-icon {
      color: white;
    }
  }
`;
