import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import { FaBars, FaTimes, FaPlus, FaChevronRight } from 'react-icons/fa';
import { DrawerEditMenusContainer } from './style';
import { colors } from 'theme';
import { restaurantServices } from 'services/api-services';
import { showMessage, formatBusinessHoursToday } from 'services/functions';
import { StateContext } from 'contexts/StateContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import SingleIconWrapper from 'components/SingleIconWrapper';
import SingleLoader from 'components/SingleLoader';
import SingleEmptyScreen from 'components/SingleEmptyScreen';
import SingleDragger from 'components/SingleDragger';

export default function DrawerEditMenus({
  placement = 'right',
  // onClose = () => { },
}) {
  const { authUser } = useContext(AuthContext)
  const {
    allCategories,
    setAllCategories,
    setCategories,
    openDrawerEditMenus,
    openDrawerMenuEdit,
    openDrawerMenuAdd,
    setOpenDrawerEditMenus,
    setOpenDrawerMenuAdd,
    setOpenDrawerMenuEdit,
  } = useContext(StateContext);
  const {
    menus, setMenus,
    setSelectedMenu
  } = useContext(DataContext)
  const [isLoading, setIsLoading] = useState(false);
  const [isBlur, setIsBlur] = useState(false);

  const onClickAddMenu = () => {
    setSelectedMenu({ availability: [], status: 0 })
    setCategories([]);
    setOpenDrawerMenuAdd(true)
  }
  const onClickEditMenu = (menu) => {
    setSelectedMenu(menu);
    if (menu) {
      const category = allCategories.find(
        (categoryItem) => categoryItem.id === menu.id
      );
      setCategories(category?.categories ?? []);
    }
    setOpenDrawerMenuEdit(true)
  }
  const onClose = () => {
    setOpenDrawerEditMenus(false)
  }
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setMenus(arrayMove(menus, oldIndex, newIndex));
    setIsBlur(false);
  }

  const getMenus = async () => {
    setIsLoading(true)
    const { ok, data, errMessage } = await restaurantServices.getMenus(authUser.restaurant_id)
    if (ok) {
      setMenus(data);
      console.log('getMenus');
      setAllCategories(allCategories.map(category => {
        const menu = data.find(item => item.id === category.id);
        return {categories: category.categories, ...menu}
      }))
      // the only diff between menu and allCategories
      // is that allCategories have also 'categories' prop
      // maybe it's better to refactor that in future (delete one of unnecessary objects)
    } else {
      showMessage(errMessage, 'error');
    }
    setIsLoading(false);
  }

  useEffect(() => {
    return () => {
      if(openDrawerMenuAdd) getMenus()
    }
    // eslint-disable-next-line
  }, [openDrawerMenuAdd])

  useEffect(() => {
    return () => {
      if (openDrawerMenuEdit) getMenus()
    }
    // eslint-disable-next-line
  }, [openDrawerMenuEdit])

  useEffect(() => {
    openDrawerEditMenus && getMenus()
    // eslint-disable-next-line
  }, [openDrawerEditMenus])

  return (
    <DrawerEditMenusContainer
      placement={placement}
      onClose={onClose}
      visible={openDrawerEditMenus}
    >
      <Box display='flex' alignItems='center' marginBottom={40} className={isBlur ? 'blur-mode' : ''}>
        <SingleIconWrapper onClick={onClose}>
          <FaTimes className='cursor' size={20} color='rgba(255, 255, 255, 0.3)' />
        </SingleIconWrapper>
        <Box is='div' display='flex' justifyContent='center' flex={1}>
          <SingleText content='Edit Menus' align='center' size={22} weight={500}>Edit Menus</SingleText>
        </Box>
        <div>
          <SingleIconWrapper onClick={onClickAddMenu}>
            <FaPlus className="cursor" size={18} color={colors.redColor} />
          </SingleIconWrapper>
        </div>
      </Box>
      {isLoading
        ? <SingleLoader isIn='drawer' />
        : menus.length > 0 ?
          <SingleDragger
            renderItem={(item) => (
              <Box padding='7px 0' zIndex="1001" cursor="pointer">
                <Box
                  className={`menu-item ${item.status !== 1 ? 'disabled' : ''}`}
                  is='div'
                >
                  <FaBars className='icon-drag' color='rgba(255, 255, 255, 0.1)' size={20} />
                  <Box is='div' display='flex' flexDirection='column' justifyContent='start' marginRight='auto' marginLeft={19}>
                    <SingleText size={14} weight={600} spacing='-0.34px' marginBottom={8}>{item.name}</SingleText>
                    <SingleText size={14} spacing='-0.34px'>{formatBusinessHoursToday(item.availability)}</SingleText>
                  </Box>
                  <div className="status-and-look" onClick={() => onClickEditMenu(item)}>
                    <div className={`status ${item.status === 1 ? 'active' : ''}`} />
                    <FaChevronRight size={16} color="rgba(255, 255, 255, 0.3)" />
                  </div>
                </Box>
              </Box>
            )}
            items={menus}
            onSortStart={() => setIsBlur(true)}
            onSortEnd={(param) => onSortEnd(param)}
            lockAxis='y'
            lockOffset={['0%', '0%']}
            lockToContainerEdges={true}
          />
          : <SingleEmptyScreen />
      }
      <Box is='div' display='flex' flexDirection='column' marginTop='auto' className={isBlur ? 'blur-mode' : ''}>
        <SingleButton
          title='Done'
          onClick={onClose}
        />
      </Box>
    </DrawerEditMenusContainer>
  )
}

DrawerEditMenus.propTypes = {
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickAddMenu: PropTypes.func,
  onClickEditMenu: PropTypes.func,
};
