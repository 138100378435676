import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { DrawerPartialRefundContainerStyled } from './style';
import { Orders, OrderPriceEffects } from 'feasttt/domain';
import { CompletedOrdersContext } from 'contexts/CompletedOrdersContextContainer';
import { useRefund } from 'hooks';
import { DrawerPartialRefund } from './DrawerPartialRefund';

export function DrawerPartialRefundContainer({ visible, onClose }) {
  const { activeOrder, refundForActiveOrder } = useContext(
    CompletedOrdersContext
  );

  const orderPrice =
    Orders.calculation.calculatePriceForCompletedOrderWithoutRefunds(
      activeOrder
    );

  const {
    type,
    changeType,
    percentages,
    changePercentages,
    dollars,
    changeDollars,
    isPercentageTypeActive,
    isFixedTypeActive,
    isOtherMode,
    onEnableOtherMode,
    disableOtherMode,
    onChangeStaticPercentages,
    isStaticPercentageSelected,

    refund,
    changeRefund,
    clearRefund,
    setupAlreadyExistingRefund,
  } = useRefund(orderPrice);

  const [openDrawerPartialRefund, setOpenDrawerPartialRefund] = useState(false);
  const onOpenDrawerPartialRefund = () => setOpenDrawerPartialRefund(true);
  const onCloseDrawerPartialRefund = () => setOpenDrawerPartialRefund(false);

  const close = () => {
    onCloseDrawerPartialRefund();
    onClose();
  };

  const onChangePercentages = (newPercentages) => {
    if (newPercentages <= OrderPriceEffects.PARTIAL_REFUND_LIMIT_PERCENTAGE) {
      changePercentages(newPercentages);
    }
  };

  const onChangeDollars = (newDollars) => {
    const endOfRangeForPartialRefund =
      OrderPriceEffects.AVAILABLE_PERCENTAGE_FOR_PARTIAL_REFUND * orderPrice;

    if (newDollars <= endOfRangeForPartialRefund) {
      changeDollars(newDollars);
    }
  };

  const onIssueRefund = () => {
    const refundWithPercentages = OrderPriceEffects.createOrderPriceEffect(
      type,
      isPercentageTypeActive() ? percentages : dollars,
      dollars,
      percentages
    );
    refundForActiveOrder(refundWithPercentages);
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerPartialRefund();

      if (activeOrder.refund && activeOrder.refund.money) {
        if (refund === undefined) {
          setupAlreadyExistingRefund(activeOrder.refund);
          changeRefund(
            OrderPriceEffects.createOrderPriceEffect(
              activeOrder.refund.type,
              Number(activeOrder.refund.value)
            )
          );
        }
      }
    } else if (!visible) {
      disableOtherMode();
      clearRefund();
    }
  }, [
    visible,
    disableOtherMode,
    clearRefund,
    activeOrder,
    changeRefund,
    refund,
    setupAlreadyExistingRefund,
  ]);

  return (
    <DrawerPartialRefundContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerPartialRefund
        visible={openDrawerPartialRefund}
        onClose={close}
        onIssueRefund={onIssueRefund}
        fullPrice={orderPrice}
        changeType={changeType}
        percentages={percentages}
        changePercentages={onChangePercentages}
        dollars={dollars}
        changeDollars={onChangeDollars}
        onEnableOtherMode={onEnableOtherMode}
        onChangeStaticPercentages={onChangeStaticPercentages}
        isPercentageTypeActive={isPercentageTypeActive}
        isFixedTypeActive={isFixedTypeActive}
        isStaticPercentageSelected={isStaticPercentageSelected}
        isOtherMode={isOtherMode}
      />
    </DrawerPartialRefundContainerStyled>
  );
}

DrawerPartialRefundContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
