import React from 'react';
import PropTypes from 'prop-types';
import { SeeOrderContainer } from './style';
import TimeStamp from 'components/TimeStamp';

export function SeeOrder({
  activeOrder = {},
  isActivePage = true,
  isActiveOrderEmpty = false,
}) {
  if (isActiveOrderEmpty && !isActivePage) {
    return '';
  }

  return (
    <SeeOrderContainer
      className={
        isActivePage
          ? 'active-page-see-order'
          : activeOrder.status
            ? 'order-ready-page'
            : 'pending-page'
      }
    >
      <div className='active-top-container'>
        <div className='active-pending-icon'>
          {isActivePage ? (
            <i className='fas fa-spinner' />
          ) : activeOrder.status ? (
            <i className='fas fa-concierge-bell' />
          ) : (
            <i className='fas fa-receipt' />
          )}
        </div>
        <div className='active-title-container'>
          <div className='active-title-inner-container'>
            <span className='active-title'>
              {isActivePage
                ? 'Active'
                : activeOrder.status
                  ? 'Order Ready'
                  : 'Pending'}
            </span>
            {isActivePage ? (
              ''
            ) : (
              <div className='link-to-whole-order-container'>
                <div className='link-to-whole-order'>
                  <span className='link-to-whole-order-title'>
                    View whole order
                  </span>
                  <i className='fas fa-chevron-right' />
                </div>
                <div className='time-to-wait'>
                  <TimeStamp date={Date.now() - (activeOrder.time * 1000)} />
                </div>
              </div>
            )}
          </div>
          <span className='name-and-code-title'>
            {`Table ${activeOrder.table ? activeOrder.table.tableNumber : ''
              } • ${activeOrder.table ? activeOrder.table.orderNumber : ''}`}
          </span>
        </div>
      </div>
      <div className='current-order-main'>
        <div className='info-part guests-part'>
          <span className='info-part-title'>
            {activeOrder.table ? activeOrder.table.guests : 5} Guests
          </span>
          <div className='guests'>
            {new Array(activeOrder.table ? activeOrder.table.guests : 5)
              .fill(undefined)
              .map((_, index) => (
                <div
                  className='guest-face'
                  key={index}
                  style={{ left: index ? `${index * 30}px` : '-2px' }}
                />
              ))}
          </div>
        </div>
        <div className='info-part server-part'>
          <span className='info-part-title'>
            Server <span className='server-name'>Megan S.</span>
          </span>
          <div className='server-face' />
        </div>
      </div>
    </SeeOrderContainer>
  );
}

SeeOrder.propTypes = {
  activeOrder: PropTypes.object,
  isActivePage: PropTypes.bool,
  isActiveOrderEmpty: PropTypes.bool,
};
