import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { RequestPendingListContainer } from './style';
import Pagination from 'components/Pagination';
import { OopsAddingFailed } from 'assets/images/menu';
import { StateContext } from 'contexts/StateContextContainer';
import { RequestPendingContext } from 'contexts/RequestPendingContextContainer';
import { requestsServices } from 'services/api-services';
// import moment from 'moment';
import useLocalStorage from 'react-use-localstorage';
import TimeStamp from 'components/TimeStamp';
export function RequestPendingList({ handleClick }) {

  const {
    setSelectedPendingData,
    pendingList,
    setPendingList,
    setCompletedList,
    rejectDrinkRefresh,
    refreshOtherResolve,
    setRefreshOtherResolve
  } = useContext(RequestPendingContext);
  // pending request count
  const { setPendingRequestsTotal } = useContext(StateContext);
  const [isActiveBackground, setIsActiveBackground] = useState(false);
  const [restaurantId, setRestaurantId] = useLocalStorage('feasttt-restaurant-id', '');

  useEffect(() => {}, [rejectDrinkRefresh])

  var resId = restaurantId 
  useEffect(() => {
    // @TODO it is implement with static date only for test because depend on backend '2021/09/27', '2021/10/01', '5', '8', '15', '18', '22', '25'
    requestsServices.returnRequestPendingList(resId, {date : '2021/10/25'})
    .then((res) => {
      setPendingList(res.data.pending);
      setCompletedList(res.data.completed)
    })
    .catch((err) => err);
  }, [resId, setCompletedList, setPendingList])

  // const [paginationList, setPaginationList] = useState(RequestsPendingDummy);
  // const onChangePagination = (updatedPaginatedList) => setPaginationList(updatedPaginatedList);

  useEffect(() => {
    let pendingCount = pendingList?.length;
    setPendingRequestsTotal(pendingCount);
  }, [pendingList?.length, setPendingRequestsTotal]);

  const onAdd = (selectedItems) => setSelectedPendingData(selectedItems)

  useEffect(() => {}, [refreshOtherResolve])

  return (
    <RequestPendingListContainer>
      {pendingList ? (
        <>
          <div className='main'>
            {pendingList.map((items, index) => {

             return (
              <div
                className='request-item'
                key={index}
              >
                <div
                  key={index}
                  className={`pending-dark-card ${items.id === isActiveBackground ? 'active' : 'inactive'}`}
                  onClick={(e) => {
                    setIsActiveBackground(items.id);
                    handleClick(index);
                    onAdd(items)
                  }}
                >
                  <div className='pending-flex-inline'>
                    <div className='pending-inner-flex-inline'>
                      <div>
                        <span className='badge pending-badge-secondary'>
                          <i
                            className={
                              (items.request_type === 0 &&
                                'far fa-glass-cheers') ||
                              (items.request_type === 1 &&
                                'far fa-exchange-alt') ||
                              (items.request_type === 2 &&
                                'far fa-exclamation-circle')
                            }
                            id='pending-icon-style'
                          ></i>
                        </span>
                      </div>
                      <div className='pending-padding-horizontal'></div>
                       {/* id for test */}
                       {/* <p>{items.id}</p>{' '} */}
                      <div className='pending-line-height'>
                        <div>
                          <p className='pending-title'>
                            {(items.request_type === 0 &&
                              'Drink Refill Request') ||
                              (items.request_type === 1 &&
                                'Return Request') ||
                              (items.request_type === 2 && 'Other Request')}
                          </p>
                        </div>
                        <div>
                          <p className='pending-description'>
                            Table{' '}{items.table_number}{' '}•{' '}
                            {items.token}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='pending-clock-wrapper'>
                      {/* <i className='fad fa-clock'></i> */}
                      <span><TimeStamp date={Date.now() - (items.wait_seconds * 1000)} /></span>
                    </div>
                  </div>
                </div>
              </div>
            )})}
          </div>
          <Pagination
            className='request-to-handle-pagination'
            list={pendingList}
            limitPerPage={2}
            onChange={(paginatedItems) => {
              // console.log(paginatedItems);
              // console.log('we changed page and items for this page');
              // setPaginationList(paginatedItems);
            }}
          />
        </>
      ) : (
        <div className='pending-image-wrapper'>
          <img src={OopsAddingFailed} alt='requests-pending-icon' />
          <p className='pending-illustration-text'>
            Pending requests <br /> will show here.
          </p>
        </div>
      )}
    </RequestPendingListContainer>
  );
}

RequestPendingList.propTypes = {
  handleClick: PropTypes.func,
};