import styled from '@emotion/styled';
import { colors } from 'theme';

export const CategoriesChangesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0px 9px 9px 24px;
  padding-top: ${({paddingTop}) => paddingTop ? paddingTop : '9px'};
  background-color: ${colors.redColor};
  max-width: 494px;
  border-radius: ${({borderRadius}) => borderRadius ? borderRadius : '50px'};
  border-top-left-radius: 4px;
  margin: 16px 0 10px 40px;
  box-sizing: border-box;
  
  .status-title {
    margin: 0 0 20px;
    line-height: 18px;
    font-family: Montserrat;
    font-weight: bold;
    letter-spacing: -0.34px;
    color: #fff;
    font-size: 17px;
  }

  i {
    margin-right: 10px;
  }

  .btn-set-to-active {
    border-radius: 50% !important;
  }
`;

export const ButtonsWrapper = styled.div`
  gap: 25px;
  display: flex;
  justify-content: space-between;
  margin: 20px 0 15px;
  width: calc(100% - 14px);
`;