import styled from '@emotion/styled';
import { colors } from 'theme';

export const CompletedNotificationContainer = styled.div`
  margin: 11px 0px;
  padding: 21px 33px 24px 40px;
  background-color: ${colors.gray5};
  border-radius: 20px;
  display: flex;

  .total-container {
    margin-right: 20px;
    .total-title {
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -0.28px;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .main-container {
    width: 100%;
    .title-container {
      width: 100%;
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;

      .inner-title-container {
        .notification-title {
          font-size: 15px;
          font-weight: bold;
          color: white;
          letter-spacing: -0.28px;
        }
      }

      .price-container {
        .price-title {
          font-size: 15px;
          letter-spacing: -0.36px;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }

    .reviews-container {
      width: 100%;
      .reviews-core-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .reviews-core {
          display: flex;
          align-items: center;

          .reviews-stars {
            display: flex;
            i {
              font-size: 15px;
              color: ${colors.emptyRating};
              margin-left: 2.5px;
              margin-right: 2.5px;

              &.active {
                color: ${colors.rating};
              }
            }
          }

          .reviews-count {
            font-size: 15px;
            color: white;
          }
        }

        .leave-review-container {
          cursor: pointer;
          display: flex;
          align-items: center;

          .leave-review-title-container {
            .leave-review-title {
              font-size: 15px;
              letter-spacing: -0.36px;
              color: ${colors.redColor};
            }
          }

          .leave-review-container {
            margin-left: 5px;
            i {
              font-size: 15px;
              color: ${colors.redColor};
            }
          }
        }
      }

      .no-review-title {
        font-size: 15px;
        letter-spacing: -0.28px;
        color: rgba(255, 255, 255, 0.3);
      }
    }
  }
`;
