import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerVerifyMobileNumberContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  height: 410px !important;
  padding: 38px 22px 33px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .verify-mobile-top {
    width: 100%;
    margin-bottom: 36px;
    display: flex;
    align-items: center;

    .back-icon {
      cursor: pointer;
      margin-right: 19px;
      i {
        font-size: 28px;
        letter-spacing: 0.53px;
        color: rgba(255, 255, 255, 0.5);
      }
    }

    .verify-title {
      font-size: 20px;
      letter-spacing: 0.38px;
      color: white;
    }
  }

  .we-sent-code {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
    letter-spacing: 0;
    margin-bottom: 29px;
  }

  .code-input {
    width: 351px !important;

    &.filled {
      input {
        background-color: ${colors.lightGreen};
      }
    }

    &.fail-code {
      input {
        border: 2px solid ${colors.redColor};
      }
    }

    input {
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 7px;
      background-color: transparent;
      margin-left: 13px;
      font-family: 'Roboto';
      font-size: 36px;
      font-weight: bold;
      letter-spacing: 0.68px;
      color: white;

      &:first-of-type {
        margin-left: 0px;
      }

      &:focus + input {
        border-left: 1px solid rgba(255, 255, 255, 0.1) !important;
      }
    }
  }

  .resend-container {
    margin-top: 35px;
    margin-bottom: 35px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .did-not-get-it {
      font-size: 16px;
      font-weight: 500;
      color: white;
    }

    .btn-resend {
      cursor: pointer;
      margin-left: 6px;

      .resend {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        color: ${colors.redColor};
      }
    }
  }
`;
