import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OrderToSeeContainer } from './style';
import { colors } from 'theme';
import { needToPay } from 'services/functions';
import { useEditFoodItemFromOrder } from 'hooks';
import { PendingOrdersContext } from 'contexts/PendingOrdersContextContainer';
import { TotalSimplified, SeeOrder, ActiveOrderItems } from '../../components';
import SingleButton from 'components/SingleButton';
import { DrawerOrderReadyContainer } from './DrawerOrderReadyContainer';
import { DrawerRemoveFoodItemFromOrderContainer } from './DrawerRemoveFoodItemFromOrderContainer';
import { DrawerEditFoodItemFromOrder } from './DrawerEditFoodItemFromOrder';
import { DrawerDeleteOrderContainer } from '../DrawerDeleteOrderContainer';

export function OrderToSee({
  openDrawerDeleteOrderContainer,
  onCloseDrawerDeleteOrderContainer,
}) {
  const {
    pendingOrders,
    activeOrder,
    setupActiveOrder,
    deleteActiveOrder,
    isActiveOrderEmpty,
    changeFoodItemStatus,
    updateFoodItem,
    removeFoodItem,
  } = useContext(PendingOrdersContext);
  const [openDrawerOrderReadyContainer, setDrawerOrderReadyContainer] =
    useState(false);
  const onOpenDrawerOrderReadyContainer = () =>
    setDrawerOrderReadyContainer(true);
  const onCloseDrawerOrderReadyContainer = () =>
    setDrawerOrderReadyContainer(false);

  const [
    openDrawerRemoveFoodItemFromOrderContainer,
    setOpenDrawerRemoveFoodItemFromOrderContainer,
  ] = useState(false);
  const onOpenDrawerRemoveFoodItemFromOrderContainer = () =>
    setOpenDrawerRemoveFoodItemFromOrderContainer(true);

  const onCloseDrawerRemoveFoodItemFromOrderContainer = () =>
    setOpenDrawerRemoveFoodItemFromOrderContainer(false);

  const [openDrawerEditFoodItemFromOrder, setOpenDrawerEditFoodItemFromOrder] =
    useState(false);

  const onOpenDrawerEditFoodItemFromOrder = () =>
    setOpenDrawerEditFoodItemFromOrder(true);
  const onCloseDrawerEditFoodItemFromOrder = () =>
    setOpenDrawerEditFoodItemFromOrder(false);

  const {
    foodItem,
    setFoodItem,
    decreaseTotal,
    increaseTotal,
    changeItemDressing,
    changeItemAddons,
    changeItemSize,
  } = useEditFoodItemFromOrder();

  const onStartRemove = (food) => {
    setFoodItem(food);
    onOpenDrawerRemoveFoodItemFromOrderContainer();
  };

  const onStartEdit = (food) => {
    setFoodItem(food);
    onOpenDrawerEditFoodItemFromOrder();
  };

  useEffect(() => {
    if (isActiveOrderEmpty() && pendingOrders.length !== 0) {
      setupActiveOrder([...pendingOrders][0]);
    }
  }, [isActiveOrderEmpty, pendingOrders, setupActiveOrder]);

  //@TODO test
  // console.log(activeOrder.status, 'status')

  return (
    <OrderToSeeContainer>
      {pendingOrders.length ? (
        <>
          <SeeOrder
            activeOrder={activeOrder}
            isActivePage={false}
            isActiveOrderEmpty={isActiveOrderEmpty()}
          />
          <ActiveOrderItems
            items={activeOrder.items}
            changeFoodItemStatus={changeFoodItemStatus}
            onStartEdit={onStartEdit}
            onStartRemove={onStartRemove}
          />
          <TotalSimplified
            totalValues={[
              ['Subtotal', 'items' in activeOrder ? needToPay(activeOrder) : 0],
              ['Tax', 2.3],
              [
                'Total',
                ('items' in activeOrder ? needToPay(activeOrder) : 0) + 2.3,
              ],
              ['Restaurant Fee', 2.0],
            ]}
          />
          <SingleButton
            title={activeOrder.status ? 'Order Delivered' : 'Order Ready'}
            onClick={() => onOpenDrawerOrderReadyContainer()}
            margin='35px 0px 0px 0px'
            btnColor={
              // activeOrder.items
              //   ? activeOrder.items.some((foodItem) => foodItem.status === 0)
              //     ? 'rgba(255, 255, 255, 0.1)'
              //     : colors.redColor
              //   : 'rgba(255, 255, 255, 0.1)'

               activeOrder.items
                ? activeOrder.items.some((foodItem) => foodItem.status === 0)
                  ? colors.redColor
                  : colors.redColor
                : 'rgba(255, 255, 255, 0.1)'
            }
            disabled={
              // activeOrder.items
              //   ? activeOrder.items.some((foodItem) => foodItem.status === 0)
              //   : true
              false
            }
          />
          <DrawerOrderReadyContainer
            visible={openDrawerOrderReadyContainer}
            onClose={onCloseDrawerOrderReadyContainer}
          />
          <DrawerEditFoodItemFromOrder
            foodItem={foodItem}
            increaseTotal={increaseTotal}
            decreaseTotal={decreaseTotal}
            changeItemAddons={changeItemAddons}
            changeItemDressing={changeItemDressing}
            changeItemSize={changeItemSize}
            updateFoodItem={updateFoodItem}
            visible={openDrawerEditFoodItemFromOrder}
            onClose={onCloseDrawerEditFoodItemFromOrder}
          />
          <DrawerRemoveFoodItemFromOrderContainer
            foodItem={foodItem}
            visible={openDrawerRemoveFoodItemFromOrderContainer}
            onClose={onCloseDrawerRemoveFoodItemFromOrderContainer}
            onRemoveFoodItem={removeFoodItem}
          />
        </>
      ) : (
        <div className='select-order-when-everything-is-empty'>
          <span className='select-order-title'>
            Select order for displaying.
          </span>
        </div>
      )}
      <DrawerDeleteOrderContainer
        visible={openDrawerDeleteOrderContainer}
        onClose={onCloseDrawerDeleteOrderContainer}
        deleteActiveOrder={deleteActiveOrder}
      />
    </OrderToSeeContainer>
  );
}

OrderToSee.propTypes = {
  openDrawerDeleteOrderContainer: PropTypes.bool,
  onCloseDrawerDeleteOrderContainer: PropTypes.func,
};
