import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerDeleteFullOrderWrapper = styled(SingleDrawer)`
  background-color: ${colors.gray1};
  padding: initial;
  .delete-day-block {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #151617;
    padding: 30px 30px 20px 30px;
    color: #fff;
    &.active {
      bottom: 0;
      transition-property: bottom;
    }

    .head-block {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      h3 {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
      }
    }

    .button-save {
      height: 50px;
      margin-top: 20px;
      width: 100%;
      border-radius: 8px;
      background-color: #ed2626;
      color: #fff;
      border: none;
      cursor: pointer;

      opacity: 1;
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -webkit-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;

      &.inactive-button {
        opacity: 0.5;
      }
    }
    .button-save:hover {
      opacity: 0.9;
      &.inactive-button {
        opacity: 0.5;
      }
    }

    .button-cancel {
      height: 50px;
      margin-top: 20px;
      width: 100%;
      border-radius: 8px;
      background-color: #61646c;
      color: #fff;
      border: none;
      cursor: pointer;

      opacity: 1;
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -webkit-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;
    }
    .button-cancel:hover {
      opacity: 0.9;
    }
  }
`;
