import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types';
import { showMessage } from 'services/functions';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Box from 'components/Box';
import { FaBars, FaPencilAlt, FaTimes } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerCustomizationsContainer } from './style';
import { restaurantServices } from 'services/api-services';
import { AuthContext } from 'contexts/AuthContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { colors } from 'theme';
import SingleIconWrapper from 'components/SingleIconWrapper';
import DrawerCustomizationAdd from './DrawerCustomizationAdd';

export default function DrawerCustomizations({
  visible = false,
  placement = 'right',
  onClose = () => { },
  editFood = {},
  changeFoodMultiply,
}) {

  const [selectedCustomizations, setSelectedCustomizations] = useState([]);
  const [selectedCustomizationsChanged, setSelectedCustomizationsChanged] = useState([]);
  const [initialSequence, setInitialSequence] = useState([]);
  const { restaurantId } = useContext(AuthContext);
  const { setSelectedCustomizationIndex } = useContext(DataContext);
  const [openDrawerCustomizationAdd, setOpenDrawerCustomizationAdd] = useState(false);

  const onClickAdd = () => setOpenDrawerCustomizationAdd(true)
  const onClickSave = () => {
    changeFoodMultiply({
      ...editFood,
      beforeSubmitCustomizations: selectedCustomizations.filter(({ isNew }) => isNew),
      sortSequence: createSortSequence(selectedCustomizations),
      isSequenceChanged: !createSortSequence(selectedCustomizations).every((item, index) => item === initialSequence[index]),
      selectedCustomizationsChanged,
    });
    onClose();
  }
  const onClickEdit = (index) => {
    setSelectedCustomizationIndex(index);
    onClickAdd();
  }

  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newCustomizations = reorder(
      [...selectedCustomizations],
      result.source.index,
      result.destination.index
    );

    setSelectedCustomizations(newCustomizations);
  }

  useEffect(() => {
    if (editFood.id) {
      getAllCustomizations(editFood.id);
    }
    // eslint-disable-next-line
  }, [editFood.id])

  async function getAllCustomizations(foodId) {
    const onOk = (data) => {
      setSelectedCustomizations([...data]);
      setInitialSequence(createSortSequence(data));
    };

    const onFail = (errMessage) => {
      showMessage(errMessage, 'error');
    };

    if (foodId) {
      return await restaurantServices.getAllCustomizationsByFood({ restaurantId, foodId }, onOk, onFail);
    } else {
      setSelectedCustomizations([]);
    }
  }

 const onAddSelectedCustomizations =(customizations, payload) => {
  setSelectedCustomizations([...customizations, { ...payload, isNew: true }]);
 }

 const updateSelectedCustomizationsChanged = (customization) => {
   if (!customization.isNew) {
    if (selectedCustomizationsChanged.some(({ id }) => id === customization.id)) {
      setSelectedCustomizationsChanged(selectedCustomizationsChanged.map((selectedCustomizationChanged) => selectedCustomizationChanged.id === customization.id ? customization : selectedCustomizationChanged));
    } else {
      setSelectedCustomizationsChanged([...selectedCustomizationsChanged, customization]);
    }
   }
 };

 const onEditSelectedCustomizations = (customizations, selectedCustomizationIndex, payload) => {
   const updatedCustomizations = [...customizations.map((item, index) => {
    if (index === selectedCustomizationIndex) {
      return { ...item, ...payload }
    }
    return item;
  })];
  setSelectedCustomizations(updatedCustomizations);
  updateSelectedCustomizationsChanged(updatedCustomizations[selectedCustomizationIndex]);
 };

  const onRemoveSelectedCustomizations = (customizations, selectedCustomizationIndex) => {
    const customizationToDelete = customizations.find((_, i) => i === selectedCustomizationIndex);
    setSelectedCustomizations([...customizations.filter(x => x.id !== customizationToDelete.id)]);
  };

  return (
    <DrawerCustomizationsContainer
      placement={placement}
      onClose={onClose}
      visible={visible}
    >
      <Box display='flex' alignItems='center' marginBottom={40}>
        <SingleIconWrapper onClick={onClose}>
          <FaTimes className='cursor' size={20} color='rgba(255, 255, 255, 0.3)' />
        </SingleIconWrapper>
        <Box display='flex' justifyContent='center' flex={1}>
          <SingleText content='Customizations' align='center' size={22} weight={500}>Customizations</SingleText>
        </Box>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="grid">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps} key={Math.random()} className='drag-container'>
              {selectedCustomizations.length > 0
                ? selectedCustomizations.map((_customization, _i) =>
                  <Draggable draggableId={String(_customization.id)} index={_i} key={_customization.id + _i}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`${snapshot.isDragging && 'dragging'} w-100`}
                      >
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                          padding='15px 35px'
                          borderTop='solid 1px rgba(255, 255, 255, 0.1)'
                          borderBottom='solid 1px rgba(255, 255, 255, 0.1)'
                          background={colors.gray2}
                        >
                          <FaBars className='icon-drag' color={`${snapshot.isDragging ? 'white' : colors.white3}`} size={20} />
                          <Box display='flex' flexDirection='column' justifyContent='start' marginRight='auto' marginLeft={19}>
                            <SingleText content={_customization.name} size={14} weight={600} spacing='-0.34px'>{_customization.name}</SingleText>
                          </Box>
                          <FaPencilAlt className='cursor' size={20} color={`${snapshot.isDragging ? 'white' : colors.white3}`} onClick={() => onClickEdit(_i)} />
                        </Box>
                      </div>
                    )}
                  </Draggable>)
                : <Box margin="0 24px" color="#fff">No Data</Box>
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Box display='flex' flexDirection='column' marginTop='auto' paddingTop={200}>
        <SingleButton
          title='Add Customization'
          margin='0 0 20px'
          btnColor='#61646c'
          txtColor='white'
          onClick={onClickAdd}
        />
        <SingleButton
          title='Save'
          onClick={onClickSave}
        />
      </Box>
      <DrawerCustomizationAdd
        visible={openDrawerCustomizationAdd}
        onClose={() => setOpenDrawerCustomizationAdd(false)}
        editFood={editFood}
        selectedCustomizations={selectedCustomizations}
        onAddSelectedCustomizations={onAddSelectedCustomizations}
        onEditSelectedCustomizations={onEditSelectedCustomizations}
        onRemoveSelectedCustomizations={onRemoveSelectedCustomizations}
      />
    </DrawerCustomizationsContainer>
  )
}

DrawerCustomizations.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  editFood: PropTypes.object,
  changeFoodMultiply: PropTypes.func,
};

export const createSortSequence = (customizations) => customizations.map(({ id, name }) => id ? id : name);
