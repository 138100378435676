import React from 'react';
import PropTypes from 'prop-types';
import { DrawerAddingFailedContainer } from './style';
import { colors } from 'theme';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerAddingFailed({ visible, tryAgain, onCancel }) {
  return (
    <DrawerAddingFailedContainer
      visible={visible}
      placement='bottom'
      onClose={onCancel}
    >
      <Box className='failed-image' />
      <SingleText size={22} spacing='0.42px' weight='500' marginBottom={10}>
        Oops!
      </SingleText>
      <SingleText lineHeight='21px' spacing='0.27px' marginBottom={31}>
        Something went wrong.
      </SingleText>
      <SingleButton
        title='Try Again'
        onClick={tryAgain}
        margin='0px 0px 4px 0px'
      />
      <SingleButton
        title='Cancel'
        onClick={onCancel}
        btnColor='transparent'
        txtColor={colors.redColor}
      />
    </DrawerAddingFailedContainer>
  );
}

DrawerAddingFailed.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};
