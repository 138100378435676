import styled from '@emotion/styled';
import { colors } from 'theme';

export const ParamControlContainer = styled.div`
  display: flex;
  flex-direction: column;

  .param-control-top {
    height: 30px;
    margin-top: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .param-control-top-title-container {
      .param-control-top-title {
        font-size: 14px;
        font-weight: bold;
        color: white;
        line-height: 21px;
        letter-spacing: -0.34px;
      }
    }

    .show-control-container {
      display: flex;
      align-items: center;

      .option-title-container {
        display: none;

        &.not-so-important {
          display: block;
          margin-right: 14.5px;
        }

        .option-title {
          font-size: 14px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.4);
          letter-spacing: 0.27px;
        }
      }

      .show-selected-items-total {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.27px;
        color: white;
        margin-right: 17px;
        padding: 4px 8px;
        border-radius: 4px;
        background-color: ${colors.redColor};
      }

      .show-button-container {
        cursor: pointer;
        font-size: 16px;
        letter-spacing: 0.3px;
        color: white;
      }
    }
  }

  .param-control-options {
    display: flex;
    flex-direction: column;

    .param-control-option {
      cursor: pointer;
      margin-top: 15px;
      font-size: 14px;
      letter-spacing: -0.34px;
      color: white;
      padding: 21px 26px;
      border: 2px solid rgba(255, 255, 255, 0.08);
      border-radius: 16px;

      &.active {
        border: 2px solid ${colors.redColor};
      }

      .name-with-price {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .option-animation-name,
        .option-price {
          font-size: 14px;
          letter-spacing: -0.34px;
          color: white;
        }
      }
    }
  }
`;
