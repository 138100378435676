import styled from '@emotion/styled';
import { colors } from 'theme';

import { DefaultUser } from 'assets/images/profile';

export const AllReviewsContainer = styled.div`
  background-color: ${colors.gray2};
  display: flex;
  flex-direction: column;

  .review-item {
    padding: 33px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    display: flex;
    flex-direction: column;

    .avatar-plus-rating-container {
      display: flex;
      align-items: flex-start;
      margin-bottom: 22px;

      .avatar {
        min-width: 51px;
        height: 51px;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(${DefaultUser});
        margin-right: 15px;
      }

      .main-info-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .who-and-when {
        margin-right: 35px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .who-title {
          font-size: 15px;
          font-weight: bold;
          color: white;
          letter-spacing: -0.36px;
          margin-bottom: 6px;
        }

        .when-title {
          font-size: 14px;
          letter-spacing: -0.36px;
          color: white;
        }
      }

      .rating-container {
        display: flex;
        align-items: center;

        i {
          margin-left: 3px;
          margin-right: 3px;
          font-size: 15px;
          color: transparent;

          &.active {
            color: ${colors.rating};
          }

          &.hidden {
            color: ${colors.emptyRating};
          }
        }
      }
    }

    .review-main {
      margin-bottom: 22px;
      .review-text {
        font-size: 15px;
        letter-spacing: -0.36px;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 0px;
      }
    }

    .feasts-for-review {
      display: flex;

      .feasts-item {
        margin-right: 30px;
        display: flex;

        .feasts-value-container {
          margin-bottom: 6px;
          .feasts-value {
            font-size: 18px;
            font-weight: bold;
            color: white;
          }
        }

        .feasts-title-container {
          .feasts-title {
            font-size: 15px;
            letter-spacing: -0.36px;
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }

      .feast-here {
        padding-right: 2rem;
      }
    }
  }
`;
