import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerNutritionContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  padding: 0;
  .ant-input::placeholder {
    font-size: 14px;
    color: #ed2626;
  }
  .drawer-menu--options {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 40px;
    .nutrition-item {
      .nutrition-item-inner {
        input {
        }
        input::placeholder {
          color: #ed2626;
        }
      }
    }
    .drawer-menu--option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 62px;
      border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    }
    .expanded-items {
      width: 100%;
      margin-bottom: 26px;
      .ant-collapse-item {
        .ant-collapse-header {
          display: none;
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            .nutrition-item {
              .nutrition-item-inner {
                input {
                }
                input::placeholder {
                  color: #ed2626;
                }
              }
            }

            padding: 0;
          }
        }
      }
    }
    .expand-toggle:hover {
      border-radius: 5px;
      background: ${colors.gray3};
      transition: 0.5s ease;
    }
  }
`;
