import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { RejectRequestModalContainer } from './style';
import Button from 'react-bootstrap/Button';
import RequestPendingMock from '../../../MockData/MockRequestPending.json';
import { modalContext } from 'routes';
import { ReviewReturnRejectRequest } from './ReviewReturnRejectRequest';

export function RejectRequestModal({ visible, onClose }) {
  const context = useContext(modalContext);
  const [Text, setText] = useState('');
  const [Percentage2, setPercentage2] = useState('');
  const [id, setId] = useState('');

  function ContinueBtn() {
    context.setModal('Return-RejectRequest-Modal-2');
  }

  function onAdd(element) {
    setText(element.text);
    setPercentage2(element.percentage2);
    setId(element.id);
  }

  return (
    <RejectRequestModalContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      {/* partial refund */}
      {context.modal === 'Return-RejectRequest-Modal-1' ? (
        <>
          <div className='return-rejectrequest-wrapper animate__animated animate__fadeInUp'>
            <p className='return-rejectrequest-text-style'>Reject Request</p>

            <div className='return-rejectrequest-box-wrapper'>
              {RequestPendingMock.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item.partialRefundModalReturn
                      ? item.partialRefundModalReturn.map((element, index) => (
                          <React.Fragment key={index}>
                            <div
                              onClick={() => onAdd(element)}
                              className='return-rejectrequest-border-box-1'
                              style={
                                element.id === id
                                  ? { background: '#ed2626' }
                                  : { background: 'none' }
                              }
                            >
                              <div className='return-rejectrequest-percentage'>
                                {element.percentage}
                              </div>
                              <div className='return-rejectrequest-dollar'>
                                {element.price}
                              </div>
                              <div>{element.text}</div>
                            </div>
                          </React.Fragment>
                        ))
                      : null}
                  </React.Fragment>
                );
              })}
            </div>

            {/* other div */}
            {Text === 'Other' ? (
              <div className='return-rejectrequest-other-section-wrapper'>
                <div className='return-rejectrequest-other-section-inner'>
                  <p className='return-rejectrequest-other-text-style'>
                    Percentage
                  </p>
                  <p className='return-rejectrequest-other-text-style'>
                    {Percentage2}
                  </p>
                </div>
                <div>
                  <p className='return-rejectrequest-other-cost'>$2.29</p>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <p className='return-rejectrequest-refund'>
             $2.29 price will be refunded.
            </p>

            <div className='return-rejectrequest-btn-center'>
              <Button
                variant='none'
                className='return-rejectrequest-btn-style'
                onClick={ContinueBtn}
              >
                <span className='return-rejectrequest-btn-text'>Continue</span>
              </Button>
            </div>

            <p
              className='return-rejectrequest-cancel-text'
              onClick={onClose}
              aria-hidden='true'
            >
              Cancel
            </p>
          </div>
        </>
      ) : (
        <>
          <ReviewReturnRejectRequest onClose={onClose} />
        </>
      )}
    </RejectRequestModalContainer>
  );
}

RejectRequestModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
