import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

import { SuccessfullyAdded } from 'assets/images/menu';

export const DrawerSuccessfullyAddedContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  height: 630px !important;
  padding: 58px 26px 33px;
  display: flex;
  flex-direction: column;

  .success-image {
    width: 100%;
    height: 345px;
    margin-bottom: 33px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${SuccessfullyAdded});
  }
`;
