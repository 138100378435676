import styled from '@emotion/styled';
import { colors } from 'theme';

export const CustomPeriodFilterContainer = styled.div`
  min-width: 600px;
  max-width: 600px;
  padding: 30px 27px 20px;
  background-color: ${colors.gray2};
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: flex;
  flex-direction: column;

  .calendars-row {
    display: flex;

    .calendar {
      &:first-of-type {
        margin-right: 50px;
      }
    }
  }

  .apply-container {
    margin-top: 21px;
    display: flex;
    justify-content: flex-end;
    .btn-apply {
      border-radius: 25px;
    }
  }
`;
