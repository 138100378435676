import styled from '@emotion/styled';
import SingleAntSelect from 'components/SingleAntSelect';
import { colors } from 'theme';

export const FoodHighCategoriesDropdownContainer = styled(SingleAntSelect)`
  max-width: 280px;
  margin-right: 30px;

  &.high-categories-switcher {
    &.larger {
      .dropdown-content-menu {
        width: ${({ innerMenuWidth }) => innerMenuWidth}px;
      }
    }
  }

  .dropdown-header-menu {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: ${colors.gray5};
    border-color: transparent;
  }

  .option-label-item {
    font-size: 14px;
    color: white;

    .option-category-schedule {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
      letter-spacing: 0;
      margin-left: 9px;
    }
  }

  .select-icon-container {
    display: flex;
    align-items: center;

    .light-indicator {
      margin-right: 11px;
      width: 11px;
      height: 11px;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 50%;

      &.active {
        background-color: ${colors.lightGreen};
      }
    }

    i {
      transform: rotate(90deg);
    }
  }

  .dropdown-content-menu {
    background-color: ${colors.gray5};
  }
`;
