import styled from '@emotion/styled';
import { Logo } from 'assets/images/logo';

export const WelcomeLogoContainer = styled.div`
  width: 100%;
  margin-bottom: 49px;
  display: flex;
  align-items: center;

  .logo-container {
    width: 69px;
    height: 78px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${Logo});
  }

  .welcome-container {
    margin-left: 31px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .welcome-title {
      font-size: 30px;
      font-weight: bold;
      color: white;
      letter-spacing: 0;
      margin-bottom: 10px;
    }

    .welcome-description {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0;
      color: white;
    }
  }
`;
