import { DollarsPercentagesBinding } from './DollarsPercentageBinding';

export class OrderPriceEffects {
  static effectsTypes = {
    percentage: 'percentage',
    fixed: 'fixed',
  };

  static allEffectsTypes = [
    OrderPriceEffects.effectsTypes.percentage,
    OrderPriceEffects.effectsTypes.fixed,
  ];

  static effectsTypesTitles = {
    percentage: 'Percentage',
    fixed: 'Fixed Amount',
  };

  static FULL_REFUND_PERCENTAGE = 100;
  static PARTIAL_REFUND_LIMIT_PERCENTAGE = 99;
  static AVAILABLE_PERCENTAGE_FOR_PARTIAL_REFUND = 0.99;
  static STATIC_PERCENTAGE_OPTIONS = [10, 25, 50];

  static createOrderPriceEffect(type, value, money, percentages) {
    return {
      type,
      value,
      money,
      percentages,
    };
  }

  static initialTypeValue() {
    return OrderPriceEffects.effectsTypes.percentage;
  }

  static isEffectTypesEqual(firstEffectType, secondEffectType) {
    if (
      OrderPriceEffects.allEffectsTypes.includes(firstEffectType) &&
      OrderPriceEffects.allEffectsTypes.includes(secondEffectType)
    ) {
      return firstEffectType === secondEffectType;
    } else {
      return false;
    }
  }

  static createDollarsPercentagesBinding(
    dollars,
    percentages,
    orderTotalPrice
  ) {
    const binding = new DollarsPercentagesBinding(dollars, percentages);
    binding.receiveTotalPriceForCalculations(orderTotalPrice);

    return binding;
  }
}
