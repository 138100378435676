import React from 'react';
import PropTypes from 'prop-types';
import { DrawerStatusChangedContainer } from './style';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerStatusChanged({ visible, onClose }) {
  return (
    <DrawerStatusChangedContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='success-icon'>
        <i className='fas fa-thumbs-up' />
      </div>
      <SingleText
        className='status-title'
        size={22}
        spacing='0.42px'
        marginBottom={50}
      >
        Status Changed
      </SingleText>
      <SingleButton
        className='btn-status-okay'
        title='Okay'
        onClick={onClose}
      />
    </DrawerStatusChangedContainer>
  );
}

DrawerStatusChanged.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
