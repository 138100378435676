import styled from '@emotion/styled';
import { colors } from 'theme';
import { DefaultUser } from 'assets/images/profile';

export const SeeOrderContainer = styled.div`
  padding: 25px 35px 19px;
  border-radius: 20px;
  background-color: ${colors.gray2};
  margin-bottom: 21px;
  display: flex;
  flex-direction: column;

  &.pending-page {
    .active-top-container {
      .active-pending-icon {
        padding: 14px 17px;
        background: rgb(255, 125, 125);
        background: linear-gradient(
          322deg,
          rgba(255, 125, 125, 1) 29%,
          rgba(237, 38, 38, 1) 100%
        );
      }
    }
  }

  &.pending-page,
  &.order-ready-page {
    .active-top-container {
      .active-title-container {
        .active-title-inner-container {
          align-items: center;
        }
      }
    }
  }

  &.order-ready-page {
    .active-top-container {
      .active-pending-icon {
        padding: 14px;
        background: rgb(253, 203, 110);
        background: linear-gradient(
          322deg,
          rgba(253, 203, 110, 1) 29%,
          rgba(247, 166, 76, 1) 100%
        );
      }
    }
  }

  .active-top-container {
    display: flex;
    align-items: center;

    .active-pending-icon {
      margin-right: 14px;
      width: 47px;
      height: 47px;
      border-radius: 50%;
      background-color: #5f6061;
      padding: 15px;

      i {
        font-size: 17px;
        line-height: 14px;
        letter-spacing: -0.41px;
        color: white;
      }
    }

    .active-title-container {
      width: 100%;
      display: flex;
      flex-direction: column;

      .active-title-inner-container {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .link-to-whole-order-container {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .link-to-whole-order {
            display: flex;
            align-items: center;

            .link-to-whole-order-title,
            i {
              font-size: 15px;
              letter-spacing: -0.36px;
              color: ${colors.redColor};
            }

            i {
              margin-left: 5px;
            }
          }

          .time-to-wait {
            margin-top: 4px;
            display: flex;
            align-items: center;
          }
        }
      }

      .active-title {
        font-size: 15px;
        font-weight: bold;
        line-height: 14px;
        letter-spacing: -0.36px;
        color: white;
        margin-bottom: 14px;
      }

      .name-and-code-title {
        font-size: 15px;
        letter-spacing: -0.36px;
        color: white;
      }
    }
  }

  .current-order-main {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;

    .info-part {
      display: flex;
      flex-direction: column;

      &.guests-part {
        .guests {
          margin-top: 13px;
          position: relative;
          display: flex;
          align-items: center;

          .guest-face {
            position: absolute;
            top: -2px;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(${DefaultUser});
          }
        }
      }

      &.server-part {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .info-part-title {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
        line-height: 12px;
        letter-spacing: -0.34px;

        .server-name {
          font-size: 14px;
          line-height: 12px;
          letter-spacing: -0.34px;
          color: white;
        }
      }

      .server-face {
        margin-top: 13px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(${DefaultUser});
      }
    }
  }
`;
