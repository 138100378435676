import customAxios from 'services/customAxios';

const getRoles = () => customAxios.get('/user/roles');
const getMyInfo = () => customAxios.get('/user/me');

const updateProfile = async (payload, onOk = () => { }, onFail = () => { }) => { 
  const { ok, data, errMessage } = await customAxios.put('/user/me', payload);
  if (ok) {
    onOk(data);
  } else {
    onFail(errMessage);
  }
};

export const userServices = {
  getRoles,
  getMyInfo,
  updateProfile,
};
