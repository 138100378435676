import React from 'react';
import { BeforePublishCheckListContainer } from './style';
import { useWelcomeFlow } from 'hooks';
import { CheckListItem } from './CheckListItem';

export function BeforePublishCheckList() {
  const {
    getAllStatuses,
    changeProfileStatus,
    changeMenuStatus,
    changeTableStatus,
    changeBankStatus,
  } = useWelcomeFlow();

  const { profileCompleted, menuCompleted, tableCompleted, bankCompleted } =
    getAllStatuses();

  const checklist = [
    ['Complete your profile.', changeProfileStatus, profileCompleted],
    ['Create a menu.', changeMenuStatus, menuCompleted],
    ['Add a table.', changeTableStatus, tableCompleted],
    ['Connect your bank', changeBankStatus, bankCompleted],
  ]

  return (
    <BeforePublishCheckListContainer>
      {checklist.map(([title, onClickHandler, isCompleted], index) => (
        <CheckListItem
          title={title}
          onClick={onClickHandler}
          isChecked={isCompleted}
          key={title + isCompleted}
          isLastItem={index === checklist.length - 1}
        />
      ))}
    </BeforePublishCheckListContainer>
  );
}
