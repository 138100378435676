import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DrawerChangeToPendingContainer } from './style';
import { colors } from 'theme';
import { PendingOrdersContext } from 'contexts/PendingOrdersContextContainer';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerChangeToPending({ visible, onClose, onChangeToPending }) {
  const { changeActiveOrderStatus } = useContext(PendingOrdersContext);

  const changeToPending = () => {
    changeActiveOrderStatus();
    onChangeToPending();
  };

  return (
    <DrawerChangeToPendingContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <SingleText size={22} spacing='0.42px' align='center' marginBottom={10}>
        Are you sure ?
      </SingleText>
      <SingleText
        lineHeight='21px'
        spacing='0.27px'
        align='center'
        width='311px'
        marginBottom={31}
      >
        Are you sure you want to change status of this order back to Pending ?
      </SingleText>
      <SingleButton
        title='Change to Pending'
        margin='0px 0px 22px 0px'
        onClick={changeToPending}
      />
      <SingleButton
        title='Cancel'
        btnColor='transparent'
        txtColor={colors.redColor}
        onClick={onClose}
      />
    </DrawerChangeToPendingContainer>
  );
}

DrawerChangeToPending.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onChangeToPending: PropTypes.func,
};
