import React, { useState } from 'react';
import SingleText from 'components/SingleText';
import Box from 'components/Box';
import { DashboardContainer, DashboardHeaderContainer, HeaderSpace } from './style';
import { tablesTemp, dataForGraph, months, properlyWeekDays } from 'services/static';
// import SingleSearchComponent from 'components/SingleSearchComponent';
import { DashboardChart } from './DashboardChart';
import { DashboardTables } from './DashboardTables';
import { DashboardSearch } from './DashboardSearch';

export const dashboardDate = new Date();

export default function Dashboard() {

  const [tables] = useState(tablesTemp);

  return (
    <DashboardContainer>
      <Box borderBottom='solid 1px rgba(255, 255, 255, 0.08)' height={88} display='flex' alignItems='center' justifyContent='space-between' padding='30px'>
        <HeaderSpace>
          <SingleText size={24} weight='bold' marginRight='auto'>Dashboard</SingleText>
          <SingleText opacity={0.6} spacing='-0.34px'><b style={{ marginRight: 10 }}>{properlyWeekDays[dashboardDate.getDay()]}</b> {months[dashboardDate.getMonth()]} {`${dashboardDate.getDate() < 10 ? '0' : ''}${dashboardDate.getDate()}`}, {dashboardDate.getFullYear()}</SingleText>
        </HeaderSpace>
        {/* <SingleSearchComponent
          placeholder='Enter...'
          options={[]}
        /> */}
        {/* dashboard-search component */}
        <DashboardSearch />
      </Box>
      <DashboardHeaderContainer>
        <div className="dashboard-container">
            <div className="chart-container">
              <Box className="nav-main-container">
                <Box className="nav-container" justifyContent="flex-start">
                  <div className="title-part" style={{ marginBottom: "20px" }}>
                    <div className="title-name">
                      Today
                    </div>
                    <div className="title-arrow">
                      <i className="fas fa-angle-right" />
                    </div>
                  </div>
                  <div className="extra-info-part">
                    <div className="info-part-item">
                      <span className="item-title">Total Sales</span>
                      <span className="item-value">$ 1,743.00</span>
                    </div>
                    <div className="info-part-item">
                      <span className="item-title">Orders</span>
                      <span className="item-value">13</span>
                    </div>
                    <div className="info-part-item">
                      <span className="item-title">Guests</span>
                      <span className="item-value">43</span>
                    </div>
                    <div className="info-part-item">
                      <span className="item-title">Items Sold</span>
                      <span className="item-value">43</span>
                    </div>
                  </div>
                  <div className="bottom-part">
                    <DashboardChart dataForGraph={dataForGraph} />
                  </div>
                </Box>
              </Box>
            </div>
            <div className="right-facts-container">
              <Box className="nav-main-container">
                <Box className="nav-container" marginBottom="6px">
                  <div className="title-part">
                    <div className="title-name">
                      <i className="fas fa-utensils" /> Menu
                    </div>
                    <div className="title-arrow">
                      <span className="underscore-data">4am-7am</span><i className="fas fa-angle-right" />
                    </div>
                  </div>
                  <div className="bottom-part">
                    Dinner menu
                  </div>
                </Box>
                <Box className="nav-container">
                  <div className="title-part">
                    <div className="title-name">
                      <i className="fas fa-user" /> Staff
                    </div>
                    <div className="title-arrow">
                      <span className="underscore-data">Total  16</span>
                      <i className="fas fa-angle-right" />
                    </div>
                  </div>
                  <div className="bottom-part with-icon">
                    <div className="online-icon" /> 8 Online
                  </div>
                </Box>
              </Box>
            </div>

            <div className="right-facts-container">
              <Box className="nav-main-container">
                <Box className="nav-container" marginBottom="6px">
                  <div className="title-part">
                    <div className="title-name">
                      4.5 <i className="fas fa-star yellow" />
                    </div>
                    <div className="title-arrow">
                      <i className="fas fa-angle-right" />
                    </div>
                  </div>
                  <div className="bottom-part">
                    Average rating this week
                  </div>
                </Box>
                <Box className="nav-container returns">
                  <div className="title-part">
                    <div className="title-name">
                      <i className="fas fa-exchange-alt" /> Returns
                    </div>
                    <div className="title-arrow">
                      <i className="fas fa-angle-right" />
                    </div>
                  </div>
                  <div className="bottom-part">
                    <span className="bottom-title"><b>8</b> / wk</span>
                    <span className="bottom-side-title">1% avg</span>
                  </div>
                </Box>
              </Box>
            </div>

            <div className="right-facts-container">
              <Box className="nav-main-container">
                <Box className="nav-container best-seller-bg" marginBottom="6px">
                  <div className="title-part">
                    <div className="title-name">
                      <i className="fas fa-star" /> Best Seller
                    </div>
                    <div className="title-arrow">
                      <i className="fas fa-angle-right" />
                    </div>
                  </div>
                  <div className="bottom-part">
                    Cranberry Apple Pecan Chicken Sal…
                  </div>
                </Box>
                <Box className="nav-container highest-rated-server-today">
                  <div className="server-avatar" />
                  <div className="small-server-info">
                    <div className="title-part">
                      <div className="title-name">
                        4.4 <i className="fas fa-star yellow" />
                      </div>
                      <div className="title-arrow">
                        <span className="underscore-data">Meghan C</span> <i className="fas fa-angle-right" />
                      </div>
                    </div>
                    <div className="bottom-part">
                      Highest-Rated Server Today
                    </div>
                  </div>
                </Box>
              </Box>
            </div>
        </div>
        <DashboardTables tables={tables} />
      </DashboardHeaderContainer>
    </DashboardContainer>
  )
}
