import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DrawerUpdateValueContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import SingleAntFormatInput from 'components/SingleAntFormatInput';
export function DrawerUpdateValue({
  visible,
  onClose,
  label,
  placeholder = '',
  currentValue = '',
  inputType = 'text',
  beforeInputText = '',
  updateTitleEnd,
  onContinue,
}) {
  const [value, setValue] = useState();
  const [tel, setTel] = useState('');

  const onChangePhone = (values) => {
    setValue(values.value);
    setTel(values.formattedValue);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (value.length) {
      onContinue(value, tel);
    }
  };

  useEffect(() => {
    if (visible && value === undefined) {
      setValue(currentValue);
      setTel(currentValue);
    }
  }, [visible, value, currentValue]);

  return (
    <DrawerUpdateValueContainer
      visible={visible}
      placement='bottom'
      onClose={onClose} 
    >
      <SingleText
        className='update-value-top-title'
        size={22}
        spacing='0.42px'
        align='left'
        weight={500}
        marginBottom={40}
      >
        Update {updateTitleEnd}
      </SingleText>
      <form onSubmit={onSubmit}>
        <div className='value-container'>
          <span className='value-label'>{label}</span>
          <div className='input-container'>
            {beforeInputText ? (
              <span className='before-input-title'>{beforeInputText}</span>
            ) : (
              ''
            )}
            {inputType === 'tel' ? (
              <SingleAntFormatInput
                value={tel}
                onChange={onChangePhone}
                format='(###) ###-####'
              />
            ) : (
              <input
                value={value}
                type={inputType}
                className='value-input'
                placeholder={placeholder}
                onChange={(event) => setValue(event.target.value)}
              />
            )}
          </div>
        </div>
        <SingleButton
          htmlType='submit'
          title='Continue'
          margin='0px 0px 20px'
          value={value}
          disabled={!((""+value).length === 10)}
        />
      </form>
      <SingleButton
        title='Cancel'
        btnColor='transparent'
        txtColor={colors.redColor}
        onClick={onClose}
      />
    </DrawerUpdateValueContainer>
  );
}

DrawerUpdateValue.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  updateTitleEnd: PropTypes.string,
  currentValue: PropTypes.string,
  inputType: PropTypes.string,
  beforeInputText: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
};
