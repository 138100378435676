import styled from '@emotion/styled';
import Pagination from 'react-bootstrap/Pagination';
import { colors } from 'theme';

export const PaginationContainer = styled(Pagination)`
  align-items: center;

  .page-item {
    margin-right: 16px;

    .page-link {
      width: 8px;
      height: 8px;
      padding: 0px;
      border-radius: 50%;
      border-color: transparent;
      background-color: #484b4f;
    }

    &.active {
      .page-link {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: ${colors.redColor};
      }
    }

    &:first-of-type,
    &:last-of-type {
      .page-link {
        width: 27px;
        height: 27px;
        padding: 0px 8px 0px;
        background-color: #484b4f;
        border-radius: 6px;
        border-color: transparent;
        font-size: 30px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.3);

        span {
          &:first-of-type {
            position: relative;
          }
        }
      }

      &.page-active {
        .page-link {
          color: white;
        }
      }
    }

    &:first-of-type {
      margin-right: 24px;
      .page-link {
        span {
          &:first-of-type {
            top: -9px;
            left: -1px;
          }
        }
      }
    }

    &:last-of-type {
      margin-left: 9px;

      .page-link {
        span {
          &:first-of-type {
            top: -9px;
            left: 0px;
          }
        }
      }
    }
  }
`;
