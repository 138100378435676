import styled from '@emotion/styled';
import { colors } from 'theme';

export const FoodControlContainerStyled = styled.div`
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .control-item {
    &:first-of-type {
      .main-title-container {
        .icon-main-title {
          i {
            color: ${colors.rating2};
          }
        }
      }
    }
  }
`;
