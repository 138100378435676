import styled from '@emotion/styled';
import { Drawer } from 'react-pretty-drawer';

export const SingleDrawerContainer = styled(Drawer)`
  width: ${(props) => props.width}px;
  padding: 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  &.bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    /* border-radius: 40px; */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: gainsboro;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
