import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ItemPicker from 'components/DrawerForm/ItemPicker';
import DrawerAddManager from './DrawerAddManager';

export function ManagerField({
  isInDrawer = false,
  managerTitle,
  managerName,
  onChange,
}) {
  const [openDrawerAddManager, setDrawerAddManager] = useState(false);

  return (
    <>
      <ItemPicker
        isInDrawer={isInDrawer}
        label={managerTitle ? managerTitle : 'Manager'}
        placeholder='Add Manager'
        value={managerName}
        onClick={() => setDrawerAddManager(true)}
      />
      <DrawerAddManager
        visible={openDrawerAddManager}
        onClose={() => setDrawerAddManager(false)}
        managerTitle={managerTitle}
        managerName={managerName}
        onChange={onChange}
      />
    </>
  );
}

ManagerField.propTypes = {
  isInDrawer: PropTypes.bool,
  managerTitle: PropTypes.string,
  managerName: PropTypes.string,
  onChange: PropTypes.func,
};
