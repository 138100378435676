import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { DrinkRefillRequestPendingDrawerContainer } from './style';
import { DrinkConfirmRequest } from './DrinkConfirmRequest';
import { modalContext } from 'routes';
import { DrinkRejectItem } from './RejectItem';
import { RequestPendingContext } from 'contexts/RequestPendingContextContainer';

export function DrinkRefillRequestPendingDrawer({ visible, onClose }) {
  const context = useContext(modalContext);
  const {
    setDrinkOptionsText,
    drinkOptionsDigit,
    setDrinkOptionsDigit, 
    setDrinkStatus
  } = useContext(RequestPendingContext);

  // drink confirm request drawer
  const [openDrawerDrinkConfirmRequest, setOpenDrawerDrinkConfirmRequest] = useState(false);
  const onOpenDrawerDrinkConfirmRequest = () => {
    setOpenDrawerDrinkConfirmRequest(true);
    context.setModal('DrinkRefillModal-1');
  };
  const onCloseDrawerDrinkConfirmRequest = () => setOpenDrawerDrinkConfirmRequest(false);

  // drink reject-item confirm request drawer
  const [openDrawerDrinkRejectItem, setOpenDrawerDrinkRejectItem] = useState(false);
  const onOpenDrawerDrinkRejectItem = () => {
    setOpenDrawerDrinkRejectItem(true);
    context.setModal('DrinkRejectItemModal-1');
  };
  const onCloseDrawerDrinkRejectItem = () => setOpenDrawerDrinkRejectItem(false);

  if(drinkOptionsDigit === 2){
    setDrinkOptionsText('Fulfilled')
  } else if (drinkOptionsDigit === 1){
    setDrinkOptionsText('Reject')
  }
  
  // its log only for test
  // console.log(drinkOptionsDigit, 'drink options digit')
  // console.log(drinkOptionsText, 'drink options text')

  return (
    <DrinkRefillRequestPendingDrawerContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      <div className='drink-refill-close-icon drink-refill-wrapper-sidebar'>
        <i
          className='fas fa-chevron-left drink-refill-left'
          onClick={onClose}
          aria-hidden='true'
        />

        <div className='drink-refill-text-align'>
          <p>
            Choose how to resolve
            <br />
            this item.
          </p>
        </div>

        <div>
          <p
            className='drink-refill-border-style'
            onClick={() => {
              onOpenDrawerDrinkConfirmRequest()
              setDrinkOptionsDigit(2)
              setDrinkStatus(1)
            }}
            aria-hidden='true'
          >
            <span style={{ color: '#ffffff' }}>Fulfill Item</span>
          </p>
          <p
            className='drink-refill-border-style'
            onClick={() => {
              onOpenDrawerDrinkRejectItem()
              setDrinkOptionsDigit(1)
              setDrinkStatus(2)
            }}
            aria-hidden='true'
          >
            <span style={{ color: '#ffffff' }}>Reject Item</span>
          </p>
        </div>
      </div>

      <DrinkConfirmRequest
        visible={openDrawerDrinkConfirmRequest}
        onClose={onCloseDrawerDrinkConfirmRequest}
      />

      <DrinkRejectItem
        visible={openDrawerDrinkRejectItem}
        onClose={onCloseDrawerDrinkRejectItem}
      />
    </DrinkRefillRequestPendingDrawerContainer>
  );
}

DrinkRefillRequestPendingDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
