import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { tablesTemp } from 'services/static';

const StateContext = React.createContext(null);

export { StateContext };


// {
//   availability: [],
//   categories: [
//     { id: 1, name: 'Appetizers', items: [] },
//     { id: 2, name: 'Soups', items: [] },
//     { id: 3, name: 'Main Entrees', items: [] },
//     { id: 4, name: 'Desserts', items: [] },
//     { id: 5, name: 'Drinks', items: [] },
//   ],
//   description: null,
//   id: 0,
//   name: 'All',
//   status: 1,
// },


function StateContextContainer(props) {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [foods, setFoods] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState({});

  // const [getTables, setTables] = useState([...tablesTemp]);
  const [getTables, setTables] = useState([]);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState('');
  const [editClosedDay, setEditClosedDay] = useState();
  const [getItemToAdd, setItemToAdd] = useState({ status: 1 });
  const [checkinStatus, setCheckinStatus] = useState();

  // here we need connect Pending Orders with Global state
  // @TODO Connect Pending Orders with Global state
  // eslint-disable-next-line no-magic-numbers
  const [pendingOrdersTotal, setPendingOrdersTotal] = useState(10);

  // eslint-disable-next-line no-magic-numbers
  const [pendingRequestsTotal, setPendingRequestsTotal] = useState(10);

  const [openDrawerCheckIn, setOpenDrawerCheckIn] = useState(false);
  const [openDrawerCheckInMessage, setOpenDrawerCheckInMessage] =
    useState(false);
  const [openDrawerSelectTable, setOpenDrawerSelectTable] = useState(false);
  const [openDrawerSelectServer, setOpenDrawerSelectServer] = useState(false);
  const [openDrawerCheckInSummary, setOpenDrawerCheckInSummary] =
    useState(false);
  const [openDrawerCheckInCompleted, setOpenDrawerCheckInCompleted] =
    useState(false);

  const [openDrawerRoles, setOpenDrawerRoles] = useState(false);
  const [openDrawerRolesToEdit, setOpenDrawerRolesToEdit] = useState(false);
  const [openDrawerStaffDetails, setOpenDrawerStaffDetails] = useState(false);
  const [openDrawerSortReviews, setOpenDrawerSortReviews] = useState(false);
  const [openDrawerStatus, setOpenDrawerStatus] = useState(false);
  const [openDrawerInviteStaff, setOpenDrawerInviteStaff] = useState(false);
  const [openDrawerInviteSent, setOpenDrawerInviteSent] = useState(false);
  const [openDrawerStaffAdd, setOpenDrawerStaffAdd] = useState(false);
  const [openDrawerStaffEdit, setOpenDrawerStaffEdit] = useState(false);
  const [openDrawerStaffDelete, setOpenDrawerStaffDelete] = useState(false);

  const [openDrawerEditMenus, setOpenDrawerEditMenus] = useState(false);
  const [openDrawerMenuAdd, setOpenDrawerMenuAdd] = useState(false);
  const [openDrawerMenuEdit, setOpenDrawerMenuEdit] = useState(false);
  const [openDrawerDeleteMenu, setOpenDrawerDeleteMenu] = useState(false);
  const [openDrawerAvailabilities, setOpenDrawerAvailabilities] =
    useState(false);
  const [openDrawerAvailabilityDay, setOpenDrawerAvailabilityDay] =
    useState(false);
  const [openDrawerMenuStatus, setOpenDrawerMenuStatus] = useState(false);
  const [openDrawerMoveItemsInMenu, setOpenDrawerMoveItemsInMenu] =
    useState(false);

  const [openDrawerMoveItems, setOpenDrawerMoveItems] = useState(false);
  const [openDrawerAddOption, setOpenDrawerAddOption] = useState(false);
  const [openDrawerFoodStatus, setOpenDrawerFoodStatus] = useState(false);

  const [openDrawerCategories, setOpenDrawerCategories] = useState(false);
  const [openDrawerDeleteCategory, setOpenDrawerDeleteCategory] =
    useState(false);
  const [openDrawerDeleteCategoryConfirm, setOpenDrawerDeleteCategoryConfirm] =
    useState(false);
  const [openDrawerMoveCategoryItems, setOpenDrawerMoveCategoryItems] =
    useState(false);
  const [openDrawerAddNewCategory, setOpenDrawerAddNewCategory] =
    useState(false);

  const [openDrawerElement, setOpenDrawerElement] = useState(false);
  const [openDrawerTableAdd, setOpenDrawerTableAdd] = useState(false);
  const [openDrawerTableEdit, setOpenDrawerTableEdit] = useState(false);
  const [openDrawerTableStatus, setOpenDrawerTableStatus] = useState(false);
  const [openDrawerWallAdd, setOpenDrawerWallAdd] = useState(false);
  const [openDrawerWallEdit, setOpenDrawerWallEdit] = useState(false);
  const [tablesWasUpdated, setTablesWasUpdated] = useState(false);

  const [openDrawerOrderItemDelete, setOpenDrawerOrderItemDelete] =
    useState(false);
  const [openDrawerOrderItemDeleteCheck, setOpenDrawerOrderItemDeleteCheck] =
    useState(false);
  const [openDrawerDeleteFullOrder, setOpenDrawerDeleteFullOrder] =
    useState(false);
  const [openDrawerDeleteFullOrderCheck, setOpenDrawerDeleteFullOrderCheck] =
    useState(false);
  const [completeOrder, setCompleteOrder] = useState(false);
  const [editOrderItem, setEditOrderItem] = useState(false);
  const [pingServerDrawerOpen, setPingServerDrawerOpen] = useState(false);
  const [openOrderCompletedDrawer, setOpenOrderCompletedDrawer] =
    useState(false);
  const [openOrderDeletedDrawer, setOpenOrderDeletedDrawer] = useState(false);
  const [openOrderItemRemoved, setOpenOrderItemRemoved] = useState(false);

  return (
    <StateContext.Provider
      value={{
        categories,
        setCategories,
        allCategories,
        setAllCategories,
        foods,
        setFoods,
        selectedStaff,
        setSelectedStaff,
        getTables,
        setTables,
        selectedDayOfWeek,
        setSelectedDayOfWeek,
        editClosedDay,
        setEditClosedDay,
        getItemToAdd,
        setItemToAdd,
        checkinStatus,
        setCheckinStatus,
        selectedCategory,
        setSelectedCategory,

        pendingOrdersTotal,
        setPendingOrdersTotal,
        pendingRequestsTotal,
        setPendingRequestsTotal,

        openDrawerCheckIn,
        setOpenDrawerCheckIn,
        openDrawerCheckInMessage,
        setOpenDrawerCheckInMessage,
        openDrawerSelectTable,
        setOpenDrawerSelectTable,
        openDrawerSelectServer,
        setOpenDrawerSelectServer,
        openDrawerCheckInSummary,
        setOpenDrawerCheckInSummary,
        openDrawerCheckInCompleted,
        setOpenDrawerCheckInCompleted,

        openDrawerRoles,
        setOpenDrawerRoles,
        openDrawerRolesToEdit,
        setOpenDrawerRolesToEdit,
        openDrawerStaffDetails,
        setOpenDrawerStaffDetails,
        openDrawerSortReviews,
        setOpenDrawerSortReviews,
        openDrawerStatus,
        setOpenDrawerStatus,
        openDrawerInviteStaff,
        setOpenDrawerInviteStaff,
        openDrawerInviteSent,
        setOpenDrawerInviteSent,
        openDrawerStaffAdd,
        setOpenDrawerStaffAdd,
        openDrawerStaffEdit,
        setOpenDrawerStaffEdit,
        openDrawerStaffDelete,
        setOpenDrawerStaffDelete,

        openDrawerEditMenus,
        setOpenDrawerEditMenus,
        openDrawerMenuAdd,
        setOpenDrawerMenuAdd,
        openDrawerMenuEdit,
        setOpenDrawerMenuEdit,
        openDrawerDeleteMenu,
        setOpenDrawerDeleteMenu,
        openDrawerAvailabilities,
        setOpenDrawerAvailabilities,
        openDrawerAvailabilityDay,
        setOpenDrawerAvailabilityDay,
        openDrawerMenuStatus,
        setOpenDrawerMenuStatus,
        openDrawerMoveItemsInMenu,
        setOpenDrawerMoveItemsInMenu,

        openDrawerMoveItems,
        setOpenDrawerMoveItems,
        openDrawerAddOption,
        setOpenDrawerAddOption,
        openDrawerFoodStatus,
        setOpenDrawerFoodStatus,

        openDrawerCategories,
        setOpenDrawerCategories,
        openDrawerDeleteCategory,
        setOpenDrawerDeleteCategory,
        openDrawerDeleteCategoryConfirm,
        setOpenDrawerDeleteCategoryConfirm,
        openDrawerMoveCategoryItems,
        setOpenDrawerMoveCategoryItems,
        openDrawerAddNewCategory,
        setOpenDrawerAddNewCategory,

        openDrawerElement,
        setOpenDrawerElement,
        openDrawerTableAdd,
        setOpenDrawerTableAdd,
        openDrawerTableEdit,
        setOpenDrawerTableEdit,
        openDrawerTableStatus,
        setOpenDrawerTableStatus,
        openDrawerWallAdd,
        setOpenDrawerWallAdd,
        openDrawerWallEdit,
        setOpenDrawerWallEdit,
        tablesWasUpdated,
        setTablesWasUpdated,

        openDrawerOrderItemDelete,
        setOpenDrawerOrderItemDelete,
        openDrawerOrderItemDeleteCheck,
        setOpenDrawerOrderItemDeleteCheck,
        openDrawerDeleteFullOrder,
        setOpenDrawerDeleteFullOrder,
        openDrawerDeleteFullOrderCheck,
        setOpenDrawerDeleteFullOrderCheck,
        completeOrder,
        setCompleteOrder,
        editOrderItem,
        setEditOrderItem,
        pingServerDrawerOpen,
        setPingServerDrawerOpen,
        openOrderCompletedDrawer,
        setOpenOrderCompletedDrawer,
        openOrderDeletedDrawer,
        setOpenOrderDeletedDrawer,
        openOrderItemRemoved,
        setOpenOrderItemRemoved,
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

StateContextContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StateContextContainer;
