import styled from '@emotion/styled';
import { colors } from 'theme';

export const AnalyzeFoodContainer = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;

  .price-title {
    font-size: 12px;
    font-weight: bold;
    color: white;
    line-height: 14px;
    letter-spacing: -0.29px;
    margin-right: 30px;
  }

  .rating-container {
    font-size: 12px;
    font-weight: bold;
    color: white;
    margin-right: 30px;

    i {
      color: ${colors.rating};
    }
  }

  .calories-container {
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    letter-spacing: -0.29px;
    color: white;

    .calories-icon-item {
      margin-right: 6px;
      color: ${colors.redColor};
    }
  }
`;
