import styled from '@emotion/styled'
import Box from 'components/Box';
// import { fonts } from 'theme';

export const SingleAntDrawerInputContainer = styled(Box)`
  ${'' /* .ant-input {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.34px;
    color: #ffffff;
  }
  .ant-input-prefix {
    color: white;
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.34px;
  } */}

  .text-input-container{
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    .input-prefix{
      flex: 0 0 auto;
      color: #fff;
      padding-right: 10px;
    }
    input{
      flex: 1 1 auto;
      background-color: transparent;
      color: #fff;
      border: unset;
      outline: unset;

      &:disabled {
        color: #5e5f5f;
      }
    }
  }
`;
