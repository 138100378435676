import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { OutOfStockAlertContainer } from './style';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';

export function OutOfStockAlert({ onClose }) {
  const context = useContext(modalContext);
  return (
    <OutOfStockAlertContainer>
      {context.modal === 'Reject-Drink-OutOfStock-Modal-2' ? (
        <div className='reject-drink-outofstock-wrapper animate__animated animate__fadeInUp'>
          <div className='reject-drink-outofstock-svg-position'>
            <svg height='100' width='100'>
              <circle cx='50' cy='50' r='40' strokeWidth='3' fill='#27282a' />
            </svg>
          </div>

          <div className='reject-drink-outofstock-check-wrapper'>
            <i className='far fa-times reject-drink-outofstock-times'></i>
          </div>

          <div className='reject-drink-outofstock-body-wrapper'>
            <div>
              <p className='reject-drink-outofstock-text-style'>
                Refill Rejected
              </p>
            </div>
            <Button
              variant='none'
              className='reject-drink-outofstock-okay-btn'
              onClick={onClose}
            >
              Okay
            </Button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </OutOfStockAlertContainer>
  );
}

OutOfStockAlert.propTypes = {
  onClose: PropTypes.func,
};
