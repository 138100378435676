import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ItemPicker from 'components/DrawerForm/ItemPicker';
import DrawerFeaturedImage from './DrawerFeaturedImage';

export function FeaturedImagesField({
  isInDrawer = false,
  gallery = [],
  onDeleteFeaturedImage = () => {},
  onAddFeaturedImage = () => { },
}) {
  const [openDrawerFeaturedImage, setDrawerFeaturedImage] = useState(false);

  return (
    <>
      <ItemPicker
        isInDrawer={isInDrawer}
        className='horizontal-inner-element'
        label='Featured Images'
        value={`${gallery.length} Images`}
        placeholder='Add Images'
        onClick={() => setDrawerFeaturedImage(true)}
      />
      <DrawerFeaturedImage
        visible={openDrawerFeaturedImage}
        onClose={() => setDrawerFeaturedImage(false)}
        gallery={gallery}
        onDeleteFeaturedImage={onDeleteFeaturedImage}
        onAddFeaturedImage={onAddFeaturedImage}
      />
    </>
  );
}

FeaturedImagesField.propTypes = {
  isInDrawer: PropTypes.bool,
  gallery: PropTypes.array,
  onDeleteFeaturedImage: PropTypes.func,
  onAddFeaturedImage: PropTypes.func,
};
