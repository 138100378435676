import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import ItemPicker from 'components/DrawerForm/ItemPicker';
import DrawerTypes from '../../DrawerTypes';
import DrawerAssigneCategory from '../../DrawerAssigneCategory';
import DrawerDietaryTags from '../../DrawerDietaryTags';
import DrawerNutrition from '../../DrawerNutrition';
import DrawerCustomizations from '../../DrawerCustomizations';
import DisplayCategory from './DisplayCategory';

export const DrawerFoodForm = ({
  editFood = {},
  changeEditFood = () => {},
  changeFoodMultiply = () => {},
}) => {
  const [openDrawerTypes, setOpenDrawerTypes] = useState(false);
  const [openDrawerAssigneCategory, setOpenDrawerAssigneCategory] =
    useState(false);
  const [openDrawerDietaryTags, setOpenDrawerDietaryTags] = useState(false);
  const [openDrawerNutrition, setOpenDrawerNutrition] = useState(false);
  const [openDrawerCustomizations, setOpenDrawerCustomizations] =
    useState(false);

  const onClickType = () => setOpenDrawerTypes(true);
  const onClickCategories = () => setOpenDrawerAssigneCategory(true);
  const onClickDietaryTags = () => setOpenDrawerDietaryTags(true);
  const onClickNutrition = () => setOpenDrawerNutrition(true);
  const onClickCustomizations = () => setOpenDrawerCustomizations(true);

  return (
    <>
      <Box className='drawer-menu--options' marginBottom={27}>
        <Box className='drawer-menu--option'>
          <Box>
            <SingleText
              content='Item Name'
              size={14}
              opacity={0.3}
              lineHeight={1.57}
              spacing='-0.34px'
            >
              Item Name
            </SingleText>
            <input
              type='text'
              placeholder='Ex: Caesar Salad'
              style={{
                display: 'block',
                width: '100%',
                backgroundColor: 'transparent',
                border: 'unset',
                outline: 'unset',
                paddingLeft: 0,
                paddingTop: 5,
                color: '#fff',
              }}
              value={editFood.title}
              onChange={(e) => changeEditFood('title', e.target.value)}
            />
          </Box>
        </Box>
        <ItemPicker
          label='Type'
          value={editFood.type ? editFood.type : 'Set Type'}
          onClick={onClickType}
        />
        <DisplayCategory
          editFood={editFood}
          onClickCategories={onClickCategories}
        />
        <Box className='drawer-menu--option'>
          <Box>
            <SingleText
              content='Price'
              size={14}
              opacity={0.3}
              lineHeight={1.57}
              spacing='-0.34px'
            >
              Price
            </SingleText>
            <input
              type='text'
              placeholder='Enter Price'
              style={{
                display: 'block',
                width: '100%',
                backgroundColor: 'transparent',
                border: 'unset',
                outline: 'unset',
                paddingLeft: 0,
                paddingTop: 5,
                color: '#fff',
              }}
              value={editFood.price}
              onChange={(e) => changeEditFood('price', e.target.value)}
            />
          </Box>
        </Box>
        <ItemPicker
          label='Status'
          value={editFood.status === 0 ? 'Inactive' : 'Active'}
          onClick={/*onClickStatus*/ () => {}}
        />
      </Box>
      <Box className='drawer-menu--options' marginBottom={45}>
        <SingleText
          content='Optional but highly recommended.'
          opacity={0.7}
          size={15}
          weight={600}
          spacing='-0.36px'
          marginBottom={12}
          marginRight='auto'
        >
          Optional but highly recommended.
        </SingleText>
        <Box className='drawer-menu--option'>
          <Box>
            <SingleText
              content='Description'
              size={14}
              opacity={0.3}
              lineHeight={1.57}
              spacing='-0.34px'
            >
              Description
            </SingleText>
            <input
              type='text'
              placeholder='Write a description'
              style={{
                display: 'block',
                width: '100%',
                backgroundColor: 'transparent',
                border: 'unset',
                outline: 'unset',
                paddingLeft: 0,
                paddingTop: 5,
                color: '#fff',
              }}
              value={editFood.description}
              onChange={(e) => changeEditFood('description', e.target.value)}
            />
          </Box>
        </Box>
        <ItemPicker
          label='Dietary Tags'
          value='Set dietary tags'
          onClick={onClickDietaryTags}
        />
        <ItemPicker
          label='Nutrition Facts'
          value='Set nutrition facts'
          onClick={onClickNutrition}
        />
        <ItemPicker
          label='Customizations'
          value='Edit customizations'
          onClick={() => onClickCustomizations()}
        />
        {/* was 'type === 'Edit' &&' for customization function. When Item food start working - need extra tests. */}
      </Box>
      <DrawerTypes
        visible={openDrawerTypes}
        onClose={() => setOpenDrawerTypes(false)}
        changeEditFood={changeEditFood}
      />
      <DrawerAssigneCategory
        visible={openDrawerAssigneCategory}
        onClose={() => setOpenDrawerAssigneCategory(false)}
        changeEditFood={changeEditFood}
      />
      <DrawerDietaryTags
        visible={openDrawerDietaryTags}
        onClose={() => setOpenDrawerDietaryTags(false)}
      />
      <DrawerNutrition
        visible={openDrawerNutrition}
        onClose={() => setOpenDrawerNutrition(false)}
        changeEditFood={changeEditFood}
      />
      <DrawerCustomizations
        visible={openDrawerCustomizations}
        onClose={() => setOpenDrawerCustomizations(false)}
        editFood={editFood}
        changeEditFood={changeEditFood}
        changeFoodMultiply={changeFoodMultiply}
      />
    </>
  );
};

DrawerFoodForm.propTypes = {
  editFood: PropTypes.object,
  changeEditFood: PropTypes.func,
  changeFoodMultiply: PropTypes.func,
  onSelectType: PropTypes.func,
};
