import React from 'react'
import PropTypes from 'prop-types';
import SingleText from 'components/SingleText';
import { SingleAntDrawerInputContainer } from './style';

export default function SingleAntDrawerInput({
  placeholder = 'Ex: Caesar Salad',
  className = '',
  label = '',
  prefix = null,
  value = '',
  onChange = () => { },
  onPressEnter = () => { },
  onBlur = () => { },
  ...props
}) {

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onPressEnter();
    }
  }

  return (
    <SingleAntDrawerInputContainer display='flex' className={className} flexDirection='column' borderBottom='solid 1px rgba(255, 255, 255, 0.1)'>
      {label && <SingleText size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>{label}</SingleText>}
      <span className="text-input-container">
        {prefix && <span className="input-prefix">{prefix}</span>}
        <input
          style={{ paddingLeft: 0 }}
          type='text'
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
          {...props}
        />
      </span>
      {/* <Input 
        placeholder={placeholder} 
        prefix={prefix} 
        bordered={false} 
        style={{paddingLeft: 0}} 
        value={value}
        onChange={onChange}
        onPressEnter={onPressEnter}
        onBlur={onBlur}
      /> */}
    </SingleAntDrawerInputContainer>
  )
}

SingleAntDrawerInput.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  prefix: PropTypes.string,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  onBlur: PropTypes.func,
};
