import styled from '@emotion/styled';
import { colors } from 'theme';

export const AccountInputContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 7px;

  label {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.34px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 1px;
  }

  .input-container {
    width: 100%;
    position: relative;
    display: flex;

    .special-title {
      position: absolute;
      top: 3px;
      right: 30px;
      font-size: 12px;
      font-weight: 500;
      color: white;
      line-height: 14px;
      letter-spacing: -0.29px;
    }

    .input-view {
      width: 100%;
      background-color: transparent;
      outline: none;
      border-color: transparent;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.34px;
      color: white;
      padding-left: 0px;
      padding-bottom: 11px;
    }

    .account-input-icon {
      &.is-ok {
        i {
          color: ${colors.lightGreen};
        }
      }

      &.empty {
        i {
          color: ${colors.black1};
        }
      }

      &.error {
        i {
          color: ${colors.redColor};
        }
      }

      i {
        font-size: 14px;
      }
    }
  }
`;
