import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from 'components/Box';
import { FaChevronDown, FaChevronLeft, FaPencilAlt, FaStar } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import { Scrollbars } from 'react-custom-scrollbars';
import { DrawerDetailsContainer } from './style';
import { colors } from 'theme';
import { DefaultUser } from 'assets/images/profile';
import SinglePaginationComponent from 'components/SinglePaginationComponent';
// import { paginationRender } from 'services/functions';
// import { reviewsTemp } from 'services/static';
import ReviewItem from './ReviewItem';
import { StateContext } from 'contexts/StateContextContainer';
import SingleAntImage from 'components/SingleAntImage';
import { NoResult } from 'assets/images/menu';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { DataContext } from 'contexts/DataContextContainer';

export default function DrawerStaffDetails({
  visible = false,
  placement = 'right',
  onClose = () => { },
}) {

  const {
    selectedStaff,
    setOpenDrawerSortReviews,
    setOpenDrawerStaffEdit
  } = useContext(StateContext);

  const reviews = selectedStaff?.reviews;
  const hasReviews = reviews?.length;
  const isActive = selectedStaff?.status === 1;
  const { setChosenStaff } = useContext(DataContext)

  const onClickSort = () => {
    setOpenDrawerSortReviews(true)
  }
  const onClickEdit = () => {
    setChosenStaff(selectedStaff)
    setOpenDrawerStaffEdit(true)
  }

  const getRoleName = (role) => {
    if (role === 'manager') {
      return 'Manager / Owner'
    }
    if (role === 'kitchen_staff') {
      return 'Kitchen Staff'
    }
    if (role === 'waiter') {
      return 'Server / Host'
    }
    return '';
  }


  const getStaffAvatar = () => selectedStaff?.avatar?.file ?? DefaultUser;
  return (
    <DrawerDetailsContainer
      onClose={onClose}
      visible={visible}
      placement={placement}
    >

      <div
        className='blur-backgound-image'
        style={{ backgroundImage: `url(${getStaffAvatar()})` }} />

      <div className='account-action-container'>
        <SingleIconWrapper onClick={onClose}>
          <FaChevronLeft size={20} color='white' />
        </SingleIconWrapper>


        <div className={`account-status ${isActive ? 'active' : 'inactive'}`}>
          <SingleText content='Active' size={14} weight={600} spacing='-0.34px'>{isActive ? "Active" : "Inactive"}</SingleText>
          <div className='tooltip-container'>
            <span className='tooltip-text'>
              {
                isActive ?
                  "This user's status is Active which means they can Check In customers and manage incoming Orders and Requests."
                  :
                  "This user's status is Inactive which means they cannot Check In customers, manage incoming orders, or even access their account."
              }
            </span>
          </div>
        </div>

        <SingleIconWrapper onClick={onClickEdit}>
          <FaPencilAlt className='cursor' size={20} color='white' />
        </SingleIconWrapper>
      </div>

      <div className='profile-container'>
        <Box height={5} width='78%' borderRadius='0px 0px 10px 10px' marginLeft="auto" marginRight="auto"
          backgroundColor={isActive ? colors.lightGreen : '#727373'} />
        <div
          className='profile-photo'
          style={{ backgroundImage: `url(${getStaffAvatar()})` }} />

        <div className='profile-info-container'>
          <SingleText content='first_name' size={20} weight={600} spacing='-0.34px' marginTop={24}>
            {`${selectedStaff.first_name ?? ""} ${selectedStaff.last_name ?? ""}`}
          </SingleText>

          <SingleText content='rating' size={16} weight={600} spacing='-0.34px' marginTop={12}>
            <SingleText content={selectedStaff.rating ?? 'N/A'} size={14} weight={600} spacing='-0.34px' marginRight={10} >
              {`${selectedStaff.role_slug ? getRoleName(selectedStaff.role_slug) : ""}`}
            </SingleText>
            <FaStar color="#fdcb6e" size={16} />
            <SingleText content={selectedStaff.rating ?? 'N/A'} size={14} weight={600} spacing='-0.34px' marginLeft={3}>{selectedStaff.rating ? selectedStaff.rating : 'N/A'}</SingleText>
          </SingleText>

          <SingleText content='email' size={14} weight={600} spacing='-0.34px' color="#ed2626" marginTop={12}>
            {`${selectedStaff.email ?? "test@mail.com"}`}
          </SingleText>

          <SingleText content='phone_number' size={14} spacing='-0.34px' color="#bbbabb" marginTop={12}>
            {`${selectedStaff.phone_number ?? ""}`}
          </SingleText>
        </div>

        <div className='review-container'>
          <SingleText content='reviews' size={18} weight={600} marginTop={37} marginLeft={33} >
            Reviews from customers
          </SingleText>

          <Box display='flex' alignItems='center' justifyContent='space-between' padding='19px 33px'
            borderBottom={hasReviews ? `solid 1px rgba(255, 255, 255, 0.08)` : 'none'} >
            <Box>
              <SingleText content='total reviews' size={14} weight='bold' marginRight={3}>
                {hasReviews ?? 0}
              </SingleText>
              <SingleText content='reviews' size={14} marginLeft={5}>reviews</SingleText>
            </Box>
            {hasReviews &&
              <Box width='fit-content' height={40} display='flex' padding='0px 24px' justifyContent='center' alignItems='center' borderRadius={25} backgroundColor="#27282a"
                onClick={onClickSort}>
                <SingleText content='Sort' size={14} marginRight={8}>Sort</SingleText>
                <FaChevronDown color='white' />
              </Box>}
          </Box>

          <Scrollbars autoHide style={{ flex: 1 }}>
            {hasReviews
              ? reviews.map((_review, _i) =>
                <ReviewItem key={_i} review={_review} />
              )
              : <Box padding='0 44px' height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                <SingleAntImage src={NoResult} width='60%' height='auto' preview={false} style={{ marginBottom: 65 }} />
                <SingleText content='No reviews yet' size={16} weight='bold'>No reviews yet</SingleText>
              </Box>
            }
          </Scrollbars>
          {
            hasReviews &&
            <Box className='pagination-reviews' height={114} display='flex' justifyContent='center' flexDirection='column' marginTop='auto' borderTop={`solid 1px ${colors.border}`}>
              <SinglePaginationComponent defaultPageSize={3} total={10} />
              {/* <Pagination defaultCurrent={1} total={30} itemRender={paginationRender} /> */}
            </Box>
          }
        </div>
      </div>




    </DrawerDetailsContainer >
  )
}

DrawerStaffDetails.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickSort: PropTypes.func,
  onClickSendInvite: PropTypes.func,
};
