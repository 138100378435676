import styled from '@emotion/styled';
import { colors } from 'theme';

export const TablesListFromSelectedFloorContainer = styled.div`
  &.floors-list-container {
    margin-bottom: 150px;
    .floor-item {
      cursor: pointer;
      padding: 15px 24px;
      border-top: 1px solid rgba(255, 255, 255, 0.08);

      &.available {
        .availability-block {
          i {
            color: ${colors.lightGreen};
          }
        }
      }

      &.occupied {
        opacity: 0.7;
      }

      &.inactive {
        opacity: 0.3;
        .availability-block {
          i {
            color: ${colors.gray7};
          }
        }
      }

      .floor-title-top-container {
        margin-bottom: 7px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .floor-title,
        .waiter-name {
          font-size: 13px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.5);
          letter-spacing: -0.31px;
        }
      }

      .table-name-container {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .table-name {
          font-size: 15px;
          font-weight: 600;
          color: #ffffff;
          letter-spacing: -0.36px;
        }

        .order-id {
          font-size: 15px;
          font-weight: 600;
          color: rgba(255, 255, 255, 0.32);
          letter-spacing: -0.36px;
        }
      }

      .bottom-table-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .users-block {
          display: flex;
          align-items: center;

          .seats-and-guests {
            margin-right: 21px;
            i {
              font-size: 13px;
              color: rgba(255, 255, 255, 0.5);
            }

            .seats-and-guests-title {
              font-size: 13px;
              color: rgba(255, 255, 255, 0.5);
              letter-spacing: -0.31px;
              margin-left: 5px;
            }
          }

          .total-food-items-and-price {
            display: flex;
            align-items: center;

            .food-items {
              margin-right: 21px;
              display: flex;
              align-items: center;

              i {
                font-size: 13px;
                color: rgba(255, 255, 255, 0.5);
              }

              .food-items-title {
                margin-left: 5px;
                font-size: 13px;
                letter-spacing: -0.31px;
                color: rgba(255, 255, 255, 0.5);
              }
            }

            .price-container {
              .price-title {
                font-size: 13px;
                letter-spacing: -0.31px;
                color: rgba(255, 255, 255, 0.5);
              }
            }
          }
        }
        .availability-block {
          i {
            font-size: 14px;
          }
          span {
            font-size: 13px;
            font-weight: 500;
            color: #8d8d8e;
            margin-left: 5px;
          }

          .occupied-loading {
            i {
              font-size: 14px;
              letter-spacing: -0.31px;
              color: rgba(247, 166, 76, 0.4);
            }
          }
        }
      }
    }
    .floor-item:last-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    }
  }
`;
