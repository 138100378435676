import styled from '@emotion/styled'
import { colors } from 'theme';

export const ReviewItemContainer = styled.div`
  padding: 14px 22px;
  display: flex;
  align-items: flex-start;
  border-bottom: solid 1px ${colors.border};
  .review-content {
    position: relative;
    max-height: 97px;
    opacity: 0.8;
    overflow: hidden;
    
    .gradient-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      box-shadow: inset 0px -148px 87px -100px #1b1c1d;
    }
  }
`;

export const SpaceReviews = styled.div`
  margin-bottom: 5px;
`;
