import React from 'react';
import PropTypes from 'prop-types';
import { DrawerPreventCreateDuplicatesContainer } from './style';
import SingleButton from 'components/SingleButton';
import SingleText from 'components/SingleText';

export function DrawerPreventCreateDuplicates({ visible, onClose }) {
  return (
    <DrawerPreventCreateDuplicatesContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <SingleText size={24} marginBottom={20}>
        This day is already closed
      </SingleText>
      <SingleText size={16} marginBottom={30}>
        Try choose another day.
      </SingleText>
      <SingleButton title='Choose another day' onClick={onClose} />
    </DrawerPreventCreateDuplicatesContainer>
  );
}

DrawerPreventCreateDuplicates.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
