import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerEditFoodItemFromOrderContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  padding: 36px 26px 22px;
  display: flex;
  flex-direction: column;

  .close-icon {
    cursor: pointer;
    padding-left: 7px;
    margin-bottom: 40px;
    i {
      font-size: 28px;
      letter-spacing: 0.53px;
      color: white;
    }
  }

  .will-be-added-to-the-customer-bill {
    font-size: 13px;
    letter-spacing: 0.25px;
    color: white;
    text-align: center;
    margin-bottom: 18px;
  }
`;
