import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { OtherRequestedByMistakeAlertContainer } from './style';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';

export function OtherRequestedByMistakeAlert({ onClose }) {
  const context = useContext(modalContext);
  return (
    <OtherRequestedByMistakeAlertContainer>
      {context.modal === 'Reject-Other-RejectedByMistake-Modal-4' ? (
        <div className='reject-other-requestedbymistake-wrapper animate__animated animate__fadeInUp'>
          <div className='reject-other-requestedbymistake-svg-position'>
            <svg height='100' width='100'>
              <circle cx='50' cy='50' r='40' strokeWidth='3' fill='#27282a' />
            </svg>
          </div>

          <div className='reject-other-requestedbymistake-check-wrapper'>
            <i className='far fa-times reject-other-requestedbymistake-times'></i>
          </div>

          <div className='reject-other-requestedbymistake-body-wrapper'>
            <div>
              <p className='reject-other-requestedbymistake-text-style'>
                Request Rejected
              </p>
            </div>
            <Button
              variant='none'
              className='reject-other-requestedbymistake-okay-btn'
              onClick={onClose}
            >
              Okay
            </Button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </OtherRequestedByMistakeAlertContainer>
  );
}

OtherRequestedByMistakeAlert.propTypes = {
  onClose: PropTypes.func,
};
