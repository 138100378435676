import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { FaTimes, FaTrash, FaCamera, FaVideo } from 'react-icons/fa';
import { Scrollbars } from 'react-custom-scrollbars';
import Tab from 'react-bootstrap/Tab';
import { DrawerFoodItemContainer } from './style';
import { colors } from 'theme';
import { restaurantServices } from 'services/api-services';
import { AuthContext } from 'contexts/AuthContextContainer';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { SingleVideoUploader } from 'components/SingleVideoUploader';
import { SingleAvatarUploader } from 'components/SingleAvatarUploader';
import { DrawerFoodForm } from './DrawerFoodForm';

import { DefaultFood } from 'assets/images/menu';

export const DrawerFoodItem = ({
  visible,
  placement = 'right',
  setOpenDrawerFoodItem,
  editFood,
}) => {
  const { restaurantId } = useContext(AuthContext);
  const [food, setFood] = useState({});
  const changeFood = (key, value) => setFood({ ...food, [key]: value });
  const changeFoodMultiply = (updatedFood) => setFood(updatedFood);

  const [selectedTab, setSelectedTab] = useState('image');
  const [uploadingImage, setUploadingImage] = useState(false);
  const [uploadingVideo, setUploadingVideo] = useState(false);

  const [uploadedVideo, setUploadedVideo] = useState();

  const onClose = () => setOpenDrawerFoodItem(false);

  useEffect(() => {
    if (visible) {
      if (Object.keys(food).length === 0) {
        setFood(editFood);
      }
    } else {
      if (Object.keys(food).length) {
        setFood({});
      }
    }
  }, [visible, editFood, food, setFood]);

  const onUploadImage = () => {
    setUploadingImage(true);
  };

  function setNewVideo(e) {
    setUploadedVideo(e.target.files[0]);
    setUploadingVideo(true);
    console.log('video for uploading ', e.target.files[0]);
  }

  const onSave = () => {
    onBeforeSave(food, restaurantId);
    onClose();
  };

  return (
    <DrawerFoodItemContainer
      placement={placement}
      onClose={onClose}
      visible={visible}
    >
      <div className='drawer-food-item-top-part'>
        <SingleIconWrapper>
          <FaTimes
            className='cursor'
            size={20}
            color={colors.white3}
            onClick={onClose}
          />
        </SingleIconWrapper>
        <Box display='flex' justifyContent='center' flex={1}>
          <SingleText content='Item' align='center' size={22} weight={500}>
            Edit Item
          </SingleText>
        </Box>
        <FaTrash
          className='cursor'
          size={20}
          color='white'
          onClick={/* onClickDeleteItem */ () => {}}
        />
      </div>
      <Scrollbars className='scroolbars-container' autoHide style={{ flex: 1 }}>
        <Box className='scroolbars-container-inner' padding='0 24px'>
          <Tab.Container
            id='tabs-bootstrap'
            defaultActiveKey='image'
            activeKey={selectedTab}
          >
            <Tab.Content>
              <Tab.Pane eventKey='image'>
                <SingleAvatarUploader
                  shape='square'
                  height='220px'
                  placeholder={food.photo ? food.photo.file : DefaultFood}
                  uploading={uploadingImage}
                  onUpload={onUploadImage}
                  overlay={
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                    >
                      <FaCamera size={24} color='white' />
                      <SingleText
                        content='Change Image'
                        size={15}
                        weight={600}
                        marginLeft={6}
                        spacing='-0.36px'
                      >
                        Change Image
                      </SingleText>
                    </Box>
                  }
                />
              </Tab.Pane>
              <Tab.Pane eventKey='video'>
                <SingleVideoUploader
                  shape='square'
                  placeholder={DefaultFood}
                  uploading={uploadingVideo}
                  // onUpload={onUploadVideo}
                  onUpload={(e) => setNewVideo(e)}
                  uploadedVideo={uploadedVideo}
                  overlay={
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                    >
                      <FaVideo size={24} color='white' />
                      <SingleText
                        size={15}
                        weight={600}
                        marginLeft={6}
                        spacing='-0.36px'
                      >
                        Change Video
                      </SingleText>
                    </Box>
                  }
                />
              </Tab.Pane>
            </Tab.Content>
            <div className='tab-switcher-container'>
              <div
                href='#'
                className={`tab-switcher-item ${
                  selectedTab === 'image' ? 'active' : ''
                }`}
                onClick={() => setSelectedTab('image')}
              >
                Image
              </div>
              <div
                href='#'
                className={`tab-switcher-item ${
                  selectedTab === 'video' ? 'active' : ''
                }`}
                onClick={() => setSelectedTab('video')}
              >
                Video
              </div>
            </div>
          </Tab.Container>
          <DrawerFoodForm
            editFood={food}
            changeEditFood={changeFood}
            changeFoodMultiply={changeFoodMultiply}
          />
        </Box>
      </Scrollbars>
      <Box
        padding='24px'
        display='flex'
        flexDirection='column'
        marginTop='auto'
      >
        <SingleButton
          title='Save'
          btnColor={
            isCustomizationsWasChanged(food) ||
            isSelectedCustomizationsWasChanged(food)
              ? colors.redColor
              : 'rgba(255, 255, 255, 0.1)'
          }
          txtColor='white'
          onClick={onSave}
          disabled={
            !isCustomizationsWasChanged(food) &&
            !isSelectedCustomizationsWasChanged(food)
          }
        />
      </Box>
    </DrawerFoodItemContainer>
  );
};

DrawerFoodItem.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  setOpenDrawerFoodItem: PropTypes.func,
  editFood: PropTypes.object,
};

export const isCustomizationsWasChanged = (food) =>
  ('beforeSubmitCustomizations' in food &&
    food.beforeSubmitCustomizations.length !== 0) ||
  food.isSequenceChanged;

export const isSelectedCustomizationsWasChanged = (food) =>
  food.selectedCustomizationsChanged &&
  !!food.selectedCustomizationsChanged.length;

export const onBeforeSave = async (food, restaurantId) => {
  // here we checking if something changed in food
  // currently we have checks only for customizations

  if (
    isCustomizationsWasChanged(food) ||
    isSelectedCustomizationsWasChanged(food)
  ) {
    // here we will update new values from client to server

    if (isSelectedCustomizationsWasChanged(food)) {
      await Promise.all(
        food.selectedCustomizationsChanged.map(
          async (updatedCustomization) =>
            await restaurantServices.updateCustomizationById(
              {
                restaurantId,
                foodId: food.id,
                customizationId: updatedCustomization.id,
              },
              updatedCustomization
            )
        )
      );
    }

    const customizationsBeforeWithoutIdNowWillBeWithId = [];

    await Promise.all(
      food.beforeSubmitCustomizations.map(
        async (customization) =>
          await restaurantServices.createCustomizationOfFood(
            {
              restaurantId,
              foodId: food.id,
              payload: customization,
            },
            ({ id }) =>
              customizationsBeforeWithoutIdNowWillBeWithId.push({
                id,
                name: customization.name,
              })
          )
      )
    );

    return await restaurantServices.updateSortCustomizationByUserSequence({
      restaurantId,
      foodId: food.id,
      payload: transformSequenceOfCustomizationsWithMissedPlaces(
        food.sortSequence,
        customizationsBeforeWithoutIdNowWillBeWithId
      ),
    });
  }
};

// works like this (const a = [1,2,3, 'abc']; const b = [{ id: 4, name: 'abc'}] ) => [1,2,3,4]
export const transformSequenceOfCustomizationsWithMissedPlaces = (
  sequence,
  missedItems
) => {
  return sequence.map((id) => {
    const nameItem = missedItems.find(({ name }) => name === id);
    if (nameItem) {
      return nameItem.id;
    } else {
      return id;
    }
  });
};
