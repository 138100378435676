import React from 'react';
import PropTypes from 'prop-types';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { SortableListContainer } from './style';

export default function SingleDragger({
  items,
  containerStyle = {},
  lockAxis = '',
  lockToContainerEdges = false,
  lockOffset = '50%',
  renderItem = () => {},
  onSortStart = () => {},
  onSortEnd = () => {},
}) {
  const SortableItem = sortableElement(({ item, order }) => {
    return renderItem(item, order);
  });

  const SortableList = sortableContainer(({ children }) => {
    return (
      <SortableListContainer id='drag-menu-options'>
        {children}
      </SortableListContainer>
    );
  });

  return (
    <div style={containerStyle}>
      <SortableList
        onSortStart={onSortStart}
        onSortEnd={onSortEnd}
        helperClass='row-dragging'
        helperContainer={() => document.getElementById('drag-menu-options')}
        distance={20}
        lockAxis={lockAxis}
        lockOffset={lockOffset}
        lockToContainerEdges={lockToContainerEdges}
      >
        {items.map((value, _i) => {
          return (
            <SortableItem
              key={`item-${_i}`}
              index={_i}
              order={_i}
              item={value}
            />
          );
        })}
      </SortableList>
    </div>
  );
}

SingleDragger.propTypes = {
  onSortStart: PropTypes.func,
  onSortEnd: PropTypes.func,
  renderItem: PropTypes.func,
  items: PropTypes.array,
  containerStyle: PropTypes.object,
  axis: PropTypes.string,
  lockAxis: PropTypes.string,
  lockToContainerEdges: PropTypes.bool,
  lockOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
