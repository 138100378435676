import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import SideMenu from './components/SideMenu';
import { PageLayoutContainer } from './style';
import Drawers from './components/Drawers';

const PageLayout = ({ children }) => {
  return (
    <PageLayoutContainer>
      <SideMenu />
      <div className='site-layout'>
        <Scrollbars autoHide style={{ flex: 1 }} className="scrollbars">
          <main>
            {children}
          </main>
        </Scrollbars>
      </div>
      <Drawers />
    </PageLayoutContainer>
  );
};

PageLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default PageLayout;
