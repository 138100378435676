import React from 'react';
import PropTypes from 'prop-types';
import { FaBars, FaUtensils } from 'react-icons/fa';
import { MenuCardContainer } from './style';
import { AnalyzeFood } from './AnalyzeFood';
import { FoodControl } from './FoodControl';

export default function ItemCard({
  foodItem,
  onClick = () => {},
  isDraggable = false,
}) {
 
  return (
    <MenuCardContainer
      className='item-card-main-container'
      onClick={() => onClick(foodItem)}
    >
      <div className='menu-card'>
        <div className='menu-card-left'>
          <div className='inner-block-menu-left'>
            {isDraggable && (
              <div className='card-col icon' width='fit-content'>
                <div className='icon-container'>
                  <FaBars className='icon-drag' size={20} />
                </div>
              </div>
            )}
            <div
              className={`card-col image-place ${
                isDraggable ? 'image-left-part' : ''
              }`}
            >
              {foodItem.photo ? (
                <div
                  className={`food-photo ${
                    foodItem.kind === 1 ? 'contain' : ''
                  }`}
                  style={{
                    backgroundImage: `url(${foodItem.photo.file})`,
                    backgroundSize: foodItem.kind === 1 ? 'contain' : 'cover',
                  }}
                />
              ) : (
                <div className='utensils'>
                  <FaUtensils
                    className='no-image-class'
                    size={70}
                    color='rgba(255,255,255,0.19)'
                  />
                </div>
              )}
            </div>
          </div>
          <div className='name-block main-description'>
            <span className='title'>{foodItem.title}</span>
            <AnalyzeFood
              price={foodItem.price}
              rating={foodItem.reviews_count}
              averageRating={foodItem.yum_percent}
              calories={
                foodItem.nutrition
                  ? JSON.parse(foodItem.nutrition)?.[0]?.calories
                  : null
              }
            />
            <span className='description'>
              <p className='text-description'>{foodItem.description}</p>
            </span>
            <FoodControl foodItem={foodItem} isDraggable={isDraggable} />
          </div>
        </div>
      </div>
    </MenuCardContainer>
  );
}

ItemCard.propTypes = {
  foodItem: PropTypes.object,
  isDraggable: PropTypes.bool,
  onClickDelete: PropTypes.func,
  onClick: PropTypes.func,
};
