import styled from '@emotion/styled';
import { colors } from 'theme';

export const ActiveOrdersContainer = styled.div`
  width: 100%;
  display: flex;

  &.empty-active-orders {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 2px;
      height: 100vh;
      background-color: ${colors.black1};
      box-shadow: 0 -2px 22px 0 rgba(0, 0, 0, 0.2);
      position: absolute;
      top: -163px;
      right: 563px;
    }
  }
`;
