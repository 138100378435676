import styled from '@emotion/styled';

export const OrdersContainer = styled.div`
  color: #fff;
  font-family: Montserrat;
  position: relative;
  height: 100%;

  .order-header-part {
    .orders-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 30px 25px 35px;

      .header-title {
        font-size: 24px;
        font-weight: bold;

        &.add-item-header-title {
          font-weight: normal;
        }
      }

      .btn-add-item-cancel {
        border-radius: 25px !important;
      }
    }

    .orders-control-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 65.5%;
    }
  }
`;
