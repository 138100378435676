import React, { useContext } from 'react'
import PropTypes from 'prop-types';
// import './style.scss';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerDeleteCategoryContainer } from './style';
import { colors } from 'theme';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';

export default function DrawerDeleteCategory({ 
  visible = false,
  placement = 'bottom',
  onClose = () => {},
}) {

  const {
    setOpenDrawerMoveCategoryItems,
    setOpenDrawerDeleteCategoryConfirm,
  } = useContext(StateContext);
  const { chosenCategory, setChosenCategory } = useContext(DataContext);

  const onClickMoveItems = () => {
    setOpenDrawerMoveCategoryItems(true)
  }

  const onClickDeleteCategory = () => {
    setOpenDrawerDeleteCategoryConfirm(true)
  }

  const closeDeleteCategory = () => {
    setChosenCategory();
    onClose();
  }

  return (
      <DrawerDeleteCategoryContainer
        placement={placement}
        onClose={closeDeleteCategory}
        height={359}
        visible={visible}
      >
        <Box is='div' display='flex' flexDirection='column' alignItems='flex-start' marginBottom={30}>
          <SingleText size={22} weight={500} marginBottom={10}>Delete Category</SingleText>
          <SingleText size={14} lineHeight={1.5} spacing='0.27px'>Are you sure you want to delete category <b>{chosenCategory?.name}</b> with items inside?</SingleText>
        </Box>
        <Box is='div' display='flex' flexDirection='column'>
          <SingleButton 
            title='Move Items' 
            btnColor={colors.redColor}
            txtColor='white'
            margin='0 0 20px'
            onClick={onClickMoveItems} 
          />
          <SingleButton 
            title='Delete Category & Items' 
            btnColor='#61646c'
            txtColor='white'
            margin='0 0 20px'
            onClick={onClickDeleteCategory} 
          />
          <SingleButton 
            title='Cancel' 
            btnColor='transparent'
            txtColor={colors.redColor}
            onClick={closeDeleteCategory} 
          />
        </Box>
      </DrawerDeleteCategoryContainer>
  )
}

DrawerDeleteCategory.propTypes = {
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickDay: PropTypes.func,
  onClickMoveItems: PropTypes.func,
  onClickDeleteCategory: PropTypes.func,
};
