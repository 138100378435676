import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThisAppIsNotSupportedContainer } from './style';
import { colors } from 'theme';

export default function ThisAppIsNotSupported({ link = '' }) {
  useEffect(() => {
    document.body.style.backgroundColor = colors.black1;

    return () => {
      document.body.style.backgroundColor = undefined;
    };
  }, []);

  return (
    <ThisAppIsNotSupportedContainer>
      <div className='this-app-main-image-container'>
        <div className='this-app-is-not-supported' />
      </div>
      <div className='is-not-supported-container'>
        <h2 className='not-supported-title'>
          This application is not supported on mobile browsers.
        </h2>
        <p className='not-supported-text'>
          You can download our iOS app on the App Store to continue on your
          mobile device.
        </p>
        <a className='download-ios-app-link' href={link}>
          <div className='download-ios-app-icon' />
        </a>
      </div>
    </ThisAppIsNotSupportedContainer>
  );
}

ThisAppIsNotSupported.propTypes = {
  link: PropTypes.string,
};
