import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ItemPicker from 'components/DrawerForm/ItemPicker';
import DrawerPriceTier from './DrawerPriceTier';

export function PriceTierField({ isInDrawer = false, priceLevel, onChange }) {
  const [openDrawerPriceTier, setDrawerPriceTier] = useState(false);

  return (
    <>
      <ItemPicker
        isInDrawer={isInDrawer}
        className='horizontal-inner-element'
        label='Price Tier'
        placeholder='Choose tier'
        onClick={() => setDrawerPriceTier(true)}
        value={priceLevel ? '$'.repeat(priceLevel) : ''}
      />
      <DrawerPriceTier
        visible={openDrawerPriceTier}
        onClose={() => setDrawerPriceTier(false)}
        priceLevel={priceLevel}
        onChange={onChange}
      />
    </>
  );
}

PriceTierField.propTypes = {
  isInDrawer: PropTypes.bool,
  priceLevel: PropTypes.number,
  onChange: PropTypes.func,
};
