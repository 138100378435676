import React from 'react';
import PropTypes from 'prop-types';
import { DrawerSuccessfullyAddedContainerStyled } from './style';
import { DrawerSuccessfullyAdded } from './DrawerSuccessfullyAdded';

export function DrawerSuccessfullyAddedContainer({
  visible,
  onClose,
  category,
  amount,
}) {
  return (
    <DrawerSuccessfullyAddedContainerStyled
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      <DrawerSuccessfullyAdded
        visible={true}
        onClose={onClose}
        activeCategory={category?.name??null}
        amount={amount}
      />
    </DrawerSuccessfullyAddedContainerStyled>
  );
}

DrawerSuccessfullyAddedContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  category: PropTypes.string,
  amount: PropTypes.number,
};
