import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerBankAccountRemoveContainerStyled } from './style';
import { DrawerBankAccountRemove } from './DrawerBankAccountRemove';

export function DrawerBankAccountRemoveContainer({
  visible,
  onClose,
  clearBankAccount,
}) {
  const [openDrawerBankAccountRemove, setOpenBankAccountRemove] =
    useState(false);
  const onOpenDrawerBankAccountRemove = () => setOpenBankAccountRemove(true);
  const onCloseDrawerBankAccountRemove = () => setOpenBankAccountRemove(false);

  const close = () => {
    onCloseDrawerBankAccountRemove();
    onClose();
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerBankAccountRemove();
    }
  }, [visible]);

  return (
    <DrawerBankAccountRemoveContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerBankAccountRemove
        visible={openDrawerBankAccountRemove}
        onClose={close}
        onRemove={clearBankAccount}
      />
    </DrawerBankAccountRemoveContainerStyled>
  );
}

DrawerBankAccountRemoveContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  clearBankAccount: PropTypes.func,
};
