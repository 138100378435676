import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerChangeToPendingContainerStyled } from './style';
import { DrawerChangeToPending } from './DrawerChangeToPending';
import { DrawerStatusChanged } from './DrawerStatusChanged';

export function DrawerChangeToPendingContainer({ visible, onClose }) {
  const [openDrawerChangeToPending, setOpenDrawerChangeToPending] =
    useState(false);
  const onOpenDrawerChangeToPending = () => setOpenDrawerChangeToPending(true);
  const onCloseDrawerChangeToPending = () =>
    setOpenDrawerChangeToPending(false);

  const [openDrawerStatusChanged, setOpenDrawerStatusChanged] = useState(false);
  const onOpenDrawerStatusChanged = () => setOpenDrawerStatusChanged(true);
  const onCloseDrawerStatusChanged = () => setOpenDrawerStatusChanged(false);

  const close = () => {
    onCloseDrawerStatusChanged();
    onCloseDrawerChangeToPending();
    onClose();
  };

  const onChangeToPending = () => {
    onCloseDrawerChangeToPending();
    onOpenDrawerStatusChanged();
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerChangeToPending();
    }
  }, [visible]);

  return (
    <DrawerChangeToPendingContainerStyled
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      <DrawerChangeToPending
        visible={openDrawerChangeToPending}
        onClose={close}
        onChangeToPending={onChangeToPending}
      />
      <DrawerStatusChanged visible={openDrawerStatusChanged} onClose={close} />
    </DrawerChangeToPendingContainerStyled>
  );
}

DrawerChangeToPendingContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
