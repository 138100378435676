import React from 'react'
import PropTypes from 'prop-types';
import { NoResult } from 'assets/images/menu';
import { SingleEmptyScreenContainer } from './style';
import Box from 'components/Box';
import SingleAntImage from 'components/SingleAntImage';
import SingleText from 'components/SingleText';

export default function SingleEmptyScreen() {
  return (
    <SingleEmptyScreenContainer>
      <Box display='flex' flexDirection='column' alignItems='center' height='100%'>
        <SingleAntImage src={NoResult} width={347} height={314} style={{marginBottom: 61}} />  
        <SingleText content='No results' size={24} weight='bold' marginBottom={14}>No results</SingleText>
        <SingleText content='Try broadening your search' size={16} weight={500}>Try broadening your search</SingleText>
      </Box>
    </SingleEmptyScreenContainer>
  )
}

SingleEmptyScreen.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.number,
  isIn: PropTypes.string,
  color: PropTypes.string,
};
