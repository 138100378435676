import styled from '@emotion/styled';
import { colors } from 'theme';

export const FoodNutritionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .nutrition-items {
    display: flex;
    justify-content: center;
    align-items: center;

    .nutrition-item-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: rgba(255, 255, 255, 0.2);
      padding: 0px 36px;

      &:nth-of-type(2) {
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }

      .nutrition-value {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.48px;
        color: white;
        margin-bottom: 5px;
      }

      .nutrition-type {
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: -0.31px;
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }

  .btn-nutrition-info {
    margin-top: 18px;
    border: 1px solid transparent;
    background-color: transparent;
    outline: none;

    .btn-nutrition-info-text {
      font-size: 14px;
      letter-spacing: -0.34px;
      color: ${colors.redColor};

      i {
        font-size: 14px;
        color: ${colors.redColor};
        margin-right: 3px;
      }
    }
  }
`;
