import { StatusGroup } from './StatusGroup';

export class StatusList extends StatusGroup {
  getList() {
    return [
      this.profileStatus,
      this.menuStatus,
      this.tableStatus,
      this.bankStatus,
    ].map((status) => status.isCompleted());
  }
}
