import React from 'react'
import PropTypes from 'prop-types';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerHideNutritionContainer } from './style';
import { colors } from 'theme';

export default function DrawerHideNutrition({
  visible = false,
  placement = 'bottom',
  onClose = () => { },
  hiddenNutrition,
  onAddHiddenNutrition,
  onRemoveHiddenNutrition,
  nutritionToHide,
  clearNutritionToHide,
}) {

  const onClickHide = () => {
    if (!hiddenNutrition.includes(nutritionToHide.key)) {
      onAddHiddenNutrition(hiddenNutrition, nutritionToHide);
    }

    clearNutritionToHide();
    onClose();
  }

  const onClickKeep = () => {
    if (hiddenNutrition.includes(nutritionToHide.key)) {
      onRemoveHiddenNutrition(hiddenNutrition, nutritionToHide);
    }

    clearNutritionToHide();
    onClose();
  }

  return (
    <DrawerHideNutritionContainer
      placement={placement}
      onClose={onClose}
      height='fit-content'
      visible={visible}
    >
      <Box is='div' display='flex' flexDirection='column' alignItems='flex-start' marginBottom={30}>
        <SingleText size={22} weight={500} marginBottom={10}>Hide Nutrition</SingleText>
        <SingleText weight={500} marginBottom={10} lineHeight={1.5} spacing='0.27px'>Are you sure you want to hide <b>{nutritionToHide.name}</b>?</SingleText>
      </Box>
      <Box is='div' display='flex' flexDirection='column'>
        <SingleButton
          title='Hide'
          btnColor={colors.redColor}
          txtColor='white'
          margin='0 0 20px'
          onClick={onClickHide}
        />
        <SingleButton
          title='Keep'
          btnColor='#61646c'
          txtColor='white'
          margin='0 0 20px'
          onClick={onClickKeep}
        />
      </Box>
    </DrawerHideNutritionContainer>
  )
}

DrawerHideNutrition.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  hiddenNutrition: PropTypes.array,
  onAddHiddenNutrition: PropTypes.func,
  onRemoveHiddenNutrition: PropTypes.func,
  nutritionToHide: PropTypes.object,
  clearNutritionToHide: PropTypes.func,
};
