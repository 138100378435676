import styled from '@emotion/styled';

export const FoodDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  .features-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .food-check-item-label {
      border-radius: 14px;
      background-color: rgba(255, 255, 255, 0.2);
      padding: 5px 13px;
      margin-right: 5px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;

      .food-check-icon {
        margin-right: 5px;

        i {
          font-size: 13px;
        }
      }

      .food-check-name-container {
        .food-check-name {
          font-size: 13px;
          letter-spacing: -0.31px;
          color: white;
        }
      }
    }
  }
`;
