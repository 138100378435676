import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Box from 'components/Box';
import { FaChevronLeft } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import { DrawerTypesContainer } from './style';
import SingleIconWrapper from 'components/SingleIconWrapper';

export const FoodTypes = [
  { name: 'Food', value: 0 },
  { name: 'Drink', value: 1 },
  { name: 'Other', value: 2 }
];

export default function DrawerTypes({
  visible = false,
  placement = 'right',
  value,
  onClose = () => { },
  onSubmit = () => { },
}) {

  const [selected, setSelected] = useState(value);

  const onChangeType = (type) => {
    setSelected(type);
    onSubmit(type)
  }

  return (
    <DrawerTypesContainer
      onClose={onClose}
      visible={visible}
      placement={placement}
      active={selected}
    >
      <Box is='div' display='flex' alignItems='center' marginBottom={40}>
        <SingleIconWrapper onClick={onClose}>
          <FaChevronLeft size={20} color='white' />
        </SingleIconWrapper>
        <Box is='div' display='flex' justifyContent='center' flex={1}>
          <SingleText content='Type' align='center' size={22} weight={500}>Type</SingleText>
        </Box>
      </Box>
      <Box is='div' className='drawer-status--options'>
        {FoodTypes.map((_type, _i) =>
          <Box
            key={_i}
            className={`drawer-status--option ${selected === _type.value ? 'active' : 'inactive'}`}
            cursor="pointer"
            marginBottom={15}
            onClick={() => onChangeType(_type.value)}
          >
            <SingleText content={_type} size={14} weight={500} spacing='-0.34'>{_type.name}</SingleText>
          </Box>
        )}
      </Box>
    </DrawerTypesContainer>
  )
}

DrawerTypes.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  value: PropTypes.number,
};
