import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AssignContainer } from './style';
import SingleButton from 'components/SingleButton';
import { ItemAssignCategory } from './ItemAssignCategory';
import { colors } from 'theme';

export function Assign({
  categories = [],
  value = [],
  onSubmit = () => { },
}) {
  const [selected, setSelected] = useState(value);
  const canAssign = selected.length;
  const checkIsSelected = (id) => selected.includes(id);

  const onSelect = (selectedItem) => {
    if (!checkIsSelected(selectedItem.id)) {
      setSelected([...selected, selectedItem.id]);
    } else {
      setSelected(selected.filter((item) => item !== selectedItem.id));
    }
  };

  const onClickAssign = () => onSubmit(selected)

  return (
    <AssignContainer>
      {categories.map((category, index) => (
        <ItemAssignCategory
          key={index}
          category={category}
          selected={selected}
          onSelect={onSelect}
          checkIsSelected={checkIsSelected}
        />
      ))}
      <SingleButton
        className='btn-assign'
        title={canAssign ? `Assign(${selected.length})` : `Assign`}
        btnColor={canAssign ? colors.redColor : '#61646c'}
        onClick={() => canAssign ? onClickAssign(selected) : {}}
      />
    </AssignContainer>
  );
}

Assign.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  subCategories: PropTypes.object,
  onSubmit: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.number),
};
