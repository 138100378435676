import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { PartialRefundModalContainer } from './style';
import Button from 'react-bootstrap/Button';
// import { ReviewReturn } from './ReviewReturn';
import { modalContext } from 'routes';
import { RequestPendingContext } from 'contexts/RequestPendingContextContainer';

export function PartialRefundModal({ visible, onClose }) {

  const context = useContext(modalContext);
  const {
    targetPercentage,
    setTargetPercentage,
    displayPercentageDigit,
    setDisplayPercentageDigit,
  } = useContext(RequestPendingContext);
  
  function continueFun() {
    // this log for test
    // console.log('partial clicked')
    onClose()
  }

  // @TODO other-screen logic
  const [othersScreen, setOtherScreen] = useState(false)
  function otherScreen() {
    setOtherScreen(true)
  }

  // it's log for test 
  // console.log(targetPercentage,'targetPercentage**')
  // console.log(displayPercentageDigit, 'displayPercentageDigit**')

  return (
    <PartialRefundModalContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      {/* partial refund */}
      {context.modal === 'Return-ChooseText-Modal-1' ? (
        <>
          <div className='return-partial-wrapper animate__animated animate__fadeInUp'>
            <p className='return-partial-text-style'>Partial Refund</p>

            <div className='return-partial-box-wrapper'>
              <div
                className='return-partial-border-box-1'
                onClick={() => {
                  setTargetPercentage(0.1)
                  setDisplayPercentageDigit('10%')
                }}
                style={{background: displayPercentageDigit === '10%' ? 'red': ''}}
                aria-hidden="true"
              >
                <div className='return-partial-percentage'>
                  10%
                </div>
                <div className='return-partial-dollar'>$0.1</div>
              </div>

              <div
                className='return-partial-border-box-1'
                onClick={() => {
                  setTargetPercentage(0.25)
                  setDisplayPercentageDigit('25%')
                }}
                style={{background: displayPercentageDigit === '25%' ? 'red': ''}}
                aria-hidden="true"
              >
                <div className='return-partial-percentage'>
                  25%
                </div>
                <div className='return-partial-dollar'>$0.25</div>
              </div>

              <div
                className='return-partial-border-box-1'
                onClick={() => {
                  setTargetPercentage(0.5)
                  setDisplayPercentageDigit('50%')
                }}
                style={{background: displayPercentageDigit === '50%' ? 'red': ''}}
                aria-hidden="true"
              >
                <div className='return-partial-percentage'>
                  50%
                </div>
                <div className='return-partial-dollar'>$0.5</div>
              </div>

              <div
                className='return-partial-border-box-1'
                onClick={() => {
                  setTargetPercentage(0.7)
                  setDisplayPercentageDigit('70%')
                  otherScreen()
                }}
                style={{background: displayPercentageDigit === '70%' ? 'red': ''}}
                aria-hidden="true"
              >
                <div className='return-partial-percentage'>
                  Other
                </div>
              </div>
            </div>
                 
            {/* other's modal */}
            {othersScreen ? <div className='return-partial-other-section-wrapper'>
                <div className='return-partial-other-section-inner'>
                  <p className='return-partial-other-text-style'>Percentage</p>
                  <p className='return-partial-other-text-style'>
                    70%
                  </p>
                </div>
                <div>
                  <p className='return-partial-other-cost'>$0.7</p>
                </div>
            </div> : ''}

            <p className='return-partial-refund'>
              ${targetPercentage ? targetPercentage : '0.00'} will be refunded.
            </p>

            <div className='return-partial-btn-center'>
              <Button
                variant='none'
                className='return-partial-btn-style'
                onClick={continueFun}
              >
                <span className='return-partial-btn-text'>Continue</span>
              </Button>
            </div>

            <p
              className='return-partial-cancel-text'
              onClick={onClose}
              aria-hidden='true'
            >
              Cancel
            </p>
          </div>
        </>
      ) : (
        ' '
      )}
    </PartialRefundModalContainer>
  );
}

PartialRefundModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
