import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CompletedNotificationContainer } from './style';
import { DrawerFoodReview } from './DrawerFoodReview';

// @TODO Implement special Instructions for it
export function CompletedNotification({ foodItem, children }) {
  const [openDrawerFoodReview, setOpenDrawerFoodReview] = useState(false);
  const onOpenDrawerFoodReview = () => {
    setOpenDrawerFoodReview(true);
  };
  const onCloseDrawerFoodReview = () => setOpenDrawerFoodReview(false);

  return (
    <CompletedNotificationContainer>
      <div className='total-container'>
        <span className='total-title'>{foodItem.total}</span>
      </div>
      <div className='main-container'>
        <div className='title-container'>
          <div className='inner-title-container'>
            <span className='notification-title'>{foodItem.name}</span>
          </div>
          <div className='price-container'>
            <span className='price-title'>
              {foodItem.price === 0
                ? 'Free'
                : `$ ${(
                    foodItem.price * foodItem.total +
                    foodItem.addons.reduce(
                      (addonsTotalPrice, [, currentPrice]) =>
                        addonsTotalPrice + currentPrice,
                      0
                    ) *
                      foodItem.total
                  ).toFixed(2)}`}
            </span>
          </div>
        </div>
        {children ? children : ''}
        <div className='reviews-container'>
          {foodItem.rating ? (
            <div className='reviews-core-container'>
              <div className='reviews-core'>
                <div className='reviews-stars'>
                  {new Array(5).fill().map((_, index) => (
                    <i
                      className={`fas fa-star ${
                        index + 1 <= Math.trunc(foodItem.rating) ? 'active' : ''
                      }`}
                      key={foodItem.rating + index}
                    />
                  ))}
                </div>
                <span className='reviews-count'>({2})</span>
              </div>
              <div
                className='leave-review-container'
                onClick={onOpenDrawerFoodReview}
              >
                <div className='leave-review-title-container'>
                  <span className='leave-review-title'>Review</span>
                </div>
                <div className='leave-review-container'>
                  <i className='fas fa-chevron-right' />
                </div>
              </div>
            </div>
          ) : (
            <span className='no-review-title'>No Review</span>
          )}
        </div>
      </div>
      <DrawerFoodReview
        visible={openDrawerFoodReview}
        onClose={onCloseDrawerFoodReview}
      />
    </CompletedNotificationContainer>
  );
}

CompletedNotification.propTypes = {
  foodItem: PropTypes.object,
  children: PropTypes.any,
};
