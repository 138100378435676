import styled from '@emotion/styled';

export const LoginContainer = styled.div`
  .logo-img {
    margin-top: 110px;
    margin-bottom: 80px;
  }

  .hello-icon {
    width: 40px;
    margin-bottom: 8px;
  }

  .welcome-title {
    font-size: 30px;
    font-weight: bold;
    color: white;
    margin-bottom: 60px;
  }

  .enter-label {
    font-size: 16px;
    font-weight: 600;
    color: white;
    margin-bottom: 15px;
  }

  .do-not-worry-under-label {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    text-align: left;
    margin-bottom: 40px;
  }

  .login-from-core {
    width: 350px;
    display: flex;
    flex-direction: column;
  }
`;
