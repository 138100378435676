import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerFoodItemContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  padding: 0;

  .drawer-food-item-top-part {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 37px 24px;
  }

  .scroolbars-container {
    div {
      .scroolbars-container-inner {
        .tab-switcher-container {
          color: #fff;
          width: 100%;
          display: flex;
          align-items: center;
          border-radius: 20px;
          background-color: #5c5f65;
          margin: 20px 0;
          .tab-switcher-item {
            flex: 1 1 50%;
            display: flex;
            justify-content: center;
            padding: 10px 0;
            cursor: pointer;
            &.active {
              border-radius: 20px;
              background-color: #8b8e92;
            }
          }
        }
      }
    }

    .photo {
      img {
        border-radius: 8px !important;
      }
    }
  }

  .drawer-menu--options {
    display: flex;
    flex-direction: column;
    align-items: center;
    .drawer-menu--option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 62px;
      border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    }
  }
`;
