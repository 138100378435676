import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ActiveNotificationsListContainer } from './style';
import { ActiveOrdersContext } from 'contexts/ActiveOrdersContextContainer';
import { useEditFoodItemFromOrder } from 'hooks';
import { Orders } from 'feasttt/domain';
import { SeeOrder, ActiveOrderItems, TotalSimplified } from '../../components';
import { DrawerEditFoodItemFromOrder } from '../../Pending/OrderToSee/DrawerEditFoodItemFromOrder';
import { DrawerRemoveFoodItemFromOrderContainer } from '../../Pending/OrderToSee/DrawerRemoveFoodItemFromOrderContainer';
import { DrawerDeleteOrderContainer } from '../../Pending/DrawerDeleteOrderContainer';
import { DrawerRemoveDiscountContainer } from './DrawerRemoveDiscountContainer';

export function ActiveNotificationsList({
  openDrawerDeleteOrderContainer,
  onCloseDrawerDeleteOrderContainer,
}) {
  const {
    isActiveOrderEmpty,
    activeOrder,
    updateFoodItem,
    removeFoodItem,
    deleteActiveOrder,
  } = useContext(ActiveOrdersContext);

  const [openDrawerEditFoodItemFromOrder, setOpenDrawerEditFoodItemFromOrder] =
    useState(false);
  const onOpenDrawerEditFoodItemFromOrder = () =>
    setOpenDrawerEditFoodItemFromOrder(true);
  const onCloseDrawerEditFoodItemFromOrder = () =>
    setOpenDrawerEditFoodItemFromOrder(false);

  const [
    openDrawerRemoveFoodItemFromOrderContainer,
    setDrawerRemoveFoodItemFromOrderContainer,
  ] = useState(false);
  const onOpenDrawerRemoveFoodItemFromOrderContainer = () =>
    setDrawerRemoveFoodItemFromOrderContainer(true);
  const onCloseDrawerRemoveFoodItemFromOrderContainer = () =>
    setDrawerRemoveFoodItemFromOrderContainer(false);

  const [
    openDrawerRemoveDiscountContainer,
    setOpenDrawerRemoveDiscountContainer,
  ] = useState(false);
  const onOpenDrawerRemoveDiscountContainer = () =>
    setOpenDrawerRemoveDiscountContainer(true);
  const onCloseDrawerRemoveDiscountContainer = () =>
    setOpenDrawerRemoveDiscountContainer(false);

  const {
    foodItem,
    setFoodItem,
    decreaseTotal,
    increaseTotal,
    changeItemDressing,
    changeItemAddons,
    changeItemSize,
  } = useEditFoodItemFromOrder();

  const onStartRemove = (food) => {
    setFoodItem(food);
    onOpenDrawerRemoveFoodItemFromOrderContainer();
  };

  const onStartEdit = (food) => {
    setFoodItem(food);
    onOpenDrawerEditFoodItemFromOrder();
  };

  const mockedRemoveDiscount = () => onOpenDrawerRemoveDiscountContainer();

  return (
    <ActiveNotificationsListContainer className='active-notify-list'>
      {!isActiveOrderEmpty() ? (
        <>
          <SeeOrder
            activeOrder={activeOrder}
            isActiveOrderEmpty={isActiveOrderEmpty()}
            isActivePage
          />
          <ActiveOrderItems
            items={activeOrder.items}
            onStartEdit={onStartEdit}
            onStartRemove={onStartRemove}
            normalControl={false}
          />
          <TotalSimplified
            totalValues={[
              [
                'Subtotal',
                'items' in activeOrder
                  ? Orders.calculation.calculatePriceForOrder(activeOrder)
                  : 0,
              ],
              ['Tax', 2.3],
              [
                'Total',
                ('items' in activeOrder
                  ? Orders.calculation.calculatePriceForActiveOrder(activeOrder)
                  : 0) + 2.3,
              ],
              ['Restaurant Fee', 2.0],
            ]}
            discount={activeOrder.discount}
            onRemoveDiscount={mockedRemoveDiscount}
          />
        </>
      ) : (
        <span className='select-order-title'>Select order for displaying</span>
      )}
      <DrawerEditFoodItemFromOrder
        foodItem={foodItem}
        increaseTotal={increaseTotal}
        decreaseTotal={decreaseTotal}
        changeItemAddons={changeItemAddons}
        changeItemDressing={changeItemDressing}
        changeItemSize={changeItemSize}
        updateFoodItem={updateFoodItem}
        visible={openDrawerEditFoodItemFromOrder}
        onClose={onCloseDrawerEditFoodItemFromOrder}
      />
      <DrawerRemoveFoodItemFromOrderContainer
        foodItem={foodItem}
        visible={openDrawerRemoveFoodItemFromOrderContainer}
        onClose={onCloseDrawerRemoveFoodItemFromOrderContainer}
        onRemoveFoodItem={removeFoodItem}
      />
      <DrawerDeleteOrderContainer
        visible={openDrawerDeleteOrderContainer}
        onClose={onCloseDrawerDeleteOrderContainer}
        deleteActiveOrder={deleteActiveOrder}
      />
      <DrawerRemoveDiscountContainer
        visible={openDrawerRemoveDiscountContainer}
        onClose={onCloseDrawerRemoveDiscountContainer}
      />
    </ActiveNotificationsListContainer>
  );
}

ActiveNotificationsList.propTypes = {
  openDrawerDeleteOrderContainer: PropTypes.bool,
  onCloseDrawerDeleteOrderContainer: PropTypes.func,
};
