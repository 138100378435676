import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerSortContainerStyled } from './style';
import { DrawerSort } from './DrawerSort';

export function DrawerSortContainer({
  visible = false,
  onClose = () => {},
  sortValue = '',
  onChangeSortValue = () => {},
}) {
  const [openDrawerSort, setOpenDrawerSort] = useState(false);
  const onOpenDrawerSort = () => setOpenDrawerSort(true);
  const onCloseDrawerSort = () => setOpenDrawerSort(false);

  const close = () => {
    onCloseDrawerSort();
    onClose();
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerSort();
    }
  }, [visible]);

  return (
    <DrawerSortContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerSort
        visible={openDrawerSort}
        onClose={close}
        sortValue={sortValue}
        onChangeSortValue={onChangeSortValue}
      />
    </DrawerSortContainerStyled>
  );
}

DrawerSortContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  sortValue: PropTypes.string,
  onChangeSortValue: PropTypes.func,
};
