import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerChangeToPendingContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  padding: 36px 26px 23px;
  height: 289px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
