import customAxios from 'services/customAxios';

const returnRequestPendingList = (id, {date}) => customAxios.get(`/restaurant/${id}/request?date=${date}`);

const requestToCompleted = (resId, reqId, data) => customAxios.patch(`/restaurant/${resId}/request/${reqId}`, data)
.then((res) => res) 
.catch((err) => err)

const getRequestToCompleted = (resId, reqId) => customAxios.get(`/restaurant/${resId}/request/${reqId}`)

export const requestsServices = {
    returnRequestPendingList,
    requestToCompleted,
    getRequestToCompleted
};