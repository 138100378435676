import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerAvailablitiesContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  .drawer-menu--options {
    display: flex;
    flex-direction: column;
    align-items: center;
    .drawer-menu--option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 62px;
      border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    }
  }
`;
