import styled from '@emotion/styled';
import { colors } from 'theme';

export const PriceCheckContainer = styled.div`
  margin-top: 78px;
  border-radius: 20px;
  padding: 20px 34px 22px;
  background-color: ${colors.gray2};

  .price-check-item {
    width: 100%;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.description {
      margin-bottom: 11px;
      .price-title-container {
        .price-title {
          i {
            font-size: 15px;
            letter-spacing: -0.36px;
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }

      .price-value-container {
        .price-value {
          font-size: 15px;
          letter-spacing: -0.36px;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }

    &.total {
      margin-bottom: 10px;
    }

    &.partial {
      margin-bottom: 16px;

      .price-value-container {
        .price-value {
          font-weight: bold;
          color: ${colors.lightGreen};
        }
      }
    }

    &.full {
      .price-value-container {
        .price-value {
          font-weight: bold;
          color: ${colors.lightGreen};
        }
      }
    }

    &.fee {
      padding-top: 16px;
      border-top: 1px solid rgba(255, 255, 255, 0.08);
    }

    .price-title-container {
      .price-title {
        font-size: 15px;
        letter-spacing: -0.36px;
        color: white;
      }
    }

    .price-value-container {
      .price-value {
        font-size: 15px;
        letter-spacing: -0.36px;
        color: white;
      }
    }
  }
`;
