import React from 'react';
import PropTypes from 'prop-types';
import { DrawerAreYouSureNoPaymentContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerAreYouSureNoPayment({
  visible,
  onClose,
  onSendPayment = () => {},
}) {
  return (
    <DrawerAreYouSureNoPaymentContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='credit-card-icon'>
        <i className='fas fa-credit-card' />
      </div>
      <SingleText
        className='no-payment-warning-title'
        size={22}
        spacing='0.42px'
        width='329px'
        align='center'
        marginBottom={10}
      >
        Are you sure ?
      </SingleText>
      <SingleText
        lineHeight='21px'
        spacing='0.27px'
        width='277px'
        align='center'
        marginBottom={40}
      >
        This order will be marked complete with no payment recorded.
      </SingleText>
      <SingleButton
        title='Process without payment'
        margin='0px 0px 20px 0px'
        onClick={onSendPayment}
      />
      <SingleButton
        title='Get Back'
        onClick={onClose}
        btnColor={colors.lightGray}
      />
    </DrawerAreYouSureNoPaymentContainer>
  );
}

DrawerAreYouSureNoPayment.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSendPayment: PropTypes.func,
};
