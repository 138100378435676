import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { restaurantServices } from 'services/api-services';
import { AuthContext } from 'contexts/AuthContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { showMessage } from 'services/functions';
import SingleAntSwitch from 'components/SingleAntSwitch';
import { DrawerAddDayNameContainerWrapper } from './style';

function DrawerAddDayNameContainer({
  visible = false,
  placement = 'bottom',
  onClose = () => {},
  onUpdateClosedDays = () => {},
  selectClosedDay,
  changeSelectClosedDay,
  clearSelectClosedDay,
  editClosedDay,
  clearEditClosedDay,
  openDrawerDeleteDayContainer,
  onOpenDrawerAddDayContainer,
}) {
  const { restaurantId } = useContext(AuthContext);
  const { restaurantProfile, setRestaurantProfile } = useContext(DataContext);

  const [isAnnual, setIsAnnual] = useState(false);
  const [selectedName, setSelectedName] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  // const selectedDate = new Date(selectClosedDay) || null;

  useEffect(() => {
    setSelectedDate(new Date(selectClosedDay));
    // eslint-disable-next-line
  }, [selectClosedDay]);

  useEffect(() => {
    if (editClosedDay) {
      setIsAnnual(editClosedDay.is_annual);
      setSelectedName(editClosedDay.name);
      changeSelectClosedDay(editClosedDay.closed_date);
    }
    if (!visible && editClosedDay) {
      clearEditClosedDay();
      clearSelectClosedDay();
    }
    // eslint-disable-next-line
  }, [visible]);

  async function addClosedDay() {
    if (!editClosedDay) {
      const dataToSend = {
        closed_date: `${selectedDate.getFullYear()}-${
          selectedDate.getMonth() + 1 < 10
            ? `0${selectedDate.getMonth() + 1}`
            : selectedDate.getMonth() + 1
        }-${
          selectedDate.getDate() < 10
            ? `0${selectedDate.getDate()}`
            : selectedDate.getDate()
        }`,
        name: selectedName,
        is_annual: isAnnual,
      };

      const onOk = (data) => {
        onUpdateClosedDays({ id: data.id, ...dataToSend });
        onClose();
      };

      const onFail = (errMessage) => {
        showMessage(errMessage, 'error');
        onClose();
      };

      return await restaurantServices.addClosedDates(
        restaurantId,
        { ...dataToSend },
        onOk,
        onFail
      );
    } else {
      setRestaurantProfile({
        ...restaurantProfile,
        closed_days: restaurantProfile.closed_days.map((closedDay) =>
          closedDay.id === editClosedDay.id
            ? {
                ...editClosedDay,
                name: selectedName,
                closed_date: `${selectedDate.getFullYear()}-${
                  selectedDate.getMonth() + 1
                }-${selectedDate.getDate()}`,
              }
            : closedDay
        ),
      });
      onClose();
    }
  }

  const deleteClosedDay = () => {
    openDrawerDeleteDayContainer();
  };

  return (
    <DrawerAddDayNameContainerWrapper
      onClose={onClose}
      visible={visible}
      placement={placement}
      height='auto'
    >
      <div className={`edit-day-block`}>
        <div className='head-block'>
          {selectedDate ? (
            <h3
              onClick={
                editClosedDay ? () => onOpenDrawerAddDayContainer() : () => {}
              }
            >{`${selectedDate.getDate()} ${selectedDate.toLocaleString(
              'default',
              { month: 'long' }
            )}`}</h3>
          ) : (
            ''
          )}

          {editClosedDay ? (
            <div className='delete-container' onClick={() => deleteClosedDay()}>
              <i className='fas fa-trash' />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className='body-block'>
          <div className='name-container'>
            <p>Name</p>
            <textarea
              className='name-event'
              type='text'
              placeholder='Closed'
              value={selectedName}
              onChange={(e) => setSelectedName(e.target.value)}
            />
          </div>
          <div className='repeat-annually'>
            <p>Repeat Annually</p>
            <SingleAntSwitch
              checked={isAnnual}
              onChange={() => setIsAnnual(!isAnnual)}
            />
            {/* <Switch checked={isAnnual} onChange={() => setIsAnnual(!isAnnual)} /> */}
          </div>
        </div>
        <button className='button-save' onClick={() => addClosedDay()}>
          Save
        </button>
        <button className='button-cancel' onClick={onClose}>
          Cancel
        </button>
      </div>
    </DrawerAddDayNameContainerWrapper>
  );
}

export default DrawerAddDayNameContainer;

DrawerAddDayNameContainer.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onUpdateClosedDays: PropTypes.func,
  selectClosedDay: PropTypes.string,
  changeSelectClosedDay: PropTypes.func,
  clearSelectClosedDay: PropTypes.func,
  editClosedDay: PropTypes.object,
  clearEditClosedDay: PropTypes.func,
  openDrawerDeleteDayContainer: PropTypes.func,
  onOpenDrawerAddDayContainer: PropTypes.func,
};
