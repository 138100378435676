import * as Cuisines from './cuisines';

export const cuisinesTitles = [
  { title: 'American', image: Cuisines.AmericanCuisine },
  { title: 'Arabian', image: Cuisines.ArabianCuisine },
  { title: 'Asian', image: Cuisines.AsianCuisine },
  { title: 'Asian Fusion', image: Cuisines.AsianFusionCuisine },
  { title: 'Barbeque', image: Cuisines.BarbequeCuisine },
  { title: 'Brazilian', image: Cuisines.BrazilianCuisine },

  { title: 'Breakfast', image: Cuisines.Breakfast },
  { title: 'British', image: Cuisines.BritishCuisine },
  { title: 'Buffet', image: Cuisines.Buffet },
  { title: 'Burgers', image: Cuisines.Burgers },
  { title: 'Café', image: Cuisines.Cafe },
  { title: 'Cajun', image: Cuisines.Cajun },
  { title: 'Caribbean', image: Cuisines.CaribbeanCuisine },
  { title: 'Chicken', image: Cuisines.ChickenCuisine },
  { title: 'Chinese', image: Cuisines.ChineseCuisine },
  { title: 'Cocktail Bar', image: Cuisines.CocktailBar },
  { title: 'Coffee & Tea', image: Cuisines.Coffee },
  { title: 'Desserts', image: Cuisines.Desserts },
  { title: 'Fast Food', image: Cuisines.FastFood },
  { title: 'French', image: Cuisines.French },
  { title: 'German', image: Cuisines.German },
  { title: 'Greek', image: Cuisines.Greek },
  { title: 'Healthy', image: Cuisines.Healthy },
  { title: 'Hot Dogs', image: Cuisines.HotDogs },
  { title: 'Ice Cream', image: Cuisines.IceCream },
  { title: 'Indian', image: Cuisines.Indian },
  { title: 'Irish', image: Cuisines.Irish },
  { title: 'Italian', image: Cuisines.Italian },
  { title: 'Japanese', image: Cuisines.Japanese },
  { title: 'Mediterranean', image: Cuisines.Mediterranean },
  { title: 'Mexican', image: Cuisines.Mexican },
  { title: 'Middle Eastern', image: Cuisines.MiddleEastern },
  { title: 'Pasta', image: Cuisines.Pasta },
  { title: 'Pizza', image: Cuisines.Pizza },
  { title: 'Polish', image: Cuisines.Polish },
  { title: 'Salad', image: Cuisines.Salad },
  { title: 'Sandwiches', image: Cuisines.Sandwiches },
  { title: 'Seafood', image: Cuisines.Seafood },
  { title: 'Soup', image: Cuisines.Soup },
  { title: 'Southern', image: Cuisines.Southern },
  { title: 'Spanish', image: Cuisines.Spanish },
  { title: 'Steak', image: Cuisines.Steak },
  { title: 'Sushi', image: Cuisines.Sushi },
  { title: 'Thai', image: Cuisines.Thai },
  { title: 'Vegan', image: Cuisines.Vegan },
  { title: 'Vegetarian', image: Cuisines.Vegetarian },
];
