import styled from '@emotion/styled';
import { colors } from 'theme';

export const OrdersTopControlContainer = styled.div`
  &.orders-control {
    display: flex;
    align-items: center;
    margin-left: 33px;

    .control-item {
      min-width: 167px;
      padding: 11px 45px;
      background-color: ${colors.gray2};
      letter-spacing: -0.34px;
      text-align: center;
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;

      &.active {
        border-radius: 20px;
        background-color: ${colors.redColor};
        font-weight: bold;
        color: white;
      }

      &:first-of-type {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }

      &:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
`;
