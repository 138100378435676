import React, { useContext, useState } from 'react';
import Typist from 'react-typist';
import ReactCodeInput from 'react-verification-code-input';
import { PhoneVerifyContainer } from './style';
import { setJwt } from 'services/jwt';
import { authServices } from 'services/api-services';
import { setAuthHeader } from 'services/customAxios';
import { PhoneNumberFormatter } from 'utils';
import { useBinaryState, useBackToLoginPageAndHistory } from 'hooks';
import { AuthContext } from 'contexts/AuthContextContainer';
import { AuthLayout } from 'layouts/mainPage/components/AuthLayout';
import SingleAntImage from 'components/SingleAntImage';

import { SidebarLogo } from 'assets/images/logo';

import axios from 'axios';
import { PhoneIcon } from 'assets/images/auth';
import { showMessage } from 'services/functions';

export const WHEN_CODE_IS_MATCHED_ANIMATION_DURATION_MS = 500;
export const DELAY_ANIMATION_MULTIPLIER = 1.9;

export default function PhoneVerify() {
  const {
    isActive: isMatch,
    activate: match,
    deactivate: nowIsNotMatch,
  } = useBinaryState();

  const {
    isActive: isFilled,
    activate: fillCode,
    deactivate: resetFilled,
  } = useBinaryState();

  const {
    setAuthUser,
    setRestaurantId,
    phoneNumberForCompleteProfile,
    saveAuthDataForCompleteProfile,
  } = useContext(AuthContext);

  const [resendingOTP, setResendingOTP] = useState(false);

  const { backToLoginPage, history } = useBackToLoginPageAndHistory();

  const resetIfFilled = () => {
    if (isFilled) {
      resetFilled();
    }
  };

  const sendEnteredCode = async (enteredCode) =>
    await authServices.otpVerify(
      {
        phone_number: phoneNumberForCompleteProfile,
        otp_code: enteredCode,
        is_restaurant_staff: true,
      },
      onOkResponseAfterSendingCode,
      onFailResponseAfterSendingCode
    );

  const onOkResponseAfterSendingCode = (responseForThisUser) => {
    match();
    fillCode();
    delayAssigningResponseAndGoToNextPageWeNeedShowAnimation(
      responseForThisUser,
      WHEN_CODE_IS_MATCHED_ANIMATION_DURATION_MS * DELAY_ANIMATION_MULTIPLIER
    );
  };

  const onFailResponseAfterSendingCode = () => {
    fillCode();
    nowIsNotMatch();
    // warnUserHeEnteredWrongCode();
  };

  const delayAssigningResponseAndGoToNextPageWeNeedShowAnimation = (
    responseForThisUser,
    timeToShowAnimation
  ) => {
    setTimeout(
      () => assignReceivedResponseAndGoToNextPage(responseForThisUser),
      timeToShowAnimation
    );
  };

  const assignReceivedResponseAndGoToNextPage = (responseForThisUser) => {
    receiveResponseForThisUser(responseForThisUser);
    goToNextPageUsingReceivedResponse(responseForThisUser);
  };

  const receiveResponseForThisUser = (responseForThisUser) => {
    if (!responseForThisUser.is_new) {
      beginUseReceivedResponseForLogin(responseForThisUser);
    } else {
      saveReceivedResponseForCompletingProfile(responseForThisUser);
    }
  };

  const beginUseReceivedResponseForLogin = (responseForThisUser) => {
    const { access_token, restaurant_id, user } = responseForThisUser;

    setJwt(access_token);
    setAuthHeader(access_token);
    setRestaurantId(restaurant_id);
    setAuthUser({ ...user, restaurant_id });
  };

  const saveReceivedResponseForCompletingProfile = (responseForThisUser) => {
    const { access_token, restaurant_id, user } = responseForThisUser;

    saveAuthDataForCompleteProfile({
      token: access_token,
      restaurant_id,
      user,
    });
  };

  const goToNextPageUsingReceivedResponse = (responseForThisUser) => {
    if (responseForThisUser.is_new) {
      goToCompleteProfileBecauseUserIsNew();
    } else {
      goToMenuBecauseUserAlreadyExists();
    }
  };

  const goToCompleteProfileBecauseUserIsNew = () => {
    history.replace('/complete-profile');
  };

  const goToMenuBecauseUserAlreadyExists = () => {
    history.replace('/menu');
  };

  // const warnUserHeEnteredWrongCode = () => showMessage('Wrong code', 'error');

  const formatPhoneNumber = () => {
    const phoneNumberFormatter = new PhoneNumberFormatter(
      phoneNumberForCompleteProfile
    );

    return phoneNumberFormatter.formatPhoneNumberWithoutCountryCode();
  };

  const isValid = () => {
    return isFilled ? (isMatch ? 'valid' : 'invalid') : '';
  };

  // axios resend otp
  function otpHandlerClick() {
    if (resendingOTP) { return; }
    setResendingOTP(true);
    axios
      .post('https://nodeapi.feasttt.com/api/v1/auth/login', {
        phone_number: phoneNumberForCompleteProfile,
      })
      .then((res) => {
        // eslint-disable-next-line no-console
        console.log(res);
        // eslint-disable-next-line no-console
        console.log(phoneNumberForCompleteProfile || '');
        // eslint-disable-next-line no-console
        console.log('Send OTP');
        setTimeout(() => {
          setResendingOTP(false);
          showMessage("Verification code sent successfully!", "success")
        }, 1000);
      })
      .catch((res) => {
        setResendingOTP(false);
        // eslint-disable-next-line no-console
        console.log('Error OTP');
        showMessage("Unable to resend the code, please try again", "error")
      });
  }

  return (
    <PhoneVerifyContainer
      animationDuration={WHEN_CODE_IS_MATCHED_ANIMATION_DURATION_MS}
    >
      <AuthLayout onBackIconClick={backToLoginPage}>
        <SingleAntImage
          src={SidebarLogo}
          alt='Logo'
          className='logo-img'
          width={171}
          height={50}
        />
        <img src={PhoneIcon} className='phone-icon' alt='Verify mobile number' />
        <span className='verify-mobile-number-title'>Verify mobile number</span>
        <span className='we-sent-notify-title'>
          We sent a one-time verification code to:{' '}
          {formatPhoneNumber(phoneNumberForCompleteProfile || '')}
        </span>
        <div className='code-wrapper'>
          <Typist cursor={{ show: false }}>
            <h5 className='type-the-code-label'>Type the code</h5>
          </Typist>
          <ReactCodeInput
            className={`verify-code-input ${isValid()}`}
            fields={4}
            onChange={resetIfFilled}
            onComplete={sendEnteredCode}
            fieldWidth={78}
            fieldHeight={78}
          />
          <div className='did-not-get-it-container'>
            <span className='did-not-get-it-title'>Didn’t get it?</span>
            <div
              className='btn-resend'
              onClick={otpHandlerClick}
              aria-hidden='true'
            >
              <span className={`resend-title ${resendingOTP ? 'inactive' : 'active'}`}>
                Resend
              </span>
            </div>
          </div>
        </div>
      </AuthLayout>
    </PhoneVerifyContainer>
  );
}
