import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerCustomizationsContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  overflow-x: hidden;
  .drag-container {
    margin: 0 -24px;
    .dragging {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }
  }
`;
