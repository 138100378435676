import { useState } from 'react';
import { isLetterHaveNumber } from 'services/functions';

export const useCardDetails = () => {
  const [card, setCard] = useState({});
  const changeCard = (updatedField) => setCard({ ...card, ...updatedField });
  const clearCard = () => setCard({});

  const formatCardNumber = (value = '') => {
    let formatted = '';

    const hiddenValue = value
      .split('')
      .map((digit, index) => (index <= 11 ? '•' : digit))
      .join('');

    for (let i = 0; i < hiddenValue.length; i += 4) {
      formatted += hiddenValue.slice(i, i + 4) + ' ';
    }

    return formatted.trim();
  };

  const convertCardNumber = (value) => {
    const digits = value
      .split('')
      .filter((letter) => isLetterHaveNumber(letter) || letter === '•')
      .slice(0, 16);

    const currentValue = card.number || '';

    if (currentValue.length <= digits.length) {
      if (currentValue.length <= 12) {
        return currentValue + digits.filter((item) => item !== '•').join('');
      } else {
        const numbersFromDots = digits.filter((item) => item !== '•');
        const lastFourNumbersInCurrent = currentValue.slice(12, 16).split('');
        const added = numbersFromDots.filter(
          (_, index) => !lastFourNumbersInCurrent[index]
        );

        return currentValue + added.join('');
      }
    } else {
      return digits.map((_, index) => currentValue[index]).join('');
    }
  };

  const convertExpires = (value) => {
    let digits = value
      .split('')
      .filter(
        (letter, index) =>
          isLetterHaveNumber(letter) || (letter === '/' && index === 2)
      )
      .slice(0, 5);

    if (!digits.includes('/') && digits.length > 2) {
      digits.splice(2, 0, '/');
    }

    return digits.join('');
  };

  const convertCvv = (value) =>
    value
      .split('')
      .filter((letter) => isLetterHaveNumber(letter))
      .slice(0, 3)
      .join('');

  const isCardholderNameValid = () =>
    card.cardholderName && card.cardholderName.length >= 3;

  const isNumberValid = () => card.number && card.number.length === 16;

  const isExpiresValid = () =>
    card.expires &&
    card.expires.length === 5 &&
    Number(card.expires.slice(0, 2)) <= 12;

  const isCvvValid = () => card.cvv && card.cvv.length === 3;

  const isReady = () =>
    isCardholderNameValid() &&
    isNumberValid() &&
    isExpiresValid() &&
    isCvvValid();

  const cardFields = [
    ['Cardholder Name', 'cardholderName', isCardholderNameValid],
    [
      'Card Number',
      'number',
      isNumberValid,
      formatCardNumber,
      convertCardNumber,
    ],
    [
      ['Expires', 'expires', isExpiresValid, undefined, convertExpires],
      ['CVV', 'cvv', isCvvValid, undefined, convertCvv],
    ],
  ];

  return {
    card,
    setCard,
    changeCard,
    clearCard,
    cardFields,

    convertExpires,

    isCardholderNameValid,
    isNumberValid,
    isExpiresValid,
    isCvvValid,

    isReady,
  };
};
