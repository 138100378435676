import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { DrawerDeleteFloorContainer } from './style';
import { showMessage } from 'services/functions';
import { restaurantServices } from 'services/api-services';
import { AuthContext } from 'contexts/AuthContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { StateContext } from 'contexts/StateContextContainer';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerPreventDeleteFloor } from './DrawerPreventDeleteFloor';

// @TODO Integrate commented code with sub drawer (DrawerPreventDeleteFloor)
export default function DrawerDeleteFloor({
  visible = false,
  placement = 'bottom',
  onClose = () => {},
  onDrawerFloorEditClose = () => {},
}) {
  const { restaurantId } = useContext(AuthContext);
  const { floorForEditId, setFloorForEditId } = useContext(DataContext);
  const { getTables } = useContext(StateContext);

  const [
    openDrawerPreventDeleteFloor,
    setOpenDrawerPreventDeleteFloor,
  ] = useState(false);

  const onOpenDrawerPreventDeleteFloor = () => {
    setOpenDrawerPreventDeleteFloor(true);
  };

  const onCloseDrawerPreventDeleteFloor = () =>
    setOpenDrawerPreventDeleteFloor(false);

  const onClickDeleteItem = async () => {
    const onOk = () => {
      onClose();
      onDrawerFloorEditClose();
      setFloorForEditId();
    };

    const onFail = (errMessage) => {
      showMessage(errMessage, 'error');
    };

    return await restaurantServices.deleteFloor(
      { restaurantId, floorId: floorForEditId },
      onOk,
      onFail
    );
  };

  const returnBack = () => {
    onClose();
  };

  return (
    <DrawerDeleteFloorContainer
      className={openDrawerPreventDeleteFloor ? 'delete-floor-hide' : ''}
      placement={placement}
      onClose={onClose}
      height='fit-content'
      visible={visible}
    >
      <Box
        is='div'
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        marginBottom={30}
      >
        <SingleText size={22} weight={500} marginBottom={10}>
          Remove Floor
        </SingleText>
        <SingleText lineHeight={1.5} spacing='0.27px' marginBottom={10}>
          Are you sure you want to remove this floor?
        </SingleText>
        <SingleText
          content='This cannot be undone.'
          size={14}
          lineHeight={1.5}
          spacing='0.27px'
          marginBottom={21}
        >
          This cannot be undone.
        </SingleText>
      </Box>
      <Box is='div' display='flex' flexDirection='column'>
        <SingleButton
          title='Remove'
          margin='0 0 20px'
          onClick={
            getTables.filter((table) => !table.is_available).length
              ? onOpenDrawerPreventDeleteFloor
              : onClickDeleteItem
          }
        />
        <SingleButton
          title='Keep'
          btnColor='#61646c'
          txtColor='white'
          margin='0 0 20px'
          onClick={onClose}
        />
      </Box>
      <DrawerPreventDeleteFloor
        visible={openDrawerPreventDeleteFloor}
        onClose={onCloseDrawerPreventDeleteFloor}
        returnBack={returnBack}
      />
    </DrawerDeleteFloorContainer>
  );
}

DrawerDeleteFloor.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onDrawerFloorEditClose: PropTypes.func,
};
