import styled from '@emotion/styled';
import { colors } from 'theme';

export const StaffCardContainer = styled.div`
  flex: 1;
  width: 31%;
  padding: 0px 0px 41px;
  margin-right: 36px;
  margin-bottom: 62px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
  background-color: ${colors.gray2};

  &:last-of-type {
    margin-right: 0px;
  }

  .best-staff-container {
    position: relative;
    background-color: ${colors.gray5};
    width: 100%;
    border-radius: 15px;
    padding: 35px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .staff-rating-image {
      width: 53px;
      position: absolute;
      top: -30px;
    }

    .staff-period {
      font-size: 25px;
      font-weight: bold;
      color: white;
      letter-spacing: -0.6px;
    }
  }

  .btn-view-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .btn-view-profile-staff {
    width: 69% !important;
  }
`;
