import styled from '@emotion/styled';

export const ActiveNotificationsListContainer = styled.div`
  max-width: 460px;
  min-width: 460px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -77px;
  right: 70px;

  .select-order-title {
    margin-top: 95%;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
  }
`;
