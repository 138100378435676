import React from 'react';
import PropTypes from 'prop-types';
import { FoodDescriptionContainer } from './style';
import { _mocked_Food_Checks } from 'services/static';
import SingleText from 'components/SingleText';

export function FoodDescription({
  name,
  description,
  features = _mocked_Food_Checks,
}) {
  return (
    <FoodDescriptionContainer>
      <SingleText
        size={24}
        lineHeight='30px'
        spacing='-0.58px'
        align='center'
        width='343px'
        weight='bold'
        marginBottom={14}
      >
        {name}
      </SingleText>
      <SingleText
        size={15}
        lineHeight='21px'
        spacing='-0.36px'
        align='center'
        width='343px'
        color='rgba(255, 255, 255, 0.5)'
        marginBottom={25}
      >
        {description}
      </SingleText>
      <div className='features-list'>
        {features.map(({ icon, name }, index) => (
          <div className='food-check-item-label' key={name + index}>
            <div className='food-check-icon'>{icon}</div>
            <div className='food-check-name-container'>
              <span className='food-check-name'>{name}</span>
            </div>
          </div>
        ))}
      </div>
    </FoodDescriptionContainer>
  );
}

FoodDescription.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  features: PropTypes.array,
};
