import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerProcessPaymentAreYouSureContainerStyled } from './style';
import { DrawerProcessPaymentAreYouSure } from './DrawerProcessPaymentAreYouSure';
import { DrawerAddTipContainer } from './DrawerAddTipContainer';

export function DrawerProcessPaymentAreYouSureContainer({
  paymentMethod = {},
  isNoPaymentNecessary = false,
  isCash = false,
  onOpenDrawerAreYouSureNoPaymentContainer = () => {},
  visible,
  onClose,
  onSendPayment,
}) {
  const [
    openDrawerProcessPaymentAreYouSure,
    setOpenDrawerProcessPaymentAreYouSure,
  ] = useState(false);
  const onOpenDrawerProcessPaymentAreYouSure = () =>
    setOpenDrawerProcessPaymentAreYouSure(true);
  const onCloseDrawerProcessPaymentAreYouSure = () =>
    setOpenDrawerProcessPaymentAreYouSure(false);

  const [openDrawerAddTipContainer, setOpenDrawerAddTipContainer] =
    useState(false);
  const onOpenDrawerAddTipContainer = () => setOpenDrawerAddTipContainer(true);
  const onCloseDrawerAddTipContainer = () =>
    setOpenDrawerAddTipContainer(false);

  const [tip, setTip] = useState(undefined);

  const close = () => {
    onCloseDrawerAddTipContainer();
    onCloseDrawerProcessPaymentAreYouSure();
    onClose();
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerProcessPaymentAreYouSure();
    }
  }, [visible]);

  return (
    <DrawerProcessPaymentAreYouSureContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerProcessPaymentAreYouSure
        tip={tip}
        paymentMethod={paymentMethod}
        visible={openDrawerProcessPaymentAreYouSure}
        onClose={close}
        onAddTip={onOpenDrawerAddTipContainer}
        onSendPayment={onSendPayment}
        onOpenDrawerAreYouSureNoPaymentContainer={
          onOpenDrawerAreYouSureNoPaymentContainer
        }
        isNoPaymentNecessary={isNoPaymentNecessary}
        isCash={isCash}
        isTipAvailable={tip !== undefined}
      />
      <DrawerAddTipContainer
        visible={openDrawerAddTipContainer}
        onClose={onCloseDrawerAddTipContainer}
        onAddTip={setTip}
        tip={tip}
        isTipAvailable={tip !== undefined}
      />
    </DrawerProcessPaymentAreYouSureContainerStyled>
  );
}

DrawerProcessPaymentAreYouSureContainer.propTypes = {
  paymentMethod: PropTypes.object,
  isNoPaymentNecessary: PropTypes.bool,
  isCash: PropTypes.bool,
  onOpenDrawerAreYouSureNoPaymentContainer: PropTypes.func,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSendPayment: PropTypes.func,
};
