import styled from '@emotion/styled';
import { colors } from 'theme';

export const StaffHeaderContainer = styled.div`
  border-bottom: 1px solid ${colors.border};
  height: 88px;
  min-height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;

  .filter-container {
    display: flex;
    align-items: center;
    div {
      margin-left: 16px;
    }
    div:first-of-type {
      margin-left: 0;
    }

    .search-container {
      border-color: transparent;
      background-color: ${colors.gray5};
    }

    .suggestions-list {
      margin-left: 0 !important;
      background-color: ${colors.gray5};
      border-color: transparent;
    }

    .staff-header-block {
      width: fit-content;
      height: 40px;
      padding: 0 24px;
      border-radius: 25px;
      border: 1px solid ${colors.border};
      display: flex;
      justify-content: center;
      align-items: center;

      &.soft-block {
        border-color: transparent;
        background-color: #27282a;
      }
    }
    .dropdown-header-menu {
      .option-label-item {
        font-size: 14px;
        color: white;
      }

      i {
        font-size: 20px;
        color: white;
      }
    }
    .staff-header-select {
      .dropdown-header-menu {
        background-color: ${colors.gray5};
        border: 1px solid transparent;
      }
    }
  }
`;
