import React from 'react';
import PropTypes from 'prop-types';
import { OrderTableContainer } from './style';
import { needToPay } from 'services/functions';

export function OrderTable({
  completedOrder,
  onClick = () => {},
  isActive = false,
}) {
  return (
    <OrderTableContainer
      className={isActive ? '' : 'inactive'}
      onClick={() => onClick(completedOrder)}
    >
      <div className='check-icon'>
        <i className='fas fa-check' />
      </div>
      <div className='order-table-description'>
        <div className='order-table-title-container'>
          <div className='order-table-title-inner-container'>
            {completedOrder.overallRating ? <i className='fas fa-star' /> : ''}
            <span className='order-table-title'>
              {completedOrder.overallRating
                ? completedOrder.overallRating
                : 'No Reviews'}
            </span>
          </div>
          <div className='time-title-container'>
            <span className='time-title'>
              {formatCompletedTime(completedOrder.completed)}
            </span>
          </div>
        </div>
        <div className='name-code-container'>
          <span className='name-title'>{`Table ${completedOrder.table.tableNumber}`}</span>
          <span className='separator'>•</span>
          <span className='code-title'>{completedOrder.table.orderNumber}</span>
        </div>
        <div className='core-facts'>
          {[
            [
              'price-title',
              `$${needToPay({ items: [], ...completedOrder }).toFixed(2)}`,
            ],
            ['guests-title', `${completedOrder.table.guests} Guest`],
            ['items-title', `${completedOrder.items.length} Item`],
          ].map(([cssClassName, value]) => (
            <React.Fragment key={cssClassName + value}>
              <div className='fact-title' key={cssClassName + value}>
                <span className={cssClassName}>{value}</span>
              </div>
              <span className='separator'>•</span>
            </React.Fragment>
          ))}
        </div>
      </div>
    </OrderTableContainer>
  );
}

OrderTable.propTypes = {
  completedOrder: PropTypes.object,
  price: PropTypes.number,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

export const formatCompletedTime = (completedTimeNumeric) => {
  const date = new Date(completedTimeNumeric);
  const completedTimeLocal = date.toLocaleTimeString('en-US');
  const timeLetters = completedTimeLocal.split('');

  timeLetters.splice(timeLetters.lastIndexOf(':'), 3);
  return timeLetters.join('');
};
