import React from 'react';
import PropTypes from 'prop-types';
import { TotalRatingContainer } from './style';
export function TotalRating({
  reviewsCount,
  sortValue,
  onOpenDrawerSortContainer = () => {},
  totalRating
}) {

  let totalFoodRating = totalRating

  return (
    <TotalRatingContainer>
      <div className='rating-container'>
        <div className='rating-main'>
          <i className='fas fa-star' />
          <span className='rating-title'>{totalFoodRating.rating}</span>
        </div>
        <div className='total-reviews-container'>
          <span className='total-reviews'>{reviewsCount}</span>
          <span className='total-reviews-label'>reviews</span>
        </div>
      </div>
      <div
        className='rating-control-container'
        onClick={onOpenDrawerSortContainer}
      >
        <span className='rating-control-title'>
          {sortValue ? sortValue : 'Sort'}
        </span>
        <i className='fas fa-chevron-down' />
      </div>
    </TotalRatingContainer>
  );
}

TotalRating.propTypes = {
  reviewsCount: PropTypes.number,
  sortValue: PropTypes.string,
  onOpenDrawerSortContainer: PropTypes.func,
  totalRating: PropTypes.any
};
