import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatBusinessHoursToday } from 'services/functions';
import ItemPicker from 'components/DrawerForm/ItemPicker';
import DrawerBusinessHours from './DrawerBusinessHours';

export function BusinessHoursField({
  isInDrawer = false,
  workingHours = [],
  closedDays = [],
  onChange = () => {},
  onUpdateClosedDays = () => {},
  onDeleteClosedDay = () => {},
}) {
  const [openDrawerBusinessHours, setDrawerBusinessHours] = useState(false);
  return (
    <>
      <ItemPicker
        isInDrawer={isInDrawer}
        label='Business Hours'
        placeholder='Set Hours'
        value={formatBusinessHoursToday(workingHours)}
        onClick={() => setDrawerBusinessHours(true)}
      />
      <DrawerBusinessHours
        visible={openDrawerBusinessHours}
        onClose={() => setDrawerBusinessHours(false)}
        workingHours={workingHours}
        closedDays={closedDays}
        onChange={onChange}
        onUpdateClosedDays={onUpdateClosedDays}
        onDeleteClosedDay={onDeleteClosedDay}
      />
    </>
  );
}

BusinessHoursField.propTypes = {
  isInDrawer: PropTypes.bool,
  workingHours: PropTypes.array,
  closedDays: PropTypes.array,
  onChange: PropTypes.func,
  onUpdateClosedDays: PropTypes.func,
  onDeleteClosedDay: PropTypes.func,
};
