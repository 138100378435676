import React, { useState, useEffect } from 'react';
import { Pagination } from 'react-bootstrap'
import PropTypes from 'prop-types';
import './style.scss';

const SinglePaginationComponent = (props) => {
    const { defaultPageSize, total, onChange } = props;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [disabledButton, setDisabledButton] = useState('');

    function changePaginatorKey(newPosition) {
        const maxAvailable = Math.ceil(total / defaultPageSize);
        if (newPosition < 0 && selectedIndex > 0) {
            setSelectedIndex(selectedIndex + newPosition)
        } else if (newPosition > 0 && selectedIndex + newPosition < maxAvailable) {
            setSelectedIndex(selectedIndex + newPosition)
        }
    }

    useEffect(() => {
        const maxAvailable = Math.ceil(total / defaultPageSize);
        if (selectedIndex + 1 === maxAvailable) {
            setDisabledButton('next')
        } else if (selectedIndex === 0) {
            setDisabledButton('prev')
        } else {
            setDisabledButton('');
        }

        // returns chosen index on change;
        onChange && onChange(selectedIndex);
        // eslint-disable-next-line
    }, [selectedIndex])

    return (
        <div className="single-pagination-component">
            <Pagination>
                <Pagination.Prev className={`prev-button ${disabledButton === 'prev' ? 'disabled' : ''}`} onClick={() => changePaginatorKey(-1)}>
                    <i className="fas fa-chevron-left" />
                </Pagination.Prev>
                {
                    new Array(Math.ceil(total / defaultPageSize)).fill(null).map((_item, i) => {
                        return (
                            <Pagination.Item key={`paginatorItemKey${i}`} active={selectedIndex === i}>
                                <i className="fas fa-circle" />
                            </Pagination.Item>
                        );
                    })
                }
                <Pagination.Next className={`next-button ${disabledButton === 'next' ? 'disabled' : ''}`} onClick={() => changePaginatorKey(1)}>
                    <i className="fas fa-chevron-right" />
                </Pagination.Next>
            </Pagination>
        </div>
    );
}

export default SinglePaginationComponent;

SinglePaginationComponent.propTypes = {
    defaultPageSize: PropTypes.number,
    total: PropTypes.number,
    onChange: PropTypes.func,
}