import styled from '@emotion/styled';
import { colors } from 'theme';

export const NotificationControlContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 11px;

  .control-item-container {
    cursor: pointer;
    padding: 22px 0px;
    i {
      font-size: 14px;
      color: white;
    }

    &:first-of-type {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: ${colors.gray5};
    }

    &:nth-of-type(2) {
      background-color: ${colors.redColor};
    }

    &:nth-of-type(3) {
      background-color: rgba(255, 255, 255, 0.08);
    }

    &:first-of-type,
    &:nth-of-type(2),
    &:nth-of-type(3) {
      padding: 22px 30px;
    }

    &:last-of-type {
      padding-left: 115px;
      padding-right: 115px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      background-color: ${colors.lightGreen};
    }
  }
`;
