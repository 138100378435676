import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FoodNutritionContainer } from './style';
import { DrawerDetailNutritionForNewItemInOrder } from './DrawerDetailNutritionForNewItemInOrder';

export function FoodNutrition({ calories, protein, carbs }) {
  const [openDrawerDetailNutrition, setOpenDrawerDetailNutrition] =
    useState(false);
  const onOpenDrawerDetailNutrition = () => {
    setOpenDrawerDetailNutrition(true);
  };
  const onCloseDrawerDetailNutrition = () =>
    setOpenDrawerDetailNutrition(false);

  return (
    <FoodNutritionContainer>
      <div className='nutrition-items'>
        {[
          { label: 'calories', value: calories },
          { label: 'protein', value: protein, suffix: 'g' },
          { label: 'carbs', value: carbs, suffix: 'g' },
        ].map(({ value, suffix, label }, index) => (
          <div className='nutrition-item-container' key={value + index}>
            <span className='nutrition-value'>
              {value}
              {suffix || ''}
            </span>
            <span className='nutrition-type'>{label}</span>
          </div>
        ))}
      </div>
      <button
        className='btn-nutrition-info'
        onClick={onOpenDrawerDetailNutrition}
      >
        <span className='btn-nutrition-info-text'>
          <i className='far fa-info-circle' />
          Nutrition
        </span>
      </button>
      <DrawerDetailNutritionForNewItemInOrder
        visible={openDrawerDetailNutrition}
        onClose={onCloseDrawerDetailNutrition}
      />
    </FoodNutritionContainer>
  );
}

FoodNutrition.propTypes = {
  calories: PropTypes.number,
  protein: PropTypes.number,
  carbs: PropTypes.number,
};
