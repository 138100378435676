import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { MonthCalendarContainer } from './style';
import { months } from 'services/static';

export const week = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export function MonthCalendar({
  visible = false,
  closedDays = [],
  controlDate = '',
  onChangeControlDate = () => {},
}) {
  const [currentYear, setCurrentYear] = useState(undefined);
  const [currentMonth, setCurrentMonth] = useState(undefined);

  const onNext = () => {
    if (currentMonth === 11) {
      setCurrentYear(currentYear + 1);
    }

    const newCurrentMonth = (currentMonth + 1) % 12;
    setCurrentMonth(newCurrentMonth);
  };

  const onPrevious = () => {
    if (currentMonth === 0) {
      setCurrentYear(currentYear - 1);
      setCurrentMonth(11);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const daysInMonth = (iMonth, iYear) =>
    32 - new Date(iYear, iMonth, 32).getDate();

  const handleDayClick = (currentYear, currentMonth, day) => {
    onChangeControlDate(new Date(currentYear, currentMonth, day));
  };

  useEffect(() => {
    if (visible) {
      const today = new Date();
      const todayMonth = today.getMonth();
      const todayYear = today.getFullYear();

      setCurrentYear(todayYear);
      setCurrentMonth(todayMonth);
    }
  }, [visible]);

  const allClosedDays = useMemo(
    () =>
      closedDays
        .map(({ closed_date }) => new Date(closed_date))
        .map((closedDate) => [closedDate.getMonth(), closedDate.getDate()]),
    [closedDays]
  );

  const firstDay = new Date(currentYear, currentMonth).getDay();

  return (
    <MonthCalendarContainer>
      <Card className='calendar-container'>
        <Card.Header className='calendar-month'>
          <div className='change-month-icon previous' onClick={onPrevious}>
            <i className='fas fa-chevron-left' />
          </div>
          {months[currentMonth]}
          <div className='change-month-icon next' onClick={onNext}>
            <i className='fas fa-chevron-right' />
          </div>
        </Card.Header>
        <Table id='calendar' responsive='sm'>
          <thead className='calendar-head'>
            <tr>
              {week.map((weekDay, index) => (
                <th key={index + weekDay}>{weekDay.slice(0, 1)}</th>
              ))}
            </tr>
          </thead>
          <tbody className='calendar-body'>
            {(() => {
              let date = 1;
              const today = new Date();

              const doesItIncludesInClosedDays = (inMonth, withDay) =>
                allClosedDays.some(
                  ([closedDateMonth, closedDateDay]) =>
                    closedDateMonth === inMonth && closedDateDay === withDay
                );

              return new Array(6).fill(undefined).map((_, i) => (
                <tr key={i}>
                  {[1, 2, 3, 4, 5, 6, 0].map((j) => {
                    if (date <= daysInMonth(currentMonth, currentYear)) {
                      let renderDay = '';

                      if (i === 0 && j !== firstDay && date === 1) {
                        renderDay = (
                          <td key={i + j}>
                            <div className='empty-day'></div>
                          </td>
                        );
                      } else {
                        const userDay = new Date(controlDate);
                        const isToday =
                          date === today.getDate() &&
                          currentYear === today.getFullYear() &&
                          currentMonth === today.getMonth();

                        const isClosedDay = doesItIncludesInClosedDays(
                          currentMonth,
                          date
                        );

                        renderDay = (
                          <td
                            onClick={(event) =>
                              handleDayClick(
                                currentYear,
                                currentMonth,
                                Number(event.currentTarget.innerText)
                              )
                            }
                            key={i + j}
                          >
                            <div
                              className={`${isToday ? 'today' : ''} ${
                                isClosedDay ? 'closed-day' : ''
                              } ${
                                controlDate
                                  ? date === userDay.getDate() &&
                                    currentMonth === userDay.getMonth()
                                    ? 'user-day'
                                    : ''
                                  : ''
                              } regular-day`.trim()}
                            >
                              {date}
                            </div>
                          </td>
                        );
                        date++;
                      }

                      return renderDay;
                    }

                    return (
                      <td key={i + j}>
                        <div />
                      </td>
                    );
                  })}
                </tr>
              ));
            })()}
          </tbody>
        </Table>
      </Card>
    </MonthCalendarContainer>
  );
}

MonthCalendar.propTypes = {
  visible: PropTypes.bool,
  closedDays: PropTypes.array,
  controlDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  onChangeControlDate: PropTypes.func,
};
