import customAxios from 'services/customAxios';

const login = async (phoneNumber, onOk = () => {}, onFail = () => {}) => {
  const { ok, errMessage } = await customAxios.post('/auth/login', { phone_number: phoneNumber });
  if (ok) {
    onOk();
  } else {
    onFail(errMessage);
  }
};

const logout = async (onOk, onFail) => {
  const { ok, errMessage } = await customAxios.post('/auth/logout');
  if (ok) {
    onOk();
  } else {
    onFail(errMessage);
  }
};

const otpVerify = async ({ phone_number, otp_code, is_restaurant_staff }, onOk = () => {}, onFail = () => {}) => {
  const { ok, data: responseForThisUser, errMessage } = await customAxios.post('/auth/otp-verify', { phone_number, otp_code, is_restaurant_staff });

  if (ok) {
    onOk(responseForThisUser);
  } else {
    onFail(errMessage);
  }
};

const emailVerify = ({ token }) => customAxios.get(`/auth/email-verify?token=${token}`);

// @TODO move this function into `userServices`
const deleteMyAccount = async (onOk = () => {}, onFail = () => {}) => {
  const { ok, errMessage } = await customAxios.delete(`/user/me`);
  if (ok) {
    onOk();
  } else {
    onFail(errMessage);    
  }
};

export const authServices = {
  login,
  logout,
  otpVerify,
  emailVerify,
  deleteMyAccount,
};
