export class StaffRoles {
  static roles = {
    manager: 'manager',
    kitchen_staff: 'kitchen_staff',
    waiter: 'waiter',
    // none: 'none',
  };

  static allRoles = [...Object.values(StaffRoles.roles)];

  static titles = {
    manager: 'Manager/Owner',
    kitchen_staff: 'Kitchen Staff',
    waiter: 'Server/Host',
    // none: 'None',
  };

  static readRole(role) {
    const keyToReadTitle = StaffRoles.allRoles.includes(role)
      ? role
      : StaffRoles.roles.none;

    return StaffRoles.titles[keyToReadTitle];
  }
}
