import styled from '@emotion/styled';

export const DrinkCompletedRejectItemAlertContainer = styled.div`
  .drink-rejectitem-alert-wrapper {
    width: 100%;
    margin: 35px 0 0;
    padding: 72px 26px 33px;
    border-radius: 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #27282a;
    position: absolute;
    bottom: 0px;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
  }

  .drink-rejectitem-alert-svg-position {
    position: relative;
    bottom: 118px;
    text-align: center;
  }

  .drink-rejectitem-alert-check-wrapper {
    text-align: center;
  }

  .drink-rejectitem-alert-glass-cheers {
    background-image: linear-gradient(316deg, #ed2626 96%, #ed2626 8%);
    font-size: 26px;
    border-radius: 85px;
    position: relative;
    bottom: 200px;
    height: 63px;
    width: 64px;
    padding: 18px 0 0 0;
  }

  .drink-rejectitem-alert-body-wrapper {
    margin: -142px 0 12px 0;
  }

  .drink-rejectitem-alert-text {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }

  .drink-rejectitem-alert-btn-center {
    padding-top: 32px;
  }

  .drink-rejectitem-alert-btn {
    width: 100%;
    border: none;
    background: #ed2626;
    color: #ffffff;
    height: 47px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
  }
`;
