import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerDeleteAccountContainerStyled } from './style';
import { DrawerDeleteAccount } from './DrawerDeleteAccount';
import { DrawerDeleteEntireAccount } from './DrawerDeleteEntireAccount';

export function DrawerDeleteAccountContainer({ visible, onClose }) {
  const [openDrawerDeleteAccount, setDrawerDeleteAccount] = useState(false);
  const onOpenDrawerDeleteAccount = () => setDrawerDeleteAccount(true);
  const onCloseDrawerDeleteAccount = () => setDrawerDeleteAccount(false);

  const [openDrawerDeleteEntireAccount, setOpenDrawerDeleteEntireAccount] =
    useState(false);
  const onOpenDrawerDeleteEntireAccount = () =>
    setOpenDrawerDeleteEntireAccount(true);
  const onCloseDrawerDeleteEntireAccount = () =>
    setOpenDrawerDeleteEntireAccount(false);

  const onDelete = () => {
    onCloseDrawerDeleteAccount();
    onOpenDrawerDeleteEntireAccount();
  };

  const close = () => {
    onCloseDrawerDeleteEntireAccount();
    onCloseDrawerDeleteAccount();
    onClose();
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerDeleteAccount();
    }
  }, [visible]);

  return (
    <DrawerDeleteAccountContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerDeleteAccount
        visible={openDrawerDeleteAccount}
        onClose={close}
        onDelete={onDelete}
      />
      <DrawerDeleteEntireAccount
        visible={openDrawerDeleteEntireAccount}
        onClose={close}
      />
    </DrawerDeleteAccountContainerStyled>
  );
}

DrawerDeleteAccountContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
