import { useState } from 'react';

export const useEditFoodItemFromOrder = () => {
  const [foodItem, setFoodItem] = useState(undefined);

  const decreaseTotal = () =>
    setFoodItem({
      ...foodItem,
      total: foodItem.total === 1 ? foodItem.total : foodItem.total - 1,
    });

  const increaseTotal = () =>
    setFoodItem({ ...foodItem, total: foodItem.total + 1 });

  const changeItemDressing = (newDressing) =>
    setFoodItem({
      ...foodItem,
      dressing: foodItem.dressing.some(
        ([dressingName]) => dressingName === newDressing[0]
      )
        ? foodItem.dressing.filter(
            ([dressingItem]) => dressingItem !== newDressing[0]
          )
        : [...foodItem.dressing, newDressing],
    });

  const changeItemAddons = (newAddon) =>
    setFoodItem({
      ...foodItem,
      addons: foodItem.addons.some(([addonName]) => addonName === newAddon[0])
        ? foodItem.addons.filter(([addonItem]) => addonItem !== newAddon[0])
        : [...foodItem.addons, newAddon],
    });

  const changeItemSize = (newSize) =>
    setFoodItem({
      ...foodItem,
      size: foodItem.size.some(([sizeName]) => sizeName === newSize[0])
        ? foodItem.size.filter(([sizeName]) => sizeName !== newSize[0])
        : [...foodItem.size, newSize],
    });

  return {
    foodItem,
    setFoodItem,
    decreaseTotal,
    increaseTotal,
    changeItemDressing,
    changeItemAddons,
    changeItemSize,
  };
};
