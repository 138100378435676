import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { DrawerSelectTableWrapper } from './style';
import { Floors } from 'feasttt/domain';
import { useLayoutListSwitchView } from 'hooks';
import { DataContext } from 'contexts/DataContextContainer';
import { SelectTableHeader } from './SelectTableHeader';
import { FloorDropdown } from './FloorDropdown';
import { MovableLayout } from './MovableLayout';
import { TablesListFromSelectedFloor } from './TablesListFromSelectedFloor';
import { WaiterDescription } from './WaiterDescription';

function DrawerSelectTable({ visible = false, onClose = () => {} }) {
  const { floorDetails } = useContext(DataContext);
  const [selectedFloor, setSelectedFloor] = useState({ tables: [] });

  const {
    changeToLayoutView,
    changeToListView,
    isLayoutViewActive,
    isListViewActive,
  } = useLayoutListSwitchView();

  useEffect(() => {
    if (floorDetails.length) {
      onSelectFloor(floorDetails[0]);
    }
  }, [floorDetails, setSelectedFloor]);

  const onSelectFloor = (floor) => setSelectedFloor(floor);

  const getTotalAvailableTables = () => {
    const availableFloor = Floors.createAvailableFloor(selectedFloor);
    return availableFloor.getTotalAvailableTables();
  };

  return (
    <DrawerSelectTableWrapper
      onClose={onClose}
      visible={visible}
      placement='right'
    >
      <SelectTableHeader
        onClose={onClose}
        getTotalAvailableTables={getTotalAvailableTables}
        changeToLayoutView={changeToLayoutView}
        changeToListView={changeToListView}
        isLayoutViewActive={isLayoutViewActive}
        isListViewActive={isListViewActive}
      />
       <div
        className={`view ${
          isLayoutViewActive() ? 'selected-table-layout-view' : ''
        }`}
      >
        <FloorDropdown
          selectedFloor={selectedFloor}
          onSelectFloor={onSelectFloor}
          floors={floorDetails}
        />
        {isLayoutViewActive() ? (
          <div className='table-display-content'>
            <div className='table-display-body'>
              <MovableLayout selectedFloor={selectedFloor} />
            </div>
          </div>
        ) : (
          <TablesListFromSelectedFloor selectedFloor={selectedFloor} />
        )}
        <WaiterDescription />
      </div>
    </DrawerSelectTableWrapper>
  );
}

export default DrawerSelectTable;

DrawerSelectTable.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
