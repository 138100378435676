import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerSelectTableWrapper = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  font-family: Montserrat;
  color: #ffffff;
  padding-bottom: 0;

  .view {
    padding-top: 30px;
    margin-left: -24px;
    margin-right: -24px;

    &.selected-table-layout-view {
      background-color: #151617;

      .table-display-content {
        position: relative;
        /* margin-bottom: 150px; */

        &:before,
        &:after {
          content: '';
          display: block;
          background-color: ${colors.gray2};
          width: 330px;
          height: 11px;
          margin-left: 30px;
          position: absolute;
        }

        &:before {
          top: 7px;
        }

        &:after {
          bottom: 38px;
        }

        .table-display-body {
          position: relative;
          padding: 0px 10px;
          height: 460px;

          &::-webkit-scrollbar {
            height: 6px;
            width: 6px;
          }
          &::-webkit-scrollbar-track {
            background: gainsboro;
            border-radius: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.8);
            border-radius: 5px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        }
      }
    }
  }
`;
