import styled from '@emotion/styled'

export const DashboardSearchContainer = styled.div`
  position: relative;
  .title-container{
    margin-bottom: 5px;
    padding-left: 2px;
    span{
      font-size: 12px;
      color: rgba(255,255,255,0.6);
    }
  }
  .search-container{
    ${'' /* width: 100%; */}
    cursor: ${props => props.active ? 'initial' : 'pointer'};
    width: ${props => props.active ? '400px' : '50px'};
    height: 40px;
    transition: width 0.5s;
    ${'' /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */}
    border-radius: 25px;
    border: solid 1px #525558;
    display: flex;
    padding: 5px 10px;
      .search-container-input{
      display: ${props => props.active ? 'initial' : 'none'};
      ${'' /* opacity: ${props => props.active ? '1' : '0'};
      transition: opacity 2s; */}
      flex: 1 1 auto;
      background-color: transparent;
      border: unset;
      outline: unset;
      font-size: 14px;
      color: #ffffff;
    }
    .clear-search{
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 5px;
      color: #fff;
      font-size: 14px;
      opacity: ${props => props.active ? '0.2' : '1'};
      margin-left: 5px;
      .fa-times{
        font-weight: 500;
      }
    }
  }
  .suggestions-list{
    position: absolute;
    width: 100%;
    margin-top: 5px;
    border-radius: 25px;
    box-shadow: 1px 2px 15px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #525558;
    background-color: #1b1c1d;
    color: #fff;
    padding: 10px 15px;
    z-index: 99999;
    margin-left: 0px;

    &.not-found {
      display: none;
    }

    .suggestion-item{
      display: flex;
      align-items: center;
      /* justify-content: space-between; */
      padding: 10px 0;
      cursor: pointer;
      margin-left: 0px;
      padding-left: 16px;
      border-radius: 12px;
      .suggestion-main{
        font-size: 14px;
        font-weight: 600;
      }
      .suggestion-secondary{
        font-size: 14px;
        font-weight: 500;
        color: rgba(255,255,255,0.6);
      }
    }
    .suggestion-item:hover{
        background-color: rgba(255,255,255,0.08);
    }
    .flex-wrapper{
      display: flex;
      justify-content: space-between;
      padding: 10px 0 13px 0;
    }
    .flex-wrapper:hover{
      display: flex;
      background-color: rgba(255,255,255,0.08);
      border-radius: 12px;
      align-items: center;
      padding: 14px 14px;
      cursor: pointer;
      margin-left: 0px;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;