import React from 'react';
import PropTypes from 'prop-types';
import { ActiveOrderContainer } from './style';
import { totalItems, needToPay } from 'services/functions';

export function ActiveOrder({ order, onClick, isActive = false }) {
  return (
    <ActiveOrderContainer
      className={isActive ? 'active' : ''}
      onClick={onClick}
    >
      <div className='loading-icon'>
        <i className='fas fa-spinner' />
      </div>
      <div className='active-order-container'>
        <div className='active-order-title-container'>
          <span className='active-order-title'>Active</span>
        </div>
        <div className='name-and-code-container'>
          <span className='name-and-code-title'>
            Table {order.table.tableNumber} • {order.table.orderNumber}
          </span>
        </div>
        <div className='active-order-info-container'>
          <span className='active-order-info-title'>
            ${needToPay({ items: order.items }).toFixed(2)} •{' '}
            {order.table.guests} Guest • {totalItems(order.items)} Item
          </span>
        </div>
      </div>
    </ActiveOrderContainer>
  );
}

ActiveOrder.propTypes = {
  isActive: PropTypes.bool,
  order: PropTypes.object,
  onClick: PropTypes.func,
};
