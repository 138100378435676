import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types';
import Box from 'components/Box';
import { Form } from 'react-bootstrap';
import { FaChevronRight, FaTimes, FaTrash } from 'react-icons/fa';
import { showMessage } from 'services/functions';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerStaffContainer } from './style';
// import SingleAntInput from 'components/SingleAntInput';
import { colors } from 'theme';
import { SingleAvatarUploader } from 'components/SingleAvatarUploader';
import { restaurantServices } from 'services/api-services';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';

export default function DrawerStaff({
  type = 'Add',
  visible = false,
  placement = 'right',
  onClose = () => { },
}) {

  // const [form] = Form.useForm();
  const {
    setOpenDrawerRolesToEdit,
    setOpenDrawerStatus,
    setOpenDrawerStaffAdd,
    setOpenDrawerStaffDelete,
  } = useContext(StateContext);
  const { restaurantId } = useContext(AuthContext);
  const { chosenStaff, setChosenStaff } = useContext(DataContext);
  // const [staff, setStaff] = useState({});
  const [uploading, setUploading] = useState(false);

  const onClickRole = () => {
    setOpenDrawerRolesToEdit(true);
  }
  const onClickStatus = () => setOpenDrawerStatus(true)
  const onClickSaveStaff = () => setOpenDrawerStaffAdd(false)

  const onClickDeleteStaff = () => {
    setOpenDrawerStaffDelete(true);
  }

  const onUpload = () => {
    setUploading(true);
    setChosenStaff({ ...chosenStaff, avatar: null });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClickSaveStaff();
  }

  async function updateStaff() {
    const { ok, errMessage } = await restaurantServices.updateStaff({ restaurantId, staffId: chosenStaff.id }, chosenStaff);
    if (ok) {
      onClose();
    } else {
      showMessage(errMessage, 'error');
    }
  }

  return (
    <DrawerStaffContainer
      placement={placement}
      onClose={onClose}
      visible={visible}
    >
      <Box display='flex' alignItems='center' marginBottom={40}>
        <SingleIconWrapper onClick={onClose}>
          <FaTimes className='cursor' size={20} color={colors.white3} />
        </SingleIconWrapper>
        <Box display='flex' justifyContent='center' flex={1}>
          <SingleText content={`${type} Staff`} align='center' size={22} weight={500}>{`${type} Staff`}</SingleText>
        </Box>
        {type === 'Edit' && <FaTrash className='cursor' size={20} color='white' onClick={onClickDeleteStaff} />}
      </Box>

      <Box display='flex' justifyContent='center' marginBottom={39}>
        <SingleAvatarUploader
          width='149px'
          height='149px'
          src={chosenStaff.avatar ? chosenStaff.avatar : ''}
          uploading={uploading}
          onUpload={onUpload}
        />
      </Box>


      <Form noValidate className="form-container" onSubmit={onSubmit}>
        <Form.Group controlId="formGridName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            value={chosenStaff.first_name}
            onChange={e => setChosenStaff({ ...chosenStaff, first_name: e.target.value })}
          />
        </Form.Group>

        <Form.Group controlId="formGridLastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Write Last Name"
            value={chosenStaff.last_name}
            onChange={e => setChosenStaff({ ...chosenStaff, last_name: e.target.value })}
          />
        </Form.Group>

        <Form.Group controlId="formGridPhone">
          <Form.Label>Mobile Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Mobile Number"
            value={chosenStaff.phone_number}
            onChange={e => setChosenStaff({ ...chosenStaff, phone_number: e.target.value })}
          />
        </Form.Group>

        <Form.Group controlId="formGridEmail">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter Email Address"
            value={chosenStaff.email}
            onChange={e => setChosenStaff({ ...chosenStaff, email: e.target.value })}
          />
        </Form.Group>

        <Box className='drawer--options'>
          <Box cursor='pointer' className='drawer--option' onClick={onClickRole}>
            <Box display='flex' flexDirection='column'>
              <SingleText size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>Role</SingleText>
              <SingleText color={chosenStaff.role_slug !== "manager" && chosenStaff.role_slug !== "waiter" && chosenStaff.role_slug !== 'staff' ? colors.redColor : 'white'} size={14} weight={500} lineHeight={1.57} spacing='-0.34px'>{chosenStaff.role_slug !== "manager" && chosenStaff.role_slug !== "waiter" && chosenStaff.role_slug !== "staff" ? 'Choose role' : chosenStaff.role_slug === "manager" ? 'Manager' : chosenStaff.role_slug === 'waiter' ? 'Server/Host' : 'Kitchen Staff'}</SingleText>
            </Box>
            <FaChevronRight size={15} color={colors.white3} />
          </Box>
          <Box cursor='pointer' className='drawer--option' onClick={onClickStatus}>
            <Box display='flex' flexDirection='column'>
              <SingleText size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>Status</SingleText>
              <SingleText color={chosenStaff.status !== 0 && chosenStaff.status !== 1 ? colors.redColor : 'white'} size={14} weight={500} lineHeight={1.57} spacing='-0.34px'>{chosenStaff.status !== 0 && chosenStaff.status !== 1 ? 'Set status' : chosenStaff.status === 1 ? 'Active' : 'Inactive'}</SingleText>
            </Box>
            <FaChevronRight size={15} color={colors.white3} />
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' marginTop='50px'>
          <SingleButton
            htmlType='submit'
            title={type === 'Add' ? 'Add Staff' : 'Save Staff'}
            onClick={type === 'Edit' ? updateStaff : () => { }}
            btnColor={colors.redColor}
            txtColor='white'
          />
        </Box>

      </Form>





      {/* <Form
        form={form}
        layout="vertical"
        className="form-wrapper"
        onFinish={onSubmit}
      >
        <Box display='flex' flexDirection='column' marginBottom={5}>
          <SingleText content='First Name' size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>First Name</SingleText>
          <Form.Item
            className='form-col'
            label={false}
            name='firstName'
          >
            <SingleAntInput
              placeholder='Write First Name'
              height='40px'
              type='underline'
              customValue={chosenStaff.first_name}
              onChange={e => setChosenStaff({ ...chosenStaff, first_name: e.target.value })}
            />
          </Form.Item>
        </Box>
        <Box display='flex' flexDirection='column' marginBottom={5}>
          <SingleText content='Last Name' size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>Last Name</SingleText>
          <Form.Item
            className='form-col'
            label={false}
            name='lastName'
          >
            <SingleAntInput
              placeholder='Write Last Name'
              height='40px'
              type='underline'
              customValue={chosenStaff.last_name}
              onChange={e => setChosenStaff({ ...chosenStaff, last_name: e.target.value })}
            />
          </Form.Item>
        </Box>
        <Box display='flex' flexDirection='column' marginBottom={5}>
          <SingleText content='Mobile Number' size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>Mobile Number</SingleText>
          <Form.Item
            className='form-col'
            label={false}
            name='phone'
          >
            <SingleAntInput
              placeholder='Enter Mobile Number'
              height='40px'
              type='underline'
              customValue={chosenStaff.phone_number}
              onChange={e => setChosenStaff({ ...chosenStaff, phone_number: e.target.value })}
            />
          </Form.Item>
        </Box>
        <Box display='flex' flexDirection='column'>
          <SingleText content='Email Address' size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>Email Address</SingleText>
          <Form.Item
            className='form-col'
            label={false}
            name='email'
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ]}
          >
            <SingleAntInput
              placeholder='Enter Email Address'
              height='40px'
              type='underline'
              customValue={chosenStaff.email}
              onChange={e => setChosenStaff({ ...chosenStaff, email: e.target.value })}
            />
          </Form.Item>
        </Box>

        <Box className='drawer--options'>
          <Box cursor='pointer' className='drawer--option' onClick={onClickRole}>
            <Box display='flex' flexDirection='column'>
              <SingleText content='Role' size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>Role</SingleText>
              <SingleText content='Choose role' color={colors.redColor} size={14} weight={500} lineHeight={1.57} spacing='-0.34px'>Choose role</SingleText>
            </Box>
            <FaChevronRight size={15} color={colors.white3} />
          </Box>
          <Box cursor='pointer' className='drawer--option' onClick={onClickStatus}>
            <Box display='flex' flexDirection='column'>
              <SingleText content='Status' size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>Status</SingleText>
              <SingleText content='Set status' color={colors.redColor} size={14} weight={500} lineHeight={1.57} spacing='-0.34px'>Set status</SingleText>
            </Box>
            <FaChevronRight size={15} color={colors.white3} />
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' marginTop='auto'>
          <Form.Item noStyle>
            <SingleButton
              htmlType='submit'
              title={type === 'Add' ? 'Add Staff' : 'Save Staff'}
              onClick={type === 'Edit' ? updateStaff : () => {}}
              btnColor={colors.redColor}
              txtColor='white'
            />
          </Form.Item>
        </Box>
      </Form> */}
    </DrawerStaffContainer>
  )
}

DrawerStaff.propTypes = {
  type: PropTypes.string,
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickRole: PropTypes.func,
  onClickStatus: PropTypes.func,
  onClickDeleteStaff: PropTypes.func,
  onClickSaveStaff: PropTypes.func,
};
