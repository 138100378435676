import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { DefaultUser } from 'assets/images/profile';
import { StateContext } from 'contexts/StateContextContainer';
// import { DataContext } from 'contexts/DataContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';
import { DrawerPingServerWrapper } from './style';

const DrawerPingServer = ({
    visible = false,
    placement = 'bottom',
    // onClose = () => { },
}) => {
    const [messageForPing, setMessageForPing] = useState('');
    const { setPingServerDrawerOpen } = useContext(StateContext);
    const { authUser } = useContext(AuthContext);

    function deleteFullOrderItem() {
        setPingServerDrawerOpen(true);
        // setOrderItemForDelete('')
        // setOpenDrawerOrderItemDeleteCheck(true);
    }

    function keepFullOrderItems() {
        setPingServerDrawerOpen(false);
    }

    return (
        <DrawerPingServerWrapper
            onClose={keepFullOrderItems}
            visible={visible}
            placement={placement}
            height="auto"
        >
            <div className="img-container">
                <img src={authUser.avatar ? authUser.avatar.file : DefaultUser} alt="avatar" />
            </div>
            <div className={`ping-server-block`}>
                <div className="head-block">
                    <h3>{authUser.last_name} {authUser.first_name ? authUser.first_name.charAt(0) : ""}</h3>
                </div>
                <p className="message-title">
                    Message
                </p>
                <div className="write-your-message">
                    <input type="text" placeholder="Write your message" value={messageForPing} onChange={(e) => setMessageForPing(e.target.value)} />
                </div>
                <button className="button-save" onClick={() => deleteFullOrderItem()}>Send Message</button>
                <button className="button-cancel" onClick={keepFullOrderItems}>Cancel</button>
            </div>

        </DrawerPingServerWrapper>
    )
}

export default DrawerPingServer;

DrawerPingServer.propTypes = {
    visible: PropTypes.bool,
    placement: PropTypes.string,
    onClose: PropTypes.func,
};