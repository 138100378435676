import React from 'react';
import PropTypes from 'prop-types';
import { DrawerServerReviewContainer } from './style';

const _mockedServerReviews = [
  {
    name: 'Isabelle F',
    rating: 4,
    reviewContent:
      'If you ask for Pasta Alfredo in a restaurant in Italy all you get from your waiter is a stare. Why is one of the most famous “Italian sauces” for pasta unknown in its country of origin? ',
  },
  {
    name: 'John D',
    rating: 5,
    reviewContent: 'It was tasty!',
  },
];

export function DrawerServerReview({
  server,
  visible,
  onClose,
  reviews = _mockedServerReviews,
}) {
  return (
    <DrawerServerReviewContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      <div className='close-icon' onClick={onClose}>
        <i className='fas fa-chevron-left' />
      </div>
      <div className='server-detail-container'>
        <div className='server-image' />
        <span className='server-full-name'>{server.name}</span>
        <span className='position-title'>{server.position}</span>
      </div>
      <div className='server-container' />
      <div className='reviews-container'>
        {reviews.map(({ name, rating, reviewContent }) => (
          <div className='review-item' key={name + rating + reviewContent}>
            <div className='review-image' />
            <span className='name'>{name}</span>
            <div className='rating-stars'>
              {new Array(5).fill(undefined).map((_, index) => (
                <div
                  className={`rating-star ${
                    index + 1 <= Math.trunc(rating) ? 'marked' : ''
                  }`}
                  key={index}
                >
                  <i className='fas fa-star' />
                </div>
              ))}
            </div>
            <p className='review-content'>{reviewContent}</p>
          </div>
        ))}
      </div>
    </DrawerServerReviewContainer>
  );
}

DrawerServerReview.propTypes = {
  server: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  reviews: PropTypes.array,
};
