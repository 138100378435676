import React from 'react'
import PropTypes from 'prop-types';
import useDimensions from 'react-use-dimensions'
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import { colors } from 'theme';
import { DefaultUser } from 'assets/images/profile';
import SingleImage from 'components/SingleImage';
import { ReviewItemContainer, SpaceReviews } from './style';

export default function ReviewItem({
  review = {},
}) {

  const [ref, { height }] = useDimensions();

  return (
    <ReviewItemContainer>
      <Box width={45} height={45}>
        <SingleImage src={DefaultUser} size={45} />
      </Box>
      <Box display='flex' flexDirection='column' marginLeft={10}>
        <Box display='flex' justifyContent='space-between'>
          <SingleText content={review.username} size={14} weight='bold' spacing='-0.34px'>{review.username}</SingleText>
          <SingleText content={review.status === 0 ? 'Yum!' : 'Meh'} family='SFProText' color={review.status === 0 ? colors.redColor : 'white'} size={15} spacing='-0.36px' weight='bold'>{review.status === 0 ? 'Yum!' : 'Meh'}</SingleText>
        </Box>
        <SpaceReviews>
          <SingleText content='Dined' size={13} opacity={0.6} spacing='-0.31px'>Dined <b>{review.dinedNum}</b> times</SingleText>
        </SpaceReviews>
        {/* <Space size={3} style={{ marginBottom: 5 }}>
          <SingleText content='Dined' size={13} opacity={0.6} spacing='-0.31px'>Dined <b>{review.dinedNum}</b> times</SingleText>
        </Space> */}
        <div ref={ref} className='review-content'>
          <SingleText content={review.content || ''} size={14} opacity={0.8} spacing='-0.34px'>{review.content || ''}</SingleText>
          {height > 90 &&
            <div className='gradient-overlay' />
          }
        </div>
      </Box>
    </ReviewItemContainer>
  )
}

ReviewItem.propTypes = {
  review: PropTypes.object,
};
