import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { restaurantServices } from 'services/api-services';
import { AuthContext } from 'contexts/AuthContextContainer';
import { showMessage } from 'services/functions';
import { DrawerDeleteDayContainerWrapper } from './style';

function DrawerDeleteDayContainer({
  visible = false,
  placement = 'bottom',
  onClose = () => {},
  editClosedDay,
  onDeleteClosedDay = () => {},
  closeDrawerAddDayContainer = () => {},
  closeDrawerAddDayNameContainer = () => {},
}) {
  const { restaurantId } = useContext(AuthContext);

  async function deleteDayConfirmed() {
    const onOk = () => {
      onDeleteClosedDay((currentClosedDays) => [
        ...currentClosedDays.filter((x) => x.id !== editClosedDay.id),
      ]);
      closeDrawerAddDayContainer();
      closeDrawerAddDayNameContainer();
      onClose();
    };

    const onFail = (errMessage) => {
      showMessage(errMessage, 'error');
      onClose();
    };

    return await restaurantServices.deleteClosedDates(
      restaurantId,
      editClosedDay.id,
      onOk,
      onFail
    );
  }

  return (
    <DrawerDeleteDayContainerWrapper
      onClose={onClose}
      visible={visible}
      placement={placement}
      height='auto'
    >
      <div className={`delete-day-block`}>
        <div className='head-block'>
          <h3>Delete {editClosedDay?.name}</h3>
        </div>
        <p>
          Are you sure you want to delete Closed Day on{' '}
          <b>
            {`${new Date(editClosedDay?.closed_date).toLocaleString('default', {
              month: 'long',
            })} ${new Date(editClosedDay?.closed_date).getDate()}`}
          </b>{' '}
          ?
        </p>
        <button className='button-save' onClick={() => deleteDayConfirmed()}>
          Delete
        </button>
        <button className='button-cancel' onClick={onClose}>
          Keep
        </button>
      </div>
    </DrawerDeleteDayContainerWrapper>
  );
}

export default DrawerDeleteDayContainer;

DrawerDeleteDayContainer.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  editClosedDay: PropTypes.object,
  onDeleteClosedDay: PropTypes.func,
  closeDrawerAddDayContainer: PropTypes.func,
  closeDrawerAddDayNameContainer: PropTypes.func,
};
