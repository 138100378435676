import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft } from 'react-icons/fa';
import { DrawerFloorsContainer } from './style';
import { sortFloorsOrder, moveFloors } from 'services/functions';
import { DataContext } from 'contexts/DataContextContainer';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { SortableList } from './SortableList';
import { SortableItem } from './SortableItem';
import { DrawerFloorAdd } from './DrawerFloorAdd';
import { DrawerFloorEdit } from './DrawerFloorEdit';

export function DrawerFloors({
  visible = false,
  placement = 'right',
  onClose = () => {},
  getFloorDetails = () => {},
}) {
  const [openDrawerFloorAdd, setOpenDrawerFloorAdd] = useState(false);
  const [openDrawerFloorEdit, setOpenDrawerFloorEdit] = useState(false);

  const { floorDetails, setFloorDetails, setFloorForEditId } =
    useContext(DataContext);
  const [floors, setFloors] = useState([]);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setFloors(moveFloors(floors, oldIndex, newIndex));
  };

  useEffect(() => {
    setFloors(sortFloorsOrder(floorDetails));
  }, [floorDetails]);

  function editFloorClicked(id) {
    setFloorForEditId(id);
    setOpenDrawerFloorEdit(true);
  }

  const onDone = () => {
    setFloorDetails(floors.reverse());
    onClose();
  };

  const close = () => {
    setFloors(floorDetails);
    onClose();
  };

  return (
    <DrawerFloorsContainer
      placement={placement}
      onClose={close}
      visible={visible}
    >
      <Box is='div' display='flex' alignItems='center' padding={24}>
        <SingleIconWrapper className='floors-exit' onClick={onClose}>
          <FaChevronLeft
            className='cursor'
            size={20}
            color='rgba(255, 255, 255, 0.3)'
          />
        </SingleIconWrapper>
        <Box className='drawer-floors-top'>
          <SingleText align='center' size={22} weight={500}>
            Floors
          </SingleText>
        </Box>
      </Box>
      <SortableList
        onSortEnd={onSortEnd}
        helperClass='row-dragging'
        helperContainer={() =>
          document.getElementById('drag-customization-options')
        }
        useDragHandle
      >
        {floors.map((_floor, index) => (
          <SortableItem
            key={`item-${_floor.id}`}
            index={index}
            value={_floor.name}
            onClick={() => editFloorClicked(_floor.id)}
          />
        ))}
      </SortableList>
      <Box
        is='div'
        display='flex'
        flexDirection='column'
        marginTop='auto'
        padding={24}
      >
        <SingleButton
          title='Add New Floor'
          margin='0 0 20px'
          btnColor='#61646c'
          txtColor='white'
          onClick={() => setOpenDrawerFloorAdd(true)}
        />
        <SingleButton title='Done' onClick={onDone} />
      </Box>
      <DrawerFloorAdd
        visible={openDrawerFloorAdd}
        onClose={() => setOpenDrawerFloorAdd(false)}
        fetchUpdatedFloors={getFloorDetails}
      />
      <DrawerFloorEdit
        visible={openDrawerFloorEdit}
        onClose={() => setOpenDrawerFloorEdit(false)}
        fetchUpdatedFloors={getFloorDetails}
      />
    </DrawerFloorsContainer>
  );
}

DrawerFloors.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  getFloorDetails: PropTypes.func,
};
