import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { showMessage } from 'services/functions';
import { DrawerAddOptionContainer } from './style';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import SingleAntSwitch from 'components/SingleAntSwitch';
import { FaTrash } from 'react-icons/fa';
import { colors } from 'theme';
import SingleAntDrawerInput from 'components/SingleAntDrawerInput';

export default function DrawerAddOption({
  placement = 'bottom',
  // onClose = () => { },
}) {

  const {
    openDrawerAddOption, setOpenDrawerAddOption,
  } = useContext(StateContext);
  const {
    options, setOptions,
    selectedOptionIndex, setSelectedOptionIndex
  } = useContext(DataContext);
  const [optionName, setOptionName] = useState('');
  const [additionalPrice, setAdditionalPrice] = useState();
  const [isFreeOption, setFreeOption] = useState(true);

  useEffect(() => {
    if (selectedOptionIndex !== undefined) {
      const selectedOption = options[selectedOptionIndex];
      setOptionName(selectedOption.name);
      if (selectedOption.price === 0) {
        setFreeOption(true);
        setAdditionalPrice();
      } else {
        setFreeOption(false);
        setAdditionalPrice(selectedOption.price);
      }
    }
    // eslint-disable-next-line
  }, [selectedOptionIndex])

  const onClickAdd = () => {
    if (optionName && (isFreeOption || additionalPrice)) {
      const newOptions = selectedOptionIndex !== undefined
        ? [...options.map((option, i) => {
          if (i === selectedOptionIndex) {
            return { name: optionName, price: isFreeOption ? 0 : additionalPrice }
          }
          return option
        })]
        : [...options, { name: optionName, price: isFreeOption ? 0 : additionalPrice }];

      setOptions(newOptions);
      setOptionName('');
      setAdditionalPrice();
      setFreeOption(true);
      setOpenDrawerAddOption(false);
      setSelectedOptionIndex();
    } else {
      showMessage('Please, fill all fields correctly.', 'error');
    }
  }

  function onClickDelete() {
    const newOptions = [...options.filter((option, i) => i !== selectedOptionIndex)];
    setOptions(newOptions);
    setOptionName('');
    setAdditionalPrice();
    setFreeOption(true);
    setSelectedOptionIndex();
    setOpenDrawerAddOption(false);
  }

  function onCancel() {
    setOptionName('');
    setAdditionalPrice();
    setFreeOption(true);
    setSelectedOptionIndex();
    setOpenDrawerAddOption(false);
  }

  return (
    <DrawerAddOptionContainer
      placement={placement}
      onClose={() => onCancel()}
      height='fit-content'
      visible={openDrawerAddOption}
    >
      <Box display='flex' flexDirection='row' alignItems='baseline' justifyContent='space-between' marginBottom={30} padding='0 26px'>
        <SingleText size={22} weight={500} marginBottom={10}>{selectedOptionIndex !== undefined ? 'Edit Option' : 'Add Option'}</SingleText>
        {selectedOptionIndex !== undefined
          ? <FaTrash className='cursor' size={20} color='white' onClick={() => onClickDelete()} />
          : ''}
      </Box>
      <Box display='flex' flexDirection='column' alignItems='flex-start' marginBottom={30} padding='0 26px'>
        <SingleAntDrawerInput label='Name' placeholder='Ex: Caesar Salad' value={optionName} onChange={(e) => setOptionName(e.target.value)} />
      </Box>
      <Box height={60} display='flex' justifyContent='space-between' alignItems='center' background={colors.gray2} marginBottom={16} padding='0 27px 0 39px'>
        <SingleText weight={500} size={14} lineHeight={1.57} spacing='-0.34px'>Free Option</SingleText>
        <SingleAntSwitch checked={isFreeOption} onChange={() => setFreeOption(!isFreeOption)} />
      </Box>
      {!isFreeOption &&
        <Box className='additional-price' display='flex' flexDirection='column' alignItems='flex-start' marginBottom={30} padding='0 26px'>
          <SingleAntDrawerInput label='Additional Price' prefix='+$' placeholder='0.00' value={additionalPrice} onChange={(e) => setAdditionalPrice(e.target.value)} />
        </Box>
      }
      <Box display='flex' flexDirection='column' padding='0 26px'>
        <SingleButton
          title='Add Option'
          margin='0 0 20px'
          onClick={onClickAdd}
        />
        <SingleButton
          title='Cancel'
          btnColor='transparent'
          txtColor={colors.redColor}
          margin='0 0 20px'
          onClick={onCancel}
        />
      </Box>
    </DrawerAddOptionContainer>
  )
}

DrawerAddOption.propTypes = {
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickDay: PropTypes.func,
  onClickDeleteItem: PropTypes.func,
};
