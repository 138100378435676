import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft } from 'react-icons/fa';
import { DrawerFloorAddContainer } from './style';
import { colors } from 'theme';
import { showMessage } from 'services/functions';
import { restaurantServices } from 'services/api-services';
import { AuthContext } from 'contexts/AuthContextContainer';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import SingleIconWrapper from 'components/SingleIconWrapper';
import SingleAntDrawerInput from 'components/SingleAntDrawerInput';

export function DrawerFloorAdd({
  visible = false,
  placement = 'right',
  onClose = () => {},
  fetchUpdatedFloors = () => {},
}) {
  const { restaurantId } = useContext(AuthContext);

  const [floorToEditName, setFloorToEditName] = useState();

  async function onCreateNewFloor() {
    const onOk = async () => {
      closeAddMenu();
      await fetchUpdatedFloors();
    };

    const onFail = (errMessage) => {
      showMessage(errMessage, 'error');
    };

    return await restaurantServices.createFloor(
      restaurantId,
      { name: floorToEditName },
      onOk,
      onFail
    );
  }

  function closeAddMenu() {
    setFloorToEditName();
    onClose();
  }

  return (
    <DrawerFloorAddContainer
      placement={placement}
      onClose={closeAddMenu}
      visible={visible}
    >
      <Box is='div' display='flex' alignItems='center' padding={24}>
        <SingleIconWrapper onClick={closeAddMenu}>
          <FaChevronLeft
            className='cursor'
            size={20}
            color='rgba(255, 255, 255, 0.3)'
          />
        </SingleIconWrapper>
        <Box is='div' display='flex' justifyContent='center' flex={1}>
          <SingleText align='center' size={22} weight={500}>
            Add Floor
          </SingleText>
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        marginBottom={30}
        padding='0 26px'
      >
        <SingleAntDrawerInput
          label='Floor Name'
          placeholder='Ex: First Floor'
          value={floorToEditName}
          onChange={(e) => setFloorToEditName(e.target.value)}
        />
      </Box>
      <Box
        is='div'
        display='flex'
        flexDirection='column'
        marginTop='auto'
        padding={24}
      >
        <SingleButton
          title='Add New Floor'
          margin='0 0 20px'
          btnColor={colors.redColor}
          txtColor='white'
          onClick={onCreateNewFloor}
        />
        <SingleButton
          title='Cancel'
          btnColor='transparent'
          txtColor={colors.redColor}
          onClick={() => closeAddMenu()}
        />
      </Box>
    </DrawerFloorAddContainer>
  );
}

DrawerFloorAdd.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  fetchUpdatedFloors: PropTypes.func,
};
