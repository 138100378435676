import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SingleAntFormatInput from 'components/SingleAntFormatInput';

export function PhoneField({ phoneNumber, onChange }) {
  const [phone, setPhone] = useState(undefined);

  useEffect(() => {
    if (phone === undefined) {
      setPhone(phoneNumber);
    }
  }, [phone, phoneNumber, setPhone]);

  const onChangePhone = (values) => {
    setPhone(values.value);
  };

  return (
    <SingleAntFormatInput
      type='underline'
      className='horizontal-inner-element'
      height='36px'
      label='Phone Number'
      placeholder='Write Phone Number'
      value={phone}
      onChange={onChangePhone}
      onBlur={() => onChange({ phone })}
    />
  );
}

PhoneField.propTypes = {
  phoneNumber: PropTypes.string,
  onChange: PropTypes.func,
};
