import styled from '@emotion/styled';
import { colors } from 'theme';

export const ProgressBarContainer = styled.div`
  width: 100%;
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.full {
    .progress-bar-container {
      &:before {
        background-color: ${colors.lightGreen};
      }
    }
  }

  .progress-bar-container {
    width: 100%;
    margin-bottom: 18px;
    height: 11px;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.08);

    &:before {
      content: '';
      display: block;
      background-color: ${colors.redColor};
      border-radius: 100px;
      width: ${({ percentage }) => percentage}%;
      height: 100%;
    }
  }

  .percentage-completed {
    font-size: 18px;
    letter-spacing: 0;
    color: rgba(255, 255, 255, 0.5);
  }
`;
