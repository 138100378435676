import React from 'react';
import PropTypes from 'prop-types';
import { PersonalCheckingContainer } from './style';
import SingleText from 'components/SingleText';
import { NumberItem } from './NumberItem';
import { EmptyBankAccountPlaceholder } from './EmptyBankAccountPlaceholder';

export function PersonalChecking({ currentBankAccount, isEmptyState }) {
  return (
    <PersonalCheckingContainer
      className={isEmptyState ? 'empty-personal-checking' : ''}
    >
      {!isEmptyState ? (
        <>
          <SingleText
            className='nickname-title'
            size={17}
            lineHeight='23px'
            spacing='-0.41px'
            marginBottom={10}
            weight='bold'
            width='158px'
            align='center'
          >
            {currentBankAccount.nickname || ''}
          </SingleText>
          {[
            [
              'Routing Number',
              '••• •••',
              currentBankAccount.routingNumber
                ? currentBankAccount.routingNumber.slice(6, 9)
                : '',
            ],
            [
              'Account Number',
              '•••• •••',
              currentBankAccount.accountNumber
                ? currentBankAccount.accountNumber.slice(7, 10)
                : '',
            ],
          ].map(([title, dots, value]) => (
            <NumberItem
              label={title}
              dots={dots}
              value={value}
              key={title + dots + value}
            />
          ))}
        </>
      ) : (
        <EmptyBankAccountPlaceholder />
      )}
      <div className='bottom-border' />
    </PersonalCheckingContainer>
  );
}

PersonalChecking.propTypes = {
  isEmptyState: PropTypes.bool,
  currentBankAccount: PropTypes.object,
};
