import { TableItem } from './TableItem';

export class TableStatus extends TableItem {
  isAvailable() {
    return !this.isInactive() && this.table.is_available;
  }

  isOccupied() {
    return !this.isInactive() && !this.table.is_available;
  }

  isInactive() {
    return this.table.status === 0 || this.table.status === '0';
  }
}
