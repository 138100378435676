import React from 'react';
import PropTypes from 'prop-types';
import { PaymentWayContainer } from './style';

export function PaymentWay({ title, icon, className = '', onClick, isActive }) {
  return (
    <PaymentWayContainer
      className={`${className} ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      <div className='title-container'>
        <span className='title'>{title}</span>
      </div>
      <div className='icon-container'>{icon}</div>
    </PaymentWayContainer>
  );
}

PaymentWay.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};
