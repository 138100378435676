import { useState } from 'react';
import { SubPages } from './SubPages';

export const useSubPageSwitch = () => {
  const [selectedPage, setSelectedPage] = useState(SubPages.initialPage());

  const [lastOpenedPageWas, setLastOpenedPageWas] = useState('');
  const changeLastOpenedPageWas = (newLastPage) =>
    setLastOpenedPageWas(newLastPage);

  const changeSelectedPage = (page) => setSelectedPage(page);

  const openPendingPage = () => changeSelectedPage(SubPages.getPendingPage());
  const openAddItemPage = () => changeSelectedPage(SubPages.getAddItemPage());
  const openActivePage = () => changeSelectedPage(SubPages.getActivePage());
  const openCompletedPage = () =>
    changeSelectedPage(SubPages.getCompletedPage());

  const isPendingPageSelected = () =>
    SubPages.isPageEqual(SubPages.getPendingPage(), selectedPage);

  const isAddItemPageSelected = () =>
    SubPages.isPageEqual(SubPages.getAddItemPage(), selectedPage);

  const isActivePageSelected = () =>
    SubPages.isPageEqual(SubPages.getActivePage(), selectedPage);

  const isCompletedPageSelected = () =>
    SubPages.isPageEqual(SubPages.getCompletedPage(), selectedPage);

  const isLastOpenedPagePendingPage = () =>
    SubPages.isPageEqual(SubPages.getPendingPage(), lastOpenedPageWas);
  const isLastOpenedPageActivePage = () =>
    SubPages.isPageEqual(SubPages.getActivePage(), lastOpenedPageWas);

  const goToAddItemPageAndWriteLastOpenedPage = () => {
    changeLastOpenedPageWas(selectedPage);
    openAddItemPage();
  };

  const goOutFromAddItemPage = () => {
    if (isLastOpenedPagePendingPage()) {
      openPendingPage();
    } else if (isLastOpenedPageActivePage()) {
      openActivePage();
    }
  };

  return {
    selectedPage,

    openPendingPage,
    openAddItemPage,
    openActivePage,
    openCompletedPage,

    isPendingPageSelected,
    isAddItemPageSelected,
    isActivePageSelected,
    isCompletedPageSelected,

    lastOpenedPageWas,
    changeLastOpenedPageWas,
    isLastOpenedPagePendingPage,
    isLastOpenedPageActivePage,
    goToAddItemPageAndWriteLastOpenedPage,
    goOutFromAddItemPage,
  };
};
