import React from 'react';
import PropTypes from 'prop-types';
import { DrawerPartialRefundContainer } from './style';
import { colors } from 'theme';
import { OrderPriceEffects } from 'feasttt/domain';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerPartialRefund({
  visible,
  onClose,
  onIssueRefund,
  fullPrice,
  changeType,
  percentages,
  changePercentages,
  onChangeStaticPercentages,
  dollars,
  changeDollars,
  isOtherMode,
  onEnableOtherMode,
  isStaticPercentageSelected,
  isPercentageTypeActive,
  isFixedTypeActive,
}) {
  const refund = () => {
    onIssueRefund();
    onClose();
  };

  return (
    <DrawerPartialRefundContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
      customHeight={isOtherMode ? 475 : 395}
    >
      <SingleText size={22} spacing='0.42px' marginBottom={32} align='center'>
        Partial Refund
      </SingleText>
      <div className='refund-options'>
        {OrderPriceEffects.STATIC_PERCENTAGE_OPTIONS.map(
          (staticPercentageOption, index) => (
            <div
              className={`refund-option ${
                isStaticPercentageSelected(staticPercentageOption)
                  ? 'selected'
                  : ''
              }`}
              onClick={() => onChangeStaticPercentages(staticPercentageOption)}
              key={staticPercentageOption + index}
            >
              <span className='refund-option-title'>
                {staticPercentageOption}%
              </span>
              <span className='refund-option-dollars'>
                ${(fullPrice * (staticPercentageOption / 100)).toFixed(2)}
              </span>
            </div>
          )
        )}
        <div
          className={`refund-option ${isOtherMode ? 'selected' : ''}`}
          onClick={onEnableOtherMode}
        >
          <span className='refund-option-title'>Other</span>
        </div>
      </div>
      {isOtherMode ? (
        <div className='other-container'>
          <div
            className={`${
              isFixedTypeActive() ? 'active' : ''
            } type-container fixed`}
            onClick={() => changeType(OrderPriceEffects.effectsTypes.fixed)}
          >
            <span className='type-title'>Fixed Amount</span>
            <div className='type-value-container'>
              <span className='type-value-title'>$</span>
              <input
                type='number'
                className='type-value'
                value={dollars}
                onChange={(event) => changeDollars(Number(event.target.value))}
                min='1'
                maxLength={5}
              />
            </div>
          </div>
          <div
            className={`${
              isPercentageTypeActive() ? 'active' : ''
            } type-container percentage`}
            onClick={() =>
              changeType(OrderPriceEffects.effectsTypes.percentage)
            }
          >
            <span className='type-title'>Percentage</span>
            <div className='type-value-container'>
              <input
                type='number'
                className='type-value'
                value={Math.trunc(percentages)}
                onChange={(event) =>
                  changePercentages(Number(event.target.value))
                }
                min={1}
                max={OrderPriceEffects.PARTIAL_REFUND_LIMIT_PERCENTAGE}
              />
              <span className='type-value-title'>%</span>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className={`will-be-refunded ${isOtherMode ? 'with-other' : ''}`}>
        <span className='refunded'>
          {`$${dollars.toFixed(2)}`} will be refunded.
        </span>
      </div>
      <div className='issue-refund-container'>
        <SingleButton
          title='Issue Refund'
          margin='17px 0px 20px'
          onClick={refund}
        />
        <SingleButton
          title='Cancel'
          btnColor='transparent'
          txtColor={colors.redColor}
          onClick={onClose}
        />
      </div>
    </DrawerPartialRefundContainer>
  );
}

DrawerPartialRefund.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onIssueRefund: PropTypes.func,
  fullPrice: PropTypes.number,
  changeType: PropTypes.func,
  percentages: PropTypes.number,
  changePercentages: PropTypes.func,
  dollars: PropTypes.number,
  changeDollars: PropTypes.func,
  isOtherMode: PropTypes.bool,
  isStaticPercentageSelected: PropTypes.func,
  isPercentageTypeActive: PropTypes.func,
  isFixedTypeActive: PropTypes.func,
  onEnableOtherMode: PropTypes.func,
  onChangeStaticPercentages: PropTypes.func,
};
