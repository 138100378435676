import React from 'react';
import PropTypes from 'prop-types';
import { ControlItemContainer } from './style';

export function ControlItem({
  iconForMainTitle,
  mainTitle,
  valueWithLabel,
  onClick = () => {},
}) {
  return (
    <ControlItemContainer className='control-item'>
      <div className='main-title-container'>
        {iconForMainTitle ? (
          <div className='icon-main-title'>{iconForMainTitle}</div>
        ) : (
          ''
        )}
        <span className='main-title'>{mainTitle}</span>
      </div>
      <div className='value-container' onClick={onClick}>
        <span className='value-title'>{valueWithLabel}</span>
        <div className='look-container'>
          <i className='fas fa-chevron-right' />
        </div>
      </div>
    </ControlItemContainer>
  );
}

ControlItem.propTypes = {
  iconForMainTitle: PropTypes.any,
  mainTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueWithLabel: PropTypes.string,
  onClick: PropTypes.func,
};
