import React, { useContext } from 'react'
import PropTypes from 'prop-types';
// import './style.scss';
import Box from 'components/Box';
import { FaChevronLeft } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import { DrawerMenuStatusContainer } from './style';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';

export default function DrawerMenuStatus({ 
  // visible = false,
  placement = 'right',
  // onClose = () => {},
}) {

  const {
    openDrawerMenuStatus, setOpenDrawerMenuStatus,
  } = useContext(StateContext);
  const {selectedMenu, setSelectedMenu} = useContext(DataContext);

  const onClose = () => {
    setOpenDrawerMenuStatus(false)
    setSelectedMenu({...selectedMenu, status: null})
  }
  const onClickStatus = (value) => {
    setSelectedMenu({...selectedMenu, status: value});
    setOpenDrawerMenuStatus(false)
  }

  return (
      <DrawerMenuStatusContainer
        onClose={() => setOpenDrawerMenuStatus(false)}
        visible={openDrawerMenuStatus}
        placement={placement}
      >
        <Box display='flex' alignItems='center' marginBottom={40}>
          <SingleIconWrapper onClick={onClose}>
            <FaChevronLeft size={20} color='white' />
          </SingleIconWrapper>
          <Box display='flex' justifyContent='center' flex={1}>
            <SingleText content='Status' align='center' size={22} weight={500}>Status</SingleText>
          </Box>
        </Box>
        <Box className='drawer-status--options'>
          <Box className={`drawer-status--option ${selectedMenu.status === 1 ? 'active' : 'inactive'}`} marginBottom={15} onClick={() => onClickStatus(1)}>
            <SingleText content='Active' size={14} weight={500} spacing='-0.34'>Active</SingleText>
          </Box>
          <Box className={`drawer-status--option ${selectedMenu.status === 0 ? 'active' : 'inactive'}`} onClick={() => onClickStatus(0)}>
            <SingleText content='Inactive' size={14} width='fit-content' weight={500} spacing='-0.34'>Inactive</SingleText>
            <SingleText content='Not visible to customers' width='fit-content' size={14} opacity={0.5} weight={500} spacing='-0.34'>Not visible to customers</SingleText>
          </Box>
        </Box>
      </DrawerMenuStatusContainer>
  )
}

DrawerMenuStatus.propTypes = {
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickDay: PropTypes.func,
  onClickDoneStatus: PropTypes.func,
};
