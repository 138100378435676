import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import Box from 'components/Box';
import { FaBars, FaTimes, FaTrash } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerCategoriesContainer } from './style';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { colors } from 'theme';
import SingleIconWrapper from 'components/SingleIconWrapper';
import SingleDragger from 'components/SingleDragger';
// import { restaurantServices } from 'services/api-services';
// import { AuthContext } from 'contexts/AuthContextContainer';
import SingleEmptyScreen from 'components/SingleEmptyScreen';
import SingleLoader from 'components/SingleLoader';

export default function DrawerCategories({
  placement = 'right',
}) {

  const {
    allCategories, setAllCategories,
    openDrawerCategories, setOpenDrawerCategories,
    setOpenDrawerDeleteCategory, setOpenDrawerAddNewCategory
  } = useContext(StateContext);
  // const { restaurantId } = useContext(AuthContext)
  const { setChosenCategory } = useContext(DataContext);
  const [isLoading] = useState(false);

  const onClickSaveCategory = () => setOpenDrawerCategories(false);

  const onClickRemoveCategory = (item) => {
    setChosenCategory(item);
    setOpenDrawerDeleteCategory(true);
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAllCategories(arrayMove(allCategories, oldIndex, newIndex));
    // setCategories(arrayMove(categories, oldIndex, newIndex))
  }
  // const getCategories = async () => {
  //   setIsLoading(true)
  //   const { ok, data, errMessage } = await restaurantServices.getCategories(restaurantId)
  //   console.log({ ok, data, errMessage })
  //   setIsLoading(false)
  // }

  const onClose = () => {
    setOpenDrawerCategories(false);
  }

  useEffect(() => {
    // openDrawerCategories && getCategories()
  }, [openDrawerCategories])

  return (
    <DrawerCategoriesContainer
      placement={placement}
      visible={openDrawerCategories}
      onClose={onClose}
    >
      <Box is='div' display='flex' alignItems='center' marginBottom={40}>
        <SingleIconWrapper onClick={onClose}>
          <FaTimes className='cursor' size={20} color='rgba(255, 255, 255, 0.3)' />
        </SingleIconWrapper>
        <Box is='div' display='flex' justifyContent='center' flex={1}>
          <SingleText align='center' size={22} weight={500}>Edit Categories</SingleText>
        </Box>
      </Box>
      {isLoading
        ? <SingleLoader isIn='drawer' />
        : allCategories.length > 0 ?
          <SingleDragger
            containerStyle={{ margin: '0 -24px' }}
            renderItem={(item) =>
              <Box
                is='div'
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                padding='15px 35px'
                borderTop='solid 1px rgba(255, 255, 255, 0.1)'
                borderBottom='solid 1px rgba(255, 255, 255, 0.1)'
                background={colors.gray2}
              >
                <FaBars className='icon-drag' color={colors.white3} size={20} style={{ cursor: 'pointer' }} />
                <Box is='div' display='flex' flexDirection='column' justifyContent='start' marginRight='auto' marginLeft={19}>
                  <SingleText size={14} weight={600} spacing='-0.34px'>{item.name}</SingleText>
                </Box>
                <FaTrash className='cursor' color={colors.white3} size={20} onClick={() => onClickRemoveCategory(item)} />
              </Box>
            }
            items={allCategories}
            onSortEnd={(param) => onSortEnd(param)}
          />
          : <SingleEmptyScreen />
      }
      <Box is='div' display='flex' flexDirection='column' marginTop='auto'>
        <SingleButton
          title='Add New Category'
          margin='0 0 20px'
          btnColor='#61646c'
          txtColor='white'
          onClick={() => setOpenDrawerAddNewCategory(true)}
        />
        <SingleButton
          title='Save'
          onClick={onClickSaveCategory}
        />
      </Box>
    </DrawerCategoriesContainer>
  )
}

DrawerCategories.propTypes = {
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickAddNewCategory: PropTypes.func,
  onClickSaveCategory: PropTypes.func,
  onClickRemoveCategory: PropTypes.func,
};
