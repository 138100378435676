import React, { useContext } from 'react'
import PropTypes from 'prop-types';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { showMessage } from 'services/functions';
import { restaurantServices } from 'services/api-services';
import { AuthContext } from 'contexts/AuthContextContainer';
import { DrawerDeleteItemContainer } from './style';
import { StateContext } from 'contexts/StateContextContainer';

export default function DrawerDeleteItem({
  visible = false,
  placement = 'bottom',
  editFood,
  setOpenDrawerDeleteFoodItem = () => { },
}) {
  const { restaurantId } = useContext(AuthContext);

  const { foods, setFoods } = useContext(StateContext);

  if (!editFood) {
    return null
  }

  const onClose = () => setOpenDrawerDeleteFoodItem(false)

  const onClickDeleteItem = async () => {
    const { ok, errMessage } = await restaurantServices.deleteFood({ restaurantId, foodId: editFood.id });
    if (ok) {
      showMessage("Deleted successfully", "success");
      const index = foods.findIndex(item => item.id == editFood.id);
      const foodsCopy = [...foods];
      foodsCopy.splice(index, 1);
      setFoods(foodsCopy);
      onClose();
    } else {
      showMessage(errMessage, 'error');
    }
  }

  return (
    <DrawerDeleteItemContainer
      placement={placement}
      onClose={onClose}
      height='fit-content'
      visible={visible}
    >
      <Box is='div' display='flex' flexDirection='column' alignItems='flex-start' marginBottom={30}>
        <SingleText size={22} weight={500} marginBottom={10}>Delete Item</SingleText>
        <SingleText lineHeight={1.5} spacing='0.27px' marginBottom={10}>Are you sure you want to delete <b>{editFood.title}</b>?</SingleText>
        <SingleText content='This cannot be undone.' size={14} lineHeight={1.5} spacing='0.27px' marginBottom={21}>This cannot be undone.</SingleText>
      </Box>
      <Box is='div' display='flex' flexDirection='column'>
        <SingleButton
          title='Keep'
          margin='0 0 20px'
          onClick={onClose}
        />
        <SingleButton
          title='Delete'
          btnColor='#61646c'
          txtColor='white'
          margin='0 0 20px'
          onClick={onClickDeleteItem}
        />
      </Box>
    </DrawerDeleteItemContainer>
  )
}

DrawerDeleteItem.propTypes = {
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  setOpenDrawerDeleteFoodItem: PropTypes.func,
  editFood: PropTypes.object,
};
