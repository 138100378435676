import React from 'react';
import { WelcomeDescriptionContainer } from './style';
import { useWelcomeFlow } from 'hooks';
import { WelcomeLogo } from './WelcomeLogo';
import { ProgressBar } from './ProgressBar';

export function WelcomeDescription() {
  const { getProgressPercentage } = useWelcomeFlow();

  return (
    <WelcomeDescriptionContainer>
      <WelcomeLogo />
      <ProgressBar percentage={getProgressPercentage()} />
      <p className='welcome-description-text'>
        Your restaurant will not be visible to customers on the Feasttt app
        until you complete these steps.
      </p>
    </WelcomeDescriptionContainer>
  );
}
