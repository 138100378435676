import styled from '@emotion/styled';
import { Dropdown } from 'react-bootstrap';

export const StyledDropdownMenu = styled(Dropdown.Menu)`
  border-radius: 25px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.26);
  border: solid 1px rgba(255, 255, 255, 0.08);
  background-color: #151617;
  width: max-content;
  padding: 15px 20px;
`;