import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Box from 'components/Box';
import { FaCandyCane, FaChevronLeft, FaLeaf } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import { DrawerDietaryTagsContainer } from './style';
import SingleIconWrapper from 'components/SingleIconWrapper';
import SingleButton from 'components/SingleButton';
import { colors } from 'theme';

export const DietaryTags = [
  { value: 0, name: 'Vegetarian', icon: <FaLeaf color='white' size={14} /> },
  { value: 1, name: 'Vegan', icon: <i className="fas fa-leaf-heart text-white"></i> },
  { value: 2, name: 'Gluten-Free', icon: <i className="fas fa-wheat text-white"></i> },
  { value: 3, name: 'Dairy-Free', icon: <i className="fas fa-cow text-white"></i> },
  { value: 4, name: 'Sugar-Free', icon: <FaCandyCane color='white' size={14} /> },
]
export default function DrawerDietaryTags({
  visible = false,
  placement = 'right',
  value = [],
  onClose = () => { },
  onSubmit = () => { },
}) {

  const [selected, setSelected] = useState(value ?? []);
  const checkIsSelected = (id) => selected.includes(id);

  const onClickTag = (selectedItem) => {
    if (!checkIsSelected(selectedItem.value)) {
      setSelected([...selected, selectedItem.value]);
    } else {
      setSelected(selected.filter((item) => item !== selectedItem.value));
    }
  };

  const onClickDone = () => {
    onSubmit(selected)
  };

  return (
    <DrawerDietaryTagsContainer
      onClose={onClose}
      visible={visible}
      placement={placement}
    >
      <Box is='div' display='flex' alignItems='center' marginBottom={40}>
        <SingleIconWrapper onClick={onClose}>
          <FaChevronLeft size={20} color='white' />
        </SingleIconWrapper>
        <Box is='div' display='flex' justifyContent='center' flex={1}>
          <SingleText content='Dietary Tags' align='center' size={22} weight={500}>Dietary Tags</SingleText>
        </Box>
      </Box>
      <Box is='div' className='drawer-status--options'>
        {DietaryTags.map((_tag, _i) =>
          <Box key={_i} className={`drawer-status--option cursor ${checkIsSelected(_tag.value) ? 'active' : 'inactive'}`} marginBottom={15} onClick={() => onClickTag(_tag, _i)}>
            <Box width={20}>
              {_tag.icon}
            </Box>
            <SingleText content={_tag.name} size={14} weight={500} spacing='-0.34' marginLeft={20}>{_tag.name}</SingleText>
          </Box>
        )}
      </Box>
      <Box display='flex' flexDirection='column' marginTop='auto'>
        <SingleButton
          title='Done'
          btnColor={colors.redColor}
          txtColor='white'
          onClick={onClickDone}
        />
      </Box>
    </DrawerDietaryTagsContainer>
  )
}

DrawerDietaryTags.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.number),
};
