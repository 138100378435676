import styled from '@emotion/styled';
import { colors } from 'theme';
import InputGroup from 'react-bootstrap/InputGroup';

export const DropdownRoleContainer = styled(InputGroup)`
  &.role-dropdown-container {
    width: 100%;

    .role-dropdown {
      width: 100%;

      .dropdown-toggle {
        width: 100%;
        font-size: 15px;
        color: white;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background-color: ${colors.gray2};
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          cursor: pointer;
          background-color: ${colors.gray2};
        }

        &:focus {
          box-shadow: none;
        }
      }

      .dropdown-menu {
        width: 100%;
        border-radius: 25px;
        background-color: ${colors.gray5};

        .dropdown-item {
          padding-top: 8px;
          padding-bottom: 8px;
          color: white;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
`;
