import React, { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { MoveableStyled, ContextMenuContainer } from './style';
import { Tables } from 'feasttt/domain';
import { showMessage, collisionDetection } from 'services/functions';
import { restaurantServices } from 'services/api-services';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';
import useOutsideClick from 'components/OutsideClickDetect';
import DrawerRemoveWall from './DrawerRemoveWall';
import DrawerRemoveTable from './DrawerRemoveTable';
import CreateSeats from './CreateSeats';

import { DefaultMenu } from 'assets/images/menu';

// ***IMPORTANT NOTE***
// CreateSeats & collisionDetection
// CreateSeats component has extra code, that can be useful in future (like: rectangle elements for tables, horizontal / vertical positions etc...). For now, these part of code doesn't use anywhere. In case, if it will not be used, these part of codes can be refactored.

export function SingleMovable({
  className,
  tableOriginal,
  otherClassNames,
  translate = [178, 78],
  seats,
  status,
  shape,
  wall = null,
  isOccupied,
  tableName,
  tableId,
  position,
  setPanDisabled,
  rotation,
  contextMenuDisabled = false,
  editable = true,
}) {
  const {
    getTables,
    setTables,
    setTablesWasUpdated,
    setOpenDrawerTableEdit,
    setOpenDrawerWallEdit,
  } = useContext(StateContext);

  const onOpenDrawerWallEdit = () => setOpenDrawerWallEdit(true);

  const { restaurantId } = useContext(AuthContext);
  const {
    floorDetails,
    selectedFloorId,
    floorWalls,
    setFloorWalls,
    setTableForEditTemp,
    setWallForEdit,
  } = useContext(DataContext);

  const [openDrawerWallDelete, setOpenDrawerWallDelete] = useState(false);
  const [openDrawerTableDelete, setOpenDrawerTableDelete] = useState(false);
  const [target, setTarget] = useState();
  const [elementGuidelines, setElementGuidelines] = useState([]);
  const [isRotatedHidden, setRotatedHidden] = useState(true);
  const [isTableActive, setTableActive] = useState(false);
  const [frame, setFrame] = useState({
    translate: [...translate],
    rotate: 0,
  });
  const [isContextMenuActive, setIsContextMenuActive] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const ref = useRef();

  const computeShapeSize = () => {
    if (shape === 'wall') {
      return computeWallSize();
    } else {
      return computeTableSize();
    }
  };

  const computeWallSize = () => {
    const { width, height } = wall;

    return {
      width,
      height,
    };
  };

  const computeTableSize = () => {
    const tableSize = Tables.createTableSize(tableOriginal);
    return tableSize.computeTableSize();
  };

  const elementSize = computeShapeSize();

  useEffect(() => {
    setTarget(document.querySelector(`.${className}`));
    setElementGuidelines(
      otherClassNames.map((_className) =>
        document.querySelector(`.${_className}`)
      )
    );
    //eslint-disable-next-line
  }, [getTables]);

  useEffect(() => {
    setFrame({
      translate: [...translate],
      rotate: 0,
    });
    // eslint-disable-next-line
  }, [translate[0], translate[1]]);

  useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  });

  function handleContextMenu(e) {
    if (!editable)
      return;
    e.preventDefault();
    if (ref.current.contains(e.target)) {
      setIsContextMenuActive(true);
      setContextMenuPosition({ x: e.layerX, y: e.layerY });
    }
  }

  useOutsideClick(ref, () => {
    if (!isRotatedHidden || isTableActive) {
      setRotatedHidden(true);
      setTableActive(false);
    }
    if (isContextMenuActive) {
      setIsContextMenuActive(false);
    }
  });

  async function onDragEndRelease() {
    if (shape === 'wall') {
      const currentFloorObj = floorDetails.find(
        (x) => x?.id === selectedFloorId
      );
      const newWalls = [...floorWalls];
      const indexObjectToChange = newWalls.findIndex(
        (wall) =>
          wall.x === translate[0] &&
          wall.y === translate[1] &&
          ((position === 'horizontal' && wall.width > wall.height) ||
            (position === 'vertical' && wall.width < wall.height))
      );
      newWalls.splice(indexObjectToChange, 1, {
        x: frame.translate[0],
        y: frame.translate[1],
        width: position === 'horizontal' ? 200 : 20,
        height: position === 'horizontal' ? 20 : 200,
      });

      const { ok, errMessage } = await restaurantServices.updateFloor(
        { restaurantId, floorId: selectedFloorId },
        { ...currentFloorObj, walls: newWalls }
      );
      if (ok) {
        setFloorWalls(newWalls);
      } else {
        showMessage(errMessage, 'error');
      }
    } else {
      const currentFloorObj = floorDetails.find(
        (x) => x.id === selectedFloorId
      );
      const newTablesList = [...getTables];
      const editedTable = newTablesList.find((x) => x.id === tableId);

      if (!editedTable) {
        return;
      }

      editedTable.x = frame.translate[0];
      editedTable.y = frame.translate[1];

      const { ok, errMessage } = await restaurantServices.updateFloor(
        { restaurantId, floorId: selectedFloorId },
        { ...currentFloorObj, tables: newTablesList }
      );
      if (ok) {
        setTables(newTablesList);
      } else {
        showMessage(errMessage, 'error');
      }
    }
  }

  function onRemoveWall() {
    setIsContextMenuActive(false);
    setOpenDrawerWallDelete(true);
  }

  const onDeleteWall = async () => {
    const currentFloorObj = floorDetails.find((x) => x.id === selectedFloorId);
    const newWalls = [...floorWalls];
    const indexObjectToChange = newWalls.findIndex(
      (wall) =>
        wall.x === translate[0] &&
        wall.y === translate[1] &&
        ((position === 'horizontal' && wall.width > wall.height) ||
          (position === 'vertical' && wall.width < wall.height))
    );
    newWalls.splice(indexObjectToChange, 1);

    const onOk = () => {
      setFloorWalls(newWalls);
    };

    const onFail = (errMessage) => {
      showMessage(errMessage, 'error');
    };

    return await restaurantServices._updateFloor(
      { restaurantId, floorId: selectedFloorId },
      { ...currentFloorObj, walls: newWalls },
      onOk,
      onFail
    );
  };

  const onOpenDrawerEditWall = () => {
    setIsContextMenuActive(false);
    setWallForEdit(wall);
    onOpenDrawerWallEdit();
  };

  async function onRemoveTable() {
    setIsContextMenuActive(false);
    const tableToEdit = getTables.find((x) => x.id === tableId);
    setTableForEditTemp(tableToEdit);
    setOpenDrawerTableDelete(true);
  }

  const onDeleteTable = async () => {
    const currentFloorObj = floorDetails.find((x) => x.id === selectedFloorId);
    const newTablesList = [...getTables.filter((x) => x.id !== tableId)];

    const onOk = () => {
      setTables(newTablesList);
    };

    const onFail = (errMessage) => {
      showMessage(errMessage, 'error');
    };

    return await restaurantServices._updateFloor(
      { restaurantId, floorId: selectedFloorId },
      { ...currentFloorObj, tables: newTablesList },
      onOk,
      onFail
    );
  };

  async function onDuplicateTable() {
    setIsContextMenuActive(false);
    const currentFloorObj = floorDetails.find((x) => x.id === selectedFloorId);
    const tableToClone = getTables.find((x) => x.id === tableId);
    const newTable = {
      floor_id: tableToClone.floor_id,
      is_available: tableToClone.is_available,
      restaurant_id: tableToClone.restaurant_id,
      rotation: tableToClone.rotation,
      seats: tableToClone.seats,
      status: tableToClone.status,
      type: tableToClone.type,
      x: 0,
      y: 0,
    };
    const { ok, errMessage } = await restaurantServices.updateFloor(
      { restaurantId, floorId: selectedFloorId },
      { ...currentFloorObj, tables: [...getTables, newTable] }
    );
    if (ok) {
      setTablesWasUpdated(true);
      // setTables([...getTables, newTable]);
    } else {
      showMessage(errMessage, 'error');
    }
  }

  function onEditTable() {
    setIsContextMenuActive(false);
    const tableToEdit = getTables.find((x) => x.id === tableId);
    setTableForEditTemp(tableToEdit);
    setOpenDrawerTableEdit(true);
  }

  // eslint-disable-next-line react/no-multi-comp
  function MenuItem() {
    return (
      <ContextMenuContainer
        style={{
          transform: `translate(${contextMenuPosition.x}px, ${contextMenuPosition.y}px)`,
          zIndex: 10000,
        }}
      >
        {shape === 'wall' ? (
          <>
            <div
              key='1menuitem'
              className='context-menu-item'
              onClick={() => onOpenDrawerEditWall()}
              aria-hidden='true'
            >
              <i className='fas fa-pencil-alt' />
              <span>Edit</span>
            </div>
            <div
              key='3menuitem'
              className='context-menu-item'
              onClick={() => onRemoveWall()}
              aria-hidden='true'
            >
              <i className='fas fa-trash' />
              <span>Remove</span>
            </div>
          </>
        ) : (
          <>
            <div
              key='1menuitem'
              className='context-menu-item'
              onClick={() => onEditTable()}
              aria-hidden='true'
            >
              <i className='fas fa-pencil-alt' />
              <span>Edit</span>
            </div>
            <div
              key='2menuitem'
              className='context-menu-item'
              onClick={() => onDuplicateTable()}
              aria-hidden='true'
            >
              <i className='far fa-clone' />
              <span>Duplicate</span>
            </div>
            <div
              key='3menuitem'
              className='context-menu-item'
              onClick={() => onRemoveTable()}
              aria-hidden='true'
            >
              <i className='fas fa-trash' />
              <span>Remove</span>
            </div>
          </>
        )}
      </ContextMenuContainer>
    );
  }

  return (
    <div style={{ minHeight: 0, width: 0, position: 'absolute' }} ref={ref}>
      {/* Actual table element */}
      {/* "position" property appears only for walls. It can be vertical or horizontal */}
      {isContextMenuActive === true && !contextMenuDisabled && <MenuItem />}
      <div
        src={DefaultMenu}
        className={`${className} ${isContextMenuActive}`}
        // preview={false}
        style={{
          cursor: 'pointer',
          transform: `translate(${translate[0]}px, ${translate[1]}px)`,
          width: elementSize.width,
          height: elementSize.height,
          backgroundColor: `${
            status === 1 ? (isOccupied ? '#d6a67c' : '#1fc086') : '#767b81'
          }`,
          borderRadius: `${shape === 'circle' ? '50%' : ''}`,
          display: `flex`,
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
          fontSize: 11,
        }}
      >
        <span
          className='table-name-title'
          style={{ transform: `${rotation ? `rotate(${-rotation}deg)` : ''}` }}
        >
          {tableName}
        </span>
        <CreateSeats
          seats={seats}
          shape={shape}
          position={position}
          width={elementSize.width}
          height={elementSize.height}
          backgroundColor={
            status === 1 ? (isOccupied ? '#d6a67c' : '#1fc086') : '#767b81'
          }
        />
      </div>

      <MoveableStyled
        className={`${isRotatedHidden ? 'hidden-rotatable' : ''}`}
        target={target}
        elementGuidelines={elementGuidelines}
        snappable={editable}
        snapThreshold={5}
        isDisplaySnapDigit={true}
        snapGap={true}
        snapElement={true}
        snapVertical={true}
        snapHorizontal={true}
        snapCenter={true}
        snapDigit={0}
        draggable={editable}
        throttleDrag={0}
        startDragRotate={0}
        throttleDragRotate={0}
        onClick={() => {
          setRotatedHidden(!isRotatedHidden);
          if (!isTableActive) setTableActive(true);
        }}
        rotatable={editable}
        throttleRotate={45}
        rotationPosition={'right'}
        zoom={isTableActive ? 1 : 0}
        origin={false}
        padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
        onDragStart={({ set }) => {
          setPanDisabled(true);
          set(frame.translate);
        }}
        onDrag={({ target, beforeTranslate }) => {
          if (!isTableActive) {
            setTableActive(true);
          }
          const currentElementsCoordinates = [
            [beforeTranslate[0], beforeTranslate[1]],
            [beforeTranslate[0] + elementSize.width, beforeTranslate[1]],
            [beforeTranslate[0], beforeTranslate[1] + elementSize.height],
            [
              beforeTranslate[0] + elementSize.width,
              beforeTranslate[1] + elementSize.height,
            ],

            [beforeTranslate[0] + elementSize.width / 2, beforeTranslate[1]],
            [
              beforeTranslate[0] + elementSize.width / 2,
              beforeTranslate[1] + elementSize.height,
            ],
            [beforeTranslate[0], beforeTranslate[1] + elementSize.height / 2],
            [
              beforeTranslate[0] + elementSize.width,
              beforeTranslate[1] + elementSize.height / 2,
            ],
          ];
          if (
            !collisionDetection(currentElementsCoordinates, elementGuidelines)
          ) {
            setFrame({ ...frame, translate: beforeTranslate });
            // frame.translate = beforeTranslate;
            target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
          }
        }}
        onDragEnd={() => {
          onDragEndRelease();
          setPanDisabled(false);
        }}
        onRotateStart={({ set }) => {
          set(frame.rotate);
          setPanDisabled(true);
        }}
        onRotate={({ beforeRotate }) => {
          setFrame({ ...frame, rotate: beforeRotate });
          // frame.rotate = beforeRotate;
          target.style.transform = `rotate(${beforeRotate}deg)`;
        }}
        onRotateEnd={() => setPanDisabled(false)}
        onRender={({ target }) => {
          const { translate, rotate } = frame;
          target.style.transform =
            `translate(${translate[0]}px, ${translate[1]}px)` +
            ` rotate(${rotate}deg)`;
        }}
      />
      <DrawerRemoveWall
        visible={openDrawerWallDelete}
        onClose={() => setOpenDrawerWallDelete(false)}
        onDelete={onDeleteWall}
      />
      <DrawerRemoveTable
        visible={openDrawerTableDelete}
        onClose={() => setOpenDrawerTableDelete(false)}
        onDelete={onDeleteTable}
      />
    </div>
  );
}

SingleMovable.propTypes = {
  className: PropTypes.string,
  tableOriginal: PropTypes.object,
  otherClassNames: PropTypes.array,
  translate: PropTypes.array,
  y: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  seats: PropTypes.number,
  status: PropTypes.number,
  shape: PropTypes.string,
  wall: PropTypes.object,
  isOccupied: PropTypes.bool,
  tableName: PropTypes.any,
  tableId: PropTypes.any,
  position: PropTypes.string,
  setPanDisabled: PropTypes.func,
  rotation: PropTypes.number,
  contextMenuDisabled: PropTypes.bool,
  editable: PropTypes.bool,
};
