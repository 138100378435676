import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerDetailsContainer = styled(SingleDrawer)`
  background-color: ${colors.black1};
  padding: 0;

  .blur-backgound-image {
    width: 100%;
    min-height: 215px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    filter: blur(10px);
  }

  .account-action-container {
    position: absolute;
    width: 100%;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .account-status{
      height: 30px;
      width: fit-content;
      padding: 0px 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius:15.5px;
      cursor: pointer;

      &.active{
        background-color: ${colors.lightGreen};
      }

      &.inactive{
        background-color: #727373;
      }

      &:hover {
        .tooltip-container {
          display: block;
        }
      }

      .tooltip-container {
        position: absolute;
        top: 77px;
        display: none;
        background-color: #27282a;
        border-radius: 27px;
        padding: 0px 24px 15px 24px;
        width: 100%;
        height: auto;
        z-index: 999;
        max-width: 330px;
        
        .tooltip-text{
          font-size: 14px;
          color: white;
        }
        
        &:before {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          transform: rotate(45deg) translateX(86px) translateY(-97px);
          background-color: #27282a;
          border-radius: 2px;
        }
      }
    }
  }

  .profile-container{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 156px;
    z-index: 101;
    background-color: ${colors.black1};
    border-radius: 30px 30px 0px 0px;
    
    .profile-photo {
      width: 133px;
      height: 133px;
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      margin-left: auto;
      margin-right: auto;
      margin-top: -54px;
    }

    .profile-info-container {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .review-container{
      background-color: ${colors.gray2};
      border-radius: 30px 30px 0px 0px;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      min-height: 600px;

      .pagination-reviews {
        .ant-pagination {
          display: flex;
          justify-content: center;
          align-items: center;
          .ant-pagination-prev,
          .ant-pagination-next {
            margin: 0 15px;
            height: fit-content !important;
            .ant-pagination-item-link {
              /* border-radius: 20px; */
            }
          }
          .ant-pagination-item {
            background-color: unset;
            border: unset;
            min-width: 35px;
            &.ant-pagination-item-active {
              span {
                opacity: 1 !important;
              }
            }
          }
        }
      }
    }
  }
`;

export const SpaceDrawerReviews = styled.div`
  display: flex;
  align-items: center;
  .space-item {
    margin-right: 16px;
  }
  .space-item:last-child {
    margin-right: 0px;
  }
`;
