import styled from 'styled-components';

export const MenuListContainer = styled.div`
  width: 100%;
  position: relative;

  .list-category {
    padding: 24px 40px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .list-category-container {
      display: flex;
      flex-wrap: wrap;
    }

    .category-name {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 21px;

      .category-name-title {
        hyphens: auto;
        word-break: break-all;
        width: 80%;
        font-size: 20px;
        font-weight: 500;
        color: white;
      }
    }
    .category-empty-text {
      margin: 5px 0 0 0px;
      opacity: 0.5;
      font-size: 15px;
      letter-spacing: -0.36px;
      color: #fff;
    }
  }

  .add-item-wrapper {
    position: relative;

    .add-item-tooltip {
      position: absolute;
      background: #27282a;
      top: -120px;
      left: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100px;
      width: 256px;
      border-radius: 30px;
      transform: translateX(-50%);

      .add-item-button {
        gap: 18px;
        height: 50px;
        display: flex;
        padding-left: 26px;
        align-items: center;
        border: none;
        outline: none;
        background: transparent;
        color: #fff;
        box-sizing: border-box;
        position: relative;
        z-index: 1;

        .tooltip-icon {
          font-size: 18px;
        }

        &:hover {
          background: rgb(255 255 255 / 10%);
        }
        &:first-child {
          border-radius: 30px 30px 0 0;
        }
        &:last-child {
          border-radius: 0 0 30px 30px;
        }
      }

      &::after {
        content: '';
        position: absolute;
        height: 20px;
        top: 81%;
        width: 20px;
        left: 50%;
        background: #27282a;
        transform: rotate(45deg) translate(-5%, 70%) skew(10deg, 10deg);
        border-radius: 2px;
        z-index: 0;
      }
    }
  }
`;
