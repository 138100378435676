import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const RequestPendingContext = React.createContext(null);
export function RequestPendingContextContainer({ children }) {
  const [selectedPendingData, setSelectedPendingData] = useState({});
  const [pendingList, setPendingList] = useState([]);
  const [pushItems, setPushItems] = useState({})
  const [completedList, setCompletedList] = useState([])
  const [selectedCompletedData, setSelectedCompletedData] = useState({})
  const [otherReasonInput, setOtherReasonInput] = useState('')
  // partial refund option
  const [targetFoodCompletedOptions, setTargetFoodCompletedOptions] = useState(undefined)
  const [targetFoodCompletedOptionsText, setTargetFoodCompletedOptionsText] = useState(undefined)
  // Percentage
  const [targetPercentage, setTargetPercentage] = useState(undefined)
  const [displayPercentageDigit, setDisplayPercentageDigit] = useState(undefined)
  // pending info
  const [pendingInfo, setPendingInfo] = useState([]);
  // completed info
  const [completedInfo, setCompletedInfo] = useState([]);
  // reject-drink refresh
  const [rejectDrinkRefresh, setRejectDrinkRefresh] = useState(false);
  const [drinkOtherReasonInput, setDrinkOtherReasonInput] = useState('');
  // drink-refill options text & digit
  const [drinkOptionsText, setDrinkOptionsText] = useState(undefined);
  const [drinkOptionsDigit, setDrinkOptionsDigit] = useState(undefined);
  const [drinkStatus, setDrinkStatus] = useState(undefined);
  // other reject
  const [rejectOtherDigit, setRejectOtherDigit] = useState(undefined)
  const [otherRejectInput, setOtherRejectInput] = useState('');
  // other options text
  const [otherOptionsText, setOtherOptionsText] = useState(undefined)
  const [otherStatus, setOtherStatus] = useState(undefined)
  const [otherOptionsDigit, setOtherOptionsDigit] = useState(undefined)
  const [refreshOtherResolve, setRefreshOtherResolve] = useState(false)

  return (
    <RequestPendingContext.Provider
      value={{ 
       selectedPendingData, setSelectedPendingData, 
       pendingList, setPendingList, 
       pushItems, setPushItems,
       completedList, setCompletedList,
       selectedCompletedData, setSelectedCompletedData,
       otherReasonInput, setOtherReasonInput,
       targetFoodCompletedOptions, setTargetFoodCompletedOptions,
       targetFoodCompletedOptionsText, setTargetFoodCompletedOptionsText,
       targetPercentage, setTargetPercentage,
       displayPercentageDigit, setDisplayPercentageDigit,
       pendingInfo, setPendingInfo,
       completedInfo, setCompletedInfo,
       rejectDrinkRefresh, setRejectDrinkRefresh,
       drinkOtherReasonInput, setDrinkOtherReasonInput,
       drinkOptionsText, setDrinkOptionsText,
       drinkOptionsDigit, setDrinkOptionsDigit,
       drinkStatus, setDrinkStatus,
       rejectOtherDigit, setRejectOtherDigit,
       otherRejectInput, setOtherRejectInput,
       otherOptionsText, setOtherOptionsText,
       otherStatus, setOtherStatus,
       otherOptionsDigit, setOtherOptionsDigit,
       refreshOtherResolve, setRefreshOtherResolve
      }}
    >
      {children}
    </RequestPendingContext.Provider>
  );
}

RequestPendingContextContainer.propTypes = {
  children: PropTypes.any,
};
