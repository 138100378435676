/* eslint-disable react/display-name */
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { FaSpinner, FaChevronRight, FaPen, FaStar, FaTrash } from 'react-icons/fa';
import { AllStaffContainer, DropdownStaffMenu } from './style';
import { StaffRoles } from 'feasttt/domain';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import { colors } from 'theme';
import SingleAntPopup from 'components/SingleAntPopup';

import DefaultProfileImg from 'assets/images/profile/default-profile.png';

export default function TableAllStaff({
  staffs = [],
  searchValue,
  filterStaffsIfUserSearch,
  onClickStaffEdit = () => { },
  onClickStaffDelete = () => { },
  onClickStaffItem = () => { },
}) {
  const [selectedFilter, setSelectedFilter] = useState('');
  const [sortType, setSortType] = useState('');

  const onClickEdit = (_id) => {
    onClickStaffEdit(_id);
  }

  const onClickDelete = async (_id) => {
    onClickStaffDelete(_id);
  }

  function toggleFilter(filterName) {
    if (selectedFilter === filterName) {
      setSortType(sortType === 'up' ? 'down' : 'up')
    } else {
      setSelectedFilter(filterName);
      setSortType('up');
    }
  }

  function sortTableData() {
    const newList = [...filterStaffsIfUserSearch(staffs, searchValue)];
    if (selectedFilter) {
      newList.sort((a, b) => {
        if (a[selectedFilter] < b[selectedFilter]) {
          return sortType === 'up' ? -1 : 1;
        }
        if (a[selectedFilter] > b[selectedFilter]) {
          return sortType === 'up' ? 1 : -1;
        }
        return 0;
      });
    }
    return newList;
  }

  return (
    <AllStaffContainer>
      <thead>
        <tr>
          <th onClick={() => toggleFilter('first_name')}>
            Name {selectedFilter === 'first_name' && (sortType === 'up' ? <i className="fas fa-arrow-up" /> : <i className="fas fa-arrow-down" />)}
          </th>
          <th onClick={() => toggleFilter('role_slug')}>
            Role {selectedFilter === 'role_slug' && (sortType === 'up' ? <i className="fas fa-arrow-up" /> : <i className="fas fa-arrow-down" />)}
          </th>
          <th onClick={() => toggleFilter('status')}>
            Status {selectedFilter === 'status' && (sortType === 'up' ? <i className="fas fa-arrow-up" /> : <i className="fas fa-arrow-down" />)}
          </th>
          <th onClick={() => toggleFilter('rating')}>
            Rating {selectedFilter === 'rating' && (sortType === 'up' ? <i className="fas fa-arrow-up" /> : <i className="fas fa-arrow-down" />)}
          </th>
          <th onClick={() => toggleFilter('phone_number')}>
            Phone {selectedFilter === 'phone_number' && (sortType === 'up' ? <i className="fas fa-arrow-up" /> : <i className="fas fa-arrow-down" />)}
          </th>
          <th onClick={() => toggleFilter('email')}>
            Email {selectedFilter === 'email' && (sortType === 'up' ? <i className="fas fa-arrow-up" /> : <i className="fas fa-arrow-down" />)}
          </th>
          <th>

          </th>
        </tr>
      </thead>
      <tbody>
        {sortTableData().map((staff) => {
          const status = staff.status === 'Pending' ? 'Pending' : staff.status ? 'Active' : 'Inactive';
          return (
            <tr
              key={`trkeyintable${staff.id}`}
              className={status.toLowerCase()}
              onClick={() => onClickStaffItem(staff.id)}
            >
              {/* <td>
                <img className="staff-avatar" src={staff.avatar ? staff.avatar : DefaultProfileImg} alt="avatar" />
              </td> */}
              <td>
                <div className="name-container">
                  <img className="staff-avatar" src={staff.avatar ? staff.avatar.file : DefaultProfileImg} alt="avatar" />
                  <span className="staff-avatar-title">{`${staff.first_name} ${staff.last_name}`}</span>
                </div>
              </td>
              <td className="text-container-normal">
                {`${translateRole(staff.role_slug)}`}
              </td>
              <td>
                <span className={`status-title ${status.toLowerCase()}`}>{status === 'Pending' ? <><FaSpinner colors={colors.lightOrange} size={14} className="spinner-icon" />{status}</> : status}</span>
              </td>
              <td>
                <Box className='ratings' display='flex' alignItems='center'>
                  <FaStar className="ratings-icon" size={14} />
                  <SingleText size={14} weight="bold" spacing='-0.34px' marginLeft={5}>{staff.rating ? staff.rating : "N/A"}</SingleText>
                </Box>
              </td>
              <td className="text-container-normal">
                {`${staff.phone_number}`}
              </td>
              <td className="text-container-normal">
                {`${staff.email || "N/A"}`}
              </td>
              <td>
                <SingleAntPopup placement="bottom" title={''} content={
                  <DropdownStaffMenu>
                    <div className='popup-item' onClick={() => onClickEdit(staff.id)}>
                      <FaPen className='popup-item-icon' color={'#fff'} />
                      <SingleText content='Edit' size={14} weight={600} color={'#fff'}>Edit</SingleText>
                    </div>
                    <div className='popup-item' onClick={() => onClickDelete(staff.id)}>
                      <FaTrash className='popup-item-icon' color={'#fff'} />
                      <SingleText content='Delete' size={14} weight={600} color={'#fff'}>Delete</SingleText>
                    </div>
                  </DropdownStaffMenu>
                } trigger="click">
                  <span style={{ justifyContent: 'flex-end', cursor: 'pointer' }}><FaChevronRight color='white' /></span>
                </SingleAntPopup>
              </td>
            </tr>
          )
        })}
      </tbody>
    </AllStaffContainer>
  )
}

TableAllStaff.propTypes = {
  staffs: PropTypes.array,
  searchValue: PropTypes.string,
  filterStaffsIfUserSearch: PropTypes.func,
  onClickStaffEdit: PropTypes.func,
  onClickStaffDelete: PropTypes.func,
  onClickStaffItem: PropTypes.func,
  onStuffActiveChange: PropTypes.func,
};

export const translateRole = (role) => {
  return StaffRoles.readRole(role);
};
