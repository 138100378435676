import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerRemoveTableContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  padding: 0px;

  .drawer-remove-table-top {
    text-align: center;
    padding-top: 37px;
    z-index: 1000;
  }
`;
