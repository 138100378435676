import styled from '@emotion/styled'

export const StaffContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  div{
    margin-left: 16px;
  }
  div:first-of-type{
    margin-left: 0;
  }
`;

export const StaffContainerMenu = styled.div`
  padding: 0 35px;
  .staff-menus{
    display: flex;
    align-items: center;
    margin: 25px 0;
    .staff-menu-item{
      position: relative;
      margin-left: 35px;
      font-size: 14px;
      font-weight: bold;
      color: rgba(255,255,255,0.5);
      cursor: pointer;
      &.active{
        color: rgba(255,255,255);
      }
      &.active:after{
        background-color: #ed2626;
        border-radius: 10px;
      }
    }
    .staff-menu-item:first-of-type{
      margin-left: 0;
    }
    .staff-menu-item:after{
      content: '';
      display: block;
      position: absolute;
      height: 3px;
      width: 30px;
      background-color: transparent;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .staff-content-container{

  }
`;
