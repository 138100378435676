import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { dayTitles, months } from 'services/static';
import { SingleMonthCalendarContainer } from './style';

export default function SingleMonthCalendar({
  className = '',
  visible = false,
  onClick = () => {},
  isInRange = () => {},
  isRangeActive = false,
}) {
  const [currentYear, setCurrentYear] = useState(undefined);
  const [currentMonth, setCurrentMonth] = useState(undefined);

  const onNext = (event) => {
    event.stopPropagation();

    if (currentMonth === 11) {
      setCurrentYear(currentYear + 1);
    }

    const newCurrentMonth = (currentMonth + 1) % 12;
    setCurrentMonth(newCurrentMonth);
  };

  const onPrevious = (event) => {
    event.stopPropagation();

    if (currentMonth === 0) {
      setCurrentYear(currentYear - 1);
      setCurrentMonth(11);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const daysInMonth = (iMonth, iYear) =>
    32 - new Date(iYear, iMonth, 32).getDate();

  const handleDayClick = (currentYear, currentMonth, day) => {
    onClick(new Date(currentYear, currentMonth, day));
  };

  useEffect(() => {
    if (visible) {
      const today = new Date();
      const todayMonth = today.getMonth();
      const todayYear = today.getFullYear();

      setCurrentYear(todayYear);
      setCurrentMonth(todayMonth);
    }
  }, [visible]);

  return (
    <SingleMonthCalendarContainer className={className}>
      <Card className='calendar-container'>
        <Card.Header className='calendar-month'>
          <div className='change-month-icon previous' onClick={onPrevious}>
            <i className='fas fa-chevron-left' />
          </div>
          {months[currentMonth]}
          <div className='change-month-icon next' onClick={onNext}>
            <i className='fas fa-chevron-right' />
          </div>
        </Card.Header>
        <Table className='calendar-core' responsive='sm'>
          <thead className='calendar-head'>
            <tr>
              {dayTitles.map(({ key: weekDay }, index) => (
                <th key={index + weekDay}>{weekDay.slice(0, 1)}</th>
              ))}
            </tr>
          </thead>
          <tbody className='calendar-body'>
            {(() => {
              let date = 1;
              const today = new Date();
              const firstDay = new Date(currentYear, currentMonth).getDay();

              return new Array(6).fill(undefined).map((_, i) => (
                <tr key={i}>
                  {[1, 2, 3, 4, 5, 6, 0].map((j) => {
                    if (date <= daysInMonth(currentMonth, currentYear)) {
                      let renderDay = '';

                      if (i === 0 && j !== firstDay && date === 1) {
                        renderDay = (
                          <td key={i + j}>
                            <div className='empty-day'></div>
                          </td>
                        );
                      } else {
                        const isToday =
                          date === today.getDate() &&
                          currentYear === today.getFullYear() &&
                          currentMonth === today.getMonth();

                        const [isAvailableInRange, rangeEndOrStartClassName] = isInRange(new Date(currentYear, currentMonth, date));

                        const isPartOfRange =
                          isRangeActive && isAvailableInRange;

                        renderDay = (
                          <td
                            onClick={(event) => {
                              event.stopPropagation();
                              handleDayClick(
                                currentYear,
                                currentMonth,
                                event.currentTarget.innerText
                              );
                            }}
                            key={i + j}
                          >
                            <div
                              className={`${isToday ? 'today' : ''} ${
                                isPartOfRange
                                  ? `part-of-range ${rangeEndOrStartClassName}`
                                  : ''
                              } regular-day`}
                            >
                              {date}
                            </div>
                          </td>
                        );
                        date++;
                      }

                      return renderDay;
                    }

                    return (
                      <td key={i + j}>
                        <div />
                      </td>
                    );
                  })}
                </tr>
              ));
            })()}
          </tbody>
        </Table>
      </Card>
    </SingleMonthCalendarContainer>
  );
}

SingleMonthCalendar.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  onClick: PropTypes.func,
  isInRange: PropTypes.func,
  isRangeActive: PropTypes.bool,
};
