import React from 'react';
import { NoOrdersPlaceholderContainer } from './style';
import SingleText from 'components/SingleText';

export function NoOrdersPlaceholder() {
  return (
    <NoOrdersPlaceholderContainer>
      <div className='no-orders' />
      <SingleText
        size={24}
        color='rgba(255, 255, 255, 0.6)'
        width='314px'
        align='center'
      >
        There are no pending orders to show.
      </SingleText>
    </NoOrdersPlaceholderContainer>
  );
}
