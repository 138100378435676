import styled from '@emotion/styled';

export const ReviewReturnReplaceItemContainer = styled.div`
  .return-review-replaceitem-wrapper {
    width: 395px;
    padding: 35px 0 36px;
    border-radius: 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #27282a;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
    margin: -52px 0 0 0;
    position: absolute;
    bottom: 0;
  }

  .return-review-replaceitem-text {
    font-size: 22px;
    color: #ffffff;
    text-align: center;
  }

  .return-review-replaceitem-border-padding {
    border-bottom: 1px solid rgb(97 92 92 / 50%);
    padding: 15px 0 0 30px;
    line-height: 10px;
  }

  .return-review-replaceitem-title {
    font-size: 14px;
    color: #ffffff;
  }

  .return-review-replaceitem-refund-rejected-text {
    font-size: 14px;
    font-weight: 500;
  }

  .return-review-replaceitem-refund {
    text-align: center;
    margin-top: 2rem;
  }

  .return-review-replaceitem-btn-center {
    text-align: center;
  }

  .return-review-replaceitem-btn-style {
    background: #ed2626;
    border: none;
    width: 20rem;
    position: relative;
    height: 44px;
  }

  .return-review-replaceitem-btn-text {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
  }

  .return-review-replaceitem-go-back-text {
    text-align: center;
    font-size: 14px;
    margin-top: 2rem;
    color: #ed2626;
    font-weight: 600;
    cursor: pointer;
  }
`;
