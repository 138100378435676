import React from 'react';
import { WelcomeLogoContainer } from './style';

export function WelcomeLogo() {
  return (
    <WelcomeLogoContainer>
      <div className='logo-container' />
      <div className='welcome-container'>
        <span className='welcome-title'>Welcome</span>
        <span className='welcome-description'>
          Let`s get you set up on Feasttt.
        </span>
      </div>
    </WelcomeLogoContainer>
  );
}
