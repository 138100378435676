/* eslint-disable react/display-name */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { FaPlus } from 'react-icons/fa';
import { colors } from 'theme';
import { TableLayoutContainer } from './style';
import { Tables } from 'feasttt/domain';
import {
  showMessage,
  sortFloorsOrder,
  computeLengthBetweenTwoPoints,
  availableTables,
} from 'services/functions';
import { restaurantServices } from 'services/api-services';
import { AuthContext } from 'contexts/AuthContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { StateContext } from 'contexts/StateContextContainer';
import Box from 'components/Box';
import SingleAntSelect from 'components/SingleAntSelect';
import { DrawerFloors, SingleMovable } from '../components';
import { StatusBadge } from './StatusBadge';

export default function TableLayout({
  // tables = [],
  extraButtons,
  isTitle = false,
  editable = true,
}) {
  const [openDrawerFloors, setOpenDrawerFloors] = useState(false);

  const {
    setOpenDrawerElement,
    getTables,
    setTables,
    openDrawerFloorEdit,
    openDrawerFloorDelete,
    openDrawerTableAdd,
    tablesWasUpdated,
    setTablesWasUpdated,
  } = useContext(StateContext);
  const { restaurantId } = useContext(AuthContext);
  const {
    floorDetails,
    setFloorDetails,
    floorWalls,
    setFloorWalls,
    selectedFloorId,
    setSelectedFloorId,
  } = useContext(DataContext);
  // const [floorTables, setFloorTables] = useState([...getTables]);
  // const [floorDetails, setFloorDetails] = useState([]);
  // const [floorWalls, setFloorWalls] = useState([...wallsTemp]);
  // const [floorWalls, setFloorWalls] = useState([]);

  // const [selectedFloorIndex, setSelectedFloorIndex] = useState(0);
  // const [selectedFloorId, setSelectedFloorId] = useState();

  const [scale, onScale] = useState(1);
  const [isPanDisabled, setPanDisabled] = useState(false);
  const [sceneRotation, setSceneRotation] = useState(0);
  const [floorsLoaded, setFloorsLoaded] = useState(false);

  function changeFloor(floorId) {
    // setSelectedFloorIndex(floorIndex)
    setSelectedFloorId(floorId);
  }

  useEffect(() => {
    getFloorDetails();
    // eslint-disable-next-line
  }, [openDrawerFloorEdit, openDrawerFloorDelete, openDrawerTableAdd]);

  useEffect(() => {
    if (tablesWasUpdated) {
      getFloorDetails();
      setTablesWasUpdated(false);
    }
    // eslint-disable-next-line
  }, [tablesWasUpdated]);

  useEffect(() => {
    if (floorsLoaded) {
      getFloorDetails();
      setTables([...floorDetails.find((x) => x.id === selectedFloorId).tables]);
      setFloorWalls([
        ...floorDetails.find((x) => x.id === selectedFloorId).walls,
      ]);
    }
    // eslint-disable-next-line
  }, [selectedFloorId]);

  useEffect(() => {
    if (floorsLoaded) {
      setTables([...floorDetails.find((x) => x.id === selectedFloorId).tables]);
    }
  }, [floorDetails, setTables, selectedFloorId, floorsLoaded]);

  async function getFloorDetails() {
    // WARNING - Server has serious problem - It will not update `is_available` prop of table
    // we need to mock this feature:
    // 1. Update only in client-side
    // 2. Prevent any state updates that killing our mocked feature

    const isMockedFeatureIsExists = floorDetails.some(
      ({ tables }) => !!tables.find(({ __internal = false }) => __internal)
    );
    if (isMockedFeatureIsExists) {
      // preventing killing of our feature
      setFloorsLoaded(true);
      setTables([...floorDetails.find((x) => x.id === selectedFloorId).tables]);
      return;
    }

    const onOk = (data) => {
      if (data.length) {
        const allFloors = data.map(({ id }) => id);

        setFloorDetails(
          data.map((dataFloor, index) => ({
            ...dataFloor,
            floorOrder: index + 1,
          }))
        );
        if (!floorsLoaded || !allFloors.includes(selectedFloorId)) {
          setSelectedFloorId(data[0].id);
          setFloorsLoaded(true);
          setTables(data[0].tables);
          setFloorWalls(data[0].walls);
        } else {
          setTables(data.find((x) => x.id === selectedFloorId).tables);
        }
      }
    };

    const onFail = (errMessage) => {
      showMessage(errMessage, 'error');
    };

    if (restaurantId) {
      return await restaurantServices.getFloor(
        restaurantId,
        true,
        onOk,
        onFail
      );
    }
  }

  return (
    <TableLayoutContainer>
     <div style={{ background: '#1b1c1d' }}>
      <Box
        className='header-menu-container'
        height={99}
        padding='0 40px'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        borderBottom='solid 2px rgba(255, 255, 255, 0.08)'
      >
        {isTitle ? <span className='layout-title'>Tables</span> : ''}
        <div className='status-menu'>
          {[
            [
              'Available',
              availableTables({ tables: getTables }).length,
              colors.green,
            ],
            [
              'Occupied',
              getTables.filter((table) => !table.is_available).length,
              colors.orange1,
            ],
            [
              'Inactive',
              getTables.filter((table) => table.status === 0).length,
              colors.gray,
            ],
          ].map(([title, value, backgroundColor]) => (
            <StatusBadge
              title={title}
              value={value}
              backgroundColor={backgroundColor}
              key={title}
            />
          ))}
        </div>
        {/* </Space> */}
        <Box display='flex' alignItems='center' justifyContent='flex-end'>
          {floorsLoaded === true &&
            floorDetails
              .map(({ id: floorId }) => floorId)
              .includes(selectedFloorId) && (
              <SingleAntSelect
                className='floors-select'
                options={[
                  ...sortFloorsOrder(floorDetails).map(({ id, name }) => ({
                    value: id,
                    label: name,
                  })),
                ]}
                width='235px'
                placeholder='Select Floor'
                dropdownRender={
                  <div
                    onClick={() => setOpenDrawerFloors(true)}
                    aria-hidden='true'
                  >
                    Edit Floors
                  </div>
                }
                backgroundColor={colors.gray1}
                // onChange={(val) => changeFloor(val - 1)}
                onChange={(val) => changeFloor(val)}
                // value={floorsTemp[selectedFloorIndex]}
                value={{
                  value: floorDetails.find((x) => x.id === selectedFloorId).id,
                  label: floorDetails.find((x) => x.id === selectedFloorId).name,
                }}
              />
            )}
          {extraButtons ? <>{extraButtons}</> : <></>}
        </Box>
      </Box>
      <div className='tables'>
        <TransformWrapper
          scale={scale}
          wheel={{ disabled: true }}
          pan={{ disabled: isPanDisabled }}
        >
          <TransformComponent>
            {/* sceneRotation */}
            <div className='internal-scene'>
              <div
                className='scene-container'
                style={{
                  ...computeWidthAndHeightOfScene(getTables, floorWalls),
                  ...transformByDegrees(sceneRotation),
                }}
              >
                {getTables.map((_table, _i) => {
                  // I had to use some parameters, like isRightFloor, because of specifications of Movable library. I tried to manipulate with "floorTables" directly, but it cause app to crash.
                  return (
                    <SingleMovable
                      key={_i}
                      tableOriginal={_table}
                      className={`table_${_table.id}`}
                      // className={_table.className}
                      otherClassNames={[
                        ...getTables
                          .filter((_item) => _item.id !== _table.id)
                          .map((_item) => `table_${_item.id}`),
                        ...floorWalls.map((_item, i) => `walls-${i}`),
                      ]}
                      translate={[_table.x, _table.y]}
                      seats={_table.seats}
                      status={_table.status}
                      shape={_table.type === 0 ? 'circle' : 'square'}
                      position={_table.position}
                      tableName={
                        <div className='table-name-movable-container'>
                          <span className='table-name'>{`${_table.table_number}`}</span>
                          <span className='guests-and-seats-title'>
                            {_table.guests}/{_table.seats}
                          </span>
                        </div>
                      }
                      tableId={_table.id}
                      isOccupied={!_table.is_available}
                      // isRightFloor={_table.floor === floorsTemp[selectedFloorIndex].value}
                      setPanDisabled={setPanDisabled}
                      rotation={sceneRotation}
                      editable={editable}
                    />
                  );
                })}
                {floorWalls.map((_walls, i) => {
                  // Walls
                  const otherWallsArr = [
                    ...floorWalls.map((_item, _i) =>
                      i !== _i ? `walls-${_i}` : null
                    ),
                  ];
                  return (
                    <SingleMovable
                      key={`${i}walls-movable`}
                      className={`walls-${i}`}
                      // className={_table.className}
                      otherClassNames={[
                        ...otherWallsArr.filter((_item) => _item !== null),
                        ...getTables.map((_item) => `table_${_item.id}`),
                      ]}
                      translate={[_walls.x, _walls.y]}
                      seats={null}
                      status={null}
                      position={Tables.geometry.defineShapeDependingOnSize(
                        _walls
                      )}
                      shape='wall'
                      wall={_walls}
                      tableName={null}
                      isOccupied={null}
                      // isRightFloor={_walls.floor === floorsTemp[selectedFloorIndex].value}
                      setPanDisabled={setPanDisabled}
                      rotation={sceneRotation}
                      editable={editable}
                    />
                  );
                })}
              </div>
            </div>
          </TransformComponent>
        </TransformWrapper>

        {/* Buttons */}
        {editable && (
          <>
            {/* add table button */}
            <Box
              className='add-table-class'
              backgroundColor={colors.redColor}
              width={60}
              height={60}
              borderRadius='50%'
              display='flex'
              justifyContent='center'
              alignItems='center'
              onClick={() => setOpenDrawerElement(true)}
            >
              <FaPlus color='white' fontSize={23} />
            </Box>

            {/* rotate buttons */}
            <Box
              className='rotate-class'
              width='auto'
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <Box
                className='rotate-button'
                width={40}
                height={40}
                borderRadius='25px'
                display='flex'
                justifyContent='center'
                alignItems='center'
                onClick={() => setSceneRotation((sceneRotation - 90) % 360)}
              >
                <i className='fas fa-undo' />
              </Box>
              <Box
                className='rotate-button'
                width={40}
                height={40}
                borderRadius='25px'
                display='flex'
                justifyContent='center'
                alignItems='center'
                onClick={() => setSceneRotation((sceneRotation + 90) % 360)}
              >
                <i className='fas fa-redo' />
              </Box>
            </Box>
          </>
        )}
        {/* zoom buttons */}
        <Box
          className='zoom-class'
          width='auto'
          padding={3}
          borderRadius='25px'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Box
            className='zoom-button zoom-button-margin-bottom'
            width={30}
            height={30}
            borderRadius='50%'
            display='flex'
            justifyContent='center'
            alignItems='center'
            onClick={() => onScale(scale + 0.25)}
          >
            <i className='fas fa-plus' />
          </Box>
          <Box
            className='zoom-button'
            width={30}
            height={30}
            borderRadius='50%'
            display='flex'
            justifyContent='center'
            alignItems='center'
            onClick={() => scale > 1 && onScale(scale - 0.25)}
          >
            <i className='fas fa-minus' />
          </Box>
        </Box>
      </div>
      <DrawerFloors
        visible={openDrawerFloors}
        onClose={() => setOpenDrawerFloors(false)}
        getFloorDetails={getFloorDetails}
      />
     </div>
    </TableLayoutContainer>
  );
}

TableLayout.propTypes = {
  extraButtons: PropTypes.any,
  isTitle: PropTypes.bool,
  editable: PropTypes.bool,
};

export const transformByDegrees = (degrees) => {
  switch (degrees) {
    case -270:
    case 90:
      return {
        transform: `rotate(${degrees}deg)`,
        transformOrigin: 'center',
        // test
        // border: '1px solid green',
        height: '100%',
        width: '100%',
      };
    case 270:
    case -90:
      return {
        transform: `rotate(${degrees}deg)`,
        transformOrigin: 'center',
        //  test
        // border: '1px solid white',
        height: '100%',
        width: '100%',
      };
    case -180:
    case 180:
      return {
        transform: `rotate(${degrees}deg)`,
        // test
        // border: '1px solid red',
        height: '100%',
        width: '100%',
        transformOrigin: '50% 45%',
      };
    default:
      return {
        transform: 'rotate(0deg)',
        // test
        // border: '1px solid blue',
        height: '100%',
        width: '100%',
      };
  }
};

export const sortCoordinateAscending = (key) => (first, second) => {
  const firstValue = first[key];
  const secondValue = second[key];

  if (firstValue < secondValue) {
    return -1;
  } else if (firstValue > secondValue) {
    return 1;
  } else {
    return 0;
  }
};

// calculate width & height of area with tables and walls based on tables and walls coors
export const computeWidthAndHeightOfScene = (tables, walls) => {
  const all = [...tables, ...walls];
  if (all.length) {
    const xFromLowToHigh = [...all].sort(sortCoordinateAscending('x'));
    const yFromLowToHigh = [...all].sort(sortCoordinateAscending('y'));

    if (xFromLowToHigh.length === 1 && yFromLowToHigh.length === 1) {
      return;
    }

    const { x: X1, y: y1 } = xFromLowToHigh.shift();
    const { x: X2, y: y2 } = xFromLowToHigh.pop();

    const { x: x1, y: Y1 } = yFromLowToHigh.shift();
    const { x: x2, y: Y2 } = yFromLowToHigh.pop();

    const width = computeLengthBetweenTwoPoints(X1, y1, X2, y2);
    const height = computeLengthBetweenTwoPoints(x1, Y1, x2, Y2);

    return {
      width: `${width}px`,
      height: `${height}px`,
    };
  }

  return {};
};
