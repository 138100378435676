import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerDeleteOrderAreYouSureContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  height: 358px !important;
  padding: 72px 26px 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  position: relative;

  .trash-delete-icon {
    top: -35px;
  }

  &:before {
    content: '';
    display: block;
    width: 79px;
    height: 33px;
    background-color: transparent;
    position: absolute;
    top: -16.7px;
    left: 93px;
    border-right: 15px solid ${colors.gray5};
    border-bottom: 16px solid ${colors.gray5};
    border-bottom-right-radius: 51px;
  }

  &:after {
    content: '';
    display: block;
    width: 79px;
    height: 33px;
    background-color: transparent;
    position: absolute;
    top: -16.7px;
    right: 94px;
    border-left: 15px solid ${colors.gray5};
    border-bottom: 16px solid ${colors.gray5};
    border-bottom-left-radius: 51px;
  }

  .are-you-sure-title {
    position: relative;

    &:before {
      content: '';
      width: 86px;
      height: 86px;
      border: 16px solid ${colors.gray5};
      border-radius: 50%;
      background-color: transparent;
      position: absolute;
      top: -115px;
      left: 37px;
    }
  }
`;
