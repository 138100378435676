import styled from '@emotion/styled'
import { motion } from "framer-motion";

export const SingleIconWrapperContainer = styled(motion.div)`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  ${'' /* &:hover {
    background: ${props => props.background};
  } */}
`;
