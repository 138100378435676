import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactCodeInput from 'react-verification-code-input';
import { DrawerVerifyMobileNumberContainer } from './style';
import { colors } from 'theme';
import { PhoneNumberFormatter } from 'utils';
import { useBinaryState } from 'hooks';
import { showMessage } from 'services/functions';
import SingleButton from 'components/SingleButton';
import { useUpdateMyAccount } from 'hooks';

export function DrawerVerifyMobileNumber({
  phone,
  visible,
  onClose,
  onBack = () => {},
  onConfirmCode = () => {},
}) {
  const { isActive: codeIsResend, activate: resendCode } = useBinaryState();
  const { confirmMobileNumber } = useUpdateMyAccount();
  const [code, setCode] = useState('');
  const onChangeCode = (value) => setCode(value);

  const confirm = () => {
    onConfirmCode();
    onClose();
  };

  const onResendCode = () => {
    resendCode();
    showMessage('Code is resend');
  };

  const VALID_CODE_1 = '4444';
  const VALID_CODE_2 = '2626';

  const onComplete = (value) => {
    if (
      (!codeIsResend && value === VALID_CODE_1) ||
      (codeIsResend && value === VALID_CODE_2)
    ) {
      setTimeout(confirm, 400);
    }
  };

  const onCancel = () => {
    confirmMobileNumber(false);
    onClose();
  };

  const formatPhoneNumber = () => {
    const phoneNumberFormatter = new PhoneNumberFormatter(phone);
    return phoneNumberFormatter.formatPhoneNumberWithoutCountryCode();
  };

  const cssClassForCodeInputifUserInteractWithIt = () => {
    if (code.length === 4) {
      if (code === VALID_CODE_1 || code === VALID_CODE_2) {
        return 'filled';
      } else {
        return 'fail-code';
      }
    }

    return '';
  };

  return (
    <DrawerVerifyMobileNumberContainer
      visible={visible}
      placement='bottom'
      onClose={onCancel}
    >
      <div className='verify-mobile-top'>
        <div className='back-icon' onClick={onBack}>
          <i className='fas fa-chevron-left' />
        </div>
        <span className='verify-title'>Enter the verification code</span>
      </div>
      <span className='we-sent-code'>
        We sent the code to: {formatPhoneNumber()}
      </span>
      <ReactCodeInput
        className={`code-input ${cssClassForCodeInputifUserInteractWithIt()}`}
        fields={4}
        fieldWidth={78}
        fieldHeight={78}
        onChange={onChangeCode}
        onComplete={onComplete}
      />
      <div className='resend-container'>
        <span className='did-not-get-it'>Didn’t get it?</span>
        <div className='btn-resend' onClick={onResendCode}>
          <span className='resend'>Resend</span>
        </div>
      </div>
      <SingleButton
        title='Cancel'
        btnColor='transparent'
        txtColor={colors.redColor}
        onClick={onCancel}
      />
    </DrawerVerifyMobileNumberContainer>
  );
}

DrawerVerifyMobileNumber.propTypes = {
  phone: PropTypes.string,
  visible: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  onConfirmCode: PropTypes.func,
};
