import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerRemoveFoodItemFromOrderContainerStyled } from './style';
import { DrawerRemoveFoodItemFromOrder } from './DrawerRemoveFoodItemFromOrder';
import { DrawerFoodItemRemovedFromOrder } from './DrawerFoodItemRemovedFromOrder';

export function DrawerRemoveFoodItemFromOrderContainer({
  foodItem = {},
  visible,
  onClose,
  onRemoveFoodItem,
}) {
  const [
    openDrawerRemoveFoodItemFromOrder,
    setOpenDrawerRemoveFoodItemFromOrder,
  ] = useState(false);

  const onOpenDrawerRemoveFoodItemFromOrder = () =>
    setOpenDrawerRemoveFoodItemFromOrder(true);

  const onCloseDrawerRemoveFoodItemFromOrder = () =>
    setOpenDrawerRemoveFoodItemFromOrder(false);

  const [
    openDrawerFoodItemRemovedFromOrder,
    setOpenDrawerFoodItemRemovedFromOrder,
  ] = useState(false);

  const onOpenDrawerFoodItemRemovedFromOrder = () =>
    setOpenDrawerFoodItemRemovedFromOrder(true);

  const onCloseDrawerFoodItemRemovedFromOrder = () =>
    setOpenDrawerFoodItemRemovedFromOrder(false);

  const close = () => {
    onCloseDrawerFoodItemRemovedFromOrder();
    onCloseDrawerRemoveFoodItemFromOrder();
    onClose();
  };

  const onAfterRemoveFoodItem = () => {
    onCloseDrawerRemoveFoodItemFromOrder();
    onOpenDrawerFoodItemRemovedFromOrder();
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerRemoveFoodItemFromOrder();
    }
  }, [visible]);

  return (
    <DrawerRemoveFoodItemFromOrderContainerStyled
      visible={visible}
      placeholder='right'
      onClose={close}
    >
      <DrawerRemoveFoodItemFromOrder
        foodItem={foodItem}
        visible={openDrawerRemoveFoodItemFromOrder}
        onClose={close}
        onRemoveFoodItem={onRemoveFoodItem}
        onAfterRemoveFoodItem={onAfterRemoveFoodItem}
      />
      <DrawerFoodItemRemovedFromOrder
        visible={openDrawerFoodItemRemovedFromOrder}
        onClose={close}
      />
    </DrawerRemoveFoodItemFromOrderContainerStyled>
  );
}

DrawerRemoveFoodItemFromOrderContainer.propTypes = {
  foodItem: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onRemoveFoodItem: PropTypes.func,
};
