import React from 'react';
import PropTypes from 'prop-types';
import { DrawerVerifyEmailContainer } from './style';
import { showMessage } from 'services/functions';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

// @TODO Here we should implement logic which notifies server about changed email by user and it needs
// be confirmed
export function DrawerVerifyEmail({ visible, onClose, email = '' }) {
  const onResendLinkForConfirmEmail = () => {
    showMessage('Link is resended');
  };

  return (
    <DrawerVerifyEmailContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='verify-icon'>
        <i className='fas fa-envelope-open-text' />
      </div>
      <SingleText
        className='verify-email-top-title'
        size={22}
        spacing='0.42px'
        align='center'
        marginBottom={10}
      >
        Are you sure ?
      </SingleText>
      <SingleText
        lineHeight='21px'
        spacing='0.27px'
        align='center'
        marginBottom={31}
      >
        Click the link inside to verify your email address ({email}).
      </SingleText>
      <div className='didnot-get-it'>
        <span className='didnot-title'>Didn&apos;t get it?</span>
        <div className='btn-resend' onClick={onResendLinkForConfirmEmail}>
          <span className='resend'>Resend</span>
        </div>
      </div>
      <SingleButton title='Okay' onClick={onClose} />
    </DrawerVerifyEmailContainer>
  );
}

DrawerVerifyEmail.propTypes = {
  email: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
