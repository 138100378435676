import React from 'react';
import PropTypes from 'prop-types';
import { DrawerSuccessfullyAddedContainer } from './style';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerSuccessfullyAdded({
  visible,
  onClose,
  activeCategory = 'Appetizers',
  amount,
}) {
  return (
    <DrawerSuccessfullyAddedContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <Box className='success-image' />
      <SingleText
        size={22}
        align='center'
        weight={500}
        spacing='0.42px'
        lineHeight='34px'
        marginLeft='auto'
        marginRight='auto'
        marginBottom={44}
        width={200}
      >
        {amount} items added to &quot;{activeCategory}&quot;
      </SingleText>
      <SingleButton title='Okay' onClick={onClose} />
    </DrawerSuccessfullyAddedContainer>
  );
}

DrawerSuccessfullyAdded.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  activeCategory: PropTypes.string,
  amount: PropTypes.number,
};
