import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { FloorDropdownContainer } from './style';
import { Floors } from 'feasttt/domain';
import { useBinaryState } from 'hooks';

export function FloorDropdown({ selectedFloor, onSelectFloor, floors }) {
  const {
    isActive: isDropdownOpen,
    activate: openDropdown,
    deactivate: closeDropdown,
  } = useBinaryState();

  const onToggle = (isOpen) => {
    if (isOpen) {
      openDropdown();
    } else {
      closeDropdown();
    }
  };

  const isSelectedFloorHaveAvailableTables = () => {
    const availableFloor = Floors.createAvailableFloor(selectedFloor);
    return availableFloor.isFloorHaveAvailableTables();
  };

  return (
    <FloorDropdownContainer>
      <Dropdown onToggle={onToggle}>
        <Dropdown.Toggle>
          <div
            className={`left-part ${
              isSelectedFloorHaveAvailableTables() ? 'active' : 'inactive'
            }`}
          >
            <i className='fas fa-circle' />
            <span>{selectedFloor ? selectedFloor.name : ''}</span>
          </div>
          <div className='right-part'>
            <i
              className={
                isDropdownOpen ? 'fas fa-angle-up' : 'fas fa-angle-down'
              }
            />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className='floor-menu'>
          {floors.map((floor, i) => {
            const availableFloor = Floors.createAvailableFloor(floor);

            return (
              <Dropdown.Item
                className={
                  availableFloor.isFloorHaveAvailableTables()
                    ? 'have-tables'
                    : 'empty'
                }
                key={`floorlistkeys${i}`}
                eventKey={i}
                onClick={() => onSelectFloor(floor)}
              >
                <i className='fas fa-circle' />
                <span>{floor.name}</span>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </FloorDropdownContainer>
  );
}

FloorDropdown.propTypes = {
  selectedFloor: PropTypes.object,
  floors: PropTypes.array,
  onSelectFloor: PropTypes.func,
};
