import styled from '@emotion/styled';

export const RequestPendingContainer = styled.div`
  display: flex;
  .wrapper {
    padding-top: 2rem;
    padding-right: 2rem;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #ed2626;
    width: 15.5rem;
    text-align: center;
    border-radius: 30px;
  }

  a.nav-link {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-weight: 600;
    width: 15.5rem;
    text-align: center;
  }

  .nav {
    background: #1b1c1d;
    border-radius: 30px;
    width: 31rem;
    display: flex;
    justify-content: space-between;
    margin-left: 2em;
  }

  .flex-inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 2em;
  }

  .request-text {
    font-size: 24px;
    color: #ffffff;
    font-weight: bold;
  }

  .line-height {
    line-height: 8px;
  }

  .avg-waiting-text {
    font-size: 13px;
    color: #ffffff;
    opacity: 0.6;
  }

  .underscore {
    color: #ffffff;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
  }

  .avg-waiting-today {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;

    .fa-clock {
      margin-right: 11px;
    }
  }

  .sidebar-wrapper {
    box-shadow: 0 -2px 22px 0 rgba(0, 0, 0, 0.2);
    background-color: #151617;
    height: 100vh;
    margin-left: 10px;
    position: relative;
    width: 30%;
    max-width: 450px;
    min-width: 450px;
  }

  .sidebar-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    opacity: 0.6;
  }
`;
