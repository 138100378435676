import { useState } from 'react';
import { Views } from './Views';

const views = new Views();

export const useLayoutListSwitchView = () => {
  const [view, setView] = useState(views.getInitialView());

  const changeToLayoutView = () => setView(views.getLayoutView());
  const changeToListView = () => setView(views.getListView());

  const isLayoutViewActive = () => views.isEqualToLayoutView(view);
  const isListViewActive = () => views.isEqualToListView(view);

  return {
    view,
    changeToLayoutView,
    changeToListView,
    isLayoutViewActive,
    isListViewActive,
  };
};
