import styled from '@emotion/styled'
// import { colors } from 'theme';

export const SingleLocationSearchContainer = styled.div`
  position: relative;
  .title-container{
    margin-bottom: 5px;
    padding-left: 2px;
    span{
      font-size: 12px;
      color: rgba(255,255,255,0.6);
    }
  }
  .address-container{
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    padding: 5px 0;
      .address-input{
      flex: 1 1 auto;
      background-color: transparent;
      border: unset;
      outline: unset;
      font-size: 14px;
      color: #ffffff;
    }
    .clear-address{
      height: 100%;
      padding: 0 20px;
      color: #fff;
      font-size: 14px;
      .fa-times{
        font-weight: 500;
      }
    }
  }
  .suggestions{
    position: absolute;
    width: 100%;
    margin-top: 5px;
    border-radius: 25px;
    box-shadow: 1px 2px 15px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #525558;
    background-color: #1b1c1d;
    color: #fff;
    padding: 10px 15px;
    z-index: 1;
    .suggestion-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      cursor: pointer;
      .suggestion-main{
        font-size: 14px;
        font-weight: 600;
      }
      .suggestion-secondary{
        font-size: 14px;
        font-weight: 500;
        color: rgba(255,255,255,0.6);
      }
    }
  }
`;
