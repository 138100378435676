import React from 'react'
import PropTypes from 'prop-types';
import { DrawerPreventDeleteFloorContainer } from './style';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerPreventDeleteFloor({
  visible = false,
  placement = 'bottom',
  onClose = () => { },
  returnBack = () => {},
}) {

  // @TODO Remade exit flow from this drawer
  const close = () => {
    onClose();
    returnBack();
  };

  return (
    <DrawerPreventDeleteFloorContainer
      placement={placement}
      onClose={onClose}
      height='fit-content'
      visible={visible}
    >
      <Box is='div' display='flex' flexDirection='column' alignItems='flex-start' marginBottom={36}>
        <SingleText size={22} weight={500} marginBottom={10}>Remove Floor</SingleText>
        <SingleText
          size={14}
          lineHeight={1.5}
          spacing='0.27px'
          marginBottom={0}
        >
          We’re sorry, this Floor has at least one table that is currently occupied.
          Please wait for the guest(s) to checkout before trying to remove this floor again.
        </SingleText>
      </Box>
      <Box is='div' display='flex' flexDirection='column'>
        <SingleButton
          title='Okay'
          margin='0 0 23px'
          onClick={close}
        />
      </Box>
    </DrawerPreventDeleteFloorContainer>
  )
}

DrawerPreventDeleteFloor.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  returnBack: PropTypes.func,
};
