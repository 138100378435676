import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerRolesContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  .drawer-role--options {
    display: flex;
    flex-direction: column;
    align-items: center;
    .drawer-role--option {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 22px 26px;
      border-radius: 16px;
      border: solid 1px rgba(255, 255, 255, 0.1);
      &.active {
        border: solid 1px rgba(255, 255, 255, 1);
        transition: 0.5s ease;
      }
      &.inactive {
        border: solid 1px rgba(255, 255, 255, 0.1);
        transition: 0.5s ease;
        cursor: pointer;
      }
    }
  }
`;

export const SpaceStaff = styled.div`
  display: flex;
  flex-direction: column;
  .drawer-role--option{
    margin-bottom: 15px;
  }
`;
