import styled from 'styled-components';

export const NoItemsYetContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: '#ffffff';

  .center-core {
    text-align: center;

    .no-items-title {
      font-size: 24px;
      font-weight: bold;
      color: white;
      margin-bottom: 15px;
    }

    .no-items-action {
      font-size: 16px;
      font-weight: 500;
      color: white;
    }

    .no-items-yet-img {
      width: 315px;
      height: 347px;
      margin-top: 124px;
      margin-bottom: 10px;
      background-position: center;
      background-size: 100% 92%;
      background-repeat: no-repeat;
    }
  }
`;
