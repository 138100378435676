export class Status {
  completed;

  constructor(completed) {
    this.completed = completed;
  }

  isCompleted() {
    return this.completed === true;
  }
}
