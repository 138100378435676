import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerBusinessHoursWrapper = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  .business-hours-panel {
    font-family: Montserrat;
    .panel-header-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      .back-arrow {
        width: 50px;
        cursor: pointer;
        position: absolute;
        left: 0;
        i {
          color: #ffffff;
          font-size: 22px;
        }
      }
      .panel-header-context {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .panel-status-container {
      margin-bottom: 20px;
      margin-top: 70px;
      .dates-container,
      .closed-dates-container {
        .day-of-week-container {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 5px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          .left-part {
            p {
              margin-bottom: 0;
            }
            .day-of-week {
              font-size: 14px;
              color: rgba(255, 255, 255, 0.5);
            }
            .set-time {
              font-size: 14px;
              font-weight: 500;
              color: #ed2626;

              &.selection-done {
                font-size: 14px;
                font-weight: 500;
                color: #fff;
              }
            }
          }

          .right-part {
            i {
              font-size: 20px;
              color: rgba(255, 255, 255, 0.2);
            }
          }
        }
      }
      .closed-dates-container {
        margin-top: 70px;
      }
    }

    .button-container {
      margin-top: 300px;
      padding-bottom: 25px;
      .done-button {
        height: 50px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.4);
        border: none;
        &.active {
          background-color: #ed2626;
          color: #fff;
        }
      }
    }
  }
`;
