import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerAddBankAccountContainerStyled } from './style';
import { DrawerAddBankAccount } from './DrawerAddBankAccount';
import { DrawerBankAccountAdded } from './DrawerBankAccountAdded';
import { DrawerAddBankAccountError } from './DrawerAddBankAccountError';

export function DrawerAddBankAccountContainer({
  visible,
  onClose,
  addBankAccount,
}) {
  const [openDrawerAddBankAccount, setOpenDrawerAddBankAccount] =
    useState(false);
  const onOpenDrawerAddBankAccount = () => setOpenDrawerAddBankAccount(true);
  const onCloseDrawerAddBankAccount = () => setOpenDrawerAddBankAccount(false);

  const [openDrawerBankAccountAdded, setOpenDrawerBankAccountAdded] =
    useState(false);
  const onOpenDrawerBankAccountAdded = () =>
    setOpenDrawerBankAccountAdded(true);
  const onCloseDrawerBankAccountAdded = () =>
    setOpenDrawerBankAccountAdded(false);

  const [openDrawerAddBakAccountError, setOpenDrawerAddBankAccountError] =
    useState(false);

  const onOpenDrawerAddBankAccountError = () =>
    setOpenDrawerAddBankAccountError(true);
  const onCloseDrawerAddBankAccountError = () =>
    setOpenDrawerAddBankAccountError(false);

  const close = () => {
    onCloseDrawerAddBankAccount();
    onCloseDrawerBankAccountAdded();
    onCloseDrawerAddBankAccountError();
    onClose();
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerAddBankAccount();
    }
  }, [visible]);

  return (
    <DrawerAddBankAccountContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerAddBankAccount
        visible={openDrawerAddBankAccount}
        openSuccessDrawer={onOpenDrawerBankAccountAdded}
        openFailureDrawer={onOpenDrawerAddBankAccountError}
        onClose={close}
        addBankAccount={addBankAccount}
      />
      <DrawerBankAccountAdded
        visible={openDrawerBankAccountAdded}
        onClose={close}
      />
      <DrawerAddBankAccountError
        visible={openDrawerAddBakAccountError}
        onClose={close}
        onTryAgainClick={onCloseDrawerAddBankAccountError}
      />
    </DrawerAddBankAccountContainerStyled>
  );
}

DrawerAddBankAccountContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  addBankAccount: PropTypes.func,
};
