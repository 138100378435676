import styled from '@emotion/styled';
import { colors } from 'theme';

export const OrderNotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 35px;
  margin-bottom: 11px;
  border-radius: 20px;
  background-color: ${colors.gray5};

  .notification-top-part {
    display: flex;
    align-items: center;

    .checked-icon {
      width: 18px;
      height: 18px;
      margin-right: 17px;
      border-radius: 50%;
      background-color: white;

      i {
        font-size: 20px;
        line-height: 19px;
        letter-spacing: -0.46px;
        color: ${colors.lightGreen};
        position: relative;
        top: 0px;
        left: -1px;
      }
    }

    .notifications-total-container {
      margin-right: 20px;
      .notification-title-total {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: -0.28px;
        color: rgba(255, 255, 255, 0.3);
      }
    }

    .notification-title-price-container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .notification-title {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: -0.28px;
        color: rgba(255, 255, 255, 0.3);
      }

      .notification-price {
        font-size: 15px;
        letter-spacing: -0.36px;
        color: rgba(255, 255, 255, 0.18);
      }
    }

    .look-icon-container {
      cursor: pointer;
      margin-left: 20px;
      i {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.3);
        letter-spacing: 0.27px;
      }
    }
  }

  .description-container {
    margin-top: 12px;
    padding-left: 60px;
    p {
      width: 300px;
      margin-bottom: 0px;
      font-size: 15px;
      letter-spacing: -0.36px;
      color: rgba(255, 255, 255, 0.18);

      p {
        &:first-of-type {
          margin-bottom: 7px;
        }

        b {
          margin-right: 3px;
        }
      }
    }
  }
`;
