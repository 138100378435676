import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { DrawerOrderItemDeleteCheckWrapper } from './style';

const DrawerOrderItemDeleteCheck = ({
    visible = false,
    placement = 'bottom',
    // onClose = () => { },
}) => {
    const { setOpenOrderItemRemoved, setOpenDrawerOrderItemDeleteCheck, setOpenDrawerOrderItemDelete } = useContext(StateContext);
    const { orderItemForDelete, setOrderItemForDelete } = useContext(DataContext);

    function deleteChosenOrderItem() {
        setOrderItemForDelete('')
        setOpenDrawerOrderItemDelete(false);
        setOpenDrawerOrderItemDeleteCheck(false);
        setOpenOrderItemRemoved(true);
    }

    function closeMenus() {
        setOrderItemForDelete('')
        setOpenDrawerOrderItemDelete(false);
        setOpenDrawerOrderItemDeleteCheck(false);
    }

    return (
        <DrawerOrderItemDeleteCheckWrapper
            onClose={closeMenus}
            visible={visible}
            placement={placement}
            height="auto"
        >
            <div className={`delete-day-block`}>
                <div className="head-block">
                    <h3>Are you sure?</h3>
                </div>
                <p>
                    <b>{orderItemForDelete}</b> will be removed from this order, this can’t be undone.?
                </p>
                <button className="button-save" onClick={() => deleteChosenOrderItem()}>Yes, Remove Item</button>
                <button className="button-cancel" onClick={closeMenus}>No, Keep Item</button>
            </div>

        </DrawerOrderItemDeleteCheckWrapper>
    )
}

export default DrawerOrderItemDeleteCheck;

DrawerOrderItemDeleteCheck.propTypes = {
    visible: PropTypes.bool,
    placement: PropTypes.string,
    onClose: PropTypes.func,
};