import { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContextContainer';
import {
  StatusChanger,
  Readiness,
  StatusList,
  ProgressPercentage,
  OverallState,
  ReadyToPublishProfile,
  WelcomeProfile,
} from 'feasttt/domain';

export const useWelcomeFlow = () => {
  const { authUser, setAuthUser } = useContext(AuthContext);

  const overallState = new OverallState(authUser);
  const statusList = new StatusList(authUser);
  const progressPercentage = new ProgressPercentage(statusList.getList());
  const readiness = new Readiness(statusList.getList());
  const readyToPublishProfile = new ReadyToPublishProfile(authUser);
  const welcomeProfile = new WelcomeProfile(authUser);

  const getProgressPercentage = () =>
    progressPercentage.getProgressPercentage();

  const changeProfileStatus = () => {
    const profileChanger = new StatusChanger(authUser, 'profileCompleted');
    setAuthUser(profileChanger.changeStatusToCompleted());
  };

  const changeMenuStatus = () => {
    const menuChanger = new StatusChanger(authUser, 'menuCompleted');
    setAuthUser(menuChanger.changeStatusToCompleted());
  };

  const changeTableStatus = () => {
    const tableChanger = new StatusChanger(authUser, 'tableCompleted');
    setAuthUser(tableChanger.changeStatusToCompleted());
  };

  const changeBankStatus = () => {
    const bankChanger = new StatusChanger(authUser, 'bankCompleted');
    setAuthUser(bankChanger.changeStatusToCompleted());
  };

  const getAllStatuses = () => overallState.getState();

  const isReadyToPublish = () => readiness.isReady();

  const isPublished = () => welcomeProfile.isPublished();

  const publishProfile = () =>
    setAuthUser(readyToPublishProfile.publishProfile());

  return {
    getProgressPercentage,
    changeProfileStatus,
    changeMenuStatus,
    changeTableStatus,
    changeBankStatus,
    getAllStatuses,
    isReadyToPublish,
    isPublished,
    publishProfile,
  };
};
