import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerAddBankAccountContainer } from './style';
import { colors } from 'theme';
import { useBankAccount } from 'hooks';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { AccountInput } from './AccountInput';

export function DrawerAddBankAccount({
  visible,
  onClose,
  openSuccessDrawer,
  openFailureDrawer,
  addBankAccount,
}) {
  const {
    bankAccount,
    changeBankAccount,
    clearBankAccount,
    bankAccountFields,
    isReady,
    generateErrorMessage,
  } = useBankAccount({
    onAfterBankAccountUpdate: () => (!isTouched ? touch() : ''),
  });

  const [isTouched, setIsTouched] = useState(false);
  const touch = () => setIsTouched(true);
  const clearTouches = () => setIsTouched(false);

  const onAddBankAccount = () => {
    let isRequestSucceeded = true;

    try {
      tryAddBankAccount();
    } catch (_) {
      isRequestSucceeded = false;
    } finally {
      if (isRequestSucceeded) {
        openSuccessDrawer();
      } else {
        openFailureDrawer();
      }
    }
  };

  const tryAddBankAccount = () => {
    _errorWhenMakingServerRequest();
    addBankAccount(bankAccount);
  };

  // emulate request with error
  const _errorWhenMakingServerRequest = () => {
    const num = Math.floor(Math.random() * 68);
    if (num % 2 === 0) {
      throw new Error('error when making request');
    }
  };

  useEffect(() => {
    if (!visible && isTouched) {
      clearTouches();
      clearBankAccount();
    }
  }, [visible, isTouched, clearBankAccount]);

  return (
    <DrawerAddBankAccountContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
      customHeight={isTouched ? (isReady() ? 555 : 635) : 590}
    >
      <SingleText size={20} spacing='0.38px' align='center' marginBottom={43}>
        Add bank account
      </SingleText>
      {bankAccountFields.map(
        (
          [
            label,
            placeholder,
            key,
            formatValue,
            convertBack,
            isValid,
            specialTitle,
          ],
          index
        ) => (
          <AccountInput
            label={label}
            placeholder={placeholder}
            value={bankAccount[key] || ''}
            onChange={(newValue) => changeBankAccount({ [key]: newValue })}
            formatter={formatValue}
            convertBack={convertBack}
            specialTitle={specialTitle}
            isFilled={isValid()}
            isTouched={isTouched}
            key={index + label + placeholder}
          />
        )
      )}
      {isTouched && !isReady() ? (
        <div className='error-message-container'>
          <i className='fas fa-exclamation-circle' />
          <span className='error-message'>{generateErrorMessage()}</span>
        </div>
      ) : (
        ''
      )}
      <SingleButton
        title='Add bank account'
        margin={isReady() ? '23px 0px 20px' : '42px 0px 20px'}
        btnColor={
          isTouched && isReady() ? colors.redColor : 'rgba(255, 255, 255, 0.1)'
        }
        txtColor={isTouched && isReady() ? 'white' : 'rgba(255, 255, 255, 0.2)'}
        onClick={onAddBankAccount}
        disabled={!isTouched || !isReady()}
      />
      <SingleButton
        title='Cancel'
        btnColor='transparent'
        txtColor={colors.redColor}
        onClick={onClose}
      />
    </DrawerAddBankAccountContainer>
  );
}

DrawerAddBankAccount.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  openSuccessDrawer: PropTypes.func,
  openFailureDrawer: PropTypes.func,
  addBankAccount: PropTypes.func,
};
