import React, { useContext, useEffect, useState } from 'react';
import { restaurantServices } from 'services/api-services';
import { showMessage } from 'services/functions';
import PropTypes from 'prop-types';
import Box from 'components/Box';
import { FaChevronLeft } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerInviteStaffContainer } from './style';
import { colors } from 'theme';
import SingleAntFormatInput from 'components/SingleAntFormatInput';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';

export default function DrawerInviteStaff({
  visible = false,
  placement = 'right',
  onClose = () => { },
}) {

  const { setOpenDrawerInviteSent } = useContext(StateContext);
  const { restaurantId } = useContext(AuthContext);
  const { staffToAdd, setStaffToAdd } = useContext(DataContext)
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);

  const onClickSendInvite = async () => {
    const { ok, errMessage } = await restaurantServices.createStaff(restaurantId, { ...staffToAdd, phone_number: phoneNumber });
    if (ok) {
      setStaffToAdd({ ...staffToAdd, phone_number: phoneNumber });
      setOpenDrawerInviteSent(true);
    } else {
      showMessage(errMessage, 'error')
    }
  }

  const onChangePhoneNumber = (values) => {
    setPhoneNumber(values.value);
    setFormattedPhoneNumber(values.formattedValue);
  }

  useEffect(() => {
    if (phoneNumber.length > 9) setIsCompleted(true);
    else setIsCompleted(false);
  }, [phoneNumber]);

  return (
    <DrawerInviteStaffContainer
      onClose={onClose}
      visible={visible}
      placement={placement}
    >
      <Box display='flex' alignItems='center' marginBottom={64}>
        <SingleIconWrapper onClick={onClose}>
          <FaChevronLeft size={20} color='white' />
        </SingleIconWrapper>
        <Box display='flex' justifyContent='center' flex={1}>
          <SingleText content='Invite Staff' align='center' size={22} weight={500}>Invite Staff</SingleText>
        </Box>
      </Box>
      <Box display='flex' flexDirection='column'>
        <SingleText content='Invite someone to join your team.' weight={600} size={14} marginBottom={5}>Invite someone to join your team.</SingleText>
        <SingleText content='They’ll receive a text with instructions on how to log-in to their account.' lineHeight={1.5} size={14} marginBottom={66}>They’ll receive a text with instructions on how to log-in to their account.</SingleText>
        <SingleAntFormatInput
          placeholder='Enter their mobile number'
          height='40px'
          type='underline'
          value={formattedPhoneNumber}
          onChange={onChangePhoneNumber}
        />
      </Box>
      <Box display='flex' flexDirection='column' marginTop='auto'>
        <SingleButton
          title='Send Invite'
          btnColor={isCompleted ? colors.redColor : colors.gray3}
          txtColor={isCompleted ? 'white' : '#949697'}
          onClick={isCompleted ? onClickSendInvite : null}
        />
      </Box>
    </DrawerInviteStaffContainer>
  )
}

DrawerInviteStaff.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickSendInvite: PropTypes.func,
};
