import styled from '@emotion/styled';

export const ControlItemContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  padding: 16.7px;
  display: flex;
  justify-content: space-between;

  .main-title-container {
    display: flex;
    align-items: center;

    .icon-main-title {
      margin-right: 5px;
    }

    .main-title {
      font-size: 15px;
      font-weight: bold;
      color: white;
    }
  }

  .value-container {
    cursor: pointer;
    display: flex;
    align-items: center;

    .value-title {
      font-size: 13px;
      letter-spacing: 0.25px;
      color: rgba(255, 255, 255, 0.8);
      margin-right: 17px;
    }

    .look-container {
      height: 19px;
      i {
        font-size: 16px;
        letter-spacing: 0.3px;
        color: rgba(255, 255, 255, 0.3);
      }
    }
  }
`;
