import styled from '@emotion/styled';

export const AmountControlContainer = styled.div`
  margin-top: 70px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .control-button {
    cursor: pointer;
    width: 60px;
    height: 60px;
    padding: 17px 19px;
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 50%;

    &.minus {
      margin-right: 30px;
    }

    &.plus {
      margin-left: 26px;
    }

    i {
      font-size: 24px;
      letter-spacing: 0.46px;
      color: white;
    }
  }

  .control-display {
    width: 52px;
    text-align: center;

    .display-title {
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.46px;
      color: white;
    }
  }
`;
