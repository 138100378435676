import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReviewReturnFullrefundContainer } from './style';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';
import { CompletedAlertFullrefund } from './CompletedAlertFullRefund';

export function ReviewReturnFullrefund({ onClose }) {
  const context = useContext(modalContext);

  function reviewReturnBtn() {
    context.setModal('Return-Full-Refund-Modal-3');
  }

  return (
    <ReviewReturnFullrefundContainer>
      {context.modal === 'Return-Full-Refund-Modal-2' ? (
        <>
          <div className='return-review-fullrefund-wrapper animate__animated animate__fadeInUp'>
            <div>
              <p className='return-review-fullrefund-text'>Review return</p>
            </div>

            <div>
              <div className='return-review-fullrefund-border-padding'>
                <p className='return-review-fullrefund-title'>
                  Pineapple & Pork Medallions
                </p>
                <p
                  style={{ color: '#1fc086' }}
                  className='return-review-fullrefund-refund-rejected-text'
                >
                  Full Refund
                </p>
              </div>
              <div className='return-review-fullrefund-border-padding'>
                <p>Strawberry Daquiri</p>
                <p
                  style={{ color: '#1fc086' }}
                  className='return-review-fullrefund-refund-rejected-text'
                >
                  Partial Refund (70%)
                </p>
              </div>
              <div className='return-review-fullrefund-border-padding'>
                <p>Piña Colada</p>
                <p
                  style={{ color: '#ed2626' }}
                  className='return-review-fullrefund-refund-rejected-text'
                >
                  Rejected: Requested by mistake
                </p>
              </div>
            </div>

            <p className='return-review-fullrefund-refund'>
              $2.29 will be refunded.
            </p>

            <div className='return-review-fullrefund-btn-center'>
              <Button
                variant='none'
                className='return-review-fullrefund-btn-style'
                onClick={reviewReturnBtn}
              >
                <span className='return-review-fullrefund-btn-text'>
                  Complete Request
                </span>
              </Button>
            </div>

            <p
              className='return-review-fullrefund-go-back-text'
              onClick={onClose}
              aria-hidden='true'
            >
              Go Back
            </p>
          </div>
        </>
      ) : (
        <>
          <CompletedAlertFullrefund onClose={onClose} />
        </>
      )}
    </ReviewReturnFullrefundContainer>
  );
}

ReviewReturnFullrefund.propTypes = {
  onClose: PropTypes.func,
};
