import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerDeleteMenuFirstStepContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  padding: 36px 26px 33px;
  max-height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .question-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
  }
`;
