import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerAddItemToOrderContainer = styled(SingleDrawer)`
  background-color: gray;
  position: relative;
  padding: 0px;

  .close-icon {
    position: absolute;
    top: 36px;
    left: 26px;
    z-index: 103;
    cursor: pointer;

    i {
      font-size: 28px;
      font-weight: 400;
      letter-spacing: 0.53px;
      color: white;
    }
  }

  .blur-food-image-container {
    position: absolute;
    top: 0px;
    height: 340px;
    width: 388px;

    .blur-food-image {
      width: 100%;
      height: 340px;
      filter: blur(9px);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(${({ backgroundImage }) => backgroundImage});
    }
  }

  .food-image {
    width: 343px;
    height: 220px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${({ backgroundImage }) => backgroundImage});
    position: absolute;
    top: 96px;
    left: 25px;
    z-index: 102;
    border-radius: 8px;
  }

  .food-add-item {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: ${colors.gray2};
    margin-top: 250px;
    z-index: 11;
    padding: 87px 26px 33px;
    display: flex;
    flex-direction: column;

    .amount-control-container {
      margin-top: 25.5px;
      margin-bottom: 44px;

      .control-button {
        background-color: rgba(255, 255, 255, 0.2);
        padding: 18px 21px;

        i {
          font-weight: 400;
        }
      }
    }

    .btn-add-food-to-order {
      padding: 15px 16px;
      border: 1px solid transparent;
      border-radius: 8px;
      background-color: ${colors.redColor};
      outline: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .add-to-order-title {
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
        letter-spacing: -0.34px;
        color: white;
      }

      .add-to-order-price {
        margin-left: 50px;
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
        letter-spacing: -0.34px;
        color: white;
      }
    }
  }
`;
