export class StatusChanger {
  profile;
  key;

  constructor(profile, key) {
    this.profile = profile;
    this.key = key;
  }

  changeStatusToCompleted() {
    return {
      ...this.profile,
      [this.key]: true,
    };
  }
}
