import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { CategoriesTopListContainer, CarouselContainer } from './style';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import { CategoriesFetch } from './CategoriesFetch';
import { formatName } from 'services/functions';
import SingleButton from 'components/SingleButton';
import { colors } from 'theme';

export function CategoriesTopList() {
  const { category } = useParams();
  const { menus, chosenMenu, setSelectedMenu, isMenusEditing } =
    useContext(DataContext);
  const { allCategories, categories, setOpenDrawerMenuEdit } =
    useContext(StateContext);

  const [translateX, setTranslateX] = useState(0);
  const [iterableCategories, setIterableCategories] = useState([]);
  const [checkedCategory, setCheckedCategory] = useState(0);
  const [isCarousel, setIsCarousel] = useState(false);

  const wrapperRef = useRef();
  const itemsRef = useRef([]);

  const changeTranslateXRight = () => {
    const wrapperWidth = wrapperRef.current.getBoundingClientRect();
    const fringeItem = itemsRef.current[itemsRef.current.length - 1];
    // itemsRef.current.find((item, i) => {
    //   const itemRect = item.getBoundingClientRect();
    //   return itemRect.left > wrapperWidth.width;
    // });
    if (fringeItem)
      fringeItem.scrollIntoView({
        alignToTop: false,
        behavior: 'smooth',
        block: 'end',
      });
    // calculate the fringe element
  };

  const changeTranslateXLeft = () => {
    const fringeItem = itemsRef.current[0];
    if (fringeItem)
      fringeItem.scrollIntoView({
        alignToTop: false,
        behavior: 'smooth',
        block: 'end',
      });
  };

  useEffect(() => {
    if (wrapperRef.current && itemsRef.current && itemsRef.current.length) {
      const wrapperWidth = wrapperRef.current?.clientWidth ?? null;
      if (wrapperWidth) {
        let elementsWidth = 0;
        itemsRef.current.forEach((item) => {
          elementsWidth += item?.clientWidth ?? 0;
        });

        setIsCarousel(elementsWidth > wrapperWidth);
      }
    }
  }, [iterableCategories, allCategories, menus, checkedCategory]);

  useEffect(() => {
    if (!category) {
      setIterableCategories([
        { name: 'Appetizers' },
        { name: 'Soups' },
        { name: 'Main Entrees' },
        { name: 'Desserts' },
        { name: 'Drinks' },
      ]);
    } else {
      setIterableCategories(categories);
    }

    if (checkedCategory !== Number(category) && category !== undefined) {
      setCheckedCategory(Number(category));
    }
  }, [
    category,
    categories,
    allCategories,
    menus,
    checkedCategory,
    setCheckedCategory,
  ]);

  const onClickEditMenu = () => {
    const currentMenu = allCategories.find(
      (category) => category.id === chosenMenu
    );
    setSelectedMenu(currentMenu);
    setOpenDrawerMenuEdit(true);
  };

  const scroll = (i) => {
    let element = document.getElementById(`list-category-${i}`);
    if (element) element.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <CarouselContainer className='carousel' ref={wrapperRef}>
      {isMenusEditing && (
        <SingleButton
          height={42}
          className='edit-icon'
          onClick={() => onClickEditMenu()}
          title={<i className='fal fa-pencil' />}
          shape='circle'
          txtColor='white'
          width={40}
          btnColor={colors.redColor}
        />
      )}
      {isCarousel && (
        <button
          className='nav-button category-label-container'
          onMouseDown={changeTranslateXLeft}
        >
          <i className='far fa-angle-left' />
        </button>
      )}
      <div className='container'>
        <CategoriesTopListContainer
          className='categories-container'
          style={{ transform: `translateX(${translateX}%)` }}
        >
          {iterableCategories.length &&
            iterableCategories.map((_category, _i) => (
              <Box
                ref={(el) => (itemsRef.current[_i] = el)}
                key={_i}
                id={`item-${_i}`}
                className={`category-label-container ${
                  checkedCategory === _category.id ? 'selected' : ''
                }`}
                onClick={() => scroll(_i)}
              >
                <SingleText
                  width='max-content'
                  spacing='0'
                  lineHeight='14px'
                  weight={600}
                >
                  {formatName(_category.name)}
                </SingleText>
              </Box>
            ))}
          <CategoriesFetch allCategories={allCategories} />
        </CategoriesTopListContainer>
      </div>
      {isCarousel && (
        <button
          className='nav-button category-label-container'
          onMouseDown={changeTranslateXRight}
        >
          <i className='far fa-angle-right' />
        </button>
      )}
    </CarouselContainer>
  );
}

CategoriesTopList.propTypes = {
  onChangeChosenMenu: PropTypes.func,
};
