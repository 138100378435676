import styled from '@emotion/styled';
import Dropdown from 'react-bootstrap/Dropdown';
import { colors } from 'theme';

export const BankAccountTopDropdownContainer = styled(Dropdown)`
  cursor: pointer;

  .bank-account-more {
    padding: 0px;
    background-color: transparent !important;
    border-color: transparent !important;
    outline: none;

    i {
      font-size: 24px;
      color: white;
    }

    &:after {
      display: none;
    }

    &:active {
      background-color: transparent !important;
    }
  }

  .bank-account-dropdown-options {
    padding: 17px 26px;
    border-radius: 25px;
    background-color: ${colors.gray5};

    .dropdown-item {
      padding: 0px;
      margin-bottom: 30px;

      &:hover {
        background-color: transparent;
      }

      &:focus {
        background-color: transparent;
      }

      &:last-of-type {
        margin-bottom: 0px;
      }

      .bank-account-dropdown-item {
        display: flex;
        align-items: center;

        i {
          font-size: 14px;
          color: white;
          letter-spacing: 0;
          margin-right: 19px;
        }

        .title {
          font-size: 14px;
          letter-spacing: 0;
          color: white;
        }
      }
    }
  }
`;
