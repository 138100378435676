import styled from '@emotion/styled';
import { colors } from 'theme';

export const RowCategoriesControlContainer = styled.div`
  display: flex;
  align-items: center;

  .row-category-container {
    padding: 13px 23px;
    background-color: ${colors.gray2};
    border-radius: 25px;
    margin-right: 16px;

    &.active {
      background-color: ${colors.gray5};
    }

    .row-category-name-title {
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0;
      color: white;
    }
  }
`;
