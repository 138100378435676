export class SubPages {
  static pages = {
    pending: 'pending',
    addItem: 'addItem',
    active: 'active',
    completed: 'completed',
  };

  static allSubPages = [
    SubPages.pages.pending,
    SubPages.pages.addItem,
    SubPages.pages.active,
    SubPages.pages.completed,
  ];

  static initialPage() {
    return SubPages.pages.pending;
  }

  static getPendingPage() {
    return SubPages.pages.pending;
  }

  static getAddItemPage() {
    return SubPages.pages.addItem;
  }

  static getActivePage() {
    return SubPages.pages.active;
  }

  static getCompletedPage() {
    return SubPages.pages.completed;
  }

  static isPageEqual(firstPage, secondPage) {
    if (
      SubPages.allSubPages.includes(firstPage) &&
      SubPages.allSubPages.includes(secondPage)
    ) {
      return firstPage === secondPage;
    }

    return false;
  }
}
