import styled from '@emotion/styled';

export const OrderToSeeContainer = styled.div`
  margin-left: 85px;
  position: relative;
  top: -80px;
  width: 30%;
  max-width: 450px;
  min-width: 450px;

  .order-notification-item {
    &.inactive {
      .checked-icon {
        display: none;
      }

      .notifications-total-container {
        .notification-title-total {
          color: white;
        }
      }

      .notification-title-price-container {
        .notification-title {
          color: white;
        }
      }

      .description-container {
        padding-left: 25px;
      }
    }
  }

  .select-order-when-everything-is-empty {
    display: flex;
    justify-content: flex-end;
    margin-right: 153px;
    padding-top: 50vh;

    .select-order-title {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.6);
      letter-spacing: 0;
    }
  }
`;
