import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerOrderReadyContainerStyled } from './style';
import { DrawerOrderReady } from './DrawerOrderReady';
import { DrawerServerNotified } from './DrawerServerNotified';

export function DrawerOrderReadyContainer({ visible, onClose }) {
  const [openDrawerOrderReady, setOpenDrawerOrderReady] = useState(false);
  const onOpenDrawerOrderReady = () => setOpenDrawerOrderReady(true);
  const onCloseDrawerOrderReady = () => setOpenDrawerOrderReady(false);

  const [openDrawerServerNotified, setOpenDrawerServerNotified] =
    useState(false);
  const onOpenDrawerServerNotified = () => setOpenDrawerServerNotified(true);
  const onCloseDrawerServerNotified = () => setOpenDrawerServerNotified(false);

  const [previousId, setPreviousId] = useState(undefined);
  const changePreviousStatus = (id) => setPreviousId(id);

  useEffect(() => {
    if (visible) {
      onOpenDrawerOrderReady();
    } else {
      changePreviousStatus(undefined);
    }
  }, [visible]);

  const close = () => {
    onCloseDrawerServerNotified();
    onCloseDrawerOrderReady();
    onClose();
  };

  const onOrderReady = (previous_id) => {
    changePreviousStatus(previous_id);
    onCloseDrawerOrderReady();
    onOpenDrawerServerNotified();
  };

  return (
    <DrawerOrderReadyContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerOrderReady
        visible={openDrawerOrderReady}
        onClose={close}
        onOrderReady={onOrderReady}
      />
      <DrawerServerNotified
        visible={openDrawerServerNotified}
        onClose={close}
        previousId={previousId}
      />
    </DrawerOrderReadyContainerStyled>
  );
}

DrawerOrderReadyContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
