import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerBusinessHoursClosedDaysWrapper = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  .closed-days-panel {
    font-family: Montserrat;
    .panel-header-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      .back-arrow {
        width: 50px;
        cursor: pointer;
        position: absolute;
        left: 0;
        i {
          color: #ffffff;
          font-size: 22px;
        }
      }
      .panel-header-context {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
      }
      .add-new-day {
        width: 20px;
        cursor: pointer;
        position: absolute;
        right: 0px;
        i {
          color: #ed2626;
          font-size: 20px;
        }
      }
    }
    .panel-status-container {
      margin-bottom: 20px;
      margin-top: 70px;

      .closed-day-item {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 5px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        .left-part {
          p {
            margin-bottom: 0;
          }
          .day-date {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);
            span {
              border-radius: 10.5px;
              background-color: #525558;
              font-size: 12px;
              color: #ffffff;
              padding: 2px 10px;
              margin-left: 10px;
            }
          }
          .day-description {
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
          }
        }
        .right-part {
          i {
            color: rgba(255, 255, 255, 0.2);
            font-size: 20px;
          }
        }
      }
    }
  }
`;
