import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBarContainer } from './style';

export const PROGRESS_BAR_MAX_PERCENTAGE = 100;

export function ProgressBar({ percentage }) {
  return (
    <ProgressBarContainer
      className={percentage === PROGRESS_BAR_MAX_PERCENTAGE ? 'full' : ''}
      percentage={percentage === 0 ? 2 : percentage}
    >
      <div className='progress-bar-container' />
      <span className='percentage-completed'>{percentage}% Complete</span>
    </ProgressBarContainer>
  );
}

ProgressBar.propTypes = {
  percentage: PropTypes.number,
};
