import { TableItem } from '../TableItem';
import { Size } from './Size';

export class TableSize extends TableItem {
  square = 1;
  circle = 0;
  verticalRectangleSize = new Size(48, 103);
  horizontalRectangleSize = new Size(103, 48);
  commonSmallSize = new Size(50, 50);
  circleMiddleSize = new Size(80, 80);

  // -------------------------
  verticalRectangleSize8Seats = new Size(48, 103);
  // horizontalRectangleSize8Seats = new Size(103, 48);

  verticalRectangleSize12Seats = new Size(48, 103);
  // horizontalRectangleSize12Seats = new Size(206, 98);
 // -----------------------

  computeTableSize() {
    const { seats } = this.table;

    if (seats <= 4) {
      return this.commonSmallSize.getSize();
    } 
    else {
      if (this.isSquare()) {
        if (this.isVertical()) {
          // -----------------
           if(seats <= 8) {
             return this.verticalRectangleSize8Seats.getSize();
           }
           if(seats <= 12) {
            return this.verticalRectangleSize12Seats.getSize();
           }
          // ----------------
          return this.verticalRectangleSize.getSize();
        } else {
          return this.horizontalRectangleSize.getSize();
        }
      } else {
        return this.circleMiddleSize.getSize();
      }
    }

  }

  isSquare() {
    return this.table.type === this.square;
  }

  isCircle() {
    return this.table.type === this.circle;
  }

  isVertical() {
    return this.table.rotation === 0;
  }

  isHorizontal() {
    return this.table.rotation === 90;
  }
}
