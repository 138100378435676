import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerUpdateValueContainer = styled(SingleDrawer)`
  padding: 36px 26px 33px;
  height: 366px !important;
  background-color: ${colors.gray5};
  display: flex;
  flex-direction: column;

  .value-container {
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 46px;
    display: flex;
    flex-direction: column;

    .value-label {
      font-size: 14px;
      line-height: 22px;
      color: rgba(255, 255, 255, 0.5);
      letter-spacing: -0.34px;
    }

    .input-container {
      width: 100%;
      display: flex;
      align-items: center;

      .before-input-title {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.34px;
        color: white;
        margin-right: 6px;
      }

      .value-input {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.34px;
        color: white;
        padding-left: 0px;
        background-color: transparent;
        border-color: transparent;
        outline: none;
        border-left-width: 0px;
        border-right-width: 0px;
      }

      .single-ant-format-input {
        border-color: transparent;
        border-radius: 0px;
        background-color: transparent;
        padding-left: 0px;
        height: 28px;
      }
    }
  }
`;
