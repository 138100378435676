import styled from '@emotion/styled';

import { OopsAddingFailed } from 'assets/images/menu';

export const EmptyBankAccountPlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .empty-bank-account-image {
    width: 150px;
    height: 92px;
    margin-bottom: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${OopsAddingFailed});
  }
`;
