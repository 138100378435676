import styled from '@emotion/styled'
import { colors } from 'theme';

export const SingleAntFormatInputContainer = styled.div`
  .single-ant-format-input {
    width: 100%;
    height: ${props => props.height};
    border-radius: ${props => props.type === 'outline' ? '7px' : '0px'};
    border: ${props => props.type === 'outline' ? 'solid' : 'none'} 1px rgba(255, 255, 255, 0.3);
    border-bottom: solid 1px rgba(255, 255, 255, 0.3);
    background-color: ${colors.gray2};
    color: white;
    font-family: Montserrat;
    font-size: 14px;
    box-shadow: unset !important;
    padding-left: ${props => props.type === 'outline' ? '11px' : '0px'};
    outline: unset;
    &::placeholder {
      opacity: 0.5;
    }
    &:hover, &:focus {
      border-color: white;
    }
  }
`;
