import React from 'react';
import PropTypes from 'prop-types';
import { DrawerDeleteAccountContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerDeleteAccount({ visible, onClose, onDelete }) {
  return (
    <DrawerDeleteAccountContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <SingleText
        className='your-account-will-be-deleted'
        size={22}
        spacing='0.42px'
        align='center'
        marginBottom={10}
        weight='500'
      >
        Delete my account
      </SingleText>
      <SingleText spacing='0.27px' lineHeight='21px' align='center'>
        Your account and order history will be permanently deleted.
      </SingleText>
      <SingleButton
        title='Delete my account'
        margin='31px 0px 20px'
        onClick={onDelete}
      />
      <SingleButton
        title='Keep my account'
        btnColor={colors.lightGray}
        onClick={onClose}
      />
    </DrawerDeleteAccountContainer>
  );
}

DrawerDeleteAccount.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};
