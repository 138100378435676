import styled from '@emotion/styled';

import { OopsAddingFailed } from 'assets/images/menu';

export const NoActiveOrdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 13.75%;
  margin-left: 20.35%;

  .no-active-orders-placeholder-image {
    width: 412px;
    height: 237px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${OopsAddingFailed});
  }
`;
