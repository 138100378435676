import styled from '@emotion/styled';

export const DiscountTypeValueContainer = styled.div`
  width: 100%;
  padding: 22px 33px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .type-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 50%;


    &:first-of-type {
      align-items: flex-start;
    }

    &:last-of-type {
      align-items: flex-end;
    }

    &.active {
      .type-title,
      .type-value {
        color: white;
      }

      .type-value-container {
        input {
          color: white;
        }
      }
    }

    .type-title,
    .type-value {
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.34px;
      color: rgba(255, 255, 255, 0.5);
    }

    .type-title {
      margin-bottom: 3px;
    }

    .type-value-container {
      display: flex;
      align-items: baseline;

      &.percents {
        input {
          text-align: right;
        }
      }

      input {
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        background-color: transparent;
        border: 0px solid transparent;
        outline: none;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 4px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
`;
