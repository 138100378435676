export const fonts = {
  roboto: 'Roboto',
  mono: 'Menlo, Monaco, monospace',
  main: 'Montserrat',
};

export const colors = {
  green: '#1ece6d',
  redColor: '#ED2626',
  mainColor: '#3C3D3C',
  border: '#525558',
  black1: '#151617',
  lightGray: '#61646c',
  lightGray2: '#bbbbbb',
  gray: '#60646c',
  gray1: '#151617',
  gray2: '#1b1c1d',
  gray3: '#515457',
  gray5: '#27282a',
  gray6: '#767b81',
  gray7: '#8d8d8e',
  gray8: '#8c8d8d',
  gray9: '#8f949a',
  gray10: '#767677',
  gray11: '#5b5e61',
  gray12: '#323334',
  gray13: '#272829',
  gray14: '#353536',
  lightGreen: '#1fc086',
  lightOrange: '#f7a64c',
  orange: '#ffa55a',
  orange1: '#d6a67c',
  yellow: '#ffca5b',
  rating: '#fdcb6e',
  rating2: '#ffd35f',
  emptyRating: '#37383a',
  white3: 'rgba(255, 255, 255, 0.3)',
  dark1: 'rgba(0, 0, 0, 0.1)',
};

export const mixins = {
  drawerWidth: '395px',
};
