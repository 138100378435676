import styled from '@emotion/styled';
import { colors } from 'theme';

export const PeriodCalendarContainer = styled.div`
  background-color: transparent;

  .period-calendar {
    .calendar-container {
      padding-left: 0px;
      padding-right: 0px;

      .calendar-month {
        padding-top: 0px;
        padding-left: 30px;
        padding-right: 30px;
      }

      .calendar-core {
        border-collapse: collapse !important;
        .calendar-head {
          th {
            padding: 0px;
          }
        }

        .calendar-body {
          tr {
            td {
              border: none !important;
              padding: 5px 0px 0px 0px;

              .empty-day {
                width: 32px;
                height: 32px;
              }

              .regular-day {
                width: 32px;
                height: 32px;
                padding: 4px;
                border-radius: 9px;

                &.today {
                  border-color: transparent;
                }

                &:hover {
                  border: 1.5px solid ${colors.redColor};
                }

                &.part-of-range {
                  background-color: ${colors.redColor};
                  border-color: transparent;
                  border-radius: 0px;

                  &.start-range {
                    border-top-left-radius: 9px;
                    border-bottom-left-radius: 9px;
                  }

                  &.end-range {
                    border-top-right-radius: 9px;
                    border-bottom-right-radius: 9px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
