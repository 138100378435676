import React from 'react';
import PropTypes from 'prop-types';
import { AmountControlContainer } from './style';

// empty component for implementing amount control flow logic & view
// @TODO
export function AmountControl({
  total = 0,
  decreaseTotal = () => {},
  increaseTotal = () => {},
}) {
  return (
    <AmountControlContainer className='amount-control-container'>
      <div className='control-button minus' onClick={decreaseTotal}>
        <i className='fas fa-minus' />
      </div>
      <div className='control-display'>
        <span className='display-title'>{total}</span>
      </div>
      <div className='control-button plus' onClick={increaseTotal}>
        <i className='fas fa-plus' />
      </div>
    </AmountControlContainer>
  );
}

AmountControl.propTypes = {
  total: PropTypes.number,
  decreaseTotal: PropTypes.func,
  increaseTotal: PropTypes.func,
};
