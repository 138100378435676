import React, { useState } from 'react';
import { CategoriesChangesContainer, ButtonsWrapper } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function CategoriesChanges({ onChangeMenuStatus }) {
  const [isChangeStatus, setIsChangeStatus] = useState(false);
  return (
    <CategoriesChangesContainer
      paddingTop={isChangeStatus ? '20px' : '9px'}
      borderRadius={isChangeStatus ? '27px' : '50px'}
    >
      {isChangeStatus
        ?
        <>
          <p className="status-title">Menu status will be changed to Active.
          </p>
          <SingleText spacing='-0.34px' marginRight={35} lineHeight={1.5}>
            Customers will be able to browse this menu in the Feasttt app.<br />
            You will be able to change status in the Menu Settings anytime.
          </SingleText>
          <ButtonsWrapper>
            <SingleButton
              title='Cancel'
              btnColor='rgb(255 255 255 / 20%)'
              txtColor='white'
              height='50px'
              onClick={() => setIsChangeStatus(false)}
            />
            <SingleButton
              title='Set to Active'
              btnColor='white'
              txtColor={colors.redColor}
              height='50px'
              onClick={() => onChangeMenuStatus()}
            />
          </ButtonsWrapper>
        </>
        :
        <>
          <SingleText spacing='-0.34px' marginRight={35}>
            <i className='fas fa-info-circle' />
            Menu not visible to customers
          </SingleText>
          <SingleButton
            shape='rounded'
            width='196px'
            title='Set to Active'
            btnColor='white'
            txtColor={colors.redColor}
            height='40px'
            onClick={() => setIsChangeStatus(true)}
          />
        </>}
    </CategoriesChangesContainer>
  );
}
