import { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContextContainer';
import { authServices } from 'services/api-services';
import { showMessage } from 'services/functions';
import { clearJwt } from 'services/jwt';

export const useLogOut = ({
  onOkLogOut,
  onFailLogOut,
  onBeforeLogOut = () => {},
  onAfterLogOut = () => {},
}) => {
  const { clearCredentials } = useContext(AuthContext);

  const onLogOutSuccess = () => {
    onAfterLogOut();
    clearJwt();
    clearCredentials();
    window.location.reload();
  };

  const onFail = (errMessage) => {
    showMessage(errMessage, 'error');
  };

  const onLogOut = async () => {
    onBeforeLogOut();
    return await authServices.logout(
      onOkLogOut || onLogOutSuccess,
      onFailLogOut || onFail
    );
  };

  return onLogOut;
};
