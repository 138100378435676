import styled from '@emotion/styled';

export const RejectOtherContainer = styled.div`
  .reject-other-wrapper {
    width: 100%;
    border-radius: 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #27282a;
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }

  .padding-wrap-1 {
    padding: 33px 20px 0 20px;
  }

  .padding-wrap-2 {
    padding: 9px 20px 20px 20px;
  }

  .reject-other-heading {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }

  .reject-other-comment-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
  }

  .comments {
    font-weight: bold;
  }

  .optional {
    color: #ffffff;
    opacity: 0.4;
  }

  .input-style {
    width: 100%;
    background-color: #e2e8e2;
    height: 65px;
    border: transparent;
    background-color: #27282a;
    color: #ffffff;
    padding: 33px 26px 36px;
  }

  input:focus {
    outline: none;
  }

  input::-webkit-input-placeholder {
    color: #636363;
    font-size: 14px;
  }

  .reject-other-reject-request-btn {
    width: 100%;
    padding: 13px 0 13px 0;
    border-radius: 8px;
    color: white;
    background: #ed2626;
    border: none;
    font-size: 14px;
    font-weight: 600;
    margin: 35px 0 20px;
  }

  .reject-other-keep-btn {
    width: 100%;
    padding: 13px 0 13px 0;
    border-radius: 8px;
    color: white;
    background: #61646c;
    border: none;
    font-size: 14px;
    font-weight: 600;
  }

  .border-top-style {
    border-top: 1px solid #424242;
  }
`;
