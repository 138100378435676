export class ProgressPercentage {
  completedItems = [];
  percentageForCalculation;

  constructor(completedItems) {
    this.completedItems = completedItems;
    this.percentageForCalculation = 100;
  }

  getProgressPercentage() {
    const completedItems = this.completedItems.filter(
      (isCompleted) => isCompleted
    );
    const allCompletedItems = completedItems.length;
    const allItems = this.completedItems.length;

    if (this.isItemsListNotEmpty(allItems)) {
      const percentsCompleted = Math.trunc(
        (allCompletedItems / allItems) * this.percentageForCalculation
      );
      return percentsCompleted;
    } else {
      return 0;
    }
  }

  isItemsListNotEmpty(list) {
    const emptyLength = 0;
    return list.length !== emptyLength;
  }
}
