import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ItemAssignCategoryContainer } from './style';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import { CategoryTop } from './CategoryTop';

export function ItemAssignCategory({
  category = '',
  selected = [],
  onSelect = () => { },
  checkIsSelected = () => { }
}) {
  const { categories: subCategories } = category;
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const selectedCount = (selected?.length && subCategories?.length) ? subCategories.filter(item => selected.includes(item.id)).length : 0
    setCounter(selectedCount);
  }, [selected])

  return (
    <ItemAssignCategoryContainer>
      <CategoryTop
        category={category.name}
        selected={counter}
        isEmpty={subCategories.length === 0}
      >
        {subCategories.length ? (
          subCategories.map((subCategory, index) => (
            <Box
              key={index}
              className={`sub-category-container ${checkIsSelected(subCategory.id) ? 'selected' : ''
                }`}
              onClick={() => onSelect(subCategory)}
            >
              <SingleText spacing='0.27px'>{subCategory.name}</SingleText>
            </Box>
          ))
        ) : (
          <SingleText color='rgba(255, 255, 255, 0.3)'>
            There are no categories
          </SingleText>
        )}
      </CategoryTop>
    </ItemAssignCategoryContainer>
  );
}

ItemAssignCategory.propTypes = {
  category: PropTypes.string,
  selected: PropTypes.array,
  onSelect: PropTypes.func,
  checkIsSelected: PropTypes.func,
};
