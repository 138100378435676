import styled from '@emotion/styled';
import Box from 'components/Box';
import { colors } from 'theme';

export const ProfileHeaderContainer = styled(Box)`
  border-bottom: 1px solid ${colors.border};
  height: 88px;
  min-height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;

  .profile-log-out-container {
    cursor: pointer;
    width: initial;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .profile-log-out-icon {
      width: 20px;
      margin-top: 4px;
      margin-right: 9px;
      i {
        font-size: 20px;
        color: ${colors.redColor};
      }
    }
  }
`;
