import { useContext, useEffect, useState } from 'react';
import { showMessage } from 'services/functions';
import { restaurantServices } from 'services/api-services';
import { StateContext } from 'contexts/StateContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';

export const useFoods = () => {
  const { restaurantId } = useContext(AuthContext);
  const { setFoods } = useContext(StateContext);
  const [cloneFoods, setCloneFoods] = useState(undefined);

  useEffect(() => {
    const fetchAllFood = async () => {
      const onOk = ({ foods: restaurantFoods }) => {
        setCloneFoods(restaurantFoods);
      };

      const onFail = (errMessage) => {
        showMessage(errMessage, 'error');
      };

      return await restaurantServices.getRestaurantById(
        restaurantId,
        onOk,
        onFail
      );
    };

    if (cloneFoods === undefined) {
      fetchAllFood();
    }
  }, [cloneFoods, restaurantId]);

  useEffect(() => {
    if (cloneFoods !== undefined) {
      setFoods(cloneFoods);
    }
  }, [cloneFoods, setFoods]);
};
