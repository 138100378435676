import React, { useContext, useState } from 'react'
import DrawerRoles from './Staff/DrawerRoles';
import DrawerRolesToEdit from './Staff/DrawerRolesToEdit';
import DrawerInviteStaff from './Staff/DrawerInviteStaff';
import DrawerInviteSent from './Staff/DrawerInviteSent';
import DrawerStaff from './Staff/DrawerStaff';
import DrawerStatus from './Staff/DrawerStatus';
import DrawerDeleteStaff from './Staff/DrawerDeleteStaff';
import DrawerStaffDetails from './Staff/DrawerStaffDetails';
import DrawerSortReviews from './Staff/DrawerSortReviews';

import DrawerEditMenus from './Menu/Menus/DrawerEditMenus';
import DrawerMenu from './Menu/Menus/DrawerMenu';
import DrawerAvailabilities from './Menu/Menus/DrawerAvailabilities';
// import DrawerAvailabilityDay from './Menu/Menus/DrawerAvailabilityDay';
import DrawerAvailabilityDayCustom from './Menu/Menus/DrawerAvailabilityDayCustom'; // new item
import DrawerDeleteMenuContainer from './Menu/Menus/DrawerDeleteMenuContainer';
import { StateContext } from 'contexts/StateContextContainer';
import DrawerMoveAndDeleteMenu from './Menu/Menus/DrawerMoveAndDeleteMenu';

import DrawerCategories from './Menu/Categories/DrawerCategories';
import DrawerDeleteCategory from './Menu/Categories/DrawerDeleteCategory';
import DrawerMoveItems from './Menu/Categories/DrawerMoveItems';
import DrawerDeleteCategoryConfirm from './Menu/Categories/DrawerDeleteCategoryConfirm';
import DrawerAddOption from './Menu/Items/DrawerAddOption';
import DrawerElement from './Tables/DrawerElement';
import DrawerTable from './Tables/DrawerTable';
import DrawerTableStatus from './Tables/DrawerTableStatus';
import DrawerWall from './Tables/DrawerWall';
import DrawerMenuStatus from './Menu/Menus/DrawerStatus';
import DrawerAddNewCategory from './Menu/Categories/DrawerAddNewCategory';

import DrawerCheckIn from "./CheckIn/DrawerCheckIn";
import DrawerCheckInMessage from "./CheckIn/DrawerCheckInMessage";
import DrawerSelectTable from "./CheckIn/DrawerSelectTable";
import DrawerSelectServer from "./CheckIn/DrawerSelectServer";
import DrawerCheckInSummary from "./CheckIn/DrawerCheckInSummary";
import DrawerCheckInCompleted from "./CheckIn/DrawerCheckInCompleted";

import DrawerOrderItemDelete from "./Orders/DrawerOrderItemDelete";
import DrawerOrderItemDeleteCheck from "./Orders/DrawerOrderItemDeleteCheck";
import DrawerDeleteFullOrder from "./Orders/DrawerDeleteFullOrder";
import DrawerDeleteFullOrderCheck from "./Orders/DrawerDeleteFullOrderCheck";
import DrawerCompleteOrder from "./Orders/DrawerCompleteOrder";
import DrawerEditOrderItem from "./Orders/DrawerEditOrderItem";
import DrawerPingServer from "./Orders/DrawerPingServer";
import DrawerOrderCompleted from "./Orders/DrawerOrderCompleted";
import DrawerOrderDeleted from "./Orders/DrawerOrderDeleted";
import DrawerOrderItemRemoved from "./Orders/DrawerOrderItemRemoved";

export default function Drawers() {

  const {
    openDrawerRoles, setOpenDrawerRoles,
    openDrawerRolesToEdit, setOpenDrawerRolesToEdit,
    openDrawerSortReviews, setOpenDrawerSortReviews,
    openDrawerStatus, setOpenDrawerStatus,
    openDrawerInviteStaff, setOpenDrawerInviteStaff,
    openDrawerInviteSent, setOpenDrawerInviteSent,
    openDrawerStaffAdd, setOpenDrawerStaffAdd,
    openDrawerStaffEdit, setOpenDrawerStaffEdit,
    openDrawerStaffDelete, setOpenDrawerStaffDelete,
    openDrawerStaffDetails, setOpenDrawerStaffDetails,


    openDrawerCheckIn, setOpenDrawerCheckIn,
    openDrawerCheckInMessage, setOpenDrawerCheckInMessage,
    openDrawerSelectTable, setOpenDrawerSelectTable,
    openDrawerSelectServer, setOpenDrawerSelectServer,
    openDrawerCheckInSummary, setOpenDrawerCheckInSummary,
    openDrawerCheckInCompleted, setOpenDrawerCheckInCompleted,

    openDrawerEditMenus, setOpenDrawerEditMenus,
    openDrawerMenuAdd, setOpenDrawerMenuAdd,
    openDrawerMenuEdit, setOpenDrawerMenuEdit,
    openDrawerDeleteMenu, setOpenDrawerDeleteMenu,
    openDrawerAvailabilities, setOpenDrawerAvailabilities,
    openDrawerAvailabilityDay, setOpenDrawerAvailabilityDay,
    openDrawerMoveItemsInMenu, setOpenDrawerMoveItemsInMenu,


    openDrawerCategories, setOpenDrawerCategories,
    openDrawerDeleteCategory, setOpenDrawerDeleteCategory,
    openDrawerDeleteCategoryConfirm, setOpenDrawerDeleteCategoryConfirm,
    openDrawerMoveCategoryItems, setOpenDrawerMoveCategoryItems,
    openDrawerAddNewCategory, setOpenDrawerAddNewCategory,

    openDrawerTableAdd, setOpenDrawerTableAdd,
    openDrawerTableEdit, setOpenDrawerTableEdit,
    openDrawerTableStatus, setOpenDrawerTableStatus,
    openDrawerWallAdd, setOpenDrawerWallAdd,
    openDrawerWallEdit, setOpenDrawerWallEdit,

    openDrawerOrderItemDelete, setOpenDrawerOrderItemDelete,
    openDrawerOrderItemDeleteCheck, setOpenDrawerOrderItemDeleteCheck,
    openDrawerDeleteFullOrder, setOpenDrawerDeleteFullOrder,
    openDrawerDeleteFullOrderCheck, setOpenDrawerDeleteFullOrderCheck,
    completeOrder, setCompleteOrder,
    editOrderItem, setEditOrderItem,
    pingServerDrawerOpen, setPingServerDrawerOpen,
    openOrderCompletedDrawer, setOpenOrderCompletedDrawer,
    openOrderDeletedDrawer, setOpenOrderDeletedDrawer,
    openOrderItemRemoved, setOpenOrderItemRemoved,
  } = useContext(StateContext);

  const [drawerTableActiveStatus, setDrawerTableActiveStatus] = useState(false);

  return (
    <div>
      <DrawerStaffDetails
        visible={openDrawerStaffDetails}
        onClose={() => setOpenDrawerStaffDetails(false)}
      />
      <DrawerSortReviews
        visible={openDrawerSortReviews}
        onClose={() => setOpenDrawerSortReviews(false)}
      />
      <DrawerStaff
        type='Add'
        visible={openDrawerStaffAdd}
        onClose={() => setOpenDrawerStaffAdd(false)}
      />
      <DrawerStaff
        type='Edit'
        visible={openDrawerStaffEdit}
        onClose={() => setOpenDrawerStaffEdit(false)}
      />
      <DrawerDeleteStaff
        visible={openDrawerStaffDelete}
        onClose={() => setOpenDrawerStaffDelete(false)}
      />
      <DrawerStatus
        visible={openDrawerStatus}
        onClose={() => setOpenDrawerStatus(false)}
      />
      <DrawerRoles
        visible={openDrawerRoles}
        onClose={() => setOpenDrawerRoles(false)}
      />
      <DrawerRolesToEdit
        visible={openDrawerRolesToEdit}
        onClose={() => setOpenDrawerRolesToEdit(false)}
      />
      <DrawerInviteStaff
        visible={openDrawerInviteStaff}
        onClose={() => setOpenDrawerInviteStaff(false)}
      />
      <DrawerInviteSent
        visible={openDrawerInviteSent}
        onClose={() => setOpenDrawerInviteSent(false)}
      />

      <DrawerCheckIn
        visible={openDrawerCheckIn}
        onClose={() => setOpenDrawerCheckIn(false)}
      />
      <DrawerCheckInMessage
        visible={openDrawerCheckInMessage}
        onClose={() => setOpenDrawerCheckInMessage(false)}
      />
      <DrawerCheckInCompleted
        visible={openDrawerCheckInCompleted}
        onClose={() => setOpenDrawerCheckInCompleted(false)}
      />

      <DrawerSelectTable
        visible={openDrawerSelectTable}
        onClose={() => setOpenDrawerSelectTable(false)}
      />
      <DrawerSelectServer
        visible={openDrawerSelectServer}
        onClose={() => setOpenDrawerSelectServer(false)}
      />
      <DrawerCheckInSummary
        visible={openDrawerCheckInSummary}
        onClose={() => setOpenDrawerCheckInSummary(false)}
      />

      <DrawerEditMenus
        visible={openDrawerEditMenus}
        onClose={() => setOpenDrawerEditMenus(false)}
      />
      <DrawerMenu
        type='Add'
        visible={openDrawerMenuAdd}
        onClose={() => setOpenDrawerMenuAdd(false)}
        onClickAvailability={() => setOpenDrawerAvailabilities(true)}
        onClickStatus={() => setOpenDrawerStatus(true)}
      />
      <DrawerMenu
        type='Edit'
        visible={openDrawerMenuEdit}
        onClose={() => setOpenDrawerMenuEdit(false)}
        onClickAvailability={() => setOpenDrawerAvailabilities(true)}
        onClickStatus={() => setOpenDrawerStatus(true)}
        onClickDeleteMenu={() => setOpenDrawerDeleteMenu(true)}
        onClickAddMenu={() => setOpenDrawerMenuEdit(false)}
      />
      <DrawerAvailabilities
        visible={openDrawerAvailabilities}
        onClose={() => setOpenDrawerAvailabilities(false)}
      />
      {/* <DrawerAvailabilityDay
        visible={openDrawerAvailabilityDay}
        onClose={() => setOpenDrawerAvailabilityDay(false)}
      /> */}
      <DrawerAvailabilityDayCustom
        visible={openDrawerAvailabilityDay}
        onClose={() => setOpenDrawerAvailabilityDay(false)}
      />
      {/* DrawerAvailabilityDayCustom */}
      <DrawerDeleteMenuContainer
        visible={openDrawerDeleteMenu}
        onClose={() => setOpenDrawerDeleteMenu(false)}
      />
      <DrawerMenuStatus
        onClickDoneStatus={() => setOpenDrawerStatus(false)}
      />
      <DrawerMoveAndDeleteMenu
        visible={openDrawerMoveItemsInMenu}
        onClose={() => setOpenDrawerMoveItemsInMenu(false)}
      />

      <DrawerAddOption />

      <DrawerCategories
        visible={openDrawerCategories}
        onClose={() => setOpenDrawerCategories(false)}
      />
      <DrawerDeleteCategory
        visible={openDrawerDeleteCategory}
        onClose={() => setOpenDrawerDeleteCategory(false)}
      />
      <DrawerDeleteCategoryConfirm
        visible={openDrawerDeleteCategoryConfirm}
        onClose={() => setOpenDrawerDeleteCategoryConfirm(false)}
      />
      <DrawerMoveItems
        visible={openDrawerMoveCategoryItems}
        onClose={() => setOpenDrawerMoveCategoryItems(false)}
      />
      <DrawerAddNewCategory
        visible={openDrawerAddNewCategory}
        onClose={() => setOpenDrawerAddNewCategory(false)}
      />

      <DrawerElement />
      <DrawerTable
        type='Add'
        visible={openDrawerTableAdd}
        onClose={() => setOpenDrawerTableAdd(false)}
        drawerTableActiveStatus={drawerTableActiveStatus}
      />
      <DrawerTable
        type='Edit'
        visible={openDrawerTableEdit}
        onClose={() => setOpenDrawerTableEdit(false)}
        drawerTableActiveStatus={drawerTableActiveStatus}
      />
      <DrawerTableStatus
        visible={openDrawerTableStatus}
        onClose={() => setOpenDrawerTableStatus(false)}
        drawerTableActiveStatus={drawerTableActiveStatus}
        changeTableActiveStatus={(activeStatus) => setDrawerTableActiveStatus(activeStatus)}
      />
      <DrawerWall
        type='Add'
        visible={openDrawerWallAdd}
        onClose={() => setOpenDrawerWallAdd(false)}
      />
      <DrawerWall
        type='Edit'
        visible={openDrawerWallEdit}
        onClose={() => setOpenDrawerWallEdit(false)}
      />

      <DrawerOrderItemDelete
        visible={openDrawerOrderItemDelete}
        onClose={() => setOpenDrawerOrderItemDelete(false)}
      />
      <DrawerOrderItemDeleteCheck
        visible={openDrawerOrderItemDeleteCheck}
        onClose={() => setOpenDrawerOrderItemDeleteCheck(false)}
      />
      <DrawerDeleteFullOrder
        visible={openDrawerDeleteFullOrder}
        onClose={() => setOpenDrawerDeleteFullOrder(false)}
      />
      <DrawerDeleteFullOrderCheck
        visible={openDrawerDeleteFullOrderCheck}
        onClose={() => setOpenDrawerDeleteFullOrderCheck(false)}
      />
      <DrawerCompleteOrder
        visible={completeOrder}
        onClose={() => setCompleteOrder(false)}
      />
      <DrawerEditOrderItem
        visible={editOrderItem}
        onClose={() => setEditOrderItem(false)}
      />
      <DrawerPingServer
        visible={pingServerDrawerOpen}
        onClose={() => setPingServerDrawerOpen(false)}
      />
      <DrawerOrderCompleted
        visible={openOrderCompletedDrawer}
        onClose={() => setOpenOrderCompletedDrawer(false)}
      />
      <DrawerOrderDeleted
        visible={openOrderDeletedDrawer}
        onClose={() => setOpenOrderDeletedDrawer(false)}
      />
      <DrawerOrderItemRemoved
        visible={openOrderItemRemoved}
        onClose={() => setOpenOrderItemRemoved(false)}
      />
    </div>
  )
}
