import styled from "@emotion/styled";
import SingleAntDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerCustomizationAddContainer = styled(SingleAntDrawer)`
  background-color: ${colors.gray1};
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 36px 0px 0px;
  .custom-options {
    .custom-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      padding: 0 29px 0 39px;
      background: ${colors.gray2};
    }
  }
  .drawer-menu--option {
    div {
      input::placeholder {
        color: #fff;
        opacity: 1;
      }
    }
  }
`;
