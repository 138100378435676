import styled from '@emotion/styled';
import { colors } from 'theme';

export const PeriodSwitcherContainer = styled.div`
  &.period-switcher {
    position: relative;
    cursor: pointer;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .period-dropdown {
      width: 100%;
      height: 100%;

      &.dropdown {
        .dropdown-toggle {
          width: 100%;
          height: 100%;
          font-size: 14px;
          color: white;
          padding: 11px 23px;
          background-color: ${colors.gray5};
          border-radius: 25px;
          border-color: transparent;

          .checked {
            margin-left: -3px;
          }

          &:after {
            display: none;
          }
        }

        .dropdown-menu {
          padding-top: 20px;
          padding-bottom: 26px;
          &.custom-selected {
            height: 425px;
          }
          &.periods {
            transform: translate(1px, 45px) !important;
            min-width: 122px;
            background-color: ${colors.gray5};
            border-radius: 25px;

            .dropdown-item {
              padding-top: 9px;
              padding-bottom: 9px;

              &:first-of-type {
                padding-top: 0px;
              }

              &:last-of-type {
                padding-bottom: 0px;
              }

              &:hover {
                background-color: transparent;
              }

              &:focus {
                background-color: transparent;
              }

              .period-title {
                font-size: 14px;
                color: white;
                margin-top: 10px;
                margin-bottom: 10px;

                &.currently-active {
                  color: ${colors.redColor};
                }
              }
            }
          }
        }
      }
    }

    i {
      font-size: 14px;
      color: white;
      letter-spacing: 0;
      margin-left: 6px;
    }

    .custom-period-filter {
      z-index: 100;
      position: absolute;
      bottom: -424px;
      left: -582px;

      &.show {
        display: block;
      }

      &.hide {
        display: none;
      }
    }
  }
`;
