import React from 'react';
import PropTypes from 'prop-types';
import { FoodCategoryControlBarContainer } from './style';
import { RowCategoriesControl } from './RowCategoriesControl';
import { FoodHighCategoriesDropdown } from './FoodHighCategoriesDropdown';

export function FoodCategoryControlBar({
  onChangeCategory = () => {},
  onChangeSubCategory = () => {},
}) {
  return (
    <FoodCategoryControlBarContainer>
      <RowCategoriesControl />
      <FoodHighCategoriesDropdown />
    </FoodCategoryControlBarContainer>
  );
}

FoodCategoryControlBar.propTypes = {
  onChangeCategory: PropTypes.func,
  onChangeSubCategory: PropTypes.func,
};
