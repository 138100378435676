import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { restaurantServices } from 'services/api-services';
import { AuthContext } from 'contexts/AuthContextContainer';
import { showMessage } from 'services/functions';
import { DrawerAddNewCategoryWrapper } from './style';
import { DataContext } from 'contexts/DataContextContainer';
import SingleButton from 'components/SingleButton';
import { colors } from 'theme';
import { StateContext } from 'contexts/StateContextContainer';

function DrawerAddNewCategory({
    visible = false,
    placement = 'bottom',
    onClose = () => { },
}) {
    const { restaurantId } = useContext(AuthContext);
    const { selectedMenu } = useContext(DataContext);
    const {
        allCategories,
        setAllCategories
    } = useContext(StateContext);
    const [isProcessing, setIsProcessing] = useState(false);


    const [name, setName] = useState('');

    async function addNewCategory() {
        if (restaurantId && selectedMenu?.id) {
            setIsProcessing(true)
            const { ok, data, errMessage } = await restaurantServices.createCategory(restaurantId, selectedMenu.id, { name });
            if (ok) {
                setIsProcessing(false)
                showMessage('Added Successfully', 'success');
                const addedCategory = {
                    id: data.id,
                    name: name,
                    items: []
                }
                var arrayCopy = [...allCategories];
                const index = arrayCopy.findIndex((item) => item.id === selectedMenu.id)
                arrayCopy[index].categories.push(addedCategory)
                setAllCategories(arrayCopy);
                closeDrawer();
            } else {
                setIsProcessing(false)
                showMessage(errMessage, 'error')
            }
        }
    }

    function closeDrawer() {
        onClose();
        setName('');
    }

    return (
        <DrawerAddNewCategoryWrapper
            onClose={closeDrawer}
            visible={visible}
            placement={placement}
            height="auto"
        >
            <div className="add-category-panel">
                <div className="panel-header-container">
                    <div className="back-arrow" onClick={closeDrawer}>
                        <i className="fas fa-chevron-left" />
                    </div>
                    <div className="panel-header-context">
                        Add Category
                    </div>
                </div>
                <div className="panel-status-container">
                    <div className="basic-field">
                        <p>Category Name</p>
                        <input placeholder="Enter full name" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    {/* <div className="basic-field">
                        <p>Description</p>
                        <input placeholder="Enter job title" value={description} onChange={(e) => setDescription(e.target.value)} />
                    </div> */}
                </div>
                <div className="button-container">
                    <SingleButton
                        title="Done"
                        margin='0 0 20px'
                        loading={isProcessing}
                        btnColor={name.length ? colors.redColor : '#61646c'}
                        txtColor='white'
                        onClick={() => name.length ? addNewCategory() : {}}
                    />
                    <SingleButton
                        title='Cancel'
                        btnColor='transparent'
                        txtColor={colors.redColor}
                        onClick={closeDrawer}
                    />
                </div>
            </div>

        </DrawerAddNewCategoryWrapper>
    )
}

export default DrawerAddNewCategory;

DrawerAddNewCategory.propTypes = {
    visible: PropTypes.bool,
    placement: PropTypes.string,
    onClose: PropTypes.func,
};