import styled from '@emotion/styled';
import { colors } from 'theme';

export const TotalSimplifiedContainer = styled.div`
  width: 100%;
  margin-top: 196px;
  padding: 22px 35px;
  background-color: ${colors.gray2};
  border-radius: 20px;
  position: relative;

  &.total-simplified {
    margin-top: 160px;
  }

  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 460px;
    height: 33px;
    background-color: transparent;
    left: 0px;
    bottom: -33px;
  }

  .active-total-value-container {
    width: 100%;

    &.special-total {
      .active-title-container {
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.08);
        margin-bottom: 16px;

        .active-title,
        .active-value {
          font-weight: bold;
        }
      }
    }

    &.discount {
      .active-title-container {
        .active-title {
          display: flex;
          
          .remove-discount {
            margin-left: 5px;
            cursor: pointer;
            font-size: 15px;
            letter-spacing: -0.36px;
            color: ${colors.redColor};
          }
        }

        .active-value {
          color: ${colors.lightGreen};
        }
      }
    }

    .active-title-container {
      margin-bottom: 9px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .active-title,
      .active-value {
        font-size: 15px;
        letter-spacing: -0.36px;
        color: white;
      }
    }
`;
