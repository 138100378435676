import React from 'react';
import PropTypes from 'prop-types';
import { SingleIconWrapperContainer } from './style';

export default function SingleIconWrapper({
  className = '',
  children,
  background = 'rgba(0, 0, 0, 0.1)',
  height = '100%',
  onClick = () => {},
}) {
  return (
    <SingleIconWrapperContainer
      className={className}
      style={{ height: height }}
      onClick={onClick}
      background={background}
    >
      {children}
    </SingleIconWrapperContainer>
  );
}

SingleIconWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  height: PropTypes.any,
  background: PropTypes.node,
  onClick: PropTypes.func,
};
