import React from 'react';
import PropTypes from 'prop-types';
import { CompletedTableNotificationContainer } from './style';
import { readOrderWaitTime } from 'services/functions';
import { properlyWeekDays, months } from 'services/static';
import { formatCompletedTime } from '../../OrderTable';

export function CompletedTableNotification({
  activeOrder,
  date = 'Sun Jan 19, 2020 from 7:33pm–8:58pm',
  server,
  onOpenDrawerServerReview = () => {},
}) {
  return (
    <CompletedTableNotificationContainer>
      <div className='table-description'>
        <div className='table-description-icon'>
          <i className='fas fa-check' />
        </div>
        <div className='main-description'>
          <div className='main-inner-description'>
            <div className='rating-container'>
              {activeOrder.overallRating ? <i className='fas fa-star' /> : ''}
              <span className='rating-title'>
                {activeOrder.overallRating
                  ? activeOrder.overallRating
                  : 'No Reviews'}
              </span>
            </div>
            <div className='avg-wait-container'>
              Avg Wait <i className='fas fa-clock' />{' '}
              {readOrderWaitTime(activeOrder.time)}
            </div>
          </div>
          <div className='name-code-container'>
            <div className='name-container'>
              <span className='name-title'>{`Table ${activeOrder.table.tableNumber}`}</span>
            </div>
            <span className='separator'>•</span>
            <div className='code-container'>
              <span className='code-title'>
                {activeOrder.table.orderNumber}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='table-date'>
        <span className='table-date-title'>
          {showDate(activeOrder.completed, activeOrder.time)}
        </span>
      </div>
      <div className='table-more-detail'>
        <div className='guests-items'>
          <span className='title-item'>{activeOrder.table.guests} Guests</span>
          <span className='separator'>•</span>
          <span className='title-item'>{activeOrder.items.length} Items</span>
        </div>
        <div className='server-container'>
          <span className='position-title'>Server</span>
          <span className='server-name'>{server.name}</span>
        </div>
      </div>
      <div className='photo-container'>
        <div className='guests'>
          {new Array(activeOrder.table.guests)
            .fill(undefined)
            .map((_, index) => (
              <div
                className='guest-photo-item'
                key={index}
                style={{
                  left: index ? `${index * 30}px` : '-2px',
                }}
              />
            ))}
        </div>

        <div className='server' />
      </div>
      <div className='server-rating-container'>
        <div className='server-rating-core'>
          <div className='server-rating-core-main'>
            {new Array(5).fill(undefined).map((_, index) => (
              <div
                className={`rating-star ${
                  index + 1 <= server.rating ? 'active' : ''
                }`}
                key={index}
              >
                <i className='fas fa-star' />
              </div>
            ))}
          </div>
          <span className='reviews-count-title'>({2})</span>
        </div>
        <div className='btn-review' onClick={onOpenDrawerServerReview}>
          <span className='btn-review-title'>Review</span>
          <div className='btn-review-icon'>
            <i className='fas fa-chevron-right' />
          </div>
        </div>
      </div>
    </CompletedTableNotificationContainer>
  );
}

CompletedTableNotification.propTypes = {
  activeOrder: PropTypes.object,
  date: PropTypes.string,
  server: PropTypes.object,
  onOpenDrawerServerReview: PropTypes.func,
};

export const showDate = (completedTime, overallTimeForCompleting) => {
  const dateWhenCompleted = new Date(completedTime);
  const overallTimeInMilliseconds = overallTimeForCompleting * 1000;
  const startTime = new Date(
    dateWhenCompleted.getTime() - overallTimeInMilliseconds
  );

  return `${properlyWeekDays[startTime.getDay()].slice(0, 3)} ${months[
    startTime.getMonth()
  ].slice(
    0,
    3
  )} ${startTime.getDate()}, ${startTime.getFullYear()} from ${formatCompletedTime(
    startTime.getTime()
  )
    .toLowerCase()
    .replace(' ', '')}-${formatCompletedTime(dateWhenCompleted.getTime())
    .toLowerCase()
    .replace(' ', '')}`;
};
