import styled from 'styled-components';

export const MovableLayoutContainer = styled.div`
  position: relative;
    .internal-scene {
      transform-origin: center;
      width: 100%;
      height: 100%;

      .table-name-movable-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .table-name,
        .guests-and-seats-title {
          font-size: 13px;
          color: white;
          letter-spacing: -0.31px;
        }

        .table-name {
          font-weight: bold;
          margin-bottom: 0px;
        }
    }
  }

  .react-transform-component {
      height: 100%;
      width: 100%;
      .react-transform-element {
        height: 100%;
        width: 100%;
        transform: translate(0px, 0px) scale(0.35)!important;
      }
   }
`;
