import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactCodeInput from 'react-verification-code-input';
import { FaChevronLeft } from 'react-icons/fa';
import { DrawerCheckInContainer } from './style';
import { colors } from 'theme';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { howManyTablesAvailableForTheseFloors } from 'services/functions';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import SingleIconWrapper from 'components/SingleIconWrapper';

export default function DrawerCheckIn({
  visible = false,
  placement = 'right',
  onClose = () => { },
}) {

  const {
    setCheckinStatus, setOpenDrawerSelectTable
  } = useContext(StateContext);

  const { floorDetails } = useContext(DataContext);

  const [code, setCode] = useState('');
  const [isMatch, setIsMatch] = useState();
  const [isFilled, setIsFilled] = useState(false);

  const tablesAvailable = useMemo(() => howManyTablesAvailableForTheseFloors(floorDetails), [floorDetails]);

  const onChangeCode = async (value) => {
    setCode(value)
  }

  const onClickCheckin = () => {
    setIsMatch(false);
    setCheckinStatus(false);
    setOpenDrawerSelectTable(true);
    // setOpenDrawerCheckInMessage(true);
  }

  useEffect(() => {
    if (code.length === 6) {
      setIsFilled(true)
    } else if (isFilled) {
      setIsFilled(false)
      setIsMatch()
    }
    // eslint-disable-next-line
  }, [code])

  return (
    <DrawerCheckInContainer
      onClose={onClose}
      visible={visible}
      placement={placement}
    >
      <Box is='div' display='flex' alignItems='center' marginBottom={60}>
        <SingleIconWrapper onClick={onClose}>
          <FaChevronLeft size={20} color='white' />
        </SingleIconWrapper>
        <Box is='div' display='flex' justifyContent='center' flex={1}>
          <SingleText content='Check In' align='center' size={22} weight={500}>Check In</SingleText>
        </Box>
      </Box>
      <SingleText content='Enter a 6 digit code from customer’s phone.' size={14} marginBottom={45}>Enter a 6 digit code from customer’s phone.</SingleText>
      <Box is='div' className='drawer-checkin--options'>
        <ReactCodeInput
          type="text"
          className={`verify-code-input ${(isMatch === false) ? 'invalid' : ''}`}
          fields={6}
          onChange={onChangeCode}
          fieldWidth={43}
          fieldHeight={43}
        />
      </Box>
      <Box is='div' display='flex' flexDirection='column' marginTop='auto'>
        <div className={`available-tables ${isFilled && !!tablesAvailable ? 'filled' : ''}`}>
          <i className="fas fa-circle" />
          {isFilled && !!tablesAvailable
            ? <span>{tablesAvailable} Tables Available</span>
            : <span>No Tables Available</span>
          }
        </div>
        <SingleButton
          title='Check In'
          btnColor={isFilled ? colors.redColor : colors.gray3}
          txtColor={isFilled ? 'white' : '#949697'}
          onClick={isFilled ? onClickCheckin : () => { }}
        />
      </Box>
    </DrawerCheckInContainer>
  )
}

DrawerCheckIn.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickDay: PropTypes.func,
  onClickContinue: PropTypes.func,
};
