import styled from '@emotion/styled'
import { colors } from 'theme';

import { DefaultUser } from 'assets/images/profile';
import { DefaultMenu } from 'assets/images/menu';

export const DashboardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .clear-search {
    margin-left: 2px!important;
  }
`;

export const HeaderSpace = styled.div`
  display: flex;
  align-items: center;
  span{
    margin-right: 57px !important;
  }
  span:last-child{
    margin-right: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  .dashboard-button{
    margin-left: 15px;
  }
`;

export const DashboardHeaderContainer = styled.div`

    .dashboard-container {
      padding: 0px 42px 0px 22px;
      display: flex;
      flex-wrap: wrap;
    }

    .chart-container {
      margin-right: 27px;
    }

    .right-facts-container {
      max-width: 301px;
      min-width: 301px;
      margin-right: 27px;

      &:last-of-type {
        margin-right: 0px;
      }
    }

    .nav-main-container{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .nav-container{
        margin-top: 24px;
        cursor: pointer;
        flex: 1 1 auto;
        border-radius: 8px;
        box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
        background-color: #1b1c1d;
        padding: 20px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &.best-seller-bg {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          background-image: url(${DefaultMenu});
          position: relative;

          &:before {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            border-radius: 20px;
            top: 0;
            left: 0;
          }

          .title-part {
            margin-bottom: 5px;
          }
        }

        &.highest-rated-server-today {
          display: flex;
          align-items: center;
          flex-direction: row;
          padding-top: 19px;
          padding-bottom: 19px;


          .server-avatar {
            width: 51px;
            height: 51px;
            border-radius: 50%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url(${DefaultUser});
          }

          .small-server-info {
            width: 100%:
            display: flex;
            flex-direction: column;

            .title-part {
              margin-bottom: 5px;

              .underscore-data {
                font-size: 14px;
                letter-spacing: -0.34px;
                color: white;
              }
            }
          }
        }

        &.returns {
          .bottom-part {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .bottom-title {
              font-size: 14px;
              letter-spacing: -0.34px;
              color: white;
            }

            .bottom-side-title {
              font-size: 14px;
              letter-spacing: -0.34px;
              color: rgba(255, 255, 255, 0.6);
              margin-right: 6px;
            }
          }
        }

        .title-part{
          z-index: 101;
          display: flex;
          justify-content: space-between;
          .title-name{
            color: #ffffff;
            font-size: 16px;
            font-weight: bold;

            i{
              margin-right: 5px;
              &.yellow{
                margin-right: 0px;
                color: #fdcb6e;
              }
            }
          }
          .title-arrow{
            font-size: 14px;
            margin-right: 6px;

            .underscore-data {
              font-size: 14px;
              letter-spacing: -0.34px;
              color: rgba(255, 255, 255, 0.6);
            }

            .fa-angle-right{
              font-size: 20px;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.6);
              vertical-align: bottom;
              margin-left: 12px;
            }
          }
        }
        .extra-info-part{
          padding-left: 10px;
          display: flex;
          align-item: center;
          justify-content: space-between;
          margin-bottom: 20px;
          .info-part-item{
            .item-title{
              font-size: 14px;
              letter-spacing: -0.29px;
              color: rgba(255,255,255,0.6);
              margin-right: 10px;
            }
            .item-value{
              font-size: 17px;
              letter-spacing: -0.38px;
              color: #ffffff;
              font-weight: bold;
            }
          }
        }
        .bottom-part{
          z-index: 101;
          font-size: 14px;
          color: #ffffff;

          &.with-icon {
            display: flex;
            align-items: center;
          }

          .online-icon {
            margin-top: -1px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: ${colors.lightGreen};
            margin-right: 10px;
          }
        }
      }
    }

    .header-menu-container {
      @media (max-width: 1600px) {
        flex-wrap: wrap;
      }
    }
`;