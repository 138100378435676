import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerApplyDiscountContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  height: ${({ customHeight }) => customHeight}px !important;
  padding: 36px 0px 26px;

  .subtotal-container {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 26px;
    padding-right: 26px;
  }

  .discount-options-container {
    width: 100%;
    max-height: 72px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    display: flex;

    .discount-option {
      width: 25%;
      height: 100%;
      padding-top: 26px;
      padding-bottom: 26px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &.active {
        background-color: ${colors.redColor};
      }

      .option-title {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: -0.36px;
        color: white;
      }
    }
  }
`;
