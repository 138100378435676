import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CompletedAlertFullrefundContainer } from './style';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';

export function CompletedAlertFullrefund({ onClose }) {
  const context = useContext(modalContext);
  return (
    <CompletedAlertFullrefundContainer>
      {context.modal === 'Return-Full-Refund-Modal-3' ? (
        <div className='return-fullrefund-alert-wrapper animate__animated animate__fadeInUp'>
          <div className='return-fullrefund-alert-svg-position'>
            <svg height='100' width='100'>
              <circle cx='50' cy='50' r='40' strokeWidth='3' fill='#27282a' />
            </svg>
          </div>

          <div className='return-fullrefund-alert-check-wrapper'>
            <i className='far fa-check return-fullrefund-alert-check'></i>
          </div>

          <div className='return-fullrefund-alert-body-wrapper'>
            <div>
              <p className='return-fullrefund-alert-text-style'>
                Return Completed
              </p>
            </div>

            <div className='return-fullrefund-alert-btn-center'>
              <Button
                variant='none'
                className='return-fullrefund-alert-btn-style'
                onClick={onClose}
              >
                <span className='return-fullrefund-alert-btn-text'>Okay</span>
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </CompletedAlertFullrefundContainer>
  );
}

CompletedAlertFullrefund.propTypes = {
  onClose: PropTypes.func,
};
