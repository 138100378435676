import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerUpdateEmailAddressContainerStyled } from './style';
import { DrawerUpdateValue } from '../../DrawerUpdateValue';
import { DrawerVerifyEmail } from './DrawerVerifyEmail';

export function DrawerUpdateEmailAddressContainer({
  visible,
  onClose,
  email = '',
  changeEmail = () => {},
  verify,
  confirmEmail
}) {
  const [openDrawerUpdateEmailAddress, setOpenDrawerUpdateEmailAddress] =
    useState(false);
    
  const onCloseDrawerUpdateEmailAddress = () =>
    setOpenDrawerUpdateEmailAddress(false);

  const [openDrawerVerifyEmail, setOpenDrawerVerifyEmail] = useState(false);

  const onContinue = (newValue) => {
    changeEmail(newValue.trim());
    onCloseDrawerUpdateEmailAddress();
    setOpenDrawerVerifyEmail(true);
  };

  const onCloseWhenUpdating = () => {
    setOpenDrawerVerifyEmail(false);
    onClose();
  };

  const onCloseWhenVerifying = () => {
    setOpenDrawerVerifyEmail(false);
    confirmEmail();
    onClose();
  };

  const close = () => {
    setOpenDrawerVerifyEmail(false);
    onCloseDrawerUpdateEmailAddress();
    onClose();
  };

  useEffect(() => {
    if (visible) {
      setOpenDrawerUpdateEmailAddress(true);
    }
  }, [visible]);

  return (
    <DrawerUpdateEmailAddressContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerUpdateValue
        visible={openDrawerUpdateEmailAddress}
        onClose={onCloseWhenUpdating}
        label='Email Address'
        inputType='email'
        currentValue={email}
        placeholder='Enter your email address'
        updateTitleEnd='e-mail address'
        onContinue={(updatedEmail) => onContinue(updatedEmail)}
      />
      <DrawerVerifyEmail
        email={email}
        visible={openDrawerVerifyEmail}
        onClose={onCloseWhenVerifying}
      />
    </DrawerUpdateEmailAddressContainerStyled>
  );
}

DrawerUpdateEmailAddressContainer.propTypes = {
  visible: PropTypes.bool,
  email: PropTypes.string,
  onClose: PropTypes.func,
  changeEmail: PropTypes.func,
};
