import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { DrawerApplyDiscountContainerStyled } from './style';
import { ActiveOrdersContext } from 'contexts/ActiveOrdersContextContainer';
import { needToPay } from 'services/functions';
import { usePaymentAdditional } from 'hooks';
import { DrawerApplyDiscount } from './DrawerApplyDiscount';
import { DrawerApplyDiscountAreYouSure } from './DrawerApplyDiscountAreYouSure';

export function DrawerApplyDiscountContainer({ visible, onClose }) {
  const { activeOrder, applyDiscountForActiveOrder } =
    useContext(ActiveOrdersContext);

  const [openDrawerApplyDiscount, setOpenDrawerApplyDiscount] = useState(false);
  const onOpenDrawerApplyDiscount = () => setOpenDrawerApplyDiscount(true);
  const onCloseDrawerApplyDiscount = () => setOpenDrawerApplyDiscount(false);

  const [
    openDrawerApplyDiscountAreYouSure,
    setOpenDrawerApplyDiscountAreYouSure,
  ] = useState(false);

  const onOpenDrawerApplyDiscountAreYouSure = () =>
    setOpenDrawerApplyDiscountAreYouSure(true);
  const onCloseDrawerApplyDiscountAreYouSure = () =>
    setOpenDrawerApplyDiscountAreYouSure(false);

  const [discount, setDiscount] = useState(undefined);

  const _discountTypes = {
    first: {
      value: 'percentage',
      initial: 15,
    },
    second: {
      value: 'fixed',
      initial: 10,
    },
  };

  const [dollars, setDollars] = useState(10);
  const [percents, setPercents] = useState(15);

  const {
    isOther,
    enableOtherMode,
    disableOtherMode,

    paymentOption: _discountOption,
    onChangePaymentOption: onChangeDiscountOption,
    paymentType: _discountType,
    onChangePaymentType: onChangeDiscountType,
    isFirstType: isPercentageActive,
    isSecondType: isFixedActive,
  } = usePaymentAdditional({
    paymentTypes: _discountTypes,
    initialValueFromFirstType: 15,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangePercentageOption = (option) => {
    disableOtherMode();
    onChangeDiscountType(_discountTypes.first.value);
    onChangeDiscountOption(option);
    setPercents(option);
  };

  const onSwitchToOtherAndSetPercentage = () => {
    enableOtherMode();
    onChangeDiscountType(_discountTypes.first.value);
  };

  useEffect(() => {
    if (isPercentageActive()) {
      // changing dollars when changing percents
      setDollars(Number(computeDiscount(activeOrder, percents, true)));
    } else if (isFixedActive()) {
      // changing percents when changing dollars
      setPercents(Number(computeDiscount(activeOrder, dollars, false)));
    }
  }, [dollars, percents, activeOrder, isFixedActive, isPercentageActive]);

  useEffect(() => {
    const setupExistingDiscount = (userDiscount) => {
      onChangeDiscountType(userDiscount.type);
      if (userDiscount.type === _discountTypes.second.value) {
        setDollars(Number(userDiscount.value));
        enableOtherMode();
      } else {
        if ([10, 15, 20].includes(Number(userDiscount.value))) {
          onChangeDiscountOption(Number(userDiscount.value));
          setPercents(Number(userDiscount.value));
        } else {
          setPercents(userDiscount.value);
          enableOtherMode();
        }
      }
    };

    if (visible) {
      if (
        Object.keys(activeOrder.discount || {}).length !== 0 &&
        discount === undefined
      ) {
        setDiscount(activeOrder.discount);
        setupExistingDiscount(activeOrder.discount);
      }
    } else {
      setDiscount(undefined);
      onChangePercentageOption(15);
    }
  }, [
    _discountTypes,
    activeOrder,
    discount,
    enableOtherMode,
    onChangeDiscountOption,
    onChangeDiscountType,
    onChangePercentageOption,
    visible,
  ]);

  const close = () => {
    onCloseDrawerApplyDiscountAreYouSure();
    onCloseDrawerApplyDiscount();
    onClose();
  };

  const onApplyDiscount = () => {
    setDiscount({
      type: _discountType,
      value: isOther
        ? isPercentageActive()
          ? percents
          : dollars
        : _discountOption,
      money: dollars,
    });
    onCloseDrawerApplyDiscount();
    onOpenDrawerApplyDiscountAreYouSure();
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerApplyDiscount();
    }
  }, [visible]);

  return (
    <DrawerApplyDiscountContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerApplyDiscount
        visible={openDrawerApplyDiscount}
        onClose={close}
        discountType={_discountType}
        onChangeType={onChangeDiscountType}
        discountOption={_discountOption}
        onChoose={onChangePercentageOption}
        onApply={onApplyDiscount}
        isOther={isOther}
        percents={percents}
        setPercents={setPercents}
        dollars={dollars}
        setDollars={setDollars}
        onSwitchToOtherAndSetPercentage={onSwitchToOtherAndSetPercentage}
        isPercentageActive={isPercentageActive}
        isFixedActive={isFixedActive}
      />
      <DrawerApplyDiscountAreYouSure
        visible={openDrawerApplyDiscountAreYouSure}
        onClose={close}
        discount={discount}
        discountTypes={_discountTypes}
        applyDiscountForActiveOrder={applyDiscountForActiveOrder}
      />
    </DrawerApplyDiscountContainerStyled>
  );
}

DrawerApplyDiscountContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export const computeDiscount = (
  activeOrder,
  currentUserDiscount,
  isPercentDiscount = false
) => {
  const totalPay = Number(needToPay({ items: [], ...activeOrder }));
  if (isPercentDiscount) {
    const dollars = totalPay * (currentUserDiscount / 100);

    return dollars.toFixed(2);
  } else {
    const percents = (currentUserDiscount / totalPay) * 100;

    return percents.toFixed(2);
  }
};
