/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { RequestPendingDrawerContainer } from './style';
import { PartialRefundModal } from './PartialRefundModal';
import { modalContext } from 'routes';
import { ReplaceItemModal } from './ReplaceItem';
import { FullRefundModal } from './FullRefund';
import { RejectRequestModal } from './RejectRequest';
import { RequestPendingContext } from 'contexts/RequestPendingContextContainer';

export function RequestPendingDrawer({ visible, onClose }) {

  const context = useContext(modalContext);
  const {
    targetFoodCompletedOptions,
    setTargetFoodCompletedOptions,
    targetFoodCompletedOptionsText,
    setTargetFoodCompletedOptionsText,
  } = useContext(RequestPendingContext);

  // Drawer
  const [openDrawerRequestPending, setOpenDrawerRequestPending] =
    useState(false);
  const onOpenDrawerRequestPending = () => {
    setOpenDrawerRequestPending(true);
    context.setModal('Return-ChooseText-Modal-1');
  };
  const onCloseDrawerRequestPending = () => setOpenDrawerRequestPending(false);

  // replace item drawer method
  const [openDrawerReplaceItem, setOpenDrawerReplaceItem] = useState(false);
  const onOpenDrawerReplaceItem = () => {
    setOpenDrawerReplaceItem(true);
    context.setModal('Return-Replace-Item-Modal-1');
  };
  const onCloseDrawerReplaceItem = () => setOpenDrawerReplaceItem(false);

  // fullrefund drawer method
  const [openDrawerFullRefund, setOpenDrawerFullRefund] = useState(false);
  const onOpenDrawerFullRefund = () => {
    setOpenDrawerFullRefund(true);
    context.setModal('Return-FullRefund-Modal-1');
  };
  const onCloseDrawerFullRefund = () => setOpenDrawerFullRefund(false);

  // reject request drawer method
  const [openDrawerRejectRequest, setOpenDrawerRejectRequest] = useState(false);
  const onOpenDrawerRejectRequest = () => {
    setOpenDrawerRejectRequest(true);
    context.setModal('Return-RejectRequest-Modal-1');
  };
  const onCloseDrawerRejectRequest = () => setOpenDrawerRejectRequest(false);

  // its log for test
  // console.log(targetFoodCompletedOptions, 'targetFoodCompleted')
  // console.log(targetFoodCompletedOptionsText, 'targetFoodCompletedOptionsText')

  // partial-refund text
  if(targetFoodCompletedOptions === 3) {
    setTargetFoodCompletedOptionsText('Partial Refund:')
  }

  return (
    <RequestPendingDrawerContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      <div className='return-request-close-icon return-request-wrapper-sidebar'>
        <i
          className='fas fa-chevron-left return-request-left'
          onClick={onClose}
          aria-hidden='true'
        />

        <div className='return-request-text-align'>
          <p>
            Choose how to resolve
            <br />
            this item.
          </p>
        </div>

        <div>
          <p
            className='return-request-border-style'
            // onClick={onOpenDrawerReplaceItem}
            aria-hidden='true'
            onClick={() => {
              setTargetFoodCompletedOptions(1)
              onOpenDrawerReplaceItem()
            }}
          >
            <span style={{ color: '#ffffff' }}>Replace item</span>
          </p>

          <p
            className='return-request-border-style'
            // onClick={onOpenDrawerFullRefund}
            aria-hidden='true'
            onClick={() => {
              setTargetFoodCompletedOptions(2)
              onOpenDrawerFullRefund()
            }}
          >
            <span style={{ color: '#ffffff' }}>Full Refund</span>
          </p>

          <p
            className='return-request-border-style'
            // onClick={onOpenDrawerRequestPending}
            aria-hidden='true'
            onClick={() => {
              setTargetFoodCompletedOptions(3)
              // setTargetFoodCompletedOptionsText('Partial Refund:')
              onOpenDrawerRequestPending()
            }}
          >
            <span style={{ color: '#ffffff' }}>Partial Refund</span>
          </p>

          <p
            className='return-request-border-style'
            // onClick={onOpenDrawerRejectRequest}
            aria-hidden='true'
            onClick={() => {
              setTargetFoodCompletedOptions(4)
              onOpenDrawerRejectRequest()
            }}
          >
            <span style={{ color: '#ffffff' }}>Reject Request</span>
          </p>
        </div>
      </div>

      {/* Drawer */}
      <PartialRefundModal
        visible={openDrawerRequestPending}
        onClose={onCloseDrawerRequestPending}
      />

      {/* replace item drawer */}
      <ReplaceItemModal
        visible={openDrawerReplaceItem}
        onClose={onCloseDrawerReplaceItem}
      />

      {/* fullrefund item drawer */}
      <FullRefundModal
        visible={openDrawerFullRefund}
        onClose={onCloseDrawerFullRefund}
      />

      {/* reject request modal */}
      <RejectRequestModal
        visible={openDrawerRejectRequest}
        onClose={onCloseDrawerRejectRequest}
      />
    </RequestPendingDrawerContainer>
  );
}

RequestPendingDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
