import { Status } from './Status';

export class StatusGroup {
  profileStatus;
  menuStatus;
  tableStatus;
  bankStatus;

  constructor({
    profileCompleted = false,
    menuCompleted = false,
    tableCompleted = false,
    bankCompleted = false,
  }) {
    this.profileStatus = new Status(profileCompleted);
    this.menuStatus = new Status(menuCompleted);
    this.tableStatus = new Status(tableCompleted);
    this.bankStatus = new Status(bankCompleted);
  }
}
