import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SingleAntDrawerInput from 'components/SingleAntDrawerInput';

export function WebsiteField({ website, onSubmit }) {
  const [webSite, setWebSite] = useState(undefined);

  useEffect(() => {
    if (webSite === undefined) {
      setWebSite(website);
    }
  }, [website, webSite, setWebSite]);

  return (
    <>
      <SingleAntDrawerInput
        label='Website'
        className='horizontal-inner-element'
        placeholder='Write website'
        value={webSite}
        onChange={(e) => setWebSite(e.target.value)}
        onPressEnter={() => onSubmit(webSite.trim())}
        onBlur={() => onSubmit(webSite.trim())}
      />
    </>
  );
}

WebsiteField.propTypes = {
  website: PropTypes.string,
  onSubmit: PropTypes.func,
};
