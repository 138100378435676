import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types';
// import './style.scss';
import Box from 'components/Box';
import { FaChevronLeft } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import { DrawerElementContainer } from './style';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { StateContext } from 'contexts/StateContextContainer';
import SingleAntImage from 'components/SingleAntImage';
import { IconTable } from 'assets/images/dashboard';

export default function DrawerElement({ 
  placement = 'right',
}) {

  const {
    openDrawerElement, setOpenDrawerElement,
    setOpenDrawerTableAdd,
    setOpenDrawerWallAdd
  } = useContext(StateContext);
  const [active, setActive] = useState('');

  const onClose = () => {
    setOpenDrawerElement(false);
  }

  return (
      <DrawerElementContainer
        onClose={() => setOpenDrawerElement(false)}
        visible={openDrawerElement}
        placement={placement}
        active={active}
      >
        <Box is='div' display='flex' alignItems='center' marginBottom={40}>
          <SingleIconWrapper onClick={onClose}>
            <FaChevronLeft size={20} color='white' />
          </SingleIconWrapper>
          <Box is='div' display='flex' justifyContent='center' flex={1}>
            <SingleText content='Type' align='center' size={22} weight={500}>Add</SingleText>
          </Box>
        </Box>
        <Box is='div' className='drawer-options'>
          <Box 
            className={`drawer-option ${active === 'table' ? 'active' : 'inactive'}`} 
            marginBottom={15} 
            cursor="pointer"
            onClick={() => {
              setActive('table')
              setOpenDrawerTableAdd(true)
            }}
          >
            <Box width={20} height={20} display='flex' justifyContent='center' alignItems='center' marginRight={10}>
              <SingleAntImage src={IconTable} width='19px' />
            </Box>
            <SingleText size={14} weight={500} spacing='-0.34'>{'Table'}</SingleText>
          </Box>
          <Box 
            className={`drawer-option ${active === 'wall' ? 'active' : 'inactive'}`} 
            marginBottom={15} 
            cursor="pointer"
            onClick={() => {
              setActive('wall')
              setOpenDrawerWallAdd(true)
            }}
          >
            <Box width={20} height={20} display='flex' justifyContent='center' alignItems='center' marginRight={10}>
              <Box width={6} height={19} backgroundColor='white' />
            </Box>
            <SingleText size={14} weight={500} spacing='-0.34'>{'Wall'}</SingleText>
          </Box>
        </Box>
      </DrawerElementContainer>
  )
}

DrawerElement.propTypes = {
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
};
