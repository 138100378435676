import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerAddManagerWrapper } from './style';

function DrawerAddManager({
  visible = false,
  placement = 'right',
  onClose = () => {},
  managerName,
  managerTitle,
  onChange = () => {},
}) {
  const [name, setName] = useState('');
  const [jobTitle, setJobTitle] = useState('');

  useEffect(() => {
    if (visible) {
      setName(managerName);
      setJobTitle(managerTitle);
    }
  }, [visible, managerName, managerTitle]);

  return (
    <DrawerAddManagerWrapper
      onClose={onClose}
      visible={visible}
      placement={placement}
    >
      <div className='add-manager-panel'>
        <div className='panel-header-container'>
          <div className='back-arrow' onClick={onClose}>
            <i className='fas fa-chevron-left' />
          </div>
          <div className='panel-header-context'>Edit Manager</div>
        </div>
        <div className='panel-status-container'>
          <div className='basic-field'>
            <p>Full Name</p>
            <input
              placeholder='Enter full name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className='basic-field'>
            <p>Job Title</p>
            <input
              placeholder='Enter job title'
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </div>
        </div>
        <div className='button-container'>
          <button
            type='button'
            className={`button-general submit-button ${
              jobTitle.length && name.length ? 'active' : ''
            }`}
            onClick={() =>
              jobTitle.length && name.length
                ? onChange({ newManagerName: name, newManagerTitle: jobTitle })
                : () => {}
            }
          >
            Done
          </button>
          <button
            type='button'
            className={`button-general cancel-button`}
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </DrawerAddManagerWrapper>
  );
}

export default DrawerAddManager;

DrawerAddManager.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  managerName: PropTypes.string,
  managerTitle: PropTypes.string,
  onChange: PropTypes.func,
};
