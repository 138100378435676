import styled from '@emotion/styled';
import { colors } from 'theme';

export const MenuCardContainer = styled.div`
  padding: 8px 0;
  width: 100%;
  margin-bottom: 34px;

  .menu-card {
    max-width: 494px;
    border-radius: 20px;
    box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
    background-color: ${colors.gray2};
    margin-right: 26px;
    padding: 24px 12px 24px 24px;

    .menu-card-left {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .inner-block-menu-left {
        width: 187px;
        display: flex;
        align-items: center;
        width: fit-content;

        .image-place {
          width: 100%;
          height: 186px;

          &.image-left-part {
            margin-left: 0;
          }

          .utensils {
            width: 187px;
            height: 186px;
            border-radius: 15px;
            background-color: ${colors.gray5};
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .food-photo {
            width: 187px;
            height: 186px;
            border-radius: 15px;
            background-position: center;
            background-repeat: no-repeat;

            // for drinks
            &.contain {
              background-color: white;
            }
          }
        }
      }

      .main-description {
        padding-left: 17px;
        min-height: 186px;
        position: relative;

        .title {
          font-size: 16.2px;
          font-weight: bold;
          color: white;
          letter-spacing: -0.41px;
        }

        .description {
          display: flex;
          flex-direction: column;

          .text-description {
            width: 246px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.5);
            letter-spacing: -0.29px;
            line-height: 18px;
            margin-top: 8px;
            margin-bottom: 0px;
          }
        }
      }
    }

    .card-col {
      text-align: left;

      &.icon {
        width: fit-content;

        .icon-container {
          width: 60px;
          text-align: center;
          .icon-drag {
            width: 19px;
            color: #dadde8;
          }
        }
      }

      &.name-block {
        padding: 0 80px 0 30px;

        .title {
          font-size: 16px;
          font-weight: 500;
          color: white;
          letter-spacing: -0.38px;
        }

        .description {
          font-size: 14px;
          letter-spacing: -0.38px;
          color: white;
          opacity: 0.5;
        }
      }

      &.more-detail {
        width: 5%;
        display: flex;
        justify-content: flex-start;
      }
    }
  }
`;
