import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FoodControlContainer } from './style';
import { FoodChecks } from './FoodChecks';
import SingleAntPopup from 'components/SingleAntPopup';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { PopupContent } from '../PopupContent';
import DrawerItem from 'pages/Menu/components/DrawerItem';
import DrawerDeleteItem from 'pages/Menu/components/DrawerDeleteItem';

export function FoodControl({ foodItem, isDraggable }) {
  const [openDrawerFoodItem, setOpenDrawerFoodItem] = useState(false);
  const [openDrawerDeleteFoodItem, setOpenDrawerDeleteFoodItem] = useState(false);
  const onClickEdit = () => setOpenDrawerFoodItem(true);
  const onClickDelete = () => setOpenDrawerDeleteFoodItem(true);

  const content = (
    <PopupContent
      onClickEdit={onClickEdit}
      onClickDelete={onClickDelete}
      // onDeleteMenu={onOpenDrawerDeleteFoodItem}
      isDraggable={isDraggable}
    />
  );

  return (
    <FoodControlContainer>
      <FoodChecks checks={foodItem.dietary} />
      <SingleAntPopup
        placement='bottom'
        title={''}
        content={content}
        trigger='click'
      >
        <SingleIconWrapper className='btn-more'>
          <i className='fas fa-ellipsis-h' />
        </SingleIconWrapper>
      </SingleAntPopup>
      <DrawerItem
        visible={openDrawerFoodItem}
        setOpenDrawerFoodItem={setOpenDrawerFoodItem}
        editFood={openDrawerFoodItem ? foodItem : null}
      />
      <DrawerDeleteItem
        visible={openDrawerDeleteFoodItem}
        setOpenDrawerDeleteFoodItem={setOpenDrawerDeleteFoodItem}
        editFood={openDrawerDeleteFoodItem ? foodItem : null}
      />
    </FoodControlContainer>
  );
}

FoodControl.propTypes = {
  foodItem: PropTypes.object,
  isDraggable: PropTypes.bool,
};
