import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { DrawerDeleteTableContainer } from './style';
import { DataContext } from 'contexts/DataContextContainer';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerPreventDeleteTable } from './DrawerPreventDeleteTable';

export default function DrawerDeleteTable({
  onKeep = () => {},
  onRemove = () => {},
}) {
  const [
    openDrawerPreventDeleteTable,
    setOpenDrawerPreventDeleteTable,
  ] = useState(false);
  const onOpenDrawerPreventDeleteTable = () =>
    setOpenDrawerPreventDeleteTable(true);
  const onCloseDrawerPreventDeleteTable = () =>
    setOpenDrawerPreventDeleteTable(false);

  const { getTableForEditTemp } = useContext(DataContext);

  const onRemoveItem = () => {
    if (getTableForEditTemp && getTableForEditTemp.is_available) {
      onRemove();
      return;
    } else {
      onOpenDrawerPreventDeleteTable();
    }
  };

  return (
    <DrawerDeleteTableContainer
      placement='bottom'
      onClose={onKeep}
      height='fit-content'
      visible={true}
      className={!openDrawerPreventDeleteTable ? '' : 'drawer-prevent-hide'}
    >
      <Box
        is='div'
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        marginBottom={42}
      >
        <SingleText size={22} weight={500} marginBottom={10}>
          Remove Table
        </SingleText>
        <SingleText lineHeight={1.5} spacing='0.27px' marginBottom={10}>
          Are you sure you want to remove{' '}
          <b>
            {getTableForEditTemp
              ? `Table ${getTableForEditTemp.table_number}`
              : 'this table'}
          </b>
          ?
        </SingleText>
      </Box>
      <Box is='div' display='flex' flexDirection='column'>
        <SingleButton title='Remove' margin='0 0 20px' onClick={onRemoveItem} />
        <SingleButton
          title='Keep'
          btnColor='#61646c'
          txtColor='white'
          margin='0 0 20px'
          onClick={onKeep}
        />
      </Box>
      <DrawerPreventDeleteTable
        visible={openDrawerPreventDeleteTable}
        onClose={onCloseDrawerPreventDeleteTable}
        onComplete={onKeep}
      />
    </DrawerDeleteTableContainer>
  );
}

DrawerDeleteTable.propTypes = {
  onKeep: PropTypes.func,
  onRemove: PropTypes.func,
};
