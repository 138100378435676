import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DrawerDeleteMenuFirstStepContainer } from './style';
import { colors } from 'theme';
import { DataContext } from 'contexts/DataContextContainer';
import { StateContext } from 'contexts/StateContextContainer';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerDeleteMenuFirstStep({ visible, onClose }) {
  const { selectedMenu } = useContext(DataContext);
  const { setOpenDrawerDeleteMenu } = useContext(StateContext);

  const onOpenDrawerDeleteMenu = () => setOpenDrawerDeleteMenu(true);

  const onDelete = () => {
    onClose();
    onOpenDrawerDeleteMenu();
  };

  return (
    <DrawerDeleteMenuFirstStepContainer
      visible={true}
      placement='bottom'
      onClose={onClose}
    >
      <Box className='question-container'>
        <SingleText size={22} spacing='0.42px' align='center' marginBottom={10}>
          Delete Menu
        </SingleText>
        <SingleText size={14} spacing='0.27px' lineHeight='21px'>
          Are you sure you want delete <b>{selectedMenu.name}</b> ?
        </SingleText>
      </Box>
      <SingleButton title='Keep' margin='0px 0px 20px 0px' onClick={onClose} />
      <SingleButton
        title='Delete'
        btnColor={colors.lightGray}
        onClick={onDelete}
      />
    </DrawerDeleteMenuFirstStepContainer>
  );
}

DrawerDeleteMenuFirstStep.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
