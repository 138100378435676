import React from 'react';
import PropTypes from 'prop-types';
import { DrawerBankAccountAddedContainer } from './style';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerBankAccountAdded({ visible, onClose }) {
  return (
    <DrawerBankAccountAddedContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='success-added-image' />
      <div className='check-icon'>
        <i className='fas fa-check' />
      </div>
      <SingleText
        size={22}
        lineHeight='34px'
        spacing='0.42px'
        align='center'
        marginBottom={44}
        width='236px'
      >
        Bank account added successfully!
      </SingleText>
      <SingleButton title='Okay' onClick={onClose} />
    </DrawerBankAccountAddedContainer>
  );
}

DrawerBankAccountAdded.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
