import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerMyAccountContainer = styled(SingleDrawer)`
  padding: 0;
  background-color: ${colors.gray2};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 389px;
    height: 100px;
    background-color: ${colors.gray2};
    position: absolute;
    top: 215px;
  }

  .my-account-top-image-container {
    width: 100%;
    min-height: 215px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    filter: blur(10px);
  }

  .my-account-top {
    position: absolute;
    width: 389px;
    min-height: 215px;
    padding-top: 37px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .close-icon {
      margin-left: 33px;
      cursor: pointer;
      i {
        font-size: 28px;
        font-weight: 400;
        color: white;
      }
    }

    .my-account-top-title-container {
      .my-account-top-title {
        font-size: 22px;
        letter-spacing: 0;
        color: white;
      }
    }
  }

  .account-photo {
    width: 174px;
    height: 174px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 127px;
    left: 111px;
    z-index: 101;
    overflow: hidden;

    &:hover {
      cursor: pointer;
      .btn-change {
        display: block;
      }
    }

    .btn-change {
      cursor: pointer;
      display: none;
      font-size: 16px;
      font-weight: bold;
      color: white;
      background-color: rgba(0, 0, 0, 0.35);
      padding: 14px 0px;
      letter-spacing: 0;
      text-align: center;
      position: relative;
      bottom: -125px;
    }
  }

  .save-changes-container {
    margin-top: 90px;
    width: 100%;
    padding: 0px 26px 43px;
  }

  .file-image-input {
    display: none;
  }

  input[type='file'] {
    display: none;
  }

  .uploading-icon {
    position: absolute;
    top: 22%;
    z-index: 99999999;
  }
`;
