import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

import { BankAccountBg } from 'assets/images/bankAccount';

export const DrawerBankAccountContainer = styled(SingleDrawer)`
  padding: 0;
  background-color: ${colors.gray2};
  display: flex;
  flex-direction: column;

  .drawer-bank-account-top {
    width: 100%;
    min-height: 368px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url(${BankAccountBg});

    padding: 37px 34px 0px;

    .top-control-part {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .close-icon {
        cursor: pointer;
        i {
          font-size: 28px;
          font-weight: 400;
          letter-spacing: 0.53px;
          color: white;
        }
      }

      .top-title-container {
        .top-title {
          font-size: 22px;
          letter-spacing: 0;
          color: white;
        }
      }
    }
  }
`;
