import React from 'react';
import PropTypes from 'prop-types';
import { SingleDrawerContainer } from './style';

export default function SingleDrawer({
  className = '',
  placement = 'right',
  visible = false,
  width = 395,
  height = '100%',
  onClose = () => {},
  children,
}) {
  return (
    <SingleDrawerContainer
      placement={placement}
      key={placement}
      destroyOnClose
      maskClosable
      closable={false}
      width={width}
      height={height}
      className={`${className} ${placement} single-drawer`}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ display: 'flex', flexDirection: 'column' }}
    >
      {children}
    </SingleDrawerContainer>
  );
}

SingleDrawer.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  children: PropTypes.any,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};
