import React, { useState } from 'react';
import { MenuContainer } from './style';
import { MenuHeader, MenuList, CategoriesSwitcher } from './components';

export default function Menu() {
  const [isMenusLoaded, setIsMenusLoaded] = useState(true);
  const [searchFood, setSearchFood] = useState('');

  return (
    <MenuContainer>
      <MenuHeader
        setSearchFood={setSearchFood}
        setIsMenusLoaded={setIsMenusLoaded}
      />
      <CategoriesSwitcher isMenusLoaded={isMenusLoaded} />
      <MenuList searchFood={searchFood} isEditable={false} />
    </MenuContainer>
  );
}
