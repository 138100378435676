import styled from '@emotion/styled';

export const RejectedAlertDrinkContainer = styled.div`
  .rejected-alert-drink-wrapper {
    width: 100%;
    margin: 35px 0 0;
    padding: 72px 20px 0px 20px;
    border-radius: 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #27282a;
    position: absolute;
    bottom: 0px;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
  }

  .rejected-alert-drink-svg-position {
    position: relative;
    bottom: 118px;
    text-align: center;
  }

  .rejected-alert-drink-check-wrapper {
    text-align: center;
  }

  .rejected-alert-drink-times {
    background: #ed2626;
    font-size: 26px;
    border-radius: 64px;
    padding: 19px;
    position: relative;
    bottom: 200px;
    height: 4rem;
    width: 4rem;
  }

  .rejected-alert-drink-body-wrapper {
    margin: -165px 0 12px 0;
  }

  .rejected-alert-drink-text-style {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }

  .rejected-alert-drink-okay-btn {
    width: 100%;
    padding: 13px 0 13px 0;
    border-radius: 8px;
    color: white;
    background: #ed2626;
    border: none;
    font-size: 14px;
    font-weight: 600;
    margin: 25px 0 20px;
  }
`;
