import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DrawerOrderReadyContainer } from './style';
import { colors } from 'theme';
import { PendingOrdersContext } from 'contexts/PendingOrdersContextContainer';
import { CompletedOrdersContext } from 'contexts/CompletedOrdersContextContainer';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerOrderReady({ visible, onClose, onOrderReady }) {
  const {
    changeActiveOrderStatus,
    activeOrder,
    moveActiveOrderToCompletedSection,
  } = useContext(PendingOrdersContext);

  const { onAddToCompletedOrders } = useContext(CompletedOrdersContext);

  const orderReady = () => {
    onOrderReady(activeOrder.id);
    if (activeOrder.status !== 1) {
      changeActiveOrderStatus();
    } else {
      moveActiveOrderToCompletedSection(activeOrder, onAddToCompletedOrders);
    }
  };

  const isActiveOrderAlreadyMarkedReady = !!activeOrder.status;

  return (
    <DrawerOrderReadyContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <SingleText size={22} spacing='0.42px' marginBottom={10} weight={500}>
        {!isActiveOrderAlreadyMarkedReady ? 'Order Ready' : 'Order Delivered'}
      </SingleText>
      <SingleText
        spacing='0.27px'
        lineHeight='21px'
        marginBottom={31}
        width='267px'
        align='center'
      >
        {!isActiveOrderAlreadyMarkedReady
          ? 'The server will receive an alert that the order is ready.'
          : 'Was this order delivered to the customer’s table?'}
      </SingleText>
      <SingleButton
        title={
          !isActiveOrderAlreadyMarkedReady
            ? 'Order Ready'
            : 'Yes, order delivered'
        }
        margin='0px 0px 22px 0px'
        onClick={orderReady}
      />
      <SingleButton
        title='Cancel'
        btnColor='transparent'
        txtColor={colors.redColor}
        onClick={onClose}
      />
    </DrawerOrderReadyContainer>
  );
}

DrawerOrderReady.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onOrderReady: PropTypes.func,
  onCancel: PropTypes.func,
};
