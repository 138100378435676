import styled from '@emotion/styled';
import { colors } from 'theme';

export const TablesContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .tables-tabs-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 35px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    .left-part {
      display: flex;
      align-items: center;
      h3 {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 0;
        margin-right: 80px;
      }
      .navs-container {
        display: flex;
        align-items: center;
        .nav-item {
          cursor: pointer;
          position: relative;
          font-size: 14px;
          font-weight: bold;
          color: rgba(255, 255, 255, 0.5);
          margin-right: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;

          &.active {
            color: rgba(255, 255, 255, 1);
          }
          &.active::after {
            background-color: ${colors.redColor};
          }
        }
        .nav-item::after {
          content: '';
          width: 30px;
          height: 2px;
          display: block;
          position: absolute;
          bottom: -5px;
        }
        .nav-item:last-child {
          margin-right: 0px;
        }
      }
    }
    .right-part {
    }
  }
  .content-container {
    height: 100%;
    overflow: hidden;
    padding-bottom: 20px;
  }

  .list-view-pagination {
    position: absolute;
    padding-top: 35px;
    padding-bottom: 35px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  div {
    margin-right: 16px;
  }
  div:last-child {
    margin-right: 0;
  }
`;
