import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { BankAccountTopDropdownContainer } from './style';

export function BankAccountTopDropdown({ onReplace, onRemove }) {
  return (
    <BankAccountTopDropdownContainer>
      <Dropdown.Toggle className='bank-account-more'>
        <i className='fas fa-ellipsis-h' />
      </Dropdown.Toggle>
      <Dropdown.Menu className='bank-account-dropdown-options'>
        {[
          [
            <i className='fas fa-repeat' key='repeat' />,
            'Replace bank account',
            onReplace,
          ],
          [
            <i className='fal fa-trash' key='remove' />,
            'Remove bank account',
            onRemove,
          ],
        ].map(([icon, title, handler], index) => (
          <Dropdown.Item key={title + index} eventKey={index} onClick={handler}>
            <div className='bank-account-dropdown-item'>
              {icon}
              <span className='title'>{title}</span>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </BankAccountTopDropdownContainer>
  );
}

BankAccountTopDropdown.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onReplace: PropTypes.func,
  onRemove: PropTypes.func,
};
