import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from 'components/Box';
import { FaTimes, FaTrash } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerMenuContainer } from './style';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { colors } from 'theme';
import { StateContext } from 'contexts/StateContextContainer';
import ItemPicker from 'components/DrawerForm/ItemPicker';
import SingleAntDrawerInput from 'components/SingleAntDrawerInput';
import { DataContext } from 'contexts/DataContextContainer';
import { showMessage } from 'services/functions';
import { dayTitles } from 'services/static';
import { restaurantServices } from 'services/api-services';
import { AuthContext } from 'contexts/AuthContextContainer';
import { MenuCategories } from './MenuCategories';
import { DrawerDeleteMenuFirstStepContainer } from './DrawerDeleteMenuFirstStepContainer';

export default function DrawerMenu({
  type = 'Add',
  visible = false,
  placement = 'right',
  // onClose = () => {},
}) {
  const { authUser, restaurantId } = useContext(AuthContext);
  const {
    categories,
    setOpenDrawerMenuAdd,
    setOpenDrawerMenuEdit,
    setOpenDrawerMenuStatus,
    setOpenDrawerAvailabilities,
  } = useContext(StateContext);
  const {
    selectedMenu, setSelectedMenu,
    setSelectedAvailability,
    menus, setMenus, isMenusEditing,
  } = useContext(DataContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const [requiredFields, setRequiredFields] = useState([]);

  const [openDrawerDeleteMenuFirstStepContainer, setOpenDrawerDeletemenuFirstStepContainer] = useState(false);

  const onOpenDrawerDeleteMenuFirstStepContainer = () => setOpenDrawerDeletemenuFirstStepContainer(true);
  const onCloseDrawerDeleteMenuFirstStepContainer = () => setOpenDrawerDeletemenuFirstStepContainer(false);
  useEffect(() => {
    checkAllRequired()
    // eslint-disable-next-line
  }, [selectedMenu])

  function checkAllRequired() {
    const requiredKeys = ['name', 'status', 'availability']
    const translation = { status: 'Status', name: 'Menu Name', availability: 'Availability' };
    const getAddedKeys = Object.keys(selectedMenu);
    let missingKeys = [];
    requiredKeys.forEach((_key) => {
      if (!getAddedKeys.includes(_key)) missingKeys.push(translation[_key]);
      if ((_key === 'name') && selectedMenu[_key] === '') missingKeys.push(translation[_key]);
    })
    setRequiredFields(missingKeys);
  }

  const onClickMenuAdd = async () => {
    setIsProcessing(true)
    const { ok, errMessage } = await restaurantServices.createMenu({ restaurantId: restaurantId }, selectedMenu)
    if (ok) {
      // let tempMenus = [...menus]
      // tempMenus.push(selectedMenu)
      // setMenus([...menus, {...selectedMenu }])
    } else {
      showMessage(errMessage, 'error')
    }
    setIsProcessing(false)
    setOpenDrawerMenuAdd(false)
  }

  const onClickMenuEdit = async () => {
    setIsProcessing(true)

    const { ok, errMessage } = await restaurantServices.updateMenu({ restaurantId: authUser.restaurant_id, menuId: selectedMenu.id }, selectedMenu)
    if (ok) {
      let tempMenus = [...menus]
      setMenus(tempMenus.map(_menu => {
        if (_menu.id === selectedMenu.id) {
          return selectedMenu
        }
        return _menu
      }))
    } else {
      showMessage(errMessage, 'error')
    }
    setIsProcessing(false)
    setOpenDrawerMenuEdit(false)
  }

  const onClickStatus = () => setOpenDrawerMenuStatus(true)

  const onClickAvailability = (availability) => {
    setSelectedAvailability(availability)
    setOpenDrawerAvailabilities(true)
  }

  const onClose = () => {
    if (type === 'Add') setOpenDrawerMenuAdd(false)
    else setOpenDrawerMenuEdit(false)
    setSelectedMenu({ availability: [], status: 0 })
  }

  function onClickSubmit() {
    if (requiredFields.length > 0) {
      showMessage(`${requiredFields.join(', ')} fields are missing`, 'error')
    } else {
      if (type === 'Add') {
        onClickMenuAdd()
      } else {
        onClickMenuEdit()
      }
    }
  }

  return (
    <DrawerMenuContainer
      placement={placement}
      onClose={onClose}
      visible={visible}
    >
      <Box is='div' display='flex' alignItems='center' marginBottom={40}>
        <SingleIconWrapper onClick={onClose}>
          <FaTimes className='cursor' size={20} color='rgba(255, 255, 255, 0.3)' />
        </SingleIconWrapper>
        <Box is='div' display='flex' justifyContent='center' flex={1}>
          <SingleText content={`${type} Menu`} align='center' size={22} weight={500}>{`${type} Menu`}</SingleText>
        </Box>
        {type === 'Edit' && <FaTrash className='cursor' size={20} color='white' onClick={onOpenDrawerDeleteMenuFirstStepContainer} />}
      </Box>
      <Box is='div' className='drawer-menu--options'>
        <SingleAntDrawerInput
          label='Menu Name'
          placeholder='Borderless'
          value={selectedMenu.name}
          disabled={isMenusEditing}
          onChange={e => setSelectedMenu({ ...selectedMenu, name: e.target.value })}
        />

        <ItemPicker
          label='Availability'
          placeholder='Set availability'
          value={selectedMenu?.availability?.length > 0 ? selectedMenu.availability.map(_hour => dayTitles[_hour.day].key).join() : ''}
          onClick={() => onClickAvailability(selectedMenu.availability)}
        />

        <ItemPicker
          label='Status'
          placeholder='Choose status'
          value={selectedMenu.status === 1 ? 'Available' : 'Not Available'}
          onClick={onClickStatus}
        />
      </Box>
      <MenuCategories selectedMenu={selectedMenu} subCategories={categories} />
      <Box is='div' display='flex' flexDirection='column' marginTop='auto'>
        <SingleButton
          title={type === 'Add' ? 'Add Menu' : 'Done'}
          margin='0 0 20px'
          loading={isProcessing}
          btnColor={requiredFields.length > 0 ? '#61646c' : colors.redColor}
          txtColor='white'
          onClick={onClickSubmit}
        />
        <SingleButton
          title='Cancel'
          btnColor='transparent'
          txtColor={colors.redColor}
          onClick={onClose}
        />
      </Box>
      <DrawerDeleteMenuFirstStepContainer
        visible={openDrawerDeleteMenuFirstStepContainer}
        onClose={onCloseDrawerDeleteMenuFirstStepContainer}
      />
    </DrawerMenuContainer>
  )
}

DrawerMenu.propTypes = {
  type: PropTypes.string,
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickAvailability: PropTypes.func,
  onClickStatus: PropTypes.func,
  onClickDeleteMenu: PropTypes.func,
  onClickAddMenu: PropTypes.func,
};
