import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { FullNameContainer } from './style';
import { ProfileUpdateContext } from 'routes';

// @TODO Add additional validation
export function FullName({
  firstName = '',
  lastName = '',
  onChangeFirstName = () => {},
  onChangeLastName = () => {},
}) {
  const { setProfileFirstName, setProfileLastName } =
    useContext(ProfileUpdateContext);

  useEffect(() => {
    setProfileFirstName(firstName);
    setProfileLastName(lastName);
  }, [firstName, lastName, setProfileFirstName, setProfileLastName]);

  return (
    <FullNameContainer>
      <div className='full-name-item'>
        {/* eslint-disable-next-line */}
        <label>First name</label>
        <input
          value={firstName}
          onChange={(event) => onChangeFirstName(event.target.value)}
          type='text'
          placeholder='Enter your first name'
          min={3}
          maxLength={40}
        />
      </div>
      <div className='full-name-item'>
        {/* eslint-disable-next-line */}
        <label>Last Name</label>
        <input
          value={lastName}
          onChange={(event) => onChangeLastName(event.target.value)}
          type='text'
          placeholder='Enter your last name'
          min={3}
          maxLength={40}
        />
      </div>
    </FullNameContainer>
  );
}

FullName.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onChangeFirstName: PropTypes.func,
  onChangeLastName: PropTypes.func,
};
