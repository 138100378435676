import React from 'react';
import { RequestsContainer } from './style';
import { RequestPending } from './RequestPending';
// import { RequestPendingDrawer } from './RequestPending/RequestPendingDrawer';

export default function Requests() {
  return (
    <RequestsContainer>
      <RequestPending />
      {/* <RequestPendingDrawer /> */}
    </RequestsContainer>
  );
}
