import React from 'react';
import PropTypes from 'prop-types';
import { SingleSwitchContainer } from './style';

export default function SingleAntSwitch({
  checked = false,
  onChange = () => {},
  disabled = false,
}) {
  return (
    <SingleSwitchContainer
      className={`${checked ? 'active-button' : ''} ${
        disabled ? 'disabled' : ''
      }`}
      onClick={!disabled ? onChange : () => {}}
    >
      <div className='switch-handle' />
    </SingleSwitchContainer>
  );
}

SingleAntSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
