import { useState } from 'react';

export const optionsByTimeAndRating = [
  'Earliest',
  'Oldest',
  'Highest Rated',
  'Lowest Rated',
];

export const useSortByTimeAndRating = () => {
  const [sortValue, setSortValue] = useState(undefined);
  const onChangeSortValue = (value) => setSortValue(value);

  return {
    sortValue,
    onChangeSortValue,
    optionsByTimeAndRating,
  };
};
