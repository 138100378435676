import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RejectOtherRequestContainer } from './style';
import { RejectConfirmOtherRequest } from './RejectConfirm';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';
import { RequestPendingContext } from 'contexts/RequestPendingContextContainer';
export function RejectOtherRequest({ onClose, removePendingOtherData }) {
  const context = useContext(modalContext);
  const {otherRejectInput, setOtherRejectInput} = useContext(RequestPendingContext)

  function RejectOtherRequestFunc(e) {
    context.setModal('Reject-Other-Modal-3');
    e.preventDefault();
    // @TODO its log for test
    // console.log(otherRejectInput)
  }

  return (
    <RejectOtherRequestContainer>
      {context.modal === 'Reject-Other-Modal-2' ? (
        <>
          <div className='reject-other-other-request-wrapper animate__animated animate__fadeInUp'>
            <div className='reject-other-other-request-padding-wrap-1'>
              <div>
                <p className='reject-other-other-request-heading'>
                  Reason other request <br />
                  can&apos;t be fulfilled.
                </p>
              </div>

              <div className='reject-other-other-request-comment-flex'>
                <p className='reject-other-other-request-comments'>Comments</p>
                <p className='reject-other-other-request-optional'>Optional</p>
              </div>
            </div>

            <div>
              <div className='reject-other-other-request-border-top-style'></div>
              <input
                type='text'
                placeholder='Describe why you are rejecting this request.'
                className='reject-other-other-request-input-style'
                value={otherRejectInput}
                onChange={(e) => setOtherRejectInput(e.target.value)}
              />
              <div className='reject-other-other-request-border-top-style'></div>
            </div>

            <div className='reject-other-other-request-padding-wrap-2'>
              <div>
                <Button
                  variant='none'
                  className='reject-other-other-request-btn'
                  onClick={RejectOtherRequestFunc}
                >
                  Reject Request
                </Button>
              </div>

              <div>
                <Button
                  variant='none'
                  className='reject-other-other-request-keep-btn'
                  onClick={onClose}
                >
                  Keep
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <RejectConfirmOtherRequest onClose={onClose} removePendingOtherData={removePendingOtherData} />
      )}
    </RejectOtherRequestContainer>
  );
}

RejectOtherRequest.propTypes = {
  onClose: PropTypes.func,
  removePendingOtherData: PropTypes.func
};
