import React from 'react';
import PropTypes from 'prop-types';
import { FaPen, FaTrash } from 'react-icons/fa';
import { PopupContentContainer } from './style';

export function PopupContent({
  onClickEdit,
  onClickDelete,
  onDeleteMenu,
  isDraggable,
}) {
  return (
    <PopupContentContainer>
      <div className='popup-item' onClick={onClickEdit}>
        <FaPen className='popup-item-icon' color='white' />
        <span className='edit-title'>Edit</span>
      </div>
      <div
        className='popup-item'
        onClick={isDraggable ? onDeleteMenu : onClickDelete}
      >
        <FaTrash className='popup-item-icon' color='white' />
        <span className='delete-title'>Delete</span>
      </div>
    </PopupContentContainer>
  );
}

PopupContent.propTypes = {
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  onDeleteMenu: PropTypes.func,
  isDraggable: PropTypes.bool,
};
