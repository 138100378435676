import React from 'react';
import PropTypes from 'prop-types';
import { PaymentCardContainer } from './style';

export function PaymentCard({
  className = '',
  type,
  lastFourNumbers,
  expire,
  name,
  onClick = () => {},
  isActive,
}) {
  return (
    <PaymentCardContainer
      className={`${className} ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      <div className='card-container'>
        <div className='card-info-container'>
          <span className='card-info'>
            {type} {lastFourNumbers}
          </span>
        </div>
        <div className='card-expire-container'>
          <span className='expire'>
            Ex {expire.getMonth() + 1}/
            {expire.getFullYear().toString().slice(2)}
          </span>
        </div>
      </div>
      <div className='name-container'>
        <span className='name'>{name}</span>
      </div>
    </PaymentCardContainer>
  );
}

PaymentCard.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  lastFourNumbers: PropTypes.number,
  expire: PropTypes.object,
  name: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};
