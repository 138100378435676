import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerSelectServerWrapper = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  font-family: Montserrat;
  color: #ffffff;
  padding-bottom: 0;
  .panel-header-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .back-arrow {
      width: 50px;
      cursor: pointer;
      position: absolute;
      left: 0;
      i {
        color: #ffffff;
        font-size: 22px;
      }
    }
    .panel-header-context {
      font-size: 22px;
      font-weight: 500;
      color: #ffffff;
    }
  }

  .servers-list {
    margin-left: -24px;
    margin-right: -24px;
    .server-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 24px;
      border-top: 1px solid rgba(255, 255, 255, 0.08);
      cursor: pointer;
      &.active {
        .left-part {
          .image-container {
            position: relative;
            .is-selected {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              border: solid 1px #ffffff;
              background-color: rgba(0, 0, 0, 0.6);
              border-radius: 50%;
            }
          }
        }
      }
      .left-part {
        display: flex;
        align-items: center;
        .image-container {
          margin-right: 15px;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
        .server-name {
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .right-part {
        display: flex;
        align-items: center;
        .table-count {
          margin-right: 20px;
          i {
            color: #ffffff;
            font-size: 16px;
          }
          span {
            margin-right: 7px;
            color: #ffffff;
            font-size: 14px;
            font-weight: bold;
          }
        }
        .arrow-container {
          i {
            font-size: 20px;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
    .server-item:last-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    }
  }
`;
