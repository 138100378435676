import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { DropdownRoleContainer } from './style';
import { StaffRoles } from 'feasttt/domain';

export function DropdownRole({ roleSlug, onChangeRole }) {
  return (
    <DropdownRoleContainer className='role-dropdown-container'>
      <DropdownButton
        className='role-dropdown'
        variant='outline-primary'
        title={
          roleSlug && roleSlug !== 'none'
            ? StaffRoles.readRole(roleSlug)
            : 'Choose Role'
        }
      >
        {StaffRoles.allRoles.map((role) => (
          <Dropdown.Item onSelect={() => onChangeRole(role)} key={role}>
            {StaffRoles.readRole(role)}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </DropdownRoleContainer>
  );
}

DropdownRole.propTypes = {
  roleSlug: PropTypes.string,
  onChangeRole: PropTypes.func,
};
