import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RequestPendingSideDataContainer } from './style';
import { RequestPendingDrawer } from '../RequestPendingDrawer';
import { RejectRequestPendingDrawer } from '../RejectReturnRequestPendingDrawer';
import { DrinkRefillRequestPendingDrawer } from '../DrinkRefillRequestPendingDrawer';
import { OtherRequestPendingDrawer } from '../OtherRequestPendingDrawer';
import { RejectDrinkRequestPendingDrawer } from '../RejectDrinkRefillRequestPendingDrawer';
import { RejectOtherPendingDrawer } from '../RejectOtherRequestPendingDrawer';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';
import TimeStamp from 'components/TimeStamp';
import { RequestPendingContext } from 'contexts/RequestPendingContextContainer';
import { requestsServices } from 'services/api-services';
import useLocalStorage from 'react-use-localstorage';
import { DefaultUser } from 'assets/images/profile';
import { colors } from 'theme';
import ReviewReturnModal from './ReturnReviewModal';
export function RequestPendingSideData({
  index,
  pending,
  completed,
  comIndex,
  conditionPendingCompleted,
}) {
  const [restaurantId, setRestaurantId] = useLocalStorage('feasttt-restaurant-id','');
  // const [completedInfo, setCompletedInfo] = useState([]);
  // const [pendingInfo, setPendingInfo] = useState([]);
  const [rejectValue, setRejectValue] = useState();
  const [rejectValueDrinkRefill, setRejectValueDrinkRefill] = useState();
  const [rejectValueOther, setRejectValueOther] = useState();
  const context = useContext(modalContext);
  const {
    selectedPendingData,
    selectedCompletedData,
    targetFoodCompletedOptionsText,
    displayPercentageDigit,
    pendingInfo,
    setPendingInfo,
    completedInfo,
    setCompletedInfo,
    rejectDrinkRefresh, 
    drinkOptionsText,
    otherOptionsText,
    otherOptionsDigit,
  } = useContext(RequestPendingContext);

  const {reject_reason, request_type, done_by} = completedInfo
  const contextRequestType = pendingInfo

  // return-request pending **drawer**
  const [openDrawerRequestPending, setOpenDrawerRequestPending] = useState(false);
  const onOpenDrawerRequestPending = () => setOpenDrawerRequestPending(true);
  const onCloseDrawerRequestPending = () => setOpenDrawerRequestPending(false);

  // reject return-request pending **drawer**
  const [openDrawerRejectReturnPending, setOpenDrawerRejectReturnPending] = useState(false);
  const onOpenDrawerRejectReturnPending = () => {
    setOpenDrawerRejectReturnPending(true);
    context.setModal('Modal-1');
    setRejectValue(2);
  };
  const onCloseDrawerRejectReturnPending = () =>setOpenDrawerRejectReturnPending(false);

  // drink-refill request pending **drawer**
  const [openDrinkRefillRequestPending, setOpenDrinkRefillRequestPending] = useState(false);
  const onOpenDrinkRefillRequestPending = () => setOpenDrinkRefillRequestPending(true);
  const onCloseDrinkRefillRequestPending = () => setOpenDrinkRefillRequestPending(false);

  // reject drink-refill request pending **drawer**
  const [openRejectDrinkRequestPendingDrawer, setOpenRejectDrinkRequestPendingDrawer] = useState(false);
  const onOpenRejectDrinkRequestPendingDrawer = () => {
    setOpenRejectDrinkRequestPendingDrawer(true);
    context.setModal('Reject-Drink-Modal-1');
    setRejectValueDrinkRefill(2);
  };
  const onCloseRejectDrinkRequestPendingDrawer = () => setOpenRejectDrinkRequestPendingDrawer(false);

  // other request pending **drawer**
  const [openOtherRequestPendingDrawer, setOpenOtherRequestPendingDrawer] = useState(false);
  const onOpenOtherRequestPendingDrawer = () => setOpenOtherRequestPendingDrawer(true);
  const onCloseOtherRequestPendingDrawer = () => setOpenOtherRequestPendingDrawer(false);

  // reject other request pending **drawer**
  const [openRejectDrawerRequestPending, setOpenRejectDrawerRequestPending] = useState(false);
  const onOpenRejectDrawerRequestPending = () => {
    setOpenRejectDrawerRequestPending(true);
    context.setModal('Reject-Other-Modal-1');
    setRejectValueOther(2)
  };
  const onCloseRejectDrawerRequestPending = () => setOpenRejectDrawerRequestPending(false);

  // @TODO review-modal
  const [openDrawerReturnReviewModal, setOpenDrawerReturnReviewModal] = useState(false);
  // return
  const onOpenDrawerReturnReviewModal = () => {
    setOpenDrawerReturnReviewModal(true)
    setDoneStatus(1)
    // test
    // console.log('clicked for return')
  }
  // drink
  const onOpenDrawerReturnReviewModalDrink = () => {
    setOpenDrawerReturnReviewModal(true)
    // test
    // console.log('clicked for drink-refill')
  }
   // other
   const onOpenDrawerReturnReviewModalOther = () => {
    setOpenDrawerReturnReviewModal(true)
    // test
    // console.log('clicked for other')
  }
  const onCloseDrawerReturnReviewModal = () => setOpenDrawerReturnReviewModal(false);

  useEffect(() => {}, [rejectDrinkRefresh])

  // done-status state
  const [doneStatus, setDoneStatus] = useState()

  // const reqId = selectedPendingData.id;
  const reqIdP = selectedPendingData.id;
  const resIdC = selectedCompletedData.id

  // get api details for completed
  useEffect(() => {
    if(resIdC) {
    const resId = restaurantId;
    const reqId = selectedCompletedData.id;
    requestsServices
      .getRequestToCompleted(resId, reqId)
      .then((res) => {
        setCompletedInfo(res.data);
      })
      .catch((err) => err);
    }
  }, [restaurantId, selectedCompletedData.id]);

 
  // get api details for pending
  useEffect(() => {
    if(reqIdP) {
      const resId = restaurantId;
      const reqId = selectedPendingData.id;
      requestsServices
        .getRequestToCompleted(resId, reqId)
        .then((res) => {
          setPendingInfo(res.data);
        })
        .catch((err) => err);
    }    
  }, [restaurantId, selectedPendingData.id]);

  return (
    <RequestPendingSideDataContainer>
      <div className='request-detail-wrapper'>
        {conditionPendingCompleted ? (
          <>
            {pending ? (
              <>
                {/* 1st card*/}
                <div className='side-dark-card'>
                  <div className='inner-flex'>
                    <div>
                      <span className='badge badge-secondary'>
                        <i
                          className={
                            (selectedPendingData.request_type === 0 &&
                              'far fa-glass-cheers') ||
                            (selectedPendingData.request_type === 1 &&
                              'far fa-exchange-alt') ||
                            (selectedPendingData.request_type === 2 &&
                              'far fa-exclamation-circle')
                          }
                          id='icon-style'
                          style={{ zIndex: 1 }}
                        ></i>
                      </span>
                    </div>
                    <div className='padding-horizontal'></div>
                    <div className='title-table-wrapper'>
                      <p className='title-text'>
                        <strong>
                          {(selectedPendingData.request_type === 1 &&
                            'Return Request') ||
                            (selectedPendingData.request_type === 2 &&
                              'Other Request') ||
                            (selectedPendingData.request_type === 0 &&
                              'Drink Refill Request')}
                        </strong>
                      </p>
                      <p>
                        Table {selectedPendingData.table_number} •{' '}
                        {selectedPendingData.token}
                      </p>
                    </div>
                  </div>
                  <div className='title-table-wrapper'>
                    {selectedPendingData.request_type === 1 ? (
                      <>
                        <p
                          className='reject-text'
                          onClick={onOpenDrawerRejectReturnPending}
                        >
                          Reject
                        </p>
                      </>
                    ) : (
                      <div></div>
                    )}

                    {selectedPendingData.request_type === 0 ? (
                      <>
                        <p
                          className='reject-text'
                          onClick={onOpenRejectDrinkRequestPendingDrawer}
                        >
                          Reject
                        </p>
                      </>
                    ) : (
                      <div></div>
                    )}

                    {selectedPendingData.request_type === 2 ? (
                      <>
                        <p
                          className='reject-text'
                          onClick={onOpenRejectDrawerRequestPending}
                        >
                          Reject
                        </p>
                      </>
                    ) : (
                      <div></div>
                    )}

                    <span className="time-stamp">
                      <TimeStamp date={Date.now() - selectedPendingData.wait_seconds * 1000} />
                    </span>
                  </div>
                </div>

                {/* 2nd */}
                {pendingInfo.items &&
                  pendingInfo.items.map((foods, index) => {

                    return (
                    <div className='completed-food-wrapper' key={index}>
                      <div className='completed-flex'>
                        <div className='completed-number'>
                          {foods?.quantity}
                        </div>
                        <div className='completed-space-between'></div>
                        <div className='completed-flex-column'>
                          <div className='completed-food-name'>
                            {foods?.food_item?.food_name}
                          </div>

                          {/* <div className='completed-sides-line space-padding'>
                            Sides: Mashed Potatoes, Steamed Brocolli, Side Salad
                          </div> */}

                          {/* <div className='completed-full-refund'>
                            Full Refund
                          </div> */}

                          {contextRequestType.request_type === 1 ? (
                            <div
                              className='completed-full-refund'
                              onClick={onOpenDrawerRequestPending}
                            >
                              {targetFoodCompletedOptionsText ? (
                                <span style={{ color: `${colors.lightGreen}` }}>
                                  {targetFoodCompletedOptionsText}{' '}
                                  {displayPercentageDigit}
                                </span>
                              ) : (
                                <span style={{ color: `${colors.redColor}` }} className="pointer">
                                  Choose how to resolve <i className="fas fa-chevron-right arrow-icon"></i>
                                </span>
                              )}
                            </div>
                          ) : (
                            ''
                          )}

                          {contextRequestType.request_type === 0 ? (
                            <div
                                className='completed-full-refund'
                                onClick={onOpenDrinkRefillRequestPending}
                              >
                              {drinkOptionsText? (
                                  <span style={{ color: `${colors.lightGreen}` }} className="pointer">
                                    {drinkOptionsText}
                                  </span>
                                ) : (
                                  <span style={{ color: `${colors.redColor}` }} className="pointer">
                                    Choose how to resolve
                                  </span>
                              )}
                            </div>
                          ) : (
                            ''
                          )}

                          {contextRequestType.request_type === 2 ? (
                            <div
                              className='completed-full-refund'
                              onClick={() => {
                               const tempArray = pendingInfo.items.map((item) => {
                                     return {
                                       ...item,
                                       selected: item.id === foods?.id 
                                     }
                                })
                                setPendingInfo({...pendingInfo, items : tempArray})
                                onOpenOtherRequestPendingDrawer()
                              }}
                              aria-hidden="true" 
                            >
                              {foods.selectedResolveType ? (
                                  <span style={{ color: `${colors.lightGreen}` }} className="pointer">
                                    {foods.selectedResolveType === 2 ? 'Fulfilled':<span style={{color:'#ed2626'}}>Rejected</span>}
                                  </span>
                                ) : (
                                  <span style={{ color: `${colors.redColor}` }} className="pointer">
                                    Choose how to resolve
                                  </span>
                              )}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className='completed-food-cost'>
                        ${foods?.food_item?.price}
                      </div>
                    </div>
                  )})}

                {/* 5th card*/}
                <div className='comment-wrapper'>
                  <div>
                    <p>
                      <strong>Comment</strong>
                    </p>
                  </div>
                  <div>
                    <p>{selectedPendingData.comment}</p>
                  </div>
                </div>

                {/* return request button */}
                {contextRequestType.request_type === 1 ? <div className='button-wrapper'>
                  <div>
                    <p className='text-style'>$31.38 will be refunded</p>
                  </div>
                  <div>
                    <Button
                      variant='none'
                      size='lg'
                      className='btn-width btn-style'
                      disabled={targetFoodCompletedOptionsText ? false : true}
                      style={{
                        background: displayPercentageDigit
                          ? `${colors.redColor}`
                          : `${colors.gray14}`,
                      }}
                      onClick={onOpenDrawerReturnReviewModal}
                    >
                      <span className='btn-text-color'>Complete Request</span>
                    </Button>
                  </div>
                </div> : ''}

                {/* drink-refill button */}
                {contextRequestType.request_type === 0 ? <div className='button-wrapper'>
                  <div>
                    <p className='text-style'>$31.38 will be refunded</p>
                  </div>
                  <div>
                    <Button
                      variant='none'
                      size='lg'
                      className='btn-width btn-style'
                      disabled={drinkOptionsText ? false : true}
                      style={{
                        background: drinkOptionsText ? `${colors.redColor}` : `${colors.gray14}`
                      }}
                      onClick={onOpenDrawerReturnReviewModalDrink}
                    >
                      <span className='btn-text-color'>Complete Request</span>
                    </Button>
                  </div>
                </div> : ''}

                {/* other-request button */}
                {contextRequestType.request_type === 2 ? <div className='button-wrapper'>
                  <div>
                    <p className='text-style'>$31.38 will be refunded</p>
                  </div>
                  <div>
                    <Button
                      variant='none'
                      size='lg'
                      className='btn-width btn-style'
                      disabled={otherOptionsText ? false : true}
                      style={{
                        background: otherOptionsText ? `${colors.redColor}` : `${colors.gray14}`
                      }}
                      onClick={onOpenDrawerReturnReviewModalOther}
                    >
                      <span className='btn-text-color'>Complete Request</span>
                    </Button>
                  </div>
                </div> : ''}
              </>
            ) : (
              <p className='sidebar-text'>
                <span>Select request for displaying.</span>
              </p>
            )}
          </>
        ) : (
          <>
            {completed ? (
              <>
                {/* 1st */}
                <div className='completed-dark-card'>
                  <div className='completed-inner-flex'>
                    <div>
                      <RequestPendingSideDataContainer
                        isBadgeBackground={reject_reason === 0 || 
                         reject_reason === 1 || 
                         reject_reason === 2 || 
                         reject_reason === 3 || 
                         reject_reason === 4
                        }
                      >
                        <span className='badge completed-badge-secondary'>
                          <i
                            className={
                              (selectedCompletedData.request_type === 0 &&
                                'far fa-glass-cheers') ||
                              (selectedCompletedData.request_type === 1 &&
                                'far fa-exchange-alt') ||
                              (selectedCompletedData.request_type === 2 &&
                                'far fa-exclamation-circle')
                            }
                            id='icon-style'
                          ></i>
                        </span>
                      </RequestPendingSideDataContainer>
                    </div>
                    <div className='completed-padding-horizontal'></div>
                    <div className='completed-title-table-wrapper'>
                      <p className='title-text'>
                        <b>
                          {(selectedCompletedData.request_type === 0 &&
                            'Drink Refill Request') ||
                            (selectedCompletedData.request_type === 1 &&
                              'Return Request') ||
                            (selectedCompletedData.request_type === 2 &&
                              'Other Request')}
                        </b>
                      </p>
                      <p>
                        Table {selectedCompletedData.table_number} • #
                        {selectedCompletedData.token}
                      </p>
                    </div>
                  </div>
                  <div
                    className='completed-title-table-wrapper'
                    style={{ marginTop: '53px' }}
                  >

                      {/* <i className='fad fa-clock'></i> */}
                      <span className="time-stamp">
                        <TimeStamp
                          date={
                            Date.now() -
                            selectedCompletedData.wait_seconds * 1000
                          }
                        />
                      </span>
                  </div>
                </div>

                {/* 2nd */}
                {completedInfo.items &&
                  completedInfo.items.map((completed_food, index) => (
                    <div className='completed-food-wrapper' key={`food-completed ${index}`}>
                      <div className='completed-flex'>
                        <div className='completed-number'>{completed_food?.quantity}</div>
                        <div className='completed-space-between'></div>
                        <div className='completed-flex-column'>
                          <div className='completed-food-name'>
                            {completed_food?.food_item?.food_name}
                          </div>

                          {/* <div className='completed-sides-line space-padding'>
                            Sides: Mashed Potatoes, Steamed Brocolli, Side Salad
                          </div> */}

                          {request_type === 1 ? <div className='completed-full-refund'>
                            {completed_food.resolve_type === 2 ? 'Full Refund:' : '' }
                            {completed_food.resolve_type === 3 ? 'Partial Refund: ' : '' }
                            {completed_food.resolve_type === 1 ? '' : '' }
                            {completed_food.resolve_type === 4 ? 'Replace Item:' : '' }

                            {completed_food?.partial === 0.1 && '10%' ||
                             completed_food?.partial === 0.25 && '25%' ||
                             completed_food?.partial === 0.5 && '50%' ||
                             completed_food?.partial === 0.7 && '70%'
                            }
                          </div> : ''}

                          {request_type === 0 ? <div className='completed-full-refund'>
                            {completed_food.resolve_type === 2 ? 'Fulfilled' : '' }
                            {completed_food.resolve_type === 3 ? '3: ' : '' }
                            {completed_food.resolve_type === 1 ? '' : '' }
                            {completed_food.resolve_type === 4 ? '4:' : '' }
                          </div> : ''}

                          {request_type === 2 ? <div className='completed-full-refund'>
                            {completed_food.resolve_type === 2 ? 'Fulfilled' : '' }
                            {completed_food.resolve_type === 3 ? '3: ' : '' }
                            {completed_food.resolve_type === 1 ? '' : '' }
                            {completed_food.resolve_type === 4 ? '4:' : '' }
                          </div> : ''}

                        </div>
                      </div>
                      <div className='completed-food-cost'>${completed_food?.food_item?.price}</div>
                    </div>
                  ))}

                {/* 5th */}
                <div className='completed-comment-wrapper'>
                  <div>
                    <p>
                      <b>Comment</b>
                    </p>
                  </div>
                  <div>
                    <p>{selectedCompletedData.comment}</p>
                  </div>
                </div>

                {/* completed-by card */}
                <div className='completed-card-wrapper'>
                  <div>
                    {completedInfo.reject_reason === 0 ||
                    completedInfo.reject_reason === 1 ||
                    completedInfo.reject_reason === 2 ||
                    completedInfo.reject_reason === 3 ||
                    completedInfo.reject_reason === 4
                      ? '$0.00'
                      : '$11.00'}{' '}
                    was refunded.
                  </div>
                  <img
                    src={done_by?.avatar ? done_by?.avatar?.file : DefaultUser}
                    alt='user-avatar'
                    id='avatar-style'
                  />
                  <div>
                    <span className='completed-completed-by'>
                      {completedInfo.reject_reason === 0 ||
                      completedInfo.reject_reason === 1 ||
                      completedInfo.reject_reason === 2 ||
                      completedInfo.reject_reason === 3 ||
                      completedInfo.reject_reason === 4
                        ? 'Rejected by'
                        : 'Completed by'}
                    </span>{' '}
                    {done_by?.first_name} {done_by?.last_name}
                  </div>
                  <br />
                  <div style={{ color: '#ed2626' }}>
                    {completedInfo.reject_reason === 0 ||
                    completedInfo.reject_reason === 1 ||
                    completedInfo.reject_reason === 2 ||
                    completedInfo.reject_reason === 3 ||
                    completedInfo.reject_reason === 4
                      ? 'Reason: '
                      : ''}
                    {/* Reason:{' '} */}
                    <strong>
                      {(completedInfo.reject_reason === 0 && 'Other') ||
                        (completedInfo.reject_reason === 1 && 'Out of Stock') ||
                        (completedInfo.reject_reason === 2 && 'Bar closed') ||
                        (completedInfo.reject_reason === 3 &&
                          'Request by mistake') ||
                        (completedInfo.reject_reason === 4 && 'Unreasonable')}
                    </strong>
                  </div>
                  <div style={{ color: '#727373' }}>
                    <strong>{completedInfo.reject_comment}</strong>
                  </div>
                </div>
              </>
            ) : (
              <p className='sidebar-text'>
                <span>Select request for displaying.</span>
              </p>
            )}
          </>
        )}
      </div>

      {/* return-request pending drawer */}
      <RequestPendingDrawer
        visible={openDrawerRequestPending}
        onClose={onCloseDrawerRequestPending}
      />

      {/* reject return-request pending drawer  */}
      <RejectRequestPendingDrawer
        visible={openDrawerRejectReturnPending}
        onClose={onCloseDrawerRejectReturnPending}
        rejectValue={rejectValue}
      />

      {/* drink-refill request pending drawer  */}
      <DrinkRefillRequestPendingDrawer
        visible={openDrinkRefillRequestPending}
        onClose={onCloseDrinkRefillRequestPending}
      />

      {/* reject drink-refill request pending drawer */}
      <RejectDrinkRequestPendingDrawer
        visible={openRejectDrinkRequestPendingDrawer}
        onClose={onCloseRejectDrinkRequestPendingDrawer}
        rejectValueDrinkRefill={rejectValueDrinkRefill}
      />

      {/* other request pending drawer */}
      <OtherRequestPendingDrawer
        visible={openOtherRequestPendingDrawer}
        onClose={onCloseOtherRequestPendingDrawer}
      />

      {/* reject other request pending drawer */}
      <RejectOtherPendingDrawer
        visible={openRejectDrawerRequestPending}
        onClose={onCloseRejectDrawerRequestPending}
        rejectValueOther={rejectValueOther}
      />

      {/* return-review modal */}
      <ReviewReturnModal
        visible={openDrawerReturnReviewModal}
        onClose={onCloseDrawerReturnReviewModal}
        doneStatus={doneStatus}
      />
    </RequestPendingSideDataContainer>
  );
}

RequestPendingSideData.propTypes = {
  index: PropTypes.number,
  comIndex: PropTypes.number,
  pending: PropTypes.bool,
  completed: PropTypes.bool,
  conditionPendingCompleted: PropTypes.bool,
};
