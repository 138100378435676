import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Box from 'components/Box';
import { FaChevronLeft } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import { DrawerFoodStatusContainer } from './style';
import SingleIconWrapper from 'components/SingleIconWrapper';

export default function DrawerFoodStatus({
  visible = false,
  placement = 'right',
  value = 1,
  onClose = () => { },
  onSubmit = () => { },
}) {

  const [active, setActive] = useState(value);

  const onClickStatus = (value) => {
    setActive(value);
    onSubmit(value)
  }

  return (
    <DrawerFoodStatusContainer
      onClose={onClose}
      visible={visible}
      placement={placement}
    >
      <Box display='flex' alignItems='center' marginBottom={40}>
        <SingleIconWrapper onClick={onClose}>
          <FaChevronLeft size={20} color='white' />
        </SingleIconWrapper>
        <Box display='flex' justifyContent='center' flex={1}>
          <SingleText content='Status' align='center' size={22} weight={500}>Status</SingleText>
        </Box>
      </Box>
      <Box className='drawer-status--options'>
        <Box className={`drawer-status--option ${active === 1 ? 'active' : 'inactive'}`} cursor="pointer" marginBottom={15} onClick={() => onClickStatus(1)}>
          <SingleText content='Active' size={14} weight={500} spacing='-0.34'>Active</SingleText>
        </Box>
        <Box className={`drawer-status--option ${active === 0 ? 'active' : 'inactive'}`} cursor="pointer" onClick={() => onClickStatus(0)}>
          <SingleText content='Inactive' size={14} width='fit-content' weight={500} spacing='-0.34'>Inactive</SingleText>
          {/* <SingleText content='Not visible to customers' width='fit-content' size={14} opacity={0.5} weight={500} spacing='-0.34'>Not visible to customers</SingleText> */}
        </Box>
      </Box>
    </DrawerFoodStatusContainer>
  )
}

DrawerFoodStatus.propTypes = {
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  value: PropTypes.number,
};
