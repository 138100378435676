import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerFeaturedImageWrapper = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  .featured-images-panel {
    .panel-header-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      .back-arrow {
        width: 50px;
        cursor: pointer;
        position: absolute;
        left: 0;
        i {
          color: #ffffff;
          font-size: 22px;
        }
      }
      .panel-header-context {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
      }
      .add-new-container {
        width: 30px;
        cursor: pointer;
        position: absolute;
        right: 0;
        i {
          color: #ed2626;
          font-size: 20px;
          vertical-align: middle;
        }
      }
      .file-image-input {
        display: none;
      }
    }
    .panel-status-container {
      margin-bottom: 20px;
      margin-top: 70px;
      .featured-images-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        align-items: center;
        .image-item {
          cursor: pointer;
          position: relative;
          margin-bottom: 10px;
          width: 160px;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          background-color: rgba(255, 255, 255, 0.6);
          margin-right: 10px;
          .delete-button-container {
            position: absolute;
            width: 30px;
            height: 30px;
            top: 5px;
            right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.6);
            border-radius: 50%;
            .fa-trash {
              font-size: 12px;
              color: #ffffff;
            }
          }
        }
      }
    }
    .uploading-icon-container {
        display: flex;
        justify-content: center;
    }
  }
`;
