import React from 'react'
import PropTypes from 'prop-types';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerResetNutritionContainer } from './style';
import { colors } from 'theme';

export default function DrawerResetNutrition({
  visible = false,
  placement = 'bottom',
  onClose = () => { },
  clearHiddenNutrition,
  clearSelectedNutrition,
}) {

  const onClickReset = () => {
    clearSelectedNutrition();
    clearHiddenNutrition();
    onClose();
  }

  return (
    <DrawerResetNutritionContainer
      placement={placement}
      onClose={onClose}
      height='fit-content'
      visible={visible}
    >
      <Box is='div' display='flex' flexDirection='column' alignItems='flex-start' marginBottom={30}>
        <SingleText content='Reset Nutrition' size={22} weight={500} marginBottom={10}>Reset Nutrition</SingleText>
        <span style={{ fontSize: 14, fontFamily: 'Montserrat', lineHeight: 1.5, letterSpacing: '0.27px', color: 'white' }}>
          Are you sure you want to reset nutrition?
          </span>
      </Box>
      <Box is='div' display='flex' flexDirection='column'>
        <SingleButton
          title='Reset'
          btnColor={colors.redColor}
          txtColor='white'
          margin='0 0 20px'
          onClick={onClickReset}
        />
        <SingleButton
          title='Keep'
          btnColor='#61646c'
          txtColor='white'
          margin='0 0 20px'
          onClick={onClose}
        />
      </Box>
    </DrawerResetNutritionContainer>
  )
}

DrawerResetNutrition.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  clearHiddenNutrition: PropTypes.func,
  clearSelectedNutrition: PropTypes.func,
};
