import React from 'react';
import { sortableContainer } from 'react-sortable-hoc';
import { SortableListContainer } from 'components/SingleDragger/style';

export const SortableList = sortableContainer(({ children }) => {
  return (
    <SortableListContainer id='drag-customization-options'>
      {children}
    </SortableListContainer>
  );
});
