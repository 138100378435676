import React from 'react';
import PropTypes from 'prop-types';
import { PendingContainer } from './style';
import { OrdersToHandle } from './OrdersToHandle';
import { OrderToSee } from './OrderToSee';
import { DrawerChangeToPendingContainer } from './DrawerChangeToPendingContainer';

export function Pending({
  openDrawerChangeToPendingContainer,
  setOpenDrawerChangeToPendingContainer,
  openDrawerDeleteOrderContainer,
  setOpenDrawerDeleteOrderContainer,
}) {
  const onCloseDrawerChangeToPendingContainer = () =>
    setOpenDrawerChangeToPendingContainer(false);

  const onCloseDrawerDeleteOrderContainer = () =>
    setOpenDrawerDeleteOrderContainer(false);

  return (
    <PendingContainer>
      <OrdersToHandle />
      <OrderToSee
        openDrawerDeleteOrderContainer={openDrawerDeleteOrderContainer}
        onCloseDrawerDeleteOrderContainer={onCloseDrawerDeleteOrderContainer}
      />
      <DrawerChangeToPendingContainer
        visible={openDrawerChangeToPendingContainer}
        onClose={onCloseDrawerChangeToPendingContainer}
      />
    </PendingContainer>
  );
}

Pending.propTypes = {
  openDrawerDeleteOrderContainer: PropTypes.bool,
  setOpenDrawerDeleteOrderContainer: PropTypes.func,
  openDrawerChangeToPendingContainer: PropTypes.bool,
  setOpenDrawerChangeToPendingContainer: PropTypes.func,
};
