import customAxios from 'services/customAxios';

const uploadToS3 = async (payload, onOk, onFail) => {
  const { ok, data, errMessage } = await customAxios.post(
    '/asset/upload',
    payload
  );
  if (ok) {
    onOk(data);
  } else {
    onFail(errMessage);
  }
};

export const assetServices = {
  uploadToS3,
};
