import React, { useRef } from 'react'
import PropTypes from 'prop-types';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { SingleLocationSearchContainer } from './style';
import useOutsideClick from 'components/OutsideClickDetect';
import { FaSearch } from 'react-icons/fa';
import SingleText from 'components/SingleText';

export default function SingleLocationSearch({
  label = '',
  address = '',
  onChange = () => {},
}) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
    defaultValue: address,
  });
  const ref = useRef();

  useOutsideClick(ref, () => {
    clearSuggestions();
  })

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log("📍 Coordinates: ", { lat, lng });
        onChange([lat, lng], description);
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div className="suggestion-item" key={place_id} onClick={handleSelect(suggestion)}>
          <div className="suggestion-main">{main_text}</div>
          <div className="suggestion-secondary">{secondary_text}</div>
        </div>
      );
    });

  return (
    <SingleLocationSearchContainer ref={ref}>
      <div className="title-container">
        {label && <SingleText size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px'>{label}</SingleText>}
      </div>
      <div className="address-container">
        <input
          className="address-input"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Enter an address"
        />
        {value
          ? <div className="clear-address" style={{ cursor: 'pointer' }} onClick={() => setValue('')}>
            <i className="fas fa-times" />
          </div>
          : <div className="clear-address" style={{ opacity: '0.2'}}>
            <FaSearch />
          </div>
        }
      </div>
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <div className="suggestions">{renderSuggestions()}</div>}
    </SingleLocationSearchContainer>
  );
}

SingleLocationSearch.propTypes = {
  label: PropTypes.string,
  address: PropTypes.string,
  onChange: PropTypes.func,
};
