import React from 'react';
import PropTypes from 'prop-types';
import { OrderContainer } from './style';
import TimeStamp from 'components/TimeStamp';

export function Order({
  type = Order.types.pending,
  className = '',
  waitTime,
  tableName,
  code,
  total,
  guests,
  items,
}) {
  return (
    <OrderContainer className={`${type} ${className}`}>
      <div className='order-ready-icon'>
        {type === Order.types.ready ? (
          <i className='fas fa-concierge-bell' />
        ) : (
          <i className='fas fa-receipt' />
        )}
      </div>
      <div className='order-ready-container'>
        <div className='order-title-container'>
          <div className='order-title-inner-container'>
            <span className='order-title'>
              {type === Order.types.ready ? 'Order Ready' : 'Pending'}
            </span>
          </div>
          <TimeStamp date={Date.now() - (waitTime * 1000)} />
        </div>
        <div className='table-name-code-container'>
          <span className='name-code-title'>
            {tableName} • {code}
          </span>
        </div>
        <div className='table-info-container'>
          <span className='table-info-title'>
            ${total.toFixed(2)} • {guests} Guest • {items} Item
          </span>
        </div>
      </div>
    </OrderContainer>
  );
}

Order.types = {
  pending: 'pending',
  ready: 'ready',
};

Order.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  waitTime: PropTypes.number,
  tableName: PropTypes.string,
  code: PropTypes.string,
  total: PropTypes.number,
  guests: PropTypes.number,
  items: PropTypes.number,
};
