import styled from '@emotion/styled';

export const MenuCategoriesContainer = styled.div`
  margin-top: 66px;
  margin-bottom: 175px;
  display: flex;
  flex-direction: column;

  .tip {
    font-size: 12.5px;
    letter-spacing: -0.29px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 23px;

    .tip-example {
      font-style: italic;
    }
  }

  .sub-category {
    cursor: pointer;
    padding: 24px 21px;
    margin-bottom: 11px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-with-control {
      display: flex;
      align-items: center;

      .drag-icon {
        width: 20px;
        height: 20px;
        margin-right: 19px;
        font-size: 18px;
        color: rgba(255, 255, 255, 0.3);

        .drag-icon-wrapper {
          width: 20px;
          height: 20px;
        }
      }
    }

    .delete-icon {
      font-size: 19px;
      color: rgba(255, 255, 255, 0.3);
      display: flex;
      justify-content: flex-end;

      .delete-icon-wrapper {
      }
    }
  }

  .add-category-container {
    cursor: pointer;
    padding: 20px;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.08);
  }
`;
