import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DataContext } from 'contexts/DataContextContainer';
import { FoodHighCategoriesDropdownContainer } from './style';

export function FoodHighCategoriesDropdown({ minWidth = 235 }) {
  const { menus, chosenMenu, setChosenMenu } = useContext(DataContext);
  const onChangeChosenMenuHere = (newValue) => setChosenMenu(newValue);
  const options = menus.map(({ name, id }) => ({
    label: (
      <>
        {name}
        <span className='option-category-schedule'>(11am-4pm)</span>
      </>
    ),
    value: id,
  }));

  const isLongNamesFromMenu = isNamesFromMenuItemsHasLongNames(menus);

  const calculateWidthBasedOnLengthForNamesFromMenu = () => {
    const additionalWidth =
      Math.max(...menus.map(({ name }) => name.length)) + 140;

    return minWidth + additionalWidth;
  };

  return chosenMenu ? (
    <FoodHighCategoriesDropdownContainer
      className={`high-categories-switcher ${
        isLongNamesFromMenu ? 'larger' : ''
      }`}
      innerMenuWidth={
        isLongNamesFromMenu
          ? calculateWidthBasedOnLengthForNamesFromMenu()
          : minWidth
      }
      options={options}
      value={labelByChosenMenu(chosenMenu, options)}
      onChange={onChangeChosenMenuHere}
      width={minWidth}
      iconOpen={
        <div className='select-icon-container'>
          <div className='light-indicator active' />
          <i className='fas fa-angle-up' />
        </div>
      }
      iconClosed={
        <div className='select-icon-container'>
          <div className='light-indicator active' />
          <i className='fas fa-angle-right' />
        </div>
      }
    />
  ) : (
    ''
  );
}

FoodHighCategoriesDropdown.propTypes = {
  minWidth: PropTypes.number,
};

export const labelByChosenMenu = (chosenMenu, options) =>
  options.find(({ value = '' } = {}) => chosenMenu === value);

export const isNamesFromMenuItemsHasLongNames = (menus) =>
  menus.some(({ name }) => name.length > 10);
