import React from 'react';
import PropTypes from 'prop-types';
import { useOrders, useSwitchPeriod } from 'hooks';
import { computeRatingForCompletedOrder } from 'services/functions';
import { CompletedOrdersPeriods } from 'feasttt/domain';

export const CompletedOrdersContext = React.createContext(null);

export function CompletedOrdersContextContainer({ children }) {
  const {
    orders: completedOrders,
    setOrders: setCompletedOrders,
    onAddToOrders,
    isEmptyOrdersPool,
    onAddFoodItem,
    updateFoodItem,
    changeFoodItemStatus,
    removeFoodItem,
    changeActiveOrderStatus,
    activeOrder,
    setupActiveOrder,
    clearActiveOrder,
    isActiveOrder,
    isActiveOrderEmpty,
    deleteActiveOrder,
    refundForActiveOrder,
  } = useOrders();

  const {
    selectedPeriod,
    onSelect,
    isThisPeriodEqualToSelectedPeriod,

    customTimeRange,
    changeCustomTimeRange,
    onClearTimeRange,
    isRangeActive,
    isPartOfRange,
  } = useSwitchPeriod();

  const onAddToCompletedOrders = (order) => {
    onAddToOrders({
      ...order,
      completed: Date.now(),
      overallRating: Number(computeRatingForCompletedOrder(order, { name: 'Megan S.', rating: 4.5 }).toFixed(1)),
    });
  };

  // Jun 27 2021 15:09 -> Jun 27 2021 00:00
  const prepareCompletedTime = (completedTime) => {
    const completedDate = new Date(completedTime);
    const preparedCompletedDate = new Date(completedDate.getFullYear(), completedDate.getMonth(), completedDate.getDate());
    return preparedCompletedDate.getTime();
  };

  const filterCompletedOrdersByTimePeriod = (orders, filterPeriod) => {
  
    if (filterPeriod) {
      switch(filterPeriod) {
        case CompletedOrdersPeriods.periods.today:
          return orders.filter(({ completed }) => new Date(completed).getDate() === new Date().getDate());
        case CompletedOrdersPeriods.periods.yesterday:
          return orders.filter(({ completed }) => new Date(completed).getDate() === new Date(new Date().getTime() - 86400000).getDate());
        case CompletedOrdersPeriods.periods.custom:
          return isRangeActive() ? orders.filter(({ completed }) => isPartOfRange(prepareCompletedTime(completed))) : [];
        default:
          return orders;
      }
    }

    return orders;
  };

  const showFilteredOrders = () => filterCompletedOrdersByTimePeriod(completedOrders, selectedPeriod);

  const onSelectWithoutActiveOrder = (...args) => {
    clearActiveOrder();
    onSelect(...args);
  };

  const onClearGlobalRange = () => {
    onClearTimeRange();
    clearActiveOrder();
  };

  const isActiveOrderHaveRefund = () => activeOrder.refund && activeOrder.refund.money;
  const isActiveOrderHaveFullRefund = () => activeOrder.refund.percentages >= 100;

  return (
    <CompletedOrdersContext.Provider
      value={{
        completedOrders,
        setCompletedOrders,
        onAddToCompletedOrders,
        isEmptyOrdersPool,
        onAddFoodItem,
        updateFoodItem,
        changeFoodItemStatus,
        removeFoodItem,
        changeActiveOrderStatus,
        activeOrder,
        setupActiveOrder,
        clearActiveOrder,
        isActiveOrder,
        isActiveOrderEmpty,
        isActiveOrderHaveRefund,
        isActiveOrderHaveFullRefund,
        deleteActiveOrder,
        refundForActiveOrder,

        selectedPeriod,
        onSelect: onSelectWithoutActiveOrder,
        isThisPeriodEqualToSelectedPeriod,

        showFilteredOrders,

        customTimeRange,
        changeCustomTimeRange,
        onClearGlobalRange,
        isRangeActive,
        isPartOfRange,
      }}
    >
      {children}
    </CompletedOrdersContext.Provider>
  );
}

CompletedOrdersContextContainer.propTypes = {
  children: PropTypes.any,
};
