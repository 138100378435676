import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { dayTitles } from 'services/static';
import { StateContext } from 'contexts/StateContextContainer';
import { DrawerBusinessHoursWrapper } from './style';
import { DayContainer } from './DayContainer';
import DrawerBusinessHoursSetTime from './DrawerBusinessHoursSetTime';
import DrawerBusinessHoursClosedDays from './DrawerBusinessHoursClosedDays';

function DrawerBusinessHours({
  visible = false,
  placement = 'right',
  onClose = () => {},
  workingHours = [],
  closedDays = [],
  onChange = () => {},
  onUpdateClosedDays = () => {},
  onDeleteClosedDay = () => {},
}) {
  const [
    openDrawerBusinessHoursSetTime,
    setDrawerBusinessHoursSetTime,
  ] = useState(false);
  const [
    openDrawerBusinessHoursClosedDays,
    setDrawerBusinessHoursClosedDays,
  ] = useState(false);

  const { setSelectedDayOfWeek } = useContext(StateContext);

  function onDaySelect(selectedDay) {
    setSelectedDayOfWeek(selectedDay);
    setDrawerBusinessHoursSetTime(true);
  }

  function convertTimeToReadableFormat(time) {
    const formattedTime = time % (12 * 60 * 60);
    const hr = Math.floor(formattedTime / (60 * 60));
    const min = (formattedTime - hr * 60 * 60) / 60;
    const dateTime = Math.floor(time / (12 * 60 * 60)) >= 1 ? 'PM' : 'AM';

    return `${hr < 10 ? '0' + hr : hr}:${min === 0 ? '00' : min} ${dateTime}`;
    // console.log(`${hr < 10 ? '0' + hr : hr}:${min === 0 ? '00' : min} ${dateTime}`)
  }

  return (
    <DrawerBusinessHoursWrapper
      onClose={onClose}
      visible={visible}
      placement={placement}
    >
      <div className='business-hours-panel'>
        <div className='panel-header-container'>
          <div className='back-arrow' onClick={onClose}>
            <i className='fas fa-chevron-left' />
          </div>
          <div className='panel-header-context'>Business Hours</div>
        </div>
        <div className='panel-status-container'>
          <div className='dates-container'>
            {dayTitles.map(({ content: day }) => {
              const dayNumeric = dayTitles.find(
                ({ content: dayContent }) => dayContent === day
              ).num;
              const isDayWasSelected = workingHours.find(
                (selectedDay) => selectedDay.day === dayNumeric
              );
              let dayToDisplay = '';
              if (isDayWasSelected) {
                const timeRange = isDayWasSelected.times[0];
                const [startTime, endTime] = timeRange;
                if (startTime === 0 && endTime === 86400) {
                  dayToDisplay = 'All Day';
                } else {
                  dayToDisplay =
                    convertTimeToReadableFormat(startTime) +
                    ' - ' +
                    convertTimeToReadableFormat(endTime);
                }
              }
              return (
                <DayContainer
                  key={`dayKey${day}`}
                  onClick={() => onDaySelect(day)}
                  selectedTime={dayToDisplay}
                >
                  {day}
                </DayContainer>
              );
            })}
          </div>
          <div className='closed-dates-container'>
            <DayContainer
              onClick={() => setDrawerBusinessHoursClosedDays(true)}
              selectedTime={`${closedDays.length} days`}
              placeholder='Set days'
            >
              Closed Days
            </DayContainer>
          </div>
        </div>
        <div className='button-container'>
          <button
            type='button'
            className={`done-button active`}
            onClick={onClose}
          >
            Done
          </button>
        </div>
      </div>
      <DrawerBusinessHoursSetTime
        visible={openDrawerBusinessHoursSetTime}
        onClose={() => setDrawerBusinessHoursSetTime(false)}
        workingHours={workingHours}
        onChange={onChange}
      />
      <DrawerBusinessHoursClosedDays
        visible={openDrawerBusinessHoursClosedDays}
        onClose={() => setDrawerBusinessHoursClosedDays(false)}
        closedDays={closedDays}
        onUpdateClosedDays={onUpdateClosedDays}
        onDeleteClosedDay={onDeleteClosedDay}
      />
    </DrawerBusinessHoursWrapper>
  );
}

DrawerBusinessHours.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  workingHours: PropTypes.array,
  closedDays: PropTypes.array,
  onChange: PropTypes.func,
  onUpdateClosedDays: PropTypes.func,
  onDeleteClosedDay: PropTypes.func,
};

export default DrawerBusinessHours;
