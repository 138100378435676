import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RejectConfirmDrinkContainer } from './style';
import { RejectedAlertDrink } from './RejectedAlert';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';

export function RejectConfirmDrink({ onClose, removePendingDrinkData }) {
  const context = useContext(modalContext);

  function RejectConfirmDrinkFunc() {
    context.setModal('Reject-Drink-Modal-4');
    removePendingDrinkData();
  }

  return (
    <RejectConfirmDrinkContainer>
      {context.modal === 'Reject-Drink-Modal-3' ? (
        <>
          <div className='reject-drink-confirm-wrapper animate__animated animate__fadeInUp'>
            <div>
              <p className='reject-drink-confirm-heading'>Are you sure?</p>
            </div>

            <div>
              <p className='reject-drink-confirm-paragraph'>
                The full drink refill request will be rejected. This cannot be
                undone.
              </p>
            </div>

            <div>
              <Button
                variant='none'
                className='reject-drink-confirm-yes-reject-request-btn'
                onClick={RejectConfirmDrinkFunc}
              >
                Yes, reject request
              </Button>
            </div>

            <div>
              <Button
                variant='none'
                className='reject-drink-confirm-keep-btn'
                onClick={onClose}
              >
                Keep
              </Button>
            </div>
          </div>
        </>
      ) : (
        <RejectedAlertDrink onClose={onClose} />
      )}
    </RejectConfirmDrinkContainer>
  );
}

RejectConfirmDrink.propTypes = {
  onClose: PropTypes.func,
  removePendingDrinkData: PropTypes.func
};
