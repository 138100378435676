import React from 'react';
import PropTypes from 'prop-types';
import { PersonalCheckingContainerStyled } from './style';
import { months, properlyWeekDays } from 'services/static';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { PersonalChecking } from './PersonalChecking';

export function PersonalCheckingContainer({
  isEmptyState,
  currentBankAccount,
  onAddBankAccount,
  onClose,
}) {
  return (
    <PersonalCheckingContainerStyled>
      <PersonalChecking
        currentBankAccount={currentBankAccount}
        isEmptyState={isEmptyState}
      />
      {!isEmptyState ? (
        <>
          <SingleText
            spacing='0.27px'
            marginBottom={23}
            color='rgba(255, 255, 255, 0.6)'
            align='center'
          >
            Your tips are deposited weekly.
          </SingleText>
          <SingleText
            spacing='0.27px'
            weight='bold'
            align='center'
            marginBottom={23}
          >
            Next Deposit: {afterSevenDaysFromToday()}
          </SingleText>
        </>
      ) : (
        ''
      )}
      <SingleButton
        title={isEmptyState ? 'Add bank account' : 'Done'}
        onClick={isEmptyState ? onAddBankAccount : onClose}
      />
    </PersonalCheckingContainerStyled>
  );
}

PersonalCheckingContainer.propTypes = {
  isEmptyState: PropTypes.bool,
  currentBankAccount: PropTypes.object,
  onAddBankAccount: PropTypes.func,
  onClose: PropTypes.func,
};

export const afterSevenDaysFromToday = () => {
  const today = new Date();
  const afterSevenDays = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 7
  );
  const weekDay = properlyWeekDays[afterSevenDays.getDay()].slice(0, 3);
  const month = months[afterSevenDays.getMonth()].slice(0, 3);
  const date = afterSevenDays.getDate();

  return `${weekDay} ${month} ${date < 10 ? '0' + date : date}`;
};
