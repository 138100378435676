import styled from '@emotion/styled';

export const RequestPendingSideDataContainer = styled.div`
  
  .request-detail-wrapper {
    position: fixed;
    width: 450px;
    height: 100%;
    overflow-y: scroll;
  }

  /* 1st card */
  .completed-dark-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 13px 14px 13px;
    border-radius: 20px;
    background-color: #1b1c1d;
    margin: 20px 15px 20px 20px;
    padding: 0 32px 0 30px;
  }

  .completed-inner-flex {
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .completed-badge-secondary {
    color: #fff;
    background-color: ${(props) =>
    props.isBadgeBackground ? '#ed2626' :'#1fc086'};
    height: 47px;
    width: 47px;
    border-radius: 41px;
  }

  #icon-style {
    z-index: 999999;
    font-size: 20px;
    position: relative;
    top: 11px;
  }

  .completed-padding-horizontal {
    padding-left: 1rem;
  }

  .btn-width {
    width: 100%;
  }

  .completed-title-table-wrapper {
    margin-top: 17px;
    color: #ffffff;
  }

  .completed-title-table-wrapper .title-text {
    font-size: 15px;
  }

  /* 2nd card */
  .completed-food-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 15px 33px 15px 35px;
    border-radius: 20px;
    background-color: #27282a;
    color: #fff;
    margin: 15px 13px 10px 21px;
  }

  .completed-flex {
    display: flex;
  }

  .completed-number {
    letter-spacing: -0.28px;
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    opacity: 0.21;
  }

  .completed-space-between {
    padding-right: 10px;
  }

  .completed-flex-column {
    display: flex;
    flex-direction: column;
  }

  .completed-food-name {
    letter-spacing: -0.28px;
    color: #585859;
    font-size: 15px;
    font-weight: bold;
  }

  .space-padding {
    padding: 7px 0 7px 0;
  }

  .completed-sides-line {
    color: #ffffff;
    letter-spacing: -0.36px;
    font-size: 15px;
    opacity: 0.18;
    line-height: 21px;
  }

  .completed-full-refund {
    letter-spacing: -0.36px;
    color: #1fc086;
    font-size: 15px;
    font-weight: 500;
  }

  .completed-food-cost {
    color: #ffffff;
    letter-spacing: -0.36px;
    font-size: 15px;
    opacity: 0.18;
  }

  /* 3rd card */
  .completed-food-wrapper-reject {
    margin: 11px 0;
    padding: 21px 33px 24px 35px;
    border-radius: 20px;
    background-color: #27282a;
    margin: 12px 13px 0 21px;
    padding-bottom: 4px;
  }

  .completed-food-flex-reject {
    display: flex;
    justify-content: space-between;
  }

  .completed-food-inner-flex-reject {
    display: flex;
    line-height: 10px;
  }

  .completed-food-number-reject {
    letter-spacing: -0.28px;
    color: ${(props) => (props.IsChangeNumberColor ? '#ffffff' : '#68696a')};
    font-size: 15px;
    font-weight: bold;
    /* opacity: 0.21; */
  }

  .completed-padding-horizontal {
    padding-left: 1rem;
  }

  .completed-food-name-reject {
    letter-spacing: -0.28px;
    color: #585859;
    font-size: 15px;
    font-weight: bold;
  }

  .food-name-reject {
    letter-spacing: -0.28px;
    color: ${(props) => (props.IsChangeTextColor ? '#ffffff' : '#68696a')};
    font-size: 15px;
    font-weight: bold;
  }

  .completed-food-cost-reject {
    color: #ffffff;
    letter-spacing: -0.36px;
    font-size: 15px;
    opacity: 0.18;
  }

  .pointer {
    cursor: pointer;
  }

  /* @TODO 3rd card style has replaced with 4th card style */

  /* 5th card */
  .completed-comment-wrapper {
    padding: 21px 61px 24px 35px;
    border-radius: 20px;
    background-color: #1b1c1d;
    margin: 13px 15px 0 20px;
    padding-bottom: 4px;
    color: #ffffff;
    letter-spacing: -0.36px;
    size: 15px;
  }

  /* completed by card  */
  .completed-card-wrapper {
    /* padding: 30px 72px 29px 73px; */
    padding: 30px 35px 15px 35px;
    border-radius: 20px;
    background-color: #1b1c1d;
    margin: 15px 15px 0 21px;
    text-align: center;
    margin-bottom: 32px;
    color: #ffffff;
  }

  #avatar-style {
    height: 50px;
    border-radius: 50px;
    margin: 11px 0 11px 0;
  }

  .completed-completed-by {
    color: #585859;
  }

  /* ----Pending Requests style---- */

  /* 1st card */
  .side-dark-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 13px 14px 13px;
    border-radius: 20px;
    background-color: #1b1c1d;
    margin: 20px 15px 20px 20px;
    padding: 0 32px 0 30px;
  }

  .badge-secondary {
    color: #fff;
    background-color: #373737;
    height: 47px;
    width: 47px;
    border-radius: 41px;
  }

  #icon-style {
    z-index: 999999;
    font-size: 20px;
    position: relative;
    top: 11px;
  }

  .inner-flex {
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .padding-horizontal {
    padding-left: 1rem;
  }

  .btn-width {
    width: 100%;
  }

  .title-table-wrapper {
    margin-top: 17px;
    color: #ffffff;
  }

  .title-table-wrapper .title-text {
    font-size: 15px;
  }

  .reject-text {
    display: flex;
    justify-content: flex-end;
    color: red;
    cursor: pointer;
    position: relative;
    top: -9px;
  }

  .time-stamp {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: -7px
  }

  /* 2nd card */
  .food-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 15px 33px 15px 35px;
    border-radius: 20px;
    background-color: #27282a;
    color: #fff;
    margin: 15px 13px 10px 21px;
  }

  .flex {
    display: flex;
  }

  .space-between {
    padding-right: 10px;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .food-name {
    letter-spacing: -0.28px;
    color: #585859;
    font-size: 15px;
    font-weight: bold;
  }

  .number {
    letter-spacing: -0.28px;
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    opacity: 0.21;
  }

  .sides-line {
    color: #ffffff;
    letter-spacing: -0.36px;
    font-size: 15px;
    opacity: 0.18;
    line-height: 17px;
  }

  .full-refund {
    letter-spacing: -0.36px;
    color: #1fc086;
    font-size: 15px;
    font-weight: 500;
  }

  .food-cost {
    color: #ffffff;
    letter-spacing: -0.36px;
    font-size: 15px;
    opacity: 0.18;
  }

  .sides-text {
    color: #ffffff;
    letter-spacing: -0.36px;
    font-size: 15px;
    opacity: 0.18;
    font-weight: bold;
  }

  /* 3rd card */
  .food-wrapper-reject {
    margin: 11px 0;
    padding: 21px 33px 24px 35px;
    border-radius: 20px;
    background-color: #27282a;
    margin: 12px 13px 0 21px;
    padding-bottom: 4px;
  }

  .food-flex-reject {
    display: flex;
    justify-content: space-between;
  }

  .food-inner-flex-reject {
    display: flex;
    line-height: 10px;
  }

  .food-number-reject {
    letter-spacing: -0.28px;
    color: ${(props) => (props.IsChangeNumberColor ? '#ffffff' : '#68696a')};
    font-size: 15px;
    font-weight: bold;
  }

  .food-cost-reject {
    color: #ffffff;
    letter-spacing: -0.36px;
    font-size: 15px;
    opacity: 0.18;
  }

  /* 3rd card style has replaced with 4th card style */

  /* 5th card */
  .comment-wrapper {
    padding: 21px 61px 24px 35px;
    border-radius: 20px;
    background-color: #1b1c1d;
    margin: 13px 15px 0 20px;
    padding-bottom: 4px;
    color: #ffffff;
    letter-spacing: -0.36px;
    size: 15px;
  }

  /* button's text */
  .text-style {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.25px;
    text-align: center;
    color: #ffffff;
  }

  .btn-style {
    background: ${(prop) => (prop.isActiveBackground ? '#27282a' : '#ed2626')};
    margin: 0 0 0 6px;
  }

  .btn-danger {
    border-color: none;
  }

  .btn-style span {
    font-size: 15px;
    font-weight: bold;
  }

  .btn-text-color {
    color: ${(prop) => (prop.isTextColorChange ? '#5a5a5a' : '#ffffff')};
  }

  .arrow-icon {
    color: #ed2626;
    font-size: 11px;

    .fa-chevron-right:before {
      content: '\f054';
      color: #ed2626;
    }
  }

  .button-wrapper {
    /* position: absolute; */
    /* bottom: 0; */
    padding-bottom: 2rem;
    margin: 30px 15px 20px 20px;
  }

  .choose-resolve {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: -0.36px;
    color: #ed2626;
  }
`;
