import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerAddBankAccountContainer = styled(SingleDrawer)`
  padding: 36px 26px 33px;
  height: ${({ customHeight }) => customHeight}px !important;
  background-color: ${colors.gray5};
  display: flex;
  flex-direction: column;
  align-items: center;

  .error-message-container {
    margin-top: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 13px;
      color: ${colors.redColor};
    }

    .error-message {
      font-size: 13px;
      color: white;
      font-weight: 600;
      margin-left: 15px;
    }
  }
`;
