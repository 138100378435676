import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerDeleteOrderContainerStyled } from './style';
import { DrawerDeleteOrder } from './DrawerDeleteOrder';
import { DrawerDeleteOrderAreYouSure } from './DrawerDeleteOrderAreYouSure';
import { DrawerOrderDeleted } from './DrawerOrderDeleted';

// @TODO Change folder to Orders/components
export function DrawerDeleteOrderContainer({
  visible,
  deleteActiveOrder = () => {},
  onClose,
}) {
  const [openDrawerDeleteOrder, setOpenDrawerDeleteOrder] = useState(false);

  const onOpenDrawerDeleteOrder = () => setOpenDrawerDeleteOrder(true);
  const onCloseDrawerDeleteOrder = () => setOpenDrawerDeleteOrder(false);

  const [openDrawerDeleteOrderAreYouSure, setOpenDrawerDeleteOrderAreYouSure] =
    useState(false);

  const onOpenDrawerDeleteOrderAreYouSure = () =>
    setOpenDrawerDeleteOrderAreYouSure(true);

  const onCloseDrawerDeleteOrderAreYouSure = () =>
    setOpenDrawerDeleteOrderAreYouSure(false);

  const [openDrawerOrderDeleted, setOpenDrawerOrderDeleted] = useState(false);

  const onOpenDrawerOrderDeleted = () => setOpenDrawerOrderDeleted(true);
  const onCloseDrawerOrderDeleted = () => setOpenDrawerOrderDeleted(false);

  const close = () => {
    onCloseDrawerOrderDeleted();
    onCloseDrawerDeleteOrderAreYouSure();
    onCloseDrawerDeleteOrder();
    onClose();
  };

  const onDelete = () => {
    onCloseDrawerDeleteOrder();
    onOpenDrawerDeleteOrderAreYouSure();
  };

  const onYesDelete = () => {
    onCloseDrawerDeleteOrderAreYouSure();
    onOpenDrawerOrderDeleted();
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerDeleteOrder();
    }
  }, [visible]);

  return (
    <DrawerDeleteOrderContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerDeleteOrder
        visible={openDrawerDeleteOrder}
        onClose={close}
        onDelete={onDelete}
      />
      <DrawerDeleteOrderAreYouSure
        visible={openDrawerDeleteOrderAreYouSure}
        onClose={close}
        onYesDelete={onYesDelete}
        deleteActiveOrder={deleteActiveOrder}
      />
      <DrawerOrderDeleted visible={openDrawerOrderDeleted} onClose={close} />
    </DrawerDeleteOrderContainerStyled>
  );
}

DrawerDeleteOrderContainer.propTypes = {
  visible: PropTypes.bool,
  deleteActiveOrder: PropTypes.func,
  onClose: PropTypes.func,
};
