import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BootstrapPagination from 'react-bootstrap/Pagination';
import { PaginationContainer } from './style';

export default function Pagination({
  className = '',
  list,
  limitPerPage,
  onChange = () => {},
}) {
  const [currentPage, setCurrentPage] = useState('');
  const [pages, setPages] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangePage = (page, itemsRelatedToPage) => {
    setCurrentPage(page);
    onChange(itemsRelatedToPage);
  };

  useEffect(() => {
    const createPages = () => {
      const pages = [];
      for (let i = 0; i < list.length; i += limitPerPage) {
        pages.push(list.slice(i, i + limitPerPage));
      }
      return pages;
    };

    if (pages.length === 0 && list.length !== 0) {
      const createdPages = createPages();
      setPages(createdPages);
      onChangePage(1, createdPages[0]);
    }
  }, [pages, setPages, limitPerPage, list, onChangePage]);

  const isPrevActive = currentPage !== 1;
  const isNextActive = currentPage !== Math.floor(list.length / limitPerPage);

  return (
    <PaginationContainer className={className}>
      <BootstrapPagination.Prev
        className={isPrevActive ? 'page-active' : ''}
        onClick={() => onChangePage(currentPage - 1, pages[currentPage - 2])}
        disabled={!isPrevActive}
      />
      {pages.map((pageWithItems, index) => (
        <BootstrapPagination.Item
          key={index}
          onClick={() => onChangePage(index + 1, pageWithItems)}
          active={index + 1 === currentPage}
        />
      ))}
      <BootstrapPagination.Next
        className={isNextActive ? 'page-active' : ''}
        onClick={() => onChangePage(currentPage + 1, pages[currentPage])}
        disabled={!isNextActive}
      />
    </PaginationContainer>
  );
}

Pagination.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array,
  limitPerPage: PropTypes.number,
  onChange: PropTypes.func,
};
