export class OrderCalculation {
  calculateTotalPriceForAddonsOfFoodItem(foodItem) {
    const priceForAddonsForOneItem = foodItem.addons.reduce(
      (addonsTotalPrice, [, currentPrice]) => currentPrice + addonsTotalPrice,
      0
    );
    const priceForAddonsForAllInstancesOfThisFoodItem =
      priceForAddonsForOneItem * foodItem.total;

    return priceForAddonsForAllInstancesOfThisFoodItem;
  }

  calculatePriceForOrder(order) {
    const foodItems = order.items || [];

    return foodItems.reduce((totalPriceForPreviousItems, currentFoodItem) => {
      const priceForAllInstancesOfCurrentFoodItem =
        currentFoodItem.total * currentFoodItem.price;
      const priceForAllAddonsOfCurrentFoodItem =
        this.calculateTotalPriceForAddonsOfFoodItem(currentFoodItem);

      return (
        totalPriceForPreviousItems +
        priceForAllInstancesOfCurrentFoodItem +
        priceForAllAddonsOfCurrentFoodItem
      );
    }, 0);
  }

  calculatePriceForActiveOrder(order) {
    const totalPrice = this.calculatePriceForOrder(order);
    const discount =
      order.discount && order.discount.money ? order.discount.money : 0;

    return totalPrice - discount;
  }

  calculatePriceForCompletedOrderWithoutRefunds(order, tax = 2.3) {
    const totalPrice = this.calculatePriceForOrder(order);
    const discount =
      order.discount && order.discount.money ? order.discount.money : 0;

    return totalPrice - discount + tax;
  }

  calculatePriceForCompletedOrder(order, tax = 2.3) {
    const refund = order.refund && order.refund.money ? order.refund.money : 0;

    return this.calculatePriceForCompletedOrderWithoutRefunds(order) - refund;
  }

  calculateTotalSalesForCompletedOrders(orders) {
    const totalSales = orders.reduce(
      (totalPriceForPreviousOrders, currentOrder) =>
        totalPriceForPreviousOrders +
        this.calculatePriceForCompletedOrder(currentOrder),
      0
    );

    return Number(totalSales.toFixed(2));
  }

  calculateAveragePriceOfCompletedOrders(orders) {
    const totalPriceForCompletedOrders =
      this.calculateTotalSalesForCompletedOrders(orders);
    const averageValue = Number(
      (totalPriceForCompletedOrders / orders.length).toFixed(2)
    );

    return averageValue;
  }
}
