import React from 'react';
import PropTypes from 'prop-types';
import { OrderNotification } from '../OrderNotification';

export function ActiveOrderItems({
  items,
  normalControl = true,
  changeFoodItemStatus = () => {},
  onStartEdit = () => {},
  onStartRemove = () => {},
}) {
  let activeOrder = {};

  return items
    ? items.map(
        ({
          id,
          status,
          price,
          total,
          name,
          description,
          specialInstructions,
          ...other
        }) => (
          <OrderNotification
            title={name}
            price={
              price * total +
              other.addons.reduce(
                (total, [, currentPrice]) => total + currentPrice,
                0
              ) *
                total
            }
            total={total}
            description={description}
            status={status}
            changeStatus={() => changeFoodItemStatus(id, activeOrder)}
            onStartEdit={() =>
              onStartEdit({
                id,
                status,
                price,
                name,
                total,
                description,
                specialInstructions,
                ...other,
              })
            }
            onStartRemove={() => onStartRemove({ name, id })}
            normalControl={normalControl}
            key={status + price + total + name + description}
          />
        )
      )
    : '';
}

ActiveOrderItems.propTypes = {
  items: PropTypes.array,
  changeFoodItemStatus: PropTypes.func,
  onStartEdit: PropTypes.func,
  onStartRemove: PropTypes.func,
};
