import styled from '@emotion/styled';

export const RejectConfirmContainer = styled.div`
  .reject-confirm-wrapper {
    width: 100%;
    padding: 33px 26px 36px;
    border-radius: 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #27282a;
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }

  .reject-confirm-heading {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }

  .reject-confirm-paragraph {
    font-size: 14px;
    text-align: center;
    color: #ffffff;
  }

  .reject-confirm-yes-reject-request-btn {
    width: 100%;
    padding: 13px 0 13px 0;
    border-radius: 8px;
    color: white;
    background: #ed2626;
    border: none;
    font-size: 14px;
    font-weight: 600;
    margin: 25px 0 20px;
  }

  .reject-confirm-keep-btn {
    width: 100%;
    padding: 13px 0 13px 0;
    border-radius: 8px;
    color: white;
    background: #61646c;
    border: none;
    font-size: 14px;
    font-weight: 600;
  }
`;
