import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { TablesListFromSelectedFloorContainer } from './style';
import { Tables } from 'feasttt/domain';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { DrawerCheckInErrorContainer } from '../DrawerCheckInErrorContainer';

export function TablesListFromSelectedFloor({
  className = '',
  selectedFloor = { tables: [] },
}) {
  const { setOpenDrawerSelectServer } = useContext(StateContext);
  const { changeTableForCheckInData } = useContext(DataContext);

  const [openDrawerCheckInErrorContainer, setOpenDrawerCheckInErrorContainer] =
    useState(false);
  const [checkInError, setCheckInError] = useState("");

  const onClickTable = (table, tableStatus) => {
    if (tableStatus.isAvailable()) {
      onChooseTable(table);
      setCheckInError("")
    } else {
      setCheckInError("Can't check-in to an inactive table.")
      showWarningForUserIfHeSelectedWrongTable();
    }
  };

  const onChooseTable = (table) => {
    changeTableForCheckInData(table);
    onOpenDrawerSelectServer();
  };

  const onOpenDrawerSelectServer = () => setOpenDrawerSelectServer(true);

  const showWarningForUserIfHeSelectedWrongTable = () =>
    onOpenDrawerCheckInErrorContainer();

  const createStatusTitleBasedOnCssClass = (status) => {
    const cssClassStatus = cssClassForCurrentTableStatus(status);
    return `${cssClassStatus.slice(0, 1).toUpperCase()}${cssClassStatus
      .slice(1)
      .toLowerCase()}`;
  };

  const cssClassForCurrentTableStatus = (status) => {
    if (status.isAvailable()) {
      return 'available';
    } else if (status.isOccupied()) {
      return 'occupied';
    } else {
      return 'inactive';
    }
  };

  const onOpenDrawerCheckInErrorContainer = () =>
    setOpenDrawerCheckInErrorContainer(true);
  const onCloseDrawerCheckInErrorContainer = () =>
    setOpenDrawerCheckInErrorContainer(false);

  return (
    <TablesListFromSelectedFloorContainer
      className={`floors-list-container ${className}`}
    >
      {selectedFloor.tables.map((table, index) => {
        const tableStatus = Tables.createTableStatus(table);
        // eslint-disable-next-line no-lone-blocks
        {
          /* console.log(tableStatus, 'status'); */
        }

        return (
          <div
            key={`tablekeyprop${index}`}
            className={`floor-item ${cssClassForCurrentTableStatus(
              tableStatus
            )}`}
            onClick={() => onClickTable(table, tableStatus)}
            aria-hidden='true'
          >
            {/* @TODO JSON test */}
            {/* <p>{JSON.stringify(table)}</p> */}

            <div className='floor-title-top-container'>
              <span className='floor-title'>{selectedFloor.name}</span>
              {tableStatus.isOccupied() ? (
                <span className='waiter-name'>Yvonne K</span>
              ) : (
                ''
              )}
            </div>
            <div className='table-name-container'>
              <span className='table-name'>
                {`Table ${table.table_number}`}
              </span>
              {tableStatus.isOccupied() ? (
                <span className='order-id'>WAEAE3</span>
              ) : (
                ''
              )}
            </div>
            <div className='bottom-table-info'>
              <div className='users-block'>
                <div className='seats-and-guests'>
                  <i className='fas fa-users' />
                  <span className='seats-and-guests-title'>
                    {table.guests}/{table.seats}
                  </span>
                </div>
                {tableStatus.isOccupied() ? (
                  <div className='total-food-items-and-price'>
                    <div className='food-items'>
                      <i className='fas fa-salad' />
                      <span className='food-items-title'>6</span>
                    </div>
                    <div className='price-container'>
                      <span className='price-title'>$121.21</span>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className='availability-block'>
                {tableStatus.isOccupied() ? (
                  <div className='occupied-loading'>
                    <i className='fas fa-spinner' />
                  </div>
                ) : (
                  <>
                    <i className='fas fa-circle' />
                    <span>{createStatusTitleBasedOnCssClass(tableStatus)}</span>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
      <DrawerCheckInErrorContainer
        visible={openDrawerCheckInErrorContainer}
        onClose={onCloseDrawerCheckInErrorContainer}
        onRetry={onCloseDrawerCheckInErrorContainer}
        errorMessage={checkInError}
      />
    </TablesListFromSelectedFloorContainer>
  );
}

TablesListFromSelectedFloor.propTypes = {
  selectedFloor: PropTypes.object,
  className: PropTypes.string,
  onRetry: PropTypes.func,
};
