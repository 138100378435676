import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerProcessPaymentContainer = styled(SingleDrawer)`
  padding: 36px 26px 23px;
  background-color: ${colors.gray2};

  .close-icon {
    cursor: pointer;
    margin-left: 7px;
    margin-bottom: 40px;
    height: 29px;
    i {
      font-size: 28px;
      font-weight: 600;
      letter-spacing: 0.53px;
      color: white;
    }
  }
`;
