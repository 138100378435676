import React, { useState, useEffect } from 'react';
// import { StateContext } from 'contexts/StateContextContainer';
import { Dropdown } from 'react-bootstrap';
import './TimePicker.scss';

export const TimePicker = (props) => {
  // const { openDrawerBusinessHoursSetTime } = useContext(StateContext);
  const [selectedHr, setSelectedHr] = useState(12);
  const [selectedMin, setSelectedMin] = useState(0);
  const [selectedDatetime, setSelectedDatetime] = useState('AM');

  useEffect(() => {
    setTimeCallback();
    // eslint-disable-next-line
  }, [selectedHr, selectedMin, selectedDatetime]);

  useEffect(() => {
    setTimeCallback();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPresentedValue();
    // eslint-disable-next-line
  }, [props.value]);

  function setPresentedValue() {
    // eslint-disable-next-line react/prop-types
    const presetValue = props.value;
    if (presetValue) {
      const formattedTime = presetValue % (12 * 60 * 60);
      const hr = Math.floor(formattedTime / (60 * 60));
      const min = (formattedTime - hr * 60 * 60) / 60;
      const dateTime =
        Math.floor(presetValue / (12 * 60 * 60)) >= 1 ? 'PM' : 'AM';
      setSelectedHr(hr);
      setSelectedMin(min);
      setSelectedDatetime(dateTime);
    } else {
      setSelectedDatetime('AM');
      setSelectedHr(12);
      setSelectedMin(0);
    }
  }

  function numberChange(e, newNum, type) {
    e.preventDefault();
    if (type === 'hr') {
      setSelectedHr(newNum <= 0 ? 12 : newNum % 12);
    }
    if (type === 'min') {
      setSelectedMin(newNum < 0 ? 45 : newNum % 60);
    }
  }

  const displayHr = (hour) => {
    if (hour <= 0) return 12;
    if (hour === 13) return '01';
    return hour < 10 ? `0${hour}` : hour % 13;
  };

  const displayMin = (min) => {
    if (min < 0) return 45;
    if (min === 60) return `00`;
    return min < 10 ? `0${min}` : min % 60;
  };

  function setTimeCallback() {
    const hrToSec =
      selectedDatetime === 'AM' && selectedHr === 12 ? 0 : selectedHr * 60 * 60;
    const minToSec = selectedMin * 60;
    const multiplier =
      selectedHr === 12 ? 0 : selectedDatetime === 'AM' ? 0 : 12 * 60 * 60;

    // eslint-disable-next-line react/prop-types
    props.selectedTime((hrToSec + multiplier + minToSec) % 86400);
  }

  const TimeSwitcherItem = () => {
    return (
      <div className='time-switcher-component'>
        <div className='selector-item'>
          <div
            className='control-arrows'
            onClick={(e) => numberChange(e, selectedHr + 1, 'hr')}
          >
            <i className='fas fa-chevron-up' />
          </div>
          <div
            className='secondary-number'
            onClick={(e) => numberChange(e, selectedHr + 1, 'hr')}
          >
            {displayHr(selectedHr + 1)}
          </div>
          <div className='primary-number'>{displayHr(selectedHr)}</div>
          <div
            className='secondary-number'
            onClick={(e) => numberChange(e, selectedHr - 1, 'hr')}
          >
            {displayHr(selectedHr - 1)}
          </div>
          <div
            className='control-arrows'
            onClick={(e) => numberChange(e, selectedHr - 1, 'hr')}
          >
            <i className='fas fa-chevron-down' />
          </div>
        </div>
        <span>:</span>
        <div className='selector-item'>
          <div
            className='control-arrows'
            onClick={(e) => numberChange(e, selectedMin + 15, 'min')}
          >
            <i className='fas fa-chevron-up' />
          </div>
          <div
            className='secondary-number'
            onClick={(e) => numberChange(e, selectedMin + 15, 'min')}
          >
            {displayMin(selectedMin + 15)}
          </div>
          <div className='primary-number'>{displayMin(selectedMin)}</div>
          <div
            className='secondary-number'
            onClick={(e) => numberChange(e, selectedMin - 15, 'min')}
          >
            {displayMin(selectedMin - 15)}
          </div>
          <div
            className='control-arrows'
            onClick={(e) => numberChange(e, selectedMin - 15, 'min')}
          >
            <i className='fas fa-chevron-down' />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className='set-your-time-container'>
      <div className='time-select-custom-container'>
        <Dropdown className='button-custom-time'>
          <Dropdown.Toggle>
            <span className='selected-time'>
              {displayHr(selectedHr)}:{displayMin(selectedMin)}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <TimeSwitcherItem />
          </Dropdown.Menu>
        </Dropdown>
        <div className='select-datetime'>
          <span
            className={selectedDatetime === 'AM' ? 'active' : ''}
            onClick={() => setSelectedDatetime('AM')}
          >
            AM
          </span>
          <span
            className={selectedDatetime === 'PM' ? 'active' : ''}
            onClick={() => setSelectedDatetime('PM')}
          >
            PM
          </span>
        </div>
      </div>
    </div>
  );
};
