import React from 'react';
import PropTypes from 'prop-types';
import { CheckListItemContainer } from './style';

export function CheckListItem({ title, onClick, isChecked, isLastItem }) {
  return (
    <CheckListItemContainer className={isChecked ? 'checked' : ''} isLastItem={isLastItem}>
      <div className='status-container'>
        <i className='fas fa-check' />
      </div>
      <div className='list-item-container'>
        <div className='title-container'>
          <span className='title'>{title}</span>
        </div>
        <div
          className='btn-more'
          onClick={onClick}
          role='button'
          onKeyPress={onClick}
          tabIndex={0}
        >
          <i className='fas fa-chevron-right' />
        </div>
      </div>
    </CheckListItemContainer>
  );
}

CheckListItem.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  isChecked: PropTypes.bool,
  isLastItem: PropTypes.bool,
};
