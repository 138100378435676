import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { DrawerAddItemToOrderContainer } from './style';
import { AuthContext } from 'contexts/AuthContextContainer';
import { FoodDescription } from './FoodDescription';
import { FoodNutrition } from './FoodNutrition';
import { FoodControlContainer } from './FoodControlContainer';
import { AmountControl } from 'pages/Orders/Pending/OrderToSee/DrawerEditFoodItemFromOrder/AmountControl';
import DrawerCustomizations from 'pages/Menu/components/DrawerCustomizations';
import { onBeforeSave } from 'pages/Menu/components/DrawerFoodItem';
import { DefaultFood } from 'assets/images/menu';
import { DataContext } from 'contexts/DataContextContainer';

export function DrawerAddItemToOrder({
  visible,
  food = { photo: {} },
  onAddToOrder = () => {},
  onClose,
}) {
  const { restaurantId } = useContext(AuthContext);
  const { setSelectedFood } = useContext(DataContext);

  const [total, setTotal] = useState(1);
  const increaseTotal = () => setTotal(total + 1);
  const decreaseTotal = () => setTotal(total === 1 ? total : total - 1);

  const [editFood, setEditFood] = useState(undefined);

  const [openDrawerCustomizations, setOpenDrawerCustomizations] =
    useState(false);
  const onOpenDrawerCustomizations = () => setOpenDrawerCustomizations(true);
  const onCloseDrawerCustomizations = () => setOpenDrawerCustomizations(false);

  useEffect(() => {
    if (!visible && total !== 1) {
      setTotal(1);
      setEditFood(undefined);
    }
  }, [visible, total]);

  useEffect(() => {
    if (visible && editFood === undefined && Object.keys(food).length !== 1) {
      setEditFood(food);
      setSelectedFood(food);
    } else if (!visible) {
      setEditFood(undefined);
      setTotal(1);
    }
  }, [visible, editFood, food, setSelectedFood]);

  const close = () => {
    onBeforeSave(editFood, restaurantId);
    onClose();
  };

  const addToOrder = (foodItem) => {
    onBeforeSave(editFood, restaurantId);
    onAddToOrder(foodItem);
  };

  return (
    <DrawerAddItemToOrderContainer
      visible={visible}
      placement='right'
      onClose={close}
      backgroundImage={food.photo ? food.photo.file : DefaultFood}
    >
      {/* eslint-disable-next-line */}
      <div className='close-icon' onClick={close}>
        <i className='fas fa-times' />
      </div>
      <div className='blur-food-image-container'>
        <div className='blur-food-image' />
      </div>
      <div className='food-image' />
      <div className='food-add-item'>
        <FoodDescription name={food.title} description={food.description} />
        <FoodNutrition
          calories={food.calories || 240}
          protein={food.protein || 12}
          carbs={food.carbs || 18}
        />
        <FoodControlContainer
          rating={food.reviews_count ? food.yum_percent : '-'}
          reviewsCount={food.reviews_count}
          customizations={
            editFood && editFood.sortSequence
              ? editFood.sortSequence.length
              : food.customizations
          }
          onOpenDrawerCustomizations={onOpenDrawerCustomizations}
        />
        <AmountControl
          total={total}
          decreaseTotal={decreaseTotal}
          increaseTotal={increaseTotal}
        />
        <button
          className='btn-add-food-to-order'
          onClick={() => addToOrder({ ...food, total })}
        >
          <span className='add-to-order-title'>Add to Order</span>
          <span className='add-to-order-price'>
            ${food.price ? (food.price * total).toFixed(2) : 8.99}
          </span>
        </button>
      </div>

      <DrawerCustomizations
        visible={openDrawerCustomizations}
        editFood={editFood}
        changeFoodMultiply={(updatedEditFood) => setEditFood(updatedEditFood)}
        onClose={onCloseDrawerCustomizations}
      />
    </DrawerAddItemToOrderContainer>
  );
}

DrawerAddItemToOrder.propTypes = {
  visible: PropTypes.bool,
  food: PropTypes.any,
  onAddToOrder: PropTypes.func,
  onClose: PropTypes.func,
};
