import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerAddTipContainer } from './style';
import { colors } from 'theme';
import { usePaymentAdditional } from 'hooks';
import { needToPay } from 'services/functions';
import { ActiveOrdersContext } from 'contexts/ActiveOrdersContextContainer';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerAddTip({
  visible,
  onClose,
  onAddTip,
  tip = {},
  isTipAvailable,
}) {
  const { activeOrder } = useContext(ActiveOrdersContext);

  const [dollars, setDollars] = useState(10);
  const [percents, setPercents] = useState(15);

  const [isCreateMode, setIsCreateMode] = useState(true);

  const tipTypes = {
    first: {
      value: 'percentage',
      initial: 15,
    },
    second: {
      value: 'fixed',
      initial: 10,
    },
  };

  const {
    isOther,
    enableOtherMode,
    disableOtherMode,

    paymentOption: tipOption,
    onChangePaymentOption: onChangeTipOption,
    paymentType: tipType,
    onChangePaymentType: onChangeTipType,
    isFirstType: isPercentageActive,
    isSecondType: isFixedActive,

    paymentAdditionalNowAvailable,
  } = usePaymentAdditional({
    paymentTypes: tipTypes,
    initialValueFromFirstType: 15,
  });

  const onChangePercentageOption = (option) => {
    disableOtherMode();
    onChangeTipType(tipTypes.first.value);
    onChangeTipOption(option);
    setPercents(option);
  };

  const onSwitchToOtherAndSetPercentage = () => {
    enableOtherMode();
    onChangeTipType(tipTypes.first.value);
  };

  const add = () => {
    paymentAdditionalNowAvailable();
    onAddTip({
      type: tipType,
      value: isOther ? (isPercentageActive() ? percents : dollars) : tipOption,
      money: dollars,
    });
    onClose();
  };

  useEffect(() => {
    if (isPercentageActive()) {
      // changing dollars when changing percents
      setDollars(Number(computeTip(activeOrder, percents, true)));
    } else if (isFixedActive()) {
      // changing percents when changing dollars
      setPercents(Number(computeTip(activeOrder, dollars, false)));
    }
  }, [dollars, percents, activeOrder, isFixedActive, isPercentageActive]);

  useEffect(() => {
    const setupExistingTip = () => {
      onChangeTipType(tip.type);
      if (tip.type === tipTypes.second.value) {
        setDollars(Number(tip.value));
        enableOtherMode();
      } else {
        if ([10, 15, 20].includes(Number(tip.value))) {
          onChangeTipOption(Number(tip.value));
          setPercents(Number(tip.value));
        } else {
          setPercents(tip.value);
          enableOtherMode();
        }
      }
    };

    if (visible) {
      if (isCreateMode && isTipAvailable) {
        setupExistingTip();
        setIsCreateMode(false);
      }
    } else {
      setIsCreateMode(true);
    }
  }, [
    enableOtherMode,
    isCreateMode,
    isTipAvailable,
    onChangeTipOption,
    onChangeTipType,
    tip,
    tipTypes,
    visible,
  ]);

  return (
    <DrawerAddTipContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
      customHeight={isOther ? 490 : 405}
    >
      <SingleText
        size={22}
        spacing='0.42px'
        width='329px'
        align='center'
        weight='500'
        marginBottom={32}
      >
        Add a Tip
      </SingleText>
      <div className='tip-options'>
        {[10, 15, 20].map((option) => (
          <div
            className={`tip-option ${
              option === tipOption && isPercentageActive() && !isOther
                ? 'active'
                : ''
            }`}
            onClick={() => onChangePercentageOption(option)}
            key={option}
          >
            <span className='tip-option-title'>{option}%</span>
          </div>
        ))}
        <div
          className={`tip-option ${isOther ? 'active' : ''}`}
          onClick={onSwitchToOtherAndSetPercentage}
        >
          <span className='tip-option-title'>Other</span>
        </div>
      </div>
      {isOther ? (
        <div className='type-tip-container'>
          <div
            className={`tip-type-container fixed ${
              isFixedActive() ? 'active' : ''
            }`}
            onClick={() => onChangeTipType(tipTypes.second.value)}
          >
            <span className='tip-type-title'>Fixed Amount</span>
            <div className='tip-type-value-container'>
              <span className='tip-type-value'>$</span>
              <input
                type='number'
                value={dollars}
                onChange={(event) => setDollars(Number(event.target.value))}
                min='1'
                maxLength={5}
              />
            </div>
          </div>
          <div
            className={`tip-type-container percentage ${
              isPercentageActive() ? 'active' : ''
            }`}
            onClick={() => onChangeTipType(tipTypes.first.value)}
          >
            <span className='tip-type-title'>Percentage</span>
            <div className='tip-type-value-container percents'>
              <input
                type='number'
                value={Math.trunc(percents)}
                onChange={(event) => setPercents(Number(event.target.value))}
                min={1}
              />
              <span className='tip-type-value'>%</span>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='total-tip-container'>
        <span className='tip-title'>
          {isPercentageActive()
            ? `${isOther ? Math.trunc(percents) : tipOption}%`
            : `$${dollars.toFixed(2)}`}{' '}
          will be added as a tip.
        </span>
      </div>
      <div className='bottom-container'>
        <SingleButton
          title='Add a Tip'
          onClick={add}
          margin='0px 0px 20px 0px'
        />
        <SingleButton
          title='Cancel'
          onClick={onClose}
          btnColor='transparent'
          txtColor={colors.redColor}
        />
      </div>
    </DrawerAddTipContainer>
  );
}

DrawerAddTip.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onAddTip: PropTypes.func,
  tip: PropTypes.object,
  isTipAvailable: PropTypes.bool,
};

export const computeTip = (activeOrder, currentUserTip, isPercent = false) => {
  const totalToPay = Number(needToPay(activeOrder));
  if (isPercent) {
    const dollars = (totalToPay * (currentUserTip / 100)).toFixed(2);
    return dollars;
  } else {
    const percents = ((currentUserTip / totalToPay) * 100).toFixed(2);
    return percents;
  }
};
