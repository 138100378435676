export class WelcomeProfile {
  user;

  constructor(user) {
    this.user = user;
  }

  isPublished() {
    return this.user.isPublished;
  }
}
