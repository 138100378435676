import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RejectConfirmContainer } from './style';
import { RejectedAlert } from './RejectedAlert';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';
export function RejectConfirm({ onClose, removePendingData }) {
  const context = useContext(modalContext);
  // const [val, setVal] = useState(false)

  // useEffect(() => {}, [val])

  function RejectConfirmFunc() {
    context.setModal('Modal-4');
    removePendingData()
  }

  return (
    <RejectConfirmContainer>
      {context.modal === 'Modal-3' ? (
        <>
          <div className='reject-confirm-wrapper animate__animated animate__fadeInUp'>
            <div>
              <p className='reject-confirm-heading'>Are you sure?</p>
            </div>

            <div>
              <p className='reject-confirm-paragraph'>
                The full return request will be rejected. This cannot be undone.
              </p>
            </div>

            <div>
              <Button
                variant='none'
                className='reject-confirm-yes-reject-request-btn'
                onClick={RejectConfirmFunc}
              >
                Yes, reject request
              </Button>
            </div>

            <div>
              <Button
                variant='none'
                className='reject-confirm-keep-btn'
                onClick={onClose}
              >
                Keep
              </Button>
            </div>
          </div>
        </>
      ) : (
        <RejectedAlert onClose={onClose} />
      )}
    </RejectConfirmContainer>
  );
}

RejectConfirm.propTypes = {
  onClose: PropTypes.func,
  removePendingData: PropTypes.func,
};
