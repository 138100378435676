import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types';
import Box from 'components/Box';
import { FaChevronLeft } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import { DrawerEditOrderItemContainer } from './style';
// import { colors } from 'theme';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { StateContext } from 'contexts/StateContextContainer';
// import { DataContext } from 'contexts/DataContextContainer';

export default function DrawerEditOrderItem({
  visible = false,
  placement = 'right',
  onClose = () => { },
}) {
  const [amountOfItem, setAmountOfItem] = useState(1);
  // const [addons, setAddons] = [];
  const { setEditOrderItem } = useContext(StateContext);

  function changeAmountOfItem(value) {
    if (amountOfItem + value >= 1) {
      setAmountOfItem(amountOfItem + value);
    }
  }

  function submitChanges() {
    setEditOrderItem(false);
  }

  function closeMenu() {
    setEditOrderItem(false);
  }

  return (
    <DrawerEditOrderItemContainer
      onClose={onClose}
      visible={visible}
      placement={placement}
    >
      <Box is='div' display='flex' alignItems='center' marginBottom={60}>
        <SingleIconWrapper onClick={onClose}>
          <FaChevronLeft size={20} color='white' />
        </SingleIconWrapper>
        <Box is='div' display='flex' justifyContent='center' flex={1}>
          <SingleText content='Edit Item' align='center' size={22} weight={500}>Edit Item</SingleText>
        </Box>
      </Box>
      <div className="dressing-container">
        <div className="dressing-title">
          <p className="title">Dressing</p>
          <p className="title-amount">Select 1</p>
        </div>
        <div className="dressing-content">
          <div className={`dressing-item active`}>
            Ranch
          </div>
          <div className={`dressing-item`}>
            French
          </div>
          <div className={`dressing-item`}>
            Italian
          </div>
        </div>
      </div>
      <div className="add-ons-container">
        <div className="add-ons-title">
          <p className="title">Add-ons</p>
          <p className="title-amount">Optional</p>
        </div>
        <div className="add-ons-content">
          <div className={`add-ons-item`}>
            <p>Bacon</p>
            <p>+$1.00</p>
          </div>
          <div className={`add-ons-item`}>
            <p>Avocado</p>
            <p>+$2.00</p>
          </div>
          <div className={`add-ons-item`}>
            <p>Shrimp</p>
            <p>+$1.00</p>
          </div>
        </div>
      </div>
      <div className="special-instructions">
        <h6>Special Instructions</h6>
        <p>No pecans please, I’m allergic.</p>
      </div>
      <div className="amount-counter">
        <button type="button" className="button-changer" onClick={() => changeAmountOfItem(-1)}><i className="fas fa-minus" /></button>
        <span className="counter">{amountOfItem}</span>
        <button type="button" className="button-changer" onClick={() => changeAmountOfItem(1)}><i className="fas fa-plus" /></button>
      </div>
      <button type="button" className="submit-button" onClick={() => submitChanges()}>Save</button>
      <button type="button" className="cancel-button" onClick={() => closeMenu()}>Cancel</button>
    </DrawerEditOrderItemContainer>
  )
}

DrawerEditOrderItem.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
};
