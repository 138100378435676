import React from 'react';
import PropTypes from 'prop-types';
import { useOrders } from 'hooks';

export const PendingOrdersContext = React.createContext(null);

export function PendingOrdersContextContainer({ children }) {
  const {
    orders: pendingOrders,
    setOrders: setPendingOrders,
    isEmptyOrdersPool,
    onAddFoodItem,
    updateFoodItem,
    changeFoodItemStatus,
    removeFoodItem,
    changeActiveOrderStatus,
    activeOrder,
    setupActiveOrder,
    clearActiveOrder,
    isActiveOrderEmpty,
    moveActiveOrderToCompletedSection,
    deleteActiveOrder,
  } = useOrders();

  return (
    <PendingOrdersContext.Provider
      value={{
        pendingOrders,
        setPendingOrders,
        isEmptyOrdersPool,
        onAddFoodItem,
        updateFoodItem,
        changeFoodItemStatus,
        removeFoodItem,
        changeActiveOrderStatus,
        activeOrder,
        setupActiveOrder,
        clearActiveOrder,
        isActiveOrderEmpty,
        moveActiveOrderToCompletedSection,
        deleteActiveOrder,
      }}
    >
      {children}
    </PendingOrdersContext.Provider>
  );
}

PendingOrdersContextContainer.propTypes = {
  children: PropTypes.any,
};
