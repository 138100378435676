import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StateContext } from 'contexts/StateContextContainer';
// import { DataContext } from 'contexts/DataContextContainer';
import { DrawerOrderCompletedWrapper } from './style';

const DrawerOrderDeleted = ({
    visible = false,
    placement = 'bottom',
    // onClose = () => { },
}) => {
    const { setOpenOrderCompletedDrawer, setCompleteOrder } = useContext(StateContext);

    function closeMenus() {
        // setOrderItemForDelete('')
        setCompleteOrder(false);
        setOpenOrderCompletedDrawer(false);
    }

    return (
        <DrawerOrderCompletedWrapper
            onClose={closeMenus}
            visible={visible}
            placement={placement}
            height="auto"
        >
            <div className={`delete-day-block`}>
                <div className="head-block">
                    <div className="icon-container">
                        <i className="fas fa-receipt" />
                    </div>
                    <h3>Order Completed</h3>
                </div>
                <button className="button-save" onClick={() => closeMenus()}>Okay</button>
            </div>

        </DrawerOrderCompletedWrapper>
    )
}

export default DrawerOrderDeleted;

DrawerOrderDeleted.propTypes = {
    visible: PropTypes.bool,
    placement: PropTypes.string,
    onClose: PropTypes.func,
};