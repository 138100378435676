import React from 'react';
import PropTypes from 'prop-types';
import { DrawerBankAccountReplaceContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerBankAccountReplace({ visible, onClose, onReplace }) {
  const replace = () => {
    onClose();
    onReplace();
  };

  return (
    <DrawerBankAccountReplaceContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='replace-icon'>
        <i className='fas fa-repeat' key='repeat' />
      </div>
      <SingleText
        className='are-you-sure-title'
        size={22}
        lineHeight='34px'
        spacing='0.42px'
        align='center'
        width='329px'
        weight='500'
      >
        Are you sure you want to replace your bank account?
      </SingleText>
      <SingleButton
        title='Yes, replace bank account'
        margin='46px 0px 20px 0px'
        onClick={replace}
      />
      <SingleButton
        title='Keep'
        btnColor={colors.lightGray}
        onClick={onClose}
      />
    </DrawerBankAccountReplaceContainer>
  );
}

DrawerBankAccountReplace.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onReplace: PropTypes.func,
};
