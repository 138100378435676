import React, { useContext } from 'react'
import PropTypes from 'prop-types';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { colors } from 'theme';
import { DrawerCheckInMessageContainer } from './style';
import { StateContext } from 'contexts/StateContextContainer';

export default function DrawerCheckInMessage({
  visible = false,
  placement = 'bottom',
  onClose = () => { },
}) {
  const { checkinStatus, setOpenDrawerCheckInMessage } = useContext(StateContext);

  return (
    <DrawerCheckInMessageContainer
      placement={placement}
      onClose={onClose}
      height='fit-content'
      visible={visible}
    >
      <Box is='div' display='flex' flexDirection='column' alignItems='flex-start' marginBottom={30}>
        <SingleText content='Check In' size={22} weight={500} marginBottom={10}>Check In</SingleText>
        <SingleText content='Check in status' size={14} lineHeight={1.5} spacing='0.27px' marginBottom={21}>Check In is {checkinStatus === false && <b>not</b>} successful.</SingleText>
      </Box>
      <Box is='div' display='flex' flexDirection='column'>
        <SingleButton
          title={checkinStatus ? 'Okay' : 'Try Again'}
          btnColor={colors.redColor}
          txtColor='white'
          margin='0 0 20px'
          onClick={() => setOpenDrawerCheckInMessage(false)}
        />
      </Box>
    </DrawerCheckInMessageContainer>
  )
}

DrawerCheckInMessage.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickDeleteStaff: PropTypes.func,
};
