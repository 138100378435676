import React from 'react';
import PropTypes from 'prop-types';
import { CompletedAlertContainer } from './style';
import Button from 'react-bootstrap/Button';

export function CompletedAlertModal({ onClose }) {
  
  return (
    <CompletedAlertContainer>
        <div className='return-completed-alert-wrapper animate__animated animate__fadeInUp'>
          <div className='return-completed-svg-position'>
            <svg height='100' width='100'>
              <circle cx='50' cy='50' r='40' strokeWidth='3' fill='#27282a' />
            </svg>
          </div>

          <div className='return-completed-check-wrapper'>
            <i className='far fa-check return-completed-check'></i>
          </div>

          <div className='return-completed-body-wrapper'>
            <div>
              <p className='return-completed-text-style'>Return Completed</p>
            </div>

            <div className='return-completed-btn-center'>
              <Button
                variant='none'
                className='return-completed-btn-style'
                onClick={onClose}
              >
                <span className='return-completed-btn-text'>Okay</span>
              </Button>
            </div>
          </div>
        </div>
    </CompletedAlertContainer>
  );
}

CompletedAlertModal.propTypes = {
  onClose: PropTypes.func,
};
