import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerClassDetailsContainerStyled } from './style';
import { DrawerClassDetails } from './DrawerClassDetails';

export function DrawerClassDetailsContainer({ visible, onClose, onContinue }) {
  const [openDrawerClassDetails, setOpenDrawerClassDetails] = useState(false);
  const onOpenDrawerClassDetails = () => setOpenDrawerClassDetails(true);
  const onCloseDrawerClassDetails = () => setOpenDrawerClassDetails(false);

  const close = () => {
    onCloseDrawerClassDetails();
    onClose();
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerClassDetails();
    }
  }, [visible]);

  return (
    <DrawerClassDetailsContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerClassDetails
        visible={openDrawerClassDetails}
        onClose={close}
        onContinue={onContinue}
      />
    </DrawerClassDetailsContainerStyled>
  );
}

DrawerClassDetailsContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
};
