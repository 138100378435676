import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerAddTipContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  padding: 35px 0px 33px;
  height: ${({ customHeight }) => customHeight}px !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tip-options {
    width: 100%;
    height: 72px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    display: flex;

    .tip-option {
      cursor: pointer;
      border-right: 1px solid rgba(255, 255, 255, 0.08);
      width: 25%;
      height: 100%;
      padding: 26px 0px;
      text-align: center;

      &:last-of-type {
        border-right-color: transparent;
      }

      &.active {
        background-color: ${colors.redColor};
      }

      .tip-option-title {
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: -0.36px;
        color: white;
      }
    }
  }

  .type-tip-container {
    width: 100%;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 22px 33px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .tip-type-container {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      width: 50%;

      &.active {
        .tip-type-title,
        .tip-type-value {
          color: white;
        }

        .tip-type-value-container {
          input {
            color: white;
          }
        }
      }

      &.fixed {
        align-items: flex-start;
      }

      &.percentage {
        align-items: flex-end;
      }

      .tip-type-title,
      .tip-type-value {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.34px;
        color: rgba(255, 255, 255, 0.5);
      }

      .tip-type-title {
        line-height: 14px;
        margin-bottom: 3px;
      }

      .tip-type-value-container {
        display: flex;

        &.percents {
          input {
            text-align: right;
          }
        }

        input {
          -moz-appearance: textfield;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          background-color: transparent;
          border: 0px solid transparent;
          outline: none;
          padding-top: 0px;
          padding-bottom: 0px;
          padding-left: 4px;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  .total-tip-container {
    text-align: center;
    margin-top: 48px;
    margin-bottom: 17px;

    .tip-title {
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.25px;
      color: white;
    }
  }

  .bottom-container {
    width: 100%;
    padding: 0px 26px;
  }
`;
