import React, {useContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { ReviewReturnModalContainer } from './style';
import Button from 'react-bootstrap/Button';
import { RequestPendingContext } from 'contexts/RequestPendingContextContainer';
import { CompletedAlertModal } from './CompletedAlertModal';
import useLocalStorage from 'react-use-localstorage';
import { requestsServices } from 'services/api-services';
export default function ReviewReturnModal({ visible, onClose, doneStatus }) {
 
  const [restaurantId, setRestaurantId] = useLocalStorage('feasttt-restaurant-id', '');  
  const {
    pendingInfo,
    targetFoodCompletedOptionsText,
    displayPercentageDigit,
    targetPercentage,
    selectedPendingData,
    targetFoodCompletedOptions,
    pendingList,
    drinkOptionsText,
    drinkOptionsDigit,
    drinkStatus,
    otherOptionsText,
    otherOptionsDigit,
    otherStatus,
    refreshOtherResolve, 
    setRefreshOtherResolve,
  } = useContext(RequestPendingContext);
  const [reviewModal, setReviewModal] = useState(true)
  const [val, setVal] = useState(false)

  useEffect(() => {}, [val])

  const obj = []
  const objDrinkRefill =[]
  const objOther = []
  
  function reviewReturnModalBtn() {
    setReviewModal(false)
    removeData()
   // api call patch *partial*
   // const resId = restaurantId;
   // const reqId = selectedPendingData.id;
   // const data = { done_status: doneStatus, items: obj };
   // requestsServices.requestToCompleted(resId, reqId, data)
  }

  function removeData() {
    let data = pendingList.map((dataItem, index) => {
        if(dataItem.id === selectedPendingData.id) {
             removeDataCompleted()
             pendingList.splice(index, 1)
             setVal(!val)
           return index
        }
     })
     // it is for test
     // console.log(data, 'data')
 }

  // return resolve api
  function removeDataCompleted() {
    const resId = restaurantId;
    const reqId = selectedPendingData.id;
    const data = { done_status: doneStatus, items: obj };
    requestsServices.requestToCompleted(resId, reqId, data)
  }

  const {request_type} = pendingInfo;

  // drink-refill-resolve api
  function reviewDrinkModalBtn() {
    const resId = restaurantId;
    const reqId = selectedPendingData.id;
    const data = { done_status: drinkStatus, items: objDrinkRefill };
    requestsServices.requestToCompleted(resId, reqId, data)
  }

  //other-resolve api refresh
  function reviewOtherModalBtn() {
    setReviewModal(false)
    const resId = restaurantId;
    const reqId = selectedPendingData.id;
    const data = { done_status: otherStatus, items: objOther };
    requestsServices.requestToCompleted(resId, reqId, data)
    .then((res) => {
      // setRefreshOtherResolve(!refreshOtherResolve)
      window.location.reload(false) 
    })
    .catch((err) => err)
  }

  return (
    <ReviewReturnModalContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      {reviewModal ? (
        <div className='return-review-wrapper animate__animated animate__fadeInUp'>
          <div>
            <p className='return-review-text'>Review return</p>
          </div>

          {pendingInfo.items &&
            pendingInfo.items.map((review_foods, _index) => {

              {/* return completed */}
              obj.push({
                id: review_foods.id,
                resolve_type: targetFoodCompletedOptions,
                partial: targetPercentage,
              });

              {/* drink-refill completed */}
              objDrinkRefill.push({
                id: review_foods.id,
                resolve_type: drinkOptionsDigit
              })

              {/* other completed */}
              objOther.push({
                id: review_foods.id,
                resolve_type: review_foods.selectedResolveType === 2 ? 2 : null,
                reject_reason: review_foods.selectedResolveType === 1 ? 1 : null
              })
           
              {/* test */}
              {/* console.log(review_foods.selectedResolveType, 'resolve-type')  */}

              return (
                <div key={'food-review-return' + _index}>
                  <div className='return-review-border-padding'>
                    <p className='return-review-title'>
                      {review_foods?.food_item?.food_name}
                    </p>
                    <p
                      style={{ color: '#1fc086' }}
                      className='return-review-refund-rejected-text'
                    >
                      {request_type === 1 ? (
                        <span>
                          {targetFoodCompletedOptionsText}{' '}
                          {displayPercentageDigit}
                        </span>
                      ) : (
                        ''
                      )}

                      {request_type === 0 ? (
                        <span>{drinkOptionsText}</span>
                      ) : (
                        ''
                      )}

                      {request_type === 2 ? (
                          review_foods.selectedResolveType === 2 ? 'Fulfield': <span style={{color:'red'}}>Rejected</span>
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                </div>
              );
            })}

          {/* not implement refunded functionality yet */}
          <p className='return-review-refund'>$31.38 will be refunded.</p>

          {/* complete-request return btn */}
          {request_type === 1 ? (
            <div className='return-review-btn-center'>
              <Button
                variant='none'
                className='return-review-btn-style'
                onClick={reviewReturnModalBtn}
              >
                <span className='return-review-btn-text'>Complete Request</span>
              </Button>
            </div>
          ) : (
            ''
          )}

          {/* complete-request drink-refill btn */}
          {request_type === 0 ? (
            <div className='return-review-btn-center'>
              <Button
                variant='none'
                className='return-review-btn-style'
                onClick={reviewDrinkModalBtn}
              >
                <span className='return-review-btn-text'>Complete Request</span>
              </Button>
            </div>
          ) : (
            ''
          )}

          {/* complete-request other btn */}
          {request_type === 2 ? (
            <div className='return-review-btn-center'>
              <Button
                variant='none'
                className='return-review-btn-style'
                onClick={reviewOtherModalBtn}
              >
                <span className='return-review-btn-text'>Complete Request</span>
              </Button>
            </div>
          ) : (
            ''
          )}

          <p
            className='return-review-go-back-text'
            onClick={onClose}
            aria-hidden='true'
          >
            Go Back
          </p>
        </div>
      ) : (
        <CompletedAlertModal onClose={onClose} />
      )}
    </ReviewReturnModalContainer>
  );
}

ReviewReturnModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  doneStatus : PropTypes.number
};



