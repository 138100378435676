import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DrawerProcessPaymentAreYouSureContainer } from './style';
import { colors } from 'theme';
import { needToPay } from 'services/functions';
import { ActiveOrdersContext } from 'contexts/ActiveOrdersContextContainer';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { PaymentCard } from '../../PaymentCard';
import { PaymentWay } from '../../PaymentWay';

export function DrawerProcessPaymentAreYouSure({
  isNoPaymentNecessary = false,
  isCash = false,
  isTipAvailable = false,
  tip = {},
  paymentMethod = {},
  visible,
  onClose,
  onAddTip,
  onSendPayment,
  onOpenDrawerAreYouSureNoPaymentContainer = () => {},
}) {
  const { activeOrder } = useContext(ActiveOrdersContext);

  return (
    <DrawerProcessPaymentAreYouSureContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
      customHeight={
        (isNoPaymentNecessary ? 460 : 555) + (isTipAvailable ? 19 : 0)
      }
      totalContainerMarginTop={isTipAvailable ? 45 : 26}
    >
      <div className='money-icon'>
        <i className='far fa-money-bill-wave-alt' />
      </div>
      <SingleText
        className='are-sure-payment-title'
        size={22}
        spacing='0.42px'
        align='center'
        weight='500'
        width='329px'
        marginBottom={10}
      >
        Are you sure?
      </SingleText>
      <SingleText
        lineHeight='21px'
        spacing='0.27px'
        weight='500'
        width='265px'
        align='center'
        marginBottom={18}
      >
        Do you want to process a payment using this method?
      </SingleText>
      {isNoPaymentNecessary ? (
        <PaymentWay
          className='selected-thing-to-payment no-payment'
          title={paymentMethod.title}
        />
      ) : isCash ? (
        <PaymentWay
          className='selected-thing-to-payment'
          title={paymentMethod.title}
          icon={paymentMethod.icon}
        />
      ) : (
        <PaymentCard
          className='selected-thing-to-payment'
          type={paymentMethod.type}
          lastFourNumbers={paymentMethod.lastFourNumbers}
          expire={paymentMethod.expire}
          name={paymentMethod.name}
        />
      )}
      {!isNoPaymentNecessary ? (
        <>
          <SingleButton
            title={
              isTipAvailable ? (
                <>
                  Edit Tip
                  <br />
                  <span className='will-be-added-as-a-tip'>
                    {`${tip.type === 'fixed' ? '$' : ''}${tip.value}${
                      tip.type === 'percentage' ? '%' : ''
                    }`}{' '}
                    will be added as a tip.
                  </span>
                </>
              ) : (
                'Add a Tip'
              )
            }
            btnColor='transparent'
            txtColor={colors.redColor}
            margin='16px 0px 0px 0px'
            onClick={onAddTip}
          />
          <div className='total-container'>
            <span className='total-title'>
              Total: $
              {isTipAvailable
                ? (
                    needToPay({ items: [], ...activeOrder }) + tip.money
                  ).toFixed(2)
                : needToPay({ items: [], ...activeOrder }).toFixed(2)}
            </span>
          </div>
        </>
      ) : (
        ''
      )}
      <SingleButton
        title='Process Payment'
        margin='16px 0px 20px'
        onClick={() =>
          isNoPaymentNecessary
            ? onOpenDrawerAreYouSureNoPaymentContainer()
            : onSendPayment(tip)
        }
      />
      <SingleButton
        title='Go Back'
        btnColor={colors.lightGray}
        onClick={onClose}
      />
    </DrawerProcessPaymentAreYouSureContainer>
  );
}

DrawerProcessPaymentAreYouSure.propTypes = {
  isNoPaymentNecessary: PropTypes.bool,
  isCash: PropTypes.bool,
  isTipAvailable: PropTypes.bool,
  tip: PropTypes.object,
  paymentMethod: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onAddTip: PropTypes.func,
  onSendPayment: PropTypes.func,
  onOpenDrawerAreYouSureNoPaymentContainer: PropTypes.func,
  onCloseWholeProcessDrawer: PropTypes.func,
};
