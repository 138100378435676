import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RejectConfirmOtherRequestContainer } from './style';
import { RejectedAlertOtherRequest } from './RejectedAlert';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';

export function RejectConfirmOtherRequest({ onClose, removePendingOtherData }) {
  const context = useContext(modalContext);

  function RejectedConfirmOtherRequestFunc() {
    context.setModal('Reject-Other-Modal-4');
    removePendingOtherData()
  }
  return (
    <RejectConfirmOtherRequestContainer>
      {context.modal === 'Reject-Other-Modal-3' ? (
        <>
          <div className='reject-confirm-other-wrapper animate__animated animate__fadeInUp'>
            <div>
              <p className='reject-confirm-other-heading'>Are you sure?</p>
            </div>

            <div>
              <p className='reject-confirm-other-paragraph'>
                The full request will be rejected. This cannot be undone.
              </p>
            </div>

            <div>
              <Button
                variant='none'
                className='reject-confirm-other-yes-reject-request-btn'
                onClick={RejectedConfirmOtherRequestFunc}
              >
                Yes, reject request
              </Button>
            </div>

            <div>
              <Button
                variant='none'
                className='reject-confirm-other-keep-btn'
                onClick={onClose}
              >
                Keep
              </Button>
            </div>
          </div>
        </>
      ) : (
        <RejectedAlertOtherRequest onClose={onClose} />
      )}
    </RejectConfirmOtherRequestContainer>
  );
}

RejectConfirmOtherRequest.propTypes = {
  onClose: PropTypes.func,
  removePendingOtherData: PropTypes.func
};
