import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DrawerMyAccountContainer } from './style';
import { colors } from 'theme';
import { useUpdateMyAccount } from 'hooks';
import { AuthContext } from 'contexts/AuthContextContainer';
import { DrawerLogOutContainer } from 'pages/Profile/components/ProfileHeader/DrawerLogOutContainer';
import SingleButton from 'components/SingleButton';
import { DrawerDeleteAccountContainer } from './DrawerDeleteAccountContainer';
import { MyAccountMore } from './MyAccountMore';
import { Account } from './Account';
import { Settings } from './Settings';

import { userServices } from 'services/api-services';

// import { HighestServer } from 'assets/images/profile';
import { DefaultUser } from 'assets/images/profile';
import { ScaleLoader } from 'react-spinners';
import { useBinaryState } from 'hooks';
import { showMessage } from 'services/functions';
import { assetServices } from 'services/api-services';
import { ProfileUpdateContext } from 'routes';

// @TODO We need confirmation from server for this values (new email value & new phone value)
export function DrawerMyAccount({ visible, onClose }) {
  const { authUser, setAuthUser } = useContext(AuthContext);
  const { ProfileFirstName, ProfileLastName } = useContext(ProfileUpdateContext);
  const [isUserChanged, setUserChanged] = useState(false);

  const {
    user,
    changeFirstName,
    changeLastName,
    changeMobileNumber,
    confirmMobileNumber,
    changeEmail,
    confirmEmail,
    changePushNotifications,
    changeSMSNotifications,
    changeEmailNotifications,
    changePromotionalNotifications,
    changeAvatar,
    changeUser,
  } = useUpdateMyAccount();

  const [openDrawerLogOut, setOpenDrawerLogOut] = useState(false);
  const onOpenDrawerLogOut = () => setOpenDrawerLogOut(true);
  const onCloseDrawerLogOut = () => setOpenDrawerLogOut(false);

  const [openDrawerDeleteAccount, setOpenDrawerDeleteAccount] = useState(false);
  const onOpenDrawerDeleteAccountContainer = () =>
    setOpenDrawerDeleteAccount(true);
  const onCloseDrawerDeleteAccountContainer = () =>
    setOpenDrawerDeleteAccount(false);

  useEffect(() => {
    (async () => {
      const res = await userServices.getMyInfo();
      setAuthUser(res.data);
    })();
  }, []);

  useEffect(() => {
    const temp =
      user &&
      (user.hasOwnProperty('isEmailConfirmed') ||
        user.hasOwnProperty('isMobileNumberConfirmed'));
    if ((visible && user === undefined) || temp) {
      const newUser = {...authUser}
      if(temp && user.isEmailConfirmed) newUser.isEmailConfirmed = user.isEmailConfirmed
      if(temp && user.isMobileNumberConfirmed) newUser.isMobileNumberConfirmed = user.isMobileNumberConfirmed
      changeUser(newUser);
    }
  }, []);

  const getUserAvatar = () => user?.avatar?.file ?? DefaultUser;
  // ---------------------s3 upload-----------------------------
  const onUploadLocalFileToAssetsStorage = async (info) => {
    const fileToUploadFromFormData = createFormDataForLocalFile(info);
    startUploading();
    uploadPhotoAsset(fileToUploadFromFormData);
  };

  const createFormDataForLocalFile = (uploadInfo) => {
    const postData = new FormData();
    postData.append('asset', uploadInfo.target.files[0]);
    postData.append('type', 'user');
    postData.append('api_key', process.env.REACT_APP_API_KEY);

    return postData;
  };

  const {
    isActive: isUploading,
    activate: startUploading,
    deactivate: endUploading,
  } = useBinaryState();

  const uploadPhotoAsset = async (localFile) =>
    await assetServices.uploadToS3(
      localFile,
      onSuccessfullyUploaded,
      onFailWhenTriedUpload
    );

  const putAvatarOnServer = (uploadedResource) => {
    const data = {
      avatar: uploadedResource?.id ?? null,
    };
    const previousAvatar = authUser.avatar;
    setAuthUser({
      ...authUser,
      avatar: uploadedResource,
    });

    const onOk = () => {};

    const onFail = (errMessage) => {
      showMessage('Something went wrong!');
      setAuthUser({
        ...authUser,
        avatar: previousAvatar,
      });
      console.log('Something went wrong!', errMessage);
    };

    userServices.updateProfile(data, onOk, onFail);
  };

  const onSuccessfullyUploaded = (uploadedResource) => {
    console.log(uploadedResource, 'full details of upload image');

    endUploading();
    putAvatarOnServer(uploadedResource);
    changeAvatar({
      avatar: uploadedResource,
    });
    setAuthUser({
      ...authUser,
      avatar: uploadedResource,
    });
  };

  const onFailWhenTriedUpload = (errMessage) => {
    showMessage(errMessage, 'error');
    endUploading();
  };

  const inputFileRef = useRef();
  const invokeEventForInput = () => inputFileRef.current.click();

  // profile put api
  function ProfileSaveChanges() {
    const data = {
      first_name: ProfileFirstName,
      last_name: ProfileLastName,
      notification_push: user.notification_push,
      notification_promote: user.notification_promote,
    };

    if (user.email) data.email = user.email;
    if (user.notification_sms) data.notification_sms = user.notification_sms;
    if (user.notification_email) data.notification_email = user.notification_email;

    const oldUser = { ...authUser };

    setAuthUser({
      // updated here, so that the update for user looked like instant, without waiting for server
      ...authUser,
      ...data,
    });

    const onOk = () => {
      setUserChanged(false);
      onClose();
    }
    const onFail = (err) => {
      showMessage('Something went wrong!');
      setAuthUser(oldUser); // if server error, data just will be reverted
      console.log('Something went wrong!', err);
    };

    userServices.updateProfile(data, onOk, onFail);
  }

  return (
    <DrawerMyAccountContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      <div
        className='my-account-top-image-container'
        style={{
          backgroundImage: `url(${getUserAvatar()})`,
        }}
      />

      <input
        className='file-image-input'
        type='file'
        ref={inputFileRef}
        onChange={onUploadLocalFileToAssetsStorage}
        accept='image/*'
      />

      {isUploading && (
        <div className='uploading-icon'>
          <ScaleLoader size={15} color={'white'} />
        </div>
      )}

      <div className='my-account-top'>
        {/* eslint-disable-next-line */}
        <div className='close-icon' onClick={onClose}>
          <i className='fas fa-chevron-left' />
        </div>
        <div className='my-account-top-title-container'>
          <span className='my-account-top-title'>My Account</span>
        </div>
        <MyAccountMore
          onLogout={onOpenDrawerLogOut}
          onDelete={onOpenDrawerDeleteAccountContainer}
        />
      </div>
      <div
        className='account-photo'
        style={{
          backgroundImage: `url(${getUserAvatar()})`,
        }}
      >
        {/* eslint-disable-next-line */}
        <div className='btn-change' onClick={invokeEventForInput}>
          Change
        </div>
      </div>
      {user ? (
        <>
          <Account
            setUserChanged={() => setUserChanged(true)}
            user={user}
            changeFirstName={changeFirstName}
            changeLastName={changeLastName}
            changeEmail={changeEmail}
            confirmEmail={confirmEmail}
            changeMobileNumber={changeMobileNumber}
            confirmMobileNumber={confirmMobileNumber}
          />
          <Settings
            setUserChanged={() => setUserChanged(true)}
            user={user}
            changePushNotifications={changePushNotifications}
            changeSMSNotifications={changeSMSNotifications}
            changeEmailNotifications={changeEmailNotifications}
            changePromotionalNotifications={changePromotionalNotifications}
            isEmailVerified={user && user.isEmailConfirmed}
            isPhoneVerified={user && user.isMobileNumberConfirmed}
          />
        </>
      ) : (
        ''
      )}
      <div className='save-changes-container'>
        <SingleButton
          title='Save Changes'
          btnColor={isUserChanged ? colors.redColor : 'rgba(255, 255, 255, 0.1)'}
          txtColor={isUserChanged ? 'white' : 'rgba(255, 255, 255, 0.2)'}
          onClick={isUserChanged ? ProfileSaveChanges : () => {}}
          disabled={!isUserChanged}
        />
      </div>
      <DrawerLogOutContainer
        visible={openDrawerLogOut}
        onClose={onCloseDrawerLogOut}
      />
      <DrawerDeleteAccountContainer
        visible={openDrawerDeleteAccount}
        onClose={onCloseDrawerDeleteAccountContainer}
      />
    </DrawerMyAccountContainer>
  );
}

DrawerMyAccount.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
