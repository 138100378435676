import styled from '@emotion/styled';
import { colors } from 'theme';

export const CarouselContainer = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  width: 72%; 

  .nav-button {
    border-radius: 50%;
    border: none;
    background: #1b1c1d;
    padding: 10px 16px 8px;
    outline: none;

    &:hover {
      background: #27282a;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .container {
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 0 15px 0 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .edit-icon {
    box-sizing: border-box;
  }
`;

export const CategoriesTopListContainer = styled.div`  
  .category-label-container {
    height: 40px;
    padding: 0 21px;
    width: auto;
    display: flex;
    align-items: center;
    border-radius: 25px;
    background-color: ${colors.gray2};

    &.selected {
      background-color: ${colors.gray5};
    }
  }
`;
