import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerLogOutContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  height: 315px !important;
  padding: 72px 26px 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  position: relative;

  .logout-icon {
    z-index: 101;
    width: 68.5px;
    height: 68.5px;
    padding: 22px 21px;
    border-radius: 50%;
    background-color: ${colors.redColor};
    position: absolute;
    top: -37px;

    i {
      font-size: 26px;
      letter-spacing: 0.46px;
      color: white;
    }
  }

  &:before {
    content: '';
    display: block;
    width: 79px;
    height: 33px;
    background-color: transparent;
    position: absolute;
    top: -16.7px;
    left: 92px;
    border-right: 15px solid ${colors.gray5};
    border-bottom: 16px solid ${colors.gray5};
    border-bottom-right-radius: 51px;
  }

  &:after {
    content: '';
    display: block;
    width: 79px;
    height: 33px;
    background-color: transparent;
    position: absolute;
    top: -16.7px;
    right: 92px;
    border-left: 15px solid ${colors.gray5};
    border-bottom: 16px solid ${colors.gray5};
    border-bottom-left-radius: 51px;
  }

  .you-will-be-logged-out {
    position: relative;
    &:before {
      content: '';
      width: 86px;
      height: 86px;
      border: 16px solid ${colors.gray5};
      border-radius: 50%;
      background-color: transparent;
      position: absolute;
      top: -155px;
      left: 77.85px;
    }
  }
`;
