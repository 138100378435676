import React, { useState } from 'react';
import { ProfileHeaderContainer } from './style';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import { DrawerLogOutContainer } from './DrawerLogOutContainer';

export function ProfileHeader() {
  const [openLogOutDrawer, setLogOutDrawer] = useState(false);
  const onOpenLogOutDrawer = () => setLogOutDrawer(true);
  const onCloseLogOutDrawer = () => setLogOutDrawer(false);

  return (
    <ProfileHeaderContainer>
      <SingleText size={24} weight='bold' marginRight='auto'>
        Restaurant Profile
      </SingleText>
      <Box className='profile-log-out-container' onClick={onOpenLogOutDrawer}>
        <Box className='profile-log-out-icon'>
          <i className='fas fa-sign-out-alt' />
        </Box>
        <SingleText size={16} weight={500}>
          Log Out
        </SingleText>
      </Box>
      <DrawerLogOutContainer
        visible={openLogOutDrawer}
        onClose={onCloseLogOutDrawer}
      />
    </ProfileHeaderContainer>
  );
}
