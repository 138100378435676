import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ParamControlContainer } from './style';

export function ParamControl({
  title = '',
  options = [],
  selectedOptions = [],
  onChange = () => {},
  isOptional,
}) {
  const [open, setOpen] = useState(false);
  const showHide = () => setOpen(!open);

  return (
    <ParamControlContainer>
      <div className='param-control-top' onClick={showHide}>
        <div className='param-control-top-title-container'>
          <span className='param-control-top-title'>{title}</span>
        </div>
        <div className='show-control-container'>
          <div
            className={`option-title-container ${
              isOptional ? 'not-so-important' : ''
            }`}
          >
            <span className='option-title'>Optional</span>
          </div>
          {selectedOptions.length ? (
            <div className='show-selected-items-total'>
              {selectedOptions.length}
            </div>
          ) : (
            ''
          )}
          <div className='show-button-container'>
            <i
              className={`fas ${open ? 'fa-chevron-up' : 'fa-chevron-down'}`}
            />
          </div>
        </div>
      </div>
      {open ? (
        <div className='param-control-options'>
          {options.map(([option, price], index) => (
            <div
              className={`param-control-option ${
                selectedOptions.some(
                  ([selectedOption]) => selectedOption === option
                )
                  ? 'active'
                  : ''
              }`}
              onClick={() => onChange(price ? [option, price] : [option])}
              key={option + index}
            >
              {price ? (
                <div className='name-with-price'>
                  <span className='option-name'>{option}</span>
                  <span className='option-price'>+${price.toFixed(2)}</span>
                </div>
              ) : (
                option
              )}
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </ParamControlContainer>
  );
}

ParamControl.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  onChange: PropTypes.func,
  isOptional: PropTypes.bool,
};
