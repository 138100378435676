import React from 'react';
import PropTypes from 'prop-types';
import { CardInputContainer } from './style';
import { selectTextByClick } from 'services/functions';

export function CardInput({
  className = '',
  htmlType = 'text',
  label,
  value,
  formatToReadableValue = (initial) => initial,
  convertVisibleValueToRealValue = (transformed) => transformed,
  onChange = () => {},
  isValid,
  isTouched,
}) {
  return (
    <CardInputContainer className={className}>
      <label>{label}</label>
      <div className='card-input-container'>
        <input
          type={htmlType}
          className='card-input-view'
          value={formatToReadableValue(value)}
          onChange={(event) =>
            onChange(convertVisibleValueToRealValue(event.target.value))
          }
          placeholder=''
          onClick={(event) => selectTextByClick(event.target)}
        />
        <div
          className={`card-input-icon ${
            isTouched ? (isValid ? 'valid' : 'error') : 'empty'
          }`}
        >
          {!isTouched || isValid ? (
            <i className='fas fa-check-circle' />
          ) : (
            <i className='fas fa-times-circle' />
          )}
        </div>
      </div>
    </CardInputContainer>
  );
}

CardInput.propTypes = {
  className: PropTypes.string,
  htmlType: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  formatToReadableValue: PropTypes.func,
  convertVisibleValueToRealValue: PropTypes.func,
  onChange: PropTypes.func,
  isValid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isTouched: PropTypes.bool,
};
