import { FloorItem } from './FloorItem';
import { Tables } from '../../Tables';

export class AvailableFloor extends FloorItem {
  getTotalAvailableTables() {
    if (this.floor) {
      const totalAvailableTables = this.floor.tables.filter((table) => {
        const tableStatus = Tables.createTableStatus(table);
        return tableStatus.isAvailable();
      });

      return totalAvailableTables.length;
    } else {
      return 0;
    }
  }

  isFloorHaveAvailableTables() {
    return this.getTotalAvailableTables() !== 0;
  }
}
