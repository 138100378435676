import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { DashboardSearchContainer } from './style';
import useOutsideClick from 'components/OutsideClickDetect';
import { FaSearch } from 'react-icons/fa';
import { restaurantServices } from 'services/api-services';
import { showMessage } from 'services/functions';
import { AuthContext } from 'contexts/AuthContextContainer';
import { staffsTemp } from 'services/static';

export function DashboardSearch({
  placeholder = '',
  onChangeSearch = () => {},
  onClearAfterSearch = () => {},
}) {
  const ref = useRef();
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useOutsideClick(ref, () => {
    if (searchTerm === '') {
      setIsSearchButtonClicked(false);
    }
  });

  function handleSelect(suggestion) {
    setSearchTerm(suggestion.value);
    onChangeSearch(suggestion.value);
  }

  // staff api
  const { restaurantId } = useContext(AuthContext);
  const [selectedSort, setSelectedSort] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [staffs, setStaffs] = useState([]);

  useEffect(() => {
    getAllStaff();
  }, [staffsTemp]);

  useEffect(() => {
    getAllStaff();
  }, [selectedSort, selectedRole, selectedStatus]);

  async function getAllStaff() {
    const onOk = (data) => {
      setStaffs(data.staffs);
    };

    const onFail = (errMessage) => {
      showMessage(errMessage, 'error');
    };

    if (restaurantId) {
      const filters = {
        sort: selectedSort,
        role_slug: selectedRole,
        status: selectedStatus,
      };
      return await restaurantServices.getStaffs(
        restaurantId,
        filters,
        onOk,
        onFail
      );
    }
  }

  // menu api
  const { authUser } = useContext(AuthContext);
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    getMenus();
  }, []);

  const getMenus = async () => {
    const { ok, data, errMessage } = await restaurantServices.getMenus(
      authUser.restaurant_id
    );
    if (ok) {
      setMenus(data);
    } else {
      showMessage(errMessage, 'error');
    }
  };

  // merge array
  const obj = staffs.concat(menus)

  function clearSearchElement(e) {
    e.stopPropagation();
    setSearchTerm('');
    onClearAfterSearch();
    setIsSearchButtonClicked(false);
  }

  function expandMenu() {
    setIsSearchButtonClicked(true);
  }

  const filteredOptions = obj.filter((suggestion) =>
   (suggestion?.first_name+'').toLowerCase().includes(searchTerm.toLocaleLowerCase())||(suggestion?.name+'').toLowerCase().includes(searchTerm.toLocaleLowerCase())
  );

  const renderSuggestions = () => {
    if (filteredOptions.length) {
      return filteredOptions.map((suggestion) => {
        return (
          <div
            // className='suggestion-item'
            key={suggestion.id}
            onClick={() => handleSelect(suggestion)}
            aria-hidden='true'
          >
            {/* <div className='suggestion-main'> */}
              <div className="flex-wrapper">
                <div>{suggestion?.first_name} {suggestion?.name}</div>
                <div style={{ color: '#5a5a5a' }}>
                  {suggestion?.first_name ? 'Staff' : ''}
                  {suggestion?.name ? 'Menu' : ''}
                </div>
              </div>
            {/* </div> */}
          </div>
        );
      });
    } else {
      return [];
    }
  };

  return (
    <DashboardSearchContainer ref={ref} active={isSearchButtonClicked}>
      <div
        className='search-container'
        onClick={() => expandMenu()}
        aria-hidden='true'
      >
        <input
          className='search-container-input'
          value={searchTerm}
          placeholder={placeholder || 'Enter...'}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        />
        {searchTerm ? (
          <div
            className='clear-search'
            style={{ cursor: 'pointer' }}
            onClick={(e) => clearSearchElement(e)}
            aria-hidden='true'
          >
            <i className='fas fa-times' />
          </div>
        ) : (
          <div className='clear-search'>
            <FaSearch />
          </div>
        )}
      </div>
      {searchTerm.length !== 0 && (
        <div
          className={`suggestions-list ${
            !filteredOptions.length ? 'not-found' : ''
          }`}
        >
          {renderSuggestions()}
        </div>
      )}
    </DashboardSearchContainer>
  );
}

DashboardSearch.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChangeSearch: PropTypes.func,
  onClearAfterSearch: PropTypes.func,
};
