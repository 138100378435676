import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types';
// import './style.scss';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerDeleteStaffContainer } from './style';
import { showMessage } from 'services/functions';
import { restaurantServices } from 'services/api-services';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';

export default function DrawerDeleteStaff({
  visible = false,
  placement = 'bottom',
  onClose = () => { },
}) {
  const [isAccountConfirmation, setIsAccountConfirmation] = useState(false);

  const { setOpenDrawerStaffDelete } = useContext(StateContext);
  const { chosenStaff } = useContext(DataContext);
  const { restaurantId, authUser } = useContext(AuthContext);

  const onClickDeleteStaff = async () => {
    if (authUser.id === chosenStaff.id) {
      showMessage("You cannot delete yourself.", 'error')
      setOpenDrawerStaffDelete(false)
    } else {
      const { ok, errMessage } = await restaurantServices.deleteStaff({ restaurantId, staffId: chosenStaff.id });
      if (ok) {
        showMessage('Status was deleted', 'success')
      } else {
        showMessage(errMessage, 'error')
      }
      setOpenDrawerStaffDelete(false)
    }
    resetAccountConfirmation();
  }

  const resetAccountConfirmation = () => setIsAccountConfirmation(false);

  const onDrawerClose = () => {
    resetAccountConfirmation();
    onClose()
  }

  const title = isAccountConfirmation ? 'Are you sure?' : 'Remove Staff';
  const body = isAccountConfirmation ? `${chosenStaff.first_name} will no longer be able to access their account.` : `Are you sure you want to remove ${chosenStaff.first_name}?`;
  const buttonTitle = isAccountConfirmation ? 'Yes, remove account' : 'Remove';

  return (
    <DrawerDeleteStaffContainer
      placement={placement}
      onClose={onDrawerClose}
      height='fit-content'
      visible={visible}
    >
      <Box is='div' display='flex' flexDirection='column' alignItems='flex-start' marginBottom={30}>
        <SingleText content={title} size={22} weight={500} marginBottom={10}>{title}</SingleText>
        <SingleText content={body} size={14} lineHeight={1.5} spacing='0.27px' marginBottom={21}>{body}</SingleText>
      </Box>
      <Box is='div' display='flex' flexDirection='column'>
        <SingleButton
          title={buttonTitle}
          margin='0 0 20px'
          onClick={() => isAccountConfirmation ? onClickDeleteStaff() : setIsAccountConfirmation(true)}
        />
        <SingleButton
          title='Keep'
          btnColor='#61646c'
          txtColor='white'
          margin='0 0 20px'
          onClick={onDrawerClose}
        />
      </Box>
    </DrawerDeleteStaffContainer>
  )
}

DrawerDeleteStaff.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickDeleteStaff: PropTypes.func,
};
