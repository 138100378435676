import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RejectOtherContainer } from './style';
import { RejectConfirm } from './RejectConfirm';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { modalContext } from 'routes';
import { RequestPendingContext } from 'contexts/RequestPendingContextContainer';
export function RejectOther({ onClose, removePendingData }) {
  const context = useContext(modalContext);
  const {otherReasonInput, setOtherReasonInput} = useContext(RequestPendingContext)
  function RejectOtherFunc(e) {
    context.setModal('Modal-3');
    e.preventDefault();
    // its for test
    // console.log(otherReasonInput)
  }

  return (
    <RejectOtherContainer>
      {context.modal === 'Modal-2' ? (
        <>
          <div className='reject-other-wrapper animate__animated animate__fadeInUp'>
            <div className='padding-wrap-1'>
              <div>
                <p className='reject-other-heading'>
                  Reason return request <br />
                  can't be fulfilled.
                </p>
              </div>

              <div className='reject-other-comment-flex'>
                <p className='comments'>Comments</p>
                <p className='optional'>Optional</p>
              </div>
            </div>

           <Form>
            <div>
              <div className='border-top-style'></div>
              <input
                type='text'
                placeholder='Describe why you are rejecting this request.'
                className='input-style'
                value={otherReasonInput}
                onChange={(e) => setOtherReasonInput(e.target.value)}
              />
              <div className='border-top-style'></div>
            </div>

            <div className='padding-wrap-2'>
              <div>
                <Button
                  variant='none'
                  className='reject-other-reject-request-btn'
                  type='submit'
                  onClick={RejectOtherFunc}
                >
                  Reject Request
                </Button>
              </div>

              <div>
                <Button
                  variant='none'
                  className='reject-other-keep-btn'
                  onClick={onClose}
                >
                  Keep
                </Button>
              </div>  
            </div>
           </Form>
          </div>
        </>
      ) : (
        <RejectConfirm onClose={onClose} removePendingData={removePendingData} />
      )}
    </RejectOtherContainer>
  );
}

RejectOther.propTypes = {
  onClose: PropTypes.func,
  removePendingData: PropTypes.func,
};
