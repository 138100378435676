import styled from '@emotion/styled';
import { colors } from 'theme';

export const PersonalCheckingContainerStyled = styled.div`
  background-color: ${colors.gray2};
  height: 100%;
  padding: 0px 26px 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`;
