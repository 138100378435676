import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerCheckInSummaryWrapper = styled(SingleDrawer)`
  z-index: 1001;
  ${'' /* background-color: ${colors.gray1}; */}
  position: initial;
  background-color: #27282a;
  color: #fff;
  overflow: inherit;
  .check-in-summary {
    .waiter-info-container {
      margin-bottom: 30px;
      .img-container {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        background-color: #27282a;
        border: solid 6px #27282a;
        position: absolute;
        top: -55px;
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .content {
        padding-top: 45px;
        display: flex;
        justify-content: center;
        font-size: 22px;
        font-weight: 600;
      }
    }

    .table-info-container {
      padding: 20px;
      border-radius: 6px;
      background-color: #1b1c1d;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 35px;
      .left-part {
        .floor-title {
          font-size: 14px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.5);
          margin-bottom: 8px;
        }
        .table-title {
          font-size: 15px;
          font-weight: bold;
          color: #fff;
          margin-bottom: 7px;
        }
        .seats-amount {
          display: flex;
          align-items: center;

          .seats-icon {
            background-color: ${colors.gray8};
            margin-right: 5px;
            width: 13px;
            height: 10px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            position: relative;

            &:before {
              content: '';
              display: block;
              width: 9px;
              height: 9px;
              background-color: ${colors.gray8};
              position: absolute;
              top: 4px;
              right: 1.95px;
              border-radius: 2px;
              border: 2px solid ${colors.gray2};
              bottom: 0px;
            }
          }

          .seats-title {
            font-size: 14px;
            font-weight: 500;
            color: ${colors.gray8};
          }

        }
      }
      .right-part {
        position: relative;

        .right-table-icon {
          // transform: translate(-78px, -35px)!important;
          transform: translate(-77px, -24px)!important;// perfect for <= 4

          &.big-table {
            transform: translate(-90px, -38px)!important;
          }
        }
      }
    }

    .selected-server-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      border-top: 1px solid rgba(255, 255, 255, 0.08);
      margin-left: -24px;
      margin-right: -24px;
      margin-bottom: 60px;
      padding: 10px 24px;
      .left-part {
        display: flex;
        align-items: center;
        .img-container {
          margin-right: 15px;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
        .server-info {
          .job-title {
            font-size: 13px;
            color: rgba(255, 255, 255, 0.5);
          }
          .server-name {
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
      .right-part {
        i {
          font-size: 20px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.2);
        }
      }
    }

    .complete-check-in {
      width: 100%;
      height: 50px;
      border-radius: 8px;
      background-color: #ed2626;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 30px;
      border: unset;
      outline: unset;
      cursor: pointer;
    }
    .nav-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 14px;
        font-weight: 600;
        color: #ed2626;
        cursor: pointer;
      }
    }
  }
`;
