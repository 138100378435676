import React from 'react';
import PropTypes from 'prop-types';
import { AccountInputContainer } from './style';
import { selectTextByClick } from 'services/functions';

export function AccountInput({
  label,
  value,
  placeholder,
  formatter = (initial) => initial,
  convertBack = (transformed) => transformed,
  onChange = () => {},
  isFilled,
  isTouched,
  specialTitle = '',
}) {
  return (
    <AccountInputContainer>
      <label>{label}</label>
      <div className='input-container'>
        <input
          type='text'
          className='input-view'
          value={formatter(value)}
          onChange={(event) => onChange(convertBack(event.target.value))}
          placeholder={placeholder}
          onClick={(event) => selectTextByClick(event.target)}
          maxLength={32}
        />
        {specialTitle && isFilled && isTouched ? (
          <span className='special-title'>{specialTitle}</span>
        ) : (
          ''
        )}
        <div
          className={`account-input-icon ${
            isTouched ? (isFilled ? 'is-ok' : 'error') : 'empty'
          }`}
        >
          {!isTouched || isFilled ? (
            <i className='fas fa-check-circle' />
          ) : (
            <i className='fas fa-times-circle' />
          )}
        </div>
      </div>
    </AccountInputContainer>
  );
}

AccountInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  specialTitle: PropTypes.string,
  formatter: PropTypes.func,
  convertBack: PropTypes.func,
  onChange: PropTypes.func,
  isFilled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isTouched: PropTypes.bool,
};
