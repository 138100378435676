import customAxios from 'services/customAxios';

const getCuisineList = async (onOk, onFail) => {
  const { ok, data, errMessage } = await customAxios.get(`/cuisine`);
  if (ok) {
    onOk(data);
  } else {
    onFail(errMessage);
  }
};

export const cuisineServices = {
  getCuisineList
};
