import React from 'react';
import arrayMove from 'array-move';
import { Orders } from 'feasttt/domain';
import Box from 'components/Box';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import messageBoxItem from 'components/MessageBoxItem';
import { dayTitles } from './static';

export const formatName = (name, slice = 21) => ((!name || name && name.length <= 27) ? name : `${name.slice(0, slice)}...`);

export const paginationRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <Box borderRadius='50%' width={40} height={40} backgroundColor='white' display='flex' justifyContent='center' alignItems='center'>
      <FaCaretLeft size={20} />
    </Box>;
  }
  if (type === 'next') {
    return <Box borderRadius='50%' width={40} height={40} backgroundColor='white' display='flex' justifyContent='center' alignItems='center'>
      <FaCaretRight size={20} />
    </Box>;
  }
  if (type === 'page') {
    return <SingleText color='white' opacity={0.3} size={18} weight='bold'>{current}</SingleText>
  }
  return originalElement;
}

export const showMessage = (content, type) => {
  messageBoxItem(content, type);
}

export const isNullOrUndefined = (value) => value === null || value === undefined;


/**
 * Transform object like `{day: 0, times: [[0, 86400]], timezone: "GMT-4" }` to `12:00am-11-00pm`
 * @param {{ day: number, times: number[][], timezone: string }[]} businessHours
 * @returns {string}
 */
export const formatBusinessHoursToday = (businessHours) => {
  let result = '';
  const timeRightNow = new Date();
  const availableDays = businessHours.map(({ day }) => day);

  if (availableDays.includes(timeRightNow.getDay() - 1)) {
    result += 'Today ';

    // compute schedule from seconds that we took (start, end)
    const index = availableDays.indexOf(timeRightNow.getDay() - 1);
    const {
      times: [timesCore],
    } = businessHours[index];
    let [start, end] = timesCore; // time in seconds

    // translate seconds into hours range 0 - 24
    start = Math.floor(start / 3600);
    end = Math.floor(end / 3600);

    // stringify hours that we have
    const timeBegin = start
      ? `${start > 23 ? 23 : start}:00${start >= 12 ? 'pm' : 'am'}`
      : '12:00am';

    const timeEnd = end
      ? `${end > 23 ? 23 : end}:00${end >= 12 ? 'pm' : 'am'}`
      : '12:00am';

    result += `${timeBegin}-${timeEnd}`;

    if (result.includes('12:00am-23:00pm')) {
      result = 'All day';
    }
  } else if (dayTitles[[...availableDays].pop()]) {
    result += `Next ${dayTitles[availableDays.pop()].content}`;
  }

  return result;
};

// in first render of `Menu` component it helps to provide nested data of foods by category
export const defaultListCategories = (
  chosenMenu,
  allCategories,
  categories
) => {
  if (!chosenMenu) {
    return allCategories
      .reduce(
        (accumulator, { id, name, categories, status }) => (status === 1 ? [
            ...accumulator,
            {
              id,
              name,
              items: [
                ...categories.reduce(
                  (accumulator, current) => [...accumulator, ...current.items],
                  []
                ),
              ],
            },
          ] : [...accumulator]),
        []
      )
      .filter(({ items }) => items.length);
  }

  return categories;
};

export const mapAndFilterFoodInMenu = (searchValue, foodListIds, foods) => {
  const fullFledgedFoods = foodListIds
    .map((id) => foods.find(({ id: foodId }) => id === foodId))
    .filter((food) => !!food);

  if (searchValue.trim()) {
    return fullFledgedFoods.filter(({ title }) =>
      title.toLowerCase().includes(searchValue.trim().toLowerCase())
    );
  }

  return fullFledgedFoods;
};

/**
 * CSS `translate` to 1-length string array with x and y
 * @param {string} transform : string like `translate(123px, 23px)`;
 * @returns {[string] | undefined}
 */
export const transformCssTranslateToCoordinates = (transform) => {
  if (transform.includes('translate')) {
    return [transform.replaceAll('translate', '').replaceAll('(', '').replaceAll(')', '')];
  }

  return undefined;
};

export function collisionDetection(currentElementsCoordinates, elementGuidelines) {
  // console.time("answer time");
  // Temp solution. Context will go there.
  let isCollisionDetected = false;
  elementGuidelines.forEach((_table) => {
    const coordinateUnformatted = transformCssTranslateToCoordinates(_table.style.transform);

    if (!coordinateUnformatted) {
      return;
    }

    const coordinates = coordinateUnformatted[0].replaceAll('px', '').split(', ');
    const otherTable = {
      coordinateX: parseInt(coordinates[0], 10),
      coordinateY: parseInt(coordinates[1], 10),
      width: parseInt(_table.style.width.replaceAll('px', ''), 10),
      height: parseInt(_table.style.height.replaceAll('px', ''), 10)
    }

    currentElementsCoordinates.forEach((_size) => {
      // Algorithm to check with rotation. Seems, that this algorithm working correctly, but still, need extra tests.
      // coordinateX should be + elementPosition.centerCoordinateX and and coordinateY + elementPosition.centerCoordinateY = this will display new coordinates after rotation.
      //   const tempX = _size[0] - parseInt(elementPosition.centerCoordinateX, 10);
      //   const tempY = _size[1] - parseInt(elementPosition.centerCoordinateY, 10);

      //   const coordinateX = tempX * Math.cos(elementPosition.degree) - tempY * Math.sin(elementPosition.degree);
      //   const coordinateY = tempX * Math.sin(elementPosition.degree) + tempY * Math.cos(elementPosition.degree);

      //   const rotatedX = coordinateX + elementPosition.centerCoordinateX
      //   const rotatedY = coordinateY + elementPosition.centerCoordinateY

      //   if ((otherTable.coordinateX <= rotatedX && rotatedX <= otherTable.coordinateX + otherTable.width)
      //   && (otherTable.coordinateY <= rotatedY && rotatedY <= otherTable.coordinateY + otherTable.height)) {
      //   // Collision Detected
      //   console.log('collision of rotated object!')
      // }

      if ((otherTable.coordinateX <= _size[0] + 20 && _size[0] - 20 <= otherTable.coordinateX + otherTable.width)
        && (otherTable.coordinateY <= _size[1] + 20 && _size[1] - 20 <= otherTable.coordinateY + otherTable.height)) {
        // Collision Detected
        isCollisionDetected = true;
      }
    })
  })
  return isCollisionDetected;
}

export const splitArrayIntoChunks = (array, chunkSize) => {
  let result = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }

  return result;
};
export const availableTables = (floor) => floor.tables.filter(({ is_available, status }) => is_available && status === 1);

export const howManyTablesAvailableForTheseFloors = (floors) => floors.reduce((totalAvailableTables, forCurrentFloor) => totalAvailableTables + availableTables(forCurrentFloor).length, 0);

export const sortFloorsOrder = (floors) => floors.sort((firstFloor, secondFloor) => {
  const firstOrder = firstFloor.floorOrder;
  const secondOrder = secondFloor.floorOrder;

  if (firstOrder < secondOrder) {
    return 1;
  } else if (firstOrder > secondOrder) {
    return -1;
  } else {
    return 0;
  }
});

export const moveFloors = (floors, oldIndex, newIndex) => {
  const copyFloors = arrayMove(floors, oldIndex, newIndex);
  return sortFloorsOrder(copyFloors.reverse().map((floor, index) => ({ ...floor, floorOrder: index + 1 })));
};

export const needToPay = (order) => {
  return order.items.reduce((totalPrice, { total, price, addons }) => totalPrice + (total * price) + addons.reduce((addonsTotalPrice, [, currentPrice]) => (currentPrice) + addonsTotalPrice, 0) * total, 0);
};

export const totalItems = (items) => items.reduce(
  (total, current) => total + current.total,
  0
);

export const computeLengthBetweenTwoPoints = (x1, y1, x2, y2) => {
  const lengthBetweenX = Math.pow(x2 - x1, 2);
  const lengthBetweenY = Math.pow(y2 - y1, 2);

  return Math.sqrt(lengthBetweenX + lengthBetweenY);
};
export const isLetterHaveNumber = (letter) => '1234567890'.includes(letter);

export const selectTextByClick = (target) => {
  if (target.value.length) {
    target.setSelectionRange(0, target.value.length);
  }
};

export const creditCardManufacturer = (firstNumber) => {
  switch (firstNumber) {
    case 3:
      return ['Amex', 'American Express'];
    case 4:
      return ['Visa', 'Visa'];
    case 5:
      return ['MasterCard', 'MasterCard'];
    case 6:
      return ['Disc', 'Discover'];
    default:
      return ['Unknown', 'Unknown'];
  }
};

// @TODO Back-end should give vale for restaurant rating (currently it is mocked here)
export const computeRatingForCompletedOrder = (order, server, restaurantRating = 4.5) => {
  const overallRatingForFoodItems = order.items.reduce((accumulator, current) => accumulator + (current.rating ? current.rating : 0), 0) / order.items.length;

  return (overallRatingForFoodItems + server.rating + restaurantRating) / 3;
};

/**
 * Convert number to time in seconds, minutes, hours
 * @param {number} time number from 0 86400
 */
export const readOrderWaitTime = (time) => {
  const hours = Math.trunc(time / 3600);
  if (hours < 1) {
    const minutes = Math.trunc((time % 3600) / 60);
    const seconds = Math.trunc(time % 60);

    return `${minutes}m ${seconds}s`;
  }

  return `${hours}hr+`;
};

export const generateOrdersStatistics = (orders) => {
  return {
    totalSales: orders.length ? Orders.calculation.calculateTotalSalesForCompletedOrders(orders) : 0,
    avgOrder: orders.length ? Orders.calculation.calculateAveragePriceOfCompletedOrders(orders) : 0,
    orders: orders.length,
    guests: orders.reduce((accumulator, current) => accumulator + current.table.guests, 0),
    items: orders.reduce((accumulator, current) => accumulator + current.items.length, 0),
    avgWaitTime: orders.length ? readOrderWaitTime(orders.reduce((accumulator, current) => accumulator + current.time, 0) / orders.length) : 0,
    avgRating: orders.length ? (orders.reduce((accumulator, current) => accumulator + current.overallRating, 0) / orders.length).toFixed(1) : 0,
  };
};

