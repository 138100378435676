import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CustomPeriodFilterContainer } from './style';
import { showMessage } from 'services/functions';
import { months } from 'services/static';
import Box from 'components/Box';
import SingleButton from 'components/SingleButton';
import { PeriodCalendar } from './PeriodCalendar';

export function CustomPeriodFilter({
  className = '',
  onApply = () => {},
  onClearGlobalRange = () => {},
  isRangeActive = () => {},
  isPartOfRange = () => {},
}) {
  const [range, setRange] = useState([undefined, undefined]);

  const onStartRangeClick = (date) => setRange([date.getTime(), undefined]);
  const onEndRangeClick = (date) =>
    setRange([[...range].shift(), date.getTime()]);

  const onClearRange = () => setRange([undefined, undefined]);

  const onRangeClick = (date) => {
    const [startRange] = range;
    const rangeActive = isRangeActive(range);

    if (!rangeActive) {
      const newEndTime = date.getTime();

      if (startRange) {
        if (newEndTime > startRange) {
          onEndRangeClick(date);
        } else {
          const startOfRange = new Date(startRange);
          const startOfRangeFormatted = `${months[
            startOfRange.getMonth()
          ].slice(0, 3)} ${startOfRange.getDate()}`;

          showMessage(
            `You selected ${startOfRangeFormatted} as start of custom range.For selecting end of range you need select date after ${startOfRangeFormatted}`,
            'warning'
          );
        }
      } else {
        onStartRangeClick(date);
      }
    } else {
      onClearGlobalRange();
      onClearRange();
    }
  };

  const isInRange = (date) => {
    const placeInRange = date.getTime();

    const inRange = isPartOfRange(placeInRange, range);

    return [inRange, setClassName(date, placeInRange, range)];
  };

  return (
    <CustomPeriodFilterContainer className={className}>
      <Box className='calendars-row'>
        <div className='calendar'>
          <PeriodCalendar
            onRangeClick={onRangeClick}
            isInRange={isInRange}
            onClearRange={onClearGlobalRange}
            isRangeActive={isRangeActive(range)}
          />
        </div>
        <div className='calendar'>
          <PeriodCalendar
            onRangeClick={onRangeClick}
            isInRange={isInRange}
            isRangeActive={
              isRangeActive(range)
                ? new Date(range[0]).getMonth() !==
                  new Date(range[1]).getMonth()
                : false
            }
          />
        </div>
      </Box>
      <Box className='apply-container'>
        <SingleButton
          className='btn-apply'
          title='Apply'
          shape='rounded'
          width='170px'
          onClick={() => onApply(range)}
        />
      </Box>
    </CustomPeriodFilterContainer>
  );
}

CustomPeriodFilter.propTypes = {
  className: PropTypes.string,
  onApply: PropTypes.func,
  isRangeActive: PropTypes.func,
  isPartOfRange: PropTypes.func,
  onClearGlobalRange: PropTypes.func,
};

export const setClassName = (date, placeInRange, range) => {
  const [startRange, endRange] = range;
  let startsEndsRangeClassName = '';

  if (date.getDay() === 1 || date.getDay() === 0) {
    startsEndsRangeClassName =
      date.getDay() === 1 ? 'start-range' : 'end-range';
  }

  if (placeInRange === startRange || placeInRange === endRange) {
    startsEndsRangeClassName +=
      placeInRange === startRange ? ' start-range' : ' end-range';
  }

  if (isFirstDayOfMonth(date) || isLastDayOfMonth(date)) {
    startsEndsRangeClassName += isFirstDayOfMonth(date)
      ? ' start-range'
      : ' end-range';
  }

  return startsEndsRangeClassName;
};

export const isFirstDayOfMonth = (date) => date.getDate() === 1;

export const isLastDayOfMonth = (date) => {
  const nextDate = new Date(
    date.getYear(),
    date.getMonth(),
    date.getDate() + 1
  );
  return nextDate.getMonth() !== date.getMonth();
};
