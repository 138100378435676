import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TopFoodImageContainer } from './style';
import { DefaultFood } from 'assets/images/menu';
import { DataContext } from 'contexts/DataContextContainer';

export function TopFoodImage({ onBack = () => {} }) {
  const { selectedFood } = useContext(DataContext);
  
  return (
    <TopFoodImageContainer
      backgroundImage={selectedFood.photo?.file ?? DefaultFood}
    >
      <div className='back-icon'>
        <i className='fas fa-chevron-left' onClick={onBack} />
        <p>Reviews</p>
      </div>
      <div className='top-food-content'>
        <div className='top-food-image' />
        <span className='top-food-title'>{selectedFood.title}</span>
      </div>
    </TopFoodImageContainer>
  );
}

TopFoodImage.propTypes = {
  onBack: PropTypes.func,
};
