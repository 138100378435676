import React from 'react';
import PropTypes from 'prop-types';
import { SingleTextContainer } from './style';

export default function SingleText({
  className = '',
  children,
  width = 'fit-content',
  size = 14,
  color = 'white',
  family = 'Montserrat',
  weight = 'normal',
  opacity = 1,
  stretch = 'normal',
  fontStyle = 'normal',
  spacing = 'normal',
  lineHeight = 'normal',
  marginBottom = 0,
  marginTop = 0,
  marginLeft = 0,
  marginRight = 0,
  cursor = false,
  align = 'left',
}) {
  return (
    <SingleTextContainer
      className={className}
      style={{
        width: width,
        fontSize: size,
        color: color,
        fontFamily: family,
        fontWeight: weight,
        opacity: opacity,
        fontStretch: stretch,
        fontStyle: fontStyle,
        letterSpacing: spacing,
        lineHeight: lineHeight,
        marginBottom: marginBottom,
        marginTop: marginTop,
        marginLeft: marginLeft,
        marginRight: marginRight,
        textAlign: align,
        cursor: cursor ? 'pointer' : 'inherit',
        // wordBreak: 'break-all',
      }}
    >
      {children}
    </SingleTextContainer>
  );
}

SingleText.propTypes = {
  className: PropTypes.string,
  width: PropTypes.any,
  size: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.string,
  family: PropTypes.string,
  weight: PropTypes.any,
  stretch: PropTypes.string,
  fontStyle: PropTypes.string,
  spacing: PropTypes.string,
  lineHeight: PropTypes.any,
  align: PropTypes.string,
  marginBottom: PropTypes.any,
  marginLeft: PropTypes.any,
  marginRight: PropTypes.any,
  marginTop: PropTypes.any,
  cursor: PropTypes.bool,
  children: PropTypes.any,
};
