import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerFloorsContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  padding: 0px 0px;

  .floors-exit {
    z-index: 99;
  }

  .drawer-floors-top {
    display: flex;
    justify-content: center;
    margin-left: -30px;
  }
`;
