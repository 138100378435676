import React from 'react';
import PropTypes from 'prop-types';
import { useOrders } from 'hooks';

export const ActiveOrdersContext = React.createContext(null);

export function ActiveOrdersContextContainer({ children }) {
  const {
    orders: activeOrders,
    setOrders: setActiveOrders,
    isEmptyOrdersPool,
    onAddFoodItem,
    updateFoodItem,
    changeFoodItemStatus,
    removeFoodItem,
    changeActiveOrderStatus,
    activeOrder,
    setupActiveOrder,
    clearActiveOrder,
    isActiveOrder,
    isActiveOrderEmpty,
    moveActiveOrderToCompletedSection,
    deleteActiveOrder,
    applyDiscountForActiveOrder,
    removeDiscountForActiveOrder,
  } = useOrders();

  return (
    <ActiveOrdersContext.Provider
      value={{
        activeOrders,
        setActiveOrders,
        isEmptyOrdersPool,
        onAddFoodItem,
        updateFoodItem,
        changeFoodItemStatus,
        removeFoodItem,
        changeActiveOrderStatus,
        activeOrder,
        setupActiveOrder,
        clearActiveOrder,
        isActiveOrder,
        isActiveOrderEmpty,
        moveActiveOrderToCompletedSection,
        deleteActiveOrder,
        applyDiscountForActiveOrder,
        removeDiscountForActiveOrder,
      }}
    >
      {children}
    </ActiveOrdersContext.Provider>
  );
}

ActiveOrdersContextContainer.propTypes = {
  children: PropTypes.any,
};
