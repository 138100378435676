import React, { useState } from 'react';
import { OrdersContainer } from './style';
import { PendingOrdersContextContainer } from 'contexts/PendingOrdersContextContainer';
import { ActiveOrdersContextContainer } from 'contexts/ActiveOrdersContextContainer';
import { CompletedOrdersContextContainer } from 'contexts/CompletedOrdersContextContainer';
import { useSubPageSwitch } from 'hooks';
import SingleButton from 'components/SingleButton';
import { Pending } from './Pending';
import { AddItem } from './AddItem';
import { Active } from './Active';
import { CompletedComponent } from './CompletedComponent';
import { MoreSelect } from './MoreSelect';
import { OrdersTopControl } from './OrdersTopControl';
import { PeriodSwitcher } from './PeriodSwitcher';

function Orders() {
  const {
    selectedPage,

    openPendingPage,
    openActivePage,
    openCompletedPage,

    isPendingPageSelected,
    isAddItemPageSelected,
    isActivePageSelected,
    isCompletedPageSelected,

    isLastOpenedPagePendingPage,
    isLastOpenedPageActivePage,
    goToAddItemPageAndWriteLastOpenedPage,
    goOutFromAddItemPage,
  } = useSubPageSwitch();

  const [userOption, setUserOption] = useState(undefined);

  const [
    openDrawerChangeToPendingContainer,
    setOpenDrawerChangeToPendingContainer,
  ] = useState(false);

  const [
    openDrawerApplyDiscountContainer,
    setOpenDrawerApplyDiscountContainer,
  ] = useState(false);
  const onOpenDrawerApplyDiscountContainer = () =>
    setOpenDrawerApplyDiscountContainer(true);

  const onCloseDrawerApplyDiscountContainer = () =>
    setOpenDrawerApplyDiscountContainer(false);

  const onOpenDrawerChangeToPendingContainer = () =>
    setOpenDrawerChangeToPendingContainer(true);

  const [openDrawerProcessPayment, setDrawerProcessPayment] = useState(false);
  const onOpenDrawerProcessPayment = () => setDrawerProcessPayment(true);
  const onCloseDrawerProcessPayment = () => setDrawerProcessPayment(false);

  const [openDrawerDeleteOrderContainer, setOpenDrawerDeleteOrderContainer] =
    useState(false);

  const onOpenDrawerDeleteOrderContainer = () =>
    setOpenDrawerDeleteOrderContainer(true);

  const [
    openDrawerPartialRefundContainer,
    setOpenDrawerPartialRefundContainer,
  ] = useState(false);
  const onOpenDrawerPartialRefundContainer = () =>
    setOpenDrawerPartialRefundContainer(true);
  const onCloseDrawerPartialRefundContainer = () =>
    setOpenDrawerPartialRefundContainer(false);

  const [openDrawerFullRefundContainer, setDrawerFullRefundContainer] =
    useState(false);

  const onOpenDrawerFullRefundContainer = () =>
    setDrawerFullRefundContainer(true);
  const onCloseDrawerFullRefundContainer = () =>
    setDrawerFullRefundContainer(false);

  const changeUserOption = (newOptionValue) => setUserOption(newOptionValue);

  return (
    <CompletedOrdersContextContainer>
      <ActiveOrdersContextContainer>
        <PendingOrdersContextContainer>
          <OrdersContainer>
            {/* Header Part */}
            <div className='order-header-part'>
              <div className='orders-header'>
                <div
                  className={`header-title ${
                    isActivePageSelected() ? 'add-item-header-title' : ''
                  }`}
                >
                  {isAddItemPageSelected() ? 'Add Item' : 'Orders'}
                </div>
                {!isAddItemPageSelected() ? (
                  <MoreSelect
                    selectedPage={selectedPage}
                    userOption={userOption}
                    changeUserOption={changeUserOption}
                    onOpenDrawerChangeToPendingContainer={
                      onOpenDrawerChangeToPendingContainer
                    }
                    onOpenDrawerDeleteOrderContainer={
                      onOpenDrawerDeleteOrderContainer
                    }
                    onOpenDrawerApplyDiscountContainer={
                      onOpenDrawerApplyDiscountContainer
                    }
                    onOpenDrawerProcessPayment={onOpenDrawerProcessPayment}
                    onOpenDrawerFullRefundContainer={
                      onOpenDrawerFullRefundContainer
                    }
                    onOpenDrawerPartialRefundContainer={
                      onOpenDrawerPartialRefundContainer
                    }
                    openAddItemPageAndWritePageThatWasBefore={
                      goToAddItemPageAndWriteLastOpenedPage
                    }
                    isPendingPageSelected={isPendingPageSelected}
                    isActivePageSelected={isActivePageSelected}
                    isCompletedPageSelected={isCompletedPageSelected}
                  />
                ) : (
                  <SingleButton
                    className='btn-add-item-cancel'
                    title='Cancel'
                    onClick={goOutFromAddItemPage}
                    width='170px'
                    height='initial'
                    padding='9px 0px'
                  />
                )}
              </div>
              <div className='orders-control-container'>
                {!isAddItemPageSelected() ? (
                  <OrdersTopControl
                    openPendingPage={openPendingPage}
                    openActivePage={openActivePage}
                    openCompletedPage={openCompletedPage}
                    isPendingPageSelected={isPendingPageSelected}
                    isActivePageSelected={isActivePageSelected}
                    isCompletedPageSelected={isCompletedPageSelected}
                  />
                ) : (
                  ''
                )}
                {isCompletedPageSelected() ? <PeriodSwitcher /> : ''}
              </div>
            </div>
            {/* Body Part */}
            {isPendingPageSelected() && (
              <Pending
                openDrawerChangeToPendingContainer={
                  openDrawerChangeToPendingContainer
                }
                setOpenDrawerChangeToPendingContainer={
                  setOpenDrawerChangeToPendingContainer
                }
                openDrawerDeleteOrderContainer={openDrawerDeleteOrderContainer}
                setOpenDrawerDeleteOrderContainer={
                  setOpenDrawerDeleteOrderContainer
                }
              />
            )}
            {isAddItemPageSelected() && (
              <AddItem
                isLastOpenedPagePendingPage={isLastOpenedPagePendingPage}
                isLastOpenedPageActivePage={isLastOpenedPageActivePage}
              />
            )}
            {isActivePageSelected() && (
              <Active
                openDrawerDeleteOrderContainer={openDrawerDeleteOrderContainer}
                setOpenDrawerDeleteOrderContainer={
                  setOpenDrawerDeleteOrderContainer
                }
                openDrawerApplyDiscountContainer={
                  openDrawerApplyDiscountContainer
                }
                onCloseDrawerApplyDiscountContainer={
                  onCloseDrawerApplyDiscountContainer
                }
                openDrawerProcessPayment={openDrawerProcessPayment}
                onCloseDrawerProcessPayment={onCloseDrawerProcessPayment}
              />
            )}
            {isCompletedPageSelected() && (
              <CompletedComponent
                openDrawerFullRefundContainer={openDrawerFullRefundContainer}
                onCloseDrawerFullRefundContainer={
                  onCloseDrawerFullRefundContainer
                }
                openDrawerPartialRefundContainer={
                  openDrawerPartialRefundContainer
                }
                onCloseDrawerPartialRefundContainer={
                  onCloseDrawerPartialRefundContainer
                }
              />
            )}
          </OrdersContainer>
        </PendingOrdersContextContainer>
      </ActiveOrdersContextContainer>
    </CompletedOrdersContextContainer>
  );
}

export default Orders;
