import styled from '@emotion/styled';
import { colors } from 'theme';

export const MenuHeaderContainer = styled.div`
  width: 100%;
  min-height: 88px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;

  .btns-right-container {
    display: flex;
    align-items: center;
    div {
      margin-left: 16px;
    }
    div:first-of-type {
      margin-left: 0;
    }

    .search-container {
      border-color: transparent;
      background-color: ${colors.gray5};
    }

    .btn-menu-header-options {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .dropdown-header-menu {
      .option-label-item {
        font-size: 14px;
        color: white;
      }

      i {
        font-size: 14px;
        font-weight: bold;
        color: white;
      }
    }

    .menu-header-select {
      .dropdown-header-menu {
        background-color: ${colors.gray5};
        border: 1px solid transparent;
      }
    }
  }
`;
