import styled from '@emotion/styled';
import { colors } from 'theme';

export const PageLayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh);
  background: ${colors.black1};

  .site-layout {
    background: transparent;
    width: 100%;
  }

  .scrollbars {
    & > div {
      overflow-x: hidden !important;
    }
  }

  main {
    height: 100%;
    padding: 0 20px 25px;
  }
`;
