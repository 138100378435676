import React from 'react';
import PropTypes from 'prop-types';
import { PriceCheckContainer } from './style';

export function PriceCheck({
  description = 'Split Bill - 3 Ways',
  subTotal = 55.96,
  tax = 2.3,
  total = 65.0,
  refund = undefined,
  discount = undefined,
  tip = undefined,
  fee = 2.0,
}) {
  return (
    <PriceCheckContainer>
      {formatMoneyValues({
        subTotal,
        tax,
        total,
        refund,
        discount,
        tip,
        fee,
        description,
      }).map(([title, value, cssClassName]) => (
        <div className={`price-check-item ${cssClassName}`} key={title + value}>
          <div className='price-title-container'>
            <span className='price-title'>{title}</span>
          </div>
          <div className='price-value-container'>
            <span className='price-value'>{value}</span>
          </div>
        </div>
      ))}
    </PriceCheckContainer>
  );
}

PriceCheck.propTypes = {
  description: PropTypes.string,
  subTotal: PropTypes.number,
  tax: PropTypes.number,
  tip: PropTypes.any,
  total: PropTypes.number,
  refund: PropTypes.any,
  discount: PropTypes.any,
  fee: PropTypes.number,
};

export const formatMoneyValues = ({
  subTotal,
  tax,
  total,
  refund,
  discount,
  tip,
  fee,
  description,
}) => {
  const formattedDescription = [
    <>
      <i className='fas fa-users' />
    </>,
    description,
    'description',
  ];

  const formattedSubTotal = ['Subtotal', `$${subTotal.toFixed(2)}`];

  const formattedTax = ['Tax', `$${tax.toFixed(2)}`];

  const formattedTotal = [
    <b key='total'>Total</b>,
    <b key='total-price'>{`$${total.toFixed(2)}`}</b>,
    'total',
  ];

  const formattedRestaurantFee = [
    'Restaurant Fee',
    `$${fee.toFixed(2)}`,
    'fee',
  ];

  return [
    formattedDescription,
    formattedSubTotal,
    formattedTax,

    tip ? ['Tip', `$${tip.money.toFixed(2)}`] : [],

    discount
      ? [
          discount.type === 'percentage'
            ? `Discount (${discount.value}%)`
            : `Discount ($${discount.value})`,
          `-$${discount.money}`,
        ]
      : [],

    formattedTotal,

    refund
      ? [
          refund.percentages >= 100 ? (
            <b key='full'>Full Refund</b>
          ) : (
            <>
              <b>Partial Refund</b> {`(${Math.trunc(refund.percentages)}%)`}
            </>
          ),
          `-$${refund.money.toFixed(2)}`,
          refund.percentages >= 100 ? 'full' : 'partial',
        ]
      : [],

    formattedRestaurantFee,
  ].filter((array) => !!array.length);
};
