import React, { useContext, useEffect /*useState*/ } from 'react';
import PropTypes from 'prop-types';
import { useAllCategories } from 'hooks';
import { DataContext } from 'contexts/DataContextContainer';

export function CategoriesFetch({ allCategories }) {
  const { menus } = useContext(DataContext);
  const getAllCategories = useAllCategories();

  useEffect(() => {
    if (menus.length !== 0 && !allCategories.length) {
      getAllCategories();
    }
  }, [menus, allCategories, getAllCategories]);

  return <div />;
}

CategoriesFetch.propTypes = {
  allCategories: PropTypes.array,
};
