import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { DrawerRemoveDiscountContainerStyled } from './style';
import { ActiveOrdersContext } from 'contexts/ActiveOrdersContextContainer';
import { DrawerRemoveDiscount } from './DrawerRemoveDiscount';
import { DrawerDiscountRemoved } from './DrawerDiscountRemoved';

export function DrawerRemoveDiscountContainer({ visible, onClose }) {
  const { removeDiscountForActiveOrder } = useContext(ActiveOrdersContext);

  const [openDrawerRemoveDiscount, setOpenDrawerRemoveDiscount] =
    useState(false);
  const onOpenDrawerRemoveDiscount = () => setOpenDrawerRemoveDiscount(true);
  const onCloseDrawerRemoveDiscount = () => setOpenDrawerRemoveDiscount(false);

  const [openDrawerDiscountRemoved, setOpenDrawerDiscountRemoved] =
    useState(false);
  const onOpenDrawerDiscountRemoved = () => setOpenDrawerDiscountRemoved(true);
  const onCloseDrawerDiscountRemoved = () =>
    setOpenDrawerDiscountRemoved(false);

  const onRemove = () => {
    removeDiscountForActiveOrder();
    onCloseDrawerRemoveDiscount();
    onOpenDrawerDiscountRemoved();
  };

  const close = () => {
    onCloseDrawerDiscountRemoved();
    onCloseDrawerRemoveDiscount();
    onClose();
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerRemoveDiscount();
    }
  }, [visible]);

  return (
    <DrawerRemoveDiscountContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerRemoveDiscount
        visible={openDrawerRemoveDiscount}
        onClose={close}
        onRemove={onRemove}
      />
      <DrawerDiscountRemoved
        visible={openDrawerDiscountRemoved}
        onClose={close}
      />
    </DrawerRemoveDiscountContainerStyled>
  );
}

DrawerRemoveDiscountContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
