import styled from '@emotion/styled';
import { colors } from 'theme';

export const WaiterDescriptionContainer = styled.div`
  position: relative;
  bottom: -69px;
  border-radius: 40px 40px 0 0;
  height: 110px;
  background-color: ${colors.gray5};

  &:before {
    content: '';
    display: block;
    width: 102px;
    height: 70px;
    background-color: transparent;
    position: absolute;
    top: -59.6px;
    left: 85px;
    border-right: 7px solid transparent;
    border-bottom: 10px solid ${colors.gray5};
    border-bottom-right-radius: 114px;
  }

  &:after {
    z-index: 97;
    content: '';
    display: block;
    width: 102px;
    height: 70px;
    background-color: transparent;
    position: absolute;
    top: -59.6px;
    right: 85px;
    border-left: 7px solid transparent;
    border-bottom: 10px solid ${colors.gray5};
    border-bottom-left-radius: 114px;
  }

  .img-container {
    z-index: 98;
    width: 82px;
    height: 80px;
    border-radius: 50%;
    background-color: #27282a;
    border: solid 6px #27282a;
    position: absolute;
    top: -35px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
  }

  .content {
    padding-top: 57px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
  }
`;
