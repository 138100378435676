import styled from '@emotion/styled';
import { colors } from 'theme';

export const PhoneVerifyContainer = styled.div`
  .logo-img {
    margin-top: 125px;
    margin-bottom: 80px;
  }

  .phone-icon {
    width: 40px;
    margin-bottom: 8px;
  }

  .verify-mobile-number-title {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    color: white;
    margin-bottom: 10px;
  }

  .we-sent-notify-title {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    text-align: left;
    width: 270px;
    line-height: 22px;
    letter-spacing: -0.34px;
    margin-bottom: 25px;
  }

  .code-wrapper {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .type-the-code-label {
      font-size: 16px;
      color: white;
      letter-spacing: 0;
      margin-bottom: 15px;
    }

    .verify-code-input {
      width: 100% !important;
      > div {
        display: flex;
        justify-content: space-between;
        input {
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 7px;
          background-color: ${colors.gray2};
          -webkit-transition: background-color 300ms linear;
          -ms-transition: background-color 300ms linear;
          transition: background-color 300ms linear;
          font-family: 'Roboto';
          font-weight: bold;
          font-size: 36px;
          letter-spacing: 0.68px;
          color: white;
          caret-color: white;
        }
      }
      &.invalid {
        > div {
          input {
            border: 2px solid ${colors.redColor};
          }
        }
      }
      &.valid {
        > div {
          input {
            background-color: ${colors.lightGreen};
            -webkit-transition: background-color
              ${({ animationDuration }) => animationDuration}ms linear;
            -ms-transition: background-color
              ${({ animationDuration }) => animationDuration}ms linear;
            transition: background-color
              ${({ animationDuration }) => animationDuration}ms linear;
          }
        }
      }
    }

    .did-not-get-it-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 41px;

      .did-not-get-it-title {
        font-size: 16px;
        font-weight: 500;
        color: white;
        letter-spacing: 0;
        margin-right: 7px;
      }

      .btn-resend {
        cursor: pointer;
        .resend-title {
          font-size: 16px;
          font-weight: bold;
         
          &.active {
            color: ${colors.redColor};
          }

          &.inactive {
            color: ${colors.lightGray};
          }
        }
      }
    }
  }
`;
