import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerWallContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  padding: 0px 0px;
`;
export const WallShapeContainer = styled.div`
  padding: 36px 26px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .wall-shape {
    width: 155px;
    height: 98px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    cursor: pointer;
    &.active {
      border: solid 1px #ffffff;
      transition: 0.5s ease;
    }
    &.inactive {
      border: solid 1px rgba(255, 255, 255, 0.1);
      transition: 0.5s ease;
    }
  }
`;

export const WallSpace = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
