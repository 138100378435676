import { useState } from 'react';
import { isLetterHaveNumber } from 'services/functions';

export const useBankAccount = ({
  onAfterBankAccountUpdate = () => {},
} = {}) => {
  const [bankAccount, setBackAccount] = useState({});

  const changeBankAccount = (updatedFieldOfBankAccount) => {
    const updatedBankAccount = { ...bankAccount, ...updatedFieldOfBankAccount };
    setBackAccount(updatedBankAccount);
    onAfterBankAccountUpdate(updatedBankAccount);
  };

  const clearBankAccount = () => setBackAccount({});

  const isBankAccountEmpty = () => Object.keys(bankAccount).length === 0;

  const formatNumberThreePeriods = (value) => {
    let formatted = '';

    for (let i = 0; i < value.length; i += 3) {
      formatted += value.slice(i, i + 3) + ' ';
    }

    return formatted.trim();
  };

  const convertBackNumberValue = (transformedValue, length) => {
    return transformedValue
      .split('')
      .map((letter) => (isLetterHaveNumber(letter) ? letter : ''))
      .join('')
      .slice(0, length);
  };

  const formatAccountNumber = (value) =>
    `${value.slice(0, 4)} ${formatNumberThreePeriods(
      value.slice(4, 10)
    )}`.trim();

  const isNicknameValid = () =>
    bankAccount.nickname && bankAccount.nickname.length >= 3;

  const isRoutingNumberValid = () =>
    bankAccount.routingNumber && bankAccount.routingNumber.length === 9;

  const isAccountNumberValid = () =>
    bankAccount.accountNumber && bankAccount.accountNumber.length === 10;

  const isConfirmAccountNumberValid = () =>
    bankAccount.accountNumber &&
    bankAccount.confirmAccountNumber &&
    bankAccount.accountNumber === bankAccount.confirmAccountNumber;

  const isReady = () =>
    isNicknameValid() &&
    isRoutingNumberValid() &&
    isAccountNumberValid() &&
    isConfirmAccountNumberValid();

  const generateErrorMessage = () => {
    if (!isNicknameValid()) {
      return 'Account Nickname is not valid';
    } else if (!isRoutingNumberValid()) {
      return 'Routing Number is not valid.';
    } else if (!isAccountNumberValid()) {
      return 'Account Number is not valid.';
    } else if (!isConfirmAccountNumberValid()) {
      return 'Account numbers do not match.';
    }
  };

  const bankAccountFields = [
    [
      'Account Nickname',
      'Enter Account Nickname',
      'nickname',
      undefined,
      undefined,
      isNicknameValid,
    ],
    [
      'Routing Number',
      'Enter Routing Number',
      'routingNumber',
      formatNumberThreePeriods,
      (value) => convertBackNumberValue(value, 9),
      isRoutingNumberValid,
      'Bank of America',
    ],
    [
      'Account Number',
      'Enter Account Number',
      'accountNumber',
      formatAccountNumber,
      (value) => convertBackNumberValue(value, 10),
      () =>
        isAccountNumberValid()
          ? isAccountNumberValid() && isConfirmAccountNumberValid()
          : isAccountNumberValid(),
    ],
    [
      'Confirm Account Number',
      'Confirm Account Number',
      'confirmAccountNumber',
      formatAccountNumber,
      (value) => convertBackNumberValue(value, 10),
      isConfirmAccountNumberValid,
    ],
  ];

  return {
    bankAccount,
    setBackAccount,
    changeBankAccount,
    clearBankAccount,
    bankAccountFields,
    isBankAccountEmpty,

    formatRoutingNumber: formatNumberThreePeriods,
    convertBackRoutingNumberBack: (value) => convertBackNumberValue(value, 9),

    formatAccountNumber,
    convertBackAccountNumber: (value) => convertBackNumberValue(value, 10),

    isRoutingNumberValid,
    isAccountNumberValid,
    isConfirmAccountNumberValid,

    isReady,
    generateErrorMessage,
  };
};
