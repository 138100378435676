import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { SingleSelectDropdownContainer } from './style';

export default function SingleAntSelect({
  className = '',
  placeholder = '',
  options = [],
  width = '100%',
  dropdownRender,
  backgroundColor = 'transparent',
  iconOpen = <i className='fas fa-angle-up' />,
  iconClosed = <i className='fas fa-angle-right' />,
  onChange = () => {},
  value,
}) {
  const [selectedValue, setSelectedValue] = useState(value);
  const [isOpen, setIsOpen] = useState(false);

  function changeMenuValue(_value) {
    setSelectedValue(_value);
    onChange(_value.value);
  }

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  // eslint-disable-next-line react/display-name
  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <div className='list-unstyled'>
            {React.Children.toArray(children).filter(
              (child) => child.props.children
            )}
          </div>
        </div>
      );
    }
  );

  // eslint-disable-next-line react/display-name
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      className='dropdown-header-menu'
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      {isOpen === true && iconOpen}
      {isOpen === false && iconClosed}
    </div>
  ));

  return (
    <SingleSelectDropdownContainer
      className={className}
      style={{ width }}
      onToggle={(_isOpen) => setIsOpen(_isOpen)}
    >
      <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
        {
          <div className='option-label-item'>
            {selectedValue ? selectedValue.label : placeholder}
          </div>
        }
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} className='dropdown-content-menu'>
        {options.map((_option) => (
          <Dropdown.Item
            key={`optionKeyVal${_option.value}`}
            eventKey={`optionKeyVal${_option.value}`}
            value={_option.value}
            label={_option.label}
            onClick={() => changeMenuValue(_option)}
          >
            <div className='option-label-item'>{_option.label}</div>
          </Dropdown.Item>
        ))}
        {dropdownRender && (
          <div
            key={`optionKeyDropdownRendered`}
            eventKey={`optionKeyDropdownRendered`}
            className='rendered-dropdown dropdown-item'
          >
            {dropdownRender}
          </div>
        )}
      </Dropdown.Menu>
    </SingleSelectDropdownContainer>
  );
}

SingleAntSelect.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  backgroundColor: PropTypes.string,
  options: PropTypes.array,
  dropdownRender: PropTypes.object,
  iconOpen: PropTypes.object,
  iconClosed: PropTypes.object,
  onChange: PropTypes.func,
  width: PropTypes.any,
  value: PropTypes.object,
};
