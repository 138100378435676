import { useState } from 'react';

export const useOrders = () => {
  const [orders, setOrders] = useState([]);
  const [activeOrder, setActiveOrder] = useState({});

  const setupActiveOrder = (order) => setActiveOrder(order);
  const clearActiveOrder = () => setActiveOrder({});

  const isActiveOrderEmpty = () => !Object.keys(activeOrder || {}).length;

  const isActiveOrder = (order) => order.id === activeOrder.id;

  const isEmptyOrdersPool = () => orders.length === 0;

  const onAddToOrders = (order) => setOrders([...orders, order]);

  const updateActiveOrder = (updatedActiveOrder) => {
    setActiveOrder(updatedActiveOrder);
    setOrders(
      orders.map((order) =>
        order.id === updatedActiveOrder.id ? updatedActiveOrder : order
      )
    );
  };

  const onAddFoodItem = ({ id, price, title: name, description, total }) => {
    const updatedActiveOrder = {
      ...activeOrder,
      items: [
        ...activeOrder.items,
        {
          id,
          status: 0,
          price,
          total,
          name,
          description,
          specialInstructions: [],
          addons: [],
          dressing: [],
          size: [],
        },
      ],
    };

    updateActiveOrder(updatedActiveOrder);
  };

  const updateFoodItem = (updatedItem) => {
    const updatedActiveOrder = {
      ...activeOrder,
      items: activeOrder.items.map((item) =>
        item.id === updatedItem.id ? updatedItem : item
      ),
    };

    updateActiveOrder(updatedActiveOrder);
  };

  const changeFoodItemStatus = (foodItemId) => {
    const updatedActiveOrder = {
      ...activeOrder,
      items: activeOrder.items.map((item) =>
        item.id === foodItemId
          ? {
              ...item,
              status: item.status ? 0 : 1,
            }
          : item
      ),
    };

    updateActiveOrder(updatedActiveOrder);
  };

  const removeFoodItem = (foodItemId) => {
    const updatedActiveOrder = {
      ...activeOrder,
      items: activeOrder.items.filter((item) => item.id !== foodItemId),
    };

    updateActiveOrder(updatedActiveOrder);
  };

  const changeActiveOrderStatus = () => {
    const updatedActiveOrder = {
      ...activeOrder,
      status: activeOrder.status ? 0 : 1,
    };

    updateActiveOrder(updatedActiveOrder);
  };

  const moveActiveOrderToCompletedSection = (
    order,
    onAddToCompletedOrders = () => {}
  ) => {
    const copyOrders = [...orders].filter(
      (orderItem) => orderItem.id !== order.id
    );
    setOrders(copyOrders);
    clearActiveOrder();
    onAddToCompletedOrders(order);
  };

  const deleteActiveOrder = () => {
    const updatedOrders = [...orders].filter(
      (orderItem) => orderItem.id !== activeOrder.id
    );
    setOrders(updatedOrders);
    clearActiveOrder();
  };

  const applyDiscountForActiveOrder = (discount) => {
    const updatedActiveOrder = {
      ...activeOrder,
      discount,
    };

    updateActiveOrder(updatedActiveOrder);
  };

  const removeDiscountForActiveOrder = () => {
    const updatedActiveOrder = {
      ...activeOrder,
      discount: {},
    };

    updateActiveOrder(updatedActiveOrder);
  };

  // full refund by default
  const refundForActiveOrder = ({ money, value = 100, type, percentages }) => {
    const updatedActiveOrder = {
      ...activeOrder,
      refund: {
        money,
        value,
        type,
        percentages,
      },
    };

    updateActiveOrder(updatedActiveOrder);
  };

  return {
    orders,
    setOrders,
    onAddToOrders,
    isEmptyOrdersPool,
    onAddFoodItem,
    updateFoodItem,
    changeFoodItemStatus,
    removeFoodItem,
    changeActiveOrderStatus,
    activeOrder,
    setupActiveOrder,
    clearActiveOrder,
    isActiveOrder,
    isActiveOrderEmpty,
    moveActiveOrderToCompletedSection,
    deleteActiveOrder,
    applyDiscountForActiveOrder,
    removeDiscountForActiveOrder,
    refundForActiveOrder,
  };
};
