import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'components/Box';
import TableLayout from 'pages/Tables/TableLayout';
import { StateContext } from 'contexts/StateContextContainer';
import { IconCheckIn } from 'assets/images/icons';
import { colors } from 'theme';
import { ButtonContainer } from '../style';
import SingleButton from 'components/SingleButton';

export function DashboardTables({ tables }) {

    const [isEditState, setEditState] = useState(false);
    const { setOpenDrawerCheckIn } = useContext(StateContext);

    const startEditTables = () => setEditState(true);
    const stopEditTables = () => setEditState(false);

    const onCancelTablesChanges = () => {
        stopEditTables();
    };

    const onDoneTablesChanges = () => {
        stopEditTables();
    };

    const onCheckIn = () => {
        setOpenDrawerCheckIn(true);
    };

    const onClickFirstAction = () => {
        if (isEditState) {
            onCancelTablesChanges();
        } else {
            startEditTables();
        }
    };

    const onClickSecondAction = () => {
        if (isEditState) {
            onDoneTablesChanges();
        } else {
            onCheckIn();
        }
    };

    const actionBtns = React.useMemo(() => {
        return (
            <ButtonContainer>
                {/* <Space size={16} style={{ marginLeft: 15 }}> */}
                <SingleButton
                    className='dashboard-button'
                    shape='circle'
                    width={170}
                    height={40}
                    txtColor='white'
                    btnColor={colors.gray}
                    title={isEditState ? 'Cancel' : 'Edit Tables'}
                    onClick={onClickFirstAction}
                />
                <SingleButton
                    className='dashboard-button'
                    shape='circle'
                    width={170}
                    height={40}
                    txtColor='white'
                    btnColor={colors.redColor}
                    title={isEditState ? 'Done' : 'Check In'}
                    icon={
                        isEditState ? null : (
                            <img
                                src={IconCheckIn}
                                width={22}
                                height={20}
                                alt='check-in-icon'
                            />
                        )
                    }
                    onClick={onClickSecondAction}
                />
                {/* </Space> */}
            </ButtonContainer>
        )
        // eslint-disable-next-line
    }, [isEditState]);

    return (
        <Box padding="5px 52px 30px 22px" height="768px" marginBottom={52} overflow="hidden">
            <TableLayout
                tables={tables}
                extraButtons={actionBtns}
                isTitle
                editable={isEditState} />
        </Box>
    );
}

DashboardTables.propTypes = {
    tables: PropTypes.any,
};
