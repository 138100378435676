import React from 'react';

export const paymentMethods = {
  cards: [
    {
      type: 'Visa',
      lastFourNumbers: 7543,
      expire: new Date('2024-12-1'),
      name: 'Alex Clayton',
      id: 1,
    },
    {
      type: 'Amex',
      lastFourNumbers: 5235,
      expire: new Date('2023-11-1'),
      name: 'Alberto Raya',
      id: 2,
    },
    {
      type: 'Visa',
      lastFourNumbers: 8787,
      expire: new Date('2022-10-1'),
      name: 'Ezequiel Dengra',
      id: 3,
    },
  ],
  ways: [
    {
      title: 'Credit or debit card',
      icon: <i className='fas fa-credit-card' />,
      id: 4,
    },
    { title: 'Cash', icon: <i className='far fa-money-bill-wave' />, id: 5 },
    { title: 'No payment necessary', id: 6, className: 'no-title' },
  ],
};
