import styled from '@emotion/styled';
import { colors } from 'theme';

export const ItemAssignCategoryContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  .sub-category-container {
    padding: 20px;
    margin-bottom: 11px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.08);

    &.selected {
      border: 2px solid ${colors.redColor};
    }

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;
