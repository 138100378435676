import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerEditOrderItemContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  color: #fff;
  font-family: Montserrat;
  .dressing-container {
    .dressing-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 10px 15px 10px;
      .title {
        font-size: 14px;
        font-weight: bold;
      }
      .title-amount {
        font-size: 14px;
        font-weight: 500;
        border-radius: 4px;
        background-color: #ed2626;
        padding: 2px 9px;
      }
    }
    .dressing-content {
      margin-bottom: 40px;
      .dressing-item {
        cursor: pointer;
        width: 100%;
        border-radius: 16px;
        border: solid 1px rgba(255, 255, 255, 0.08);
        padding: 20px 25px;
        margin-bottom: 15px;
        &.active {
          border: solid 1px #ed2626;
        }
      }
      .dressing-item:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .add-ons-container {
    .add-ons-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 10px 15px 10px;
      .title {
        font-size: 14px;
        font-weight: bold;
      }
      .title-amount {
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.4);
      }
    }
    .add-ons-content {
      margin-bottom: 40px;
      .add-ons-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;
        border-radius: 16px;
        border: solid 1px rgba(255, 255, 255, 0.08);
        padding: 20px 25px;
        margin-bottom: 15px;
        p {
          margin-bottom: 0px;
        }
        &.active {
          border: solid 1px #ed2626;
        }
      }
    }
  }
  .special-instructions {
    padding: 0 10px;
    margin-bottom: 100px;
    h6 {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: -0.34px;
      color: #ffffff;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .amount-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    .button-changer {
      cursor: pointer;
      width: 60px;
      height: 60px;
      background-color: rgba(255, 255, 255, 0.06);
      border-radius: 50%;
      font-size: 24px;
      color: #ffffff;
      border: unset;
      outline: unset;
      i {
        font-size: 24px;
      }
    }
    .counter {
      margin: 0 30px;
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
    }
  }

  .submit-button {
    cursor: pointer;
    width: 100%;
    height: 50px;
    min-height: 50px;
    border-radius: 8px;
    background-color: #ed2626;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    border: unset;
    outline: unset;
    margin-bottom: 15px;
  }
  .cancel-button {
    cursor: pointer;
    width: 100%;
    height: 50px;
    min-height: 50px;
    border-radius: 8px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    color: #ed2626;
    border: unset;
    outline: unset;
  }
`;
