import React, { useContext, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLocation } from 'react-router-dom';
import { ProfileContainer, ProfileCardContainer } from './style';
import { restaurantServices, cuisineServices } from 'services/api-services';
import { getJwt } from 'services/jwt';
import { showMessage } from 'services/functions';
import { DataContext } from 'contexts/DataContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';
import Box from 'components/Box';
import ItemPicker from 'components/DrawerForm/ItemPicker';
import SingleLoader from 'components/SingleLoader';
import SingleLocationSearch from 'components/SingleLocationSearch';
import SingleAntDrawerInput from 'components/SingleAntDrawerInput';
import { SingleAvatarUploader } from 'components/SingleAvatarUploader';
import {
  ProfileHeader,
  BusinessHoursField,
  DrawerCuisine,
  ManagerField,
  PriceTierField,
  PhoneField,
  EmailField,
  WebsiteField,
  FeaturedImagesField,
} from './components';

import { DefaultUser } from 'assets/images/profile';
import isEqual from 'lodash/isEqual';

export default function Profile() {
  const { pathname } = useLocation();
  const { restaurantId } = useContext(AuthContext);
  const { restaurantProfile, setRestaurantProfile } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  // const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [openDrawerCuisine, setDrawerCuisine] = useState(false);

  useEffect(() => {
    getRestaurantProfile();

    // eslint-disable-next-line
  }, [pathname]);

  const getRestaurantProfile = async () => {
    setIsLoading(true);

    const onOk = ({ restaurant }) => {
      setRestaurantProfile(restaurant);
      setIsLoading(false);
    };

    const onFail = (errMessage) => {
      showMessage(errMessage, 'error');
      setIsLoading(false);
    };

    return await restaurantServices.getRestaurantById(
      restaurantId,
      onOk,
      onFail
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function updateRestaurantProfile(updatedRestaurantProfile) {
    setIsLoading(true);
    const {
      title,
      photo,
      gallery = [],
      location,
      // we should replace it with `place_id`
      // place_id
      address,
      country,
      state_code,
      city,
      street,
      //
      working_hours,
      price_level,
      cuisinesIds = [],
      email,
      phone_number,
      manager_name,
      manager_title,
      status,
      website,
    } = updatedRestaurantProfile;
    const payload = {
      title,
      photo: String(photo ? photo.id : ''),
      gallery: (gallery || []).map(({ id }) => id),
      location,
      // we should replace it with `place_id`
      // place_id
      address,
      country,
      state_code,
      city,
      street,
      //
      working_hours,
      price_level,
      cuisines: cuisinesIds,
      email,
      phone_number,
      manager_name,
      manager_title,
      status,
      website,
    };

    const onOk = () => {
      showMessage('Updated Successfully', 'success');
      setIsLoading(false);
    };

    const onFail = (errMessage) => {
      showMessage(errMessage, 'error');
      setIsLoading(false);
    };

    return await restaurantServices.updateRestaurantDetails(
      restaurantId,
      payload,
      onOk,
      onFail
    );
  }

  const onSyncChange = (updatedRestaurant) => {
    if (!isEqual(restaurantProfile, updatedRestaurant)) {
      updateRestaurantProfile(updatedRestaurant);
      setRestaurantProfile(updatedRestaurant);
    }
  };

  const onChangeWorkingHours = (updatedWorkingHours) =>
    onSyncChange({
      ...restaurantProfile,
      working_hours: updatedWorkingHours,
    });

  const onUpdateClosedDays = (newClosedDay) => {
    setRestaurantProfile({
      ...restaurantProfile,
      closed_days: [...restaurantProfile.closed_days, newClosedDay],
    });
  };

  const onDeleteClosedDay = (updateClosedDays) =>
    setRestaurantProfile({
      ...restaurantProfile,
      closed_days: updateClosedDays(restaurantProfile.closed_days),
    });

  const onChangeLocation = (newLocation, newAddress) => {
    const [street, city, stateCode, country] = newAddress.split(',');
    onSyncChange({
      ...restaurantProfile,
      address: newAddress,
      country: country.trim(),
      state_code: stateCode.trim(),
      city: city.trim(),
      street: street.trim(),
      location: newLocation,
    });
  };

  const onChangeCuisines = ({ cuisines, cuisinesIds }) =>
    onSyncChange({
      ...restaurantProfile,
      cuisines,
      cuisinesIds,
    });

  const onChangeManager = ({ newManagerName, newManagerTitle }) =>
    onSyncChange({
      ...restaurantProfile,
      manager_name: newManagerName,
      manager_title: newManagerTitle,
    });

  const onChangePriceTier = ({ priceLevel }) =>
    onSyncChange({
      ...restaurantProfile,
      price_level: priceLevel,
    });

  const onDeleteFeaturedImage = (updatedGallery) =>
    onSyncChange({
      ...restaurantProfile,
      gallery: updatedGallery,
    });

  const onAddFeaturedImage = (addedImage) => {
    onSyncChange({
      ...restaurantProfile,
      gallery: [...(restaurantProfile.gallery ?? []), addedImage],
    });
  }
  const onChangePhone = ({ phone }) =>
    onSyncChange({ ...restaurantProfile, phone_number: phone });

  const onChangeEmail = ({ email }) =>
    onSyncChange({
      ...restaurantProfile,
      email,
    });

  const onChangeWebSite = (updatedWebSite) =>
    onSyncChange({
      ...restaurantProfile,
      website: updatedWebSite,
    });

  return (
    <ProfileContainer>
      <ProfileHeader />
      <div className='restaurant-profile-content'>
        {isLoading ? (
          <SingleLoader />
        ) : (
          <div className='animate__animated animate__fadeIn vertical-space'>
            <ProfileCardContainer
              className='space-item'
              padding='31px 65px 30px 29px'
            >
              <div className='boot-container'>
                <div>
                  <Row>
                    <Col lg={2}>
                      <SingleAvatarUploader
                        placeholder={
                          restaurantProfile.photo
                            ? restaurantProfile.photo.file
                            : DefaultUser
                        }
                        receiveImage={(image) =>
                          setRestaurantProfile({
                            ...restaurantProfile,
                            photo: image,
                          })
                        }
                      />
                    </Col>
                    <Col lg={5}>
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='space-around'
                        height='100%'
                      >
                        <SingleAntDrawerInput
                          label='Restaurant Name'
                          placeholder='Enter name'
                          value={restaurantProfile.title}
                          onChange={(e) =>
                            setRestaurantProfile({
                              ...restaurantProfile,
                              title: e.target.value,
                            })
                          }
                          onPressEnter={() =>
                            updateRestaurantProfile(restaurantProfile)
                          }
                          onBlur={() =>
                            updateRestaurantProfile(restaurantProfile)
                          }
                        />
                        <SingleLocationSearch
                          label='Location'
                          address={restaurantProfile.address}
                          placeholder='Enter an address'
                          onChange={onChangeLocation}
                        />
                      </Box>
                    </Col>
                    <Col lg={5}>
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='space-around'
                        height='100%'
                      >
                        <BusinessHoursField
                          workingHours={restaurantProfile.working_hours}
                          closedDays={restaurantProfile.closed_days}
                          onChange={onChangeWorkingHours}
                          onUpdateClosedDays={onUpdateClosedDays}
                          onDeleteClosedDay={onDeleteClosedDay}
                        />
                        <ManagerField
                          managerTitle={restaurantProfile.manager_title}
                          managerName={restaurantProfile.manager_name}
                          onChange={onChangeManager}
                        />
                      </Box>
                    </Col>
                  </Row>
                </div>
              </div>
            </ProfileCardContainer>
            <ProfileCardContainer
              className='space-item horizontal-space'
              padding='50px 65px 59px 56px'
            >
              <ItemPicker
                isInDrawer={false}
                label='Cuisine'
                className='horizontal-inner-element'
                value={(restaurantProfile.cuisines || []).join(', ').trim()}
                placeholder='Choose cuisine'
                onClick={() => setDrawerCuisine(true)}
              />
              <PriceTierField
                priceLevel={restaurantProfile.price_level}
                onChange={onChangePriceTier}
              />
              <FeaturedImagesField
                gallery={restaurantProfile.gallery || []}
                onDeleteFeaturedImage={onDeleteFeaturedImage}
                onAddFeaturedImage={onAddFeaturedImage}
              />
            </ProfileCardContainer>
            <ProfileCardContainer
              className='space-item horizontal-space'
              padding='50px 65px 59px 56px'
            >
              <PhoneField
                phoneNumber={restaurantProfile.phone_number}
                onChange={onChangePhone}
              />
              <EmailField
                email={restaurantProfile.email}
                onChange={onChangeEmail}
              />
              <WebsiteField
                website={restaurantProfile.website}
                onSubmit={onChangeWebSite}
              />
            </ProfileCardContainer>
          </div>
        )}
      </div>
      <DrawerCuisine
        visible={openDrawerCuisine}
        onClose={() => setDrawerCuisine(false)}
        onChange={onChangeCuisines}
        currentCuisines={restaurantProfile.cuisines}
      />
    </ProfileContainer>
  );
}

export const fetchCuisineList = async (setCuisineList) => {
  const currentJwt = getJwt();

  if (currentJwt) {
    const onOk = (data) => {
      setCuisineList(data);
    };

    const onFail = (errorMessage) => {
      showMessage(errorMessage, 'error');
    };

    return await cuisineServices.getCuisineList(onOk, onFail);
  }
};
