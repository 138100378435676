import React from 'react';
import PropTypes from 'prop-types';
import { DrawerCheckInErrorContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerCheckInError({ onClose, onRetry, errorMessage }) {
  return (
    <DrawerCheckInErrorContainer
      visible={true}
      placement="bottom"
      onClose={onClose}
    >
      <div className="warning-icon">
        <i className="fas fa-exclamation-triangle" />
      </div>
      <SingleText size={22} spacing="0.42px" marginBottom={10}>Check In Error</SingleText>
      <SingleText spacing="0.27px" marginBottom={5} lineHeight="21px">{errorMessage ? errorMessage : "Something went wrong."}</SingleText>
      <SingleText spacing="0.27px" marginBottom={30} lineHeight="21px">Please try again.</SingleText>
      <SingleButton className="btn-try-again" title="Try Again" margin="0px 0px 20px" onClick={onRetry} />
      <SingleButton title="Cancel" btnColor={colors.lightGray} onClick={onClose} />
    </DrawerCheckInErrorContainer>
  );
}

DrawerCheckInError.propTypes = {
  onClose: PropTypes.func,
  onRetry: PropTypes.func,
  errorMessage: PropTypes.string,
};
