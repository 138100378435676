import styled from '@emotion/styled';

export const SelectTableHeaderContainer = styled.div`
  .panel-header-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .back-arrow {
      width: 50px;
      cursor: pointer;
      position: absolute;
      left: 0;
      i {
        color: #ffffff;
        font-size: 22px;
      }
    }
    .panel-header-context {
      font-size: 22px;
      font-weight: 500;
      color: #ffffff;
    }
  }

  .available-tables-message {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    i {
      color: #1fc086;
    }
    span {
      margin-left: 5px;
    }
  }

  .display-variant-nav {
    display: flex;
    align-items: center;
    margin-left: -24px;
    margin-right: -24px;

    .display-variant {
      cursor: pointer;
      padding: 15px 0;
      flex: 1 1 50%;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.5);
      border-bottom: 3px solid rgba(255, 255, 255, 0.08);

      &.active {
        color: #fff;
        border-bottom: 3px solid #ed2626;
      }
    }
  }
`;
