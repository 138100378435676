import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerClassDetailsContainer } from './style';
import { colors } from 'theme';
import { useCardDetails } from 'hooks';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { CardInput } from './CardInput';

export function DrawerClassDetails({ visible, onClose, onContinue }) {
  const { card, cardFields, changeCard, clearCard, isReady } = useCardDetails();

  const [isTouched, setIsTouched] = useState(false);
  const touch = () => setIsTouched(true);
  const clearTouches = () => setIsTouched(false);

  const onAddCard = () => {
    onClose();
    onContinue(card);
  };

  const onChange = (key, newValue) => {
    changeCard({ [key]: newValue });

    if (!isTouched) {
      touch();
    }
  };

  useEffect(() => {
    if (!visible && isTouched) {
      clearTouches();
      clearCard();
    }
  }, [visible, isTouched, clearCard]);

  const allCardFields = [...cardFields];
  const combinedCardFields = allCardFields.splice(2, 1);

  return (
    <DrawerClassDetailsContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
      customHeight={isTouched ? (isReady() ? 510 : 525) : 510}
    >
      <SingleText size={20} spacing='0.38px' weight='500' marginBottom={43}>
        Card Details
      </SingleText>
      {allCardFields.map(
        ([label, key, isValid, formatter, convertBack], index) => {
          return (
            <CardInput
              className={key === 'number' ? 'card-number' : ''}
              label={label}
              value={card[key] || ''}
              formatToReadableValue={formatter}
              convertVisibleValueToRealValue={convertBack}
              onChange={(newValue) => onChange(key, newValue)}
              isValid={isValid()}
              isTouched={isTouched}
              key={index + label + key}
            />
          );
        }
      )}
      {combinedCardFields.map(([leftField, rightField], index) => {
        const [
          leftFieldLabel,
          leftFieldKey,
          isLeftFieldValid,
          formatterLeft,
          convertBackLeft,
        ] = leftField;
        const [
          rightFieldLabel,
          rightFieldKey,
          isRightFieldValid,
          formatterRight,
          convertBackRight,
        ] = rightField;

        return (
          <div
            className='combined-container'
            key={
              index +
              leftFieldLabel +
              leftFieldKey +
              rightFieldLabel +
              rightFieldKey
            }
          >
            <CardInput
              className='combined-left'
              label={leftFieldLabel}
              value={card[leftFieldKey] || ''}
              formatToReadableValue={formatterLeft}
              convertVisibleValueToRealValue={convertBackLeft}
              onChange={(newValue) => onChange(leftFieldKey, newValue)}
              isValid={isLeftFieldValid()}
              isTouched={isTouched}
            />
            <CardInput
              className={`combined-right ${
                rightFieldKey === 'cvv' ? 'cvv' : ''
              }`}
              htmlType={rightFieldKey === 'cvv' ? 'password' : 'text'}
              label={rightFieldLabel}
              value={card[rightFieldKey] || ''}
              formatToReadableValue={formatterRight}
              convertVisibleValueToRealValue={convertBackRight}
              onChange={(newValue) => onChange(rightFieldKey, newValue)}
              isValid={isRightFieldValid()}
              isTouched={isTouched}
            />
          </div>
        );
      })}
      {isTouched && !isReady() ? (
        <div className='error-message-container'>
          <i className='fas fa-exclamation-circle' />
          <span className='error-message'>Incorrect fields.</span>
        </div>
      ) : (
        ''
      )}
      <SingleButton
        title='Continue'
        margin={
          isTouched
            ? isReady()
              ? '51px 0px 23px'
              : '17px 0px 23px'
            : '51px 0px 23px'
        }
        btnColor={
          isTouched && isReady() ? colors.redColor : 'rgba(255, 255, 255, 0.1)'
        }
        txtColor={isTouched && isReady() ? 'white' : 'rgba(255, 255, 255, 0.2)'}
        onClick={onAddCard}
        disabled={!isTouched || !isReady()}
      />
      <SingleButton
        title='Cancel'
        btnColor='transparent'
        txtColor={colors.redColor}
        onClick={onClose}
      />
    </DrawerClassDetailsContainer>
  );
}

DrawerClassDetails.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
};
