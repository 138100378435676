import React, { useContext, useMemo } from 'react';
import { generateOrdersStatistics } from 'services/functions';
import { months } from 'services/static';
import { CompletedOrdersContext } from 'contexts/CompletedOrdersContextContainer';

const genStatsStub = ({
  totalSales,
  avgOrder,
  orders,
  guests,
  items,
  avgWaitTime,
  avgRating,
}) => [
  {
    title: 'Total Sales',
    value: `$${totalSales.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`, // separating zeros with comma
  },
  {
    title: 'Avg. Order',
    value: `$${avgOrder.toFixed(2)}`,
  },
  { title: 'Orders', value: orders },
  { title: 'Guests', value: guests },
  { title: 'Items Sold', value: items },
  {
    title: 'Avg. Wait',
    value: avgWaitTime !== 0 ? avgWaitTime : '––',
    isNumericValueEmpty: avgWaitTime === 0,
  },
  {
    title: 'Avg. Rating',
    value:
      avgRating !== 0 ? (
        <>
          <i className='fas fa-star' style={{ marginRight: 10 }} />
          {avgRating}
        </>
      ) : (
        '––'
      ),
    isNumericValueEmpty: avgRating === 0,
  },
];

export function GeneralStatistic() {
  const {
    completedOrders,
    showFilteredOrders,
    selectedPeriod,
    customTimeRange,
    isRangeActive,
  } = useContext(CompletedOrdersContext);

  const orderOverall = useMemo(
    () => generateOrdersStatistics(showFilteredOrders()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [completedOrders, selectedPeriod, customTimeRange, showFilteredOrders]
  );

  return (
    <div className='general-statistics'>
      <div className='navigation-part'>
        <div className='current-time-period'>
          <i className='fas fa-chart-area' />
          <span className='current-period-title'>
            {readPeriod(selectedPeriod, customTimeRange, isRangeActive())}
          </span>
        </div>
      </div>
      <div className='gen-stats-container'>
        {genStatsStub(orderOverall).map(
          ({ title, value, isNumericValueEmpty = false }) => (
            <div className='stats-item' key={title + value}>
              <div className='stats-title'>
                <span className='title'>{title}</span>
              </div>
              <div
                className={`stats-value ${isNumericValueEmpty ? 'empty' : ''}`}
              >
                {value}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

/**
 * Transforms time range into string, like this `[1623618000000, 1625000400000] -> "Jun 14 - Jun 30"`
 * @param {[number, number]} timeRange
 * @returns {string}
 */
export const formatTimeRange = (timeRange) => {
  const [firstTime, secondTime] = timeRange;
  const firstDate = new Date(firstTime);
  const secondDate = new Date(secondTime);

  return `${months[firstDate.getMonth()].slice(
    0,
    3
  )} ${firstDate.getDate()} - ${months[secondDate.getMonth()].slice(
    0,
    3
  )} ${secondDate.getDate()}`;
};

/**
 *
 * @param {"Today" | "Yesterday" | "Custom"} period
 * @param {[number, number]} timeRange
 * @param {boolean} isTimeRangeValid
 * @returns {string}
 */
export const readPeriod = (period, timeRange, isTimeRangeValid) => {
  if (period === 'Custom' && isTimeRangeValid) {
    return formatTimeRange(timeRange);
  }

  return period;
};
