import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerUpdateMobileNumberContainerStyled } from './style';
import { PhoneNumberFormatter } from 'utils';
import { DrawerUpdateValue } from '../../DrawerUpdateValue';
import { DrawerVerifyMobileNumber } from './DrawerVerifyMobileNumber';

export function DrawerUpdateMobileNumberContainer({
  visible,
  onClose,
  phone,
  changeMobileNumber = () => {},
  confirmMobileNumber = () => {},
}) {
  const [openDrawerUpdateMobileNumber, setOpenDrawerUpdateMobileNumber] =
    useState(false);
  const onOpenDrawerUpdateMobileNumber = () =>
    setOpenDrawerUpdateMobileNumber(true);
  const onCloseDrawerUpdateMobileNumber = () =>
    setOpenDrawerUpdateMobileNumber(false);

  const [openDrawerVerifyMobileNumber, setOpenDrawerVerifyMobileNumber] =
    useState(false);
  const onOpenDrawerVerifyMobileNumber = () =>
    setOpenDrawerVerifyMobileNumber(true);
  const onCloseDrawerVerifyMobileNumber = () =>
    setOpenDrawerVerifyMobileNumber(false);

  const onContinue = (value) => {
    changeMobileNumber(1 + value);
    onCloseDrawerUpdateMobileNumber();
    onOpenDrawerVerifyMobileNumber();
  };

  const formatPhoneNumber = () => {
    const phoneNumberFormatter = new PhoneNumberFormatter(phone);
    return phoneNumberFormatter.removeCountryCode();
  };

  const onCloseWhenUpdating = () => {
    onCloseDrawerUpdateMobileNumber();
    onClose();
  };

  const onCloseWhenVerifying = () => {
    onCloseDrawerVerifyMobileNumber();
    onClose();
  };

  const onBack = () => {
    onCloseDrawerVerifyMobileNumber();
    onOpenDrawerUpdateMobileNumber();
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerUpdateMobileNumber();
    }
  }, [visible]);

  return (
    <DrawerUpdateMobileNumberContainerStyled
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      <DrawerUpdateValue
        visible={openDrawerUpdateMobileNumber}
        onClose={onCloseWhenUpdating}
        label='Mobile Number'
        inputType='tel'
        beforeInputText='+1'
        currentValue={formatPhoneNumber()}
        placeholder='Enter you mobile number'
        updateTitleEnd='mobile number'
        onContinue={onContinue}
      />
      <DrawerVerifyMobileNumber
        phone={phone}
        visible={openDrawerVerifyMobileNumber}
        onClose={onCloseWhenVerifying}
        onBack={onBack}
        onConfirmCode={confirmMobileNumber}
      />
    </DrawerUpdateMobileNumberContainerStyled>
  );
}

DrawerUpdateMobileNumberContainer.propTypes = {
  visible: PropTypes.bool,
  phone: PropTypes.string,
  onClose: PropTypes.func,
  changeMobileNumber: PropTypes.func,
  // confirmMobileNumber: PropTypes.func,
};
