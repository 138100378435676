import React from 'react';
import PropTypes from 'prop-types';
import { TotalSimplifiedContainer } from './style';

export function TotalSimplified({
  totalValues = [
    ['Subtotal', 55.96],
    ['Tax', 2.3],
    ['Total', 71.45],
    ['Restaurant Fee', 2.0],
  ],
  discount = {},
  onRemoveDiscount = () => {},
}) {
  let _totalValues = [...totalValues];
  if (Object.keys(discount).length) {
    _totalValues.splice(_totalValues.length - 2, 0, [
      <>
        {`Discount (${
          discount.type === 'percentage'
            ? `${Math.trunc(discount.value)}%`
            : `$${discount.value.toFixed(2)}`
        })`}
        <div className='remove-discount' onClick={onRemoveDiscount}>
          Remove
        </div>
      </>,
      discount.money,
      'discount',
    ]);
  }

  return (
    <TotalSimplifiedContainer className='total-simplified'>
      {_totalValues.map(([title, total, className], index) => (
        <div
          className={`active-total-value-container ${
            title === 'Total' ? 'special-total' : ''
          } ${className}`}
          onClick={onRemoveDiscount}
          key={title + total + index}
        >
          <div className='active-title-container'>
            <span className='active-title'>{title}</span>
            <span className='active-value'>
              {title === 'Discount' ? '-' : ''}${total.toFixed(2)}
            </span>
          </div>
        </div>
      ))}
    </TotalSimplifiedContainer>
  );
}

TotalSimplified.propTypes = {
  totalValues: PropTypes.array,
  discount: PropTypes.object,
  onRemoveDiscount: PropTypes.func,
};
