import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaStar } from 'react-icons/fa';
import { RestaurantInfoContainer } from './style';
import { restaurantServices } from 'services/api-services';
import { showMessage } from 'services/functions';
import { DataContext } from 'contexts/DataContextContainer';
import SingleText from 'components/SingleText';
import SingleAntImage from 'components/SingleAntImage';

import { SidebarImage } from 'assets/images/dashboard';

export function RestaurantInfo({ restaurantId }) {
  const { restaurantProfile, setRestaurantProfile } = useContext(DataContext);

  useEffect(() => {
    async function getRestaurantInfoById() {
      const onOk = ({ restaurant }) => {
        setRestaurantProfile(restaurant);
      };

      const onFail = (errMessage) => {
        showMessage(errMessage, 'error');
      };

      return await restaurantServices.getRestaurantById(
        restaurantId,
        onOk,
        onFail
      );
    }

    if (!Object.keys(restaurantProfile).length) {
      getRestaurantInfoById();
    }
  }, [restaurantId, restaurantProfile, setRestaurantProfile]);

  return (
    <RestaurantInfoContainer>
      <SingleAntImage
        src={
          restaurantProfile.photo ? restaurantProfile.photo.file : SidebarImage
        }
        borderRadius='50%'
        width={100}
        height={100}
        style={{ marginBottom: 10 }}
      />
      <SingleText
        family='SFProText'
        size={17}
        weight='bold'
        spacing='-0.41px'
        marginBottom={4}
      >
        {restaurantProfile.title}
      </SingleText>
      <SingleText
        family='SFProText'
        size={13}
        opacity={0.6}
        spacing='-0.31px'
        marginBottom={10}
      >
        {restaurantProfile.price_level
          ? '$'.repeat(restaurantProfile.price_level)
          : ''}{' '}
        Seafood, Sushi Bar
      </SingleText>
      <SingleText family='SFProText' size={13} spacing='-0.31px'>
        Open until 11:00pm
        <SingleText family='SFProText' size={13} spacing='-0.31px' marginLeft={10}>
          4.5
          <FaStar size={14} color='#fdcb6e' style={{ marginLeft: 3, marginBottom: 3 }} />
        </SingleText>
      </SingleText>
    </RestaurantInfoContainer>
  );
}

RestaurantInfo.propTypes = {
  restaurantId: PropTypes.number,
};
