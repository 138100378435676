import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { MyAccountMoreContainer } from './style';

export function MyAccountMore({ onLogout, onDelete }) {
  return (
    <MyAccountMoreContainer>
      <Dropdown.Toggle className='my-account-more'>
        <i className='fas fa-ellipsis-h' onClick={() => {}} />
      </Dropdown.Toggle>
      <Dropdown.Menu className='my-account-options'>
        {[
          [<i className='far fa-sign-out' key='logout' />, 'Log Out', onLogout],
          [
            <i className='fal fa-trash' key='delete' />,
            'Delete account',
            onDelete,
          ],
        ].map(([icon, title, handler], index) => (
          <Dropdown.Item key={title + index} eventKey={index} onClick={handler}>
            <div className='my-account-dropdown-item'>
              {icon}
              <span className='title'>{title}</span>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </MyAccountMoreContainer>
  );
}

MyAccountMore.propTypes = {
  onLogout: PropTypes.func,
  onDelete: PropTypes.func,
};
