/* eslint-disable react/display-name */
import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { HighestRatedContainer } from './style';
import { restaurantServices } from 'services/api-services';
import { showMessage } from 'services/functions';
import { AuthContext } from 'contexts/AuthContextContainer';
import { StaffCard } from '../components';
import { Rating30Days, Rating7Days, RatingToday } from 'assets/images/staff';

export default function HighestRated({ searchValue }) {
  const { restaurantId } = useContext(AuthContext);
  const [bestStaff, setBestStaff] = useState({});

  useEffect(() => {
    getHighestRatedStaff();
    // eslint-disable-next-line
  }, []);

  async function getHighestRatedStaff() {
    const { ok, data, errMessage } = await restaurantServices.getStaffHighest(
      restaurantId,
      {}
    );
    if (ok) {
      setBestStaff({
        ...data,
        // mocked data because server will not send others
        'today': {
          avatar: null,
          email: '',
          first_name: 'Jaquon',
          id: 2633333,
          last_name: 'Hart',
          phone_number: '21837461827',
          rating: 4.3,
          restaurant_id: 8,
          role_slug: 'manager',
          status: 1,
        },
        '7day': {
          avatar: null,
          email: '',
          first_name: 'Elston',
          id: 269999,
          last_name: 'Gullan',
          phone_number: '385555555555',
          rating: 4.5,
          restaurant_id: 8,
          role_slug: 'waiter',
          status: 1,
        },
        '30day': {
          avatar: null,
          email: '',
          first_name: 'Elston',
          id: 269999,
          last_name: 'Gullan',
          phone_number: '385555555555',
          rating: 4.5,
          restaurant_id: 8,
          role_slug: 'waiter',
          status: 1,
        },
      });
    } else {
      showMessage(errMessage, 'error');
    }
  }

  const isPartOfFilterRange = ({ first_name, last_name }) => {
    if (searchValue.trim()) {
      return `${first_name} ${last_name}`
        .toLowerCase()
        .includes(searchValue.trim().toLowerCase());
    }

    return true;
  };

  return (
    <HighestRatedContainer>
      <div className='staff-container'>
        {bestStaff['today'] && isPartOfFilterRange(bestStaff['today']) ? (
          <StaffCard icon={RatingToday} period='Today' staff={bestStaff['today']} />
        ) : (
          ''
        )}
        {bestStaff['7day'] && isPartOfFilterRange(bestStaff['7day']) ? (
          <StaffCard icon={Rating7Days} period='7 Days' staff={bestStaff['7day']} />
        ) : (
          ''
        )}
        {bestStaff['30day'] && isPartOfFilterRange(bestStaff['30day']) ? (
          <StaffCard icon={Rating30Days} period='30 Days' staff={bestStaff['30day']} />
        ) : (
          ''
        )}
      </div>
    </HighestRatedContainer>
  );
}

HighestRated.propTypes = {
  staffs: PropTypes.array,
  searchValue: PropTypes.string,
};
