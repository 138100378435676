import styled from '@emotion/styled';

export const CategoriesSwitcherContainer = styled.div`
  width: 100%;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;

  .categories-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    div {
      margin-left: 13px;
    }
    div:first-of-type {
      margin-left: 0;
    }
  }

  .edit-icon {
    display: flex;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin-right: 18px;
    border: none;
    border-radius: 50%;
    background: #ed2626;
    box-sizing: border-box;
  }
`;
