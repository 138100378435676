import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types';
import { DrawerTableStatusContainer } from './style';
import { DataContext} from 'contexts/DataContextContainer';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export default function DrawerTableStatus({
  visible = false,
  placement = 'right',
  onClose = () => {},
}) {

  const {
    getTableForEditTemp = {},
    setTableForEditTemp,
  } = useContext(DataContext);

  const [initial, setInitial] = useState(undefined);

  useEffect(() => {
    if (visible && initial === undefined) {
      setInitial(getTableForEditTemp.status);
    } else if (!visible && initial !== undefined) {
      setInitial(undefined);
    }
  }, [visible, getTableForEditTemp, initial, setInitial]);

  const onChange = (value) => {
    setTableForEditTemp({ ...getTableForEditTemp, status: value });
  };

  const onClickStatus = (value) => {
    onChange(value);
  };

  const onSave = () => {
    setInitial(getTableForEditTemp.status);
    onClose();
  };

  const close = () => {
    onChange(initial);
    onClose();
  };

  return (
      <DrawerTableStatusContainer
        onClose={close}
        visible={visible}
        placement={placement}
      >
        <Box display='flex' alignItems='center' marginBottom={40}>
          <Box display='flex' justifyContent='center' flex={1}>
            <SingleText align='center' size={22} weight={500}>Status</SingleText>
          </Box>
        </Box>
        <Box className='drawer-status--options'>
          <Box className={`drawer-status--option ${getTableForEditTemp.status === 1 ? 'active' : 'inactive'}`} marginBottom={15} onClick={() => onClickStatus(1)}>
            <SingleText size={14} weight={500} spacing='-0.34'>Active</SingleText>
          </Box>
          <Box className={`drawer-status--option ${getTableForEditTemp.status !== 0 ? 'inactive' : 'active'}`} onClick={() => onClickStatus(0)}>
            <SingleText size={14} width='fit-content' weight={500} spacing='-0.34'>Inactive</SingleText>
          </Box>
        </Box>
        <Box className="drawer-status-btn-save-container">
          <SingleButton
            className="btn-save"
            title="Save"
            onClick={onSave}
          />
        </Box>
      </DrawerTableStatusContainer>
  )
}

DrawerTableStatus.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  drawerTableActiveStatus:  PropTypes.bool,
  changeTableActiveStatus: PropTypes.func,
};
