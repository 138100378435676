import styled from '@emotion/styled';
import Box from 'components/Box';

export const RestaurantInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 40px;
`;
