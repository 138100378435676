import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerSortContainer = styled(SingleDrawer)`
  height: 471px !important;
  padding: 36px 26px 20px;
  background-color: ${colors.gray5};
  display: flex;
  flex-direction: column;
  align-items: center;

  .sort-options {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .sort-option {
      cursor: pointer;
      width: 100%;
      margin-top: 15px;
      padding: 21px 0px;
      height: 60px;
      border-radius: 16px;
      border: 1px solid rgba(255, 255, 255, 0.08);
      text-align: center;
      font-size: 14px;
      letter-spacing: -0.34px;
      color: white;

      &.active {
        border: 2px solid ${colors.redColor};
      }
    }
  }
`;
