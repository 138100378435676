import styled from '@emotion/styled';
import { colors } from 'theme';

export const PaymentCardContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  height: 85px;
  padding: 19px 28.5px 19px 26px;
  border-radius: 16px;
  border: 1px solid ${colors.gray11};
  background-color: ${colors.gray2};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &.active {
    border: 2px solid ${colors.redColor};
  }

  .card-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .card-info-container {
      margin-bottom: 8px;
      .card-info {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.34px;
        color: white;
      }
    }

    .card-expire-container {
      .expire {
        font-size: 14px;
        font-weight: normal;
        letter-spacing: -0.34px;
        color: white;
      }
    }
  }

  .name-container {
    .name {
      font-size: 14px;
      font-weight: normal;
      letter-spacing: -0.34px;
      color: white;
    }
  }
`;
