import styled from '@emotion/styled';
import { colors } from 'theme';

export const OrderContainer = styled.div`
  min-width: 450px;
  max-width: 450px;
  border-radius: 20px;
  background-color: ${colors.gray2};
  margin-left: 34px;
  margin-bottom: 34px;
  padding: 24px 34px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.active-now {
    background-color: ${colors.gray5};
  }

  &.ready {
    .order-ready-icon {
      background: rgb(253, 203, 110);
      background: linear-gradient(
        322deg,
        rgba(253, 203, 110, 1) 29%,
        rgba(247, 166, 76, 1) 100%
      );
    }
  }

  &.pending {
    .order-ready-icon {
      background: rgb(255, 125, 125);
      background: linear-gradient(
        322deg,
        rgba(255, 125, 125, 1) 29%,
        rgba(237, 38, 38, 1) 100%
      );
    }
  }

  &.order-to-show {
    background-color: ${colors.gray5};
  }

  .order-ready-icon {
    margin-right: 30px;
    width: 47px;
    height: 47px;
    padding: 14px 15px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;

    i {
      font-size: 17px;
      color: white;
      line-height: 14px;
      letter-spacing: -0.41px;
    }
  }

  .order-ready-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .order-title-container {
      width: 100%;
      margin-bottom: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .order-title-inner-container {
        .order-title {
          font-size: 15px;
          font-weight: bold;
          line-height: 14px;
          letter-spacing: -0.36px;
          color: white;
        }
      }
    }

    .table-name-code-container {
      margin-bottom: 15px;

      .name-code-title {
        font-size: 15px;
        color: white;
        line-height: 14px;
        letter-spacing: -0.36px;
      }
    }

    .table-info-container {
      .table-info-title {
        font-size: 15px;
        color: rgba(255, 255, 255, 0.6);
        line-height: 14px;
        letter-spacing: -0.36px;
      }
    }
  }
`;
