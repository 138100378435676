import styled from '@emotion/styled';

import { OopsAddingFailed } from 'assets/images/menu';

export const CompletedOrdersContainer = styled.div`
  &.orders-tables-section {
    width: 100%;
    display: flex;
    flex-direction: column;

    .order-tables-section-unit {
      margin-top: 34px;
      width: 100%;
      display: flex;
    }

    .no-completed-orders-to-show {
      position: relative;
      margin-top: 157px;
      width: 412px;
      height: 255px;
      margin-left: auto;
      margin-right: auto;
      background-size: cover;
      background-position: center;
      background-image: url(${OopsAddingFailed});

      &:after {
        content: 'There are no completed orders to show.';
        display: block;
        font-size: 24px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.6);
        width: 314px;
        text-align: center;
        position: absolute;
        bottom: -96px;
        left: 47px;
      }
    }
  }
`;
