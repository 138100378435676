import React from 'react';
import PropTypes from 'prop-types';
import { NoItemsYetContainer } from './style';
import { NoItemsYet as NoItemsYetImage } from 'assets/images/menu';

export function NoItemsYet({ addSomething = true }) {
  return (
    <NoItemsYetContainer>
      <div className='center-core'>
        <div
          className='no-items-yet-img'
          style={{ backgroundImage: `url(${NoItemsYetImage})` }}
        />
        <div className='no-items-title'>There’re no items yet</div>
        {addSomething ? (
          <div className='no-items-action'>Add something</div>
        ) : (
          ''
        )}
      </div>
    </NoItemsYetContainer>
  );
}

NoItemsYet.propTypes = {
  addSomething: PropTypes.bool,
};
