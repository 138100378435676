import styled from '@emotion/styled';

export const FoodContainer = styled.div`
  width: 100%;
  padding: 36px 26px 31px;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(4px);

  .food-empty-main {
    width: 100%;
    height: 290px;
    background-color: transparent;
  }
`;
