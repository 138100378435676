import React from 'react';
import PropTypes from 'prop-types';
import { DrawerLogOutContainer } from './style';
import { colors } from 'theme';
import { useLogOut } from 'hooks';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerLogOut({ visible, onClose }) {
  const onLogOut = useLogOut({ onBeforeLogOut: onClose });

  return (
    <DrawerLogOutContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='logout-icon'>
        <i className='far fa-sign-out' />
      </div>
      <SingleText
        size={22}
        spacing='0.42px'
        weight='500'
        align='center'
        marginBottom={10}
      >
        Are you sure ?
      </SingleText>
      <SingleText
        className='you-will-be-logged-out'
        size={14}
        spacing='0.27px'
        weight='500'
        align='center'
        marginBottom={32}
      >
        You will be logged out of Feasttt.
      </SingleText>
      <SingleButton title='Log Out' onClick={onLogOut} />
      <SingleButton
        title='Cancel'
        btnColor='transparent'
        txtColor={colors.redColor}
        margin='20px 0px 0px 0px'
        onClick={onClose}
      />
    </DrawerLogOutContainer>
  );
}

DrawerLogOut.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
