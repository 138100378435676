import React from 'react';
import PropTypes from 'prop-types';
import { DrawerRemoveWallContainer } from './style';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import DrawerDeleteWall from './DrawerDeleteWall';

export default function DrawerRemoveWall({
  visible = false,
  placement = 'right',
  onClose = () => {},
  onDelete = () => {},
}) {

  const onKeep = () => {
    onClose();
  };

  const onRemove = () => {
    onDelete();
    onClose();
  };

  return (
    <DrawerRemoveWallContainer
      visible={visible}
      placement={placement}
      onClose={onClose}
    >
      <Box className="drawer-remove-wall-top">
        <SingleText size={22} weight={500}>Remove Wall</SingleText>
      </Box>
      <DrawerDeleteWall
        onKeep={onKeep}
        onRemove={onRemove}
      />
    </DrawerRemoveWallContainer>
  );
}

DrawerRemoveWall.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};