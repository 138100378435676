import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerProfileUpdateEmailContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  padding: 36px 26px 33px;
  height: 365px !important;

  .update-email-input-container {
    display: flex;
    flex-direction: column;

    label {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
      line-height: 22px;
      letter-spacing: -0.34px;
      margin-bottom: 4px;
    }

    input {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.34px;
      color: white;
      padding-bottom: 12px;
      outline: none;
      border-color: transparent;
      border-top-width: 0px;
      border-left-width: 0px;
      border-right-width: 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      background-color: transparent;
    }
  }
`;
