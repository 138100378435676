import styled from 'styled-components';

export const PopupContentContainer = styled.div`
  .popup-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
    .popup-item-icon {
      margin-right: 10px;
    }

    .edit-title {
      font-size: 14px;
      font-weight: 600;
      color: white;
    }

    .delete-title {
      font-size: 14px;
      font-weight: 600;
      color: white;
    }
  }
  .popup-item:last-child {
    margin-bottom: 0px;
  }
`;
