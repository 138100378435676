import styled from '@emotion/styled';
import { colors } from 'theme';
import { LoginImage } from 'assets/images/auth';

export const AuthLayoutContainer = styled.div`
  /* overflow: hidden; */
  overflow-y : hidden; 
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  background-color: ${colors.gray1};

  .left-side {
    width: 50%;
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    .top-icon-container {
      padding-top: 55px;
      padding-left: 60px;
      position: fixed;

      .back-icon {
        cursor: pointer;

        i {
          font-size: 31px;
          color: white;
        }
      }
    }

    .auth-control-container {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: calc(100vw * 0.1588);
      padding-top: 88px;
      padding-bottom: 40px;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .left-side::-webkit-scrollbar {
      display: none;
  }

  .right-side {
    width: 50%;
    /* height: 1080px; */
    height: 100vh;
    background-image: url(${LoginImage});
    background-size: cover;
  }
`;
