import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { RejectOtherPendingDrawerContainer } from './style';
import { RejectOtherRequest } from './RejectOther';
import Button from 'react-bootstrap/Button';
import { modalContext, ScreensContext } from 'routes';
import { RejectOtherOutOfStockAlert } from './OutofStock';
import { OtherRequestedByMistakeAlert } from './RequestedByMistake';
import { OtherUnreasonableRequestAlert } from './UnreasonableRequest';
import { RequestPendingContext } from 'contexts/RequestPendingContextContainer';
import { requestsServices } from 'services/api-services';
import useLocalStorage from 'react-use-localstorage';

export function RejectOtherPendingDrawer({ visible, onClose, rejectValueOther }) {
  const context = useContext(modalContext);
  const { targetElement, setTargetElement } = useContext(ScreensContext);
  const {
    rejectOtherDigit,
    setRejectOtherDigit,
    pendingList,
    selectedPendingData,
    completedList,
    otherRejectInput
  } = useContext(RequestPendingContext);

  const [OtherOutOfStock, setOtherOutOfStock] = useState(false);
  const [OtherRequestedByMistake, setOtherRequestedByMistake] = useState(false);
  const [otherUnreasonable, setOtherUnreasonable] = useState(false);
  const [other, setOther] = useState(false);
  const [val, setVal] = useState(false)
  const [restaurantId, setRestaurantId] = useLocalStorage('feasttt-restaurant-id', '');

  function handleContinueClick() {
    if (OtherOutOfStock === true) {
      OtherRejectOutOfStock();
      removePendingOtherData();
    } else if (OtherRequestedByMistake === true) {
      OtherRejectRequestedByMistake();
      removePendingOtherData();
    }
    else if (otherUnreasonable === true) { 
      OtherRejectUnreasonableRequest()
      removePendingOtherData();
    }
    else if (other === true) RejectOtherPendingDrawer();
  }

  // remove other-request index
  function removePendingOtherData() {
    let data = pendingList.map((items, index) => {
      if (items.id === selectedPendingData.id) {
        requestToCompletedFunc();
        completedList.push(items);
        pendingList.splice(index, 1);
        setVal(!val);
        return index;
      }
    });
    // it is for test
    // console.log(data, 'data');
  }

  // other pending to completed api
  const resId = restaurantId;
  const reqId = selectedPendingData.id;
  function requestToCompletedFunc() {
    const data = {
      done_status: rejectValueOther ,
      reject_reason: rejectOtherDigit,
      reject_comment: otherRejectInput,
    };
    requestsServices.requestToCompleted(resId, reqId, data)
    .then((res) => {
      window.location.reload(false)
    })
    .catch((err) => err)
  }

  // alert screen reload "other-request" - out-of-stock
  function outOfStock() {
    window.location.reload(false)
  }
     
  // reject other method
  function RejectOtherPendingDrawer() {
    context.setModal('Reject-Other-Modal-2');
  }

  // reject other out of stock method
  function OtherRejectOutOfStock() {
    context.setModal('Reject-Other-OutOfStock-Modal-3');
  }

  // reject requested by mistake method
  function OtherRejectRequestedByMistake() {
    context.setModal('Reject-Other-RejectedByMistake-Modal-4');
  }

  // reject unreasonable Request method
  function OtherRejectUnreasonableRequest() {
    context.setModal('Reject-Other-unreasonableRequest-Modal-5');
  }

  return (
    <RejectOtherPendingDrawerContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      {context.modal === 'Reject-Other-Modal-1' ? (
        <>
          <div className='reject-other-pending-wrapper animate__animated animate__fadeInUp'>
            <div>
              <p className='reject-other-pending-heading'>
                Reason other request <br />
                can&apos;t be fulfilled.
              </p>
            </div>

            <div className='reject-other-margin'>
              <div
                className='reject-other-pending-border'
                // id='reject-other-pending-select-border'
                style={{
                  border:
                    targetElement === 'Out of Stock'
                      ? '2px solid #ed2626'
                      : '1px solid #5b5e61',
                }}
                onClick={() => {
                  // OtherRejectOutOfStock();
                  setOtherOutOfStock(true);
                  setOtherRequestedByMistake(false);
                  setOtherUnreasonable(false);
                  setOther(false);
                  setTargetElement('Out of Stock');
                  setRejectOtherDigit(1);
                }}
                aria-hidden='true'
              >
                Out of Stock
              </div>

              <div
                className='reject-other-pending-border'
                // id='reject-other-pending-select-border'
                style={{
                  border:
                    targetElement === 'Requested by mistake'
                      ? '2px solid #ed2626'
                      : '1px solid #5b5e61',
                }}
                onClick={() => {
                  // OtherRejectRequestedByMistake();
                  setOtherOutOfStock(false);
                  setOtherRequestedByMistake(true);
                  setOtherUnreasonable(false);
                  setOther(false);
                  setTargetElement('Requested by mistake');
                  setRejectOtherDigit(3);
                }}
                aria-hidden='true'
              >
                Requested by mistake
              </div>

              <div
                className='reject-other-pending-border'
                // id='reject-other-pending-select-border'
                style={{
                  border:
                    targetElement === 'Unreasonable Request'
                      ? '2px solid #ed2626'
                      : '1px solid #5b5e61',
                }}
                onClick={() => {
                  // OtherRejectUnreasonableRequest();
                  setOtherOutOfStock(false);
                  setOtherRequestedByMistake(false);
                  setOtherUnreasonable(true);
                  setOther(false);
                  setTargetElement('Unreasonable Request');
                  setRejectOtherDigit(4);
                }}
                aria-hidden='true'
              >
                Unreasonable Request
              </div>

              <div
                className='reject-other-pending-border'
                // id='reject-other-pending-select-border'
                style={{
                  border:
                    targetElement === 'Other'
                      ? '2px solid #ed2626'
                      : '1px solid #5b5e61',
                }}
                onClick={() => {
                  // RejectOtherPendingDrawer();
                  setOtherOutOfStock(false);
                  setOtherRequestedByMistake(false);
                  setOtherUnreasonable(false);
                  setOther(true);
                  setTargetElement('Other');
                  setRejectOtherDigit(0);
                }}
                aria-hidden='true'
              >
                Other
              </div>
            </div>

            <div>
              <Button
                variant='none'
                className='reject-other-pending-continue-btn'
                disabled={
                  !(
                    OtherOutOfStock ||
                    OtherRequestedByMistake ||
                    otherUnreasonable ||
                    other
                  )
                }
                onClick={handleContinueClick}
              >
                Continue
              </Button>
            </div>

            <div>
              <p
                className='reject-other-pending-back'
                onClick={onClose}
                aria-hidden='true'
              >
                Back
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <RejectOtherRequest onClose={onClose} removePendingOtherData={removePendingOtherData} />
          <RejectOtherOutOfStockAlert onClose={onClose} outOfStock={outOfStock} />
          <OtherRequestedByMistakeAlert onClose={onClose} />
          <OtherUnreasonableRequestAlert onClose={onClose} />
        </>
      )}
    </RejectOtherPendingDrawerContainer>
  );
}

RejectOtherPendingDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  rejectValueOther: PropTypes.any
};
