import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { CategoryTopContainer } from './style';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleIconWrapper from 'components/SingleIconWrapper';

export function CategoryTop({
  category = '',
  selected = 0,
  isEmpty = false,
  children,
}) {
  const [showChildren, setShowChildren] = useState(true);
  const onOpenChildren = () => setShowChildren(true);
  const onCloseChildren = () => setShowChildren(false);

  return (
    <CategoryTopContainer>
      <Box className='item-assign-category-top'>
        <Box className='category-top-title-container'>
          <SingleText
            color={isEmpty ? 'rgba(255, 255, 255, 0.5)' : 'white'}
            size={16}
            spacing='-0.38px'
            weight='bold'
          >
            {category}
          </SingleText>
        </Box>
        <Box className='show-control-container'>
          <Box className='current-status-container'>
            {selected ? (
              <SingleText className='current-status' weight='bold'>
                Selected {selected}
              </SingleText>
            ) : (
              ''
            )}
          </Box>
          <Box
            className='show-hide-control'
            onClick={showChildren ? onCloseChildren : onOpenChildren}
          >
            <SingleIconWrapper className='show-hide-icon'>
              {showChildren ? (
                <FaChevronUp color='white' />
              ) : (
                <FaChevronDown color='white' />
              )}
            </SingleIconWrapper>
          </Box>
        </Box>
      </Box>
      <Box className='childs'>
        {showChildren ? (
          <div className='pre-children-container'>{children}</div>
        ) : (
          ''
        )}
      </Box>
    </CategoryTopContainer>
  );
}

CategoryTop.propTypes = {
  category: PropTypes.string,
  selected: PropTypes.number,
  isEmpty: PropTypes.bool,
  children: PropTypes.any,
};
