export class Geometry {
  SEATS_MIN_LIMIT = 1;
  SEATS_RECT_MAX_LIMIT = 4;
  // SEATS_RECTANGLE_MAX_LIMIT = 8;
  SEATS_RECTANGLE_MAX_LIMIT = 12;
  SEATS_CIRCLE_MAX_LIMIT = 12;

  constructor() {
    this.shapes = {
      horizontal: 'horizontal',
      vertical: 'vertical',
    };

    this.allShapes = [this.shapes.horizontal, this.shapes.vertical];
  }

  defineShapeDependingOnSize({ width, height }) {
    if (this.isHorizontalShape({ width, height })) {
      return this.shapes.horizontal;
    } else {
      return this.shapes.vertical;
    }
  }

  isHorizontalShape({ width, height }) {
    return width > height;
  }

  isVerticalShape({ width, height }) {
    return width < height;
  }

  isHorizontal(shape) {
    return shape === this.shapes.horizontal;
  }

  isVertical(shape) {
    return shape === this.shapes.vertical;
  }

  decreaseSeats(seats) {
    if (seats > this.SEATS_MIN_LIMIT) {
      return seats;
    } else {
      return this.SEATS_MIN_LIMIT;
    }
  }

  validateSeatsForCircleTable(seats) {
    if (seats < this.SEATS_CIRCLE_MAX_LIMIT) {
      return seats;
    } else {
      return this.SEATS_CIRCLE_MAX_LIMIT;
    }
  }


  validateSeatsAndShapeForRectangle(seats) {
    if (seats <= this.SEATS_RECT_MAX_LIMIT) {
      return [seats, 'rect'];
    } else if (seats <= this.SEATS_RECTANGLE_MAX_LIMIT) {
      return [seats, 'rectangleh'];
    } else {
      return [this.SEATS_RECTANGLE_MAX_LIMIT, 'rectangleh'];
    }
  }
}
