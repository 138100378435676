import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerEditMenusContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};

  .blur-mode {
    opacity: 0.5;
  }

  .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.gray5};
    border-radius: 16px;
    border: 1px solid transparent;
    padding: 20px 22px;

    &.disabled {
      opacity: 0.4;
    }

    .status-and-look {
      cursor: pointer;
      display: flex;
      align-items: center;

      .status {
        margin-right: 15px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #5f6061;

        &.active {
          background-color: ${colors.lightGreen};
        }
      }
    }
  }
`;
