import styled from '@emotion/styled';

export const CompletedComponentContainer = styled.div`
  padding: 25px 30px 25px 35px;
  font-family: Montserrat;
  .container {
    max-width: 100%;
    padding: 0;
  }
  .general-statistics {
    border-radius: 20px;
    box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
    background-color: #1b1c1d;
    padding: 23px 34px;
    .navigation-part {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      .current-time-period {
        i {
          font-size: 20px;
          color: white;
          margin-right: 15px;
        }

        .current-period-title {
          font-size: 20px;
          font-weight: bold;
          letter-spacing: -0.48px;
        }
      }
    }
    .gen-stats-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .stats-item {
        .stats-title {
          .title {
            color: rgba(255, 255, 255, 0.6);
            font-size: 14px;
          }
          .count {
            font-size: 12px;
            margin-left: 10px;
            position: relative;
            cursor: pointer;
            .tooltip {
              visibility: hidden;
              position: absolute;
              bottom: 25px;
              left: 50%;
              -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
              width: 145px;
              height: 55px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              color: #ffffff;
              border-radius: 12px;
              box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.26);
              background-color: #27282a;
            }
            .tooltip:after {
              top: 100%;
              left: 50%;
              border: solid transparent;
              content: '';
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;

              border-top-color: #27282a;
              border-width: 8px;
              margin-left: -8px;
            }
            i {
              margin-right: 5px;
            }
            &.orange {
              color: #f7a64c;
            }
            &.green {
              color: #1fc086;
            }
          }
          .count:hover {
            .tooltip {
              visibility: visible;
            }
          }
        }
        .stats-value {
          font-size: 24px;
          font-weight: bold;
          font-family: Montserrat;

          &.empty {
            letter-spacing: 4px;
          }

          .fa-star {
            font-size: 24px;
            color: #fdcb6e;
          }
        }
      }
    }
  }
`;
