import React from 'react';
import { EmptyBankAccountPlaceholderContainer } from './style';
import SingleText from 'components/SingleText';

export function EmptyBankAccountPlaceholder() {
  return (
    <EmptyBankAccountPlaceholderContainer>
      <div className='empty-bank-account-image' />
      <SingleText
        size={15}
        spacing='-0.36px'
        align='center'
        marginBottom={17}
        width='248px'
      >
        Add a bank account and we’ll deposit your tips weekly.
      </SingleText>
      <SingleText
        size={13}
        spacing='-0.36px'
        align='center'
        color='rgba(255, 255, 255, 0.7)'
        width='205px'
      >
        Your tips will remain in Feasttt until a bank account is linked.
      </SingleText>
    </EmptyBankAccountPlaceholderContainer>
  );
}
