import React from 'react';

export default function CreateSeats(props) {
  const { shape, seats, position, width, height, backgroundColor } = props;
  if (shape === 'circle' || shape === 'square') {
    let rotation;

    if (shape === 'circle') {
      rotation = 360 / seats;
    }

    if (shape === 'square') {
      rotation = 90;
    }

    const isRectangle = (width === 48 || width === 103) && (height === 48 || height === 103) && shape === 'square';
    // const rectangleVertical = seats <=8 ? [-25, -2, 21, 44, -25, -2, 21, 44 ] : [-25, -2, 21, 44, 60, 70, -25, -2, 21, 44, 60, 70 ] ;

    const rectangleVertical = [-35, -15, 5, 24, 41, 58, -35, -15, 5, 24, 41, 58];

    // const rectangleVertical = [ -25, -2, 21, 44, 67, 90 -25, -2, 21, 44, 67, 90 ];
    const rectangleHorizontal = [-48, -26, -4, 18, -48, -26, -4, 18];

    return (
      <>
        {[...Array(seats)].map((e, i) => {
          return (
            <div
              key={i}
              className='seat-container'
              style={{
                width: width + 25,
                height: !isRectangle ? height + 30 : 83,
                transform: !isRectangle
                  ? `rotate(${rotation * i}deg)`
                  : i <= 5
                  ? width > height
                    ? 'rotate(0deg)'
                    : 'rotate(90deg)'
                  : width > height
                  ? 'rotate(180deg)'
                  : 'rotate(270deg)',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                top: !isRectangle
                  ? 'initial'
                  : width < height
                  ? rectangleVertical[i]
                  : 'initial',
                left: !isRectangle
                  ? 'initial'
                  : width > height
                  ? rectangleHorizontal[i]
                  : 'initial',
              }}
            >
              <div
                className='seat'
                style={{
                  width: 13,
                  height: 9,
                  backgroundColor: backgroundColor,
                  borderRadius: 6,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    width: 9,
                    height: 8,
                    border: '2px solid rgb(58 60 63)',
                    backgroundColor: backgroundColor,
                    marginTop: 3,
                    borderRadius: 3,
                  }}
                />
              </div>
            </div>
          );
        })}
      </>
    );
  }

  if (shape === 'rectangle') {
    return (
      <div
        style={{
          width: 80,
          height: position === 'vertical' ? 'auto' : '100px',
          position: 'absolute',
          top: position === 'vertical' ? 0 : 'auto',
          left: 'auto',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignContent: 'start',
          transform: position === 'vertical' ? '' : 'rotate(-90deg)',
        }}
      >
        {[...Array(seats)].map((e, i) => {
          return (
            <div
              key={i}
              className='seat-container'
              style={{
                height: 25,
                flex: '1 1 50%',
                display: 'flex',
                justifyContent: i % 2 === 0 ? 'initial' : 'flex-end',
                alignItems: 'center',
              }}
            >
              <div
                className='seat'
                style={{
                  width: 13,
                  height: 9,
                  backgroundColor: backgroundColor,
                  borderRadius: 6,
                  display: 'flex',
                  justifyContent: 'center',
                  transform: `rotate(${i % 2 ? 90 : -90}deg)`,
                }}
              >
                <div
                  style={{
                    width: 9,
                    height: 8,
                    border: '2px solid rgb(58 60 63)',
                    backgroundColor: backgroundColor,
                    marginTop: 3,
                    borderRadius: 3,
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return '';
}
