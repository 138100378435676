import styled from '@emotion/styled';
import { colors } from 'theme';

export const CheckListItemContainer = styled.div`
  width: 100%;
  padding: 37px 30px 44px 26px;
  border-bottom: ${props => props.isLastItem ? 'none' : '1px solid rgba(255, 255, 255, 0.08)'};
  display: flex;
  align-items: center;

  &.checked {
    .status-container {
      border-radius: 50%;
      background-color: ${colors.lightGreen};
      i {
        color: white;
      }
    }
  }

  .status-container {
    width: 40px;
    height: 40px;
    padding: 10px;

    i {
      font-size: 22px;
      color: rgba(255, 255, 255, 0.2);
      letter-spacing: -0.53px;
    }
  }

  .list-item-container {
    margin-left: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-container {
      font-size: 20px;
      font-weight: 500;
      color: white;
      line-height: 26px;
      letter-spacing: 0;
    }

    .btn-more {
      cursor: pointer;
      i {
        font-size: 26px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.2);
        line-height: 22px;
        letter-spacing: -0.62px;
      }
    }
  }
`;
