import React from 'react';
import PropTypes from 'prop-types';
import { DrawerDeleteMenuContainerStyled } from './style';
import DrawerDeleteMenu from '../DrawerDeleteMenu';

function DrawerDeleteMenuContainer({ visible, onClose }) {
  return (
    <DrawerDeleteMenuContainerStyled
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      <DrawerDeleteMenu visible={true} onClose={onClose} />
    </DrawerDeleteMenuContainerStyled>
  );
}

DrawerDeleteMenuContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DrawerDeleteMenuContainer;
