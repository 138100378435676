import React from 'react';
import PropTypes from 'prop-types';
import { AuthLayoutContainer } from './style';

export function AuthLayout({ children, onBackIconClick = () => {} }) {
  return (
    <AuthLayoutContainer>
      <div className='left-side'>
        <div className='top-icon-container'>
          <div className='back-icon' onClick={onBackIconClick}>
            <i className='fas fa-chevron-left' />
          </div>
        </div>
        <div className='auth-control-container'>{children}</div>
      </div>
      <div className='right-side' />
    </AuthLayoutContainer>
  );
}

AuthLayout.propTypes = {
  onBackIconClick: PropTypes.func,
  children: PropTypes.any,
};
