import styled from '@emotion/styled';
import { colors } from 'theme';

export const SingleMonthCalendarContainer = styled.div`
  background-color: transparent;

  .calendar-container {
    border-top: transparent;
    border-color: transparent;
    background-color: transparent;
    padding-left: 18px;
    padding-right: 18px;

    .calendar-month {
      background-color: transparent;
      border-bottom: transparent;
      width: 100%;
      padding-left: 18px;
      padding-right: 18px;
      display: flex;
      align-items: center;
      font-size: 17px;
      font-weight: bold;
      color: white;

      .change-month-icon {
        cursor: pointer;
        i {
          font-size: 17px;
          color: white;
        }

        &.previous {
          margin-right: auto;
        }

        &.next {
          margin-left: auto;
        }
      }
    }

    .calendar-core {
      background-color: transparent;

      .calendar-head {
        th {
          text-align: center;
          color: rgba(255, 255, 255, 0.5);
          border-color: transparent;
        }
      }

      .calendar-body {
        tr {
          td {
            padding: 0px;
            font-size: 14px;
            font-weight: bold;
            color: white;
            text-align: center;
            border: 1px solid transparent;

            .empty-day {
              width: 48px;
              height: 52px;
            }

            .regular-day {
              cursor: pointer;
              width: 48px;
              height: 52px;
              padding: 14px 0px;
              border: 1.5px solid transparent;
              border-radius: 20px;

              &.today,
              &:hover {
                border: 1.5px solid ${colors.redColor};
              }

              &.user-day {
                background-color: ${colors.redColor};
              }

              &.closed-day {
                position: relative;
                &:after {
                  content: '';
                  display: block;
                  width: 5px;
                  height: 5px;
                  border-radius: 50%;
                  background-color: ${colors.redColor};
                  position: absolute;
                  bottom: 8px;
                  left: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
