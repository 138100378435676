import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrinkRejectItemContainer = styled(SingleDrawer)`
  padding: 0;
  background-color: ${colors.gray2};
  font-size: 14px;
  color: white;
  overflow-y: hidden;

  .drink-confirm-wrapper {
    width: 100%;
    border-radius: 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #27282a;
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }

  .drink-confirm-heading {
    padding: 30px 0 0 0;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }

  .drink-confirm-delivered {
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    padding: 0 7px 0 7px;
  }

  .drink-confirm-customer-bill {
    margin: 29px 0 13px 0;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
  }

  .drink-confirm-btn-wrapper {
    /* padding: 0 20px 35px 20px; */

    padding: 0 20px 22px 20px;
  }

  .drink-confirm-btn {
    width: 100%;
    border: none;
    background: #ed2626;
    color: #ffffff;
    height: 47px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
  }

  .drink-keep-btn {
    width: 100%;
    border: none;
    background: #61646c;
    color: #ffffff;
    height: 47px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
  }

  .drink-confirm-goback-btn-wrapper {
    margin: 0 0 35px 0px;
  }

  .drink-confirm-goback-btn {
    text-align: center;
    color: #ed2626;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
`;
