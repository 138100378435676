import styled from '@emotion/styled';

export const SettingsContainer = styled.div`
  width: 100%;
  padding-left: 26px;
  padding-right: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .settings-option-container {
    width: 100%;
    padding-bottom: 9px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .settings-option-description-container {
      margin-top: 10px;
      margin-left: 2px;
      margin-right: 1px;
      display: flex;
      flex-direction: column;
      align-items: left;

      .settings-title {
        font-size: 14px;
        letter-spacing: 0;
        color: white;
      }

      .settings-description {
        font-size: 13px;
        color: rgba(255, 255, 255, 0.5);
        line-height: 22px;
        letter-spacing: -0.31px;
      }
    }
  }
`;
