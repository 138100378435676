import styled from '@emotion/styled';

import { OopsAddingFailed } from 'assets/images/menu';

export const NoOrdersPlaceholderContainer = styled.div`
  width: 100%;
  padding: 190px 0px 360px 348px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .no-orders {
    margin-bottom: 35px;
    width: 412px;
    height: 254px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${OopsAddingFailed});
  }
`;
