import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ConfirmContainer } from './style';
import Button from 'react-bootstrap/Button';
import { DrinkCompletedAlert } from './DrinkCompletedAlert';
import { modalContext } from 'routes';

export function DrinkConfirmRequest({ visible, onClose }) {
  const context = useContext(modalContext);

  function DrinkConfirmFunc() {
    context.setModal('DrinkRefillModal-2');
  }

  return (
    <ConfirmContainer visible={visible} placement='right' onClose={onClose}>
      <>
        {context.modal === 'DrinkRefillModal-1' ? (
          <>
            <div className='drink-confirm-wrapper animate__animated animate__fadeInUp'>
              <div>
                <p className='drink-confirm-heading'>Complete Request</p>
              </div>

              <div>
                <p className='drink-confirm-delivered'>
                  Has the drink refill request been delivered to the customer’s
                  table?
                </p>
              </div>

              <div>
                <p className='drink-confirm-customer-bill'>
                  $11.99 will be added to the customer&apos;s bill.
                </p>
              </div>

              <div className='drink-confirm-btn-wrapper'>
                <Button
                  variant='none'
                  className='drink-confirm-btn'
                  onClick={DrinkConfirmFunc}
                >
                  Yes, complete request
                </Button>
              </div>

              <div className='drink-confirm-goback-btn-wrapper'>
                <p
                  className='drink-confirm-goback-btn'
                  onClick={onClose}
                  aria-hidden
                >
                  Go back
                </p>
              </div>
            </div>
          </>
        ) : (
          <DrinkCompletedAlert onClose={onClose} />
        )}
      </>
    </ConfirmContainer>
  );
}

DrinkConfirmRequest.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
