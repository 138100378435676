import React from 'react';
import PropTypes from 'prop-types';
import { DrawerDeleteMenuFirstStepContainerStyled } from './style';
import { DrawerDeleteMenuFirstStep } from './DrawerDeleteMenuFirstStep';

export function DrawerDeleteMenuFirstStepContainer({ visible, onClose }) {
  return (
    <DrawerDeleteMenuFirstStepContainerStyled
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      <DrawerDeleteMenuFirstStep visible={true} onClose={onClose} />
    </DrawerDeleteMenuFirstStepContainerStyled>
  );
}

DrawerDeleteMenuFirstStepContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
