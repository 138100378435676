import React from 'react';
import PropTypes from 'prop-types';
import { SingleAntImageContainer } from './style';

export default function SingleAntImage(props) {
  const {
    className = '',
    src,
    height = 'auto',
    width,
    style,
    borderRadius = '0px',
    // preview = true,
  } = props;

  return (
    <SingleAntImageContainer
      className={className}
      width={width}
      height={height}
      src={src}
      style={{ ...style, borderRadius }}
      // preview={preview}
      // fallback={FeastttMark}
      // placeholder={
      //   <Image
      //     src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
      //     width={width}
      //   />
      // }
    />
  );
}

SingleAntImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  style: PropTypes.object,
  borderRadius: PropTypes.string,
  preview: PropTypes.bool,
};
