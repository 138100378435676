import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import SingleAntSwitch from 'components/SingleAntSwitch';
// import { restaurantServices } from 'services/api-services';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
// import { AuthContext } from 'contexts/AuthContextContainer';
// import { showMessage } from 'services/functions';
import { TimePicker } from 'components/TimePicker';
import { DrawerBusinessHoursSetTimeWrapper } from './style';

const DrawerAvailabilityDayCustom = ({
    visible = false,
    placement = 'right',
    onClose = () => { },
}) => {
    const daysOfWeekObject = [
        { id: 0, day: "Monday" },
        { id: 1, day: "Tuesday" },
        { id: 2, day: "Wednesday" },
        { id: 3, day: "Thursday" },
        { id: 4, day: "Friday" },
        { id: 5, day: "Saturday" },
        { id: 6, day: "Sunday" }
    ];
    const { selectedDayOfWeek, setOpenDrawerAvailabilityDay } = useContext(StateContext);
    // const { restaurantProfile, setRestaurantProfile } = useContext(DataContext);
    const { selectedMenu, setSelectedMenu } = useContext(DataContext);
    // const { restaurantId } = useContext(AuthContext);
    const [isOpen, setOpen] = useState(true);
    const [isAllDay, setAllDay] = useState(false);
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();

    function setDayTime() {
        if (isOpen) {
            const timezone = new Date().getTimezoneOffset() / 60 * (-1);
            const workingHours = {
                day: daysOfWeekObject.find((x) => x.day === selectedDayOfWeek).id,
                times: isAllDay ? [[0, 86400]] : [[startTime, endTime]],
                timezone: `GMT${timezone > 0 ? '+' : ''}${timezone}`
            };
            const newData = {
                ...selectedMenu,
                availability: [
                    ...selectedMenu?.availability?.filter(x => x.day !== workingHours.day),
                    workingHours
                ]
            };
            setSelectedMenu(newData);
            setOpenDrawerAvailabilityDay(false);
        } else {
            const newData = {
                ...selectedMenu,
                availability: [
                    ...selectedMenu.availability.filter(x => x.day !== daysOfWeekObject.find((x) => x.day === selectedDayOfWeek).id)
                ]
            };
            setSelectedMenu(newData);
            setOpenDrawerAvailabilityDay(false);
        }
    }

    return (
        <DrawerBusinessHoursSetTimeWrapper
            onClose={onClose}
            visible={visible}
            placement={placement}
        >
            <div className="configure-business-hours-panel">
                <div className="panel-header-container">
                    <div className="back-arrow" onClick={onClose}>
                        <i className="fas fa-chevron-left" />
                    </div>
                    <div className="panel-header-context">
                        {selectedDayOfWeek}
                    </div>
                </div>
                <div className="panel-status-container">
                    <div className="open-control-container">
                        <div className="is-open-container">
                            <div className="left-part">
                                <p className={`${isOpen ? 'is-open' : ''}`}>Available</p>
                            </div>
                            <div className="right-part">
                                <SingleAntSwitch checked={isOpen} onChange={() => setOpen(!isOpen)} />
                                {/* <Switch defaultChecked onChange={setOpen} /> */}
                            </div>
                        </div>

                        <div className="is-open-container">
                            <div className="left-part">
                                <p className={`${isAllDay ? 'is-open' : ''}`}>All Day</p>
                            </div>
                            <div className="right-part">
                                <SingleAntSwitch checked={isAllDay} onChange={() => setAllDay(!isAllDay)} />
                                {/* <Switch onChange={setAllDay} /> */}
                            </div>
                        </div>

                        {!isAllDay &&
                            <>
                                <p className="set-time-header">Set Your Time</p>
                                <div className="time-picker-wrapper">
                                    <TimePicker selectedTime={setStartTime} />
                                    <div className="arrow-icon-container">
                                        <i className="fas fa-long-arrow-alt-right" />
                                    </div>
                                    <TimePicker selectedTime={setEndTime} />
                                </div>
                            </>
                        }
                        <div className="button-container">
                            <button type="button" className="submit-button" onClick={() => setDayTime()}>Done</button>
                        </div>
                    </div>

                </div>
            </div>

        </DrawerBusinessHoursSetTimeWrapper>
    )
}

export default DrawerAvailabilityDayCustom;

DrawerAvailabilityDayCustom.propTypes = {
    visible: PropTypes.bool,
    placement: PropTypes.string,
    onClose: PropTypes.func,
};