import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerAddManagerWrapper = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  .add-manager-panel {
    font-family: Montserrat;
    height: 100%;
    position: relative;
    .panel-header-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      .back-arrow {
        width: 50px;
        cursor: pointer;
        position: absolute;
        left: 0;
        i {
          color: #ffffff;
          font-size: 22px;
        }
      }
      .panel-header-context {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .panel-status-container {
      margin-bottom: 20px;
      margin-top: 70px;
      .basic-field {
        padding: 10px 5px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        p {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.5);
          margin-bottom: 5px;
        }
        input {
          padding: 0;
          font-size: 14px;
          font-weight: 500;
          background-color: transparent;
          border: none;
          color: #fff;
          outline: none;
          ::placeholder {
            color: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }

    .button-container {
      margin-top: 320px;
      padding-bottom: 25px;
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      bottom: -23px;
      .button-general {
        border-radius: 8px;
        height: 50px;
        font-size: 14px;
        font-weight: 600;
        border: none;
        box-shadow: none;

        opacity: 1;
        -moz-transition: all 0.4s ease-out;
        -o-transition: all 0.4s ease-out;
        -webkit-transition: all 0.4s ease-out;
        -ms-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out;
      }
      .button-general:hover {
        opacity: 0.8;
      }
      .submit-button {
        background-color: rgba(255, 255, 255, 0.1);
        color: rgba(255, 255, 255, 0.4);
        margin-bottom: 10px;
        &.active {
          background-color: #ed2626;
          color: #ffffff;
        }
      }
      .cancel-button {
        background-color: transparent;
        color: #ed2626;
      }
    }
  }
`;
