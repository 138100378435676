import React from 'react';
import Dashboard from "../Dashboard";
import EmptyProfile from "../EmptyProfile";
 
export default function IsProfileWasFilled() {
    const profileWasFilled = true;
    if (profileWasFilled) {
        return <Dashboard />
    } else {
        return <EmptyProfile />
    }
}