import React, { useContext } from 'react'
import PropTypes from 'prop-types';
// import './style.scss';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerInviteSentContainer } from './style';
import { colors } from 'theme';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';

export default function DrawerInviteSent({
  visible = false,
  placement = 'bottom',
}) {
  const { setStaffToAdd } = useContext(DataContext);
  const { setOpenDrawerInviteSent, setOpenDrawerInviteStaff, setOpenDrawerRoles } = useContext(StateContext);

  function onClose() {
    setOpenDrawerInviteSent(false);
    setOpenDrawerInviteStaff(false);
    setOpenDrawerRoles(false);
    setStaffToAdd();
  }

  return (
    <DrawerInviteSentContainer
      placement={placement}
      onClose={() => onClose()}
      height={219}
      visible={visible}
    >
      <Box is='div' display='flex' flexDirection='column' alignItems='flex-start' marginBottom={31}>
        <SingleText content='Invite Sent' size={22} weight={500} marginBottom={10}>Invite Sent</SingleText>
        <SingleText content='Their account will be pending until they accept the invite.' size={14} lineHeight={1.5} spacing='0.27px'>Their account will be pending until they accept the invite.</SingleText>
      </Box>
      <Box is='div' display='flex' flexDirection='column'>
        <SingleButton
          title='Okay'
          btnColor={colors.redColor}
          txtColor='white'
          margin='0 0 20px'
          onClick={() => onClose()}
        />
      </Box>
    </DrawerInviteSentContainer>
  )
}

DrawerInviteSent.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
};
