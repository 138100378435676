import styled from '@emotion/styled';

export const CompletedAlertReplaceItemContainer = styled.div`
  .return-replaceitem-alert-wrapper {
    width: 100%;
    margin: 35px 0 0;
    padding: 72px 26px 33px;
    border-radius: 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #27282a;
    position: absolute;
    bottom: 0px;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
  }

  .return-replaceitem-alert-svg-position {
    position: relative;
    bottom: 118px;
    text-align: center;
  }

  .return-replaceitem-alert-check-wrapper {
    text-align: center;
  }

  .return-replaceitem-alert-check {
    background-image: linear-gradient(316deg, #5ff7c0 96%, #1fc086 8%);
    font-size: 26px;
    border-radius: 64px;
    padding: 19px;

    position: relative;
    bottom: 200px;
  }

  .return-replaceitem-alert-body-wrapper {
    margin: -142px 0 12px 0;
  }

  .return-replaceitem-alert-text-style {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }

  .return-replaceitem-alert-btn-center {
    padding-top: 32px;
  }

  .return-replaceitem-alert-btn-style {
    width: 100%;
    border: none;
    background: #ed2626;
    color: #ffffff;
    height: 47px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
  }

  .return-replaceitem-alert-btn-text {
    background: #ed2626;
    border: none;
    width: 20rem;
    position: relative;
    height: 44px;
  }
`;
