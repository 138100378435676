import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { OtherCompletedAlertContainer } from './style';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';

export function OtherCompletedAlert({ onClose }) {
  const context = useContext(modalContext);

  return (
    <OtherCompletedAlertContainer>
      {context.modal === 'otherRequestModal-2' ? (
        <div className='other-completed-alert-wrapper animate__animated animate__fadeInUp'>
          <div className='other-completed-svg-position'>
            <svg height='100' width='100'>
              <circle cx='50' cy='50' r='40' strokeWidth='3' fill='#27282a' />
            </svg>
          </div>

          <div className='other-completed-check-wrapper'>
            <i className='far fa-check other-completed-check'></i>
          </div>

          <div className='other-completed-body-wrapper'>
            <div>
              <p className='other-completed-text-style'>Request Completed</p>
            </div>

            <div className='other-completed-btn-center'>
              <Button
                variant='none'
                className='other-completed-btn-style'
                onClick={onClose}
              >
                Okay
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </OtherCompletedAlertContainer>
  );
}

OtherCompletedAlert.propTypes = {
  onClose: PropTypes.func,
};
