import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'theme';
import { FoodNutritionDetailContainer } from './style';
export function FoodNutritionDetail({
  className,
  isHaveBottomBorder,
  label,
  percentages,
  digit,
  unit,

}) {
  return (
    <FoodNutritionDetailContainer
      className={`${className} food-nutrition-detail ${
        isHaveBottomBorder ? 'bottom-border' : ''
      }`}
    >
      <div className='nutrition-wrapper'>
        <div>
          <p>
            <span style={{ fontWeight: '600' }}>{label}</span>{' '}
            <span style={{ color: `${colors.lightGray2}` }}>
              {digit}
              {unit}
            </span>
          </p>
        </div>

        <div>
          <p>{percentages}</p>
        </div>
      </div>
    </FoodNutritionDetailContainer>
  );
}

FoodNutritionDetail.propTypes = {
  className: PropTypes.string,
  isHaveBottomBorder: PropTypes.bool,
  children: PropTypes.any,

  label: PropTypes.string,
  labelSuffix: PropTypes.object,
  value: PropTypes.object,
  additionalDescription: PropTypes.string,

  percentages: PropTypes.string,
  digit: PropTypes.string,
  unit: PropTypes.string
};
