import React, { useContext } from 'react';
import { CompletedOrdersContainer } from './style';
import { splitArrayIntoChunks } from 'services/functions';
import { CompletedOrdersContext } from 'contexts/CompletedOrdersContextContainer';
import { OrderTable } from '../OrderTable';

export function CompletedOrders() {
  const {
    isActiveOrder,
    setupActiveOrder,
    clearActiveOrder,
    showFilteredOrders,
  } = useContext(CompletedOrdersContext);

  const onChooseOrder = (order) => {
    clearActiveOrder();
    setupActiveOrder(order);
  };

  const filteredOrders = showFilteredOrders();

  return (
    <CompletedOrdersContainer className='orders-tables-section'>
      {filteredOrders.length ? (
        splitArrayIntoChunks(filteredOrders, 2).map(
          (ordersGroupUnit, index) => (
            <div className='order-tables-section-unit' key={index}>
              {ordersGroupUnit.map((completedOrder, index) => (
                <OrderTable
                  completedOrder={completedOrder}
                  onClick={onChooseOrder}
                  key={index}
                  isActive={isActiveOrder(completedOrder)}
                />
              ))}
            </div>
          )
        )
      ) : (
        <div className='no-completed-orders-to-show' />
      )}
    </CompletedOrdersContainer>
  );
}
