import React from 'react';
import PropTypes from 'prop-types';
import { DrawerEditFoodItemFromOrderContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { ParamControl } from './ParamControl';
import { SpecialInstructions } from './SpecialInstructions';
import { AmountControl } from './AmountControl';

export function DrawerEditFoodItemFromOrder({
  visible,
  onClose,
  foodItem = {},
  decreaseTotal = () => {},
  increaseTotal = () => {},
  changeItemAddons = () => {},
  changeItemDressing = () => {},
  changeItemSize = () => {},
  updateFoodItem = () => {},
}) {
  const onSave = () => {
    updateFoodItem(foodItem);
    onClose();
  };

  return (
    <DrawerEditFoodItemFromOrderContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      <div className='close-icon' onClick={onClose}>
        <i className='fas fa-chevron-left' />
      </div>
      <SingleText
        spacing='0.27px'
        color={colors.lightGray2}
        marginBottom={8}
        weight={600}
      >
        Edit Item
      </SingleText>
      <SingleText
        size={22}
        lineHeight='33px'
        spacing='0'
        marginBottom={29}
        width='270px'
      >
        {foodItem.name}
      </SingleText>
      <ParamControl
        title='Dressing'
        options={[['Ranch'], ['French'], ['Italian']]}
        selectedOptions={foodItem.dressing}
        onChange={changeItemDressing}
      />
      <ParamControl
        title='Add-ons'
        options={[
          ['Bacon', 1],
          ['Avocado', 2],
          ['Shrimp', 2],
        ]}
        selectedOptions={foodItem.addons}
        onChange={changeItemAddons}
        isOptional
      />
      <ParamControl
        title='Size'
        options={[['Mini'], ['Main'], ['Hearty']]}
        selectedOptions={foodItem.size}
        onChange={changeItemSize}
        isOptional
      />
      <SpecialInstructions instructions={foodItem.specialInstructions} />
      <AmountControl
        total={foodItem.total}
        decreaseTotal={decreaseTotal}
        increaseTotal={increaseTotal}
      />
      {foodItem.addons && foodItem.addons.length ? (
        <div className='will-be-added-to-the-customer-bill'>
          $
          {foodItem.addons
            .reduce(
              (accumulator, [, currentPrice]) => accumulator + currentPrice,
              0
            )
            .toFixed(2) * (foodItem.total || 0)}{' '}
          will be added to the customer`s bill.
        </div>
      ) : (
        ''
      )}
      <SingleButton title='Save' onClick={onSave} margin='0px 0px 20px 0px' />
      <SingleButton
        title='Cancel'
        btnColor='transparent'
        txtColor={colors.redColor}
        onClick={onClose}
      />
    </DrawerEditFoodItemFromOrderContainer>
  );
}

DrawerEditFoodItemFromOrder.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  foodItem: PropTypes.any,
  decreaseTotal: PropTypes.func,
  increaseTotal: PropTypes.func,
  changeItemAddons: PropTypes.func,
  changeItemDressing: PropTypes.func,
  changeItemSize: PropTypes.func,
  updateFoodItem: PropTypes.func,
};
