import React, { useContext } from 'react';
import { OrdersIconContainer } from './style';
import { StateContext } from 'contexts/StateContextContainer';

export function OrdersIcon() {
  const { pendingOrdersTotal } = useContext(StateContext);

  if (!pendingOrdersTotal) {
    return '';
  }

  return <OrdersIconContainer>{pendingOrdersTotal}</OrdersIconContainer>;
}
