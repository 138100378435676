import { useState } from 'react';

export const useCustomTimeRange = () => {
  const [customTimeRange, setCustomTimeRange] = useState([
    undefined,
    undefined,
  ]);
  const changeCustomTimeRange = (timeRange) => setCustomTimeRange(timeRange);

  const onClearTimeRange = () => {
    setCustomTimeRange([undefined, undefined]);
  };

  const isRangeActive = (timeRange = customTimeRange) =>
    timeRange.length === 2 && timeRange.every((rangePoint) => rangePoint);

  const isPartOfRange = (time, timeRange = customTimeRange) => {
    const [startRange, endRange] = timeRange;

    return time >= startRange && time <= endRange;
  };

  return {
    customTimeRange,
    changeCustomTimeRange,
    onClearTimeRange,
    isRangeActive,
    isPartOfRange,
  };
};
