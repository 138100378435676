import React from 'react'
import PropTypes from 'prop-types';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerDeleteWallContainer } from './style';

export default function DrawerDeleteWall({
  onRemove = () => {},
  onKeep = () => {},
}) {

  return (
      <DrawerDeleteWallContainer
        placement="bottom"
        height='fit-content'
        onClose={() => onKeep()}
        visible={true}
      >
        <Box is='div' display='flex' flexDirection='column' alignItems='flex-start' marginBottom={42}>
          <SingleText size={22} weight={500} marginBottom={10}>Remove Wall</SingleText>
          <SingleText lineHeight={1.5} spacing='0.27px' marginBottom={10}>Are you sure you want to remove this wall ?</SingleText>
        </Box>
        <Box is='div' display='flex' flexDirection='column'>
          <SingleButton 
            title='Remove' 
            margin='0 0 20px'
            onClick={onRemove}
          />
          <SingleButton 
            title='Keep' 
            btnColor='#61646c'
            txtColor='white'
            margin='0 0 20px'
            onClick={onKeep} 
          />
        </Box>
      </DrawerDeleteWallContainer>
  )
}

DrawerDeleteWall.propTypes = {
  onRemove: PropTypes.func,
  onKeep: PropTypes.func,
};
