import styled from '@emotion/styled';
import { colors } from 'theme';

export const PaymentWayContainer = styled.div`
  cursor: pointer;
  width: 100%;
  margin-top: 15px;
  height: 85px;
  padding: 31px 30px 33.5px 26px;
  border-radius: 16px;
  border: 1px solid ${colors.gray11};
  background-color: ${colors.gray2};
  display: flex;
  justify-content: space-between;

  &.no-title {
    margin-bottom: 210px;
  }

  &.active {
    border: 2px solid ${colors.redColor};
  }

  .title-container {
    .title {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.34px;
      color: white;
      height: 18px;
    }
  }

  .icon-container {
    i {
      font-size: 14px;
      letter-spacing: -0.44px;
      color: white;
    }
  }
`;
