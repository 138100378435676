import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types';
// import './style.scss';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { showMessage } from 'services/functions';
import { restaurantServices } from 'services/api-services';
import { StateContext } from 'contexts/StateContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { DrawerMoveItemsContainer } from './style';
import { colors } from 'theme';

// export const categoryOptions = [
//   {key: '1', title: 'Appetizers'},
//   {key: '2', title: 'Soups & Salads'},
//   {key: '3', title: 'Pastas'},
//   {key: '4', title: 'Steaks & Ribs'},
// ];

export default function DrawerMoveItems({
  visible = false,
  placement = 'bottom',
  onClose = () => { },
}) {

  const {
    allCategories, setOpenDrawerMoveCategoryItems, setOpenDrawerDeleteCategory
  } = useContext(StateContext);
  const { restaurantId } = useContext(AuthContext);
  const { chosenCategory, setChosenCategory } = useContext(DataContext);
  const [option, setOption] = useState('');

  const onClickMove = async () => {
    const { ok, data, errMessage } = await restaurantServices.getFoodsByCategory({ restaurantId, categoryId: chosenCategory.id });
    if (ok) {
      const foodsToMove = data.map(x => x.id);

      const moveFood = await restaurantServices.moveFoodToOtherCategory({ restaurantId, categoryId: chosenCategory.id }, { to_category_id: option, food_ids: [...foodsToMove] });
      if (moveFood.ok) {
        const deleteCategory = await restaurantServices.deleteCategory({ restaurantId, categoryId: chosenCategory.id });
        if (deleteCategory.ok) {
          setOpenDrawerMoveCategoryItems(false);
          setOpenDrawerDeleteCategory(false);
          setChosenCategory();
        } else {
          showMessage(deleteCategory.errMessage, 'error')
        }
      } else {
        showMessage(moveFood.errMessage, 'error')
      }
    } else {
      showMessage(errMessage, 'error')
    }
  }

  return (
    <DrawerMoveItemsContainer
      placement={placement}
      onClose={onClose}
      height='fit-content'
      visible={visible}
    >
      <Box display='flex' flexDirection='column' alignItems='flex-start' marginBottom={30}>
        <SingleText size={22} weight={500} marginBottom={10}>Move items to</SingleText>
      </Box>
      <Box className='drawer-move--options'>
        {allCategories.map((category, _i) => {
          if (chosenCategory?.id !== category.id) {
            return (
              <Box key={_i} className={`drawer-move--option ${category.id === option ? 'active' : 'inactive'}`} cursor="pointer" marginBottom={15} onClick={() => setOption(category.id)}>
                <SingleText size={14} weight={500} spacing='-0.34'>{category.name}</SingleText>
              </Box>
            )
          }
          return '';
        })}
      </Box>
      <Box display='flex' flexDirection='column'>
        <SingleButton
          title='Apply'
          margin='0 0 20px'
          onClick={() => option ? onClickMove() : showMessage('Chose Category first', 'error')}
        />
        <SingleButton
          title='Reset'
          btnColor='transparent'
          txtColor={colors.redColor}
          margin='0 0 20px'
          onClick={onClose}
        />
      </Box>
    </DrawerMoveItemsContainer>
  )
}

DrawerMoveItems.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickMove: PropTypes.func,
};
