import { usePeriod } from './usePeriod';
import { useCustomTimeRange } from './useCustomTimeRange';

/**
 * Here `usePeriod` and `useCustomTimeRange`
 * was combined in one hook because period
 * value option `Custom` it is like empty value,
 * not period value - in case when `Custom` is selected
 * main value is `customTimeRange`.
 * */
export const useSwitchPeriod = () => {
  const {
    selectedPeriod,
    changeSelectedPeriod,
    isThisPeriodEqualToSelectedPeriod,
  } = usePeriod();

  const {
    customTimeRange,
    changeCustomTimeRange,
    onClearTimeRange,
    isRangeActive,
    isPartOfRange,
  } = useCustomTimeRange();

  const onSelect = (newPeriod) => {
    if (!isThisPeriodEqualToSelectedPeriod(newPeriod)) {
      changeSelectedPeriod(newPeriod);
    }
  };

  return {
    selectedPeriod,
    onSelect,
    isThisPeriodEqualToSelectedPeriod,

    customTimeRange,
    changeCustomTimeRange,
    onClearTimeRange,
    isRangeActive,
    isPartOfRange,
  };
};
