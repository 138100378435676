import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FoodControlContainerStyled } from './style';
import { ControlItem } from './ControlItem';
import { DrawerFoodReview } from 'pages/Orders/CompletedComponent/CompletedNotify/CompletedNotification/DrawerFoodReview';

export function FoodControlContainer({
  rating,
  reviewsCount,
  customizations,
  onOpenDrawerCustomizations,
}) {
  // for test
  // console.log(reviewsCount, 'food-review-count-props');

  const [
    openDrawerFoodReviewForNewItemInOrder,
    setOpenDrawerFoodReviewForNewItemInOrder,
  ] = useState(false);
  const onOpenDrawerFoodReviewForNewITemInOrder = () =>
    setOpenDrawerFoodReviewForNewItemInOrder(true);
  const onCloseDrawerFoodReviewForNewITemInOrder = () =>
    setOpenDrawerFoodReviewForNewItemInOrder(false);

  return (
    <FoodControlContainerStyled>
      {[
        {
          iconMainTitle: <i className='fas fa-star' />,
          mainTitle: rating,
          valueWithLabel: `${reviewsCount} reviews`,
          onClick: reviewsCount
            ? onOpenDrawerFoodReviewForNewITemInOrder
            : () => {},
        },
        {
          mainTitle: 'Customize',
          valueWithLabel: `${customizations} options`,
          onClick: customizations ? onOpenDrawerCustomizations : () => {},
        },
      ].map(({ iconMainTitle, mainTitle, valueWithLabel, onClick }, index) => (
        <ControlItem
          iconForMainTitle={iconMainTitle}
          mainTitle={mainTitle}
          valueWithLabel={valueWithLabel}
          onClick={onClick}
          key={mainTitle + valueWithLabel + index}
        />
      ))}
      {/**
       * @TODO Make decision about DrawerFoodReview ->
       * Now it used 2 times in different places and we should decide:
       * - keep ?
       * - or make single drawer with logic but it will be single and one instance
       * */}
      <DrawerFoodReview
        totalReviews={reviewsCount}
        visible={openDrawerFoodReviewForNewItemInOrder}
        onClose={onCloseDrawerFoodReviewForNewITemInOrder}
      />
    </FoodControlContainerStyled>
  );
}

FoodControlContainer.propTypes = {
  rating: PropTypes.any,
  reviewsCount: PropTypes.number,
  customizations: PropTypes.number,
  onOpenDrawerCustomizations: PropTypes.func,
};
