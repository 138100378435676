import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

import { BankAccountAdded } from 'assets/images/bankAccount';

export const DrawerBankAccountAddedContainer = styled(SingleDrawer)`
  height: 570px !important;
  padding: 70px 26px 33px;
  background-color: ${colors.gray5};
  display: flex;
  flex-direction: column;
  align-items: center;

  .success-added-image {
    width: 297px;
    height: 208px;
    padding-left: 23px;
    padding-right: 23px;
    margin-bottom: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${BankAccountAdded});
  }

  .check-icon {
    margin-bottom: 14px;
    i {
      font-size: 22px;
      letter-spacing: 0.42px;
      color: ${colors.lightGreen};
    }
  }
`;
