import { Geometry } from './Geometry';
import { TableSize, TableStatus } from './Table';

export class Tables {
  static geometry = new Geometry();

  static createTableSize(table) {
    // console.log("createTableSize", table)
    return new TableSize(table);
  }

  static createTableStatus(table) {
    return new TableStatus(table);
  }
}
