import React from 'react';
import PropTypes from 'prop-types';
import { ActiveContainer } from './style';
import { ActiveOrders } from './ActiveOrders';
import { ActiveNotificationsList } from './ActiveNotificationsList';
import { DrawerProcessPayment } from './DrawerProcessPayment';
import { DrawerApplyDiscountContainer } from './DrawerApplyDiscountContainer';

export function Active({
  openDrawerDeleteOrderContainer,
  setOpenDrawerDeleteOrderContainer,
  openDrawerApplyDiscountContainer,
  onCloseDrawerApplyDiscountContainer,
  openDrawerProcessPayment,
  onCloseDrawerProcessPayment,
}) {
  const onCloseDrawerDeleteOrderContainer = () =>
    setOpenDrawerDeleteOrderContainer(false);

  return (
    <ActiveContainer>
      <ActiveOrders />
      <ActiveNotificationsList
        openDrawerDeleteOrderContainer={openDrawerDeleteOrderContainer}
        onCloseDrawerDeleteOrderContainer={onCloseDrawerDeleteOrderContainer}
      />
      <DrawerProcessPayment
        visible={openDrawerProcessPayment}
        onClose={onCloseDrawerProcessPayment}
      />
      <DrawerApplyDiscountContainer
        visible={openDrawerApplyDiscountContainer}
        onClose={onCloseDrawerApplyDiscountContainer}
      />
    </ActiveContainer>
  );
}

Active.propTypes = {
  openDrawerDeleteOrderContainer: PropTypes.bool,
  setOpenDrawerDeleteOrderContainer: PropTypes.func,
  openDrawerApplyDiscountContainer: PropTypes.bool,
  onCloseDrawerApplyDiscountContainer: PropTypes.func,
  openDrawerProcessPayment: PropTypes.bool,
  onCloseDrawerProcessPayment: PropTypes.func,
};
