import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { MovableLayoutContainer } from './style';
import { SingleMovable } from 'pages/Tables/components';

import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { Tables } from 'feasttt/domain';
import { DrawerCheckInErrorContainer } from '../DrawerCheckInErrorContainer';
import { computeWidthAndHeightOfScene, transformByDegrees } from 'pages/Tables/TableLayout';

export function MovableLayout({
  selectedFloor = { tables: [] },
}) {
  // @TODO changes code
  const { setOpenDrawerSelectServer } = useContext(StateContext);
  const { changeTableForCheckInData } = useContext(DataContext);

  const [openDrawerCheckInErrorContainer, setOpenDrawerCheckInErrorContainer] =
    useState(false);
  const [checkInError, setCheckInError] = useState("");

  const onClickTable = (_table, tableStatus) => {
    if (tableStatus.isAvailable()) {
      onChooseTable(_table);
      setCheckInError("")
    } else {
      setCheckInError("Can't check-in to an inactive table.")
      showWarningForUserIfHeSelectedWrongTable();
    }
  };

  const onChooseTable = (_table) => {
    changeTableForCheckInData(_table);
    onOpenDrawerSelectServer();
  };

  const onOpenDrawerSelectServer = () => setOpenDrawerSelectServer(true);

  const showWarningForUserIfHeSelectedWrongTable = () =>
    onOpenDrawerCheckInErrorContainer();

  // const createStatusTitleBasedOnCssClass = (status) => {
  //   const cssClassStatus = cssClassForCurrentTableStatus(status);
  //   return `${cssClassStatus.slice(0, 1).toUpperCase()}${cssClassStatus
  //     .slice(1)
  //     .toLowerCase()}`;
  // };

  // const cssClassForCurrentTableStatus = (status) => {
  //   if (status.isAvailable()) {
  //     return 'available';
  //   } else if (status.isOccupied()) {
  //     return 'occupied';
  //   } else {
  //     return 'inactive';
  //   }
  // };

  const onOpenDrawerCheckInErrorContainer = () =>
    setOpenDrawerCheckInErrorContainer(true);
  const onCloseDrawerCheckInErrorContainer = () =>
    setOpenDrawerCheckInErrorContainer(false);

  return (
    <MovableLayoutContainer>
      {Object.keys(selectedFloor).length >= 1 ? (
        <div className='tables'>

          <TransformWrapper
            wheel={{ disabled: true }}
            pan={{ disabled: true }}
            options={{
              limitToBounds: true,
            }}
          >
            <TransformComponent>
              <div className='internal-scene'>
                <div
                  className='scene-container'
                  style={{
                    ...computeWidthAndHeightOfScene(selectedFloor.tables, selectedFloor.walls),
                    ...transformByDegrees(0),
                  }}
                >
                  {/* Tables */}
                  {selectedFloor.tables.map((_table, _i) => {
                    const tableStatus = Tables.createTableStatus(_table);

                    return (
                      <div
                        key={_i}
                        onClick={() => onClickTable(_table, tableStatus)}>
                        <SingleMovable
                          key={_i}
                          className={`table_${_table.id}`}
                          tableOriginal={_table}
                          otherClassNames={[
                            ...selectedFloor.tables
                              .filter((_item) => _item.id !== _table.id)
                              .map((_item) => `table_${_item.id}`),
                            ...selectedFloor.walls.map((_item, i) => `walls-${i}`),
                          ]}
                          translate={[_table.x, _table.y]}
                          seats={_table.seats}
                          status={_table.status}
                          shape={_table.type === 0 ? 'circle' : 'square'}
                          position={_table.position}
                          tableName={
                            <div
                              className='table-name-movable-container'
                              aria-hidden='true'
                            >
                              <span className='table-name'>{`${_table.table_number}`}</span>
                              <span className='guests-and-seats-title'>
                                {_table.guests}/{_table.seats}
                              </span>
                              {/* <span>
                            {createStatusTitleBasedOnCssClass(tableStatus)}
                          </span> */}
                            </div>
                          }
                          tableId={_table.id}
                          isOccupied={!_table.is_available}
                          setPanDisabled={() => {}}
                          rotation={0}
                          editable={false}
                        />
                      </div>
                    );
                  })}

                  {selectedFloor.walls.map((_wall, i) => {
                    // Walls
                    const otherWallsArr = [
                      ...selectedFloor.walls
                        .map((_item, _i) => (i !== _i ? `walls-${_i}` : null))
                        .filter((_item) => _item !== null),
                    ];

                    return (
                      <SingleMovable
                        key={`${i}walls-movable`}
                        className={`walls-${i}`}
                        otherClassNames={[
                          ...otherWallsArr,
                          ...selectedFloor.tables.map(
                            (_item) => `table_${_item.id}`
                          ),
                        ]}
                        translate={[_wall.x, _wall.y]}
                        seats={null}
                        status={null}
                        position={
                          _wall.width > _wall.height ? 'horizontal' : 'vertical'
                        }
                        shape='wall'
                        wall={_wall}
                        tableName={null}
                        isOccupied={null}
                        setPanDisabled={() => {}}
                        rotation={0}
                      />
                    );
                  })}
                </div>
              </div>
            </TransformComponent>
          </TransformWrapper>
        </div>
      ) : (
        ''
      )}

      <DrawerCheckInErrorContainer
        visible={openDrawerCheckInErrorContainer}
        onClose={onCloseDrawerCheckInErrorContainer}
        onRetry={onCloseDrawerCheckInErrorContainer}
        errorMessage={checkInError}
      />
    </MovableLayoutContainer>
  );
}

MovableLayout.propTypes = {
  selectedFloor: PropTypes.object,
  onRetry: PropTypes.func,
};

export const computeClassNameBasedOnSeatsAndRoundedCase = (
  seats,
  isRounded,
  availableClassNames
) => {
  let mainClass = '';
  if (seats === 4) {
    mainClass = availableClassNames.fourTierTable;
  } else {
    mainClass = availableClassNames.eightTierTable;
  }

  if (isRounded) {
    if (seats === 4) {
      mainClass += ` ${availableClassNames.tableRounded}`;
    } else {
      mainClass = availableClassNames.eightTierTableRounded;
    }
  }

  return mainClass;
};
