import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { IoMdOptions } from 'react-icons/io';
import { MenuHeaderContainer } from './style';
import { showMessage } from 'services/functions';
import { DataContext } from 'contexts/DataContextContainer';
import { StateContext } from 'contexts/StateContextContainer';
import { colors } from 'theme';
import { useMenus, useFoods } from 'hooks';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import SingleAntSelect from 'components/SingleAntSelect';
import SingleSearchComponent from 'components/SingleSearchComponent';

export function MenuHeader({ setSearchFood, setIsMenusLoaded }) {
  const { menus, isMenusEditing, setMenusEditing } = useContext(DataContext);
  const { foods, setOpenDrawerEditMenus } = useContext(StateContext);

  const [foodType, setFoodType] = useState('Dietary');
  const [ratingType, setRatingType] = useState('Rating');

  const onChangeSearchFood = (newValue) => setSearchFood(newValue);
  const onClearSearchFood = () => setSearchFood('');

  const onClickEditMenus = () => setOpenDrawerEditMenus(true);

  useMenus({
    start() {
      setIsMenusLoaded(false);
    },
    end() {
      setIsMenusLoaded(true);
    },
  });

  useFoods();

  return (
    <MenuHeaderContainer>
      <SingleText size={24} weight='bold' marginRight='auto'>
        Menu
      </SingleText>
      <div className='btns-right-container'>
        <SingleSearchComponent
          placeholder='Enter...'
          options={foods.map(({ title }, index) => ({
            id: index,
            value: title,
          }))}
          onChangeSearch={onChangeSearchFood}
          onClearAfterSearch={onClearSearchFood}
        />

        <SingleButton
          className='btn-menu-header-options'
          shape='circle'
          width={50}
          height={40}
          txtColor='white'
          btnColor={colors.gray5}
          padding='11px 21px 0px'
          border='1px solid transparent'
          icon={<IoMdOptions color='white' fontSize={14} />}
          onClick={() => {}}
        />

        <SingleAntSelect
          className='menu-header-select'
          options={['Dietary', 'Dietary2', 'Dietary3'].map((item) => {
            return { label: item, value: item };
          })}
          value={{ label: foodType, value: foodType }}
          onChange={(selectedFoodType) => setFoodType(selectedFoodType)}
          width={115}
          iconClosed={<i className='fas fa-angle-down' />}
          placeholder='Select food type'
        />

        <SingleAntSelect
          className='menu-header-select'
          options={['Rating', 'Rating2', 'Rating3'].map((item) => ({
            label: item,
            value: item,
          }))}
          value={{ label: ratingType, value: ratingType }}
          onChange={(selectedRatingType) => setRatingType(selectedRatingType)}
          width={115}
          iconClosed={<i className='fas fa-angle-down' />}
          placeholder='Select rating type'
        />

        <SingleButton
          shape='circle'
          width={170}
          height={40}
          txtColor='white'
          btnColor={colors.redColor}
          title={isMenusEditing ? 'Done' : 'Edit menu'}
          onClick={() =>
            menus.length > 0
              ? setMenusEditing(!isMenusEditing)
              : showMessage('Please, add menu first.', 'error')
          }
        />
      </div>
    </MenuHeaderContainer>
  );
}

MenuHeader.propTypes = {
  setSearchFood: PropTypes.func,
  setIsMenusLoaded: PropTypes.func,
};
