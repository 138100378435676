import React, { useContext } from 'react';
import { ActiveOrdersContainer } from './style';
import { ActiveOrdersContext } from 'contexts/ActiveOrdersContextContainer';
import { ActiveOrder } from './ActiveOrder';
import { NoActiveOrders } from './NoActiveOrders';

export function ActiveOrders() {
  const {
    isEmptyOrdersPool,
    isActiveOrder,
    activeOrders,
    setupActiveOrder,
    clearActiveOrder,
  } = useContext(ActiveOrdersContext);

  const onChooseOrder = (userOrder) => {
    clearActiveOrder();
    setupActiveOrder(userOrder);
  };

  return (
    <ActiveOrdersContainer
      className={isEmptyOrdersPool() ? 'empty-active-orders' : ''}
    >
      {!isEmptyOrdersPool() ? (
        <>
          {activeOrders.map((order, index) => (
            <ActiveOrder
              order={order}
              onClick={() => onChooseOrder(order)}
              isActive={isActiveOrder(order)}
              key={order.id + index}
            />
          ))}
        </>
      ) : (
        <NoActiveOrders />
      )}
    </ActiveOrdersContainer>
  );
}
