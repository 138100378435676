import React, { useContext } from 'react';
import { RequestsIconContainer } from './style';
import { StateContext } from 'contexts/StateContextContainer';

export function RequestsIcon() {
  const { pendingRequestsTotal } = useContext(StateContext);

  if (!pendingRequestsTotal) {
    return '';
  }

  return <RequestsIconContainer>{pendingRequestsTotal}</RequestsIconContainer>;
}
