import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerAreYouSureNoPaymentContainerStyled } from './style';
import { DrawerAreYouSureNoPayment } from './DrawerAreYouSureNoPayment';

export function DrawerAreYouSureNoPaymentContainer({
  visible,
  onClose,
  onSendPayment = () => {},
}) {
  const [openDrawerAreYouSureNoPayment, setOpenDrawerAreYouSureNoPayment] =
    useState(false);
  const onOpenDrawerAreYouSureNoPayment = () =>
    setOpenDrawerAreYouSureNoPayment(true);
  const onCloseDrawerAreYouSureNoPayment = () =>
    setOpenDrawerAreYouSureNoPayment(false);

  const close = () => {
    onCloseDrawerAreYouSureNoPayment();
    onClose();
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerAreYouSureNoPayment();
    }
  }, [visible]);

  return (
    <DrawerAreYouSureNoPaymentContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerAreYouSureNoPayment
        visible={openDrawerAreYouSureNoPayment}
        onClose={close}
        onSendPayment={onSendPayment}
      />
    </DrawerAreYouSureNoPaymentContainerStyled>
  );
}

DrawerAreYouSureNoPaymentContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSendPayment: PropTypes.func,
};
