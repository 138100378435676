import React from 'react';
import PropTypes from 'prop-types';
import { DrawerRemoveDiscountContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerRemoveDiscount({ visible, onClose, onRemove }) {
  return (
    <DrawerRemoveDiscountContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='remove-discount-icon'>
        <i className='fas fa-trash' />
      </div>
      <SingleText
        className='remove-title'
        size={22}
        spacing='0.42px'
        align='center'
        width='329px'
        marginBottom={10}
      >
        Remove Discount
      </SingleText>
      <SingleText
        lineHeight='21px'
        align='center'
        spacing='0.27px'
        width='329px'
        marginBottom={31}
      >
        We`ll remove the discount from the customer`s bill.
      </SingleText>
      <SingleButton
        title='Remove'
        onClick={onRemove}
        margin='0px 0px 20px 0px'
      />
      <SingleButton
        title='Keep'
        btnColor={colors.lightGray}
        onClick={onClose}
      />
    </DrawerRemoveDiscountContainer>
  );
}

DrawerRemoveDiscount.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
};
