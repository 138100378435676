import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { OtherUnreasonableRequestAlertContainer } from './style';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';

export function OtherUnreasonableRequestAlert({ onClose }) {
  const context = useContext(modalContext);
  return (
    <OtherUnreasonableRequestAlertContainer>
      {context.modal === 'Reject-Other-unreasonableRequest-Modal-5' ? (
        <div className='reject-other-unreasonablerequest-wrapper animate__animated animate__fadeInUp'>
          <div className='reject-other-unreasonablerequest-svg-position'>
            <svg height='100' width='100'>
              <circle cx='50' cy='50' r='40' strokeWidth='3' fill='#27282a' />
            </svg>
          </div>

          <div className='reject-other-unreasonablerequest-check-wrapper'>
            <i className='far fa-times reject-other-unreasonablerequest-times'></i>
          </div>

          <div className='reject-other-unreasonablerequest-body-wrapper'>
            <div>
              <p className='reject-other-unreasonablerequest-text-style'>
                Return Rejected
              </p>
            </div>
            <Button
              variant='none'
              className='reject-other-unreasonablerequest-okay-btn'
              onClick={onClose}
            >
              Okay
            </Button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </OtherUnreasonableRequestAlertContainer>
  );
}

OtherUnreasonableRequestAlert.propTypes = {
  onClose: PropTypes.func,
};
