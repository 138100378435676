import styled from '@emotion/styled';
import { colors } from 'theme';

export const PersonalCheckingContainer = styled.div`
  background-color: ${colors.gray5};
  min-width: 335px;
  min-height: 320px;
  max-height: 320px;
  padding-top: 38px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -70px;

  &.empty-personal-checking {
    .bottom-border {
      bottom: -70px;
    }
  }

  .nickname-title {
    height: 70px;
    overflow-wrap: anywhere;
  }

  .bottom-border {
    width: 335px;
    position: relative;
    bottom: -48px;

    &:after {
      content: '';
      background: linear-gradient(-45deg, ${colors.gray2} 16px, transparent 0),
        linear-gradient(45deg, ${colors.gray2} 16px, transparent 0);
      background-position: left-bottom;
      background-repeat: repeat-x;
      background-size: 20px 30px;
      display: block;
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 32px;
    }
  }
`;
