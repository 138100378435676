import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { WaiterDescriptionContainer } from './style';
import { AuthContext } from 'contexts/AuthContextContainer';

import { DefaultUser } from 'assets/images/profile';

export function WaiterDescription() {
  const { authUser } = useContext(AuthContext);

  return (
    <WaiterDescriptionContainer>
      <div className='img-container'>
        <img
          src={authUser.avatar ? authUser.avatar.file : DefaultUser}
          alt=''
        />
      </div>
      <div className='content'>
        {authUser.last_name}{' '}
        {authUser.first_name ? authUser.first_name.charAt(0) : ''}
      </div>
    </WaiterDescriptionContainer>
  );
}

WaiterDescription.propTypes = {
  className: PropTypes.string,
};
