import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DrinkRejectItemContainer } from './style';
import Button from 'react-bootstrap/Button';
// import { DrinkCompletedAlert } from './DrinkCompletedAlert';
import { modalContext } from 'routes';
import { DrinkCompletedRejectItemAlert } from './RejectItemCompletedAlert';

export function DrinkRejectItem({ visible, onClose }) {
  const context = useContext(modalContext);

  function DrinkConfirmFunc() {
    context.setModal('DrinkRejectItemModal-2');
  }

  return (
    <DrinkRejectItemContainer
      visible={visible}
      placement='right'
      onClose={onClose}
    >
      <>
        {context.modal === 'DrinkRejectItemModal-1' ? (
          <>
            <div className='drink-confirm-wrapper animate__animated animate__fadeInUp'>
              <div>
                <p className='drink-confirm-heading'>Are you sure?</p>
              </div>

              <div>
                <p className='drink-confirm-delivered'>
                  The full drink refill request will be rejected. This cannot be undone.
                </p>
              </div>

              {/* <div>
                <p className='drink-confirm-customer-bill'>
                  $11.99 will be added to the customer&apos;s bill.
                </p>
              </div> */}

              <div className='drink-confirm-btn-wrapper'>
                <Button
                  variant='none'
                  className='drink-confirm-btn'
                  onClick={DrinkConfirmFunc}
                >
                  Yes, reject request
                </Button>
              </div>

              <div className='drink-confirm-btn-wrapper'>
                <Button
                  variant='none'
                  className='drink-keep-btn'
                  onClick={onClose}
                >
                  Keep
                </Button>
              </div>

              {/* <div className='drink-confirm-goback-btn-wrapper'>
                <p
                  className='drink-confirm-goback-btn'
                  onClick={onClose}
                  aria-hidden
                >
                  Keep
                </p>
              </div> */}
            </div>
          </>
        ) : (
          <DrinkCompletedRejectItemAlert onClose={onClose} />
        )}
      </>
    </DrinkRejectItemContainer>
  );
}

DrinkRejectItem.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
