import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { StateContext } from 'contexts/StateContextContainer';
import { DrawerBusinessHoursClosedDaysWrapper } from './style';
import DrawerAddDayContainer from './DrawerAddDayContainer';
import DrawerAddDayNameContainer from './DrawerAddDayNameContainer';
import DrawerDeleteDayContainer from './DrawerDeleteDayContainer';

const DrawerBusinessHoursClosedDays = ({
  visible = false,
  placement = 'right',
  onClose = () => {},
  closedDays = [],
  onUpdateClosedDays = () => {},
  onDeleteClosedDay = () => {},
}) => {
  const { editClosedDay, setEditClosedDay } = useContext(StateContext);

  const clearEditClosedDay = () => setEditClosedDay();

  const [openDrawerAddDayContainer, setDrawerAddDayContainer] = useState(false);
  const [openDrawerAddDayNameContainer, setDrawerAddDayNameContainer] =
    useState(false);

  const [openDrawerDeleteDayContainer, setDrawerDeleteDayContainer] =
    useState(false);
  const [selectClosedDay, setSelectClosedDay] = useState('');

  const changeSelectClosedDay = (date) => {
    setSelectClosedDay(date);
  };
  const clearSelectClosedDay = () => setSelectClosedDay('');

  const showDrawerAddDayNameContainer = () =>
    setDrawerAddDayNameContainer(true);
  const showDrawerDeleteDayContainer = () => setDrawerDeleteDayContainer(true);

  const closeDrawerAddDayContainer = () => setDrawerAddDayContainer(false);
  const closeDrawerAddDayNameContainer = () =>
    setDrawerAddDayNameContainer(false);

  function updateClosedDay(day) {
    setEditClosedDay(day);
    setDrawerAddDayNameContainer(true);
  }

  return (
    <DrawerBusinessHoursClosedDaysWrapper
      onClose={onClose}
      visible={visible}
      placement={placement}
    >
      <div className='closed-days-panel'>
        <div className='panel-header-container'>
          <div className='back-arrow' onClick={onClose}>
            <i className='fas fa-chevron-left' />
          </div>
          <div className='panel-header-context'>Closed Days</div>
          <div
            className='add-new-day'
            onClick={() => setDrawerAddDayContainer(true)}
          >
            <i className='fas fa-plus' />
          </div>
        </div>
        <div className='panel-status-container'>
          {closedDays.map((dayItem) => {
            const closedDate = new Date(dayItem.closed_date);
            const today = new Date();

            const closedDateMonth = closedDate.getMonth() + 1;
            const todayMonth = today.getMonth() + 1;

            const isToday =
              today.getDate() === closedDate.getDate() &&
              closedDateMonth === todayMonth;

            return (
              <div
                key={`${dayItem.id}closedDayId`}
                className='closed-day-item'
                onClick={() => updateClosedDay(dayItem)}
              >
                <div className='left-part'>
                  <p className='day-date'>
                    {`${isToday ? 'Today, ' : ''} ${closedDate.toLocaleString(
                      'default',
                      {
                        month: 'long',
                      }
                    )} ${closedDate.getDate()}`}{' '}
                    {dayItem.is_annual ? <span>Repeat Annually</span> : ''}
                  </p>
                  <p className='day-description'>{dayItem.name}</p>
                </div>
                <div className='right-part'>
                  <i className='fas fa-angle-right' />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <DrawerAddDayContainer
        visible={openDrawerAddDayContainer}
        onClose={() => setDrawerAddDayContainer(false)}
        closedDays={closedDays}
        selectClosedDay={selectClosedDay}
        changeSelectClosedDay={changeSelectClosedDay}
        clearSelectClosedDay={clearSelectClosedDay}
        showDrawerAddDayNameContainer={showDrawerAddDayNameContainer}
      />
      <DrawerAddDayNameContainer
        visible={openDrawerAddDayNameContainer}
        onClose={() => setDrawerAddDayNameContainer(false)}
        onUpdateClosedDays={onUpdateClosedDays}
        selectClosedDay={selectClosedDay}
        changeSelectClosedDay={changeSelectClosedDay}
        clearSelectClosedDay={clearSelectClosedDay}
        editClosedDay={editClosedDay}
        clearEditClosedDay={clearEditClosedDay}
        onOpenDrawerAddDayContainer={() => setDrawerAddDayContainer(true)}
        openDrawerDeleteDayContainer={showDrawerDeleteDayContainer}
      />
      <DrawerDeleteDayContainer
        visible={openDrawerDeleteDayContainer}
        onClose={() => setDrawerDeleteDayContainer(false)}
        editClosedDay={editClosedDay}
        onDeleteClosedDay={onDeleteClosedDay}
        closeDrawerAddDayContainer={closeDrawerAddDayContainer}
        closeDrawerAddDayNameContainer={closeDrawerAddDayNameContainer}
      />
    </DrawerBusinessHoursClosedDaysWrapper>
  );
};

export default DrawerBusinessHoursClosedDays;

DrawerBusinessHoursClosedDays.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  closedDays: PropTypes.array,
  onUpdateClosedDays: PropTypes.func,
  onDeleteClosedDay: PropTypes.func,
};
