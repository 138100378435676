import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerServerNotifiedContainer = styled(SingleDrawer)`
  position: relative;
  overflow: visible;
  background-color: ${colors.gray5};
  padding: 72px 26px 33px;
  height: 232px !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.order-ready {
    .was-notified-title {
      &:before {
        left: 95px;
      }
    }
  }

  &:before {
    content: '';
    display: block;
    width: 79px;
    height: 33px;
    background-color: transparent;
    position: absolute;
    top: -16.7px;
    left: 92px;
    border-right: 15px solid ${colors.gray5};
    border-bottom: 16px solid ${colors.gray5};
    border-bottom-right-radius: 51px;
  }

  &:after {
    content: '';
    display: block;
    width: 79px;
    height: 33px;
    background-color: transparent;
    position: absolute;
    top: -16.7px;
    right: 92px;
    border-left: 15px solid ${colors.gray5};
    border-bottom: 16px solid ${colors.gray5};
    border-bottom-left-radius: 51px;
  }

  .check-icon {
    z-index: 101;
    width: 68.5px;
    height: 68.5px;
    padding: 22px 21px;
    border-radius: 50%;
    background-image: linear-gradient(316deg, #5ff7c0 96%, #1fc086 8%);
    position: absolute;
    top: -37px;

    i {
      font-size: 26px;
      letter-spacing: 0.46px;
      color: white;
    }
  }

  .was-notified-title {
    position: relative;
    &:before {
      content: '';
      width: 86px;
      height: 86px;
      border: 16px solid ${colors.gray5};
      border-radius: 50%;
      background-color: transparent;
      position: absolute;
      top: -118px;
      left: 91.8px;
    }
  }
`;
