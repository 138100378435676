import React, { useContext, useState } from 'react';
import { OrdersToHandleContainer } from './style';
import { needToPay, totalItems } from 'services/functions';
import { PendingOrdersContext } from 'contexts/PendingOrdersContextContainer';
import { Order } from './Order';
import Pagination from 'components/Pagination';
import { NoOrdersPlaceholder } from './NoOrdersPlaceholder';

export function OrdersToHandle() {
  const { pendingOrders, activeOrder, clearActiveOrder, setupActiveOrder } =
    useContext(PendingOrdersContext);

  const [paginatedList, setPaginatedList] = useState([]);

  const onChangePagination = (updatedPaginatedList) => setPaginatedList(updatedPaginatedList);

  const onChooseOrder = (userOrder) => {
    clearActiveOrder();
    setupActiveOrder(userOrder);
  };

  return (
    <OrdersToHandleContainer>
      {pendingOrders.length ? (
        <>
          <div className='main'>
            {paginatedList.map(
              (
                { status, time, table, code, total, items, ...otherFields },
                index
              ) => (
                <div
                  onClick={() =>
                    onChooseOrder({
                      status,
                      time,
                      table,
                      code,
                      total,
                      items,
                      ...otherFields,
                    })
                  }
                  key={time + items.length + index}
                >
                  <Order
                    className={
                      otherFields.id === activeOrder.id ? 'active-now' : ''
                    }
                    type={status ? Order.types.ready : Order.types.pending}
                    waitTime={time}
                    tableName={`Table ${table.tableNumber}`}
                    code={table.orderNumber}
                    total={needToPay({ items })}
                    guests={table.guests}
                    items={totalItems(items)}
                  />
                </div>
              )
            )}
          </div>
          <Pagination
            className='order-to-handle-pagination'
            list={pendingOrders}
            limitPerPage={5}
            onChange={onChangePagination}
          />
        </>
      ) : (
        <NoOrdersPlaceholder />
      )}
    </OrdersToHandleContainer>
  );
}
