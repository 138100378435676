import styled from '@emotion/styled';

export const CompletedNotifyContainer = styled.div`
  .pineapple-description {
    width: 287px;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
    letter-spacing: -0.36px;
    margin-bottom: 0px;
    margin-bottom: 12px;
  }

  .select-order-container {
    height: 100%;
    position: relative;

    .selected-order-title {
      font-size: 16px;
      letter-spacing: 0;
      color: rgba(255, 255, 255, 0.6);
      position: absolute;
      bottom: -350px;
      left: 205px;
    }
  }
`;
