import styled from '@emotion/styled';
import { colors } from 'theme';

export const OrderTableContainer = styled.div`
  cursor: pointer;
  width: 48%;
  padding: 26px 34px;
  border-radius: 20px;
  background-color: ${colors.gray5};
  display: flex;

  &.inactive {
    background-color: ${colors.gray2};

    .order-table-description {
      .order-table-title-container {
        .order-table-title-inner-container {
          .order-table-title {
            color: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
  }

  &:first-of-type {
    margin-right: 34px;
  }

  .separator {
    font-size: 15px;
    font-weight: 600;
  }

  .check-icon {
    width: 47px;
    height: 47px;
    padding: 15px;
    border-radius: 50%;
    background-image: linear-gradient(315deg, #5ff7c0 96%, #1fc086 8%);

    i {
      font-size: 17px;
      line-height: 14px;
      color: white;
    }
  }

  .order-table-description {
    width: 100%;
    margin-left: 29px;
    display: flex;
    flex-direction: column;

    .order-table-title-container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .order-table-title-inner-container {
        margin-bottom: 20px;
        i {
          font-size: 15px;
          line-height: 14px;
          color: ${colors.rating};
          margin-right: 5px;
        }

        .order-table-title {
          font-size: 15px;
          font-weight: bold;
          color: white;
        }
      }

      .time-title-container {
        .time-title {
          font-size: 15px;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }

    .name-code-container {
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      .name-title,
      .code-title {
        font-size: 15px;
        line-height: 14px;
        letter-spacing: -0.36px;
        color: white;
      }

      .name-title {
        margin-right: 5px;
      }

      .code-title {
        margin-left: 5px;
      }
    }

    .core-facts {
      display: flex;

      .fact-title {
        span {
          font-size: 15px;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: -0.36px;
          color: rgba(255, 255, 255, 0.6);
          margin-right: 5px;
        }
      }

      .separator {
        margin-right: 5px;

        &:last-child {
          display: none;
        }
      }
    }
  }
`;
