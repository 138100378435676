import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'routes';
import 'assets/css/fontawesome-all.min.css';
import './App.css';
import GlobalStyle from 'theme/globalStyle';
import ThisAppIsNotSupported from 'pages/ThisAppIsNotSupported';

function App() {
  return (
    <div className='App'>
      <GlobalStyle />
      {isMobileScreen() ? (
        <ThisAppIsNotSupported link={process.env.REACT_APP_LINK_TO_IOS_APP} />
      ) : (
        <Router>
          <Routes />
        </Router>
      )}
    </div>
  );
}

export const isMobileScreen = () => {
  const maxWidthForMobileScreen = 600;
  return window.innerWidth < maxWidthForMobileScreen;
};

export default App;
