import styled from '@emotion/styled';
import { colors } from 'theme';

export const FieldContainer = styled.div`
  cursor: pointer;
  width: 337px;
  margin-top: 5px;
  padding: 0px 5px 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .field-container {
    display: flex;
    flex-direction: column;
    align-items: left;

    label {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.34px;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 0px;
    }

    .value-container {
      &.empty {
        .value-title {
          font-weight: 600;
          color: ${colors.redColor};
        }
      }

      .value-title {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.34px;
        color: white;
      }
    }
  }

  .click-icon {
    display: flex;
    align-items: center;

    .unverified-title {
      margin-right: 10px;
      font-size: 14px;
      letter-spacing: 0;
      color: ${colors.redColor};
    }

    .verified-icon {
      margin-right: 10px;
      i {
        font-size: 14px;
        letter-spacing: 0;
        color: ${colors.lightGreen};
      }
    }

    i {
      font-size: 20px;
      line-height: 22px;
      letter-spacing: -0.48px;
      color: rgba(255, 255, 255, 0.2);
    }
  }
`;
