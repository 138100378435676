import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FaBars } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { MenuCategoriesContainer } from './style';
import { colors } from 'theme';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { DrawerChooseExistingItem } from '../../../../../../../../layouts/Drawers/DrawerChooseExistingItem';
import { StateContext } from 'contexts/StateContextContainer';
import { showMessage, formatName } from 'services/functions';

export function MenuCategories({ selectedMenu, subCategories = [] }) {
  const [checkedCategory, setCheckedCategory] = useState(
    subCategories[0] || '');

  const { setOpenDrawerAddNewCategory } = useContext(StateContext);
  const [openDrawerChooseExistingItem, setOpenDrawerChooseExistingItem] =
    useState(false);
  const onOpenDrawerChooseExistingItem = () =>
    setOpenDrawerChooseExistingItem(true);

  const onDrag = (event) => {
    event.stopPropagation();
  };

  const onChoose = (category) => {
    setCheckedCategory(category);
    onOpenDrawerChooseExistingItem();
  };

  const onClickAddCategory = () => {
    if (!selectedMenu.id) {
      showMessage("Please add menu first", "error")
      return
    }
    setOpenDrawerAddNewCategory(true)
  }

  return (
    <MenuCategoriesContainer>
      <SingleText size={17} weight='bold' marginBottom={15}>
        Menu Categories
      </SingleText>
      <p className='tip'>
        Categories help customers browse through your menu more efficiently.{' '}
        <span className='tip-example'>
          Example: Featured, Specials, Appetizers, Soups & Salads, Entrees,
          Dessert, Drinks, etc.
        </span>
      </p>
      {subCategories.map((subCategory, index) => (
        <Box
          className='sub-category'
          onClick={() => onChoose(subCategory)}
          key={"sub-category-" + index}
        >
          <Box className='title-with-control'>
            <Box className='drag-icon'>
              <SingleIconWrapper className='drag-icon-wrapper' onClick={onDrag}>
                <FaBars />
              </SingleIconWrapper>
            </Box>
            <SingleText spacing='0.27px'>{formatName(subCategory.name)}</SingleText>
          </Box>
          <Box className='delete-icon'>
            <SingleIconWrapper className='delete-icon-wrapper'>
              <MdDelete />
            </SingleIconWrapper>
          </Box>
        </Box>
      ))}
      <Box className='add-category-container' onClick={onClickAddCategory}>
        <SingleText
          size={14}
          spacing='0.27px'
          color={colors.redColor}
          weight={600}
        >
          Add Category
        </SingleText>
      </Box>
      <DrawerChooseExistingItem
        visible={openDrawerChooseExistingItem}
        onClose={() => setOpenDrawerChooseExistingItem(false)}
      />
    </MenuCategoriesContainer>
  );
}

MenuCategories.propTypes = {
  subCategories: PropTypes.array,
  selectedMenu: PropTypes.object,
};
