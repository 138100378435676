import React from 'react'
import PropTypes from 'prop-types';
import { ScaleLoader } from "react-spinners";
import { SingleLoaderContainer } from './style';

export default function SingleLoader({ 
  isIn = 'uploader',
  color = 'white',
}) {
  let props = {};
  if (isIn === 'drawer') {
    props = {width: 6, height: 55, margin: 3};
  } else if (isIn === 'uploader') {
    props = {width: 4, height: 35, margin: 2};
  }
  return (
    <SingleLoaderContainer>
      <ScaleLoader
        {...props}
        color={color}
      />
    </SingleLoaderContainer>
  )
}

SingleLoader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.number,
  isIn: PropTypes.string,
  color: PropTypes.string,
};
