import { useState } from 'react';

export const useBinaryState = (initialValue = false) => {
  const [isActive, setActive] = useState(initialValue);

  const activate = () => setActive(true);
  const deactivate = () => setActive(false);

  return {
    isActive,
    activate,
    deactivate,
  };
};
