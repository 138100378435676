import React from 'react';
import PropTypes from 'prop-types';
import { DrawerApplyDiscountContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DiscountTypeValue } from './DiscountTypeValue';

export function DrawerApplyDiscount({
  visible,
  onClose,
  onChangeType,
  discountOption,
  onChoose,
  onApply,

  isOther,
  percents,
  setPercents,
  dollars,
  setDollars,
  onSwitchToOtherAndSetPercentage,
  isPercentageActive,
  isFixedActive,
}) {
  return (
    <DrawerApplyDiscountContainer
      customHeight={isOther ? 475 : 395}
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <SingleText
        size={22}
        spacing='0.42px'
        weight='500'
        align='center'
        width='100%'
        marginBottom={32}
      >
        Apply Discount
      </SingleText>
      <div className='discount-options-container'>
        {[10, 15, 20].map((option, index) => (
          <div
            className={`discount-option ${
              option === discountOption && isPercentageActive() && !isOther
                ? 'active'
                : ''
            }`}
            onClick={() => onChoose(option)}
            key={index + option}
          >
            <span className='option-title'>{option}%</span>
          </div>
        ))}
        <div
          className={`discount-option ${isOther ? 'active' : ''}`}
          onClick={onSwitchToOtherAndSetPercentage}
        >
          <span className='option-title'>Other</span>
        </div>
      </div>
      {isOther ? (
        <DiscountTypeValue
          dollars={dollars}
          setDollars={setDollars}
          percents={percents}
          setPercents={setPercents}
          onChangeType={onChangeType}
          isFixedActive={isFixedActive}
          isPercentageActive={isPercentageActive}
        />
      ) : (
        ''
      )}
      <div className='subtotal-container'>
        <SingleText
          size={13}
          spacing='0.25px'
          align='center'
          weight='500'
          width='100%'
        >
          {isPercentageActive()
            ? `${
                isOther ? Math.trunc(percents) : discountOption
              }% of the subtotal will be discounted.`
            : `$${dollars.toFixed(2)} will be discounted`}
        </SingleText>
        <SingleButton title='Apply' margin='17px 0px 20px' onClick={onApply} />
        <SingleButton
          title='Cancel'
          btnColor='transparent'
          txtColor={colors.redColor}
          onClick={onClose}
        />
      </div>
    </DrawerApplyDiscountContainer>
  );
}

DrawerApplyDiscount.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onChangeType: PropTypes.func,
  discountOption: PropTypes.number,
  onChoose: PropTypes.func,
  onApply: PropTypes.func,

  isOther: PropTypes.bool,
  isPercentageActive: PropTypes.func,
  isFixedActive: PropTypes.func,
  percents: PropTypes.number,
  setPercents: PropTypes.func,
  dollars: PropTypes.number,
  setDollars: PropTypes.func,
  onSwitchToOtherAndSetPercentage: PropTypes.func,
};
