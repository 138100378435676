import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { DrawerFullRefundContainerStyled } from './style';
import { OrderPriceEffects, Orders } from 'feasttt/domain';
import { CompletedOrdersContext } from 'contexts/CompletedOrdersContextContainer';
import { useRefund } from 'hooks';
import { DrawerFullRefund } from './DrawerFullRefund';

export function DrawerFullRefundContainer({ visible, onClose }) {
  const { activeOrder, refundForActiveOrder } = useContext(
    CompletedOrdersContext
  );

  const orderPrice =
    Orders.calculation.calculatePriceForCompletedOrder(activeOrder);

  const [openDrawerFullRefund, setOpenDrawerFullRefund] = useState(false);

  const onOpenDrawerFullRefund = () => setOpenDrawerFullRefund(true);
  const onCloseDrawerFullRefund = () => setOpenDrawerFullRefund(false);

  const close = () => {
    onCloseDrawerFullRefund();
    onClose();
  };

  const {
    type,
    changeType,
    percentages,
    changePercentages,
    isPercentageTypeActive,
    dollars,
  } = useRefund(orderPrice);

  const createFullRefund = () => {
    const refundWithPercentages = OrderPriceEffects.createOrderPriceEffect(
      type,
      isPercentageTypeActive() ? percentages : dollars,
      dollars,
      percentages
    );

    refundForActiveOrder(refundWithPercentages);
  };

  useEffect(() => {
    const prepareForFullRefund = () => {
      changeType(OrderPriceEffects.effectsTypes.percentage);
      changePercentages(OrderPriceEffects.FULL_REFUND_PERCENTAGE);
    };

    if (visible) {
      prepareForFullRefund();
      onOpenDrawerFullRefund();
    }
  }, [visible, changePercentages, changeType]);

  return (
    <DrawerFullRefundContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerFullRefund
        visible={openDrawerFullRefund}
        onClose={close}
        createFullRefund={createFullRefund}
        refundMoney={Orders.calculation.calculatePriceForCompletedOrder({
          items: [],
          ...activeOrder,
        })}
      />
    </DrawerFullRefundContainerStyled>
  );
}

DrawerFullRefundContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
