import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DrawerServerNotifiedContainer } from './style';
import { PendingOrdersContext } from 'contexts/PendingOrdersContextContainer';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerServerNotified({ visible, onClose, previousId }) {
  const { activeOrder } = useContext(PendingOrdersContext);
  return (
    <DrawerServerNotifiedContainer
      className={activeOrder.id === previousId ? 'order-ready' : ''}
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='check-icon'>
        <i className='fas fa-check' />
      </div>
      <SingleText
        className='was-notified-title'
        size={22}
        spacing='0.42px'
        align='center'
        marginBottom={50}
      >
        {activeOrder.id === previousId
          ? 'The server was notified.'
          : 'Order marked delivered.'}
      </SingleText>
      <SingleButton title='Okay' onClick={onClose} />
    </DrawerServerNotifiedContainer>
  );
}

DrawerServerNotified.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  previousId: PropTypes.any,
};
