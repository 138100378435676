import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerFloorEditContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  padding: 0px 0px;

  .drawer-floor-edit-top {
    display: flex;
    justify-content: center;

    &.edit-mode {
      flex: 1 1 0%;
    }

    &.remove-mode {
      margin-left: -30px;
    }
  }
`;
