import React from 'react';
import PropTypes from 'prop-types';
import { DrawerDeleteOrderAreYouSureContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerDeleteOrderAreYouSure({
  visible,
  onClose,
  onYesDelete,
  deleteActiveOrder = () => {},
}) {
  const onTrulyDelete = () => {
    deleteActiveOrder();
    onYesDelete();
  };

  return (
    <DrawerDeleteOrderAreYouSureContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='trash-delete-icon'>
        <i className='fas fa-trash' />
      </div>
      <SingleText
        className='are-you-sure-title'
        size={22}
        spacing='0.42px'
        marginBottom={10}
      >
        Are you sure ?
      </SingleText>
      <SingleText lineHeight='21px' spacing='0.27px' marginBottom={22}>
        The entire order will be deleted.
      </SingleText>
      <SingleText lineHeight='21px' spacing='0.27px' marginBottom={32}>
        This cannot be undone.
      </SingleText>
      <SingleButton
        title='Yes, delete order'
        margin='0px 0px 20px 0px'
        onClick={onTrulyDelete}
      />
      <SingleButton
        title='Keep'
        btnColor={colors.lightGray}
        onClick={onClose}
      />
    </DrawerDeleteOrderAreYouSureContainer>
  );
}

DrawerDeleteOrderAreYouSure.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onYesDelete: PropTypes.func,
  deleteActiveOrder: PropTypes.func,
};
