import styled from '@emotion/styled';
import { colors } from 'theme';

export const TableLayoutContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 23px;
  border-radius: 8px;

  .header-menu-container {
    .layout-title {
      font-size: 17px;
      font-weight: bold;
      letter-spacing: -0.38px;
      color: white;
      margin-right: 130px;
    }

    .status-menu {
      display: flex;
      align-items: center;
      .status-menu-item {
        margin-left: 35px;
      }
      .status-menu-item:first-of-type {
        margin-left: 0px;
      }
    }

    .floors-select {
      .dropdown-header-menu {
        color: white;
        background-color: #151617;
      }

      .dropdown-content-menu {
        &.show {
          border: 1px solid ${colors.border};
        }
      }
    }
  }
  .tables {
    background-color: transparent;
    background-image: linear-gradient(
        0deg,
        transparent 24%,
        rgba(255, 255, 255, 0.05) 25%,
        rgba(255, 255, 255, 0.05) 26%,
        transparent 27%,
        transparent 74%,
        rgba(255, 255, 255, 0.05) 75%,
        rgba(255, 255, 255, 0.05) 76%,
        transparent 77%,
        transparent
      ),
      linear-gradient(
        90deg,
        transparent 24%,
        rgba(255, 255, 255, 0.05) 25%,
        rgba(255, 255, 255, 0.05) 26%,
        transparent 27%,
        transparent 74%,
        rgba(255, 255, 255, 0.05) 75%,
        rgba(255, 255, 255, 0.05) 76%,
        transparent 77%,
        transparent
      );
    height: 100%;
    background-size: 50px 50px;
  }

  .tables {
    position: relative;
    .internal-scene {
      transform-origin: center;
      width: 100%;
      height: 100%;

      .table-name-movable-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .table-name,
        .guests-and-seats-title {
          font-size: 13px;
          color: white;
          letter-spacing: -0.31px;
        }

        .table-name {
          font-weight: bold;
          margin-bottom: 0px;
        }
      }
    }
    .add-table-class {
      cursor: pointer;
      position: absolute;
      right: 40px;
      bottom: 120px;
      transition: opacity 0.5s ease-in-out;
      -moz-transition: opacity 0.5s ease-in-out;
      -webkit-transition: opacity 0.5s ease-in-out;
    }
    .add-table-class:hover {
      opacity: 0.9;
    }
    .zoom-class {
      top: 20px;
      right: 40px;
      background-color: rgba(96, 100, 108, 0.5);
      position: absolute;
      flex-direction: column;
      .zoom-button {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.3);
        color: #fff;
        font-size: 16px;
        transition: background-color 0.5s ease-in-out;
        -moz-transition: background-color 0.5s ease-in-out;
        -webkit-transition: background-color 0.5s ease-in-out;
        i {
          font-weight: 100;
        }
        &.zoom-button-margin-bottom {
          margin-bottom: 3px;
        }
      }
      .zoom-button:hover {
        background-color: rgba(255, 255, 255, 0.65);
      }
    }
    .rotate-class {
      left: 30px;
      bottom: 120px;
      position: absolute;
      .rotate-button {
        cursor: pointer;
        background-color: rgba(96, 100, 108, 0.5);
        margin: 0 10px;
        color: rgba(255, 255, 255, 0.3);
        font-size: 16px;
        transition: color 0.5s ease-in-out;
        -moz-transition: color 0.5s ease-in-out;
        -webkit-transition: color 0.5s ease-in-out;
        i {
          font-weight: 100;
        }
      }
      .rotate-button:hover {
        color: #fff;
      }
    }

    .react-transform-component {
      height: 100%;
      width: 100%;
      .react-transform-element {
        height: 100%;
        width: 100%;
      }
    }
  }
`;
