import React from 'react';
import PropTypes from 'prop-types';
import { DrawerFullRefundContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerFullRefund({
  visible,
  onClose,
  createFullRefund,
  refundMoney,
}) {
  const onIssueRefund = () => {
    createFullRefund();
    onClose();
  };

  return (
    <DrawerFullRefundContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <SingleText size={22} spacing='0.42px' marginBottom={10} align='center'>
        Full Refund
      </SingleText>
      <SingleText
        lineHeight='21px'
        spacing='0.27px'
        align='center'
        width='267px'
      >
        Are you sure you want to issue a refund of{' '}
        <b>${refundMoney.toFixed(2)}</b> to the customer ?
      </SingleText>
      <SingleButton
        title='Yes, issue refund'
        margin='31px 0px 20px'
        onClick={onIssueRefund}
      />
      <SingleButton
        title='Cancel'
        btnColor='transparent'
        txtColor={colors.redColor}
        onClick={onClose}
      />
    </DrawerFullRefundContainer>
  );
}

DrawerFullRefund.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  refundMoney: PropTypes.number,
  createFullRefund: PropTypes.func,
};
