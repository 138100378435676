import styled from '@emotion/styled';
import { DefaultUser } from 'assets/images/profile';
import { colors } from 'theme';

export const CompletedTableNotificationContainer = styled.div`
  padding: 25px 35px;
  background-color: ${colors.gray2};
  border-radius: 20px;
  display: flex;
  flex-direction: column;

  .table-description {
    display: flex;

    .table-description-icon {
      width: 47px;
      height: 47px;
      border-radius: 41px;
      padding: 15px;
      background-image: linear-gradient(315deg, #5ff7c0 96%, #1fc086 8%);

      i {
        font-size: 17px;
        line-height: 14px;
        letter-spacing: -0.41px;
        color: white;
      }
    }

    .main-description {
      margin-left: 14px;
      width: 100%;
      display: flex;
      flex-direction: column;

      .main-inner-description {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .rating-container {
          i {
            font-size: 15px;
            line-height: 14px;
            color: ${colors.rating};
            margin-right: 5px;
          }

          .rating-title {
            font-size: 15px;
            font-weight: bold;
            line-height: 14px;
            letter-spacing: -0.36px;
            color: white;
          }
        }

        .avg-wait-container {
          font-size: 15px;
          letter-spacing: -0.36px;
          color: rgba(255, 255, 255, 0.5);

          i {
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .name-code-container {
        display: flex;

        .name-container,
        .code-container {
          .name-title,
          .code-title {
            font-size: 15px;
            letter-spacing: -0.36px;
            color: white;
          }
        }

        .separator {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }

  .table-date {
    margin-top: 14px;
    margin-bottom: 25px;

    .table-date-title {
      font-size: 15px;
      color: rgba(255, 255, 255, 0.6);
      letter-spacing: -0.28px;
    }
  }

  .table-more-detail {
    width: 100%;
    margin-bottom: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .guests-items {
      display: flex;
      align-items: center;

      .title-item {
        font-size: 14px;
        line-height: 12px;
        letter-spacing: -0.34px;
        color: rgba(255, 255, 255, 0.6);
      }

      .separator {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.6);
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    .server-container {
      display: flex;
      align-items: center;

      .position-title {
        font-size: 14px;
        line-height: 12px;
        letter-spacing: -0.34px;
        color: rgba(255, 255, 255, 0.6);
      }

      .server-name {
        margin-left: 5px;
        font-size: 14px;
        line-height: 12px;
        letter-spacing: -0.34px;
        color: white;
      }
    }
  }

  .photo-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .guests {
      position: relative;
      display: flex;
      align-items: center;

      .guest-photo-item {
        position: absolute;
        top: -2px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(${DefaultUser});
      }
    }

    .server {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(${DefaultUser});
    }
  }

  .server-rating-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .server-rating-core {
      margin-top: 7px;
      display: flex;
      align-items: center;

      .server-rating-core-main {
        display: flex;
        align-items: center;

        .rating-star {
          margin-left: 6px;
          &.active {
            i {
              color: ${colors.rating};
            }
          }

          i {
            font-size: 16px;
            letter-spacing: -0.28px;
            color: ${colors.emptyRating};
          }
        }
      }

      .reviews-count-title {
        margin-left: 5px;
      }
    }

    .btn-review {
      cursor: pointer;
      margin-top: 7px;
      display: flex;

      .btn-review-title {
        margin-right: 5px;
        font-size: 15px;
        letter-spacing: -0.36px;
        color: ${colors.redColor};
      }

      .btn-review-icon {
        padding-top: 2px;

        i {
          color: ${colors.redColor};
        }
      }
    }
  }
`;
