import React from 'react';
import PropTypes from 'prop-types';
import { DrawerDeleteAccountEntireAccountContainer } from './style';
import { colors } from 'theme';
// we need test it properly
// eslint-disable-next-line
import { authServices } from 'services/api-services';
import { showMessage } from 'services/functions';
import { useLogOut } from 'hooks';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerDeleteEntireAccount({ visible, onClose }) {
  // we need test it properly
  // eslint-disable-next-line
  const onDeleteAccountFail = (errMessage) =>
    showMessage(`Delete account failed: ${errMessage}`, 'error');

  const onLogOutWithDeletingAccount = useLogOut({
    // we need test it properly
    // eslint-disable-next-line
    /*onAfterLogOut: async () =>
      await authServices.deleteMyAccount(() => {}, onDeleteAccountFail), */
  });

  return (
    <DrawerDeleteAccountEntireAccountContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='delete-account-icon'>
        <i className='fal fa-trash' />
      </div>
      <SingleText
        className='delete-my-account'
        size={22}
        spacing='0.42px'
        align='center'
        marginBottom={10}
        weight='500'
      >
        Delete my account
      </SingleText>
      <SingleText
        lineHeight='21px'
        spacing='0.27px'
        align='center'
        width='283px'
      >
        Your entire account will be deleted, this can’t be undone.
      </SingleText>
      <SingleButton
        title='Yes, delete my account'
        margin='31px 0px 20px 0px'
        onClick={onLogOutWithDeletingAccount}
      />
      <SingleButton
        title='Keep my account'
        btnColor={colors.lightGray}
        onClick={onClose}
      />
    </DrawerDeleteAccountEntireAccountContainer>
  );
}

DrawerDeleteEntireAccount.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
