import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import { Animated } from 'react-animated-css';
import { MenuListContainer } from './style';
import {
  defaultListCategories,
  mapAndFilterFoodInMenu,
} from 'services/functions';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import SingleDragger from 'components/SingleDragger';
import ItemCard from '../../ItemCard';
import { NoItemsYet } from './NoItemsYet';
import SingleButton from 'components/SingleButton';
import { colors } from 'theme';
import DrawerItem from 'pages/Menu/components/DrawerItem';
import { DrawerChooseExistingItem } from '../../../../layouts/Drawers/DrawerChooseExistingItem';

export function MenuList({ searchFood, isEditable }) {
  const { category } = useParams();
  const { menus, isMenusEditing, chosenMenu } = useContext(DataContext);
  const {
    categories,
    setCategories,
    allCategories,
    foods,
    setSelectedCategory,
  } = useContext(StateContext);
  const [menuList, setMenuList] = useState([]);

  const [openDrawerChooseExistingItem, setOpenDrawerChooseExistingItem] =
    useState(false);

  const [activeTooltipId, setActiveTooltipId] = useState(null);
  const [openDrawerFoodItem, setOpenDrawerFoodItem] = useState(false);
  const onOpenDrawerFoodItem = () => setOpenDrawerFoodItem(true);

  const getSelectedMenu = () => {
    const category = allCategories.find(
      (categoryItem) => categoryItem.id === chosenMenu
    );
    if (chosenMenu && category) {
      setCategories(category.categories);
      setMenuList(category.categories);
    }
  };

  useEffect(() => {
    if(!chosenMenu && allCategories.length) setMenuList(allCategories[0].categories)
    else setMenuList(defaultListCategories(chosenMenu, allCategories, categories));
    getSelectedMenu();
  }, [allCategories, categories, chosenMenu, menus]);

  const handleChooseExistingMenu = (category) => {
    setSelectedCategory(category);
    setOpenDrawerChooseExistingItem(true);
  };

  // console.log({menuList});

  return (
    <MenuListContainer>
      {menuList && menuList.length ? (
        menuList.map((_category, _i) => (
          <div key={_i} className='list-category' id={`list-category-${_i}`}>
            <div className='category-name'>
              <span className='category-name-title'>{_category.name}</span>
              {isMenusEditing && (
                <div className='add-item-wrapper'>
                  {activeTooltipId === _i && (
                    <div className='add-item-tooltip'>
                      <button
                        className='add-item-button'
                        onClick={() => onOpenDrawerFoodItem()}
                      >
                        <span className='tooltip-icon'>+</span>
                        Create a new item
                      </button>
                      <button
                        className='add-item-button'
                        onClick={() =>
                          handleChooseExistingMenu({
                            id: _category.id,
                            name: _category.name,
                          })
                        }
                      >
                        <i className='fal fa-pencil' />
                        Choose an existing item
                      </button>
                    </div>
                  )}
                  <SingleButton
                    shape='circle'
                    width={170}
                    height={40}
                    txtColor='white'
                    btnColor={colors.redColor}
                    title='+ Add Item'
                    onClick={() =>
                      setActiveTooltipId(activeTooltipId === null ? _i : null)
                    }
                  />
                </div>
              )}
            </div>
            {!mapAndFilterFoodInMenu(searchFood, _category.items, foods)
              .length && (
              <span className='category-empty-text'>
                There are no items yet.
              </span>
            )}
            {_category?.items?.length > 0 && isEditable ? (
              <SingleDragger
                renderItem={(food) => (
                  <ItemCard foodItem={food} isDraggable={isEditable} />
                )}
                items={mapAndFilterFoodInMenu(
                  searchFood,
                  _category.items,
                  foods
                )}
                onSortEnd={(param) =>
                  onSortEnd(param, _category.id, categories, setCategories)
                }
              />
            ) : (
              <div className='list-category-container'>
                {_category?.items?.length > 0 &&
                  mapAndFilterFoodInMenu(
                    searchFood,
                    _category.items,
                    foods
                  ).map((food, _i) => {
                    return (
                      <Animated
                        key={_i}
                        animationIn='fadeIn'
                        animationOut='fadeOut'
                        animationInDelay={_i * 100}
                        isVisible={true}
                      >
                        <ItemCard
                          key={_i}
                          foodItem={food}
                          isDraggable={isEditable}
                        />
                      </Animated>
                    );
                  })}
              </div>
            )}
          </div>
        ))
      ) : (
        <NoItemsYet />
      )}
      <DrawerItem
        visible={openDrawerFoodItem}
        setOpenDrawerFoodItem={setOpenDrawerFoodItem}
        editFood={null}
      />

      <DrawerChooseExistingItem
        visible={openDrawerChooseExistingItem}
        onClose={() => setOpenDrawerChooseExistingItem(false)}
      />
    </MenuListContainer>
  );
}

MenuList.propTypes = {
  searchFood: PropTypes.string,
  isEditable: PropTypes.bool,
};

export const onSortEnd = (
  { oldIndex, newIndex },
  categoryId,
  categories,
  setCategories
) => {
  const newCategories = categories.map((_category) => {
    if (_category.id === categoryId) {
      let newCategory = { ..._category };
      newCategory.items = arrayMove([..._category.items], oldIndex, newIndex);
      return newCategory;
    }
    return _category;
  });
  setCategories(newCategories);
};
