import styled from '@emotion/styled';

export const WelcomeContainer = styled.div`
  max-width: 693px;
  margin-top: 128px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
