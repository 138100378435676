import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerProfileUpdateEmailContainerStyled } from './style';
import { DrawerProfileUpdateEmail } from './DrawerProfileUpdateEmail';

export function DrawerProfileUpdateEmailContainer({
  visible,
  onClose,
  onChange,
}) {
  const [openDrawerProfileUpdateEmail, setOpenDrawerProfileUpdateEmail] =
    useState(false);
  const onOpenDrawerProfileUpdateEmail = () =>
    setOpenDrawerProfileUpdateEmail(true);
  const onCloseDrawerProfileUpdateEmail = () =>
    setOpenDrawerProfileUpdateEmail(false);

  useEffect(() => {
    if (visible) {
      onOpenDrawerProfileUpdateEmail();
    }
  }, [visible]);

  const close = () => {
    onCloseDrawerProfileUpdateEmail();
    onClose();
  };

  return (
    <DrawerProfileUpdateEmailContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerProfileUpdateEmail
        visible={openDrawerProfileUpdateEmail}
        onClose={close}
        onChange={onChange}
      />
    </DrawerProfileUpdateEmailContainerStyled>
  );
}

DrawerProfileUpdateEmailContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};
