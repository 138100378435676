import React, { useState, useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { PeriodSwitcherContainer } from './style';
import { CompletedOrdersPeriods } from 'feasttt/domain';
import { useBinaryState } from 'hooks';
import { CompletedOrdersContext } from 'contexts/CompletedOrdersContextContainer';
import { CustomPeriodFilter } from '../CustomPeriodFilter';
import { readPeriod } from '../CompletedComponent/GeneralStatistic';

export function PeriodSwitcher() {
  const {
    selectedPeriod,
    onSelect,
    isThisPeriodEqualToSelectedPeriod,
    changeCustomTimeRange,
    isRangeActive,
    isPartOfRange,
    onClearGlobalRange,
    customTimeRange,
  } = useContext(CompletedOrdersContext);

  const [isPeriodDropdownOpen, setPeriodDropdownOpen] = useState(false);

  const {
    isActive: showTimeRangeCalendars,
    activate: openTimeRangeCalendars,
    deactivate: closeTimeRangeCalendars,
  } = useBinaryState();

  const onSelectCustomOptionAndOpenCalendars = (event, newPeriod) => {
    event.stopPropagation();
    onSelect(newPeriod);
    openTimeRangeCalendars();
  };

  const onChooseDay = (newPeriod) => {
    onSelect(newPeriod);
    if (showTimeRangeCalendars) {
      closeTimeRangeCalendars();
    }
  };

  const onToggle = (isOpen, _, { source }) => {
    if (!isOpen && !source) {
      return;
    }

    if (
      isOpen &&
      isThisPeriodEqualToSelectedPeriod(CompletedOrdersPeriods.periods.custom)
    ) {
      openTimeRangeCalendars();
    } else {
      closeTimeRangeCalendars();
    }

    setPeriodDropdownOpen(isOpen);
  };

  const onApply = (timeRange) => {
    changeCustomTimeRange(timeRange);
    closeTimeRangeCalendars();
    setPeriodDropdownOpen(false);
  };

  return (
    <PeriodSwitcherContainer className='period-switcher'>
      <Dropdown
        className='period-dropdown'
        onToggle={onToggle}
        show={isPeriodDropdownOpen}
      >
        <Dropdown.Toggle>
          <div className='period-title-container checked'>
            <span className='period-title'>
              {readPeriod(selectedPeriod, customTimeRange, isRangeActive())}
            </span>
            {isPeriodDropdownOpen ? (
              <i className='fas fa-angle-up' />
            ) : (
              <i className='fas fa-angle-down' />
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={`periods ${
            isThisPeriodEqualToSelectedPeriod(
              CompletedOrdersPeriods.periods.custom
            )
              ? 'custom-selected'
              : ''
          }`}
        >
          {[
            CompletedOrdersPeriods.periods.today,
            CompletedOrdersPeriods.periods.yesterday,
          ].map((periodOption, index) => (
            <Dropdown.Item
              key={periodOption + index}
              eventKey={index}
              onClick={() => onChooseDay(periodOption)}
            >
              <span
                className={`period-title ${
                  isThisPeriodEqualToSelectedPeriod(periodOption)
                    ? 'currently-active'
                    : ''
                }`}
              >
                {periodOption}
              </span>
            </Dropdown.Item>
          ))}
          <Dropdown.Item>
            <span
              onClick={(event) =>
                onSelectCustomOptionAndOpenCalendars(
                  event,
                  CompletedOrdersPeriods.periods.custom
                )
              }
              className={`period-title ${
                isThisPeriodEqualToSelectedPeriod(
                  CompletedOrdersPeriods.periods.custom
                )
                  ? 'currently-active'
                  : ''
              }`}
            >
              Custom
            </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <CustomPeriodFilter
        className={`custom-period-filter ${
          showTimeRangeCalendars ? 'show' : 'hide'
        }`}
        onApply={onApply}
        isRangeActive={isRangeActive}
        isPartOfRange={isPartOfRange}
        onClearGlobalRange={onClearGlobalRange}
      />
    </PeriodSwitcherContainer>
  );
}
