import styled from '@emotion/styled';

export const SpecialInstructionsContainer = styled.div`
  margin-top: 52px;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;

  .special-instructions-text {
    font-size: 14px;
    letter-spacing: -0.34px;
    color: white;
    margin-bottom: 0px;
  }
`;
