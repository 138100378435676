export class Views {
  layout = 'Layout';
  list = 'List';

  getListView() {
    return this.list;
  }

  getLayoutView() {
    return this.layout;
  }

  getInitialView() {
    return this.layout;
  }

  isEqualToLayoutView(view) {
    return view === this.layout;
  }

  isEqualToListView(view) {
    return view === this.list;
  }
}
