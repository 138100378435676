import styled from '@emotion/styled';

export const FoodNutritionDetailContainer = styled.div`
  display: flex;
  flex-direction: column;

  &.bottom-border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 21px;
    padding-bottom: 20px;
  }

  .core {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label-with-suffix-container {
      .label {
        font-size: 14px;
        font-weight: bold;
        line-height: 21px;
        letter-spacing: -0.34px;
        color: white;
        margin-right: 8px;
      }

      .label-suffix {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.34px;
        color: white;
      }
    }

    .value-with-suffix-container {
      .value,
      .value-suffix {
        font-size: 14px;
        font-weight: bold;
        line-height: 21px;
        letter-spacing: -0.34px;
        color: white;
      }
    }
  }

  .additional-description {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.34px;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 10px;
    padding-left: 30px;
  }

  .children-all {
    display: flex;
    flex-direction: column;
    padding-left: 36px;

    .food-nutrition-detail {
      margin-top: 10px;
    }
  }
  
  .nutrition-wrapper {
    display : flex;
    justify-content: space-between;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 16px;
  }
`;
