import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { AddItemContainer } from './style';
import { PendingOrdersContext } from 'contexts/PendingOrdersContextContainer';
import { ActiveOrdersContext } from 'contexts/ActiveOrdersContextContainer';
import { FoodCategoryControlBar } from './FoodCategoryControlBar';
import { AvailableMenu } from './AvailableMenu';
import { DrawerAddItemToOrder } from './DrawerAddItemToOrder';

export function AddItem({
  isLastOpenedPagePendingPage,
  isLastOpenedPageActivePage,
}) {
  const { onAddFoodItem } = useContext(PendingOrdersContext);
  const { onAddFoodItem: onAddFoodItemForActiveOrder } =
    useContext(ActiveOrdersContext);

  const [foodToSee, setFoodToSee] = useState(undefined);
  const onOpenDrawerAddItemToOrder = (food) => setFoodToSee(food);
  const onCloseDrawerAddItemToOrder = () => setFoodToSee(undefined);

  const onChooseItem = (foodItem) => {
    onOpenDrawerAddItemToOrder(foodItem);
  };

  const onAddToOrder = (foodItem) => {
    onCloseDrawerAddItemToOrder();
    if (isLastOpenedPagePendingPage()) {
      onAddFoodItem(foodItem);
    } else if (isLastOpenedPageActivePage()) {
      onAddFoodItemForActiveOrder(foodItem);
    }
  };

  return (
    <AddItemContainer>
      <FoodCategoryControlBar />
      <AvailableMenu onChooseItem={onChooseItem} />
      <DrawerAddItemToOrder
        visible={foodToSee !== undefined}
        food={foodToSee}
        onAddToOrder={onAddToOrder}
        onClose={onCloseDrawerAddItemToOrder}
      />
    </AddItemContainer>
  );
}

AddItem.propTypes = {
  lastPageWas: PropTypes.string,
  isLastOpenedPagePendingPage: PropTypes.func,
  isLastOpenedPageActivePage: PropTypes.func,
};
