import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerCheckInCompletedWrapper = styled(SingleDrawer)`
  overflow: visible;
  z-index: 1001;
  background-color: ${colors.gray5};
  position: initial;
  .check-in-completed {
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 100px;
      height: 100px;
      background-color: #272827;
      border-top-left-radius: 73px;
      border-top-right-radius: 40px;
      border-bottom-left-radius: 50px;
      position: absolute;
      top: -88px;
      left: 111px;
    }

    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 100px;
      background-color: #272827;
      position: absolute;
      top: -88px;
      right: 111px;
      border-top-left-radius: 35px;
      border-top-right-radius: 73px;
      border-bottom-right-radius: 50px;
      z-index: 97;
    }

    .image-container {
      z-index: 98;
      width: 115px;
      height: 110px;
      border-radius: 50%;
      background-color: #27282a;
      border: solid 6px #27282a;
      position: absolute;
      top: -89px;
      right: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .completed-message {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      margin-top: 50px;

      &:before {
        content: '';
        display: block;
        width: 100px;
        height: 35px;
        background-color: transparent;
        position: absolute;
        top: -99px;
        left: 22px;
        border-right: 9px solid #272827;
        border-bottom: 10px solid #272827;
        border-bottom-right-radius: 86px;
      }

      &:after {
        content: '';
        display: block;
        width: 100px;
        height: 35px;
        background-color: transparent;
        position: absolute;
        right: 22px;
        top: -99px;
        border-left: 9px solid #272827;
        border-bottom: 10px solid #272827;
        border-bottom-left-radius: 86px;
      }

      i {
        font-size: 22px;
        color: #1fc086;
      }
      h3 {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 0;
        margin-left: 5px;
      }
    }
    .details-message {
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      margin-bottom: 50px;
    }
    .completed-message-button {
      cursor: pointer;
      width: 100%;
      height: 50px;
      font-size: 14px;
      font-weight: 600;
      background-color: #ed2626;
      border-radius: 8px;
      color: #ffffff;
      border: unset;
      outline: unset;
    }
  }
`;
