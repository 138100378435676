import React from 'react';
import PropTypes from 'prop-types';
import { DrawerBankAccountRemoveContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerBankAccountRemove({ visible, onClose, onRemove }) {
  const remove = () => {
    onRemove();
    onClose();
  };

  return (
    <DrawerBankAccountRemoveContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='remove-icon'>
        <i className='fas fa-trash' />
      </div>
      <SingleText
        className='remove-bank-account-title'
        size={22}
        spacing='0.42px'
        align='center'
        marginBottom={10}
        weight='500'
      >
        Remove bank account
      </SingleText>
      <SingleText
        lineHeight='21px'
        spacing='0.27px'
        align='center'
        width='329px'
      >
        Your <b>bank account</b> will be removed. All future tips will remain in
        Feasttt until you add another.
      </SingleText>
      <SingleButton
        title='Yes, remove bank account'
        margin='31px 0px 20px 0px'
        onClick={remove}
      />
      <SingleButton
        title='Keep'
        btnColor={colors.lightGray}
        onClick={onClose}
      />
    </DrawerBankAccountRemoveContainer>
  );
}

DrawerBankAccountRemove.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
};
