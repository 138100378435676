import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ScaleLoader } from 'react-spinners';
import { SingleAvatarUploaderContainer } from './style';
import { useBinaryState } from 'hooks';
import { showMessage } from 'services/functions';
import { assetServices } from 'services/api-services';

import { ProfileImageDefault } from 'assets/images/profile';

export function SingleAvatarUploader({
  receiveImage = () => { },
  placeholder,
  width = '100%',
  height = '100%',
  shape = 'circle',
  overlay,
}) {
  const [imageUrl, setImageUrl] = useState();

  const {
    isActive: isUploading,
    activate: startUploading,
    deactivate: endUploading,
  } = useBinaryState();

  const {
    isActive: isHover,
    activate: showHover,
    deactivate: hideHover,
  } = useBinaryState();

  const onUploadLocalFileToAssetsStorage = async (info) => {
    const fileToUploadFromFormData = createFormDataForLocalFile(info);

    startUploading();
    uploadPhotoAsset(fileToUploadFromFormData);
  };

  const createFormDataForLocalFile = (uploadInfo) => {
    const postData = new FormData();
    postData.append('asset', uploadInfo.target.files[0]);
    postData.append('type', 'user');
    postData.append('api_key', process.env.REACT_APP_API_KEY);

    return postData;
  };

  const uploadPhotoAsset = async (localFile) =>
    await assetServices.uploadToS3(
      localFile,
      onSuccessfullyUploaded,
      onFailWhenTriedUpload
    );

  const onSuccessfullyUploaded = (uploadedResource) => {
    setImageUrl(uploadedResource.file);
    receiveImage(uploadedResource);
    endUploading();
  };

  const onFailWhenTriedUpload = (errMessage) => {
    showMessage(errMessage, 'error');
    endUploading();
  };

  const inputFileRef = useRef();
  const invokeEventForInput = () => inputFileRef.current.click();

  return (
    <SingleAvatarUploaderContainer width={width} height={height} shape={shape}>
      <div className='avatar-uploader' onClick={invokeEventForInput}>
        <div
          className={`photo ${shape === 'circle' ? 'circle' : 'square'}`}
          onMouseOverCapture={showHover}
          onMouseLeave={hideHover}
        >
          <input
            className='file-image-input'
            type='file'
            ref={inputFileRef}
            onChange={onUploadLocalFileToAssetsStorage}
            accept='image/*'
          />
          <img
            src={imageUrl || placeholder || ProfileImageDefault}
            alt='avatar'
            className={`user-image ${shape === 'circle' ? 'circle' : 'square'}`}
          />
          {isUploading && (
            <div className='uploading-icon'>
              <ScaleLoader size={15} color={'white'} />
            </div>
          )}
          {isHover && (
            <div className='photo-hover'>
              {overlay || (
                <div className='change-container'>
                  <span className='change-text'>Change</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </SingleAvatarUploaderContainer>
  );
}

SingleAvatarUploader.propTypes = {
  uploading: PropTypes.bool,
  receiveImage: PropTypes.func,
  placeholder: PropTypes.any,
  src: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  shape: PropTypes.string,
  overlay: PropTypes.node,
};
