import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerProcessPaymentAreYouSureContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  height: ${({ customHeight }) => customHeight}px !important;
  padding: 72px 26px 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: visible;

  .money-icon {
    z-index: 101;
    width: 68.5px;
    height: 68.5px;
    padding: 22px 18px;
    border-radius: 50%;
    background-color: ${colors.lightGreen};
    position: absolute;
    top: -37px;

    i {
      font-size: 26px;
      letter-spacing: 0.46px;
      color: white;
    }
  }

  .are-sure-payment-title {
    position: relative;
    &:before {
      content: '';
      width: 86px;
      height: 86px;
      border: 16px solid ${colors.gray5};
      border-radius: 50%;
      background-color: transparent;
      position: absolute;
      top: -118px;
      left: 120.8px;
    }
  }

  &:before {
    content: '';
    display: block;
    width: 79px;
    height: 33px;
    background-color: transparent;
    position: absolute;
    top: -16.7px;
    left: 92px;
    border-right: 15px solid ${colors.gray5};
    border-bottom: 16px solid ${colors.gray5};
    border-bottom-right-radius: 51px;
  }

  &:after {
    content: '';
    display: block;
    width: 79px;
    height: 33px;
    background-color: transparent;
    position: absolute;
    top: -16.7px;
    right: 92px;
    border-left: 15px solid ${colors.gray5};
    border-bottom: 16px solid ${colors.gray5};
    border-bottom-left-radius: 51px;
  }

  .selected-thing-to-payment {
    border-color: transparent;

    &.no-payment {
      margin-bottom: 24px;
    }
  }

  .will-be-added-as-a-tip {
    font-size: 14px;
    font-weight: normal;
    line-height: 21px;
    letter-spacing: 0.27px;
    color: white;
  }

  .total-container {
    // margin-top: 26px;
    margin-top: ${({ totalContainerMarginTop }) => totalContainerMarginTop}px;
    text-align: center;

    .total-title {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.27px;
      color: white;
      text-align: center;
    }
  }
`;
