import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerLogOutContainerStyled } from './style';
import { DrawerLogOut } from './DrawerLogOut';

export function DrawerLogOutContainer({ visible, onClose }) {
  const [openDrawerLogOut, setOpenDrawerLogOut] = useState(false);
  const onOpenLogOutDrawer = () => setOpenDrawerLogOut(true);
  const onCloseLogOutDrawer = () => setOpenDrawerLogOut(false);

  useEffect(() => {
    if (visible) {
      onOpenLogOutDrawer();
    }
  }, [visible]);

  const close = () => {
    onCloseLogOutDrawer();
    onClose();
  };

  return (
    <DrawerLogOutContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerLogOut visible={openDrawerLogOut} onClose={close} />
    </DrawerLogOutContainerStyled>
  );
}

DrawerLogOutContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
