import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types';
import { FaChevronLeft } from 'react-icons/fa';
import { DrawerWallContainer, WallShapeContainer, WallSpace } from './style';
import { colors } from 'theme';
import { Tables } from 'feasttt/domain';
import { restaurantServices } from 'services/api-services';
import { showMessage } from 'services/functions';
import { AuthContext } from 'contexts/AuthContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { StateContext } from 'contexts/StateContextContainer';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import SingleIconWrapper from 'components/SingleIconWrapper';

export default function DrawerWall({
  type = '',
  visible = false,
  placement = 'right',
  onClose = () => { },
}) {
  const { setOpenDrawerElement } = useContext(StateContext);
  const { restaurantId } = useContext(AuthContext);
  const { floorDetails, selectedFloorId, floorWalls, setFloorWalls, wallForEdit, setWallForEdit } = useContext(DataContext);
  
  const [shape, setShape] = useState(undefined);
  const isEqualToCurrentShape = (shapeOption) => shapeOption === shape;
  const makeShapeHorizontal = () => setShape(Tables.geometry.shapes.horizontal);
  const makeShapeVertical = () => setShape(Tables.geometry.shapes.vertical);

  const assignWidthToWall = () => Tables.geometry.isHorizontal(shape) ? 200 : 20;
  const assignHeightToWall = () => Tables.geometry.isHorizontal(shape) ? 20 : 200;

  const selectedFloorDetails = floorDetails.find(x => x.id === selectedFloorId);
  
  const cleanUpAndClose = () => {
    setWallForEdit(undefined);
    setShape(undefined);
    onClose();
  };

  async function addWallClicked() {
    const newWall = { x: 0, y: 0, width: assignWidthToWall(), height: assignHeightToWall() };

    const { ok, errMessage } = await restaurantServices.updateFloor({ restaurantId, floorId: selectedFloorId }, { ...selectedFloorDetails, walls: [...selectedFloorDetails.walls, newWall] });
    if (ok) {
      setFloorWalls([...floorWalls, newWall])
      cleanUpAndClose();
      setOpenDrawerElement(false);
    } else {
      showMessage(errMessage, 'error');
    }
  }

  async function submitUpdatedWall() {
    const updatedSizesOfWall = {
      width: assignWidthToWall(),
      height: assignHeightToWall(),
    };

    const onOk = () => {
      setFloorWalls(floorWalls.map((wall) => wall.x === wallForEdit.x && wall.y === wallForEdit.y ? { ...wallForEdit, ...updatedSizesOfWall } : wall));
      cleanUpAndClose()
    };

    const onFail = (errMessage) => {
      showMessage(errMessage);
      cleanUpAndClose();
    };

    return await restaurantServices._updateFloor({ restaurantId, floorId: selectedFloorId }, { ...selectedFloorDetails, walls: (selectedFloorDetails ? selectedFloorDetails.walls: []).map((wall) => wall.x === wallForEdit.x && wall.y === wallForEdit.y ? {...wallForEdit, ...updatedSizesOfWall } : wall)}, onOk, onFail);
  }

  useEffect(() => {
    if (visible) {
      if (wallForEdit && shape === undefined) {
        setShape(Tables.geometry.defineShapeDependingOnSize(wallForEdit));
      }
    }
  }, [visible, shape, wallForEdit]);

  return (
    <DrawerWallContainer
      placement={placement}
      onClose={cleanUpAndClose}
      visible={visible}
    >
      <Box display='flex' alignItems='center' padding={24}>
        <SingleIconWrapper onClick={cleanUpAndClose}>
          <FaChevronLeft className='cursor' size={20} color='#ffffff' />
        </SingleIconWrapper>
        <Box display='flex' justifyContent='center' flex={1}>
          <SingleText align='center' size={22} weight={500}>{type} Wall</SingleText>
        </Box>
      </Box>
      <WallShapeContainer>
        <SingleText weight={600} spacing='-0.34px' marginBottom={29}>Choose wall shape</SingleText>
        {/* <Space size={33}> */}
        <WallSpace>
          {[
            [Tables.geometry.shapes.horizontal, makeShapeHorizontal],
            [Tables.geometry.shapes.vertical, makeShapeVertical]
          ].map(([shapeDirection, onClickHandler], _i) =>
            <Box key={_i} className={`wall-shape ${isEqualToCurrentShape(shapeDirection) ? 'active' : 'inactive'}`} onClick={onClickHandler}>
              <Box width={Tables.geometry.isHorizontal(shapeDirection) ? 50 : 15} height={Tables.geometry.isHorizontal(shapeDirection) ? 15 : 50} background='#d8d9da' />
            </Box>
          )}
        </WallSpace>
        {/* </Space> */}
      </WallShapeContainer>
      <Box display='flex' flexDirection='column' marginTop='auto' padding={24}>
        <SingleButton
          title={type === 'Add' ? 'Add Wall' : 'Save'}
          margin='0 0 20px'
          btnColor={colors.redColor}
          txtColor='white'
          onClick={() => type === 'Add' ? addWallClicked() : submitUpdatedWall()}
        />
        <SingleButton
          title={'Cancel'}
          btnColor='transparent'
          txtColor={colors.redColor}
          onClick={cleanUpAndClose}
        />
      </Box>
    </DrawerWallContainer>
  )
}

DrawerWall.propTypes = {
  type: PropTypes.string,
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickAddNewCategory: PropTypes.func,
  onClickSaveCategory: PropTypes.func,
  onClickRemoveCategory: PropTypes.func,
};
