import React from 'react';
import PropTypes from 'prop-types';
import { SpecialInstructionsContainer } from './style';
import SingleText from 'components/SingleText';

export function SpecialInstructions({ instructions = '' }) {
  return (
    <SpecialInstructionsContainer>
      <SingleText
        lineHeight='21px'
        spacing='-0.34px'
        weight='bold'
        marginBottom={14}
      >
        Special Instructions
      </SingleText>
      <p className='special-instructions-text'>{instructions}</p>
    </SpecialInstructionsContainer>
  );
}

SpecialInstructions.propTypes = {
  instructions: PropTypes.string,
};
