import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { OtherConfirmContainer } from './style';
import { OtherCompletedAlert } from './OtherCompletedAlert';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';

export function OtherConfirmRequest({ visible, onClose }) {
  const context = useContext(modalContext);

  function OtherConfirmRequestFunc() {
    context.setModal('otherRequestModal-2')
  }

  return (
    <>
      <OtherConfirmContainer
        visible={visible}
        placement='right'
        onClose={onClose}
      >
        {context.modal === 'otherRequestModal-1' ? (
          <>
            <div className='other-confirm-wrapper animate__animated animate__fadeInUp'>
              <div>
                <p className='other-confirm-heading'>Complete Request</p>
              </div>

              <div>
                <p className='other-confirm-delivered'>
                  Only mark this request as complete if you <br />
                  have already fulfilled it for the customer.
                </p>
              </div>

              <div className='other-confirm-btn-wrapper'>
                <Button
                  variant='none'
                  className='other-confirm-btn'
                  onClick={OtherConfirmRequestFunc}
                >
                  Complete Request
                </Button>
              </div>

              <div className='other-confirm-goback-btn-wrapper'>
                <p
                  className='other-confirm-goback-btn'
                  onClick={onClose}
                  aria-hidden='true'
                >
                  Go back
                </p>
              </div>
            </div>
          </>
        ) : (
          <OtherCompletedAlert onClose={onClose} />
        )}
      </OtherConfirmContainer>
    </>
  );
}

OtherConfirmRequest.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
