import React from 'react';
import PropTypes from 'prop-types';
import { BoxContainer } from './style';

const Box = React.forwardRef(
  ({ children, className, framer, onClick = () => {}, ...otherProps }, ref) => (
    <BoxContainer
      ref={ref}
      className={className}
      {...framer}
      style={{ ...otherProps }}
      onClick={onClick}
    >
      {children}
    </BoxContainer>
  )
);

export default Box;

Box.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  framer: PropTypes.object,
  onClick: PropTypes.func,
};
