import React from 'react'
import PropTypes from 'prop-types';
import Image from 'react-bootstrap/Image'

export default function SingleImage({ 
  src,
  size = 67,
  style,
}) {
  return (
    <Image src={src} width={size} height={size} style={style} roundedCircle />
    // <Avatar shape={shape} size={size} src={src} style={style} />
  )
}

SingleImage.propTypes = {
  src: PropTypes.any,
  shape: PropTypes.string,
  size: PropTypes.any,
  style: PropTypes.object,
};
