import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerCuisineWrapper } from './style';
import { cuisineServices } from 'services/api-services';
import { getJwt } from 'services/jwt';
import { showMessage } from 'services/functions';

import { cuisinesTitles } from 'assets/images/profile';

export function DrawerCuisine({
  visible = false,
  placement = 'right',
  onClose = () => {},
  onChange = () => {},
  currentCuisines = [],
}) {
  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [cuisinesList, setCuisinesList] = useState([]);
  const [isCuisineListFetched, setIsCuisineListFetched] = useState(false);
  const fetched = () => setIsCuisineListFetched(true);

  useEffect(() => {
    if (visible) {
      setSelectedCuisines(currentCuisines);
      if (!isCuisineListFetched) {
        const onSuccess = (data) => {
          setCuisinesList(data);
          fetched();
        };

        fetchCuisineList(onSuccess);
      }
    }
  }, [visible, currentCuisines, isCuisineListFetched]);

  function toggleSelectedCuisines(title) {
    if (selectedCuisines.includes(title)) {
      setSelectedCuisines([
        ...selectedCuisines.filter((_title) => _title !== title),
      ]);
    } else if (selectedCuisines.length < 3) {
      setSelectedCuisines([...selectedCuisines, title]);
    }
  }

  const onDone = (cuisines) => {
    onChange(cuisines);
    onClose();
  };

  return (
    <DrawerCuisineWrapper
      onClose={onClose}
      visible={visible}
      placement={placement}
    >
      <div className='cuisine-panel'>
        <div className='panel-header-container'>
          <div className='back-arrow' onClick={onClose}>
            <i className='fas fa-chevron-left' />
          </div>
          <div className='panel-header-context'>Cuisine</div>
        </div>
        <div className='panel-status-container'>
          <p className='select-message'>Select up to 3</p>
          <div className='cuisine-container'>
            {cuisinesTitles.map(({ title, image }, index) => {
              return (
                <div
                  className={`cuisine-item ${
                    selectedCuisines.includes(title) ? 'selected' : ''
                  } ${
                    selectedCuisines.length === 3 &&
                    !selectedCuisines.includes(title)
                      ? 'unselected'
                      : ''
                  }`}
                  key={title + index}
                  onClick={() => toggleSelectedCuisines(title)}
                >
                  <div
                    className='cuisine-icon'
                    style={{ backgroundImage: `url(${image})` }}
                  />
                  <span className='internal-title'>{title}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className='button-container'>
          <button
            type='button'
            className={`done-button ${
              selectedCuisines.length === 3 ? 'active' : ''
            }`}
            onClick={
              selectedCuisines.length === 3
                ? () =>
                    onDone({
                      cuisines: selectedCuisines,
                      cuisinesIds: findCuisinesIds(
                        selectedCuisines,
                        cuisinesList
                      ),
                    })
                : () => {}
            }
          >
            Done
          </button>
        </div>
      </div>
    </DrawerCuisineWrapper>
  );
}

export default DrawerCuisine;

DrawerCuisine.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  cuisineList: PropTypes.array,
  currentCuisines: PropTypes.array,
};

export const fetchCuisineList = async (setCuisineList) => {
  const currentJwt = getJwt();

  if (currentJwt) {
    const onOk = (data) => {
      setCuisineList(data);
    };

    const onFail = (errorMessage) => {
      showMessage(errorMessage, 'error');
    };

    return await cuisineServices.getCuisineList(onOk, onFail);
  }
};

export const findCuisinesIds = (selectedCuisines, cuisineList) => {
  return selectedCuisines
    .map((title) => cuisineList.find(({ name }) => name === title))
    .filter((cuisine) => !!cuisine)
    .map(({ id }) => id);
};
