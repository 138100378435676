import styled from '@emotion/styled'

export const ItemPickerContainer = styled.div`
  width: 100%;
  height: 56px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: ${props => props.isIn ? 'center' : 'flex-end'};
  padding: ${props => props.padding};
  cursor: pointer;
`;
