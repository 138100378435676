import React, { useContext } from 'react'
import PropTypes from 'prop-types';
import Box from 'components/Box';
import { FaChevronLeft } from 'react-icons/fa';
import SingleText from 'components/SingleText';
// import SingleAntButton from 'components/SingleAntButton';
import { DrawerRolesContainer, SpaceStaff } from './style';
// import { colors } from 'theme';
import SingleIconWrapper from 'components/SingleIconWrapper';
// import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';

const options = [
  {
    id: '1',
    value: 'manager',
    name: 'Manager / Owner',
    description: 'Full admin access to management tools.',
  },
  {
    id: '2',
    value: 'staff',
    name: 'Kitchen Staff',
    description: 'Access to manage menu and view orders.',
  },
  {
    id: '3',
    value: 'waiter',
    name: 'Server / Host',
    description: 'View orders and check in customers.',
  },
];

export default function DrawerRolesToEdit({
  visible = false,
  placement = 'right',
  onClose = () => { },
}) {

  // const { setOpenDrawerInviteStaff } = useContext(StateContext);
  const { chosenStaff, setChosenStaff } = useContext(DataContext)

  const choseRole = (newRole) => {
    setChosenStaff({ ...chosenStaff, role_slug: newRole })
    onClose();
  }

  return (
    <DrawerRolesContainer
      onClose={onClose}
      visible={visible}
      placement={placement}
    >
      <Box is='div' display='flex' alignItems='center' marginBottom={60}>
        <SingleIconWrapper onClick={onClose}>
          <FaChevronLeft size={20} color='white' />
        </SingleIconWrapper>
        <Box is='div' display='flex' justifyContent='center' flex={1}>
          <SingleText content='Choose Role' align='center' size={22} weight={500}>Choose Role</SingleText>
        </Box>
      </Box>
      <SingleText content='Each role has different permissions.' size={14} marginBottom={45}>Each role has different permissions.</SingleText>
      <Box is='div' className='drawer-role--options'>
        {/* <Space size={15} direction='vertical'> */}
        <SpaceStaff>
          {options.map((_option, _i) =>
            <Box key={_i} className={`drawer-role--option ${chosenStaff.role_slug === _option.value ? 'active' : 'inactive'}`} onClick={() => choseRole(_option.value)}>
              <SingleText content={_option.name} size={14} weight={600} marginBottom={8} spacing='-0.34'>{_option.name}</SingleText>
              <SingleText content={_option.description} size={14} spacing='-0.34'>{_option.description}</SingleText>
            </Box>
          )}
        </SpaceStaff>
        {/* </Space> */}
      </Box>
    </DrawerRolesContainer>
  )
}

DrawerRolesToEdit.propTypes = {
  menus: PropTypes.array,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickDay: PropTypes.func,
  onClickContinue: PropTypes.func,
};
