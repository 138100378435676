import styled from '@emotion/styled';
import { motion } from "framer-motion";

export const TimeStampContainer = styled(motion.div)`
    display: flex;
    color: rgba(255, 255, 255, 0.6);
    min-width: 75px;

    i,
    span{
      font-size: 15px;
      line-height: 14px;
      letter-spacing: -0.36px;
    }

    i{
      color: inherit;
      margin-right: 5px;
    }
`;
