import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerClassDetailsContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  padding: 37px 26px 20px;
  height: ${({ customHeight }) => customHeight}px!important;
  display: flex;
  flex-direction: column;
  align-items: center;

  .combined-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .combined-left,
    .combined-right {
      width: 110px;
    }

    .combined-right {
      &.cvv {
        .card-input-view {
          font-size: 16px;
          font-weight: bolder;
        }
      }
    }
  }

  .error-message-container {
    margin-top: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 13px;
      color: ${colors.redColor};
    }

    .error-message {
      font-size: 13px;
      color: white;
      font-weight: 600;
      margin-left: 15px;
    }
  }
`;
