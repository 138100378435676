import { useEffect, useContext, useState } from 'react';
import { showMessage } from 'services/functions';
import { restaurantServices } from 'services/api-services';
import { AuthContext } from 'contexts/AuthContextContainer';
import { DataContext } from 'contexts/DataContextContainer';

export const useMenus = (
  loading = { start: () => {}, end: () => {} },
  onUserOk = () => {},
  onUserFail = () => {}
) => {
  const { restaurantId } = useContext(AuthContext);
  const { setMenus } = useContext(DataContext);

  const [cloneMenus, setCloneMenus] = useState();

  useEffect(() => {
    async function getAllMenus() {
      if (cloneMenus === undefined && restaurantId) {
        loading.start();

        const onOk = (data) => {
          setCloneMenus(data);
          onUserOk(data);
          loading.end();
        };

        const onFail = (errorMessage) => {
          showMessage(errorMessage, 'error');
          onUserFail(errorMessage);
          loading.end();
        };

        return await restaurantServices._getMenus(restaurantId, onOk, onFail);
      }
    }

    getAllMenus();
  }, [cloneMenus, setCloneMenus, restaurantId, loading, onUserOk, onUserFail]);

  useEffect(() => {
    if (cloneMenus !== undefined) {
      setMenus(cloneMenus);
    }
  }, [cloneMenus, setMenus]);
};
