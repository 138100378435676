import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerCheckInContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  .drawer-checkin--options {
    display: flex;
    flex-direction: row;
    align-items: center;
    .drawer-checkin--option {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 22px 26px;
      border-radius: 16px;
      border: solid 1px rgba(255, 255, 255, 0.1);
      &.active {
        border: solid 1px rgba(255, 255, 255, 1);
        transition: 0.5s ease;
      }
      &.inactive {
        border: solid 1px rgba(255, 255, 255, 0.1);
        transition: 0.5s ease;
        cursor: pointer;
      }
    }

    .verify-code-input {
      width: 100% !important;
      > div {
        display: flex;
        justify-content: space-between;
        input {
          border-radius: 7px;
          border: solid 1px rgba(255, 255, 255, 0.3);
          background-color: #3e4245;
          -webkit-transition: background-color 300ms linear;
          -ms-transition: background-color 300ms linear;
          transition: background-color 300ms linear;
          font-family: Montserrat;
          font-weight: 500;
          font-size: 20px;
          color: white;
          caret-color: white;
        }
      }
      &.invalid {
        > div {
          input {
            border: solid 1px ${colors.redColor};
          }
        }
      }
      &.valid {
        > div {
          input {
            background-color: ${colors.green};
            -webkit-transition: background-color 500ms linear;
            -ms-transition: background-color 500ms linear;
            transition: background-color 500ms linear;
          }
        }
      }
    }
  }
  .available-tables {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    span {
      margin-left: 5px;
      color: #fff;
    }
    i {
      color: #ed2626;
    }
    &.filled {
      i {
        color: #1fc086;
      }
    }
  }
`;
