import React from 'react';
import PropTypes from 'prop-types';
import { SelectTableHeaderContainer } from './style';

export function SelectTableHeader({
  onClose,
  getTotalAvailableTables,
  changeToLayoutView,
  changeToListView,
  isLayoutViewActive,
  isListViewActive,
}) {
  return (
    <SelectTableHeaderContainer>
      <div className='panel-header-container'>
        <div className='back-arrow' onClick={onClose} aria-hidden='true'>
          <i className='fas fa-chevron-left' />
        </div>
        <div className='panel-header-context'>Select Table</div>
      </div>

      <div className='available-tables-message'>
        <i className='fas fa-circle' />
        <span>{getTotalAvailableTables()} Tables Available</span>
      </div>

      <div className='display-variant-nav'>
        <div
          name='Layout'
          className={`display-variant ${isLayoutViewActive() ? 'active' : ''}`}
          onClick={changeToLayoutView}
          aria-hidden='true'
        >
          Layout
        </div>
        <div
          name='List'
          className={`display-variant ${isListViewActive() ? 'active' : ''}`}
          onClick={changeToListView}
          aria-hidden='true'
        >
          List
        </div>
      </div>
    </SelectTableHeaderContainer>
  );
}

SelectTableHeader.propTypes = {
  onClose: PropTypes.func,
  getTotalAvailableTables: PropTypes.func,
  changeToLayoutView: PropTypes.func,
  changeToListView: PropTypes.func,
  isLayoutViewActive: PropTypes.func,
  isListViewActive: PropTypes.func,
};
