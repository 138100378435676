import styled from '@emotion/styled';

export const AssignContainer = styled.div`
  margin-top: auto;
  padding: 0px 26px 33px;
  display: flex;
  flex-direction: column;

  .btn-assign {
    margin-top: 136px;
  }
`;
