import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StateContext } from 'contexts/StateContextContainer';
// import { DataContext } from 'contexts/DataContextContainer';
import { DrawerDeleteFullOrderWrapper } from './style';

const DrawerDeleteFullOrder = ({
    visible = false,
    placement = 'bottom',
    // onClose = () => { },
}) => {
    const { setOpenDrawerDeleteFullOrder, setOpenDrawerDeleteFullOrderCheck } = useContext(StateContext);

    function deleteFullOrderItem() {
        setOpenDrawerDeleteFullOrderCheck(true);
        // setOrderItemForDelete('')
        // setOpenDrawerOrderItemDeleteCheck(true);
    }

    function keepFullOrderItems() {
        setOpenDrawerDeleteFullOrder(false);
    }

    return (
        <DrawerDeleteFullOrderWrapper
            onClose={keepFullOrderItems}
            visible={visible}
            placement={placement}
            height="auto"
        >
            <div className={`delete-day-block`}>
                <div className="head-block">
                    <h3>Delete Full Order</h3>
                </div>
                <p>
                    Are you sure you want to delete <b>Full Order</b>?
                </p>
                <p>
                    This cannot be undone.
                </p>
                <button className="button-save" onClick={() => deleteFullOrderItem()}>Remove</button>
                <button className="button-cancel" onClick={keepFullOrderItems}>Keep</button>
            </div>

        </DrawerDeleteFullOrderWrapper>
    )
}

export default DrawerDeleteFullOrder;

DrawerDeleteFullOrder.propTypes = {
    visible: PropTypes.bool,
    placement: PropTypes.string,
    onClose: PropTypes.func,
};