export class CompletedOrdersPeriods {
  static periods = {
    today: 'Today',
    yesterday: 'Yesterday',
    custom: 'Custom',
  };

  static allPeriods = [...Object.values(CompletedOrdersPeriods.periods)];

  static isEqual(firstPeriod, secondPeriod) {
    const periodsIsValid =
      CompletedOrdersPeriods.allPeriods.includes(firstPeriod) &&
      CompletedOrdersPeriods.allPeriods.includes(secondPeriod);

    if (periodsIsValid) {
      return firstPeriod === secondPeriod;
    } else {
      return false;
    }
  }

  static initialValue() {
    return CompletedOrdersPeriods.periods.today;
  }
}
