import React from 'react';
import PropTypes from 'prop-types';
import { DrawerRemoveTableContainer } from './style';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import DrawerDeleteTable from './DrawerDeleteTable';

export default function DrawerRemoveTable({
  visible = false,
  placement = 'right',
  onClose = () => {},
  onDelete = () => {},
}) {
  const onKeep = () => {
    onClose();
  };

  const onRemove = () => {
    onDelete();
    onClose();
  };

  return (
    <DrawerRemoveTableContainer
      visible={visible}
      placement={placement}
      onClose={onClose}
    >
      <Box className='drawer-remove-table-top'>
        <SingleText size={22} weight={500}>
          Remove Table
        </SingleText>
      </Box>
      <DrawerDeleteTable onKeep={onKeep} onRemove={onRemove} />
    </DrawerRemoveTableContainer>
  );
}

DrawerRemoveTable.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};
