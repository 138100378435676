import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { nutritions } from 'services/static';
import { DrawerDetailNutritionForNewItemInOrderContainer } from './style';
import { colors } from 'theme';
// import SingleButton from 'components/SingleButton';
import { FoodNutritionDetail } from './FoodNutritionDetail';
export function DrawerDetailNutritionForNewItemInOrder({ visible, onClose }) {
  const [showMore, setShowMore] = useState(true);
  const [showLess, setShowLess] = useState(false);

  function showMoreFunc() {
    setShowMore(false);
    setShowLess(true);
  }

  function showLessFunc() {
    if (showMore === false) {
      setShowMore(true);
      setShowLess(false);
    }
  }

  return (
    <DrawerDetailNutritionForNewItemInOrderContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='top-part'>
        <span className='nutrition-title'>Nutrition</span>
        <div className='close-icon' onClick={onClose}>
          <i className='fas fa-times' style={{ cursor: 'pointer' }} />
        </div>
      </div>

      {Object.keys(nutritions)
        .filter((_key) => _key !== 'extras')
        .map((_nutrition, _i) => (
          <FoodNutritionDetail
            key={_i}
            label={nutritions[_nutrition].title}
            percentages={nutritions[_nutrition].percentages}
            digit={nutritions[_nutrition].digit}
            unit={nutritions[_nutrition].weight_type}
          />
        ))}

      {showMore === false ? (
        <>
          {nutritions &&
            nutritions.extras.map((_extra, _i) => (
              <FoodNutritionDetail
                key={_i}
                label={_extra.title}
                unit={_extra.weightType}
                percentages={_extra.percentage}
              />
            ))}
        </>
      ) : null}

      <p className='description'>
        The % Daily Value (DV) tells you how much a nutrient in a serving of
        food contributes to a daily diet. 2,000 calories a day is used for
        general nutrition advice.
      </p>
      {/* <SingleButton
        title='Show more--'
        btnColor='transparent'
        txtColor={colors.redColor}
        onClick={onClose}
      /> */}
      {showMore ? (
        <p
          className='text-center'
          style={{
            color: `${colors.redColor}`,
            cursor: 'pointer',
            fontWeight: '600',
          }}
          onClick={showMoreFunc}
        >
          Show more
        </p>
      ) : (
        ''
      )}
      {showLess ? (
        <p
          className='text-center'
          style={{
            color: `${colors.redColor}`,
            cursor: 'pointer',
            fontWeight: '600',
          }}
          onClick={showLessFunc}
        >
          Show less
        </p>
      ) : (
        ''
      )}
    </DrawerDetailNutritionForNewItemInOrderContainer>
  );
}

DrawerDetailNutritionForNewItemInOrder.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  nutrition: PropTypes.array,
};
