import styled from '@emotion/styled';
import { colors } from 'theme';

export const CompleteProfileContainer = styled.div`
  .logo-img {
    /* margin-bottom: 40px; */
    margin-bottom: 20px;
  }

  .complete-your-profile-title {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    color: white;
    /* margin-bottom: 40px; */
    margin-bottom: 20px;
  }
  .form-container {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .form-input-container {
      /* margin-top: 40px; */
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .form-group {
        width: 100%;
        margin-bottom: 17px;

        .form-label {
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 15px;
        }

        .form-control {
          height: 50px;
          border-radius: 7px;
          border: solid 1px rgba(255, 255, 255, 0.1);
          padding: 15px 20px;
          background-color: #1b1c1d;
          color: #fff;
        }
        .form-control:focus {
          box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 25%);
        }
      }
    }
  }

  .form-container {
    .ant-row.ant-form-item-has-error {
      .ant-input {
        background-color: ${colors.gray2};
      }
    }
    .single-ant-button {
      width: 100%;
      max-width: unset;
    }
    .ant-form-item-label {
      text-align: left;
      label {
        height: unset;
        font-size: 16px;
        font-family: 'Montserrat';
        font-weight: bold;
        &::before,
        &::after {
          display: none;
        }
      }
    }
    .ant-form-item-control {
      .ant-form-item-explain {
        > div {
          text-align: left;
        }
      }
    }
  }
  .btn-continue {
    /* margin-top: 23px; */
    margin-top: 20px;
    /* margin-bottom: 123px; */
  }
`;
