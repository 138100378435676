import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerPartialRefundContainer = styled(SingleDrawer)`
  padding: 35px 0px 23px;
  height: ${({ customHeight }) => customHeight}px !important;
  background-color: ${colors.gray5};
  display: flex;
  flex-direction: column;
  align-items: center;

  .refund-options {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    display: flex;

    .refund-option {
      cursor: pointer;
      width: 25%;
      height: 70px;
      border-right: 1px solid rgba(255, 255, 255, 0.08);
      text-align: center;
      padding-top: 15px;
      display: flex;
      align-items: center;
      flex-direction: column;

      &:last-of-type {
        padding-top: 24px;
      }

      &.selected {
        background-color: ${colors.redColor};
      }

      .refund-option-title {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: -0.36px;
        color: white;
        margin-bottom: 5px;
      }

      .refund-option-dollars {
        font-size: 13px;
        letter-spacing: -0.31px;
        color: white;
        text-align: center;
      }
    }
  }

  .other-container {
    width: 100%;
    padding: 22px 33px 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;

    .type-container {
      cursor: pointer;
      display: flex;
      flex-direction: column;

      &.active {
        .type-title,
        .type-value,
        .type-value-title {
          color: white !important;
        }
      }

      &.percentage {
        align-items: flex-end;

        .type-value {
          width: 33px;
          text-align: right;
        }
      }

      .type-title {
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.34px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 2px;
      }

      .type-value-container {
        display: flex;
        align-items: center;

        .type-value-title,
        .type-value {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.34px;
          color: rgba(255, 255, 255, 0.5);
        }

        .type-value {
          -moz-appearance: textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          background-color: transparent;
          border: 0px solid transparent;
          outline: none;
          padding-top: 0px;
          padding-bottom: 0px;
          padding-left: 4px;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  .will-be-refunded {
    margin-top: 48px;
    width: 100%;
    text-align: center;

    &.with-other {
      margin-top: 48px;
    }

    .refunded {
      text-align: center;
      font-size: 13px;
      letter-spacing: 0.25px;
      color: white;
    }
  }

  .issue-refund-container {
    width: 100%;
    padding-left: 26px;
    padding-right: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
