import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StateContext } from 'contexts/StateContextContainer';
// import { DataContext } from 'contexts/DataContextContainer';
import { DrawerDeleteFullOrderCheckWrapper } from './style';

const DrawerDeleteFullOrderCheck = ({
    visible = false,
    placement = 'bottom',
    // onClose = () => { },
}) => {
    const { setOpenDrawerDeleteFullOrderCheck, setOpenDrawerDeleteFullOrder, setOpenOrderDeletedDrawer } = useContext(StateContext);

    function deleteFullOrderItem() {
        setOpenDrawerDeleteFullOrderCheck(false);
        setOpenDrawerDeleteFullOrder(false);
        setOpenOrderDeletedDrawer(true);
    }

    function keepFullOrderItems() {
        setOpenDrawerDeleteFullOrderCheck(false);
        setOpenDrawerDeleteFullOrder(false);
    }

    return (
        <DrawerDeleteFullOrderCheckWrapper
            onClose={keepFullOrderItems}
            visible={visible}
            placement={placement}
            height="auto"
        >
            <div className={`delete-day-block`}>
                <div className="head-block">
                    <h3>Are you sure?</h3>
                </div>
                <p>
                    <b>Full Order</b> will be deleted, this can’t be undone.?
                </p>
                <button className="button-save" onClick={() => deleteFullOrderItem()}>Yes, Delete Order</button>
                <button className="button-cancel" onClick={keepFullOrderItems}>No, Keep Order</button>
            </div>

        </DrawerDeleteFullOrderCheckWrapper>
    )
}

export default DrawerDeleteFullOrderCheck;

DrawerDeleteFullOrderCheck.propTypes = {
    visible: PropTypes.bool,
    placement: PropTypes.string,
    onClose: PropTypes.func,
};