import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { CategoriesSwitcherContainer } from './style';
import { DataContext } from 'contexts/DataContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';
import { restaurantServices } from 'services/api-services';
import { showMessage } from 'services/functions';
import { CategoriesSelect } from './CategoriesSelect';
import { CategoriesTopList } from './CategoriesTopList';
import { CategoriesChanges } from './CategoriesChanges';

export function CategoriesSwitcher({ isMenusLoaded }) {
  const history = useHistory();
  const { menus, setMenus, chosenMenu } = useContext(DataContext);
  const { authUser } = useContext(AuthContext);

  const [currentMenuStatus, setCurrentMenuStatus] = useState();

  useEffect(() => {
    const currentMenu = menus.find((menu) => menu.id === chosenMenu);
    if (currentMenu) setCurrentMenuStatus(currentMenu.status);
  });

  const goToSelectedMenu = (menuPage) => {
    if (menuPage) {
      history.push(`/menu/${menuPage}`);
    } else {
      history.push('/menu');
    }
  };

  const onChangeChosenMenu = (menuPage, menus) => {
    const allMenus = menus.map(({ id }) => id);

    if (menuPage === 0 || !allMenus.includes(menuPage)) {
      goToSelectedMenu();
    } else {
      goToSelectedMenu(menuPage);
    }
  };

  const onChangeMenuStatus = async () => {
    const { ok, errMessage } = await restaurantServices.updateMenu(
      { restaurantId: authUser.restaurant_id, menuId: chosenMenu },
      { status: 1 }
    );
    if (ok) {
      let tempMenus = [...menus];
      setMenus(
        tempMenus.map((_menu) => {
          if (_menu.id === chosenMenu) {
            return { ..._menu, status: 1 };
          }
          return _menu;
        })
      );
    } else {
      showMessage(errMessage, 'error');
    }
  };

  return (
    <>
      <CategoriesSwitcherContainer>
        <CategoriesTopList onChangeChosenMenu={onChangeChosenMenu} />
        {isMenusLoaded === true && (
          <CategoriesSelect onChangeChosenMenu={onChangeChosenMenu} />
        )}
      </CategoriesSwitcherContainer>
      {currentMenuStatus === 0 && chosenMenu !== 0 && (
        <CategoriesChanges onChangeMenuStatus={onChangeMenuStatus} />
      )}
    </>
  );
}

CategoriesSwitcher.propTypes = {
  isMenusLoaded: PropTypes.bool,
};
