const messageBoxItem = (message, type) => {
    document.getElementById("root").appendChild(messageElement(message, type));
    setTimeout(() => {
        document.getElementById("message-container-id").remove();
    }, 5000)

    function messageElement(message, type) {
        // Create Warning Box
        const mainNode = document.createElement("div");
        mainNode.setAttribute("id", "message-container-id");
        mainNode.className = "message-box-custom";

        const innerNode = document.createElement("div");
        innerNode.className = "message-box-custom-inner";
        mainNode.appendChild(innerNode);

        // Append Icon
        const iconContainer = document.createElement("i");
        if (type === 'success') { iconContainer.className = "fas fa-check-circle"; }
        else if (type === 'error') { iconContainer.className = "fas fa-times-circle"; }
        else if (type === 'warning') { iconContainer.className = "fas fa-exclamation-circle"; }
        else { iconContainer.className = "fas fa-info-circle"; }
        innerNode.appendChild(iconContainer);

        // Append Text
        const messageContainer = document.createElement("span");
        const messageText = document.createTextNode(message);
        messageContainer.appendChild(messageText);
        innerNode.appendChild(messageContainer);

        return mainNode;
    }
}

export default messageBoxItem;