import React, { useContext } from 'react';
import { FoodContainer } from './style';
import { DefaultFood } from 'assets/images/menu';
import { DataContext } from 'contexts/DataContextContainer';

export function Food() {
  const {selectedFood} = useContext(DataContext);
  return (
    <FoodContainer backgroundImage={selectedFood.photo?.file ?? DefaultFood}>
      <div className='food-empty-main'>Food Main</div>
    </FoodContainer>
  );
}
