import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StateContext } from 'contexts/StateContextContainer';
// import { DataContext } from 'contexts/DataContextContainer';
import { DrawerCompleteOrderWrapper } from './style';

const DrawerCompleteOrder = ({
    visible = false,
    placement = 'bottom',
    onClose = () => { },
}) => {
    const { setOpenOrderCompletedDrawer } = useContext(StateContext);

    function orderWasCompleted() {
        setOpenOrderCompletedDrawer(true);
        // setOrderItemForDelete('')
        // setOpenDrawerOrderItemDeleteCheck(true);
    }

    return (
        <DrawerCompleteOrderWrapper
            onClose={onClose}
            visible={visible}
            placement={placement}
            height="auto"
        >
            <div className={`delete-day-block`}>
                <div className="head-block">
                    <h3>Complete Order</h3>
                </div>
                <p>
                    Has this Order been completed?
                </p>
                <button className="button-save" onClick={() => orderWasCompleted()}>Complete Order</button>
                <button className="button-cancel" onClick={onClose}>Go Back</button>
            </div>

        </DrawerCompleteOrderWrapper>
    )
}

export default DrawerCompleteOrder;

DrawerCompleteOrder.propTypes = {
    visible: PropTypes.bool,
    placement: PropTypes.string,
    onClose: PropTypes.func,
};