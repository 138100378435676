import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BankAccountContainer } from './style';
import { useBankAccount } from 'hooks';
import { DrawerBankAccount } from './DrawerBankAccount';

// @TODO Make it for Host/Server accounts only
export default function BankAccount() {
  const [openDrawerBankAccount, setOpenDrawerBankAccount] = useState(true);
  const onCloseDrawerBankAccount = () => setOpenDrawerBankAccount(false);

  const {
    bankAccount,
    changeBankAccount,
    clearBankAccount,
    isBankAccountEmpty,
  } = useBankAccount();

  return (
    <BankAccountContainer>
      <DrawerBankAccount
        visible={openDrawerBankAccount}
        currentBankAccount={bankAccount}
        onClose={onCloseDrawerBankAccount}
        bankAccount={bankAccount}
        addBankAccount={changeBankAccount}
        clearBankAccount={clearBankAccount}
        isBankAccountEmpty={isBankAccountEmpty()}
      />
    </BankAccountContainer>
  );
}

BankAccount.propTypes = {
  match: PropTypes.object,
};
