import React from 'react';
import PropTypes from 'prop-types';
import { CompletedNotification } from '../CompletedNotification';

export function CompletedOrderFoodItems({ activeOrder }) {
  return (
    <>
      {activeOrder.items.map((foodItem, index) => (
        <CompletedNotification
          foodItem={foodItem}
          key={foodItem.id + index + foodItem.name}
        />
      ))}
    </>
  );
}

CompletedOrderFoodItems.propTypes = {
  activeOrder: PropTypes.object,
};
