import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RequestCompletedListContainer } from './style';
import Pagination from 'components/Pagination';
import { OopsAddingFailed } from 'assets/images/menu';
import { RequestPendingContext } from 'contexts/RequestPendingContextContainer';
import TimeStamp from 'components/TimeStamp';
export function RequestCompletedList({ handleClick }) {
  const [isActiveBackgroundCompleted, setIsActiveBackgroundCompleted] = useState(false);
  const {
    completedList,
    setSelectedCompletedData,
    rejectDrinkRefresh,
    completedInfo,
    refreshOtherResolve,
  } = useContext(RequestPendingContext);
  const onAdd = (selectedCompletedItem) => setSelectedCompletedData(selectedCompletedItem)

  useEffect(() => {}, [rejectDrinkRefresh])

  // const {request_type} = completedList
  // console.log(completedList, 'completedList from other')
  // console.log(completedInfo, 'completedInfo from other')

  useEffect(() => {}, [refreshOtherResolve])

  return (
    <RequestCompletedListContainer>
      {completedList ? (
        <>
          <div className='main'>
            {completedList.length > 0 && completedList.map((completedItem, index) => {
              return (
                <div
                  className='request-item'
                  key={index}
                >
                  <div
                    className={`completed-dark-card ${completedItem.id === isActiveBackgroundCompleted ? 'active' : 'inactive'}`}
                    onClick={(e) => {
                      setIsActiveBackgroundCompleted(completedItem.id);
                      handleClick(index);
                      onAdd(completedItem)
                    }}
                    aria-hidden='true'
                  >
                    <div className='completed-flex-inline'>
                      <div className='completed-inner-flex-inline'>
                        <div>
                          <span
                            className='badge completed-badge-secondary'
                            style={{
                              backgroundColor:
                                completedItem.status !== 2
                                  ? '#1fc086'
                                  : '#ed2626',
                            }}
                          >
                            <i
                              className={
                                 (completedItem.request_type === 0 && 'far fa-glass-cheers') ||
                                 (completedItem.request_type === 1 && 'far fa-exchange-alt') ||
                                 (completedItem.request_type === 2 && 'far fa-exclamation-circle')
                              }
                              id='completed-icon-style'
                            ></i>
                          </span>
                        </div>
                        <div className='completed-padding-horizontal'></div>
                         {/* id for test */}
                         {/* <p>{completedItem.id}</p>{' '} */}
                        <div className='completed-line-height'>
                          <div>
                            <p className='completed-title'>
                              { completedItem.request_type === 0 && 'Drink Refill Request'||
                                completedItem.request_type === 1 && 'Return Request'||
                                completedItem.request_type === 2 && 'Other Request'
                              }
                            </p>
                          </div>
                          <div>
                            <p className='completed-description'>
                              Table {completedItem.table_number}{' '}•{' '}
                              #{completedItem.token}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className='completed-clock-wrapper'>
                        {/* <i className='fad fa-clock'></i> */}
                        <span><TimeStamp date={Date.now() - (completedItem.wait_seconds * 1000)} /></span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <Pagination
            list={['First item', 'Second item', 'Third item']}
            className='request-to-handle-pagination'
            limitPerPage={1}
            onChange={(itemsData) =>
              // eslint-disable-next-line no-console
              console.log('items for this page', itemsData)
            }
          />
        </>
      ) : (
        <div className='completed-image-wrapper'>
          <img src={OopsAddingFailed} alt='requests-pending-icon' />
          <p className='completed-illustration-text'>
            Completed requests <br /> will show here.
          </p>
        </div>
      )
      }
    </RequestCompletedListContainer>
  );
}

RequestCompletedList.propTypes = {
  handleClick: PropTypes.func,
};
