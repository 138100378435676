import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RejectedAlertDrinkContainer } from './style';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';

export function RejectedAlertDrink({ onClose }) {
  const context = useContext(modalContext);
  return (
    <RejectedAlertDrinkContainer>
      {context.modal === 'Reject-Drink-Modal-4' ? (
        <div className='rejected-alert-drink-wrapper animate__animated animate__fadeInUp'>
          <div className='rejected-alert-drink-svg-position'>
            <svg height='100' width='100'>
              <circle cx='50' cy='50' r='40' strokeWidth='3' fill='#27282a' />
            </svg>
          </div>

          <div className='rejected-alert-drink-check-wrapper'>
            <i className='far fa-times rejected-alert-drink-times'></i>
          </div>

          <div className='rejected-alert-drink-body-wrapper'>
            <div>
              <p className='rejected-alert-drink-text-style'>Refill Rejected</p>
            </div>
            <Button
              variant='none'
              className='rejected-alert-drink-okay-btn'
              onClick={onClose}
            >
              Okay
            </Button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </RejectedAlertDrinkContainer>
  );
}

RejectedAlertDrink.propTypes = {
  onClose: PropTypes.func,
};
