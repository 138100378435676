import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerFoodReviewContainer = styled(SingleDrawer)`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-color: ${colors.gray2};
  background-position: top;
  background-repeat: no-repeat;
  background-size: 435px 588px;
  padding: 0;
  position: relative;
`;
