import React from 'react';
import PropTypes from 'prop-types';
import { MiniNotificationControlContainer } from './style';

export function MiniNotificationControl({
  onBack = () => {},
  onStartEdit = () => {},
  onStartRemove = () => {},
}) {
  return (
    <MiniNotificationControlContainer>
      {[
        [<i className='fas fa-chevron-right' key='chevron' />, onBack],
        [<i className='fas fa-trash' key='trash' />, onStartRemove],
        [<i className='fas fa-pencil-alt' key='pencil' />, onStartEdit],
      ].map(([icon, onClick], index) => (
        <div className='control-item-container' onClick={onClick} key={index}>
          {icon}
        </div>
      ))}
    </MiniNotificationControlContainer>
  );
}

MiniNotificationControl.propTypes = {
  onBack: PropTypes.func,
  onStartEdit: PropTypes.func,
  onStartRemove: PropTypes.func,
};
