import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const RejectDrinkRequestPendingDrawerContainer = styled(SingleDrawer)`
  padding: 0;
  background-color: ${colors.gray2};
  font-size: 14px;
  color: white;
  overflow-y: hidden;

  .reject-drink-pending-wrapper {
    width: 100%;
    padding: 33px 26px 36px;
    border-radius: 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #27282a;
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }

  .reject-drink-request-pending-heading {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }

  .reject-drink-request-margin {
    margin: 38px 0 0 0;
  }

  #reject-drink-request-pending-select-border:hover {
    /* border: 2px solid #ed2626; */
    /* border: ${(props) => (props.isActiveBorderColor ? '#ed2626' : '')}; */
  }

  .reject-drink-request-pending-border {
    border: 1px solid #5b5e61;
    padding: 15px 0 15px 0;
    text-align: center;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 15px;
    cursor: pointer;
  }

  .reject-drink-request-pending-continue-btn {
    width: 100%;
    padding: 13px 0 13px 0;
    border-radius: 8px;
    color: white;
    background: #ed2626;
    border: none;
    font-size: 14px;
    font-weight: 600;
    margin: 35px 0 35px;
  }

  .reject-drink-request-pending-back {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #ed2626;
    cursor: pointer;
  }
`;
