import React, { createContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { getJwt } from '../services/jwt';
import AuthContextContainer from 'contexts/AuthContextContainer';
import DataContextContainer from 'contexts/DataContextContainer';
import StateContextContainer from 'contexts/StateContextContainer';
import MyRoute from './MyRoute';
import Welcome from 'pages/Welcome';
import IsProfileWasFilled from 'pages/isProfileWasFilled';
import Orders from 'pages/Orders';
import Requests from 'pages/Requests';
import Menu from 'pages/Menu';
import Login from 'pages/Login';
import PhoneVerify from 'pages/PhoneVerify';
import CompleteProfile from 'pages/CompleteProfile';
import Staff from 'pages/Staff';
import Tables from 'pages/Tables';
import Profile from 'pages/Profile';
import BankAccount from 'pages/BankAccount';
import { useState } from 'react';
import { RequestPendingContextContainer } from 'contexts/RequestPendingContextContainer';

export const modalContext = createContext();
export const ScreensContext = createContext();
export const ProfileUpdateContext = createContext();

function Routes() {
  const [modal, setModal] = useState(undefined);
  const [targetElement, setTargetElement] = useState(undefined);
  const [ProfileFirstName, setProfileFirstName] = useState();
  const [ProfileLastName, setProfileLastName] = useState();

  return (
    <Switch>
      <AuthContextContainer>
        <StateContextContainer>
          <DataContextContainer>
            <RequestPendingContextContainer>
              <modalContext.Provider value={{ modal, setModal }}>
                <ScreensContext.Provider
                  value={{ targetElement, setTargetElement }}
                >
                  <ProfileUpdateContext.Provider
                    value={{
                      ProfileFirstName,
                      setProfileFirstName,
                      ProfileLastName,
                      setProfileLastName,
                    }}
                  >
                    <Route exact path='/'>
                      {!getJwt() ? <Login /> : <Redirect to='/dashboard' />}
                    </Route>
                    <Route exact path='/sign-in'>
                      {!getJwt() ? <Login /> : <Redirect to='/dashboard' />}
                    </Route>
                    <Route exact path='/sign-up'>
                      {!getJwt() ? <Login /> : <Redirect to='/dashboard' />}
                    </Route>
                    <Route exact path='/phone-verify'>
                      {!getJwt() ? (
                        <PhoneVerify />
                      ) : (
                        <Redirect to='/dashboard' />
                      )}
                    </Route>
                    <Route
                      exact
                      path='/complete-profile'
                      component={CompleteProfile}
                    >
                      {!getJwt() ? (
                        <CompleteProfile />
                      ) : (
                        <Redirect to='/dashboard' />
                      )}
                    </Route>
                    <MyRoute exact path='/welcome' component={Welcome} />
                    <MyRoute
                      exact
                      path='/dashboard'
                      component={IsProfileWasFilled}
                    />
                    <MyRoute exact path='/orders' component={Orders} />
                    <MyRoute exact path='/requests' component={Requests} />
                    <MyRoute exact path='/profile' component={Profile} />
                    <MyRoute exact path='/menu' component={Menu} />
                    <MyRoute exact path='/menu/:category' component={Menu} />
                    <MyRoute exact path='/staff' component={Staff} />
                    <MyRoute exact path='/manage/tables' component={Tables} />
                    <MyRoute
                      exact
                      path='/bank-account'
                      component={BankAccount}
                    />
                  </ProfileUpdateContext.Provider>
                </ScreensContext.Provider>
              </modalContext.Provider>
            </RequestPendingContextContainer>
          </DataContextContainer>
        </StateContextContainer>
      </AuthContextContainer>
    </Switch>
  );
}

export default Routes;
