import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerCheckInSummaryWrapper } from './style';
import { showMessage } from 'services/functions';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { AuthContext } from 'contexts/AuthContextContainer';
import { useTable } from 'hooks';
import { DrawerCancelCheckInContainer } from './DrawerCancelCheckInContainer';
import { SingleMovable } from 'pages/Tables/components/SingleMovable';

import { DefaultUser } from 'assets/images/profile';


export default function DrawerCheckInSummary({
  visible = false,
  placement = 'bottom',
  onClose = () => { },
}) {
  const { authUser } = useContext(AuthContext);
  const { checkInData, setCheckInData, floorDetails, setFloorDetails } = useContext(DataContext);
  const { setOpenDrawerCheckIn, setOpenDrawerCheckInMessage, setOpenDrawerSelectTable, setOpenDrawerSelectServer, setOpenDrawerCheckInSummary, setOpenDrawerCheckInCompleted } = useContext(StateContext);
  const [openDrawerCheckInCancelContainer, setOpenDrawerCheckInCancelContainer] = useState(false);

  const { editTable, seats, setSeats, shape, setShape } = useTable();

  useEffect(() => {
    if (visible && checkInData.table) {
      if (checkInData.table.seats !== seats || shape === undefined) {
        setSeats(checkInData.table.seats);
        setShape(checkInData.table.type === 0 ? 'circle' : '');
      }
    }
  }, [visible, checkInData, seats, setSeats, shape, setShape]);

  const onOpenDrawerCheckInCancelContainer = () => setOpenDrawerCheckInCancelContainer(true);
  const onCloseDrawerCheckInCancelContainer = () => setOpenDrawerCheckInCancelContainer(false);

  function selectedServerPressed() {
    setOpenDrawerCheckInSummary(false);
  }

  function goBackPressed() {
    setOpenDrawerCheckInSummary(false);
    setOpenDrawerSelectServer(false);
  }

  function cancelPressed() {
    setOpenDrawerCheckIn(false);
    setOpenDrawerCheckInMessage(false);
    setOpenDrawerSelectTable(false);
    setOpenDrawerCheckInSummary(false);
    setOpenDrawerSelectServer(false);
    setCheckInData();
  }

  const neededFloor = floorDetails.find(({ id }) => checkInData && checkInData.table && id === checkInData.table.floor_id);

  async function onCompletePressed() {
    const neededTable = neededFloor.tables.find(({ id: tableId}) => tableId === checkInData.table.id);
    const updatedTableAfterCheckIn =
      {
        ...neededTable,
        is_available: false,
        status: 1,
        guests: neededTable ? neededTable.guests + 1 : 0,
        server: checkInData.server.name,
        // WARNING - Server has serious problem - it will not update `is_available` prop for table
        // we need
        // 1. Update it in client-side instead
        // 2. Prevent any updates that killing our mocked feature
        __internal: true, 
      };

    const onOk = () => {
      setFloorDetails(floorDetails.map((floor) =>
        floor.id === updatedTableAfterCheckIn.floor_id
          ? {...floor, tables: floor.tables.map((table) => table.id === updatedTableAfterCheckIn.id ? updatedTableAfterCheckIn : table), }
          : floor
      ));
      setOpenDrawerCheckIn(false);
      setOpenDrawerCheckInMessage(false);
      setOpenDrawerSelectTable(false);
      setOpenDrawerCheckInSummary(false);
      setOpenDrawerSelectServer(false);
      setOpenDrawerCheckInCompleted(true);
    };

    const onFail = (error) => {
      showMessage(error, 'error');
    };

    return await editTable(updatedTableAfterCheckIn, neededFloor.id, floorDetails, onOk, onFail);
    // setCheckInData();
  }

  return (
    <DrawerCheckInSummaryWrapper
      placement={placement}
      onClose={onClose}
      height='fit-content'
      visible={visible}
    >
      <div className="check-in-summary">
        <div className="waiter-info-container">
          <div className="img-container">
            <img src={authUser.avatar ? authUser.avatar.file : DefaultUser} alt='' />
          </div>
          <div className="content">
            {authUser.last_name} {authUser.first_name ? authUser.first_name.charAt(0) : ""}
          </div>
        </div>
        <div className="table-info-container">
          <div className="left-part">
            <div className="floor-title">
              {neededFloor ? neededFloor.name : ''}
            </div>
            <div className="table-title">
              {`Table ${checkInData?.table?.table_number}`}
            </div>
            <div className="seats-amount">
              <div className="seats-icon" />
              <span className="seats-title">{checkInData?.table?.seats}</span>
            </div>
          </div>
          <div className="right-part">
            <SingleMovable
              className={`table_${checkInData?.table?.id} right-table-icon ${checkInData?.table?.seats && checkInData?.table?.seats <= 4 ? '' : 'big-table'}`}
              tableOriginal={checkInData?.table}
              otherClassNames={[]}
              translate={[checkInData?.table?.x, checkInData?.table?.y]}
              seats={checkInData?.table?.seats}
              status={checkInData?.table?.status}
              shape={checkInData?.table?.type === 0 ? 'circle' : 'square'}
              position="vertical"
              tableId={checkInData?.table?.id}
              isOccupied={!checkInData?.table?.is_available}
              setPanDisabled={() => {}}
              rotation={0}
              contextMenuDisabled={true}
            />
          </div>
        </div>

        <div className="selected-server-info" onClick={() => selectedServerPressed()}>
          <div className="left-part">
            <div className="img-container">
              <img src={checkInData?.server?.img ? checkInData?.server?.img : DefaultUser} alt='' />
            </div>
            <div className="server-info">
              <div className="job-title">
                Server
              </div>
              <div className="server-name">
                {checkInData?.server?.name}
              </div>
            </div>
          </div>
          <div className="right-part">
            <i className="fas fa-angle-right" />
          </div>
        </div>

        <button type="button" className="complete-check-in" onClick={() => onCompletePressed()}>
          Complete Check In
        </button>
        <div className="nav-buttons">
          <span onClick={() => goBackPressed()}>
            Go Back
          </span>
          <span onClick={onOpenDrawerCheckInCancelContainer}>
            Cancel
          </span>
        </div>
      </div>
      <DrawerCancelCheckInContainer
        visible={openDrawerCheckInCancelContainer}
        onClose={onCloseDrawerCheckInCancelContainer}
        onCancel={cancelPressed}
      />
    </DrawerCheckInSummaryWrapper>
  )
}

DrawerCheckInSummary.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickDeleteStaff: PropTypes.func,
};
