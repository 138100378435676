import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RejectOtherOutOfStockAlertContainer } from './style';
import Button from 'react-bootstrap/Button';
import { modalContext } from 'routes';

export function RejectOtherOutOfStockAlert({ onClose, outOfStock }) {
  const context = useContext(modalContext);

  return (
    <RejectOtherOutOfStockAlertContainer>
      {context.modal === 'Reject-Other-OutOfStock-Modal-3' ? (
        <div className='reject-other-outofstock-wrapper animate__animated animate__fadeInUp'>
          <div className='reject-other-outofstock-svg-position'>
            <svg height='100' width='100'>
              <circle cx='50' cy='50' r='40' strokeWidth='3' fill='#27282a' />
            </svg>
          </div>

          <div className='reject-other-outofstock-check-wrapper'>
            <i className='far fa-times reject-other-outofstock-times'></i>
          </div>

          <div className='reject-other-outofstock-body-wrapper'>
            <div>
              <p className='reject-other-outofstock-text-style'>
                Request Rejected
              </p>
            </div>
            <Button
              variant='none'
              className='reject-other-outofstock-okay-btn'
              onClick={() => {
                onClose()
                outOfStock()
              }}
            >
              Okay
            </Button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </RejectOtherOutOfStockAlertContainer>
  );
}

RejectOtherOutOfStockAlert.propTypes = {
  onClose: PropTypes.func,
  outOfStock : PropTypes.func
};
