import styled from '@emotion/styled'
// import { colors } from 'theme';

export const EmptyBodyContainerWrapper = styled.div`
    color: #fff;
    font-family: Montserrat;
    display: flex;
    justify-content: center;
    padding: 120px 0;
    .empty-profile-container{
        max-width: 700px;
        width: 700px;
        .welcome-container{
            display: flex;
            margin-bottom: 45px;
            .icon-container{
                margin-right: 30px;
                img{
                    width: 70px;
                    height: 80px;
                }
            }
            .messages-container{
                .welcome-message{
                    font-size: 30px;
                    font-weight: bold;
                    color: #ffffff;
                    margin-bottom: 10px;
                }
                .welcome-comment{
                    font-size: 18px;
                    color: #ffffff;
                    margin-bottom: 0;
                }
            }
        }

        .progress-bar-container{
            margin-bottom: 20px;
            .progress-bar-style{
                height: 10px;
                background-color: rgba(255,255,255,0.08);
                border-radius: 100px;
                &.finished{
                    .progress-bar{
                        background-color: #1fc086;
                    }
                }
                .progress-bar{
                    height: inherit;
                    background-color: #ed2626;
                    border-radius: inherit;
                }
            }
        }

        .complete-level{
            color: rgba(255,255,255,0.5);
            font-size: 18px;
            margin-bottom: 20px;
        }
        .warning-message{
            font-size: 18px;
            color: #ffffff;
            margin-bottom: 20px;
        }

        .steps-container{
            margin-bottom: 50px;
            .step-item{
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 35px 25px;
                border-top: 1px solid rgba(255,255,255,0.08);
                border-bottom: 1px solid rgba(255,255,255,0.08);
                .left-part{
                    display: flex;
                    align-items: center;
                    .icon-background{
                        background-color: rgba(0, 0, 0, 0.2);
                        margin-right: 25px;
                        height: 40px;
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        .fa-check{
                            font-size: 25px;
                            color: rgba(255, 255, 255, 0.2);
                        }
                        &.completed{
                            background-color: #1fc086;
                            .fa-check{
                                color: #fff;
                            }
                        }
                    }
                    span{
                        font-size: 20px;
                        font-weight: 500;
                    }
                }
                .right-part{
                    .fa-angle-right{
                        font-size: 26px;
                        color: rgba(255,255,255,0.2);
                    }
                }
            }
            .step-item:first-of-type{
                border-top: unset;
            }
            .step-item:last-child{
                border-bottom: unset;
            }
        }

        .publish-profile-button{
            width: 100%;
            height: 50px;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 600;
            color: rgba(255,255,255,0.2);
            background-color: rgba(255,255,255,0.08);
            border: unset;
            outline: unset;
            &.active{
                cursor: pointer;
                color: #fff;
                background-color: #ed2626;
            }
        }
    }
`;