import React from 'react'
import PropTypes from 'prop-types';
import Box from 'components/Box';
import { FaChevronRight } from 'react-icons/fa';
import SingleText from 'components/SingleText';
import { colors } from 'theme';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { ItemPickerContainer } from './style';

export default function ItemPicker({
  isInDrawer = true,
  label = '',
  className = '',
  placeholder = '',
  value = '',
  padding = '0px',
  onClick = () => {},
}) {
  return (
    <ItemPickerContainer className={className} padding={padding} isInDrawer={isInDrawer} onClick={onClick}>
      <Box display='flex' flexDirection='column' height='100%' justifyContent='center'>
        <SingleText content={label} size={14} opacity={0.3} lineHeight={1.57} spacing='-0.34px' marginBottom={isInDrawer ? 0 : 6}>{label}</SingleText>
        <SingleText content={value} color={!value ? colors.redColor : 'white'} size={14} weight={500} lineHeight={1.57} spacing='-0.34px'>{value || placeholder}</SingleText>
      </Box>
      <SingleIconWrapper>
        <FaChevronRight size={15} color={colors.white3} />
      </SingleIconWrapper>
    </ItemPickerContainer>
  )
}

ItemPicker.propTypes = {
  isInDrawer: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  padding: PropTypes.string,
  onClick: PropTypes.func,
};
