import React from 'react';
import { NoActiveOrdersContainer } from './style';
import SingleText from 'components/SingleText';

export function NoActiveOrders() {
  return (
    <NoActiveOrdersContainer>
      <div className='no-active-orders-placeholder-image' />
      <SingleText
        size={24}
        spacing='0'
        align='center'
        color='rgba(255, 255, 255, 0.6)'
        width='310px'
        weight='500'
      >
        There are no active orders to show.
      </SingleText>
    </NoActiveOrdersContainer>
  );
}
