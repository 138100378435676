import React from 'react';
import PropTypes from 'prop-types';
import { NotificationControlContainer } from './style';

export function NotificationControl({
  onBack,
  onMarkStatus,
  onStartEdit,
  onStartRemove,
}) {
  const onSuccessStatus = () => {
    onMarkStatus();
    onBack();
  };

  return (
    <NotificationControlContainer>
      {[
        [<i className='fas fa-chevron-right' key='chevron' />, onBack],
        [<i className='fas fa-trash' key='trash' />, onStartRemove],
        [<i className='fas fa-pencil-alt' key='pencil' />, onStartEdit],
        [<i className='fas fa-check' key='ok' />, onSuccessStatus],
      ].map(([icon, handler], index) => (
        <div className='control-item-container' onClick={handler} key={index}>
          {icon}
        </div>
      ))}
    </NotificationControlContainer>
  );
}

NotificationControl.propTypes = {
  onBack: PropTypes.func,
  onMarkStatus: PropTypes.func,
  onStartEdit: PropTypes.func,
  onStartRemove: PropTypes.func,
};
