import styled from '@emotion/styled'
import { colors, fonts } from 'theme';

export const SideMenuContainer = styled.div`
  min-width: 260px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${colors.gray2};
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.2), 0 2px 30px 0 rgba(0, 0, 0, 0.35);
  .logo-area {
    width: 100%;    
    padding: 0 20px;
    .logo-container {
      flex: 1;
      padding: 20px 0;
    }
    .divider {
      width: 100%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  .sidebar-items {
    .sidebar-item-wrapper {
      height: 55px;
      padding: 2px 0px 2px 8px;
      .sidebar-item {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        &:hover, &.active {
          background-color: #151617;
          transition: .2s ease-in-out;
          span {
            opacity: 1 !important;
          }
          .sidebar-item--icon {
            color: ${colors.redColor};
            opacity: 1 !important;
            i{
              color: inherit;
            }
            font-size: 16px;
          }
        }
        span {
          color: white;
          font-size: 14px;
          font-family: ${fonts.main};
          font-weight: 600;
          opacity: 0.5 !important;
        }
        .sidebar-item--icon {
          width: 50px;
          display: flex;
          justify-content: center;
          color: white;
          opacity: 0.5;
          font-size: 16px;
        }
      }
    }
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          padding: 0;
          .ant-collapse-arrow {
            color: white;
          }
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            .sidebar-subitem {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              height: 40px;
              span {
                color: white;
                font-size: 14px;
                font-family: ${fonts.main};
                font-weight: 600;
                opacity: 0.5 !important;
              }
              &:hover, &.active {
                span, .sidebar-subitem--icon {
                  transition: .2s ease-in-out;
                  opacity: 1 !important;
                }
              }
              .sidebar-subitem--icon {
                width: 50px;
                display: flex;
                justify-content: center;
                color: white;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
  .sidebar-bottom {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    cursor: pointer;

    .user-photo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: solid 3px ${colors.gray3};
    }
  }
`;

export const ExpandableMenu = styled.div`
  margin: 2px 0px 2px 8px;
  .expandable-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 15px 20px 15px 0px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: ${props => props.active ? '#151617' : 'transparent'};
    .left-part-title{
      .icon-container{
        width: 50px;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        i{
          ${'' /* font-size: 20px; */}
          color: ${props => props.active ? '#ed2626' : 'rgba(255,255,255,0.5)'};
          ${'' /* color: rgba(255,255,255,0.5); */}
          ${'' /* color: #ed2626; */}
          font-size: 16px;
        }
      }
      .title-text{
        font-size: 14px;
        font-weight: 600;
        color: ${props => props.active ? '#ffffff' : 'rgba(255,255,255,0.5)'};
        ${'' /* color: #ffffff; */}
      }
    }
    .right-part-title{
      i{
        font-size: 14px;
        font-weight: normal;
        color: ${props => props.active ? '#ffffff' : 'rgba(255,255,255,0.5)'}
        ${'' /* color: rgba(255,255,255,0.5); */}
        ${'' /* color: #ffffff; */}
      }
    }
  }
  .expandable-part{
    padding: 15px 20px 15px 25px;
    a{
      .sidebar-subitem{
        display: flex;
        align-items: center;
        height: 40px;
        &.active{
          .sidebar-subitem--icon{
            i{
              color: #fff;
            }
          }
          .sidebar-subitem--title{
            color: #fff;
          }
        }
        .sidebar-subitem--icon{
          width: 35px;
          display: inline-block;
          i{
            transition: .2s ease-in-out;
            color: rgba(255,255,255,0.5);
          }
        }
        .sidebar-subitem--title{
          font-weight: bold;
          transition: .2s ease-in-out;
          color: rgba(255,255,255,0.5);
        }
      }
    }
    a:hover{
      .sidebar-subitem{
        .sidebar-subitem--icon{
          i{
            color: #fff;
          }
        }
        .sidebar-subitem--title{
          color: #fff;
        }
      }
    }
  }
`;
