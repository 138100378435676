import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types';
import { SingleLocationSearchContainer } from './style';
import useOutsideClick from 'components/OutsideClickDetect';
import { FaSearch } from 'react-icons/fa';

export default function SingleSearchComponent({
  placeholder = '',
  options = [{ id: 1, value: 'test' }, { id: 2, value: 'option' }, { id: 3, value: 'other value' }],
  onChangeSearch = () => { },
  onClearAfterSearch = () => {},
}) {
  const ref = useRef();
  const [searchValue, setSearchValue] = useState('');
  const [isSuggestionActive, setIsSuggestionActive] = useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);

  useEffect(() => {
    if (searchValue === '') setIsSuggestionActive(false);
  }, [searchValue])

  useOutsideClick(ref, () => {
    setIsSuggestionActive(false);
    if (searchValue === '') setIsSearchButtonClicked(false);
  })

  const handleInput = (e) => {
    // Update the keyword of the input element
    setSearchValue(e.target.value);
    setIsSuggestionActive(true);
    onChangeSearch(e.target.value);
  };

  function handleSelect(suggestion) {
    setSearchValue(suggestion.value);
    setIsSuggestionActive(false);
    onChangeSearch(suggestion.value);
  }

  function clearSearchElement(e) {
    e.stopPropagation();
    setSearchValue('');
    onClearAfterSearch();
    setIsSuggestionActive(false);
    setIsSearchButtonClicked(false);
  }

  function expandMenu() {
    setIsSearchButtonClicked(true);
  }

  const filteredOptions = options.filter((suggestion) => suggestion.value.toLowerCase().includes(searchValue.toLocaleLowerCase()));

  const renderSuggestions = () => {
    if (filteredOptions.length) {
      return filteredOptions.map((suggestion) => {
        return (
          <div className="suggestion-item" key={suggestion.id} onClick={() => handleSelect(suggestion)}>
            <div className="suggestion-main">{suggestion.value}</div>
          </div>
        );
      });
    } else {
      return [];
    }
  }

  return (
    <SingleLocationSearchContainer ref={ref} active={isSearchButtonClicked}>
      <div className="search-container" onClick={() => expandMenu()}>
        <input
          className="search-container-input"
          value={searchValue}
          onChange={handleInput}
          placeholder={placeholder || "Enter..."}
        />
        {searchValue
          ? <div className="clear-search" style={{ cursor: 'pointer' }} onClick={(e) => clearSearchElement(e)}>
            <i className="fas fa-times" />
          </div>
          : <div className="clear-search">
            <FaSearch />
          </div>
        }
      </div>
      {isSuggestionActive === true && <div className={`suggestions-list ${!filteredOptions.length ? "not-found" : ''}`}>{renderSuggestions()}</div>}
    </SingleLocationSearchContainer>
  );
}

SingleSearchComponent.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChangeSearch: PropTypes.func,
  onClearAfterSearch: PropTypes.func,
};
