import styled from '@emotion/styled';
import { colors } from 'theme';

export const ActiveOrderContainer = styled.div`
  cursor: pointer;
  width: 30%;
  background-color: ${colors.gray2};
  padding: 22px 34px;
  border-radius: 20px;
  display: flex;
  align-items: center;

  &.active {
    background-color: ${colors.gray5};
  }

  &:first-of-type {
    margin-right: 34px;
  }

  .loading-icon {
    width: 47px;
    height: 47px;
    padding: 15px;
    border-radius: 50%;
    background-color: #5f6061;
    color: white;
    margin-right: 30px;

    i {
      font-size: 17px;
      line-height: 14px;
      letter-spacing: -0.41px;
    }
  }

  .active-order-container {
    display: flex;
    flex-direction: column;

    .active-order-title-container {
      margin-bottom: 12px;

      .active-order-title {
        font-size: 15px;
        font-weight: bold;
        color: white;
        line-height: 14px;
        letter-spacing: -0.36px;
      }
    }

    .name-and-code-container {
      margin-bottom: 12px;

      .name-and-code-title {
        font-size: 14px;
        color: white;
        line-height: 14px;
        letter-spacing: -0.36px;
      }
    }

    .active-order-info-container {
      .active-order-info-title {
        font-size: 15px;
        line-height: 14px;
        letter-spacing: -0.36px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
`;
