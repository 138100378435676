import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerAddTipContainerStyled } from './style';
import { DrawerAddTip } from './DrawerAddTip';

export function DrawerAddTipContainer({
  visible,
  onClose,
  onAddTip,
  tip,
  isTipAvailable,
}) {
  const [openDrawerAddTip, setOpenDrawerAddTipContainer] = useState(false);
  const onOpenDrawerAddTip = () => setOpenDrawerAddTipContainer(true);
  const onCloseDrawerAddTip = () => setOpenDrawerAddTipContainer(false);

  const close = () => {
    onCloseDrawerAddTip();
    onClose();
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerAddTip();
    }
  }, [visible]);

  return (
    <DrawerAddTipContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerAddTip
        visible={openDrawerAddTip}
        onClose={close}
        onAddTip={onAddTip}
        tip={tip}
        isTipAvailable={isTipAvailable}
      />
    </DrawerAddTipContainerStyled>
  );
}

DrawerAddTipContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onAddTip: PropTypes.func,
  tip: PropTypes.object,
  isTipAvailable: PropTypes.bool,
};
