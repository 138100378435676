import React from 'react';
import PropTypes from 'prop-types';
import { AreaChart, Area, YAxis, ResponsiveContainer } from 'recharts';

export function DashboardChart({ dataForGraph }) {
  return (
    <ResponsiveContainer width={520} height={100}>
      <AreaChart
        data={dataForGraph}
        margin={{
          top: 10,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id='sales' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='10%' stopColor='#1fc086' stopOpacity={1} />
            <stop offset='100%' stopColor='#1fc086' stopOpacity={0.25} />
          </linearGradient>
        </defs>
        <YAxis
          orientation='right'
          width={30}
          minTickGap={10}
          tickCount={4}
          ticks={[10, 20, 30, 40]}
          tickLine={false}
          axisLine={false}
        />
        <Area
          type='monotone'
          dataKey='total_sales'
          stroke='#1fc086'
          strokeWidth={3}
          fill='url(#sales)'
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

DashboardChart.propTypes = {
  dataForGraph: PropTypes.any,
};
