import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OrdersTopControlContainer } from './style';
import { StateContext } from 'contexts/StateContextContainer';
import { PendingOrdersContext } from 'contexts/PendingOrdersContextContainer';
import { ActiveOrdersContext } from 'contexts/ActiveOrdersContextContainer';
import { CompletedOrdersContext } from 'contexts/CompletedOrdersContextContainer';

import mockedPendingOrders from '../Pending/OrdersToHandle/__mockedOrders.json';
import mockedActiveOrders from '../Active/ActiveOrders/__mockedActiveOrders.json';
import mockedCompletedOrders from '../CompletedComponent/__mockedCompletedOrders.json';

export function OrdersTopControl({
  openPendingPage,
  openActivePage,
  openCompletedPage,
  isPendingPageSelected = () => {},
  isActivePageSelected = () => {},
  isCompletedPageSelected = () => {},
}) {
  const { setPendingOrdersTotal } = useContext(StateContext);

  const {
    pendingOrders,
    setPendingOrders,
    isEmptyOrdersPool: isEmptyPendingOrdersPool,
  } = useContext(PendingOrdersContext);
  const {
    activeOrders,
    setActiveOrders,
    isEmptyOrdersPool: isEmptyActiveOrdersPool,
  } = useContext(ActiveOrdersContext);

  const { isEmptyOrdersPool: isEmptyCompletedOrdersPool, setCompletedOrders } =
    useContext(CompletedOrdersContext);

  useEffect(() => {
    if (isEmptyPendingOrdersPool()) {
      setPendingOrders(mockedPendingOrders);
    }
  }, [isEmptyPendingOrdersPool, setPendingOrders]);

  useEffect(() => {
    setPendingOrdersTotal(pendingOrders.length);
  }, [pendingOrders, setPendingOrdersTotal]);

  useEffect(() => {
    if (isEmptyActiveOrdersPool()) {
      setActiveOrders(mockedActiveOrders);
    }
  }, [isEmptyActiveOrdersPool, setActiveOrders]);

  useEffect(() => {
    if (isEmptyCompletedOrdersPool()) {
      // WARNING: It i mocked feature - when back-end will be implemented we should replace code below
      // mocked feature
      const copyCompletedOrders = [...mockedCompletedOrders];
      const todayCompletedOrders = copyCompletedOrders
        .filter(({ completed }) => completed === 'today')
        .map((order, index) => ({
          ...order,
          completed: [
            new Date(new Date().getTime() - 3600000).getTime(),
            new Date(new Date().getTime() - 7200000).getTime(),
          ][index],
        }));

      const yesterdayCompletedOrders = copyCompletedOrders
        .filter(({ completed }) => completed === 'yesterday')
        .map((order) => ({
          ...order,
          completed: new Date(new Date().getTime() - 86400000).getTime(),
        }));

      const normalCompletedOrders = copyCompletedOrders.filter(
        ({ completed }) => completed !== 'today' && completed !== 'yesterday'
      );
      // mocked feature end

      setCompletedOrders([
        ...todayCompletedOrders,
        ...normalCompletedOrders,
        ...yesterdayCompletedOrders,
      ]);
    }
  }, [isEmptyCompletedOrdersPool, setCompletedOrders]);

  return (
    <OrdersTopControlContainer className='orders-control'>
      {[
        {
          title: 'Pending',
          value: pendingOrders.length,
          openThisPage: openPendingPage,
          isSelected: isPendingPageSelected,
        },
        {
          title: 'Active',
          value: activeOrders.length,
          openThisPage: openActivePage,
          isSelected: isActivePageSelected,
        },
        {
          title: 'Completed',
          openThisPage: openCompletedPage,
          isSelected: isCompletedPageSelected,
        },
      ].map(({ title, value, openThisPage, isSelected }) => (
        <div
          className={`control-item ${isSelected() ? 'active' : ''}`}
          onClick={openThisPage}
          key={title + value}
        >
          {title} {value ? <b>{value}</b> : ''}
        </div>
      ))}
    </OrdersTopControlContainer>
  );
}

OrdersTopControl.propTypes = {
  openPendingPage: PropTypes.func,
  openActivePage: PropTypes.func,
  openCompletedPage: PropTypes.func,
  isPendingPageSelected: PropTypes.func,
  isActivePageSelected: PropTypes.func,
  isCompletedPageSelected: PropTypes.func,
};
