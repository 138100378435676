import React from 'react';
import PropTypes from 'prop-types';
import { DrawerRemoveFeaturedImageContainer } from './style';

function DrawerRemoveFeaturedImage({
  visible = false,
  placement = 'bottom',
  onClose = () => {},
  onDelete = () => {},
  onKeep = () => {},
}) {
  return (
    <DrawerRemoveFeaturedImageContainer
      onClose={onClose}
      visible={visible}
      placement={placement}
      height='auto'
    >
      <div className='delete-image-block'>
        <div className='head-block'>
          <h3>Delete Featured Image</h3>
        </div>
        <p className='warning-text'>
          Are you sure you want to delete <b>Featured Image</b> ?
        </p>
        <button className='btn-delete' onClick={onDelete}>
          Delete
        </button>
        <button className='btn-keep' onClick={onKeep}>
          Keep
        </button>
      </div>
    </DrawerRemoveFeaturedImageContainer>
  );
}

export default DrawerRemoveFeaturedImage;

DrawerRemoveFeaturedImage.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onKeep: PropTypes.func,
};
