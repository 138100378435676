import { StatusGroup } from './StatusGroup';

export class OverallState extends StatusGroup {
  getState() {
    return {
      profileCompleted: this.profileStatus.isCompleted(),
      menuCompleted: this.menuStatus.isCompleted(),
      tableCompleted: this.tableStatus.isCompleted(),
      bankCompleted: this.bankStatus.isCompleted(),
    };
  }
}
