import { useState } from 'react';

export const useUpdateMyAccount = () => {
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    isMobileNumberConfirmed: false,
    isEmailConfirmed: false
  });

  const changeFirstName = (newName) =>
    changeUser({ ...user, first_name: newName });

  const changeLastName = (newLastName) =>
    changeUser({ ...user, last_name: newLastName });

  const changeMobileNumber = (mobileNumber) =>
    changeUser({ ...user, phone_number: mobileNumber });

  const confirmMobileNumber = (confirm = true) =>
    changeUser({ ...user, isMobileNumberConfirmed: confirm });

  const changeEmail = (newEmail) => changeUser({ email: newEmail });

  const confirmEmail = (confirm = true) =>
    changeUser({ ...user, isEmailConfirmed: confirm });

  const changePushNotifications = () =>
    changeUser({
      notification_push: user ? !user.notification_push : true,
    });
  const changeSMSNotifications = () =>
    changeUser({ ...user, notification_sms: user ? !user.notification_sms : true });
  const changeEmailNotifications = () =>
    changeUser({ ...user, notification_email: user ? !user.notification_email : true });
  const changePromotionalNotifications = () =>
    changeUser({
      ...user,
      notification_promote: user ? !user.notification_promote : true,
    });

  const changeUser = (updatedPart) => setUser({ ...user, ...updatedPart });

  const changeAvatar = (avatar) => setUser({ ...user, ...avatar });

  const clearUser = () => {
    if(user) {
      const saveVerifiedData = {}
      if(user.isEmailConfirmed) saveVerifiedData.isEmailConfirmed = user.isEmailConfirmed
      if(user.isMobileNumberConfirmed) saveVerifiedData.isMobileNumberConfirmed = user.isMobileNumberConfirmed
      console.log({saveVerifiedData});
      setUser(saveVerifiedData)
    }
    setUser();
  }

  return {
    user,
    changeFirstName,
    changeLastName,
    changeMobileNumber,
    confirmMobileNumber,
    changeEmail,
    confirmEmail,
    changePushNotifications,
    changeSMSNotifications,
    changeEmailNotifications,
    changePromotionalNotifications,
    changeAvatar,
    changeUser,
    clearUser,
  };
};
