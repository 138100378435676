import styled from '@emotion/styled';
import { colors } from 'theme';

export const TotalRatingContainer = styled.div`
  margin-top: -5px;
  padding: 30px 26px 26px 33px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background-color: ${colors.gray2};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .rating-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .rating-main {
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      i {
        font-size: 26px;
        color: ${colors.rating};
        margin-right: 5px;
      }

      .rating-title {
        margin-left: 6px;
        font-size: 26px;
        font-weight: bold;
        color: white;
        letter-spacing: -0.62px;
      }
    }

    .total-reviews-container {
      display: flex;

      .total-reviews,
      .total-reviews-label {
        font-size: 14px;
        letter-spacing: -0.34px;
        color: white;
      }

      .total-reviews {
        font-weight: bold;
        margin-right: 6px;
      }
    }
  }

  .rating-control-container {
    cursor: pointer;
    padding: 11px 22px;
    border-radius: 30px;
    background-color: ${colors.gray5};
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rating-control-title {
      margin-right: 25px;
    }

    .rating-control-title,
    i {
      font-size: 14px;
      letter-spacing: 0;
      color: white;
    }
  }
`;
