import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerBankAccountReplaceContainerStyled } from './style';
import { DrawerBankAccountReplace } from './DrawerBankAccountReplace';

export function DrawerBankAccountReplaceContainer({
  visible,
  onClose,
  onReplace,
}) {
  const [openDrawerBankAccountReplace, setOpenDrawerBankAccountReplace] =
    useState(false);
  const onOpenDrawerBankAccountReplace = () =>
    setOpenDrawerBankAccountReplace(true);
  const onCloseDrawerBankAccountReplace = () =>
    setOpenDrawerBankAccountReplace(false);

  const close = () => {
    onCloseDrawerBankAccountReplace();
    onClose();
  };

  useEffect(() => {
    if (visible) {
      onOpenDrawerBankAccountReplace();
    }
  }, [visible]);

  return (
    <DrawerBankAccountReplaceContainerStyled
      visible={visible}
      placement='right'
      onClose={close}
    >
      <DrawerBankAccountReplace
        visible={openDrawerBankAccountReplace}
        onClose={close}
        onReplace={onReplace}
      />
    </DrawerBankAccountReplaceContainerStyled>
  );
}

DrawerBankAccountReplaceContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onReplace: PropTypes.func,
};
