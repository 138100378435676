import React from 'react';
import PropTypes from 'prop-types';
import { showMessage } from 'services/functions';
import SingleText from 'components/SingleText';
import SingleNumberIncrease from 'components/SingleNumberIncrease';
import { NumberIncreaseContainer } from './style';

export default function NumberIncrease({
  label = '',
  seats = 1,
  increaseSeats = () => {},
  decreaseSeats = () => {},
  isUserDidNotSelectShape = false,
}) {
  const warnUserHeShouldSelectTableShapeFirst = () => showMessage('Please select table shape first', 'warning');

  const onClickIncrease = () => {
    if (isUserDidNotSelectShape) {
      warnUserHeShouldSelectTableShapeFirst();
    } else {
      increaseSeats();
    }
  };

  const onClickDecrease = () => {
    if (isUserDidNotSelectShape) {
      warnUserHeShouldSelectTableShapeFirst();
    } else {
      decreaseSeats();
    }
  };

  return (
    <NumberIncreaseContainer>
      <SingleText
        weight={500}
        size={14}
        lineHeight={1.57}
        spacing='-0.34px'
        color={isUserDidNotSelectShape ? 'rgba(255, 255, 255, 0.3)' : 'white'}
      >
        {label}
      </SingleText>
      <SingleNumberIncrease
        value={seats}
        onIncrease={onClickIncrease}
        onDecrease={onClickDecrease}
        defaultValue={seats}
      />
    </NumberIncreaseContainer>
  );
}

NumberIncrease.propTypes = {
  label: PropTypes.string,
  seats: PropTypes.number,
  increaseSeats: PropTypes.func,
  decreaseSeats: PropTypes.func,
  isUserDidNotSelectShape: PropTypes.bool,
};
