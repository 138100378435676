import React, { useState } from 'react';
import PropTypes from 'prop-types'
import useLocalStorage from 'react-use-localstorage';

const AuthContext = React.createContext(null);

export { AuthContext };

const AuthContextContainer = (props) => {
  const [authUser, setAuthUserContext] = useLocalStorage('feasttt-restaurant-user', '{}');
  const [restaurantId, setRestaurantId] = useLocalStorage('feasttt-restaurant-id', '');

  const [savedAuthDataForCompleteProfile, setSavedAuthDataForCompleteProfile] = useState(undefined);
  const saveAuthDataForCompleteProfile = (authData) => setSavedAuthDataForCompleteProfile(authData);

  const [phoneNumberForCompleteProfile, setPhoneNumberForCompleteProfile] = useState(undefined);
  const changePhoneNumberForCompleteProfile = (phoneNumber) => setPhoneNumberForCompleteProfile(phoneNumber);

  const setAuthUser = (req) => {
    setAuthUserContext(JSON.stringify(req));
  }
  
  const clearCredentials = () => {
    localStorage.removeItem('feasttt-restaurant-user');
    localStorage.removeItem('feasttt-restaurant-id');
  };

  const clearDataIfUserWillNotCompleteProfile = () => {
    clearSavedAuthDataForCompleteProfile();
    clearPhoneNumberForCompleteProfile();
  };

  const clearSavedAuthDataForCompleteProfile = () => {
    saveAuthDataForCompleteProfile(undefined);
  };

  const clearPhoneNumberForCompleteProfile = () => changePhoneNumberForCompleteProfile(undefined);

  return(
    <AuthContext.Provider
      value={{ 
        authUser: JSON.parse(authUser), 
        setAuthUser,
        restaurantId: parseInt(restaurantId),
        setRestaurantId,
        clearCredentials,

        savedAuthDataForCompleteProfile,
        saveAuthDataForCompleteProfile,

        phoneNumberForCompleteProfile,
        changePhoneNumberForCompleteProfile,

        clearDataIfUserWillNotCompleteProfile,
      }}
    >
      { props.children }
    </AuthContext.Provider>
  )
}

AuthContextContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


export default AuthContextContainer;
