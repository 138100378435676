import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DrawerProfileUpdateEmailContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerProfileUpdateEmail({ visible, onClose, onChange }) {
  const [email, setEmail] = useState('');

  const onSubmit = (event) => {
    event.preventDefault();
    onClose();
    onChange({ email });
  };

  return (
    <DrawerProfileUpdateEmailContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <SingleText
        size={22}
        spacing='0.42px'
        marginLeft={5}
        marginRight={5}
        marginBottom={40}
        weight={500}
      >
        Update e-mail address
      </SingleText>
      <form onSubmit={onSubmit}>
        <div className='update-email-input-container'>
          <label>E-mail Address</label>
          <input
            type='email'
            placeholder='Enter your e-mail address'
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <SingleButton
          htmlType='submit'
          title='Continue'
          margin='46px 0px 0px 0px'
          btnColor={
            email.length > 3 ? colors.redColor : 'rgba(255, 255, 255, 0.1)'
          }
          txtColor={email.length > 3 ? 'white' : 'rgba(255, 255, 255, 0.2)'}
          disabled={email.length < 3}
        />
      </form>
      <SingleButton
        title='Cancel'
        btnColor='transparent'
        txtColor={colors.redColor}
        margin='20px 0px 0px 0px'
        onClick={onClose}
      />
    </DrawerProfileUpdateEmailContainer>
  );
}

DrawerProfileUpdateEmail.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};
