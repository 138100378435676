import { useState, useEffect } from 'react';
import { OrderPriceEffects } from 'feasttt/domain';
import { useBinaryState } from '../common';

export const useRefund = (orderTotalPrice) => {
  const {
    isActive: isOtherMode,
    activate: enableOtherMode,
    deactivate: disableOtherMode,
  } = useBinaryState();

  const [dollars, setDollars] = useState(0);
  const changeDollars = (newDollars) => setDollars(newDollars);

  const [percentages, setPercentages] = useState(25);
  const changePercentages = (newPercentage) => setPercentages(newPercentage);

  const [type, setType] = useState(OrderPriceEffects.initialTypeValue());
  const changeType = (newType) => setType(newType);

  const [refund, setRefund] = useState(undefined);
  const changeRefund = (newRefund) => setRefund(newRefund);
  const clearRefund = () => setRefund(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isPercentageTypeActive = () =>
    OrderPriceEffects.isEffectTypesEqual(
      type,
      OrderPriceEffects.effectsTypes.percentage
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isFixedTypeActive = () =>
    OrderPriceEffects.isEffectTypesEqual(
      type,
      OrderPriceEffects.effectsTypes.fixed
    );

  const onChangeStaticPercentages = (staticPercentage) => {
    if (isOtherMode) {
      disableOtherMode();
    }

    if (!isPercentageTypeActive()) {
      changeType(OrderPriceEffects.effectsTypes.percentage);
    }

    changePercentages(staticPercentage);
  };

  const onEnableOtherMode = () => {
    enableOtherMode();
    changeType(OrderPriceEffects.effectsTypes.fixed);
  };

  const isStaticPercentageSelected = (staticPercentageOption) => {
    return (
      isPercentageTypeActive() &&
      !isOtherMode &&
      percentages === staticPercentageOption
    );
  };

  const setupAlreadyExistingRefund = (existingRefund) => {
    changeType(existingRefund.type);

    if (
      OrderPriceEffects.isEffectTypesEqual(
        existingRefund.type,
        OrderPriceEffects.effectsTypes.fixed
      )
    ) {
      changeDollars(Number(existingRefund.value));
      enableOtherMode();
    } else {
      changePercentages(Number(existingRefund.value));

      if (
        !OrderPriceEffects.STATIC_PERCENTAGE_OPTIONS.includes(
          existingRefund.value
        )
      ) {
        enableOtherMode();
      }
    }
  };

  useEffect(() => {
    const dollarsPercentagesBinding =
      OrderPriceEffects.createDollarsPercentagesBinding(
        dollars,
        percentages,
        orderTotalPrice
      );

    if (isPercentageTypeActive()) {
      changeDollars(Number(dollarsPercentagesBinding.computeDollars()));
    } else if (isFixedTypeActive()) {
      changePercentages(Number(dollarsPercentagesBinding.computePercentages()));
    }
  }, [
    dollars,
    percentages,
    orderTotalPrice,
    isFixedTypeActive,
    isPercentageTypeActive,
  ]);

  return {
    isOtherMode,
    enableOtherMode,
    disableOtherMode,
    onEnableOtherMode,

    dollars,
    changeDollars,

    percentages,
    onChangeStaticPercentages,
    changePercentages,

    type,
    changeType,
    isStaticPercentageSelected,
    isPercentageTypeActive,
    isFixedTypeActive,

    refund,
    changeRefund,
    clearRefund,
    setupAlreadyExistingRefund,
  };
};
