import React from 'react';
import PropTypes from 'prop-types';
import { DrawerFoodItemRemovedFromOrderContainer } from './style';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerFoodItemRemovedFromOrder({ visible, onClose }) {
  return (
    <DrawerFoodItemRemovedFromOrderContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='remove-icon'>
        <i className='fas fa-trash' />
      </div>
      <SingleText
        className='remove-item-title'
        size={22}
        spacing='0.42px'
        marginBottom={10}
        align='center'
      >
        Item Removed
      </SingleText>
      <SingleText
        lineHeight='21px'
        spacing='0.27px'
        marginBottom={33}
        align='center'
      >
        The customer will no longer see this item on their bill.
      </SingleText>
      <SingleButton title='Okay' onClick={onClose} />
    </DrawerFoodItemRemovedFromOrderContainer>
  );
}

DrawerFoodItemRemovedFromOrder.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
