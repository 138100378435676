import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types';
// import './style.scss';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import { DrawerSortReviewsContainer } from './style';
import { colors } from 'theme';
import {sortOptions} from 'services/static';
import { StateContext } from 'contexts/StateContextContainer';

export default function DrawerSortReviews({ 
  visible = false,
  placement = 'bottom',
  onClose = () => {},
}) {

  const {
    setOpenDrawerSortReviews,
  } = useContext(StateContext);
  const [option, setOption] = useState('');

  const onClickApplySort = () => {
    setOpenDrawerSortReviews(true);
  }

  return (
      <DrawerSortReviewsContainer
        placement={placement}
        onClose={onClose}
        height='fit-content'
        visible={visible}
      >
        <Box display='flex' flexDirection='column' alignItems='flex-start' marginBottom={30}>
          <SingleText content='Sort' size={22} weight={500} marginBottom={10}>Sort</SingleText>
        </Box>
        <Box className='drawer-sort--options'>
          {sortOptions.map((_option, _i) =>
            <Box key={_i} className={`drawer-sort--option ${_option.title === option ? 'active' : 'inactive'}`} marginBottom={15} onClick={() => setOption(_option.title)}>
              <SingleText content={_option.title} size={14} weight={500} spacing='-0.34'>{_option.title}</SingleText>
            </Box>
          )}
        </Box>
        <Box display='flex' flexDirection='column'>
          <SingleButton 
            title='Apply' 
            margin='0 0 20px'
            onClick={onClickApplySort} 
          />
          <SingleButton 
            title='Reset' 
            btnColor='transparent'
            txtColor={colors.redColor}
            margin='0 0 20px'
            onClick={onClose} 
          />
        </Box>
      </DrawerSortReviewsContainer>
  )
}

DrawerSortReviews.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  onClickApplySort: PropTypes.func,
};
