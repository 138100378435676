import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrinkRefillRequestPendingDrawerContainer = styled(SingleDrawer)`
  padding: 0;
  background-color: ${colors.gray2};
  font-size: 14px;
  color: white;
  overflow-y: hidden;

  .drink-refill-close-icon {
    i {
      font-size: 20px;
      color: white;
    }
  }

  .drink-refill-left {
    cursor: pointer;
  }

  .drink-refill-wrapper-sidebar {
    margin: 30px 30px 0px 30px;
  }

  .drink-refill-text-align {
    margin: 31px 0 44px 7px;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.5;
    color: #ffffff;
  }

  .drink-refill-border-style {
    border: 2px solid;
    padding: 15px 0px 15px 18px;
    border-radius: 15px;
    color: #2f2e2e;
    cursor: pointer;
  }
`;
