import React, { useState, useContext } from 'react';
import { TablesContainer, ButtonsContainer } from './style';
import { StateContext } from 'contexts/StateContextContainer';
import SingleButton from 'components/SingleButton';
import { colors } from 'theme';
import { IconCheckIn } from 'assets/images/icons';
import TableLayout from './TableLayout';
import { tablesTemp } from 'services/static';
import { showMessage } from 'services/functions';
import TableList from './TableList';

export default function Tables() {
  const { setOpenDrawerCheckIn } = useContext(StateContext);
  const [tables] = useState(tablesTemp);
  const [selectedMenu, setSelectedMenu] = useState('layout');
  const [isEditState, setEditState] = useState(false);

  const startEditTables = () => setEditState(true);
  const stopEditTables = () => setEditState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCancelTablesChanges = () => {
    stopEditTables();
  };
  const onDoneTablesChanges = () => {
    stopEditTables();
  };

  const onCheckIn = () => {
    setOpenDrawerCheckIn(true);
  };

  // actions here is:
  // - closing editing state & canceling all changes WHEN layout
  // - opening editing state WHEN layout
  // which action will executed depends only from context (isEditState == true or not)
  const onActionByContext = () => {
    if (selectedMenu === 'layout') {
      if (isEditState) {
        onCancelTablesChanges();
      } else {
        startEditTables();
      }
    } else {
      // go to Layout and start edit
      setSelectedMenu('layout');
      startEditTables();
    }
  };

  // actions here is:
  // - closing editing state & saving all changes WHEN layout
  // - check in WHEN layout
  // which action will executed depends only from context (isEditState == true or not)
  const onActionByContextSecondBtn = () => {
    if (selectedMenu === 'layout') {
      if (isEditState) {
        onDoneTablesChanges();
      } else {
        onCheckIn();
      }
    } else {
      onCheckIn();
    }
  };

  const onSwitchView = () => {
    if (selectedMenu === 'layout') {
      if (isEditState) {
        // editing state only possible in `layout` view
        showMessage(
          'Please, complete (or cancel) your changes with tables (click "Done" (or "Cancel")) and try again',
          'warning'
        );
      } else {
        setSelectedMenu('list');
      }
    } else {
      setSelectedMenu('layout');
    }
  };

  return (
    <TablesContainer>
      <div className='tables-tabs-container'>
        <div className='left-part'>
          <h3>Tables</h3>
          <div className='navs-container'>
            <div
              className={`nav-item ${
                selectedMenu === 'layout' ? 'active' : ''
              }`}
              onClick={onSwitchView}
              aria-hidden='true'
            >
              Layout
            </div>
            <div
              className={`nav-item ${selectedMenu === 'list' ? 'active' : ''}`}
              onClick={onSwitchView}
              aria-hidden='true'
            >
              List
            </div>
          </div>
        </div>
        <div className='right-part'>
          <ButtonsContainer>
            <SingleButton
              shape='circle'
              width={170}
              height={40}
              txtColor='white'
              btnColor={colors.gray}
              title={
                isEditState && selectedMenu === 'layout'
                  ? 'Cancel'
                  : 'Edit Tables'
              }
              onClick={onActionByContext}
            />
            <SingleButton
              shape='circle'
              width={170}
              height={40}
              txtColor='white'
              btnColor={colors.redColor}
              title={
                isEditState && selectedMenu === 'layout' ? 'Done' : 'Check In'
              }
              icon={
                isEditState ? null : (
                  <img
                    src={IconCheckIn}
                    width={22}
                    height={20}
                    alt='check-in-icon'
                  />
                )
              }
              onClick={onActionByContextSecondBtn}
            />
          </ButtonsContainer>
        </div>
      </div>
      <div className='content-container'>
        {selectedMenu === 'layout' && <TableLayout tables={tables} editable={isEditState} />}
        {selectedMenu === 'list' && <TableList tables={tables} />}
      </div>
    </TablesContainer>
  );
}
