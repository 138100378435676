import React from 'react';
import { WelcomeContainer } from './style';
import { WelcomeDescription } from './WelcomeDescription';
import { PublishProfile } from './PublishProfile';

function Welcome() {
  return (
    <WelcomeContainer>
      <WelcomeDescription />
      <PublishProfile />
    </WelcomeContainer>
  );
}

export default Welcome;
