import React from 'react';
import PropTypes from 'prop-types';
import { DrawerCheckInErrorContainerStyled } from './style';
import { DrawerCheckInError } from './DrawerCheckInError';

export function DrawerCheckInErrorContainer({ visible, onClose, onRetry, errorMessage }) {
  return (
    <DrawerCheckInErrorContainerStyled
      visible={visible}
      placement="right"
      onClose={onClose}
    >
      <DrawerCheckInError onClose={onClose} onRetry={onRetry} errorMessage={errorMessage} />
    </DrawerCheckInErrorContainerStyled>
  );
}

DrawerCheckInErrorContainer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onRetry: PropTypes.func,
  errorMessage: PropTypes.string,
};
