import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { DataContext } from 'contexts/DataContextContainer';
import { CategoriesSelectContainer } from './style';
import { formatName } from 'services/functions';
import { EditMenusOption } from './EditMenusOption';

export function CategoriesSelect({ onChangeChosenMenu }) {
  const [correspondingMenuHeaders, setCorrespondingMenuHeaders] =
    useState(null);
  const { category } = useParams();
  const { menus, chosenMenu, setChosenMenu } = useContext(DataContext);

  const getCorrespondingMenuHeaders = () => {
    if (chosenMenu) {
      const selectedMenu = menus.find((x) => x.id === chosenMenu);
      if (selectedMenu === undefined) {
        return { label: 'All', value: 0 };
      } else {
        return { label: formatName(selectedMenu.name), value: selectedMenu.id };
      }
    } else {
      return { label: 'All', value: 0 };
    }
  };

  useEffect(() => {
    const headers = getCorrespondingMenuHeaders();
    setCorrespondingMenuHeaders(headers);
    if (Number(category)) {
      setChosenMenu(Number(category));
    } else {
      setChosenMenu(0);
    }
  }, [])

  useEffect(() => {
    if (!chosenMenu || chosenMenu !== category || Number.isNaN(Number(category))) {
      if (Number(category)) {
        setChosenMenu(Number(category));
      } else {
        setChosenMenu(0);
      }
      setCorrespondingMenuHeaders(getCorrespondingMenuHeaders());
    }
  }, [category, chosenMenu, setChosenMenu, menus]);

  return (
    <CategoriesSelectContainer 
      className={`categories-switcher-core ${
        menus.some(({ name }) => name.length > 10) ? 'larger' : ''
      }`}
      innerMenuWidth={
        menus.some(({ name }) => name.length > 10)
          ? Math.max(...menus.map(({ name }) => name.length)) + 140
          : 0
      }
      options={[
        { label: 'All', value: 0 },
        ...menus.map((menu) => {
          return {
            label: (
              <div
                className={`option-container ${
                  menu.status ? 'active' : 'inactive'
                }`}
              >
                {formatName(menu.name)}
                <span className='option-schedule'>(11am-4pm)</span>
              </div>
            ),
            value: menu.id,
          };
        }),
      ]}
      value={correspondingMenuHeaders || getCorrespondingMenuHeaders()}
      onChange={(menuPage) => onChangeChosenMenu(menuPage, menus)}
      width={235}
      dropdownRender={<EditMenusOption />}
      placeholder='Select Menu'
      iconOpen={
        <div className='select-icon-container'>
          <div
            className={`light-indicator${lightIndicatorStatus(
              getCorrespondingMenuHeaders(),
              menus
            )}`}
          />
          <i className='fas fa-angle-up' />
        </div>
      }
      iconClosed={
        <div className='select-icon-container'>
          <div
            className={`light-indicator ${lightIndicatorStatus(
              getCorrespondingMenuHeaders(),
              menus
            )}`}
          />
          <i className='fas fa-angle-right' />
        </div>
      }
    />
  );
}

CategoriesSelect.propTypes = {
  onChangeChosenMenu: PropTypes.func,
};

export const lightIndicatorStatus = (
  correspondedMenuHeader = {},
  menus = []
) => {
  const findMenu = menus.find(({ id }) => id === correspondedMenuHeader.value);
  if (findMenu) {
    return findMenu.status ? ' active' : ' inactive';
  } else if (correspondedMenuHeader.label === 'All') {
    return ' active';
  }
  return ' inactive';
};
