import styled from '@emotion/styled';
import { Dropdown } from 'react-bootstrap';

export const SingleSelectDropdownContainer = styled(Dropdown)`
  .dropdown-header-menu{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 27px;
    border: solid 1px rgba(255, 255, 255, 0.08);
    color: rgba(255,255,255,0.5);
    padding: 10px 20px;
    cursor: pointer;
    i{
      color: rgba(255, 255, 255, 0.2);
    }
  }
  .dropdown-content-menu{
    width: 100%;
    border-radius: 25px;
    border: solid 1px rgba(255, 255, 255, 0.08);
    background-color: #151617;
    overflow: auto;
    .list-unstyled{
      a{
        padding: 10px 1.5rem;
        .option-label-item{
          font-size: 14px;
          color: rgba(255,255,255);
        }
      }
      a:last-child{
        margin-bottom: 0px;
      }
      a:hover{
        background-color: rgba(255,255,255,0.1);
      }
      .rendered-dropdown{
        cursor: pointer;
        font-size: 14px;
        color: #ed2626;
        padding: 10px 1.5rem;
      }
      .rendered-dropdown:hover{
        background-color: rgba(255,255,255,0.1);
      }
      .rendered-dropdown:active{
        background-color: unset;
      }
    }
  }
`;
