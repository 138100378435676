import React from 'react';

export const menusTemp = [
  {
    id: '1',
    name: 'Breakfast',
    time: '8am - 11am',
  },
  {
    id: '2',
    name: 'Drinks',
    time: '8am - 11am',
  },
  {
    id: '3',
    name: 'Lunch',
    time: '8am - 11am',
  },
  {
    id: '4',
    name: 'Dinner',
    time: '8am - 11am',
  },
  {
    id: '5',
    name: 'Desserts',
    time: '8am - 11am',
  },
  {
    id: '6',
    name: 'Diet Menu',
    time: '8am - 11am',
  },
]

export const reviewsTemp = [
  {
    id: '1',
    username: 'Dollie Frazier',
    status: 0,
    dinedNum: 3,
    content: 'If you ask for Pasta Alfredo in a restaurant in Italy all you get from your waiter is a stare. Why is one of the most famous “Italian sauces” for pasta unknown in its country of origin? ',
  },
  {
    id: '2',
    username: 'Edward Howell',
    status: 0,
    dinedNum: 14,
    content: 'If you ask for Pasta Alfredo in a restaurant in Italy all you get from your waiter is a stare. Why is one of the most famous “Italian sauces” for pasta unknown in its country of origin? If you ask for Pasta Alfredo in a restaurant in Italy all you get from your waiter is a stare. Why is one of the most famous “Italian sauces” for pasta unknown in its country of origin? ',
  },
  {
    id: '3',
    username: 'Edward Howell',
    status: 1,
    dinedNum: 7,
    content: '',
  },
  {
    id: '4',
    username: 'Joshua Johnston',
    status: 1,
    dinedNum: 9,
    content: 'If you ask for Pasta Alfredo in a restaurant in Italy all you get from your waiter is a stare. Why is one of the most famous “Italian sauces” for pasta unknown in its country of origin? ',
  },
  {
    id: '5',
    username: 'Dollie Frazier',
    status: 0,
    dinedNum: 3,
    content: 'aaaaaa',
  },
  {
    id: '1',
    username: 'Dollie Frazier',
    status: 0,
    dinedNum: 3,
    content: 'aaaaaa',
  },
  {
    id: '1',
    username: 'Dollie Frazier',
    status: 0,
    dinedNum: 3,
    content: 'aaaaaa',
  },
];

export const staffsTemp = [
  {
    id: '1',
    name: 'Leonardo Oliveira',
    role: 'Server / Host',
    rating: 4.7,
    phone: '883-419-9474',
    email: 'grant_levi@koelpin.co.uk',
    active: true,
    status: 'Completed',
    reviews: reviewsTemp,
  },
  {
    id: '2',
    name: 'Aneta Škodová',
    role: 'Server / Host',
    rating: 4.8,
    phone: '883-419-9474',
    email: 'grant_levi@koelpin.co.uk',
    active: false,
    status: 'Pending',
    reviews: [],
  },
  {
    id: '3',
    name: 'Malin Quist',
    role: 'Server / Host',
    rating: 4.7,
    phone: '883-419-9474',
    email: 'grant_levi@koelpin.co.uk',
    active: true,
    status: 'Completed',
    reviews: reviewsTemp,
  },
  {
    id: '4',
    name: 'Nwoye Akachi',
    role: 'Server / Host',
    rating: 4.7,
    phone: '883-419-9474',
    email: 'grant_levi@koelpin.co.uk',
    active: true,
    status: 'Completed',
    reviews: reviewsTemp,
  },
  {
    id: '5',
    name: 'Ester Bednářová',
    role: 'Server / Host',
    rating: 4.7,
    phone: '883-419-9474',
    email: 'grant_levi@koelpin.co.uk',
    active: false,
    status: 'Completed',
    reviews: reviewsTemp,
  },
  {
    id: '6',
    name: 'Kip Collison',
    role: 'Server / Host',
    rating: 4.7,
    phone: '883-419-9474',
    email: 'grant_levi@koelpin.co.uk',
    active: true,
    status: 'Completed',
    reviews: reviewsTemp,
  },
  {
    id: '7',
    name: 'Yasaman Foroutan',
    role: 'Server / Host',
    rating: 4.7,
    phone: '883-419-9474',
    email: 'grant_levi@koelpin.co.uk',
    active: false,
    status: 'Completed',
    reviews: reviewsTemp,
  },
  {
    id: '8',
    name: 'Zarela Reed',
    role: 'Server / Host',
    rating: 4.7,
    phone: '883-419-9474',
    email: 'grant_levi@koelpin.co.uk',
    active: false,
    status: 'Completed',
    reviews: reviewsTemp,
  },
  {
    id: '9',
    name: 'James Dou',
    role: 'Server / Host',
    rating: 4.3,
    phone: '883-419-9474',
    email: 'grant_levi@koelpin.co.uk',
    active: true,
    status: 'Completed',
    reviews: reviewsTemp,
  },
  {
    id: '10',
    name: 'James Brown',
    role: 'Server / Host',
    rating: 4.9,
    phone: '883-419-9474',
    email: 'grant_levi@koelpin.co.uk',
    active: true,
    status: 'Completed',
    reviews: reviewsTemp,
  },
  {
    id: '11',
    name: 'Jack Yang',
    role: 'Server / Host',
    rating: 5.0,
    phone: '883-419-9474',
    email: 'grant_levi@koelpin.co.uk',
    active: false,
    status: 'Completed',
    reviews: reviewsTemp,
  },
]
export const dayTitles = [ 
  { key: 'Mon', content: 'Monday', num: 0 }, 
  { key: 'Tue', content: 'Tuesday', num: 1 }, 
  { key: 'Wed', content: 'Wednesday', num: 2 }, 
  { key: 'Thu', content: 'Thursday', num: 3 }, 
  { key: 'Fri', content: 'Friday', num: 4 }, 
  { key: 'Sat', content: 'Saturday', num: 5 }, 
  { key: 'Sun', content: 'Sunday', num: 6 }, 
];

export const properlyWeekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const availabiltiesTemp = [
  {
    day: 0,
    times: [
      [
        39600,
        50400
      ],
      [
        61200,
        75600
      ]
    ],
    timezone: "GMT-4"
  },
  {
    day: 1,
    times: [
      [
        39600,
        50400
      ],
      [
        61200,
        75600
      ],
      [
        76000,
        84000
      ]
    ],
    timezone: "GMT-4"
  }
]

// temp
const _generateTablesTemp = () => {
  const emptyList = new Array(31).fill(undefined);
  
  return emptyList.map(() => {
    const tableName = Math.floor(Math.random() * 36);
    return {
      id: String(Math.floor(Math.random() * 44)),

      floor: `Floor ${String(Math.floor(Math.random() * 23))}`,

      name: `Table ${tableName}`,

      tableOrder: tableName,

      status: Math.floor(Math.random() * 100) % 2 === 0 ? '1' : '0',

      order: 'GPSLKJ',

      guests: Math.floor(Math.random() * 5),

      seats: 10,

      itemsCount: Math.floor(Math.random() * 100) % 2 === 0 ? Math.floor(Math.random() * 10) : null,

      shape: 'rectangle',

      position: 'horizontal',
      is_available: Math.floor(Math.random() * 100) % 2 === 0 ? true : false
    };
  });
};

export const tablesTemp = [
  {
    id: '1',
    floor: 'Floor 1',
    floorOrder: 1,
    name: 'Table 1',
    tableOrder: 1,
    status: '1',
    translate: [105, 78],
    order: 'MDLSJL',
    orderDetail: '3 Guests, 7 Items',
    guests: 3,
    itemsCount: 7,
    orderTotal: 171.66,
    server: 'Debahis B',
    seats: 3,
    shape: 'square',
    position: '',
    is_available: false,
  },
  {
    id: '2',
    floor: 'Floor 2',
    floorOrder: 2,
    name: 'Table 2',
    tableOrder: 2,
    status: '1',
    translate: [287, 63],
    order: 'GFDWRH',
    orderDetail: '3 Guests, 7 Items',
    guests: 3,
    itemsCount: 2,
    orderTotal: 175.66,
    server: 'Homura Y',
    seats: 8,
    shape: 'circle',
    position: '',
    is_available: false,
  },
  {
    id: '3',
    floor: 'Floor 3',
    floorOrder: 3,
    name: 'Table 3',
    tableOrder: 3,
    status: '1',
    translate: [500, 78],
    order: 'SFGERS',
    orderDetail: '3 Guests, 7 Items',
    guests: 2,
    itemsCount: 12,
    orderTotal: 175.66,
    server: 'Obasey C',
    seats: 4,
    shape: 'square',
    position: '',
    is_available: false,
  },
  {
    id: '4',
    floor: 'Floor 4',
    floorOrder: 4,
    name: 'Table 4',
    tableOrder: 4,
    status: '0',
    translate: [105, 234],
    order: 'DFILTD',
    orderTotal: 175.66,
    guests: 5,
    seats: 6,
    itemsCount: 8,
    server: 'Ohta K',
    shape: 'circle',
    position: '',
    is_available: false,
  },
  {
    id: '5',
    floor: 'Floor 5',
    floorOrder: 5,
    name: 'Table 5',
    tableOrder: 5,
    status: '0',
    translate: [229, 120],
    order: 'KLASPI',
    orderTotal: 175.66,
    guests: 1,
    seats: 2,
    itemsCount: 2,
    server: 'Jaroslav B',
    shape: 'square',
    position: '',
    is_available: false,
  },
  {
    id: '6',
    floor: 'Floor 6',
    floorOrder: 6,
    name: 'Table 6',
    tableOrder: 6,
    status: '1',
    translate: [500, 96],
    order: 'BGARSF',
    orderDetail: '3 Guests, 7 Items',
    orderTotal: 175.66,
    server: 'Klavdia D',
    guests: 12,
    seats: 12,
    itemsCount: 15,
    shape: 'rectangle',
    position: 'vertical',
    is_available: false,
  },
  {
    id: '7',
    floor: 'Floor 7',
    floorOrder: 7,
    name: 'Table 7',
    tableOrder: 7,
    status: '1',
    translate: [304, 209],
    order: 'GPSLKJ',
    orderTotal: 175.66,
    guests: 8,
    seats: 10,
    itemsCount: 12,
    server: 'Salomé F',
    shape: 'rectangle',
    position: 'vertical',
    is_available: false,
  },
  {
    id: '8',
    floor: 'Floor 8',
    floorOrder: 8,
    name: 'Table 8',
    tableOrder: 8,
    status: '1',
    translate: [474, 234],
    guests: 0,
    seats: 10,
    itemsCount: null,
    shape: 'rectangle',
    position: 'horizontal',
    is_available: true
  },
  ..._generateTablesTemp(),
]

export const wallsTemp = [
  { id: 1, floor: '1', position: 'horizontal', translate: [286, 10], },
  { id: 2, floor: '1', position: 'horizontal', translate: [286, 415], },
  { id: 3, floor: '1', position: 'vertical', translate: [600, 183], },
  { id: 4, floor: '1', position: 'vertical', translate: [10, 183], },
  { id: 5, floor: '2', position: 'vertical', translate: [99, 56], },
  { id: 6, floor: '2', position: 'vertical', translate: [390, 56], },
  { id: 7, floor: '2', position: 'vertical', translate: [640, 56], },
]

export const floorsTemp = [
  { id: '1', value: '1', label: 'First Floor' },
  { id: '2', value: '2', label: 'Second Floor' },
  { id: '3', value: '3', label: 'Third Floor' },
]
export const customizationsTemp = [
  {
    id: '1',
    name: 'Choose size',
    options: [
      'Half', 'Full + $3.00',
    ]
  },
  {
    id: '2',
    name: 'Dressing',
    options: [
      'Half', 'Full + $3.00',
    ]
  },
];

export const categoriesTemp = [
  {
    id: '1',
    name: 'Appetizers',
    items: [
      {
        id: '1',
        title: 'Southwest Egg Rolls',
        description: 'A hearty blend of chicken, monterey jack cheese, black beans, yellow corn, rolled in a tortilla then fried.',
        price: '8.99',
        cal: '840',
        services: [
          'Gluten-Free',
        ],
        rating: 4.5,
        customizations: customizationsTemp,
      },
      {
        id: '2',
        title: 'Mozzarella Sticks',
        description: 'Mozzarella cheese coated with an Italian seasoned breading then fried to a golden brown, served with our homemade marinara sauce.',
        price: '8.99',
        cal: '840',
        services: [
          'Gluten-Free',
        ],
        customizations: customizationsTemp,
      },
    ]
  },
  {
    id: '2',
    name: 'Soups & Salads',
    items: [
      {
        id: '1',
        title: 'Cranberry Apple Pecan Chicken Salad',
        description: 'Lettuce, crisp red and green apples, dried cranberries, roasted pecans, crumbled blue cheese.',
        price: '8.99',
        cal: '840',
        services: [
          'Gluten-Free',
        ],
        rating: 4.5,
        customizations: customizationsTemp,
      },
      {
        id: '2',
        title: 'Chicken Noodle Soup',
        description: 'Our signature soup with chicken breast, celery, carrots, onion and wavy egg noodles.',
        price: '8.99',
        cal: '840',
        services: [
          'Gluten-Free',
        ],
        rating: 4.5,
        customizations: customizationsTemp,
      },
    ]
  },
];

export const _mocked_Food_Checks = [
  { icon: <i className='fas fa-candy-cane' />, name: 'Sugar-Free' },
  { icon: <i className='fas fa-cow' />, name: 'Dairy-Free' },
  { icon: <i className='fas fa-leaf' />, name: 'Vegetarian' },
  { icon: <i className='fas fa-leaf-heart' />, name: 'Vegan' },
  { icon: <i className='fas fa-wheat' />, name: 'Gluten-Free' },
];

export const _mocked_Food_Nutrition = [
  { label: 'Calories', value: { value: 210 } },
  
  { label: 'Total Fat', value: { value: 10, suffix: '%' }, labelSuffix: { value: 8, suffix: 'g' }},
  
  { label: 'Cholesterol', value: { value: 0, suffix: '%' }, labelSuffix: { value: 0, suffix: 'mg' }},
  
  { label: 'Sodium', value: { value: 7, suffix: '%' }, labelSuffix: { value: 160, suffix: 'mg' }},
  
  { label: 'Total Carbohydrate', value: { value: 13, suffix: '%' }, labelSuffix: { value: 37, suffix: 'g' }, detail: [
    { label: 'Dietary Fiber', value: { value: 14, suffix: '%'}, labelSuffix: { value: 4, suffix: 'g' } },
    { label: 'Total Sugars', labelSuffix: { value: 12, suffix: 'g' }, additionalDescription: 'Included 10g Added Sugars' },
  ]},

  { label: 'Protein', labelSuffix: { value: 3, suffix: 'g' }},
];

export const nutritions = {
  calories: {
    title: <h6><b>Calories</b></h6>,
    value: null,
    percentage: 17,
    weight_type: null,
    percentages: <h6><b>210</b></h6>,
    digit: null
  },
  total_fat: {
    title: "Total Fat",
    value: null,
    percentage: 17,
    weight_type: "g",
    percentages: '10%',
    digit: 10
  },
  sat_fat: {
    title: "Saturated Fat",
    value: null,
    percentage: 17,
    weight_type: "g",
    percentages: '7%',
    digit: 37
  },
  trans_fat: {
    title: "Trans Fat",
    value: null,
    percentage: 17,
    weight_type: "mg",
    percentages: '13%',
    digit: 12
  },
  cholesterol: {
    title: "Cholesterol",
    value: null,
    percentage: 17,
    weight_type: "mg",
    percentages: '0%',
    digit: 0
  },
  sodium: {
    title: "Sodium",
    value: null,
    percentage: 17,
    weight_type: "mg",
    percentages: '15%',
    digit: 50
  },
  total_carbs: {
    title: "Total Carbohydrates",
    value: null,
    percentage: 17,
    weight_type: "g",
    percentages: '15%',
    digit: 75,
    temp1:[
      {
        title: "Total Carbohydrates",
        value: null,
        percentage: 17,
        weight_type: "g",
        percentages: '15%',
        digit: 75,
      },
      {
        title: "Total Carbohydrates",
        value: null,
        percentage: 17,
        weight_type: "g",
        percentages: '15%',
        digit: 75,
        temp2 :[]
      }
    ]
  },
  fiber: {
    title: "Dietary Fiber",
    value: null,
    percentage: 17,
    weight_type: "g",
    percentages: '13%',
    digit:25
  },
  sugars: {
    title: "Total Sugars",
    value: null,
    percentage: 17,
    weight_type: "g",
    percentages: '10%',
    digit: 22
  },
  added_sugars: {
    title: "Added Sugars",
    value: null,
    percentage: 17,
    weight_type: "g",
    percentages: '11%',
    digit: 18
  },
  protein: {
    title: "Protein",
    value: null,
    percentage: 17,
    weight_type: "g",
    percentages: null,
    digit: 3
  },
  extras: [
    {
      title: "Vitamin A",
      value: null,
      percentage: 10,
      weightType: "mcg",
    },
    {
      title: "Vitamin B",
      value: null,
      percentage: 10,
      weightType: "mcg",
    },
    {
      title: "Vitamin C",
      value: null,
      percentage: 10,
      weightType: "mcg",
    },
    {
      title: "Vitamin D",
      value: null,
      percentage: 17,
      weight_type: "mcg",
    },
    {
      title: "Vitamin E",
      value: null,
      percentage: 10,
      weightType: "mcg",
    },
    {
      title: "Calcium",
      value: null,
      percentage: 17,
      weight_type: "mcg",
    },
    {
      title: "Iron",
      value: null,
      percentage: 17,
      weight_type: "mg",
    },
    {
      title: "Potassium",
      value: null,
      percentage: 17,
      weight_type: "mg",
    },
  ],
};

export const servers = [
  {
    key: '1',
    name: 'Alfred Summers',
    role: 'Manager/Owner',
    status: 'Active',
    phone: '883-419-9474',
    email: 'grant_levi@koelpin.co.uk',
    rating: '4.0',
  },
  {
    key: '2',
    name: 'Jon Weaver',
    role: 'Kitchen',
    status: 'Active',
    phone: '695-084-7633',
    email: 'grant_levi@koelpin.co.uk',
    rating: '4.0',
  },
  {
    key: '3',
    name: 'Alfred Summers',
    role: 'Manager/Owner',
    status: 'Active',
    phone: '883-419-9474',
    email: 'grant_levi@koelpin.co.uk',
    rating: '4.0',
  },
]
export const ratings = [
  {
    key: '1',
    item: 'Cobb’s Salad',
    rating: 'Meh',
    review: 'Read Feedback',
  },
  {
    key: '2',
    item: 'Crispy Fried Chicken',
    rating: 'Poor',
    review: 'Read Feedback',
  },
  {
    key: '3',
    item: 'Kettle Corn on the Cobb',
    rating: 'Meh',
    review: 'Read Feedback',
  },
]

export const reviews = [
  {
    name: 'Dollie Frazier',
    content: 'If you ask for Pasta Alfredo in a restaurant in Italy all you get from your waiter is a stare. Why is one of the most famous “Italian sauces” for pasta unknown in its country of origin? The answer is simple: because in Italy Pasta Alfredo doesn’t exist.',
  },
  {
    name: 'Ronald Lloyd',
    content: 'If you ask for Pasta Alfredo in a restaurant in Italy all you get from your waiter is a stare. Why is one of the most famous “Italian sauces” for pasta unknown in its country of origin? The answer is simple: because in Italy Pasta Alfredo doesn’t exist.',
  },
  {
    name: 'Derek Reynolds',
    content: 'If you ask for Pasta Alfredo in a restaurant in Italy all you get from your waiter is a stare. Why is one of the most famous “Italian sauces” for pasta unknown in its country of origin? The answer is simple: because in Italy Pasta Alfredo doesn’t exist.',
  },
];

export const sortOptions = [
  { key: '1', title: 'Earliest' },
  { key: '2', title: 'Oldest' },
  { key: '3', title: 'Highest-Rated' },
  { key: '4', title: 'Lowest-Rated' },
];

export const dataForGraph = [
  { total_sales: 21 },
  { total_sales: 22 },
  { total_sales: 23 },
  { total_sales: 26 },
  { total_sales: 28 },
  { total_sales: 29 },
  { total_sales: 30 },
  { total_sales: 32 },
  { total_sales: 33 },
  { total_sales: 34 },
  { total_sales: 36 },
  { total_sales: 38 },
  { total_sales: 40 },
  { total_sales: 40 },
  { total_sales: 39 },
  { total_sales: 37 },
  { total_sales: 34 },
  { total_sales: 31 },
  { total_sales: 30 },
  { total_sales: 29 },
  { total_sales: 28 },
  { total_sales: 28 },
  { total_sales: 25 },
  { total_sales: 23 },
  { total_sales: 20 },
  { total_sales: 18 },
  { total_sales: 16 },
  { total_sales: 15 },
  { total_sales: 15 },
  { total_sales: 17 },
  { total_sales: 19 },
  { total_sales: 20 },
  { total_sales: 21 },
  { total_sales: 23 },
  { total_sales: 25 },
  { total_sales: 28 },
  { total_sales: 30 },
  { total_sales: 30 },
  { total_sales: 31 },
  { total_sales: 32 },
  { total_sales: 35 },
  { total_sales: 38 },
  { total_sales: 41 },
  { total_sales: 43 },
  { total_sales: 43 },
  { total_sales: 42 },
  { total_sales: 37 },
  { total_sales: 30 },
  { total_sales: 27 },
  { total_sales: 25 },
  { total_sales: 21 },
  { total_sales: 21 },
  { total_sales: 23 },
  { total_sales: 25 },
  { total_sales: 29 },
  { total_sales: 30 },
  { total_sales: 31 },
  { total_sales: 33 },
  { total_sales: 35 },
  { total_sales: 36 },
  { total_sales: 37 },
  { total_sales: 38 },
  { total_sales: 39 },
  { total_sales: 41 },
  { total_sales: 42 },
  { total_sales: 42 },
  { total_sales: 41 },
  { total_sales: 39 },
  { total_sales: 38 },
  { total_sales: 37 },
  { total_sales: 36 },
  { total_sales: 36 }
];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
