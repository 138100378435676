import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ItemPicker from 'components/DrawerForm/ItemPicker';
import { DrawerProfileUpdateEmailContainer } from './DrawerProfileUpdateEmailContainer';

export function EmailField({ isInDrawer = false, email, onChange = () => {} }) {
  const [
    openDrawerProfileUpdateEmailContainer,
    setOpenDrawerProfileUpdateEmailContainer,
  ] = useState(false);
  const onOpenDrawerProfileUpdateEmailContainer = () =>
    setOpenDrawerProfileUpdateEmailContainer(true);

  const onCloseDrawerProfileUpdateEmailContainer = () =>
    setOpenDrawerProfileUpdateEmailContainer(false);

  return (
    <>
      <ItemPicker
        isInDrawer={isInDrawer}
        className='horizontal-inner-element'
        label='Email Address'
        value={email}
        onClick={onOpenDrawerProfileUpdateEmailContainer}
        placeholder='Add email address'
      />
      <DrawerProfileUpdateEmailContainer
        visible={openDrawerProfileUpdateEmailContainer}
        onClose={onCloseDrawerProfileUpdateEmailContainer}
        onChange={onChange}
      />
    </>
  );
}

EmailField.propTypes = {
  isInDrawer: PropTypes.bool,
  email: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  onChange: PropTypes.func,
};
