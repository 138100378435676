import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerDeleteFloorContainer = styled(SingleDrawer)`
  background-color: ${colors.gray5};
  padding: 36px 26px 0px;

  &.delete-floor-hide {
    display: none;
  }
`;
