import styled from '@emotion/styled';
import Box from 'components/Box';
import { colors } from 'theme';

export const CategoryTopContainer = styled(Box)`
  .item-assign-category-top {
    width: 100%;
    display: flex;
    align-items: center;

    .current-status-container {
      .current-status {
        background-color: ${colors.redColor};
        padding: 2px 5px;
        border-radius: 4px;
      }
    }

    .show-control-container {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .show-hide-control {
        font-size: 18px;
        width: 18px;
        height: 18px;
        // margin-left: 20px;

        .show-hide-icon {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .childs {
    .pre-children-container {
      margin-top: 22px;
    }
  }
`;
