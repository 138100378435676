import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

import { DefaultUser, HighestServer } from 'assets/images/profile';

export const DrawerServerReviewContainer = styled(SingleDrawer)`
  padding: 0;
  background-color: ${colors.gray2};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .close-icon {
    position: absolute;
    left: 33px;
    top: 36px;
    cursor: pointer;
    z-index: 101;
    i {
      font-size: 28px;
      letter-spacing: 0.53px;
      color: white;
    }
  }

  .server-detail-container {
    z-index: 101;
    position: absolute;
    top: 96px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .server-image {
      width: 133px;
      height: 133px;
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(${HighestServer});
    }

    .server-full-name {
      margin-top: 24px;
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -0.48px;
      color: white;
    }

    .position-title {
      margin-bottom: 31px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.38px;
      color: white;
    }
  }

  .server-container {
    width: 100%;
    height: 350px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url(${HighestServer});
    filter: blur(6px);
  }

  .reviews-container {
    position: relative;
    top: -4px;
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${colors.gray2};
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;

    .review-item {
      width: 100%;
      padding: 28px 0px 13px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      display: flex;
      flex-direction: column;
      align-items: center;

      &:last-of-type {
        border-bottom-color: transparent;
      }

      .review-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(${DefaultUser});
      }

      .name {
        margin: 12px 0px 14px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.43px;
        align-items: center;
        color: white;
      }

      .rating-stars {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;

        .rating-star {
          margin-left: 2px;
          margin-right: 2px;
          &.marked {
            i {
              color: ${colors.rating};
            }
          }

          i {
            font-size: 17px;
            letter-spacing: -0.41px;
            color: ${colors.emptyRating};
          }
        }
      }

      .review-content {
        width: 278px;
        font-size: 15px;
        text-align: center;
        letter-spacing: -0.36px;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
`;
