import customAxios from 'services/customAxios';

const getRestaurants = (payload) => customAxios.get(`/restaurant${Object.keys(payload).length > 0 ? '?' + Object.entries(payload).map(([key, value]) => `${key}=${value}`).join('&') : ''}`);
const getRestaurantById = async (id, onOk, onFail) => {
  if (id) {
    const { ok, data, errMessage } = await customAxios.get(`/restaurant/${id}?get_detail=true`);
    if (ok) {
      onOk(data);
    } else {
      onFail(errMessage);
    }
  }
}

const updateRestaurantDetails = async (id, payload, onOk = () => {}, onFail = () => {}) => {
  const { ok, errMessage } = await customAxios.put(`/restaurant/${id}`, payload);
  if (ok) {
    onOk();
  } else {
    onFail(errMessage);
  }
};

const getMenus = async (restaurantId) => await customAxios.get(`/restaurant/${restaurantId}/menu`);
const _getMenus = async (restaurantId, onOk, onFail) => {
  const { ok, data, errMessage } = await customAxios.get(`/restaurant/${restaurantId}/menu`);
  if (ok) {
    onOk(data);
  } else {
    onFail(errMessage);
  }
};
const createMenu = async ({ restaurantId }, payload) => await customAxios.post(`/restaurant/${restaurantId}/menu`, payload);
const updateMenu = async ({ restaurantId, menuId }, payload) => await customAxios.put(`/restaurant/${restaurantId}/menu/${menuId}`, payload);
const deleteMenu = ({ restaurantId, menuId }) => customAxios.delete(`/restaurant/${restaurantId}/menu/${menuId}`);
const getMenuDetails = ({ restaurantId, menuId }) => customAxios.get(`/restaurant/${restaurantId}/menu/${menuId}/detail`);

const foodSearch = ({ restaurantId, menuId }, { query }) => customAxios.get(`/restaurant/${restaurantId}/menu/${menuId}/search?query=${query}`);
const getFoodsByMenu = ({ restaurantId, menuId }) => customAxios.get(`/restaurant/${restaurantId}/menu/${menuId}/food`);
const addFoodToMenu = ({ restaurantId, menuId }, payload) => customAxios.post(`/restaurant/${restaurantId}/menu/${menuId}/food`, payload);
const moveFoodToOtherMenu = ({ restaurantId, menuId }, payload) => customAxios.patch(`/restaurant/${restaurantId}/menu/${menuId}/food`, payload);
const deleteFoodInMenu = ({ restaurantId, menuId }, payload) => customAxios.get(`/restaurant/${restaurantId}/menu/${menuId}/food`, { data: payload }); // delete payload. Need extra check.

const getCategories = async (restaurantId) => await customAxios.get(`/restaurant/${restaurantId}/category`);
const _getCategories = async (restaurantId, menuId, onOk, onFail) => {
  const { ok, data, errMessage } = await customAxios.get(`/restaurant/${restaurantId}/menu/${menuId}?get_detail=true`);
  if (ok) {
    onOk(data);
  } else {
    onFail(errMessage);
  }
};
const createCategory = (restaurantId, menuId, payload) => customAxios.post(`/restaurant/${restaurantId}/menu/${menuId}/category`, payload);
const updateCategory = ({ restaurantId, categoryId }, payload) => customAxios.put(`/restaurant/${restaurantId}/category/${categoryId}`, payload);
const deleteCategory = ({ restaurantId, categoryId }) => customAxios.delete(`/restaurant/${restaurantId}/category/${categoryId}`);

const getFoodsByCategory = ({ restaurantId, categoryId }) => customAxios.get(`/restaurant/${restaurantId}/category/${categoryId}/food`);
const addFoodToCategory = ({ restaurantId, categoryId }, payload) => customAxios.post(`/restaurant/${restaurantId}/category/${categoryId}/food`, payload);
const moveFoodToOtherCategory = ({ restaurantId, categoryId }, payload) => customAxios.patch(`/restaurant/${restaurantId}/category/${categoryId}/food`, payload);
const deleteFoodInCategory = ({ restaurantId, categoryId }, payload) => customAxios.delete(`/restaurant/${restaurantId}/category/${categoryId}/food`, { data: payload }); // delete payload. Need extra check.

const createFood = (restaurantId, payload) => customAxios.post(`/restaurant/${restaurantId}/food`, payload);
const updateFood = ({ restaurantId, foodId }, payload) => customAxios.put(`/restaurant/${restaurantId}/food/${foodId}`, payload);
const deleteFood = ({ restaurantId, foodId }) => customAxios.delete(`/restaurant/${restaurantId}/food/${foodId}`);

const getReviews = (restaurantId, { page, page_size, sort }) => customAxios.get(`/restaurant/${restaurantId}/review?page=${page}&page_size=${page_size}&sort=${sort}`);
const createReview = (restaurantId, payload) => customAxios.post(`/restaurant/${restaurantId}/review`, payload);

const getStaffs = async (restaurantId, payload, onOk, onFail) => {
  const { ok, data, errMessage } = await customAxios.get(`/restaurant/${restaurantId}/staff${Object.keys(payload).length > 0 ? '?' + Object.entries(payload).map(([key, value]) => `${key}=${value}`).join('&') : ''}`);
  if (ok) {
    onOk(data);
  } else {
    onFail(errMessage);
  }
};
const createStaff = (restaurantId, payload) => customAxios.post(`/restaurant/${restaurantId}/staff`, payload);
const deleteStaff = ({ restaurantId, staffId }) => customAxios.delete(`/restaurant/${restaurantId}/staff/${staffId}`);
const getStaffHighest = (restaurantId) => customAxios.get(`/restaurant/${restaurantId}/staff/highest`);
const updateStaff = ({ restaurantId, staffId }, payload) => customAxios.put(`/restaurant/${restaurantId}/staff/${staffId}`, payload);

const getClosedDates = (id) => customAxios.get(`/restaurant/${id}/closed`);
const addClosedDates = async (id, payload, onOk, onFail) => {
  const {ok, data, errMessage } = await customAxios.post(`/restaurant/${id}/closed`, payload);
  if (ok) {
    onOk(data);
  } else {
    onFail(errMessage);
  }
};
const deleteClosedDates = async (id, closedId, onOk, onFail) => {
  const { ok, errMessage } = await customAxios.delete(`/restaurant/${id}/closed/${closedId}`);
  if (ok) {
    onOk();
  } else {
    onFail(errMessage);
  }
};

const getCustomizationOfFood = ({ restaurantId, foodId }) => customAxios.get(`/restaurant/${restaurantId}/food/${foodId}/customization`);
const getAllCustomizationsByFood = async ({ restaurantId, foodId }, onOk, onFail) => {
  // clone of `getCustomizationOfFood` but with new API
  const { ok, data, errMessage } = await customAxios.get(`/restaurant/${restaurantId}/food/${foodId}/customization`);

  if (ok) {
    onOk(data);
  } else {
    onFail(errMessage);
  }
};
const createCustomizationOfFood = async ({ restaurantId, foodId, payload}, onOk = () => {}, onFail = () => {}) => {
  const { ok, data, errMessage } = await customAxios.post(`/restaurant/${restaurantId}/food/${foodId}/customization`, payload);
  if (ok) {
    onOk(data);
  } else {
    onFail(errMessage);
  }
};
const sortCustomizationsOfFood = ({ restaurantId, foodId }, payload) => customAxios.post(`/restaurant/${restaurantId}/food/${foodId}/customization/sort`, payload);
const getCustomizationOfFoodById = ({ restaurantId, foodId, customizationId }) => customAxios.get(`/restaurant/${restaurantId}/food/${foodId}/customization/${customizationId}`);
const updateCustomizationById = ({ restaurantId, foodId, customizationId }, payload) => customAxios.put(`/restaurant/${restaurantId}/food/${foodId}/customization/${customizationId}`, payload);
const deleteCustomizationOfFoodById = async ({ restaurantId, foodId, customizationId }, onOk, onFail) => {
  const { ok, errMessage } = await customAxios.delete(`/restaurant/${restaurantId}/food/${foodId}/customization/${customizationId}`);
  if (ok) {
    onOk();
  } else {
    onFail(errMessage);
  }
};
const updateSortCustomizationByUserSequence = async ({ restaurantId, foodId, payload }, onOk =() => {}, onFail = () => {}) => {
  const { ok, errMessage } = await customAxios.post(`/restaurant/${restaurantId}/food/${foodId}/customization/sort`, { sort: payload });
  if (ok) {
    onOk();
  } else {
    onFail(errMessage);
  }
};

const getFloor = async (restaurantId, get_detail, onOk, onFail) => {
  const { ok, data, errMessage } = await customAxios.get(`/restaurant/${restaurantId}/floor${get_detail ? '?get_detail=true' : ''}`);
  if (ok) {
    onOk(data);
  } else {
    onFail(errMessage);
  }
};
const createFloor = async (restaurantId, payload, onOk, onFail) => {
  const { ok, errMessage } = await customAxios.post(`/restaurant/${restaurantId}/floor`, payload);
  if (ok) {
    onOk();
  } else {
    onFail(errMessage);
  }
}
const getFloorById = ({ restaurantId, floorId }) => customAxios.get(`/restaurant/${restaurantId}/floor/${floorId}`);
const updateFloor = ({ restaurantId, floorId }, payload) => customAxios.put(`/restaurant/${restaurantId}/floor/${floorId}`, payload);
const _updateFloor = async ({ restaurantId, floorId }, payload, onOk, onFail) => {
  const { ok, errMessage } = await customAxios.put(`/restaurant/${restaurantId}/floor/${floorId}`, payload);
  if (ok) {
    onOk();
  } else {
    onFail(errMessage);
  }
};
const deleteFloor = async ({ restaurantId, floorId }, onOk, onFail) => {
  const { ok, errMessage } = await customAxios.delete(`/restaurant/${restaurantId}/floor/${floorId}`);
  if (ok) {
    onOk();
  } else {
    onFail(errMessage);
  }
};

const getTablesList = (restaurantId, payload) => customAxios.get(`/restaurant/${restaurantId}/table${Object.keys(payload).length > 0 ? '?' + Object.entries(payload).map(([key, value]) => `${key}=${value}`).join('&') : ''}`);
const getTablesListAvailable = (restaurantId, payload) => customAxios.get(`/restaurant/${restaurantId}/table/available${Object.keys(payload).length > 0 ? '?' + Object.entries(payload).map(([key, value]) => `${key}=${value}`).join('&') : ''}`);

const getRestaurantStatistics = (id, date) => customAxios.get(`/restaurant/${id}/stats${date ? '?date=' + date : ''}`);

const getRestaurantOrders = (id, payload) => customAxios.get(`/restaurant/${id}/orders${Object.keys(payload).length > 0 ? '?' + Object.entries(payload).map(([key, value]) => `${key}=${value}`).join('&') : ''}`);
const getRestaurantOrdersStatus = (id, date) => customAxios.get(`/restaurant/${id}/orders/stats${date ? '?date=' + date : ''}`);
const getActivityList = (restaurantId, payload) => customAxios.get(`/restaurant/${restaurantId}/activity${Object.keys(payload).length > 0 ? '?' + Object.entries(payload).map(([key, value]) => `${key}=${value}`).join('&') : ''}`);

export const restaurantServices = {
    getRestaurants,
    getRestaurantById,
    updateRestaurantDetails,
    getMenus,
    // refactored version of getMenus
    _getMenus,
    createMenu,
    updateMenu,
    deleteMenu,
    getMenuDetails,
    foodSearch,
    getFoodsByMenu,
    addFoodToMenu,
    moveFoodToOtherMenu,
    deleteFoodInMenu,
    getCategories,
    // refactored version of getCategories
    _getCategories,
    createCategory,
    updateCategory,
    deleteCategory,
    getFoodsByCategory,
    addFoodToCategory,
    moveFoodToOtherCategory,
    deleteFoodInCategory,
    createFood,
    updateFood,
    deleteFood,
    getReviews,
    createReview,
    getStaffs,
    createStaff,
    deleteStaff,
    getStaffHighest,
    updateStaff,
    getClosedDates,
    addClosedDates,
    deleteClosedDates,
    getCustomizationOfFood,
    getAllCustomizationsByFood,
    createCustomizationOfFood,
    sortCustomizationsOfFood,
    getCustomizationOfFoodById,
    updateCustomizationById,
    deleteCustomizationOfFoodById,
    updateSortCustomizationByUserSequence,

    getFloor,
    createFloor,
    getFloorById,
    updateFloor,
    // refactored version of updateFloor
    _updateFloor,
    deleteFloor,
    getTablesList,
    getTablesListAvailable,
    getRestaurantStatistics,
    getRestaurantOrders,
    getRestaurantOrdersStatus,
    getActivityList,
};
