import styled from '@emotion/styled';
import { colors } from 'theme';

export const MiniNotificationControlContainer = styled.div`
  margin-bottom: 11px;
  display: flex;
  align-items: center;

  .control-item-container {
    cursor: pointer;
    padding: 22px 71px;

    &:first-of-type {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: ${colors.gray5};
    }

    &:nth-of-type(2) {
      background-color: ${colors.redColor};
    }

    &:last-of-type {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      background-color: rgba(255, 255, 255, 0.08);
    }

    i {
      font-size: 15px;
      line-height: 12px;
      letter-spacing: -0.36px;
      color: white;
    }
  }
`;
