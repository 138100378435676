import styled from "@emotion/styled";
import SingleDrawer from "components/SingleAntDrawer";
import { colors } from "theme";

export const DrawerStaffContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  .form-container {
    .form-group {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 10px;
      padding-left: 5px;
      .form-label {
        margin-bottom: 0;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.3);
      }
      .form-control {
        padding: 0;
        height: 25px;
        font-size: 14px;
        font-weight: 500;
        background-color: transparent;
        border: unset;
        outline: unset;
        color: #ffffff;
      }
      .form-control:focus {
        box-shadow: unset;
      }
    }
  }
  .drawer--options {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    .drawer--option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 5px;
      width: 100%;
      height: 62px;
      border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    }
  }
  .form-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;
