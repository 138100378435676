export class DollarsPercentagesBinding {
  constructor(dollars, percentages) {
    this.dollars = dollars;
    this.percentages = percentages;
  }

  receiveTotalPriceForCalculations(orderTotalPrice) {
    this.orderTotalPrice = orderTotalPrice;
  }

  computeDollars() {
    return (this.orderTotalPrice * (this.percentages / 100)).toFixed(2);
  }

  computePercentages() {
    return ((this.dollars / this.orderTotalPrice) * 100).toFixed(2);
  }
}
