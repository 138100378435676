import React from 'react';
import PropTypes from 'prop-types';
import { DrawerSortContainer } from './style';
import { colors } from 'theme';
import { optionsByTimeAndRating } from 'hooks/useSortByTimeAndRating';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
export function DrawerSort({
  visible = false,
  onClose = () => {},
  sortValue = '',
  onChangeSortValue = () => {},
}) {

  const onChoose = (option) => {
    onChangeSortValue(option);
    onClose();
  };

  return (
    <DrawerSortContainer visible={visible} placement='bottom' onClose={onClose}>
      <SingleText size={22} spacing='0.42px' marginBottom={15}>
        Sort
      </SingleText>
      <div className='sort-options'>
        {optionsByTimeAndRating.map((option) => (
          <div
            className={`sort-option ${option === sortValue ? 'active' : ''}`}
            onClick={() => onChoose(option)}
            key={option}
          >
            {option}
          </div>
        ))}
      </div>
      <SingleButton
        title='Cancel'
        btnColor='transparent'
        txtColor={colors.redColor}
        margin='20px 0px 0px 0px'
        onClick={onClose}
      />
    </DrawerSortContainer>
  );
}

DrawerSort.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  sortValue: PropTypes.string,
  onChangeSortValue: PropTypes.func,
};
