import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft } from 'react-icons/fa';
import { DrawerChooseExistingItemContainer } from './style';
import { colors } from 'theme';
import Box from 'components/Box';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';
import SingleIconWrapper from 'components/SingleIconWrapper';
import { AuthContext } from 'contexts/AuthContextContainer';
import { StateContext } from 'contexts/StateContextContainer';
import { DataContext } from 'contexts/DataContextContainer';
import { foodServices } from 'services/api-services';

import { DrawerSuccessfullyAddedContainer } from './DrawerSuccessfullyAddedContainer';
import { DrawerAddingFailedContainer } from './DrawerAddingFailedContainer';

import { DefaultFood } from 'assets/images/menu';

export function DrawerChooseExistingItem({
  visible,
  onClose,
}) {
  const [checkedIndexes, setCheckedIndexes] = useState([]);
  const [successfullyAddedContainer, setSuccessfullyAddedContainer] = useState(false);
  const [failedToAddContainer, setFailedToAddContainer] = useState(false);

  const { foods, selectedCategory, categories, allCategories, setAllCategories } = useContext(StateContext);
  const { restaurantId } = useContext(AuthContext);
  const { chosenMenu } = useContext(DataContext);

  const onAdd = (newIndex) => setCheckedIndexes([...checkedIndexes, newIndex]);
  const onRemove = (indexToDelete) =>
    setCheckedIndexes(
      checkedIndexes.filter((valueIndex) => valueIndex !== indexToDelete)
    );

  const handleClick = (index) => {
    if (checkedIndexes.includes(index)) {
      onRemove(index);
    } else {
      onAdd(index);
    }
  };

  const onSuccessfullyAdded = () => {
    const choosedFoods = checkedIndexes.map((index) =>
      foods.find((food) => food.id === index)
    );

    const requests = choosedFoods.map((food) =>
      foodServices.updateFood(restaurantId, food.id, {
          ...food,
          photo: food.photo?.id??null,
          category: [selectedCategory.id],
        })
        .then((res) => res)
        .catch((err) => err)
    );

    Promise.all(requests).then((values) => {
      const error = values.find(value => !value.ok);
      if(error) setFailedToAddContainer(true);
      else {
        setSuccessfullyAddedContainer(true);
        const newCategories = categories.map(category => {
          if(category.id === selectedCategory.id) {
            return {
              ...category,
              items: [...category.items, ...checkedIndexes]
            }
          }
          return category;
        })

        const updatedAllCategories = allCategories.map(category => {
          if (category.id === chosenMenu) {
            return {...category, categories: newCategories}
          } else return category;
        })

        setAllCategories(updatedAllCategories)
      }
    });
  };

  const tryAgain = () => {
    setFailedToAddContainer(false);
    onSuccessfullyAdded();
  };

  const closeDrawer = () => {
    setCheckedIndexes([]);
    onClose();
  }

  const closeSuccessfullyAddedContainer = () => {
    setSuccessfullyAddedContainer(false);
    setCheckedIndexes([]);
  }

  return (
    <>
      <DrawerChooseExistingItemContainer visible={visible} onClose={closeDrawer}>
        <SingleIconWrapper className='exit-icon' onClick={onClose}>
          <FaChevronLeft color='white' />
        </SingleIconWrapper>
        <SingleText
          size={22}
          lineHeight='33px'
          spacing={'0'}
          weight={500}
          marginLeft={33}
          marginBottom={8}
        >
          Choose an existing item
        </SingleText>
        <SingleText
          spacing='0.27px'
          color='#bbb'
          weight={500}
          marginLeft={33}
          marginBottom={50}
        >
          You may select more than one.
        </SingleText>

        {foods.map(({ id, title, photo }) => (
          <Box
            className={`existing-food-card ${
              checkedIndexes && checkedIndexes.includes(id) ? 'selected' : ''
            }`}
            onClick={() => handleClick(id)}
            key={id}
          >
            <Box
              className='food-photo'
              backgroundImage={`url(${photo ? photo.file : DefaultFood})`}
            />
            <SingleText spacing='0.27px' marginLeft={14} weight={500}>
              {title.length > 30 ? title.slice(0, 29) + '...' : title}
            </SingleText>
          </Box>
        ))}

        <Box className='bottomControl'>
          {checkedIndexes.length && (
            <SingleText
              size={13}
              spacing='0.25px'
              marginBottom={23}
              weight={500}
            >
              Selected {checkedIndexes.length} items.
            </SingleText>
          )}
          <SingleButton
            title='Add items to category'
            onClick={() => onSuccessfullyAdded(checkedIndexes.length)}
            btnColor={
              checkedIndexes.length === 0 ? colors.gray3 : colors.redColor
            }
            disabled={checkedIndexes.length === 0}
          />
        </Box>
      </DrawerChooseExistingItemContainer>

      <DrawerSuccessfullyAddedContainer
        visible={successfullyAddedContainer}
        onClose={closeSuccessfullyAddedContainer}
        category={selectedCategory}
        amount={checkedIndexes.length}
      />
      <DrawerAddingFailedContainer
        visible={failedToAddContainer}
        tryAgain={tryAgain}
        onCancel={() => setFailedToAddContainer(false)}
      />
    </>   
  );
}

DrawerChooseExistingItem.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  foods: PropTypes.array,
  onAdd: PropTypes.func,
};
