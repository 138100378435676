import styled from '@emotion/styled';
import Moveable from 'react-moveable';
import syncAlt from 'assets/images/sync-alt-solid.svg';

export const MoveableStyled = styled(Moveable)`
  .moveable-line {
    background: #ed2626 !important;
  }
  .moveable-dashed {
    background: transparent !important;
    &.moveable-horizontal {
      border-top: var(--zoompx) dashed #ed2626 !important;
    }
    &.moveable-vertical {
      border-left: var(--zoompx) dashed #ed2626 !important;
    }
  }
  .moveable-dashed:before {
    color: #ed2626 !important;
  }

  .moveable-rotation-line {
    .moveable-control {
      width: 40px;
      height: 40px;
      border-radius: 25px;
      background-color: #ed2626;
      background: #ed2626 !important;
      border: none;
      margin-top: -20px;
      margin-left: -20px;
      position: relative;
    }
    .moveable-control:after {
      width: 16px;
      height: 16px;
      background-image: url(${syncAlt}) !important;
      content: '';
      left: 12px;
      top: 12px;
      position: absolute;
    }
  }

  &.hidden-rotatable {
    .moveable-rotation-line {
      display: none;
    }
  }
`;

export const ContextMenuContainer = styled.div`
  position: absolute;
  border-radius: 12px;
  padding: 5px 0px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.26);
  border: solid 1px rgba(255, 255, 255, 0.08);
  background-color: #151617;
  .context-menu-item {
    color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 5px 20px;
    cursor: pointer;
    ${'' /* border-radius: 6px; */}
    span {
      margin-left: 15px;
    }
  }
  .context-menu-item:last-child {
    margin-bottom: 0px;
  }
  .context-menu-item:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`;
