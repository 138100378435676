import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerAddDayContainerWrapper = styled(SingleDrawer)`
  background-color: ${colors.gray1};
  padding: initial;
  .edit-day-block-calendar {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #27282a;
    padding: 20px 0;

    &.active {
      bottom: 0;
      transition-property: bottom;
    }

    .fixed-height-calendar {
      height: 380px;
      margin-bottom: 40px;
    }

    .head-block {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      h3 {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
      }
      .delete-container {
        cursor: pointer;
        i {
          font-size: 20px;
          color: rgba(255, 255, 255, 0.5);

          -moz-transition: color 0.4s ease-out;
          -o-transition: color 0.4s ease-out;
          -webkit-transition: color 0.4s ease-out;
          -ms-transition: color 0.4s ease-out;
          transition: color 0.4s ease-out;
        }
      }
      .delete-container:hover {
        i {
          color: #ed2626;
        }
      }
    }

    .body-block {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin-left: -30px;
      margin-right: -30px;
      padding: 0px 30px;
      .name-container {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding: 20px 0;
        p {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.5);
          margin-bottom: 5px;
        }
        input {
          font-size: 14px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.3);
          border: none;
          background-color: transparent;
          outline: none;
          padding: 0;
        }
      }

      .repeat-annually {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        p {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 0;
          color: #ffffff;
        }

        .ant-switch {
          background-color: #5f626a;
        }
        .ant-switch-checked {
          background-color: #ed2626;
        }
      }
    }

    .button-save {
      height: 50px;
      margin-top: 0px;
      width: 100%;
      border-radius: 8px;
      background-color: #ed2626;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      border: none;
      cursor: pointer;

      opacity: 1;
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -webkit-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;

      &.inactive-button {
        opacity: 0.5;
      }
    }
    .button-save:hover {
      opacity: 0.9;
      &.inactive-button {
        opacity: 0.5;
      }
    }

    .button-cancel {
      height: 50px;
      margin-top: 20px;
      width: 100%;
      border-radius: 8px;
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: ${colors.redColor};
      outline: none;
      border: none;
      cursor: pointer;

      opacity: 1;
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -webkit-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out;
    }
    .button-cancel:hover {
      opacity: 0.9;
    }

    .rdrCalendarWrapper {
      width: 100%;
      background-color: #27282a;
      .rdrDateDisplayWrapper {
        display: none;
      }
      .rdrMonthAndYearWrapper {
        padding: 0 20px;
        .rdrPprevButton,
        .rdrNextButton {
          background: transparent;
          i {
            font-size: 17px;
          }
        }
        .rdrPprevButton {
          i {
            border-color: transparent #ffffff transparent transparent;
          }
        }
        .rdrNextButton {
          i {
            border-color: transparent transparent transparent #ffffff;
          }
        }
        .rdrMonthAndYearPickers {
          .rdrMonthPicker {
            width: 120px;
            select {
              font-family: Montserrat;
              width: 180px;
              padding-left: 30px;
              color: #fff;
              -moz-appearance: none;
              -webkit-appearance: none;
              appearance: none;
              background-color: #27282a;
              font-size: 17px;
              font-weight: bold;
            }
          }
          .rdrYearPicker {
            display: none;
          }
        }
      }
      .rdrMonths {
        padding: 0 20px;
        .rdrMonth {
          padding: 0;
          width: 100%;
          .rdrWeekDays {
            .rdrWeekDay {
              font-size: 13px;
              font-weight: 600;
              color: rgba(255, 255, 255, 0.5);
              // white-space: nowrap;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // max-width: 20px;
            }
          }
          .rdrDays {
            .rdrDay {
              height: 5em;

              &.closed {
                .rdrDayNumber {
                  position: relative;
                  &:after {
                    content: '';
                    display: block;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: ${colors.redColor};
                    position: absolute;
                    bottom: 0;
                  }
                }
              }

              &.rdrDayHovered {
                .rdrSelected,
                .rdrDayStartPreview,
                .rdrDayEndPreview {
                  color: #ed2626 !important;
                }
              }

              .rdrDayNumber {
                span {
                  color: #fff;
                  font-size: 16px;
                  font-weight: bold;
                }
              }
              .rdrSelected,
              .rdrDayStartPreview,
              .rdrDayEndPreview {
                color: #ed2626 !important;
              }

              &.rdrDayToday {
                .rdrDayNumber {
                  border: 2px solid ${colors.redColor};
                  border-radius: 15px;

                  span:after {
                    display: none;
                  }
                }
              }
            }
            .rdrDayPassive {
              .rdrDayNumber {
                span {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
`;
