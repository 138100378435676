export class PhoneNumberFormatter {
  phoneNumber;

  constructor(phoneNumber = '', countryCode = 1) {
    this.phoneNumber = phoneNumber;
    this.countryCode = countryCode;
  }

  formatPhoneNumber() {
    this.formatPhoneNumberWithoutCountryCode();
    return `+${this.countryCode} ${this.phoneNumber}`;
  }

  formatPhoneNumberWithoutCountryCode() {
    this.removeCountryCode();
    this.formatPreparedPhoneNumber();
    return this.phoneNumber;
  }

  removeCountryCode() {
    this.phoneNumber = this.phoneNumber.slice(1);
    return this.phoneNumber;
  }

  formatPreparedPhoneNumber() {
    const digitsInPhoneNumber = this.phoneNumber.split('');

    digitsInPhoneNumber.splice(3, 0, ') ');
    digitsInPhoneNumber.splice(7, 0, '-');
    digitsInPhoneNumber.splice(0, 0, '(');

    this.phoneNumber = digitsInPhoneNumber.join('');
  }
}
