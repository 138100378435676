import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AccountContainer } from './style';
import { PhoneNumberFormatter } from 'utils';
import { FullName } from './FullName';
import { Field } from './Field';
import { DrawerUpdateEmailAddressContainer } from './DrawerUpdateEmailAddressContainer';
import { DrawerUpdateMobileNumberContainer } from './DrawerUpdateMobileNumberContainer';

export function Account({
  user = {},
  changeFirstName = () => {},
  changeLastName = () => {},
  changeEmail = () => {},
  changeMobileNumber = () => {},
  confirmMobileNumber = () => {},
  confirmEmail = () => {},
  setUserChanged = () => {},
}) {
  const [
    openDrawerUpdateEmailAddressContainer,
    setOpenDrawerUpdateEmailAddressContainer,
  ] = useState(false);

  const [
    openDrawerUpdateMobileNumberContainer,
    setOpenDrawerUpdateMobileNumberContainer,
  ] = useState(false);

  const formatPhoneNumber = () => {
    const phoneNumberFormatter = new PhoneNumberFormatter(user.phone_number);
    return phoneNumberFormatter.formatPhoneNumber();
  };

  return (
    <AccountContainer>
      <FullName
        firstName={user.first_name}
        onChangeFirstName={(e) => {
          changeFirstName(e);
          setUserChanged();
        }}
        lastName={user.last_name}
        onChangeLastName={(e) => {
          changeLastName(e);
          setUserChanged();
        }}
      />
      <Field
        label='Mobile Number'
        placeholder='Add mobile number'
        value={formatPhoneNumber()}
        onClick={() => {
          setOpenDrawerUpdateMobileNumberContainer(true);
          setUserChanged();
        }}
        isVerified={user.isMobileNumberConfirmed}
      />
      <Field
        label='Email Address'
        placeholder='Add e-mail address'
        value={user.email}
        onClick={() => {
          setOpenDrawerUpdateEmailAddressContainer(true);
          setUserChanged();
        }}
        isVerified={user.isEmailConfirmed}
      />
      <DrawerUpdateEmailAddressContainer
        email={user.email}
        visible={openDrawerUpdateEmailAddressContainer}
        onClose={() => setOpenDrawerUpdateEmailAddressContainer(false)}
        changeEmail={(value) => {
          changeEmail(value);
          setUserChanged();
        }}
        confirmEmail={confirmEmail}
      />
      <DrawerUpdateMobileNumberContainer
        visible={openDrawerUpdateMobileNumberContainer}
        onClose={() => setOpenDrawerUpdateMobileNumberContainer(false)}
        phone={user.phone_number}
        changeMobileNumber={changeMobileNumber}
        confirmMobileNumber={() => {
          confirmMobileNumber();
          setUserChanged();
        }}
      />
    </AccountContainer>
  );
}

Account.propTypes = {
  user: PropTypes.object,
  changeFirstName: PropTypes.func,
  changeLastName: PropTypes.func,
  changeEmail: PropTypes.func,
  confirmEmail: PropTypes.func,
  changeMobileNumber: PropTypes.func,
  confirmMobileNumber: PropTypes.func,
  setUserChanged: PropTypes.func,
};
