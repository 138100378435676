import styled from '@emotion/styled';
import SingleDrawer from 'components/SingleAntDrawer';
import { colors } from 'theme';

export const DrawerAssignItemContainer = styled(SingleDrawer)`
  background-color: ${colors.gray2};
  padding: 14px 0 0 0;
  display: flex;
  flex-direction: column;
  display: block;

  span {
    display: block;
    width: calc(100% - 33px);
    &.current-status {
      width: 100%;
    }
  }

  .drawer-assign-item-top {
    font-size: 24px;
    padding-left: 36px;
    width: 24px;
    height: 24px;
    margin-bottom: 40px;

    .drawer-top-exit-icon {
      width: 24px;
      height: 24px;
      height: 24px;
    }
  }
`;
