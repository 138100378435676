import styled from '@emotion/styled';

export const RequestCompletedListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 52px;

  .main {
    display: flex;
    flex-wrap: wrap;
  }
  
  .request-to-handle-pagination {
    margin-left: auto;
    margin-right: auto;
  }

  .request-item {

    .completed-badge-secondary {
      color: #fff;
      /* background-color: ${(prop) =>
    prop.isBadgeBackground ? '#1fc086' : '#ed2626'}; */
      height: 47px;
      width: 47px;
      border-radius: 41px;
    }

    #completed-icon-style {
      z-index: 999999;
      font-size: 20px;
      position: relative;
      top: 11px;
    }

    .completed-margin-bottom {
      margin-bottom: 1rem;
    }

    .completed-dark-card {
      background: #1b1c1d;
      color: #ffffff;
      height: 98px;
      padding: 25px 35px 25px 34px;
      border-radius: 20px;
      cursor: pointer;
      margin-left: 34px;
      margin-bottom: 34px;
      max-width: 450px;
      min-width: 450px;

      &.inactive{
        background: #1b1c1d;
      }

      &.active{
        background: #27282a;
      }
    }

    .completed-flex-inline {
      color: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .completed-padding-horizontal {
      padding-left: 1rem;
    }

    .completed-inner-flex-inline {
      display: flex;
    }

    .completed-line-height {
      line-height: 16px;
    }

    .completed-title {
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -0.36px;
    }

    .completed-description {
      font-size: 15px;
      letter-spacing: -0.36px;
      color: #ffffff;
    }

    .completed-clock-wrapper {
      opacity: 0.6;
      position: relative;
      top: -21px;
    }
  }
  .completed-image-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .completed-illustration-text {
    font-size: 24px;
    font-weight: 500;
    opacity: 0.6;
    color: #ffffff;
    margin-top: 2rem;
    text-align: -webkit-center;
  }
`;
