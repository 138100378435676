import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DefaultUser } from 'assets/images/profile';
import { DataContext } from 'contexts/DataContextContainer';
import { StateContext } from 'contexts/StateContextContainer';
import { DrawerSelectServerWrapper } from './style';

import { IconTable } from 'assets/images/icons';

function DrawerSelectServer({
  visible = false,
  placement = 'right',
  onClose = () => {},
}) {
  const serversList = [
    { id: 1, img: null, name: 'Bob Martin', seats: 2 },
    { id: 2, img: null, name: 'Robert Deniro', seats: 4 },
    { id: 3, img: null, name: 'Maralin Monro', seats: 6 },
    { id: 4, img: null, name: 'Gray Johnes', seats: 0 },
    { id: 5, img: null, name: 'George Brown', seats: 0 },
    { id: 6, img: null, name: 'Melisa Lay', seats: 0 },
    { id: 7, img: null, name: 'Dart Vaider', seats: 100 },
  ];
  const { checkInData, setCheckInData } = useContext(DataContext);
  const { setOpenDrawerCheckInSummary } = useContext(StateContext);

  function choseServer(server) {
    setCheckInData({ ...checkInData, server: server });
    setOpenDrawerCheckInSummary(true);
  }

  return (
    <DrawerSelectServerWrapper
      onClose={onClose}
      visible={visible}
      placement={placement}
    >
      <div className='panel-header-container'>
        <div className='back-arrow' onClick={onClose} aria-hidden='true'>
          <i className='fas fa-chevron-left' />
        </div>
        <div className='panel-header-context'>Select Server</div>
      </div>

      <div className='servers-list'>
        {serversList.map((server) => {
          const isSelected =
            server.id === checkInData?.server?.id ? true : false;
          return (
            <div
              key={`serverkeypr${server.id}`}
              className={`server-item ${isSelected ? 'active' : ''}`}
              onClick={() => choseServer(server)}
              aria-hidden='true'
            >
              <div className='left-part'>
                <div className='image-container'>
                  <img src={server.img ? server.img : DefaultUser} alt='user' />
                  {isSelected === true && (
                    <div className='is-selected'>
                      <i className='fas fa-check' />
                    </div>
                  )}
                </div>
                <div className='server-name'>{server.name}</div>
              </div>
              <div className='right-part'>
                <div className='table-count'>
                  <span>{server.seats}</span>
                  <img src={IconTable} alt='icon-table' />
                </div>
                <div className='arrow-container'>
                  <i className='fas fa-angle-right' />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </DrawerSelectServerWrapper>
  );
}

export default DrawerSelectServer;

DrawerSelectServer.propTypes = {
  visible: PropTypes.bool,
  placement: PropTypes.string,
  onClose: PropTypes.func,
};
