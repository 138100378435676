import React from 'react';
import PropTypes from 'prop-types';
import { DrawerRemoveFoodItemFromOrderContainer } from './style';
import { colors } from 'theme';
import SingleText from 'components/SingleText';
import SingleButton from 'components/SingleButton';

export function DrawerRemoveFoodItemFromOrder({
  foodItem = {},
  visible,
  onClose,
  onRemoveFoodItem,
  onAfterRemoveFoodItem,
}) {
  const remove = () => {
    onRemoveFoodItem(foodItem.id);
    onAfterRemoveFoodItem();
  };

  return (
    <DrawerRemoveFoodItemFromOrderContainer
      visible={visible}
      placement='bottom'
      onClose={onClose}
    >
      <div className='remove-icon'>
        <i className='fas fa-trash' />
      </div>
      <SingleText
        className='remove-item-title'
        size={22}
        spacing='0.42px'
        marginBottom={10}
        align='center'
      >
        Remove Item
      </SingleText>
      <SingleText
        lineHeight='21px'
        spacing='0.27px'
        marginBottom={22}
        align='center'
      >
        Are you sure you want to remove <b>{foodItem.name || 'this item'}</b>?
      </SingleText>
      <SingleText
        lineHeight='21px'
        spacing='0.27px'
        marginBottom={33}
        width='329px'
        align='center'
      >
        Any preferences the customer selected for this item will be lost.
      </SingleText>
      <SingleButton title='Remove' margin='0px 0px 20px 0px' onClick={remove} />
      <SingleButton
        title='Keep'
        btnColor={colors.lightGray}
        onClick={onClose}
      />
    </DrawerRemoveFoodItemFromOrderContainer>
  );
}

DrawerRemoveFoodItemFromOrder.propTypes = {
  foodItem: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onRemoveFoodItem: PropTypes.func,
  onAfterRemoveFoodItem: PropTypes.func,
};
